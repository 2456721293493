@use '../variables/globals' as *;
@use '../variables/opacity' as *;

$opacity-map: (
	"0": $opacity0,
	"100": $opacity100,
	"500": $opacity500,
	"900": $opacity900,
);

@each $key, $val in ($opacity-map) {
	.#{$namespace}opacity-#{$key} {
		opacity: $val !important;
	}
}
