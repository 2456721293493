@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/borders' as *;
@use '../variables/transitions' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;
@use '../variables/semantic' as *;
@use '../variables/icons' as *;
@use '../variables/backgrounds' as *;
@use "../mixins/typography" as *;

.#{$namespace}text-field {
	&-wrapper {
		display: flex;
		flex-direction: column;
	}
	&-label {
		display: flex;
		flex-direction: column;
		min-width: 292px;
		color: $contentSecondary;
	}
	&-input {
		padding: $spacing200 $spacing300;
		border: 1px solid $border04;
		border-radius: $borderRadius300;
		color: $contentDefault;
		min-width: 292px;
		font-family: Open Sans;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: left;
		height: 40px;
		width: 100%;
		&[disabled] {
			background-color: $background02;
		}
		&-fluid {
			min-width: auto;
			max-width: 100%;
			width: 100%;
		}
		&:focus {
			outline: none;
			border: 1px solid $border01;
			+ .#{$namespace}text-field-input-icon-left {
				i:before {
					color: $iconDefault;
				}
			}

		}
		&::placeholder {
			color: $contentPlaceholder;
			font-family: Open Sans;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0px;
			text-align: left;
		}
		&-small {
			@extend .#{$namespace}text-field-input;
			@include generate-type("small");
			height: 36px;
		}
		&-wrapper {
			position: relative;
			border: 1px solid $border04;
			border-radius: $borderRadius300;
			display: flex;
			flex-direction: row;
			align-items: center;
			transition: all $transitionSpeed01 ease;
			background: white;
			&:focus-within {
				border: 1px solid $border01;
				transition: all $transitionSpeed01 ease;
			}
			&.#{$namespace}has-error {
				border: 1px solid $error01;
			}
			&.#{$namespace}is-valid {
				border: 1px solid $success01;
			}
			.#{$namespace}text-field-input {
				border: none;
				&:focus {
					border: none;
				}
			}
		}
		&-prev-icon {
			&-left {
				margin-left: 24px;
			}
		}
		&-search {
			@extend .#{$namespace}text-field-input;
			border: none;

			&:focus {
				border: none;
			}
		}

		&-icon {
			display: flex;
			align-items: center;
			&-left {
				@extend .#{$namespace}text-field-input-icon;
				margin-left: $spacing300;
				color: $iconDisable;
				position: absolute;
				left: 0;
			}
			&-right{
				@extend .#{$namespace}text-field-input-icon;
				margin-right: $spacing300;
				color: $iconDefault;
			}
		}
	}
	&-valid {
		color: $success01;
	}
	&-error {
		color: $error01;
	}
	&-help {
		color: $contentSecondary;
	}
}
