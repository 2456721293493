@use '../variables/globals' as *;
@use '../variables/spacing' as *;
@use '../variables/breakpoints' as *;

$spacing-map: (
	"0": $spacing0,
	"50": $spacing50,
	"100": $spacing100,
	"150": $spacing150,
	"200": $spacing200,
	"250": $spacing250,
	"300": $spacing300,
	"350": $spacing350,
	"400": $spacing400,
	"450": $spacing450,
	"500": $spacing500,
	"550": $spacing550,
	"600": $spacing600,
	"650": $spacing650,
	"700": $spacing700,
	"750": $spacing750,
	"800": $spacing800,
	"825": $spacing825,
	"850": $spacing850,
	"900": $spacing900,
	"925": $spacing925,
	"950": $spacing950,
	"1200": $spacing1200,
	"n50": -$spacing50,
	"n100": -$spacing100,
	"n150": -$spacing150,
	"n200": -$spacing200,
	"n250": -$spacing250,
	"n300": -$spacing300,
	"n350": -$spacing350,
	"n400": -$spacing400,
	"n450": -$spacing450,
	"n500": -$spacing500,
	"n550": -$spacing550,
	"n600": -$spacing600,
	"n650": -$spacing650,
	"n700": -$spacing700,
	"n750": -$spacing750,
	"n800": -$spacing800,
	"n850": -$spacing850,
	"n900": -$spacing900,
	"n925": -$spacing925,
	"n950": -$spacing950,
	"n1200": -$spacing1200,
	"auto": auto,
);

// m - for classes that set margin
// p - for classes that set padding
// t - for classes that set margin-top or padding-top
// b - for classes that set margin-bottom or padding-bottom
// l - for classes that set margin-left or padding-left
// r - for classes that set margin-right or padding-right
// x - for classes that set both *-left and *-right
// y - for classes that set both *-top and *-bottom

// Example Output: .mt-50 { margin-top: $spacing50 0 0 0};
@each $name, $bp in ($breakpoints-map) {
	@if ($name == "xs") {
		@each $prop, $abbrev in (margin: m, padding: p) {
			@each $key, $val in ($spacing-map) {
				.#{$namespace}#{$abbrev}-#{$key} {
					#{$prop}: $val !important;
				}
				.#{$namespace}#{$abbrev}y-#{$key} {
					#{$prop}-top: $val !important;
					#{$prop}-bottom: $val !important;
				}
				.#{$namespace}#{$abbrev}x-#{$key} {
					#{$prop}-left: $val !important;
					#{$prop}-right: $val  !important;
				}
				.#{$namespace}#{$abbrev}t-#{$key} {
					#{$prop}-top: $val !important;
				}
				.#{$namespace}#{$abbrev}b-#{$key} {
					#{$prop}-bottom: $val !important;
				}
				.#{$namespace}#{$abbrev}l-#{$key} {
					#{$prop}-left: $val !important;
				}
				.#{$namespace}#{$abbrev}r-#{$key} {
					#{$prop}-right: $val !important;
				}
			}
		}
} @else {
	@media (min-width: $bp) {
		@each $prop, $abbrev in (margin: m, padding: p) {
				@each $key, $val in ($spacing-map) {
					.#{$namespace}#{$abbrev}-#{$name}-#{$key} {
						#{$prop}: $val !important;
					}
					.#{$namespace}#{$abbrev}y-#{$name}-#{$key} {
						#{$prop}-top: $val !important;
						#{$prop}-bottom: $val !important;
					}
					.#{$namespace}#{$abbrev}x-#{$name}-#{$key} {
						#{$prop}-left: $val !important;
						#{$prop}-right: $val !important;
					}
					.#{$namespace}#{$abbrev}t-#{$name}-#{$key} {
						#{$prop}-top: $val !important;
					}
					.#{$namespace}#{$abbrev}b-#{$name}-#{$key} {
						#{$prop}-bottom: $val !important;
					}
					.#{$namespace}#{$abbrev}l-#{$name}-#{$key} {
						#{$prop}-left: $val !important;
					}
					.#{$namespace}#{$abbrev}r-#{$name}-#{$key} {
						#{$prop}-right: $val !important;
					}
				}
			}
		}
	}
}