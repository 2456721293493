@use '../variables/globals' as *;

.#{$namespace}loader {
    display: none;
	z-index: 100000; 
    position: fixed; 
    overflow: hidden; 
    top: 0; left: 0; 
    width: 100%; 
    height: 100%;
	background: rgba(236,240,241,0.60); 
    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        &-img {
            display: block; 
            height: 100px; 
            width: 100px;
            margin-top: -50px;
            background: url(../../../img/sl_loading_bl.png) 0 0 no-repeat;
            -webkit-animation: ldnga .9s steps(12) infinite;
                -moz-animation: ldnga .9s steps(12) infinite;
                -ms-animation: ldnga .9s steps(12) infinite;
                    -o-animation: ldnga .9s steps(12) infinite;
                    animation: ldnga .9s steps(12) infinite;
        }
    }
    
}