@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/borders' as *;
@use '../variables/dataviz' as *;

.#{$namespace}doughnut {
    position: relative;
    width: 20px;
    height: 20px;
    
    &-chart {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: conic-gradient(
            $dataviz-6 0% 45%,
            $background02 45% 100%
        );
    }
    &-hole {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        background-color: white;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}