/*

	Importació

*/

.box_tip_mod, .box_tip_fld {
  position: relative;
  float: left;
  overflow: hidden;
  margin-right: 24px;
}

.box_tip_mod button {
  display: flex;
  flex-direction: column;
  border: 1px solid #D5DFE1;
  color: #2E383A;
  background-color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 52px 60px 36px 60px;
  height: 240px;

  &:hover {
    color: #2E383A;
    background-color: #FFFFFF;
    border: 1px solid #3D9487 !important;
  }
}

.box_tip_mod button img {
  width: 104px;
}

.box_list_max          { position: relative; overflow: hidden; width: 100%; min-height: 320px; margin-bottom: 20px }
.box_list_max.md_dirct { min-height: 370px; overflow-x: scroll; overflow-y: hidden }
.box_list_max.md_scrll { min-height: 340px }

.box_list_max .scroll     { z-index: 2; position: relative; width: 100%; overflow-x: scroll; overflow-y: hidden; height: 22px }
.box_list_max .scroll div { z-index: 1; position: relative; overflow: hidden; height: 1px }

.box_list_max.md_dirct .wrap { position: relative; overflow: hidden; width: 100%; padding: 0; margin: 0 }
.box_list_max.md_scrll .wrap { position: absolute; top: 30px; left: 0; width: auto; height: auto; overflow: hidden; margin: 0; padding: 0 }

.box_tip_fld {
  width: 250px;
  min-height: 340px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #D5DFE1;
  margin: 0 10px 20px 0 0;
  visibility: hidden }

.box_tip_fld .field_back {
  z-index: 2;
  position: absolute;
  top: 30px;
  right: 16px;
  color: #CFDADD;
  font-size: 20px;
  line-height: 20px;
}

.box_tip_fld .mx-inf {
  z-index: 4;
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
  font-style: normal;
}

.box_tip_fld .mx-inf a {
  color: #4C6569;
}

.box_tip_fld .mx-inf a:hover {color: rgba(0,0,0,0.8)}
.box_tip_fld.st_del .mx-inf a { cursor: default; }

.box_tip_fld input.nm {
  z-index: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 30px 16px 12px;
  border: 0;
  border-bottom: 1px solid #D5DFE1;
  margin-bottom: 16px;
  display: block;
  color: #2E383A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.box_tip_fld input.nm:focus {
  z-index: 3;
  background: #fff;
  color: #2E383A;
}

.box_tip_fld.st_fld input.nm:focus {
  z-index: 3;
  background: #E6FAE8;
  color: #2E383A;
}

.box_tip_fld p.sel {
  display: flex;
  padding: 0;
  margin: 0;
  height: 35px;
  text-align: center;
}

.box_tip_fld select {
	border: 1px solid #D5DFE1;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #2E383A;
  height: 32px;
  border-radius: 4px;
  margin: 0px 16px 12px 16px;
  width: 100%;
  display: flex;
}

.box_tip_fld .sl-field-disabled {
  display: block;
}

.box_tip_fld select.disabled { opacity: 0.3; color: #fff }

.box_tip_fld select optgroup           { padding: 5px; font-style: italic; color: #888 }
.box_tip_fld select option.imprt       { color: #000 }
.box_tip_fld select option.inctv       { color: #aaa }
.box_tip_fld select option[selected]   { background: #387B77; color: #fff }
.box_tip_fld select option             { font-size: 14px; padding: 5px 5px 5px 10px; color: #333 }
.box_tip_fld select optgroup.up option { font-size: 14px; font-weight: 600 }

.box_tip_fld select option[value=action_delete]        { color: #e74c3c; font-weight: bold }
.box_tip_fld select option[value=action_delete]:before { font-family: 'Flat-UI-Icons'; content: "\E00B "; font-weight: normal }

.box_tip_fld p.sel i {
  font-size: 14px;
  line-height: 24px;
  // color: #D2322C;
  width: 100%;
  margin-top: 70px;
  color: #4C6569;
}

.ops.ops_vrt,
.box_tip_fld p.ops {
  display: flex;
  flex-direction: column;
}

             p.ops.ops_vrt .disabled,
.box_tip_fld p.ops         .disabled       { display: block }

.ops_checkboxes {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 24px;
}

.ops_vrt .btn_config {
  margin-bottom: 24px;
}

.box_tip_fld .checkbox {
  margin: 0 0 20px 0;
  padding-left: 24px;
  text-align: left;
  color: #4C6569;
}

.box_tip_fld .checkbox .icons .fui-checkbox-unchecked:before {content: "\E034"}
.box_tip_fld .checkbox:hover {cursor: pointer}
.box_tip_fld .checkbox:hover .icons {color: rgba(0,0,0,0.40)}
.box_tip_fld .checkbox.disabled,
.box_tip_fld .checkbox.disabled .icons span {cursor: default}

             p.ops.ops_vrt .checkbox.fld_sep,
.box_tip_fld p.ops         .checkbox.fld_sep  { display: block }

.box_tip_fld .box_exempl {
  padding: 12px 12px 6px 12px;
  margin: 12px 16px;
  background-color: #F8F8F8;
  border-radius: 4px;
}


.box_tip_fld .box_exempl li {
  list-style-type: none;
  padding: 0 0 6px 0;
  margin: 0;
  border-top: none;
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
}

.box_tip_fld .box_exempl li i {
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
}

.box_tip_fld.st_del {
  background-color: #FFFFFF;
  margin: 0px 10px 0px 0px;
  p.sel i {
    margin-top: 58px;
  }
  .sl-icon-stop {
    font-size: 44px;
  }
  .field-disabled {
    margin-top: 12px;
    display: block;
  }
  .ops_btns {
    margin-top: 78px !important;
  }
}

.box_tip_fld {
  margin: 0px 10px 0px 0px;
}

.box_tip_fld.st_fld {
  background: #E6FAE8;
  margin: 0px 10px 0px 0px;
  input {
    background: #E6FAE8;
  }
  .box_exempl {
    background-color: #fff;
  }
}

.box_tip_fld.st_del .mx-inf,
.box_tip_fld.st_del input,
.box_tip_fld.st_del select,
.box_tip_fld.st_del li,
.box_tip_fld.st_del li i {
  cursor: default;
  background-color: #FFFFFF;
  opacity: .5;
}
.box_tip_fld.st_del .checkbox { visibility: hidden }

.box_tip_fld.st_del .box_exempl {
  display: none;
}

.box_tip_fld.st_del .ops_btns {
  justify-content: center;
  margin: 36px 0 0 0;
}

.form_config .box_tip_fld             { visibility: visible; width: 400px; height: auto; border: 0; border-radius: 0 }
.form_config .box_tip_fld .field_back { left: 380px}
.form_config .box_tip_fld select      { width: 400px; margin: 0 }

.form_config              p.ops.ops_vrt,
.form_config .box_tip_fld p.ops                         { padding: 20px 0 0; margin: 0; text-align: left }
.form_config              p.ops.ops_vrt                 { padding: 0 0 20px 0 }
.form_config              p.ops.ops_vrt label,
.form_config .box_tip_fld p.ops         label           { margin: 0 0 20px }
.form_config              p.ops.ops_vrt .fld_multilang,
.form_config .box_tip_fld p.ops         .fld_multilang  { display: block }

.box_tip_fld .ops_btns {
  margin: 0 0 16px 0;
  display: flex;
  justify-content: flex-end;
}

.box_tip_fld .ops_btns button.btn_conmute {
  padding: 8px 16px;
  height: 32px;
}

.box_tip_fld .ops_btns button.btn-tertiary.btn_config {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  height: 32px;
  border: 1px solid transparent;
  margin-right: 6px;
  i {
    margin-right: 8px;
  }
}

.import_generic_fin {
  font-size: 16px;
  color: #2E383A;
  margin-bottom: 40px;
  #imprt_fin_title {
    margin: 0 0 8px 0;
  }

  p {
    display: flex;
    align-items: center;
  }

  i {
    font-size: 24px;
    margin-right: 8px;
    &.sl-icon-check {
      color: #3D9487;
    }
    &.sl-icon-close {
      color: #653600;
    }
  }

  span.legacy-h7 {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    i {
      font-size: 24px;
      line-height: 24px;
    }
  }
}

.form-import-options-checks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1px;
  .col-lg-12.col-sm-6.col-xs-12 {
    padding-left: 0;
  }
  .checkbox, .radio {
    margin-bottom: 8px;
  }
}