.pndng_pmnt_modal i{
    font-size: 46px;
    padding-top: 40px;
}

.pndg_pmnt_modal_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.pndg_pmnt_modal_info {
    padding: 0 50px;
    font-size: 14px!important;
}

#btn_accept_pndng_pmnt[disabled] {
    display: unset;
    background-color: #ccc;
    color: #000;
}

