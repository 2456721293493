@use '../../variables/globals' as *;
@use '../../variables/icons' as *;
@use '../../variables/colors' as *;

.js-makeform-on {
    .#{$namespace}ct-chat-icon-container {
        display: none !important;
    }
}

.#{$namespace}ct-chat-icon-container {
    position: absolute;
    top: -1px;
    right: 15px;
    width: auto;
    cursor: pointer;
    i {
        color: $iconSecondary;
        font-size: 20px;
    }
}

.#{$namespace}ct-chat-icon-header {
 /*   position: relative;
    right: 0px;
    margin-left: 10px;
    top: 0;*/
    z-index: 901; //necesario para variantes box_head_sec
    position: absolute;
    right: unset;
    top: unset;
    bottom: -1px;
    width: 20px;
    cursor: pointer;
}

.js-makeform-on .makefield_ops:not(.ehide) ~ .#{$namespace}ct-chat-icon-container {
    right: 72px;
}

.js-makeform-on .makefield_ops:not(.ehide) + .#{$namespace}ct-chat-icon-container {
    right: 48px;
}

.js-makeform-on .#{$namespace}ct-chat-icon-container {
    right: 72px;
}

.js-makeform-on .in_connector + .makefield_ops + .#{$namespace}ct-chat-icon-container {
    right: 72px;
}

.#{$namespace}ct-chat-icon-active:after {
    content: "\A";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: flex;
    position: relative;
    background: $primary500;
    border: 1px solid white;
    top: -23px;
    right: -13px;
}