$spacinga: auto;
$spacing0: 0px;
$spacing25: 1px;
$spacing50: 2px;
$spacing100: 4px;
$spacing125: 5px;
$spacing150: 6px;
$spacing200: 8px;
$spacing250: 10px;
$spacing300: 12px;
$spacing350: 14px;
$spacing400: 16px;
$spacing450: 18px;
$spacing500: 20px;
$spacing550: 22px;
$spacing600: 24px;
$spacing650: 28px;
$spacing700: 32px;
$spacing750: 36px;
$spacing800: 40px;
$spacing825: 44px;
$spacing850: 48px;
$spacing900: 56px;
$spacing925: 60px;
$spacing950: 64px;
$spacing1200: 96px;