/*

	Loading

*/

.progress-bar { -webkit-transition:width .6s ease;-o-transition:width .6s ease;transition:width .6s ease }

#sl_loading {
	z-index: 100000; position: absolute; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%;
	background: rgba(236,240,241,0.60); _display: none
}
.modal.mod_popup #sl_loading {position: fixed; background: rgba(255,255,255,0.60); border-radius: 8px;}

#sl_loading.back_body { position: fixed }
#sl_loading.back_trsp { background: transparent }

#sl_loading .info_ldng {display: none}

#sl_loading .btn_cancel,
#sl_loading .progress,      #sl_loading .progress-info,      #sl_loading .progress-desc      { display: none   }
#sl_loading .progress.show, #sl_loading .progress-info.show, #sl_loading .progress-desc.show { display: inline }

#sl_loading i.img_loading, 
#sl_loading .progress, 
#sl_loading .progress-info, 
#sl_loading .progress-desc, 
#sl_loading .btn_cancel     { position: absolute; top: 50%; left: 50%; width: 100px; margin-left: -50px }
#sl_loading .progress       { margin-top: 60px }

#sl_loading .progress-info,
#sl_loading .progress-desc    { margin-top: 80px; font-size: 17px; line-height: 17px; color: #2E383A; font-style: italic; text-align: center }
#sl_loading .progress-info em { font-size: 12px }
#sl_loading .progress-desc    { margin-top: 110px; width: 350px; margin-left: -175px; font-size: 14px; text-align: center }
#sl_loading .progress-desc em { display: inline-block; padding: 3px 8px; background: rgba(255, 255, 255, .7); border-radius: 8px }

#box_content #sl_loading.back_body i.img_loading,
#box_content #sl_loading.back_body .progress, 
#box_content #sl_loading.back_body .progress-info, 
#box_content #sl_loading.back_body .btn_cancel     { margin-left: 40px }
#box_content #sl_loading.back_body .progress-desc  { margin-left: -75px }

.minus_left #box_content #sl_loading.back_body i.img_loading,
.minus_left #box_content #sl_loading.back_body .progress, 
.minus_left #box_content #sl_loading.back_body .progress-info, 
.minus_left #box_content #sl_loading.back_body .btn_cancel     { margin-left: -30px } 
.minus_left #box_content #sl_loading.back_body .progress-desc  { margin-left: -155px }

@media (min-width: 1000px) {

    #box_content #sl_loading.back_body i.img_loading,
    #box_content #sl_loading.back_body .progress, 
    #box_content #sl_loading.back_body .progress-info,
    #box_content #sl_loading.back_body .btn_cancel     { margin-left: 65px } 
    #box_content #sl_loading.back_body .progress-desc  { margin-left: -60px } 
    

    .minus_left #box_content #sl_loading.back_body i.img_loading,
    .minus_left #box_content #sl_loading.back_body .progress, 
    .minus_left #box_content #sl_loading.back_body .progress-info, 
    .minus_left #box_content #sl_loading.back_body .btn_cancel     { margin-left: -15px }
    .minus_left #box_content #sl_loading.back_body .progress-desc  { margin-left: -140px } 
}

@media (min-width: 1500px) {

    #box_content #sl_loading.back_body i.img_loading,
    #box_content #sl_loading.back_body .progress, 
    #box_content #sl_loading.back_body .progress-info, 
    #box_content #sl_loading.back_body .btn_cancel      { margin-left: 80px }
    #box_content #sl_loading.back_body .progress-desc   { margin-left: -45px }
    
    .minus_left #box_content #sl_loading.back_body i.img_loading,
    .minus_left #box_content #sl_loading.back_body .progress, 
    .minus_left #box_content #sl_loading.back_body .progress-info, 
    .minus_left #box_content #sl_loading.back_body .btn_cancel     { margin-left: -15px }
    .minus_left #box_content #sl_loading.back_body .progress-desc  { margin-left: -140px }
}

#sl_loading .progress-bar, .progress-bar { transition: width .2s ease }
.progress-bar.end                        { transition: none }

#sl_loading i.img_loading, .sp_loading {
	display:block; height: 100px; margin-top: -50px;
	background: url(../../../img/sl_loading_bl.png) 0 0 no-repeat;
	-webkit-animation: ldnga .9s steps(12) infinite;
       -moz-animation: ldnga .9s steps(12) infinite;
        -ms-animation: ldnga .9s steps(12) infinite;
         -o-animation: ldnga .9s steps(12) infinite;
            animation: ldnga .9s steps(12) infinite;
}
.md_skn #sl_loading i.img_loading, .sp_loading {background-image: url(../../../img/sl_loading_bl.png)}

.sp_loading         {position: relative; overflow: hidden; width: 100px; margin: 0 auto}
.sp_loading.sep_top {margin-top: 20px }

@-webkit-keyframes ldnga {from { background-position: 0; } to { background-position: -1200px; }}
@-moz-keyframes ldnga    {from { background-position: 0; } to { background-position: -1200px; }}
@-ms-keyframes ldnga     {from { background-position: 0; } to { background-position: -1200px; }}
@-o-keyframes ldnga      {from { background-position: 0; } to { background-position: -1200px; }}
@keyframes ldnga         {from { background-position: 0; } to { background-position: -1200px; }}

#sl_loading .btn_cancel   {width: 30px; height: 30px; margin: 110px 0 0 -15px; padding: 0; background: transparent}
#sl_loading .btn_cancel i {width: auto; padding: 0; margin: 0; font-size: 20px; line-height: 30px; color: #ccc}
#sl_loading .btn_cancel:hover i {color: #387B77; -webkit-transition: linear 0.15s; transition: linear 0.15s}

/*

    In-page loading

*/

#box_stats_rfrsh,
.box_stats_prcss                              { height: 20px; margin: 20px 0px 0px }
.box_default_prcss                            { height: 20px; margin: 0px }
#box_stats_rfrsh,
.box_stats_prcss,
.box_default_prcss,
#box_stats_rfrsh   .info_ldng,
.box_stats_prcss   .info_ldng,
.box_default_prcss .info_ldng                 { position: relative; width: 100%; overflow: hidden }
#box_stats_rfrsh   .info_ldng .icon,
.box_stats_prcss   .info_ldng .icon,
.box_default_prcss .info_ldng .icon           { position: relative; float: left; padding: 2px; margin-right: 10px; color: rgba(0,0,0,0.3); font-size: 16px; font-style: normal;
                                                line-height: 1; text-align: center; font-family: 'Glyphicons Halflings' }
#box_stats_rfrsh   .info_ldng .progress,
.box_stats_prcss   .info_ldng .progress,
.box_default_prcss .info_ldng .progress       { position: relative; float: left; width: 25%; margin: 5px 10px 0 0 }
#box_stats_rfrsh   .info_ldng .progress-info,
.box_stats_prcss   .info_ldng .progress-info,
.box_default_prcss .info_ldng .progress-info,
#box_stats_rfrsh   .info_ldng .progress-desc,
.box_stats_prcss   .info_ldng .progress-desc,
.box_default_prcss .info_ldng .progress-desc  { position: relative; float: left; margin-top: 6px; font-size: 13px; line-height: 13px; color: #2E383A; font-style: italic; text-align: center }
#box_stats_rfrsh   .info_ldng .progress-info,
.box_stats_prcss   .info_ldng .progress-info,
.box_default_prcss .info_ldng .progress-info  { margin-right: 10px }