/* Dahboard Connect */
.box_dashb_connect .box_info {
  display: flex;
  /*justify-content: space-between;*/
}
.box_dashb_connect .box_info.center .box_cnt_elm {
  padding-right: 30px;
  border-right: 1px solid #D5DFE1;
  margin-right: 30px;
}
.box_dashb_connect .box_info.center .box_cnt_elm:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.box_dashb_connect .box_info.center .box_cnt_elm .num {
  display: flex;
  height: 30px;
  font-weight: 300;
}

.box_dashb_connect .box_cnt_elm .num b,
.box_dashb_connect .box_cnt_elm .num strong {
    font-weight: 600;
    font-size: 29px;
    margin-right: 10px;
}

.box_dashb_connect .box_info.center .box_cnt_elm .back {
    top: -3px;
}

.box_dashb_connect .box_info .chart_highlights_left {

  position: relative;
  float: left;
  max-width: 175px;
  margin-right: 20px;
}
.box_dashb_connect .box_info .chart_highlights_left ul {
  padding: 0;
}
.box_dashb_connect .box_info .chart_highlights_left li {
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-transform: capitalize;
  color: #7B9BA1;
  border-left: 2px solid #D5DFE1;
  padding: 5px 0 5px 10px;
  margin: 0
}
.box_dashb_connect .box_info .chart_highlights_left li strong {
  display: block;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  color: #2E383A;
}
.box_dashb_connect .box_info .chart_highlights_left li.active {
  color: #2E383A;
  border-left-color: #9DB2FF;
}
.box_dashb_connect .box_info .chart_highlights_left li.active strong {
  color: #9DB2FF;
}
.box_dashb_connect .box_info .chart_sklt.all_right {
  margin-top: 10px
}