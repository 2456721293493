@use '../../variables/colors' as *;

.mentiony-container [contenteditable=true]:empty:not(:focus):before{
	content:attr(data-placeholder);
	color:#7B9BA1;
	pointer-events:none;
}

.mentiony-container{ 
    position:relative;
    height:auto; 
    // border:1px solid #cecece;
    max-width: 100%;
	width: 100% !important;
	/* NOTE: All top-right-bottom-left must be equal */
    // overflow-x: hidden;
    // overflow-y: auto;
    // max-height: 130px;
    .mentiony-content {
        max-width: 100%;
		font-size: 14px;
		width: 100% !important;
		font-weight: normal;
		max-height: 130px;
		overflow-y: auto;
    }
}
.mentiony-container .pl0{ padding-left:0;}
.mentiony-container .mention-input-hidden{float:left; display:inline-block; visibility:hidden; width:0!important;height:0!important; margin:0!important; padding:0!important;opacity:0!important;}

.mentiony-container .mentiony-popover{
	position: absolute;
    width: 266px;
    height: 156px;
    overflow-y: auto;
    background: white;
    box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
    left: -12px !important;
    padding: 8px;
    border-radius: 4px;
    z-index: 100;
	overflow-y: scroll;
	display:none;
}

.mentiony-list{list-style:none; width: 100%; padding:0;margin: 0;}

.mentiony-item{
	cursor:pointer; 
	overflow: hidden;
	.sl-avatar {
		width: 32px;
		height: 32px;
	}
}

.mentiony-item:first-child{border:none}

.mentiony-item.active{ 
    background-color: #F8F8F8;
}
.mentiony-item img{ width:40px; height:40px; }

.mentiony-item .title, .mentiony-item .help-block{ margin:0;}

.mentiony-item .title{}

.mentiony-item .help-block{}


.mentiony-container .mentiony-content {
	outline: none;
	font, span {
		color: #2E383A;
	}
}

.mention-area .highlight .mentiony-link{
	// color:white; //borrar en la version final
	// background-color: $info500; //borrar en la version final
	// border-radius: 3px; //borrar en la version final
	// margin: 0 2px; //borrar en la version final
	// padding: 0 3px; //borrar en la version final
	color:$primary700; //restablecer en la version final
}
