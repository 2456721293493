@use '../../variables/backgrounds' as *;
@use '../../variables/spacing' as *;
@use '../../variables/fonts' as *;


#box_menu {
	z-index: 50;
  position: fixed;
  left: 0;
  top: 80px;
  bottom: 0;
  width: 224px;
  background: #0D423E;
	overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  padding-bottom: 40px;
  scrollbar-width: thin;
  scrollbar-color: #6d8382 #0D423E;
}

/* Chrome */
#box_menu::-webkit-scrollbar {
  width: 8px;
}
#box_menu::-webkit-scrollbar-track {
  background: #0f3432;
}
#box_menu::-webkit-scrollbar-thumb {
  background: #6d8382;
}
#box_menu::-webkit-scrollbar-thumb:hover {
  background: #5f7170;
}

#menu_pr {
  position: relative;
  z-index: 500;
  pointer-events: none;
}

#box_menu ul li hr {
  border-color: white;
  margin: 12px 24px;
  opacity: 0.2;
}
#box_menu .accordion {
  padding: 10px 0px;
  margin: 0;
}

#box_menu li {
  margin-left: 0;
  padding: 0;
  list-style-type: none;
  pointer-events: auto;
  opacity: 0.6;
  transition: opacity .3s;
  i:before {
    color: #EDF8F3;
    transition: color .3s;
  }
}

#box_menu li a {
  display: flex;
  align-items: center;
  padding: 14px 0 14px 24px;
  margin: 0;
  color: white;
}

#box_menu ul li.selected,
#box_menu ul li:hover {
  opacity: 1;
  transition: opacity .3s;
  i:before {
    color: #fff;
  }
}

#box_menu em {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

#box_menu ul > li strong      { display: block; padding-left: 45px; color: #ccc; font-style: italic; font-size: 80%; text-transform: none }
#box_menu ul > li strong span { font-weight: normal }

#box_menu .accordion-toggle { color: #979ca2 }

#box_menu .icon { color: white; padding-right: $spacing300; vertical-align: -5px; }

#box_menu li.selected a,        #menu_pr li a:hover        { opacity: 1; }
#box_menu li.selected a .icon, #menu_pr li a:hover .icon { opacity: 1; }

            #menu_watermark        { padding-top: 10px }
  #box_menu #menu_watermark li a   { padding-bottom: 10px }
            #menu_watermark sub    { bottom: auto; color: rgba(255,255,255,0.4); font-size: 10px; line-height: 12px; text-transform: lowercase; font-style: italic }
            #menu_watermark img    { height: 18px; width: auto; padding-left: 5px; vertical-align: -7px }
.minus_left #menu_watermark sub                 { display: inline-block; width: 100%; text-align: center }
.minus_left #box_menu #menu_watermark li a      { padding-left: 0 }
.minus_left #box_menu #menu_watermark > li span { display: block; height: 25px; width: 25px; overflow: hidden; margin: 0 auto }
.minus_left #menu_watermark img                 { height: 25px; min-height: 25px; padding: 0; vertical-align: baseline }

@media (max-width: 1000px) {

    .minus_left #box_menu #menu_watermark li a { padding-top: 10px }
    .minus_left           #menu_watermark sub  { display: none }
}

/*

	Disminuidor lateral

*/

#btn_disms {
	z-index: 5001;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 224px;
  color: white;
  opacity: .6;
  cursor: pointer;
  font-family: $fontSallyIcon;
  font-size: 24px;
}

#btn_disms.open { width: 80px }

#btn_disms i {
	position: absolute;
  overflow: visible;
  display: block;
  right: 24px;
  bottom: 24px;
  text-transform: none;
  font-style: normal;
  text-shadow: none;
  font-size: 24px;
  height: 24px;
}

#btn_disms .opn:after {content: "\e91e";}
#btn_disms .cls:after {content: "\e91d";}

#btn_disms .opn {
  display: none;
  left: 50%;
  margin-left: -3px;
}

#btn_disms:hover {
    -webkit-transition: 0.10s linear; transition: 0.10s linear; opacity: 1;
}

#btn_disms.open {width: 60px; -webkit-transition: none; transition: none}
#btn_disms.open .cls {display: none}
#btn_disms.open .opn {display: block}

.minus_left #logo,
.minus_left #box_menu {
  width: 80px;
}

.minus_left #logo a {
  width: 30px;
  height: 80px;
  margin-left: 28px;
}

.minus_left #hd_title,
.minus_left #hd_options {
  left: 124px;
}


.minus_left #logo_reg + #hd_options,
#logo_reg + #hd_options {
  left: 200px;
}

.minus_left #box_menu .icon    { padding-right: 0;}
.minus_left #box_menu em       { display: none }
.minus_left #box_menu em.alter { display: inline }

.minus_left #box_menu li a {
  display: block;
  padding: 14px 0 14px 28px;
}

#box_menu:not([data-scroll='0']) {
  /* Por si queremos hacer algo con esto en el futuro */
}

.minus_left #box_content {
  left: 80px;
}

.minus_left #box_content.pop_mode { left: 0 }

.minus_left #box_menu ul > li            { position: relative }
.minus_left #box_menu ul > li strong     { padding-left: 0; text-align: center; color: #aaa }
.minus_left #box_menu ul > li span       { display: none }
.minus_left #box_menu ul > li span.label { position: absolute; top: 5px; right: 8px; display: block; width: auto }

.breadcrumbs{ margin: 5px 0 10px 0; }

.panels-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* avoid browser level touch actions */
    touch-action: none;
    &.panels-files {
      overflow: visible;
    }
}

.splitter {
  flex: 0 0 auto;
  width: 8px;
  background: url(../../../img/vsizegrip.png) center center no-repeat #c7c7c7;
  min-height: 200px;
  cursor: col-resize;
}

.main-panel {
  flex: 1 1 auto;
  /* resizable */
  width: 100%;
  min-height: 200px;
  min-width: 200px;
  margin-left: 300px;
  transition: margin-left .1s linear;
  &.tree-panel--collapsed {
    margin-left: 32px;
    transition: margin-left .1s linear;
  }
  &.tree-panel--hidden {
    margin-left: 0px;
  }

  .box_head_sec .breadcrumbs {
    margin: 0 0 4px 0;
  }
}

.box_menu_tooltip {
  margin-left: -20px !important;
  pointer-events: none;

  .tooltip-inner {
    border: 1px solid #1E413E;
    background: #FFF;
    color: #1E413E;
  }
}

/* Estados de minificación de filtros de listas de ítems */

/* se requiere evitar el transition del padding para el control de minificación de opciones de lista de ítems */
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle {
  transition-property: background-color, color, border;
}
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified {

  padding: 4px 9px;
    max-width: 40px;
    min-width: 40px;
}
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified span.icon,
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified i.icon {
  margin-right: 0;
}
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle          .filter-option.title em.alter,
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified .filter-option.title {
  display: none;
}
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.semi-minified .filter-option.title em {
  display: none;
}
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.semi-minified .filter-option.title em.alter {
  display: inline;
}

/*
@media (max-width: 1550px) {

  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle { padding: 4px 9px; max-width: 40px; min-width: 40px; }
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle span.icon,
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle    i.icon  { margin-right: 0; }
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle .filter-option.title { display: none; }

  #slct_ord .title em       { display: none; }
  #slct_ord .title em.alter { display: inline; }
}
*/


@media (max-height: 900px) {

  #box_menu li a, .minus_left #box_menu li a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* Ajax loaders */

@keyframes ajaxLoadingFadeInOut {

  0%, 100% { opacity: 0.25; }
  50% { opacity: 1; }
}

.box_ajax_loader {

  animation: ajaxLoadingFadeInOut 1s infinite;
  display: block;
}

.box_ajax_loader.ajax_title_header {

  content: '';
  display: block;
  width: 300px;
  height: 30px;
  background-image: url('../../../img/ajax/back_ajax_loading_pagination_min.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
}

.box_ajax_loader.ajax_pagination {

  content: '';
  display: block;
  width: 250px;
  height: 40px;
  background-image: url('../../../img/ajax/back_ajax_loading_pagination_min.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}

.box_ajax_loader.ajax_list_table {

  content: '';
  display: block;
  width: 800px;
  height: 300px;
  background-image: url('../../../img/ajax/back_ajax_loading_list_table_min.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
}
