/*

	## Variacions responsive ##

*/

@media (min-width: 1000px) {

	#hd_title        { left: 270px; top: 20px; font-size: 20px; line-height: 20px }

    #hd_options .btn_op,
    #head .elm_qlty,
    #head .elm_qlty {
        min-width: unset;
    }

    #hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle { height: 40px; }
    #hd_options .btn-group.select button.dropdown-toggle .icon, .btn-group.slc_currency button.dropdown-toggle .icon { top: 10px }

    #head .box_msgs               { margin: 20px 14px 0 0 }

    #head .box_msgs button                            { font-size: 20px; line-height: 16px }

    #head .box_msgs button i                          { line-height: 35px}

    #Intercom_slyr .glyphicons:before { margin-left: 0; margin-top: 0 }
    #Hubspot_slyr .glyphicons:before { margin-left: 0; margin-top: 0 }

	#box_menu ul ul li a { padding-left: 30px }

    #box_content.pop_mode                       { left: 0;     padding-top: 0 }
    #body .box_input_medium .form .frm_gnr_btns { left: 0 }

    .box_input_medium { width: 75% }

    #box_hist_vw            { width: 300px }

    #box_wrkflw .btn .icon:not(.no_pdg) {
        padding-right: 4px
    }
    #box_wrkflw .btn .icon.sl-icon-trash:not(.no_pdg) {
        padding-right: 8px
    }
    #box_wrkflw .btn em                 { display: inline }

    #box_content.frm_rev                     { padding-top: 120px }
    #box_content.hist_vw_act .box_head_sec,
    #box_content.hist_vw_act .box_msg_action,
    #box_content.hist_vw_act .form           { margin-left: 300px; width: auto }


    #box_content.hist_vw_act .box_head_sec {
        width: calc(100% - 300px);
    }

    .minus_left #box_content.pop_mode,
    .minus_left #body #box_content.pop_mode .frm_gnr_btns { left: 0 }

	.table       td { font-size: 14px; line-height: 17px }
    .table.micro td { font-size: 11px; line-height: 14px }

}

@media (min-width: 1500px) {

    .box_center.long { margin-top: 70px }

	#hd_title        { left: 300px; top: 25px; font-size: 22px; line-height: 22px}

	#head .btn_rgt button            { padding: 10px; height: 40px; font-size: 13px; line-height: 20px }
    #notifications.btn_rgt button    { padding: 10px 5px }

    #head .box_msgs                  { margin: 22px 14px 0 0 }

    #head .box_msgs button { height: 30px; font-size: 13px; line-height: 13px }
    #head .box_msgs button                            { font-size: 20px; line-height: 16px }
    #head .box_msgs button i                          { line-height: 35px }

	#box_menu .accordion { font-size: 90% }
	#box_menu ul ul li a { padding-left: 35px }

    #body .box_input_medium .form .frm_gnr_btns { left: 0 }

    .box_input_medium { width: 60% }

    #box_content.frm_rev    { padding-top: 130px }

	.table       td { font-size: 16px; line-height: 20px }
    .table.micro td { font-size: 12px; line-height: 16px }
}

@media (min-width: 1900px) {

    .dropdown-toggle .filter-option.title.med { max-width: 250px }
}

span.filter-option.pull-left.user_name {
    font-size: 14px;
    line-height: 24px;
}

#head .btn_rgt #notif_button:hover, #head .box_msgs #Hubspot_slyr:hover {
    #notif_mark::before, .sl-icon-message-round::before {
        color: #3D9487;
    }
}