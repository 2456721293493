@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;

.#{$namespace}avatar {
	width: 24px;
	height: 24px;
	border-radius: $borderRadius900;
	background-color: $background03;
	color: $white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	.#{$namespace}avatar-image {
		clip-path: circle(50% at center);
	}
	.#{$namespace}avatar-initials {
		text-transform: uppercase;
	}
	&-large {
		@extend .#{$namespace}avatar;
		width: 72px;
		height: 72px;
		span {
			font-size: 24px;
		}
		i {
			font-size: 36px;
		}
	}
	&-medium {
		@extend .#{$namespace}avatar;
		width: 48px;
		height: 48px;
		span {
			font-size: 16px;
		}
		i {
			font-size: 22px;
		}
	}
	&-small {
		@extend .#{$namespace}avatar;
		width: 32px;
		height: 32px;
		span {
			font-size: 14px;
		}
		i {
			font-size: 16px;
		}
	}
	&-tiny {
		@extend .#{$namespace}avatar;
		width: 24px;
		height: 24px;
		span {
			font-size: 12px;
		}
		i {
			font-size: 12px;
		}
	}
}

// Avatar Group wrapper

.#{$namespace}avatar-group {
	display: flex;
	.#{$namespace}avatar-labeled {
		&:not(:first-child) {
			margin-left: -$spacing150;
		}
		.#{$namespace}avatar-count {
			background-color: $background02;
		}
		.#{$namespace}avatar-count span {
				color: $contentSecondary;
				margin: 0;
		}
		.#{$namespace}avatar {
			border: 2px solid $white;
			.#{$namespace}avatar-image {
				clip-path: circle(50% at center);
			}
		}
	}
}

// Avatar Labeled wrapper

.#{$namespace}avatar-labeled {
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	p {
		margin-left: $spacing200;
		color: $contentSecondary;
		flex: 1;
	}
}