// REVIEW: missing spacing, border-radius and color variables
@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/transitions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use "../variables/content" as *;
@use '../variables/zindex' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;
@use "../mixins/button" as *;

$variants: "primary", "secondary", "tertiary", "danger", "highlight", "outline", "outline-transparent", "state-invisible", "state-draft", "state-visible", "header";

.#{$namespace}button {
	white-space: nowrap;
	border: 1px solid transparent;
	border-radius: $borderRadius300;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: $fontSemiBold;
	outline: 0;
	transition: all $transitionSpeed01 ease;
	position: relative;

	@each $key in ($variants) {
		&-#{$key} {
			@include button-variant(#{$key});
			@include generate-button-sizes(#{$key});
		}
	}

	&-group {
		@extend .#{$namespace}button;
		background-color: $background04;
		color: #4C6569;
		border: 1px solid $border04;
		@include generate-type("small");
		padding: $spacing200 $spacing400;
		height: 32px;
		&.selected {
			background-color: $actionTertiaryActive;
		}
		&:hover {
			background-color: $actionTertiaryHover;
			color: $contentSecondary;
			border: 1px solid transparent;
		}
		&:active {
			background-color: $actionTertiaryActive;
		}
		&:disabled {
			background-color: $background02;
			color: $neutral400;
			cursor: default;
		}
		&:focus {
			background-color: $background04;
			color: $contentSecondary;
		}
		&-left {
			@extend .#{$namespace}button-group;
			border-right: 1px solid $border04;
			border-radius: $borderRadius300 $borderRadius50 $borderRadius50 $borderRadius300;
			
			&:focus {
				border: 1px solid $border01;
			}
		}
		&-center {
			@extend .#{$namespace}button-group;
			border-left: 0px;
			border-right: 0px;
			border-radius: $borderRadius50;
			order: 2;
			&:hover {
				border-left: 0px;
				border-right: 0px;
			}
			&:focus {
				border-top: 1px solid $border01;
				border-bottom: 1px solid $border01;
			}
			&:focus ~ .#{$namespace}button-group-left {
				border-right: 1px solid $border01;
			}
			&:focus ~ .#{$namespace}button-group-right {
				border-left: 1px solid $border01;
			}
		}
		&-right {
			@extend .#{$namespace}button-group;
			border-left: 1px solid $border04;
			border-radius: $borderRadius50 $borderRadius300 $borderRadius300 $borderRadius50;
			order: 3;
			&:focus {
				border: 1px solid $border01;
			}
			&-two {
				@extend .#{$namespace}button-group-right;
				border-left: 0px;
				&:focus {
					border-left: 0px;
				}
				&:focus ~ .#{$namespace}button-group-left {
					border-right: 1px solid $border01;
				}
			}
		}
	}
	
	&-header {
		&-medium {
			font-size: $fontSize600;
			font-weight: $fontRegular;
		}
	}

	&-icon {
		font-weight: $fontRegular;
		font-size: $fontSize600;

		&-only {
			@extend .#{$namespace}button-icon;
			
			&-large {
				@extend .#{$namespace}button-icon-only;
				padding: 11px;
				width: 48px;
			}
			&-medium {
				@extend .#{$namespace}button-icon-only;
				font-size: $fontSize500;
				padding: 9px;
				width: 40px;
			}
			&-small {
				@extend .#{$namespace}button-icon-only;
				font-size: $fontSize400;
				padding: 7px;
				width: 32px;
			}
			&-tiny {
				@extend .#{$namespace}button-icon-only;
				font-size: $fontSize400;
				padding: 3px;
				width: 24px;
			}

		}

		&-left {
			@extend .#{$namespace}button-icon;
			margin-right: 8px;

			&-large {
				@extend .#{$namespace}button-icon-left;
			}
			&-medium {
				@extend .#{$namespace}button-icon-left;
				font-size: $fontSize500;
			}
			&-small {
				@extend .#{$namespace}button-icon-left;
				font-size: $fontSize400;
			}
			&-tiny {
				@extend .#{$namespace}button-icon-left;
				font-size: $fontSize400;
				margin-right: 4px;
			}

		}

		&-right {
			@extend .#{$namespace}button-icon;
			margin-left: 8px;

			&-large {
				@extend .#{$namespace}button-icon-right;
			}
			&-medium {
				@extend .#{$namespace}button-icon-right;
				font-size: $fontSize500;
			}
			&-small {
				@extend .#{$namespace}button-icon-right;
				font-size: $fontSize400;
			}
			&-tiny {
				@extend .#{$namespace}button-icon-right;
				font-size: $fontSize400;
				margin-left: 4px;
			}
		}
	}
}

.#{$namespace}button-split-right {
	border-radius: 0 4px 4px 0 !important;
	margin-left: 0px !important;
}

.#{$namespace}button-split-left {
	border-radius: 4px 0 0 4px !important;
	margin-right: 1px !important;
}

%btn-extension, .btn {
	&.#{$namespace}button-extension {
		&.not-click {
			border: 1px solid #D5DFE1;
			border-left: 0;
			color: #2E383A;
		}
	}
}
.input-group.focus {
	& .input-group-btn {
	  & .btn {
		  &.#{$namespace}button-extension {
			  &.not-click {
				  @extend %btn-extension;
			  }
			  &:hover {
				  background-color: white!important;
			  }
		  }
	  }
	}
}

.#{$namespace}icon-only-link {
	color: $neutral800;
	cursor: pointer;
	&:hover {
		color: $neutral900;
	}
}