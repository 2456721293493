@use '../variables/globals' as *;
@use '../variables/breakpoints' as *;
@use '../variables/layout' as *;

$direction-map: (
    "row": "row",
    "column": "column"
);

$justify-map: (
    "start": "flex-start",
    "end": "flex-end",
    "center": "center",
    "between": "space-between",
    "around": "space-around",
    "evenly": "space-evenly"
);

$align-map: (
    "start": "flex-start",
    "end": "flex-end",
    "center": "center"
);

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    // Common properties for all breakpoints
    %grid-column {
      position: relative;
      width: 100%;
      min-height: 1px; // Prevent columns from collapsing when empty
      padding-right: calc($gutter / 2);
      padding-left: calc($gutter / 2);
    }
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
    
        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
          .#{$namespace}bs-col#{$infix}-#{$i} {
            @extend %grid-column;
          }
        }
        .#{$namespace}bs-col#{$infix},
        .#{$namespace}bs-col#{$infix}-auto {
          @extend %grid-column;
        }
    
        @include media-breakpoint-up($breakpoint, $breakpoints) {
          // Provide basic `.col-{bp}` classes for equal-width flexbox columns
          .#{$namespace}bs-col#{$infix} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
          }
          .#{$namespace}bs-col#{$infix}-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none; // Reset earlier grid tiers
          }
    
          @for $i from 1 through $columns {
            .#{$namespace}bs-col#{$infix}-#{$i} {
              @include make-col($i, $columns);
            }
          }
    
          .#{$namespace}bs-order#{$infix}-first { order: -1; }
    
          .#{$namespace}bs-order#{$infix}-last { order: $columns + 1; }
    
          @for $i from 0 through $columns {
            .#{$namespace}bs-order#{$infix}-#{$i} { order: $i; }
          }
    
          // `$columns - 1` because offsetting by the width of an entire row isn't possible
          @for $i from 0 through ($columns - 1) {
            @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
              .#{$namespace}bs-offset#{$infix}-#{$i} {
                @include make-col-offset($i, $columns);
              }
            }
          }
        }
      }
}

// Generate semantic grid columns with these mixins.

@mixin make-container() {
    width: 100%;
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
  }
  
  
  // For each breakpoint, define the maximum width of the container in a media query
  @mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        max-width: $container-max-width;
      }
    }
  }
  
  @mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc($grid-gutter-width / -2);
    margin-left: calc($grid-gutter-width / -2);
  }
  
  @mixin make-col-ready() {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
  }
  
  @mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage(calc($size / $columns));
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage(calc($size / $columns));
  }
  
  @mixin make-col-offset($size, $columns: $grid-columns) {
    $num: calc($size / $columns);
    margin-left: if($num == 0, 0, percentage($num));
  }

  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
    // Makes the @content apply to the given breakpoint and wider.
    @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
        $min: breakpoint-min($name, $breakpoints);
        @if $min {
        @media (min-width: $min) {
            @content;
        }
        } @else {
        @content;
        }
    }

    // Minimum breakpoint width. Null for the smallest (first) breakpoint.
    //
    //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
    //    576px
    @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
        $min: map-get($breakpoints, $name);
        @return if($min != 0, $min, null);
    }

    // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
    // Useful for making responsive utilities.
    //
    //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
    //    ""  (Returns a blank string)
    //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
    //    "-sm"
    @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
        @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
    }


@mixin layout-utility() {
    @each $dirKey, $dirVal in ($direction-map) {
        &-#{$dirKey} {
            @extend .#{$namespace}block;
            flex-direction: #{$dirVal};
            @each $justKey, $justVal in ($justify-map) {
                &-#{$justKey} {
                    @extend .#{$namespace}block-#{$dirKey};
                    justify-content: #{$justVal};
                    @each $alKey, $alVal in ($align-map) {
                        &-#{$alKey} {
                            @extend .#{$namespace}block-#{$dirKey}-#{$justKey};
                            align-items: #{$alVal};
                        }
                    }
                }
            }
        }
    }
}
