/*

	## Configuració imatges i arxius ##

*/

.bx_conf_img             {position: relative; width: 100%; overflow: hidden}
.bx_conf_img .bx_size    {position: relative; overflow: hidden; float: left; width: 160px; height: 140px; margin: 0 5px 20px}
.bx_conf_img .sz_img     {position: relative; overflow: hidden; width: 50px; height: 50px;}
.bx_conf_img .sz_img div {position: relative; margin: 0 auto; background: #f8f8f8}
.bx_conf_img .sz_img div i {
	position: absolute; top: 50%; left: 50%; width: 18px; height: 18px; margin: -9px 0 0 -9px; font-size: 18px; line-height: 18px; color: #bbb
}

.bx_conf_img .bx_size  i.inf, .bx_conf_img .bx_size  i.titl,
.bx_conf_img .bx_image i.inf, .bx_conf_img .bx_image i.titl,
.bx_conf_img .bx_image i.ext {
	position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
	display: block;
	width: 100%;
	font-size: 14px;
	line-height: 24px;
	color: #2E383A;
}

.bx_conf_img {
	.bx_image {
		i.inf {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			word-break: break-all;
			-webkit-box-orient: vertical;
			line-height: 18px;
			white-space: normal;
			color: #4C6569;
		}
	}
}

.bx_conf_img .bx_image i.inf {
	font-size: 12px;
}

.bx_conf_img .bx_size  i.titl, .bx_conf_img .bx_image i.titl {
	font-size: 12px;
}

.bx_conf_img .bx_image i.inf a {color: #888}

.bx_conf_img .bx_size i.inf,
.bx_conf_img .bx_image i.inf,
.bx_conf_img .bx_image i.ext {
	padding-top: 4px;
}

.bx_conf_img .btns {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 8px;
	left: 8px;
}

.bx_conf_img .btns.sl-block {
	flex-direction: row;
}

.bx_conf_img .conf-img-resize {
	flex-direction: row;
	margin-top: 32px!important;
}

.bx_conf_img .bx_size.edt {
	float: auto; width: 100%; height: auto; border-radius: 5px; background: #f6f6f6; padding: 24px; margin: 0 0 15px 0
}

.bx_conf_img .form-group {width: auto}
.bx_conf_img .form-group label {}

.bx_conf_img .bx_size.edt input[disabled] {border-color: #ddd; color: #555}

.bx_conf_img .input-group-sm > .form-control {
	height: 40px;
}

.bx_conf_img .bx_size.edt .btns {text-align: right; justify-content: flex-end;}

.bx_conf_img .bx_size.del .sz_img div {border-color: #e0e0e0; background: #f8f8f8}
.bx_conf_img .bx_size.del i.inf, .bx_conf_img .bx_size.del i.titl, .bx_conf_img .bx_size.del i b {color: #bbb}

.bx_conf_img.md_vm .bx_image:hover i.inf a {color: #333}

.bx_conf_img .bx_image {
	position: relative;
	float: left;
	margin: 0 22px 22px 0;
}

// .bx_conf_img.md_vm .bx_image {width: 150px; height: 150px; margin: 0 0 5px 0}

.bx_conf_img .bx_image .vw_img {
	position: relative;
	overflow: hidden;
	width: 158px;
	height: 132px;
	border: 1px solid #D5DFE1;
	border-radius: 6px;
	background-image: url(../../../img/placeholder.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bx_conf_img.md_vm   .bx_image .vw_img                     {width: 100px; height: 85px}

.bx_conf_img .bx_image .vw_img img {
	width: inherit;
	height: auto;
}

.bx_conf_img.md_vm   .bx_image .vw_img img                 {width: 150px}
.bx_conf_img.tp_file .bx_image .vw_img img                 {width: 50px; height: auto;}

.bx_conf_img         .bx_image .vw_img.ftype               {text-align: center}
// .bx_conf_img         .bx_image .vw_img.ftype img           {width: auto; margin-top: 15px}

.bx_conf_img .bx_image .vw_img span {
	display: block;
	/* padding-top: 60px; */
	font-size: 50px;
	/* line-height: 50px; */
	color: #ccc;
	width: 100%;
	height: 100%;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bx_conf_img.md_vm   .bx_image .vw_img span                {padding-top: 20px}
.bx_conf_img .bx_image .vw_img a {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}
.bx_conf_img         .bx_image .vw_img        a       span {-webkit-transition: 0.25s; transition: 0.25s}
.bx_conf_img         .bx_image .vw_img        a:hover span {color: #387B77}
.bx_conf_img         .bx_image .vw_img span.stat           {position: absolute; top: 3px; left: 3px; font-size: 13px; line-height: 13px; padding: 0; margin: 0; color: #aaa}

.bx_conf_img         .bx_image.del .vw_img,
.bx_conf_img         .bx_image.msng img    {opacity: 0.3}

#box_content .box_head_sec        {z-index: 900}
#box_content .box_search          {z-index: 800}

#box_content .box_list_spc {
	z-index: 750;
	padding: 0 44px 40px 44px;
}

#box_content .box_pagination      { z-index: 700; }

#box_content .box_pagination.pbtm {
	z-index: 760;
	margin-top: 24px;
}

#box_content .box_list            {
	z-index: 500;
}

.modal-body .box_head_sec  {padding: 0}
.modal-body .box_search    {/*overflow: hidden*/}
.modal-body .box_list,
.modal-body .box_list_ops      { padding: 20px 0 0 }
.modal-body .box_list_ops.ptop { padding: 8px 0 42px 0 }
.modal-body .bx_image.disabled { display: block; opacity: 0.3 }
.modal-body.no-footer          { margin-bottom: 10px }

form#images_actions .bullet:hover { cursor: help; background: #444 }

.selected-file {
	position: absolute;
	left: 8px;
	top: 8px;
	border-radius: 4px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 12px;
	height: 16px;
	width: 16px;
		i {
			height: 12px;
		}
  }

  .selected-file-active {
	background: #387B77;
  }

  .selected-file-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
  }

  .selected-file-overlay:hover {
	background: rgb(52, 73, 94, 0.4);
	width: 100%;
	height: 100%;
	transition: .5s background;
  }

  .selected-file-overlay:hover .selected-file-hover{
	background: #F3F6F7;
  }

  .count-selected-img {
	align-items: center;
	display: flex;
	font-size: 15px;
  }

  .count-selected-img div {
	align-items: center;
	background: #387B77;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	height: 20px;
	justify-content: center;
	margin-right: 16px;
	width: 20px;
		i {
			height: 20px;
		}
  }

#pop_box_files .btn-group {
	margin: 0px 4px 0px 4px;
}

#pop_box_files {
	.box_cont_rgt {
		.pagination.no_mrgn {
			margin: 0 0 0 4px;
		}
	}
}

.modal-body {
	.box_list {
		&.tp_image, &.tp_file {
			padding: 32px 0 0 0;
			display: grid; /* 1 */
			grid-template-columns: repeat(auto-fill, 160px); /* 2 */
			grid-gap: 22px; /* 3 */
			justify-content: space-between; /* 4 */
		}
	}
}



.modal-body {
    .box_head_sec h2.num_elements {
		color: #2E383A;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 4px;
    }
}



#box_cats, #box_prods, #box_frmts, #box_locs, #box_regs {
	.box_list {
		span.img_alert{
			display: none;
		}
	}
	.tp_prods, .tp_cats, .tp_table {
		.sl-thumbnail-large-border i.sl-icon-warning-filled {
			display: none;
		}
	}
}

#box_imgs, #box_files {
	.box_list {
		.box_elm a {
			position: relative;
		}
		span.img_alert {
			position: absolute;
			right: 14px;
			top: 14px;
			color: #D2322C;
			i {
				font-size: 20px;
				height: 18px;
				width: 20px;
				display: block;
				line-height: 18px;
			}
		}
	}
}

.bx_conf_img {
	.bx_image {
		width: 160px;
		height: 163px;
		margin: 0 24px 24px 0;
		.vw_img {
			img {
				background-color: #fff;
			}
		}
	}
	.vw_img.ftype {
		background-image: none;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 61px;
			height: auto;
			background-color: #fff;
		}
	}
}

.box_thumb {
	.box_img.box_img_little {
		img {
			background-color: #fff;
		}
	}
	.js-open-modal {
		cursor: pointer;
	}
}

#pop_box_files .bx_conf_img.tp_file {
	.bx_image {
		.vw_img {
			background-image: none;
		}
	}
}

#pop_box_files .bx_conf_img {
	.bx_image {
		width: auto !important;
		margin: 0;
		height: auto;
		margin-bottom: 24px;
		.inf {
			max-width: 160px;
			font-size: 12px;
			line-height: 16px;
			margin-top: 2px;
			color: #4C6569;
			margin-bottom: 6px;
		}
		.vw_img {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			overflow: hidden;
			width: 150px;
			height: 130px;
			border: 1px solid #D5DFE1;
			border-radius: 6px;
			background-image: url(../../../img/placeholder.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			img {
				background-color: #fff;
			}
			span.stat {
				position: absolute;
				top: 4px;
				right: 0px;
				left: initial;
				padding: 0;
				margin: 0;
				.icon {
					color: #D2322C;
					font-size: 16px;

				}
			}
		}

		i.ext {
			padding-top: 3px;
			display: flex;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: #4C6569;
			b, strong {
				font-weight: 600;
			}
			.icon {
				margin-right: 4px;
				font-size: 14px;
				font-weight: 400;
				vertical-align: middle;
			}
		}
	}

}

#box_imgs .box_list {

	.url_popup {
		position: relative;
		span.img_alert {
			position: absolute;
			right: 14px;
			top: 14px;
			color: #D2322C;
		}
		.sl-thumbnail-tiny {
			& + span.img_alert {
				right: 2px;
				top: 2px;
				i {
					font-size: 16px;
				}
			}
		}
	}
}


.modal-body .bx_conf_img .bx_image .btns {

	.btn.btn-xs {
		height: 24px;
		padding: 4px;
		margin: 0 0 8px 0;

		i.icon {
			margin: 0;
			font-size: 16px;
			line-height: 16px;
		}
	}
}

.modal-content .data-loaded .modal-body .bx_conf_img.bx_conf_img_size .bx_size {

	.btns {
		margin-top: 8px;
		position: relative;
		top: 0;
    left: 0;
	}

	.btn.btn-xs {
		height: 24px;
	}

	.btn.btn-xs.btn-danger {
		padding: 4px;

		i.icon.alone {
			font-size: 16px;
			line-height: 16px;
			margin: 0;
		}
	}
}

.box_list_spc .buttons button {
	height: 40px;
}

.box_list_spc #form_add_type,
#qck_edt_ops #form_add_type {
	display: flex;

	.form-group {
		display: flex;
	}
}

.box_list_spc #form_add_type.ehide,
#qck_edt_ops #form_add_type.ehide {
	display: none;
}

#form_add_type button {
	height: 40px;
}