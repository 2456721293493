/*

	Paginació

*/
/*
#box_content .box_pagination.paragrh  {float: right; width: 49%; padding-left: 0}
*/
#box_content .box_pagination.paragrh,
             .box_pagination.block    { position: relative; width: 100%; display: inline-block }
             
             .box_pagination.block    { min-height: 42px }

.box_pagination .pagination,
.box_pagination .box_rgt,
.pagination.box_rgt,
.box_list_ops .box_rgt,
.form .form-group .box_rgt       { float: right }
.form .btn-group.select.box_rgt,
.box_pagination .box_rgt         { margin-left: 8px!important; margin-right: 0!important }

.box_form       .btn em,
.box_pagination .btn em       { display: inline; font-style: normal }
.box_pagination .btn em.alter { display: none }

.form         .btn-group.select                                    { margin: 0 10px 10px 0; vertical-align: top }
.form         .btn-group.select button.dropdown-toggle,
.box_list_ops .btn-group.select button.dropdown-toggle { 
        font-size: 14px;
        line-height: 24px;
        padding: 8px 20px;
        height: 40px;
        background-color: #D5DFE1;
        color: #2E383A;
        white-space: nowrap;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        outline: 0;
        transition: all 0.3s ease;

        span.icon,
        i.icon {
                font-weight: 400;
                font-size: 20px;
                margin-right: 8px;
                &.icon_order_filter {
                        margin-right: 0;
                }
        }
        &.btn-tertiary {
                background-color: transparent;
                color: #2E383A;
                &:hover {
                        background-color: #EAEEF0;
                        color: #2E383A;
                        border: 1px solid transparent;
                }
        }
}

.box_list_ops .btn-group.select button.dropdown-toggle#tags_menu {
        padding: 8px 20px;

        i {
                font-size: 20px;
                line-height: 20px;
        }
}

button.btn.btn-primary.btn_left.btn_refresh {
        height: 40px;
}

// .box_list_ops .btn-group.select button.dropdown-toggle.btn-primary { background: #387B77; color: #fff }
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I { background: #EAEEF0; color: #4C6569 }


.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V .icon,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D .icon,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I .icon  { color: #4C6569; }

.form         .btn-group.select button.dropdown-toggle .caret,
.box_list_ops .btn-group.select button.dropdown-toggle .caret      { border-top-color: rgba(0,0,0,0.25); border-bottom-color: rgba(0,0,0,0.25) }

.box_list_ops .box_cont_rgt    { position: relative; display: block; float: right }
.box_list_ops .btn_left        { float: left }
.box_list_ops > .btn_left,
.box_list_ops > .btn_right,
.box_list_ops .box_rgt,
.box_list_ops .box_lft,
.box_list_ops .pagination.pbtm {
        margin-bottom: 10px; 
        height: 40px;
}

.box_list_ops .box_lft { float: left }

.btn-group.select.md_highlight  button.dropdown-toggle        { background: #387B77; border-color: #387B77 }

#head #actions.btn-group.select.md_highlight  button.dropdown-toggle { 
        background: #387B77; 
        color: #ffffff;
        border-color: inherit;
        border: 0;
        outline: 1px solid transparent !important;
}

.btn-group.select.md_highlight  button.dropdown-toggle:hover,
.box_list_ops .btn-group.select button.dropdown-toggle:hover,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V:hover { background: #EAEEF0; color: #4C6569 }
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D:hover { background: #EAEEF0; }
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I:hover { background: #EAEEF0; }

.btn-group.select.md_highlight  button.dropdown-toggle .icon  { color: #2E383A; }

.btn-group.select.md_highlight  button.dropdown-toggle       .title,
.box_list_ops .btn-group.select button.dropdown-toggle:hover .title  { -webkit-transition: linear 0.15s; transition: linear 0.15s }

.btn-group.select.md_highlight  button.dropdown-toggle       .caret,
.box_list_ops .btn-group.select button.dropdown-toggle:hover .caret  { border-top-color: rgba(0,0,0,0.50); border-bottom-color: rgba(0,0,0,0.50) }

.box_list_ops .btn-group.select .dropdown-menu { right: 0; left: auto; min-width: 60px }

.box_pagination.block .btn-group { margin-top: 0 }

                        .drop_right ul.dropdown-menu,
.box_list_ops .btn-group.drop_right ul.dropdown-menu,
        .form .btn-group.drop_right ul.dropdown-menu  { left: 0px; right: auto; min-width: 125px }

.btn-group.btns_xcl button.btn.btn-tertiary {
        font-weight: 600;
        max-height: 40px;

        &.btn_undo,
        &.btn_redo {
                &:disabled {
			background-color: transparent;
			color: #93AFB4;
			cursor: default;
                        opacity: 1;
		}
                .icon {
                        margin-right: 0px;
                }
        }
        &:last-child:not(:first-child) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-left: 1px solid #D5DFE1;
        }
        &:first-child:not(:last-child):not(.dropdown-toggle){
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-right: 1px solid #D5DFE1;
        }
        &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
                border-radius: 4px;
        }
}

.box_pagination.box_list_ops .btn-group.select.force-bottom {
        margin: 0 8px 0 0;
}

.pagination ul {
        border-radius: 4px;
}

