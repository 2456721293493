@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/transitions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use '../variables/content' as *;

.#{$namespace}checkbox {
	display: flex;
	min-height: 20px;
	min-width: 20px;
	position: relative;
	cursor: pointer;
	&-disabled {
		* {
			transition: none !important;
		}
		pointer-events: none !important;
		span {
			color: $neutral400 !important;
		}
		.fui-checkbox-unchecked:before {
			color: $neutral400 !important;
		}
	}
	&-small {
        @extend .#{$namespace}checkbox;
		min-height: 16px;
		min-width: 16px;
	}
	input[type="checkbox"] {
		-webkit-appearance: none;
		appearance: none;
		background-color: $white;
		margin: 0;
		&:checked {
			~ .#{$namespace}checkbox-icon-check, ~ .#{$namespace}checkbox-icon-line {
				background-color: $background10;
				border: 1px solid $actionPrimaryDefault;
				&:before {
					display: block;
				}
			}
		}
		&[disabled] {
			~ .#{$namespace}checkbox-icon-check, ~ .#{$namespace}checkbox-icon-line {
				background-color: $background02;
				border: none;
				cursor: default;
			}
			~ .#{$namespace}checkbox-icon-check:after, ~ .#{$namespace}checkbox-icon-line:after {
				border: solid $contentDisable;
			}
			~ .#{$namespace}checkbox-icon-check:after {
				border-width: 0 2px 2px 0;
			}
			~ .#{$namespace}checkbox-icon-line:after {
				border-width: 0 2px 0 0;
			}
		}
	}
	
	.#{$namespace}checkbox-icon {
		border: 1px solid $border03;
		border-radius: $borderRadius100;
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $white;
		box-sizing: border-box;
		margin-top: 2px;
		&:hover {
			border: 1px solid $actionPrimaryDefault;
		}
		&:focus{
			outline :1px solid $border01;
		}
		&-check {
			@extend .#{$namespace}checkbox-icon;
			transition: all 0.3s ease;
			&:before {
				content: "\e919";
				font-family: $fontSallyIcon;
				color: white;
				top: -3px;
				left: 1px;
				position: absolute;
				font-size: $fontSize400;
			}
			&-small {
				@extend .#{$namespace}checkbox-icon-check;
				height: 16px;
				width: 16px;
				left: 2px;
				top: 3px;
				&:before {
					top: -5px;
					font-size: 12px;
					left: 1px;
				}
			}
		}
		&-line {
			@extend .#{$namespace}checkbox-icon;
			&:before {
				content: "\E039";
				font-family: $fontSallyIcon;
				color: white;
				top: -7.5px;
				left: 2px;
				position: absolute;
				font-size: 14px;
			}
			&-small {
				@extend .#{$namespace}checkbox-icon-line;
				height: 16px;
				width: 16px;
				left: 2px;
				top: 2px;
				&:before {
					top: -8px;
					left: 0.5px;
					font-size: 13px;
				}
			}
		}
	}
	.#{$namespace}checkbox-label {
		margin-left: $spacing650;
	}
}
