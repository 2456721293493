@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/borders' as *;

.#{$namespace}thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $borderRadius500;
  background-color: $background04;
  position: relative;
  overflow: hidden;

  &-img {
    width: fit-content;
    height: fit-content;
    max-height: 100%;
    max-width: 100%;
  }
  
  &-large {
    @extend .#{$namespace}thumbnail;
    width: 226px;
    height: 188px;
    
    &-border {
      @extend .#{$namespace}thumbnail-large;
      border: 1px solid $border04;
    }
    .sl-icon-warning-filled {
      position: absolute;
      right: 14px;
      top: 14px;
      color: #D2322C;
      font-size: 20px;
      height: 18px;
      width: 20px;
      display: block;
      line-height: 18px;
    }
  }
  
  &-large .#{$namespace}thumbnail-placeholder {
  	width: 88px;
    height: 88px;
  }
  
  &-medium {
    @extend .#{$namespace}thumbnail;
    width: 160px;
    height: 133px;
    
    &-border {
      @extend .#{$namespace}thumbnail-medium;
      border: 1px solid $border04;
    }
    .sl-icon-warning-filled {
      position: absolute;
      right: 4px;
      top: 0;
      color: #D2322C;
      font-size: 16px;
    }
  }
  
  &-medium .#{$namespace}thumbnail-placeholder {
  	width: 61px;
    height: 61px;
  }
  
  &-small {
    @extend .#{$namespace}thumbnail;
    width: 77px;
    height: 64px;
    
    &-border {
      @extend .#{$namespace}thumbnail-small;
      border: 1px solid $border04;
    }
    .sl-icon-warning-filled {
      position: absolute;
      right: 4px;
      top: 0;
      color: #D2322C;
      font-size: 16px;
    }
  }
  
  &-small .#{$namespace}thumbnail-placeholder {
  	width: 30px;
    height: 30px;
  }
  
  &-tiny {
    @extend .#{$namespace}thumbnail;
    width: 58px;
    height: 48px;
    
    &-border {
      @extend .#{$namespace}thumbnail-tiny;
      border: 1px solid $border04;
      border-radius: $borderRadius300;
    }
    .sl-icon-warning-filled {
      position: absolute;
      right: 4px;
      top: 0;
      color: #D2322C;
      font-size: 16px;
    }
  }
  
  &-tiny .#{$namespace}thumbnail-placeholder {
  	width: 22px;
    height: 22px;
  }

  &-icon {
    background-color: transparent;
    .#{$namespace}thumbnail-placeholder {
      width: 42px;
      height: 42px;
    }
    .sl-icon-warning-filled {
      position: absolute;
      right: 4px;
      top: 0;
      color: #D2322C;
      font-size: 16px;
    }
  }
  

}