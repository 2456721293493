/*

	Dashboards

*/

#box_cntrl_dshbrd   { position: relative; display: block; overflow: hidden; width: 100%; min-height: 150px }

.box_list .sklt {
    position: relative;
    float: left;
    padding: 0 24px 24px 0;
    width: 25%;
    overflow: hidden;
}
.box_list .sklt.dbl { width: 50%  }
.box_list .sklt.trp { width: 75%  }

.box_list .sklt.mxm {
    width: 100%;
    display: flex;
}

.box_list .sklt.not_avlbl .box_info { opacity: 0.4; filter: alpha(opacity=40) }

.box_dashb, .box_conn {
	position: relative; width: 100%; min-height: 250px; max-height: 250px;
	background: #ffffff; color: #586d83; border-radius: 6px;
}

.box_list .sklt.mxm .box_dashb,
.box_list .sklt .box_dashb {
    border-radius: 8px;
    border: 1px solid #D5DFE1;
}

.box_dashb h2,
.box_dashb h3,
.box_conn h3 {
	position: relative;
    overflow: hidden;
    padding: 24px 32px 0px 32px;
    font-size: 20px;
    line-height: 28px;
    /*text-transform: capitalize;*/
    font-weight: 600;
    color: #2E383A;
    display: flex;
    justify-content: space-between;
}

.box_conn h3 {
    padding: 24px;
}

.box_dashb .btn .icon {color: #fff}

.box_dashb h2 .icon.right, .box_dashb h3 .icon.right {font-size: 100%; line-height: 90%; vertical-align: -15%; padding: 0 0 0 5px}

.box_dashb h3 .more .icon {
    color: #387B77;
    padding: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
}

.box_dashb h3 .more:hover .icon {
    color: #3D9487;
    -webkit-transition: linear 0.15s;
    transition: linear 0.15s;
}

.box_dashb h2 span, .box_dashb h3 span {position: relative; display: block; overflow: hidden; width: 100%; text-overflow: ellipsis}


.box_dashb h2:first-letter, .box_dashb h3:first-letter,
.box_conn h2:first-letter, .box_conn h3:first-letter    {text-transform: capitalize}

.box_dashb h2 span a, .box_dashb h3 span a {display: block}

.box_dashb ul {padding: 0 15px 15px 15px; margin: 0}
.box_dashb li {list-style: none; padding: 0; margin: 0 0 5px 0; font-size: 12px; line-height: 18px; color: #777}

.box_dashb.hvar {min-height: 100px; max-height: none}
.box_dashb.mvar {min-height: 150px; max-height: none}
.box_dashb.svar {min-height: 120px; max-height: none}
.box_dashb.fvar {min-height: none;  max-height: none; height: auto}

.box_dashb.bx_bench .bx_elms    {position: relative; width: 100%; overflow: hidden; padding: 0 15px 5px; min-height: 50px}

.box_dashb.bx_bench .bx_elm {position: absolute;
    top: 65px;
    left: 20px;
    width: 45%;
    max-width: 135px;
    height: 30%;
    color: #708397;
}

.box_dashb.bx_bench .bx_elm.rgt    {left: auto; right: 15px}
.box_dashb.bx_bench .bx_elm.bm     {top: auto; bottom: 15px}
.box_dashb.bx_bench .bx_elm.bm.all {width: auto; max-width: none; left: 15px; right: 15px; height: 100px}

.box_dashb.bx_bench .bx_elm span   {
    position: absolute;
    right: 16px;
    bottom: 0;
}

.box_dashb.bx_bench .bx_elm span,
.box_dashb.bx_bench .bx_elm a.nofc,
.box_dashb .box_info a.inf_total {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #7B9BA1;
}

.box_dashb.bx_bench .bx_elm strong {
    font-weight: 600;
    font-size: 29px;
    line-height: 36px;
    color: #4C6569;
    display: block;
}

.box_dashb.bx_bench .bx_elm a:hover strong {
    color: #3D9487;
    transition: all 0.25s ease 0s;
}

.box_dashb.bx_bench .bx_elm .icon.back {
    position: absolute;
    top: 30px;
    left: 16px;
    color: #CFDADD;
    font-size: 24px;
    line-height: 24px;
}

.box_dashb.bx_bench .bx_elm.st_lst {position: relative; display: inline-block; top: auto; left: auto; margin: 35px 10px 10px 0}
.box_dashb.bx_bench.hvar .bx_elm.st_lst,
.box_dashb.bx_bench.mvar .bx_elm.st_lst {margin-top: 0}
.box_dashb.bx_bench .bx_elm.st_lst span {position: relative; left: auto; top: auto}

.box_dashb .bx_elm .bx_back {z-index: 1; position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden}

.box_dashb .bx_elm .bx_cntr {
    display: flex;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: -20px;
}

.box_dashb.tl_grt p {
    font-size: 16px;
    line-height: 24px;
    color: #4C6569;

}

.box_conn .box_info {
    position: absolute;
    top: 100px;
    left: 32px;
    right: 32px;
    bottom: 22px;
    overflow: hidden;
}

.box_conn .box_info {
    left: 24px;
    right: 24px;
}

.box_dashb.msg .box_info,
.box_conn.msg  .box_info {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 32px;
}
.box_dashb.tl_grt .box_info { top: 70px }

.box_dashb .box_info.scroll, .box_conn .box_info.scroll {overflow-y: auto}

.box_dashb .box_info.btm,
.box_conn  .box_info.btm   { bottom: 60px }
.box_dashb .box_btm,
.box_conn  .box_btm        { position: absolute; height: 50px; left: 0; right: 0; bottom: 10px; padding: 15px 24px; overflow: hidden; }

.box_conn .connDescription { bottom: 0; right: 0; position: absolute; margin-bottom: 0 }

.box_dashb .box_info .top {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2E383A;
}

.box_dashb .box_info .box        {position: relative; overflow: hidden; width: 100%}
.box_dashb .box_info .inf_total.lft,
.box_dashb .box_info .sub_inf    {position: relative; overflow: hidden; float: left; margin-right: 20px}
.box_dashb .box_info .sub_inf    {color: #bbb}

.box_dashb .box_info .sub_bx     {position: relative; overflow: hidden; float: left;}
.box_dashb .box_info .sub_bx strong { font-size: 150% }
.box_dashb .box_info .sub_bx.tri {
    font-size: 12px;
    line-height: 20px;
}
.box_dashb .box_info .sub_bx.rgt {float: right}


.box_dashb .box_info .sub_bx.st_D strong,
.box_dashb .box_info .sub_bx.st_D a strong,
.box_dashb .box_info .sub_bx.st_V strong,
.box_dashb .box_info .sub_bx.st_V a strong,
.box_dashb .box_info .sub_bx.st_I strong,
.box_dashb .box_info .sub_bx.st_I a strong {
    color: #2E383A;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

.box_dashb .box_info .sub_bx.st_V,
.box_dashb .box_info .sub_bx.st_I,
.box_dashb .box_info .sub_bx.st_D {
    text-align: center;
    color: #4C6569;
}

.box_dashb .box_info .msg {
    color: #4C6569;
    font-size: 14px;
    line-height: 24px;
    display: flex;
}

.box_dashb .box_info .msg .icon    { vertical-align: -8px }
.box_dashb .box_info .msg .icon.ok { color: #387B77 }

.box_dashb .box_info .lst_actv .info {
    font-size: 12px;
    line-height: 20px;
}

.box_dashb .progress, .box_dashb .progress-bar {position: relative; overflow: hidden}
.box_dashb .prg_back_img {display: none}

.box_dashb .progress-bar.st_V {background-color: #387B77}
.box_dashb .progress-bar.st_I {background-color: #2E383A}
.box_dashb .progress-bar.st_D {background-color: #FF9900}

.box_dashb .progress.rgt_mdl {margin-top: 15px}

.box_dashb .list_tasks        {margin: 0; padding: 0}
.box_dashb .list_tasks td     {vertical-align: top; padding-bottom: 12px}
.box_dashb .list_tasks .tick  {padding-right: 10px; font-size: 12px; line-height: 18px}

.box_dashb .list_tasks {
    .tick {
        display: flex;

        div.label {
            width: 100%;
        }
    }

    .info  {
        font-size: 14px;
        line-height: 24px;
        color: #2E383A;
        font-weight: 400;

        strong.inf_name {
            font-weight: 600;
        }
    }
}

.box_dashb.bx_bench h3        a:hover, .box_dashb.bx_bench h3        a:hover strong,
.box_dashb.bx_bench .box_info a:hover, .box_dashb.bx_bench .box_info a:hover strong {
	color: #53be92; -webkit-transition: linear 0.15s; transition: linear 0.15s
}

h1 small, h2 small, h3 small, h3 small, h5 small, h6 small { color: #93AFB4 }
.box_info h1 small, .box_info h2 small, .box_info h3 small, .box_info h4 small, .box_info h5 small, .box_info h6 small { color: #93AFB4 }

.box_dashb .box_info.center .box_cnt_elm {
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: left;
    margin-bottom: 10px;
    vertical-align: top;
    padding-left: 52px;
    margin: 0;
}
.box_dashb .box_info.center .box_cnt_elm .chart {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
}
.box_dashb .box_info.center .box_cnt_elm .chart.hv {  cursor: pointer }

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a strong {
    color: #387B77;
    -webkit-transition: linear 0.15s;
    transition: linear 0.15s
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a:hover strong {
    color: #3D9487;
}

.box_dashb .box_info.center .box_cnt_elm .logo       { position: relative; width: 100%; text-align: center; margin-bottom: 10px }
.box_dashb .box_info.center .box_cnt_elm .logo img   { width: 80%; height: auto }

.box_dashb .box_info.center .box_cnt_elm h4,
.box_dashb .box_info.center .box_cnt_elm h4 a {
    color: #4C6569;
}

.box_dashb .box_info.center .box_cnt_elm h4 a:hover  {
    color: #3D9487;
    transition: all 0.25s ease 0s;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7          { font-size: 16px; line-height: 20px }

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #387B77;
    margin-top: 4px;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a .icon  { color: #bbb; font-size:100%; padding: 0 }

.box_dashb .box_info.center .box_cnt_elm .add_new {
    display: flex;
    align-items: center;
    height: 100%;
}
.box_dashb .box_info.center .box_cnt_elm .add_new a {
    background-color: #D5DFE1;
    border: none;
    height: 40px;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
    color: #2E383A;
    font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .add_new a:hover {
    background-color: #EAEEF0;
}

.box_dashb.md_cnsmpt .box_info.center .box_cnt_elm.last {
    text-align: center;
    padding: 0 0 0 10px;
}

.box_dashb .box_info.center .box_cnt_elm .ttl {
    font-size: 14px;
    line-height: 24px;
    color: #2E383A;
    font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .num {
    font-size: 14px;
    line-height: 24px;
    color: #2E383A;
    font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .num b {
    font-weight: 600;
    font-size: 29px;
    line-height: 36px;
    color: #2E383A;
}

.box_dashb .box_info.center .box_cnt_elm .num.md_warning {
    color: #D2322C;
    b {
        color: #D2322C;
    }
}

.box_dashb .box_info.center .box_cnt_elm .sub_min {
    color: #7B9BA1;
    display: block;
    font-size: 10px;
    font-style: normal;
}

.box_dashb .box_info.center .box_cnt_elm .sub_lmt {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #7B9BA1;
    font-weight: 400;
}

.box_dashb .box_info.center .box_cnt_elm .back {
    z-index: 0;
    position: absolute;
    top: 26px;
    left: 0;
    color: #CFDADD;
    font-size: 32px;
    line-height: 32px;
}

.box_dashb.bnr_wlcm {
    display: flex;
    align-items: center;

    .left {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        padding: 0 0 0 80px;

        h4 {
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            color: #2E383A;
            margin-bottom: 20px;
            width: 100%;
        }

        h6 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #4C6569;
            margin-bottom: 20px;
            width: 100%;
        }

        a.btn {
            height: 40px;
        }
    }

    .right {
        padding: 16px;
        width: 50%;

        img {
            width: 100%;
        }
    }
}


.box_list .sklt .box_dashb.box_dashb_services {
    min-height: unset;
    max-height: unset;
    height: 370px;
    width: 33%;

    h3 {
        font-size: 18px;
    }

    .last {
        padding: 44px 32px;

        .bx_srv {
            display: flex;
            justify-content: space-between;
            margin-bottom: 26px;

            b {
                display: flex;
                white-space: nowrap;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                color: #2E383A;

                i {
                    color: #ABC0C4;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 20px;
                    margin-right: 10px;
                }
            }

            span.label-primary {
                align-self: center;
                background-color: #BFF5C5;
                color: #05570A;
                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                padding: 2px 8px;
            }

            span.label-default {
                align-self: center;
                background-color: #FBF2D0;
                color: #5A4B35;
                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                padding: 2px 8px;
            }

            span.label-upgrade {
                align-self: center;
                background-color: #D5DFE1;
                color: #2E383A;
                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                padding: 2px 8px;
            }
        }
    }
}

.box_list.md_vw.tp_dasbhoard.tp_dasbhoard_companies .dbl {
    min-width: 521px;
}

.box_list .sklt.dbl .box_dashb.box_dashb_companies {
    min-height: 370px;
    max-height: fit-content;
}

.box_dashb.box_dashb_companies {
    padding: 24px 32px 32px 32px;

    h2 {
        padding: 0;
        margin: 0;
        font-size: 18px;
    }

    .box_info {
        position: unset;
        display: flex;
        flex-direction: column;

        h6 {
            color: #2E383A;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 16px;
        }



        .lst_actv {
            display: flex;
            flex-direction: column;

            &_item{
                margin-bottom: 12px;
                display: flex;

                span.label.date {
                    margin-right: 24px;
                    min-width: 100px;
                }

                .info {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #2E383A;

                    .inf_name {
                        font-weight: 600;
                    }
                }
            }
        }

        &_date {
            color: #4C6569;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 32px;
        }

        &_numbers {
            display: flex;
            gap: 64px;
            margin-bottom: 24px;

            span {
                display: flex;
                align-items: center;

                i {
                    font-size: 24px;
                    line-height: 24px;
                    color: #3D9487;
                    background-color: #EDF8F3;
                    border-radius: 16px;
                    padding: 16px;
                    margin-right: 16px;
                }

                p {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #2E383A;

                    strong {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .box_dashb.box_dashb_companies .box_info_numbers {
        gap:25.5px;
    }
}
@media (max-width: 900px) {
    .box_dashb.box_dashb_companies .box_info_numbers {
        gap:64px;
    }
}
.box_dashb.tl_grt p.sl-companies-info {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #2E383A;

    a.btn {
        font-size: 12px;
        line-height: 16px;
        padding: 8 16px;
        height: 32px;
        margin-left: 12px;
    }
}

.box_list.md_vw.tp_dasbhoard {
    padding-top: 42px;
    min-width: 1150px;

    .sklt.mxm {
        padding: 0 0 24px 0;
    }
}

.box_list .sklt .box_dashb.box_dashb_consums_totals {
    .box_info {
        display: flex;
        justify-content: space-between;
    }
}

.sl-dashboard-activity {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

.box_list .sklt.sl-dashboard-activity-left {
    padding: 0 12px 0 0;
    margin-bottom: 24px;

    h3 {
        font-size: 18px;
    }
}

.box_list .sklt.sl-dashboard-activity-right {
    padding: 0 0 0 12px;
    margin-bottom: 24px;
}

.box_list .sklt.sl-dashboard-activity-left,
.box_list .sklt.sl-dashboard-activity-right {
    height: 356px;

    .box_dashb {
        max-height: unset;
        min-height: unset;
        height: 100%;

        .list_tasks {
            .tick {
                padding: 0 24px 12px 0;
                vertical-align: middle;
            }

            .info {
                white-space: nowrap;
            }
        }
    }

    .box_info.scroll {
        margin: 32px;
    }
}

.box_list .sklt.sl-dashboard-activity-right {

    .box_dashb {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;

        .sl-activity-right-content {
            min-width: 255px;

            h4 {
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
                color: #2E383A;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: #4C6569;
                margin-bottom: 20px;
            }
        }

        img {
            max-width: 297px;
            min-width: 130px;
        }
    }
}

.box_dashb.fvar.msg.sl-dashboard-conn {

    h3 {
        font-size: 18px;
    }

    .box_info {
        padding: 34px 32px 48px;

        #dashb_conn_list {
            display: flex;
            overflow: hidden;
        }

        .box_cnt_elm {
            padding-right: 72px;

            .logo {
                margin-bottom: 8px;

                .url_ldg {
                    img {
                        height: 36px;
                    }
                }
            }

            h3 {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #2E383A;
                padding: 0;
            }

            h4 {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #4C6569;
            }

            .add_new_bnt {
                font-size: 16px;
                line-height: 16px;
                padding: 4px;
                height: 24px;
                width: 24px;
                margin-bottom: 16px;
            }
        }

        .box_cnt_elm.box_cnt_elm_last {
            padding-right: 0;
        }

        .box_cnt_elm_empty {
            margin-left: 62px;

            .elm_empty_logo {
                width: 24px;
                height: 24px;
                background-color: #EAEEF0;
                border-radius: 4px;
                opacity: .7;
                margin-bottom: 16px;
            }

            .elm_empty_name {
                width: 124px;
                height: 12px;
                background-color: #EAEEF0;
                border-radius: 2px;
                opacity: .5;
                margin-bottom: 16px;
            }

            .elm_empty_description {
                width: 186px;
                height: 12px;
                background-color: #EAEEF0;
                border-radius: 2px;
                opacity: .35;
                margin-bottom: 16px;
            }
        }
    }
}

.box_list .sklt .box_dashb.box_dashb_knowledge.box_dashb_knowledge_full {
    width: calc(66% + 24px);
}

.box_list .sklt .box_dashb.box_dashb_knowledge {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FCF8ED;
    border: 1px solid #EEE5C0;
    min-height: unset;
    max-height: unset;
    height: 370px;
    width: 33%;
    padding: 40px 48px 0 48px;
    margin-right: 24px;

    h3 {
        padding: 0;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        color: #2E383A;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #4C6569;
        margin-bottom: 20px;
        text-align: center;
        max-width: 300px;
    }

    img {
        position: absolute;
        bottom: 0;
    }
}

.box_list .sklt .box_dashb.box_dashb_translations {
    width: 33%;
    margin-left: 24px;
    height: 370px;

    h3 {
        font-size: 18px;
    }

    .box_info {
        padding: 32px;

        .box_cnt_cont {
            display: flex;
            flex-direction: column;

            .box_dashb_translations_languages {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
                grid-column-gap: 24px;
                grid-row-gap: 19px;

                .box_cnt_elm {
                    display: flex;
                    justify-content: center;
                    padding: 12px;
                    background-color: #F8F8F8;
                    border-radius: 4px;

                    &:hover {
                        background-color: #EAEEF0;
                    }

                    h4 {
                        display: flex;
                        align-items: flex-end;
                        a {
                            color: #7B9BA1;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 24px;
                        }
                    }

                    span.legacy-h7 {
                        a {
                            white-space: nowrap;
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 600;
                            color: #2E383A;
                            margin-right: 12px;
                        }
                    }
                }

            }


            .box_cnt_elm.add {
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #D5DFE1;
                margin-top: 30px;
                padding: 24px 0 0 0;

                h4 {
                    a {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        line-height: 20px;
                        color: #387B77;
                        font-weight: 400;

                        i {
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: 400;
                            margin-right: 4px;
                        }
                    }

                    a:hover {
                        color: #3D9487;
                    }

                }

                span.legacy-h7 {
                    a {
                        font-size: 12px;
                        line-height: 20px;
                        color: #4C6569;
                        font-weight: 400;
                    }

                    a:hover {
                        color: #3D9487;
                    }

                }
            }
        }
    }
}

.box_list .sklt .box_dashb.box_dashb_taules {
    width: calc(66% + 24px);
    margin-right: 24px;
}

.box_list .sklt .box_dashb.box_dashb_llibreries {
    width: 33%;
}

.box_list .sklt .box_dashb.box_dashb_taules,
.box_list .sklt .box_dashb.box_dashb_llibreries {
    min-height: unset;
    max-height: unset;
    height: 340px;

    h3 {
        font-size: 18px;
    }

    .box_info {
        margin-top: 32px;

        .box_cnt_cont {
            display: flex;
            justify-content: space-around;
            width: 100%;

            .box_cnt_elm {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                .chart {
                    margin-bottom: 20px;
                }

                h4 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 4px;

                    a {
                        color: #2E383A;
                    }
                }

                span.legacy-h7 {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;

                    a strong {
                        color: #7B9BA1;
                    }
                }
            }
        }
    }
}

.box_dashb_leyend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 37px;

    .box_dashb_leyend_v div {
        background-color: #9DB2FF;
    }

    .box_dashb_leyend_d div {
        background-color: #FFA984;
    }

    .box_dashb_leyend_i div {
        background-color: #EFC5FD;
    }

    .box_dashb_leyend_v,
    .box_dashb_leyend_d,
    .box_dashb_leyend_i {
        display: flex;
        align-items: center;
        margin-right: 20px;
        div {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 8px;
        }

        p {
            color: #4C6569;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

.box_dashb .box_info .bx_empty_state {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;

    i {
        font-size: 48px;
        line-height: 48px;
        color: #BAAA8A;
        margin-bottom: 16px;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #2E383A;
        text-align: center;
        margin-bottom: 4px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #4C6569;
        text-align: center;
    }

}

/* Dahboard Connect */

.box_dashb_connect {

    .box_info {
        display: flex;
        justify-content: space-between;
    }

    .box_info.center {

        .back {
            top: -3px;
        }

        .box_cnt_elm {
            margin-right: 30px;
            padding-right: 30px;
            border-right: 1px solid #D5DFE1;

            .num {
                display: flex;
                height: 30px;
                font-weight: 300;
            }

            .num b, .num strong {
                font-weight: 600;
                font-size: 29px;
                margin-right: 10px;
            }

            .back {
                top: -3px;
            }
        }

        .box_cnt_elm:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
        }

        .chart_highlights_left {
            position: relative;
            float: left;
            max-width: 175px;
            margin-right: 20px;

            ul {
                padding: 0;
            }

            li {
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                color: #2E383A;
                border-left: 2px solid #D5DFE1;
                padding-left: 10px;
                padding: 5px 0 5px 10px;
                margin: 0;

                strong {
                    display: block;
                    font-weight: 600;
                    font-size: 29px;
                    line-height: 36px;
                    color: #2E383A;
                }
            }

            li.active {
                color: #2E383A;
                border-left-color: #9DB2FF;

                strong {
                    color: #9DB2FF;
                }
            }
        }

        .chart_sklt.all_right {
            margin-top: 10px;
            width: 90%;
        }
    }
}

.box_dashb_connect.md_cnsmpt .box_info {
        display: inline-block;
}

#channel_categories {

    display: inline-block;
    margin-bottom: 0;
}
#channel_categories .btn-group {

    display: flex;
    justify-content: space-between;
    height: 32px;
}

#channel_categories .btn-group.minified button i {

    margin-left: 0;
    margin-right: 0;
}
#channel_categories .btn-group.minified button span {

    display: none;
}

@media (min-width: 1500px) {

    .box_dashb.fvar.msg.sl-dashboard-conn {

        .box_info {

            .box_cnt_cont#dashb_conn_list {
                justify-content: space-between;
            }

        }
    }
}
