@use '../variables/globals' as *;
@use '../variables/border-radius' as *;

// GENERIC

$border-radius-map: (
	"50": $borderRadius50,
	"100": $borderRadius100,
	"300": $borderRadius300,
	"500": $borderRadius500,
	"700": $borderRadius700,
	"900": $borderRadius900,
);

@each $key, $val in ($border-radius-map) {
	.#{$namespace}border-radius-#{$key} {
		border-radius: $val !important;
	}
}

// MODIFIERS

// Bottom
// .border-radius700--bottom {border-radius: 0 0 $borderRadius700 $borderRadius700;}
@each $key, $val in ($border-radius-map) {
	.#{$namespace}border-radius-#{$key}-bottom {
		border-radius: 0 0 $val $val !important;
	}
}

// Top

@each $key, $val in ($border-radius-map) {
	.#{$namespace}border-radius#{$key}-top {
		border-radius: $val $val 0 0 !important;
	}
}