@use '../variables/globals' as *;
@use '../variables/fonts' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/border-radius' as *;
@use '../variables/colors' as *;
@use '../variables/spacing' as *;
@use '../variables/backgrounds' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;

details {
  width: 100%;
}

details[open] > summary {
	border: 1px solid $border01;
}

details[open] > summary:after {
	content: "\E018";
}

details p {
  @include generate-type("medium");
  color: $black;
  padding: $spacing650 $spacing400 $spacing400 $spacing400;
}

summary {
  @include generate-type("medium");
  font-weight: $fontSemiBold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $neutral250;
  border-radius: $borderRadius300;
  background-color: $background04;
  padding: $spacing400;
  cursor: pointer;
  color: $contentDefault;
}

summary:hover {
  background-color: $background02;
}

summary:after {
  font-family: $fontSallyIcon !important;
  content: "\E015";
  font-size: $fontSize600;
  font-weight: $fontRegular;
}

.#{$namespace}summary-wrapper {
  display: flex;
  align-items: center;
}