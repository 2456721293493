@use '../variables/globals' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/content' as *;

.#{$namespace}popover {
	border-radius: $borderRadius700;
	padding: $spacing600;
	box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
	width: 352px;
	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $spacing300;
		h5 {
			color: $contentDefault;
		}
	}
	&-body {
		color: $contentSecondary;
	}
	&-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: $spacing500;
	}
}
