/*

    Secció notificacions

*/

.box_list.tp_news {
    padding: 76px 44px 44px 44px;
}

.box_list.tp_news ul {
    padding: 0;
    margin: 0;
    width: 100%;
}

.box_list.tp_news ul li {
    position: relative;
    padding: 24px 32px 28px 32px;
    margin-bottom: 8px;
    list-style-type: none;
    background-color: #FFFFFF;
    border: 1px solid #D5DFE1;
    border-radius: 8px;
    -webkit-transition: .10s linear;
    transition: .10s linear;
}

.box_list.tp_news ul li
{
    &:hover,
    &.new {
        background-color: #FCF8ED;
        border: 1px solid #EEE5C0;
    }
    &:hover {
        button {
            display: block;
        }
    }
}

.box_list.tp_news li p            { margin: 0; padding: 0 }

.box_list.tp_news li button {
    position: absolute;
    top: 18px;
    right: 32px;
    margin: 0;
    color: #4C6569;
    -webkit-transition: .10s linear;
    transition: color .10s linear;
    display: none;

    i {
        font-size: 16px;
        line-height: 16px;
    }
}

.box_list.tp_news li .dat { display: block;
    margin: 0 0 8px 0;
    font-size: 10px;
    line-height: 16px;
    font-style: normal;
    color: #4C6569;
}

.box_list.tp_news li .ttl {
    display: block;
    margin: 0 0 4px 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #000000;
}
.box_list.tp_news li .ttl strong {
    font-weight: 700;
}

.box_list.tp_news li .txt {
    display: block;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #2E383A;
    white-space: normal;
}
.box_list.tp_news li .txt strong {
    font-weight: 600;
}

// .box_list.tp_news li .dat span    { padding-right: 10px }
// .box_list.tp_news li.new          { border-left: 5px solid rgba(26,188,156,0.3); background: rgba(26,188,156,0.1) }
// .box_list.tp_news li.new:hover    { border-left: 5px solid rgba(26,188,156,0.5); background: rgba(26,188,156,0.2) }
// .box_list.tp_news li.new a        { color: rgba(0, 0, 0, 0.60) }
// .box_list.tp_news li.new a:hover  { color: rgba(0, 0, 0, 0.80) }
// .box_list.tp_news li     p a,
// .box_list.tp_news li.new p a        { color: #16a085; font-weight: bold }
// .box_list.tp_news li p a:hover      { color: #387B77 }
// .box_list.tp_news li p a.alone      { display: block; padding-top: 5px }
// .box_list.tp_news li.tp_Alert       { border-color: rgba(241,196,15,0.5) }
// .box_list.tp_news li.tp_Alert:hover { border-color: rgba(241,196,15,0.7) }
// .box_list.tp_news li.tp_Error       { border-color: rgba(231,76,60,0.5) }
// .box_list.tp_news li.tp_Error:hover { border-color: rgba(231,76,69,0.7) }

#box_notifs .txt .btn,
#box_notifs .txt .btn:hover        { color: #fff }

