@use '../../variables/globals' as *;
@use '../../variables/border-radius' as *;
@use '../../variables/borders' as *;
@use '../../variables/spacing' as *;
@use '../../variables/colors' as *;
@use '../../variables/icons' as *;
@use '../../variables/backgrounds' as *;
@use '../../variables/typography' as *;
@use '../../variables/content' as *;
@use "../../variables/fonts" as *;
@use '../../variables/zindex' as *;
@use '../../variables/actions' as *;

.select2.select2-container.select2-container--bootstrap.select2-container--below,
.select2.select2-container--bootstrap {
    background-color: $white;
    border: 1px solid $border04;
    border-radius: $borderRadius300;
    min-height: unset;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    padding: 0px;
    margin-top: 0;
    box-shadow: none;
}

.select2.select2-container.select2-container--bootstrap.select2-container--below.select2-container--focus {
    border: 1px solid $border01;
}

.select2.select2-container.select2-container--bootstrap.select2-container--below.select2-container--focus.select2-container--open {
    margin-top: unset;
    box-shadow: unset;
}
.select2-container .selection .select2-selection .select2-selection__rendered,.select2-container .select2-dropdown .select2-results ul li, .select2-container .selection .select2-selection ul li {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.select2-container--bootstrap.select2-container--open {
    // margin-top: $spacing200;
    box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
    // border-radius: $borderRadius700;
}

.select2-container--bootstrap>.select2-dropdown {
    background-color: $white;
    line-height: unset;
    background: $white;
    border: none;
}

.select2.select2-container--bootstrap.select2-container--open {
    padding: 0;
}

.select2-container--bootstrap .select2-dropdown .select2-results {
    padding: 0;
    margin: 0;
}

.select2-container--bootstrap .select2-results>.select2-results__options {
    padding: $spacing300 0 $spacing300 0;

    li {
        padding: $spacing250 $spacing600;
            margin: 0 $spacing300;
        color: $contentDefault;
    }

    li {
        padding: $spacing250 $spacing600;
            margin: 0 $spacing300;
        color: $contentDefault;
    }

    li.select2-results__option.select2-results__option--highlighted, li[aria-selected="true"] {
        background-color: $background01;
            color: $actionPrimaryHover;
    }
}

.select2-container--bootstrap .select2-selection--multiple {
    padding: 4px 12px 3px 12px;
    height: unset;
    display: flex;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
    background-color: #FBF2D0;
    color: #5A4B35;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    color: $contentTertiary;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    border: 0;
    border-radius: 4px;
    cursor: default;
    float: left;
    /* He modificado margin: 2px 22px 2px 0; por este porque en los listado se muestra mal si ya había un tag seleccionado */
    margin: 2px 8px 2px 0;
    padding: 2px 8px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
    font-weight: 400;
    margin-right: 0;
    margin-left: 6px;
    font-size: 0px;
    line-height: 16px;
    height: 16px;
    color: #5A4B35;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove::after {
    font-family: "sally-icon";
    content: "\e922";
    font-size: 16px;
    line-height: 16px;
    height: 16px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: $contentTertiary;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
    padding: 8px 22px 8px 12px;
    font-size: 14px;
    line-height: 24px;
    min-height: 40px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.select2-results__option.select2-results__option[aria-selected='true']:before {
    display: none;
}

.select2-container--bootstrap .select2-results {
    height: fit-content;
}