@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/transitions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use "../mixins/typography" as *;

$button-variant-map: (
    "primary": (
        "background-color": $background10,
		"color": $white,
        "hover" : $actionPrimaryHover,
        "active" : $actionPrimaryActive,
        "focus": $background10
    ),
    "secondary": (
        "background-color": $actionSecondaryDefault,
		"color": $neutral900,
        "hover" : $actionSecondaryHover,
        "active" : $neutral300,
        "focus": $actionSecondaryDefault
    ),
    "tertiary": (
        "background-color": transparent,
		"color": $neutral900,
        "hover" : $actionSecondaryHover,
        "active" : $actionSecondaryDefault,
        "focus": transparent
    ),
    "danger": (
        "background-color": $error50,
		"color": $error600,
        "hover" : $error75,
        "active" : $error100,
        "focus": $error50
    ),
    "highlight": (
        "background-color": $background05,
		"color": $secondary900,
        "hover" : $background15,
        "active" : $background11,
        "focus": $background05
    ),
    "outline": (
        "background-color": $white,
		"color": $neutral900,
        "hover" : $actionSecondaryHover,
        "active" : $actionSecondaryDefault,
        "focus": transparent,
        "outline": "1px solid #D5DFE1"
    ),
    "outline-transparent": (
        "background-color": transparent,
		"color": $neutral900,
        "hover" : $actionSecondaryHover,
        "active" : $actionSecondaryDefault,
        "focus": transparent,
        "outline": "1px solid #D5DFE1"
    ),
    "state-visible": (
        "background-color": $background17,
		"color": $success900,
        "hover" : $success50,
        "active" : $success100,
        "focus": $success50,
    ),
    "state-draft": (
        "background-color": $background05,
		"color": $secondary900,
        "hover" : $background14,
        "active" : $background05,
        "focus": $background05,
    ),
    "state-invisible": (
        "background-color": $actionSecondaryDefault,
		"color": $neutral900,
        "hover" : $background02,
        "active" : $actionSecondaryDefault,
        "focus": $actionSecondaryDefault,
    ),
    "header": (
		"color": $neutral900,
        "hover" : transparent,
        "active" : transparent,
        "focus": transparent,
    )
);

$button-sizes-map: (
    "large": (
        "padding": $spacing300 $spacing600,
        "height": 48px,
    ),
    "medium": (
        "padding": $spacing200 $spacing500,
        "height": 40px,
    ),
    "small": (
        "padding": $spacing200 $spacing400,
        "height": 32px,
    ),
    "tiny": (
        "padding": $spacing100 $spacing250,
        "height": 24px,
    ),
);

@mixin button-variant($variant) {
    @extend .#{$namespace}button;
    background-color: #{map-get($button-variant-map, $variant, "background-color")};
    color: #{map-get($button-variant-map, $variant, "color")};
    @if ((#{$variant} == "outline") or (#{$variant} == "outline-transparent")) {
        outline: #{map-get($button-variant-map, $variant, "outline")} !important;
        &:focus {
            outline: #{map-get($button-variant-map, $variant, "outline")} !important;
        }
    }
}

@mixin generate-button-sizes($variant) {
    @each $key, $val in ($button-sizes-map) {
        &-#{$key} {
            @extend .#{$namespace}button-#{$variant};
            @if (#{$key} == "tiny") {
                @include generate-type("small");
            } @else {
                @include generate-type(#{$key});
            }
            @if (#{$variant} != "header") {
                padding: #{map-get($button-sizes-map, $key, "padding")};
                height: #{map-get($button-sizes-map, $key, "height")};
            } @else {
                position: relative;
            }
            &:hover {
                background-color: #{map-get($button-variant-map, $variant, "hover")};
                color: #{map-get($button-variant-map, $variant, "color")};
                border-color: transparent;
            }
            &:active {
                background-color: #{map-get($button-variant-map, $variant, "active")};
                @if (#{$variant} == "outline") {
                    background-color: $neutral250;
                    outline: 0;
                }
            }
            &:disabled {
                @if (#{$variant} == "tertiary") {
                    background-color: transparent;
                } @else {
                    background-color: $actionSecondaryHover;
                }
                @if (#{$variant} == "outline") {
                    background-color: transparent;
                }

                color: $neutral400;
                cursor: default;
            }
            &:focus {
                color: #{map-get($button-variant-map, $variant, "color")};
            }
            @if (#{$variant} == "tertiary" and #{$key} == "medium") {
            &.active {
                background-color: $actionSecondaryDefault;
            }
            }
        }
    }
}

