@use '../variables/globals' as *;
@use "../variables/typography" as *;
@use "../variables/backgrounds" as *;
@use "../variables/borders" as *;
@use "../variables/actions" as *;
@use "../variables/content" as *;
@use "../variables/border-radius" as *;
@use "../variables/spacing" as *;
@use "../mixins/typography" as *;

.#{$namespace}radio {
  display: flex;
  align-items: center;
  @include generate-type("medium");
  cursor: pointer;
  &-input {
    font: inherit;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    background-color: $background04;
    margin: 0;
    width: $spacing400;
    height: $spacing400;
    border: 1px solid $border03;
    border-radius: $borderRadius900;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      width: $spacing200;
      height: $spacing200;
      border-radius: $borderRadius900;
      transform: scale(0);
      transition: all 0.3s ease;
      box-shadow: inset 1em 1em $background04;
    }
    &:hover {
      border: 1px solid $actionPrimaryDefault;
    }
    &:disabled {
      background-color: $background02;
      border: none;
    }
    &:disabled ~ .radio-text {
      color: $contentDisable;
    }
    &:focus {
      border: 1px solid $border01;
    }
    &:checked {
      background-color: $background10;
      border: none;
      &::before {
        transform: scale(1);
      }
      &:hover {
        background-color: $actionPrimaryHover;
      }
      &:focus {
        border: 1px solid $border01;
      }
      &:disabled {
        background-color: $background02;
        border: none;
        &::before {
          box-shadow: inset 1em 1em $contentDisable;
        }
      }
    }
  }
  &-text {
    margin-left: $spacing200;
    color: $contentDefault;
  }
}
