/*

	## Cuerpo derecho

*/

#box_content {
    z-index: 1;
    position: absolute;
    left: 224px;
    right: 0;
    top: 0;
    padding-top: 80px;
    bottom: 0;
    /*min-width: 1150px;*/
}

#box_content.pop_mode { top: 0 }

.paragrh {
    position: relative;
    width: 100%;
    display: block;
    padding: 0px 0px 40px 0px;
}

.paragrh.box_pagination {
    padding: 0px 44px;
}

.paragrh.box_translations .form {
    padding: 24px 44px 0 44px;
}

.alert-container-blank .paragrh.box_msg_action {
    padding: 40px 0 0 0;
}

.paragrh.ptop   { padding-top: 0 }
.paragrh.pbtm   { padding-bottom: 0 }
.paragrh.xpdn   { padding: 0 0 20px }
.paragrh.left   { float: left }
.paragrh.cntr   { float: auto; margin: 0 auto; max-width: 800px; text-align: center; }
.paragrh.mrg_tp { margin-top: 100px }

.col-auto {
    width: auto;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


@media (max-width: 1000px) {

    .paragrh.mrg_tp { margin-top: 50px }
}

.paragrh .inprg { padding: 0 }

.paragrh > h1:first-child,
.paragrh > h2:first-child,
.paragrh > h3:first-child,
.paragrh > h4:first-child,
.paragrh > h5:first-child  { margin: 0 0 30px 0 }

#box_content .box_list .paragrh { padding: 0 0 20px }

.paragrh .img_back {
    display: block;
    margin-top: 54px;
    min-height: 100px;
    text-align: center;
}

.paragrh.msg_empty {

    h4 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #2E383A;
        margin-top: 20px;
    }

    p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #2E383A;
        margin-top: 20px;
    }
}

.paragrh .box_btns {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a.btn {
        padding: 8px 16px;
        font-size: 12px;
        line-height: 16px;
        height: 32px;
    }
}

/*.paragrh.is_srch .img_back img { -webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.7; filter: alpha(opacity=70); }*/
.paragrh.is_lttl .img_back img { background: rgba(0, 0, 0, 0.04); padding: 20px; border-radius: 30px; height: 125px; width: auto; opacity: 0.9; filter: alpha(opacity=90); }
.paragrh.is_lttl .img_back     { max-height: 130px; margin-top: 10px }

.modal-body .paragrh.is_lttl .img_back {
    max-height: none;
    margin-top: 72px;
    height: 176px !important;
    img {
        background: transparent;
        padding: 12px;
        border-radius: 30px;
        height: 176px !important;
        width: auto;
    }
}

@media (min-height: 1000px) and (min-width: 1400px) {

    #box_content > .paragrh.msg_empty:not(.is_srch)           { position: absolute; top: 50%; left: 50%; width: 700px; margin-left: -350px; height: 400px; margin-top: -200px }
    #box_content > .paragrh.msg_empty:not(.is_srch) .img_back { margin-top: 0 }
}

.box_head_sec.inprg {
    padding: 0;
}

.sl-payment-paragrh {
    padding: 24px 44px 40px 44px;
}

.sl-payment-noconsumption {
    padding: 30px 44px 40px 44px;

    h4 {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #2E383A;
    }

    h6 {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #2E383A;
    }
}

button.sl-link-details {
    white-space: nowrap;
    font-size: 14px;
    line-height: 24px;
    color: #4C6569;

    &:hover {
        color: #3D9487;
    }
}

.btn.sl-btn-invoice {
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    padding: 8px 16px;
}
