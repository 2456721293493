.channel-access-dropdown-menu,
.channel-access-user-dropdown-menu {
    border: 1px solid #D5DDE5;
    border-radius: 6px;
    z-index: 9;
    min-width: unset;
    width: 134px;
}

.channel-access-user-list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.channel-access-user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
}

.channel-access-user-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.channel-access-user-avatar {
    width: 32px;
    height: 32px;
    background-color: #ABC0C4;
    border-radius: 50%;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .initials {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
    }
}

.channel-access-user-data {
    max-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px; 
    line-height: 20px;
    color: #4C6569;
    strong {
        font-weight: 600;
        font-size: 14px; 
        line-height: 24px;
        color: #2E383A;
    }
}

.channel-access-user-status {
    font-size: 14px;
    color: #607589;
}

.channel-access-user-dropdown-menu {
    right: 0;
    left: auto;
}

.channel-access-card-lock {
    font-size: 40px;
    color: #CCCCCC;
    margin-bottom: 25px;
}

.channel-access-card-text {
    font-size: 15px;
    color: #586D83;
}

.channel-access-btn-pending {
    background-color: #A7AEB2;
    padding: 12px;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    max-height: 40px;
    justify-content: center;
    align-items: center;
}

.channel-access-btn-pending-icon {
    font-size: 16px;
}