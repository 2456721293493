@use '../variables/globals' as *;
@use '../variables/breakpoints' as *;
@use '../variables/spacing' as *;
@use '../variables/layout' as *;
@use '../mixins/layout' as *;

.#{$namespace}block {
	display: flex !important;
	@include layout-utility();
}

.#{$namespace}display-block {
	display: block !important;
}

.#{$namespace}row {
	flex-direction: row !important;
}

.#{$namespace}column {
	flex-direction: column !important;
}

.#{$namespace}justify-start {
	justify-content: flex-start !important;
}

.#{$namespace}justify-end {
	justify-content: flex-end !important;
}

.#{$namespace}justify-center {
	justify-content: center !important;
}

.#{$namespace}justify-between {
	justify-content: space-between !important;
}

.#{$namespace}justify-evenly {
	justify-content: space-evenly !important;
}

.#{$namespace}justify-around {
	justify-content: space-around !important;
}

.#{$namespace}align-start {
	align-items: flex-start !important;
}

.#{$namespace}align-end {
	align-items: flex-end !important;
}

.#{$namespace}align-center {
	align-items: center !important;
}

.#{$namespace}wrap {
	flex-wrap: wrap !important;
}

.#{$namespace}w-100 {
	width: 100% !important;
}

.#{$namespace}w-75 {
	width: 75% !important;
}

.#{$namespace}w-66 {
	width: 66% !important;
}

.#{$namespace}w-50 {
	width: 50% !important;
}

.#{$namespace}w-33 {
	width: 33% !important;
}

.#{$namespace}w-25 {
	width: 25% !important;
}

.#{$namespace}maxw-100 {
	max-width: 100% !important;
}

.#{$namespace}h-100 {
	height: 100% !important;
}

.#{$namespace}hide {
	display: none !important;
}

@for $size from 1 through 12 {
	.#{$namespace}flex-#{$size} {
		flex: $size !important;
	}
}


// order flex elements
// example:
// size: 1
// output: .sl-order-1
@for $size from 1 through 6 {
	.#{$namespace}order-#{$size} {
		order: $size !important;
	}
}

// Grid columns 
// col-1 through col-12 (plus media queries, ex. col-xl-5)
@for $col from 1 through 12 {
	.#{$namespace}col-#{$col} {
		display: grid !important;
		grid-template-columns: repeat($col, 1fr) !important;
	}
}

.#{$namespace}col-auto {
	display: grid !important;
	grid-template-columns: max-content 1fr;
}

@each $key, $val in ($breakpoints-map) {
		@media (min-width: $val) {
			@for $col from 1 through 12 {
				.#{$namespace}col-#{$key}-#{$col} {
					display: grid !important;
					grid-template-columns: repeat($col, 1fr) !important;
				}
			}
		}
}

@each $key, $val in ($breakpoints-map) {
	@media (min-width: $val) {	
		.#{$namespace}col-#{$key}-auto {
			display: grid !important;
			grid-template-columns: max-content 1fr;
		}
	}
}

$gap-map: (
    "100": $spacing100,
    "200": $spacing200,
    "300": $spacing300,
	"400": $spacing400,
	"450": $spacing450,
	"500": $spacing500,
	"550": $spacing550,
	"600": $spacing600,
	"650": $spacing650,
	"700": $spacing700,
	"750": $spacing750,
	"800": $spacing800,
	"825": $spacing825,
	"850": $spacing850,
	"900": $spacing900,
	"925": $spacing925,
	"950": $spacing950,
	"1200": $spacing1200
);

@each $key, $val in $gap-map {
	.#{$namespace}gap-#{$key} {
		gap: $val !important;
	}
}

.#{$namespace}no-gutters {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.#{$namespace}ellipsis {
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

$width-map: (
    "50": 50px,
    "100": 100px,
    "150": 150px,
    "200": 200px,
    "250": 250px,
    "300": 300px,
    "350": 350px,
    "400": 400px,
    "450": 450px,
    "500": 500px,
    "550": 550px,
);

@each $key, $val in $width-map {
	.#{$namespace}mw-#{$key} {
		min-width: $val !important;
	}
}

@each $key, $val in $width-map {
	.#{$namespace}width-#{$key} {
		width: $val !important;
	}
}

$float-map: (
    "left": left,
    "right": right,
    "none": none
);

@each $key, $val in $float-map {
	.#{$namespace}float-#{$key} {
		float: $val !important;
	}
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
	.#{$namespace}bs-container {
	  @include make-container();
	  @include make-container-max-widths();
	}
  }
  
  // Fluid container
  //
  // Utilizes the mixin meant for fixed width containers, but with 100% width for
  // fluid, full width layouts.
  
  @if $enable-grid-classes {
	.#{$namespace}bs-container-fluid {
	  @include make-container();
	}
  }
  
  // Row
  //
  // Rows contain and clear the floats of your columns.
  
  @if $enable-grid-classes {
	.#{$namespace}bs-row {
	  @include make-row();
	}
  
	// Remove the negative margin from default .row, then the horizontal padding
	// from all immediate children columns (to prevent runaway style inheritance).
	.#{$namespace}bs-no-gutters {
	  margin-right: 0;
	  margin-left: 0;
  
	  > .#{$namespace}bs-col,
	  > [class*="bs-col-"] {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  // Columns
  //
  // Common styles for small and large grid columns
  
  @if $enable-grid-classes {
	@include make-grid-columns();
  }
  