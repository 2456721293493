// REVIEW: missing spacing, border-radius and color variables
@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/transitions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use "../variables/content" as *;
@use "../variables/dataviz" as *;
@use "../mixins/typography" as *;
@use "../mixins/button" as *;
@use '../variables/zindex' as *;


.#{$namespace}modal-process {
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: $zindex700;
	width: 408px;
	padding: $spacing600 $spacing700 $spacing700 $spacing700;
	transition: transform 0.5s ease-out, opacity 0.2s ease-out;
	&-container {
		max-width: 340px;
	}
	&-icon {
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			display: flex;
			width: 40px;
			height: 40px;
			padding: 8px;
			text-align: center;
			align-items: center;
			justify-content: center;
			color: $neutral800;
		}
	}
	&-titles {
		max-width: calc(100% - 56px);
	}
	&-title-fail {
		color: $error600 !important;
		line-height: 20px;
	}
	&-text-desc-fail {
		color: $neutral900;
		line-height: 20px;
	}
	&-progress {
		.#{$namespace}progress-bar {
			.progress-bar {
				background-color: $skyblue500 !important;
				background-image: none !important;
			}
		}
		.#{$namespace}modal-process-step, .#{$namespace}modal-process-percentage {
			color: $info500 !important;
			
		}

	}
	&-success {
		.#{$namespace}progress-bar {
			.progress-bar {
				background-color: $success600 !important;
				background-image: none !important;
			}
		}
		.#{$namespace}modal-process-step {
			color: $success700 !important;
		}
		.#{$namespace}modal-process-text-warning {
			color: $warning600 !important;
		}

	}
	&-fail {
		.#{$namespace}progress-bar {
			.progress-bar {
				background-color: $dataviz-5 !important;
				background-image: none !important;
			}
		}
		.#{$namespace}modal-process-step {
			color: $error600 !important;
		}
		.#{$namespace}modal-process-title {
			color: $error600 !important;
		}
	}
	&-bar-success{
		background-color: $success600 !important;
		background-image: none !important;
	}
	&-bar-fail{
		background-color: $dataviz-5 !important;
		background-image: none !important;
	}
	&-bar-progress {
		background-color: $skyblue500 !important;
		background-image: none !important;
	}
	&-text {
		display: block;
	}
	&-text-success {
		color: $success700 !important;
	}
	&-text-progress {
		color: $skyblue500 !important;
	}
	&-text-fail {
		color: $error600 !important;
	}
	&-text-warning {
		color: $warning600 !important;
	}
	.mgbform {
		margin-bottom: 0;
	}
}

.#{$namespace}fade-out {
	transform: translateY(120%); // Mueve el elemento hacia abajo fuera de la ventana
}


.#{$namespace}dropzone {
    position: relative !important;
    width: calc(100% - 5px) !important;
	min-height: 50vh !important;
	&.#{$namespace}dropzone-default {
		min-height: initial !important;
		width: 100% !important;
		.#{$namespace}empty-drop {
			height: 100%;
			top: initial;
			padding-top: 0;
		}
	}
	.#{$namespace}empty-drop {
		padding-top: $spacing700;
		width: 100%;
		height: calc(100% - 80px);
		position: absolute;
		z-index: 999;
		max-width: 100%;
		max-height: 100%;
		border: 2px dashed $info500;
		top: 80px;
	}
}
