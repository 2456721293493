/*

	Buscador i tags

*/

.box_search           { position: relative; display: inline-block; width: 100%; min-height: 65px; padding-top: 20px }
.box_search label     { margin: 0 10px 5px 0 }
.box_search label.end { margin: 0 }

.box_search i.icon {
	font-size: 16px;
	color: #ABC0C4;
}

.box_search .inp_txt  {
	background: transparent;
	border: 0;
	width: auto;
	color: #2E383A;
	font-size: 14px;
	line-height: 24px;
}

.box_search .inp_txt:-moz-placeholder           { color: #8b989b }
.box_search .inp_txt::-moz-placeholder          { color: #8b989b }
.box_search .inp_txt:-ms-input-placeholder      { color: #8b989b }
.box_search .inp_txt::-webkit-input-placeholder { color: #8b989b }

.box_search .slc_srch {
	position: relative;
	width: 45%;
	height: 45px;
	padding-top: 5px;
	min-width: 300px;
	max-width: 45%;
	background-color: #ffffff;
	max-height: 40px;
	border: 1px solid #D5DFE1;
	padding: 6px 12px;
	border-radius: 4px;
}

.box_search .slc_srch:focus-within {
	border: 1px solid #3EAC93;
}

.box_search .box_srch_npt {
	height: 100%;
	position: absolute;
	left: 35px;
	right: 0;
	bottom: 0
}

.box_search .box_srch_npt .dropdown-menu p { padding: 6px 15px 8px; margin: 0; font-size: 90%; color: rgba(0,0,0,0.60);}

.box_search .slc_srch input {
	height: 100%;
	width: 100%;
	min-width: none;
	max-width: none;
}
.box_search .slc_srch                 { transition: all 0.15s ease 0s }
.box_search .slc_srch.md_mx           { width: 100%; max-width: 100% }
.box_search .slc_srch.focus i.sl-icon-search { color: #2E383A }

.box_list_ops .slc_srch       .btn-group             .dropdown-arrow { right: -5px; }
.box_list_ops .slc_srch       .btn-group.select      .dropdown-menu  { right: auto; left: -10px }
.box_list_ops .slc_srch.md_mx .btn-group.search-help .dropdown-menu  { left: auto; right: 0 }

.box_search .btn-group.select .dropdown-toggle       { height: 35px }

.box_search .btn-group.select.btn-more button.dropdown-toggle {
	margin-right: 2px;
}

.search-list,
.search-fields                  { position: absolute; height: 30px; left: 55px; right: auto }
.search-list   .dropdown-arrow,
.search-fields .dropdown-arrow  { left: 10px }
.search-list   a,
.search-list   a.active,
.search-fields a,
.search-fields a.active {
	-o-transition-property: none !important;
	-moz-transition-property: none !important;
	-ms-transition-property: none !important;
	-webkit-transition-property: none !important;
	transition-property: none !important;
}

.box_conn_srch .search-list,
.box_conn_srch .search-fields { top: 36px }
.box_conn_srch .search-list   ul,
.box_conn_srch .search-fields ul  { top: 30px; left: 0; right: auto }
.box_conn_srch .search-list   .dropdown-short li,
.box_conn_srch .search-fields .dropdown-short li,
.box_conn_srch .search-list   .dropdown-large li,
.box_conn_srch .search-fields .dropdown-large li  { margin-right: 0 }

.conn_ops_check .tagsinput-add-container,
.box_list_ops   .tagsinput-add-container    {display: none}

			.conn_ops_check .box_tags.op_create .tagsinput-add-container       { display: inline; margin-right: 10px }
            .conn_ops_check .box_tags.op_create .tagsinput-add-container input { min-width: auto; max-width: 150px }
.modal-body .conn_ops_check .box_tags.op_create .tagsinput-add-container input { min-width: 150px }

.conn_ops_check select.form-control.disabled,
.conn_ops_check select.form-control .disabled {display: none}

.conn_ops_check select .disabled {display: none}

.box_search .tagsinput      { margin-bottom: 0 }
.box_tags                   { position: relative; display: block; width: auto; float: right }
.form .box_tags,
.box_tags .tagsinput        { float: left }
.box_tags .btn { height: 40px }
.box_tags .btn-group.select { margin-bottom: 10px }
.box_tags .info             { color: rgba(0,0,0,0.25) }
.box_tags .btn_slc_logic {
	margin-right: 10px;
}

.form .box_id_tags,
.form .box_id_tags .tagsinput { float: none; width: auto; height: auto; min-height: 42px; overflow: visible; display: inline-block }

p.advs-modal-text{font-size: 16px; font-style: italic; margin-top:16px;}
.adv-search-actions        .dropdown-menu {max-width:175px; margin-top: 14px; z-index: 9 }
.adv-search-actions.open > .dropdown-menu {margin-top: 18px !important;}
.adv-search-actions        .dropdown-arrow {

	left:70px;
	margin-top:6px;
	border-style: solid;
	border-width: 0 10px 9px 10px;
	border-color: rgba(204,204,204,0) rgba(204,204,204,0) #ccc rgba(204,204,204,0);
	height: 0;
	opacity: 0;
	position: absolute;
	right: 13px;
	top: 100%;
	width: 0;
	z-index: 10;
	-webkit-transform: rotate(360deg);
	-webkit-transition: 0.25s;
	transition: 0.25s;
}
.adv-search-actions.open > .dropdown-arrow {margin-top:10px; opacity: 1;}
.adv-search-actions        .dropdown-arrow::after {

    content: " ";
    border-style: solid;
    border-width: 0 9px 9px 9px;
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
    position: absolute;
    right: -9px;
    top: 0;
    width: 0;
    height: 0;
    margin-top: 1px;
}
.adv-search-actions ul {border-radius: 6px;}

button#advanced-search i{
	color: #2E383A;
	position: relative;
	font-size: 16px;
	margin: 0;
}

button#advanced-search {
    padding: 2px;
    margin-right: 12px;
	border-radius: 4px;
	&:hover {
		background-color: #EAEEF0;
	}
}

.adv-new-tooltip .tooltip-inner {
	background-color: #2E383A;
    color: white;
	min-width:220px;
}

.adv-new-tooltip .tooltip-inner span{color:white;font-weight: bolder;}
.adv-new-tooltip.tooltip.top .tooltip-arrow{border-top-color: #FFF;margin-left: -5px;}
.adv-danger-tooltip .tooltip-inner{background-color:#e74c3c;color:#FFF;box-shadow:0px 4px 8px rgba(52, 73, 94, 0.15);}
.adv-danger-tooltip.tooltip.top .tooltip-arrow{border-top-color: #e74c3c;margin-left: -5px;}
#adv-search-conditions a{cursor:pointer;}
#adv-search-conditions .asr_icon{text-align:right;margin-top:4px;padding-left:0px;padding-right:8px;}
#adv-search-conditions .asr_icon a{color:#CFDADD;}
#adv-search-conditions .asr_icon a.act_move{cursor:grab;}
#adv-search-conditions .asr_icon a.act_trash:hover{color:#D2322C;}
#adv-search-conditions .asr_icon a.act_move:hover{color:#7B878E;}
#adv-search-conditions .asr_icon span{margin:0 5px; font-size: 24px;}

#adv-search-conditions .bool-operator {
	border-top: 1px solid #D5DFE1;
	margin: 4px 0 4px 0;
	height: 24px;
	text-align: center;
}

#adv-search-conditions .bool-operator-line 	{
	margin-top:-13px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 4px;
}

#adv-search-conditions .bool-operator a {
	display: inline-block;
	height: 24px;
	min-width: 24px;
	border: 1px solid transparent;
	margin:0 8px;
	text-align:center;
	padding: 4px 8px;
	color: #005583;
	background-color: #D7F3F9;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	border-radius: 4px;

	&:hover {
		background-color: #E9F9FB;
	}
}

#adv-search-conditions .bool-operator a.selected {
	background-color: #FBF2D0;
	color: #5A4B35;
}

#adv-search-conditions .condition-row{margin:0 -15px 28px -15px;}
#adv-search-conditions .condition-row select.form-control {
	width: 100%;
}
#adv-search-conditions .date-control, #adv-search-conditions .time-control, #adv-search-conditions .datetime-control {
	opacity: 1; 
	background: #fff; 
	border: 1px solid #D5DFE1; 
	color: #2E383A; 
	display: inline-block;
}
#adv-search-conditions .date-control {
	background: url('../../../img/icons/calendar.png') no-repeat; 
	background-position: 90%;
	background-size: 20px;
}
#adv-search-conditions .time-control {background: url('../../../img/icons/clock.png') no-repeat; background-position: 90%;}
#adv-search-conditions .datetime-control {
	background: url('../../../img/icons/calendar.png') no-repeat; 
	background-position: 97%;
	background-size: 20px;
}
#adv-search-conditions .date-control:focus, #adv-search-conditions .time-control:focus, #adv-search-conditions .datetime-control:focus {border-color: #387B77;}
#adv-search-conditions .numeric-control{width:auto;}
#adv-search-conditions .numeric-control-left {width:88%;float:left;}
#adv-search-conditions .numeric-control-right{width:12%;float:right;}
#adv-search-conditions .numeric-control.value-text{padding: 9px 0 0 5px;font-size:14px;color:#34495E;}
#adv-search-conditions .check_list{padding: 6px 0 0 0;}
#adv-search-conditions .value-slider{margin:15px 0 0 0;}
#adv-search-conditions .droppable-container{height:2px;}
#adv-search-conditions .droppable-container.droppable-active{height:45px;background-color: #EFEFEF;}
.last-droppable-container{margin:0 -15px 8px -15px;height:2px;}
.last-droppable-container.droppable-active{height:45px;background-color: #EFEFEF;}

#adv-search-conditions .field-container .dropdown-menu {
	max-height: 220px;
	overflow-y: auto;
	border-radius: 6px;
	margin-top:10px !important;
	/*min-width: fit-content;*/
}

#adv-search-conditions .field-container .dropdown-arrow{margin-top:1px;}
.select #advanced-search.btn .fui-gear {top:1px;color: #387B77;}
#adv-srch-save-container{display: inline-block;}
#adv-srch-save-container div{height: 22px;margin-top: 4px;top: -50%;display: block;position: absolute;left: 50%;margin-left: 25px;}
#adv-srch-save-container .dropdown-arrow{left:25px;margin-top:10px;}

#adv-srch-save-container a.btn {
	display:none;
	width: auto;
    padding: 8px 20px;
	background-color:transparent;
	color:#666666;
	border-radius: 4px;
	margin:0;
    margin-top: -12px;
	&:hover {
		background-color: #EAEEF0;
		span {
			color: #2E383A;
		}
	}
	&:active {
		background-color: #D5DFE1;
		span {
			color: #2E383A;
		}
	}
	&:focus {
		border: 1px solid #3EAC93;
	}
}



#adv-srch-save-container a.btn span.sl-icon-star-filled:before {
	color:#BAAA8A;
}

#adv-srch-save-container a.btn {
	height: 40px;
}

#adv-srch-save-container a.btn span{
	font-size: 14px;
    line-height: 24px;
	vertical-align: baseline;
	color :#2E383A;
	display: flex;
}

#adv-srch-save-container a.btn span:before {
	vertical-align: text-bottom;
	font-size: 20px;
	margin-right:8px;
	color:#2E383A;
}



#adv-srch-save-container a.svb-active {
	display:flex;
	align-items: center;
}

.adv-srch-dropdown li h5{
	font-size:14px;
	font-weight: 700;
	color:#2E383A;
	padding:3px 0 5px 15px;}

.adv-srch-dropdown li h6{
	font-size:14px;
	font-weight: normal;
	padding: 0px 0 5px 15px;
	color:#4C6569;
}

.adv-srch-dropdown li.moreinfo a{background-color: transparent;}
.adv-srch-dropdown li.moreinfo {
	span {
		color: #2E383A;
		font-size: 20px;
		vertical-align: text-top;
		margin-right: 10px;
	}
	a {
		&:hover {
			span {
				color: #3D9487;
			}
		}
	}
}
.adv-srch-dropdown li a.not_srch{cursor: pointer;}
.adv-srch-dropdown i.icon{font-size:14px;}
.adv-srch-ztop{z-index: 99999 !important;}
#field-lang-option-container{position: absolute; width:300px; height: 300px; border:1px solid #000; z-index: 999999;}
#adv-search-conditions .col-sm-2, #adv-search-conditions .col-sm-4, #adv-search-conditions .col-sm-5 {
	padding: 0 8px;
}

#adv-search-conditions .form-group div.field-container {
	padding: 0 8px 0 16px;
}

.adv_srch_popup_mess{margin:10px 0 0 0; color:#E74C3C;font-size:13px;}
#ui-datepicker-div{z-index: 99999 !important;}
.ui-datepicker:before{left: 50%;}
