
@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use '../variables/zindex' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;

.#{$namespace}popout {
    position: absolute;
    background-color: $background09;
    color: $contentInverse;
    @include generate-type("small"); 
    font-family: $fontOpenSans;
    padding: $spacing200 $spacing300;
    border-radius: $borderRadius300;
    width: max-content;
    max-width: 240px;
    word-break: break-all;
    opacity: 0;
    transform: translateY(0);
    transition: opacity 0.2s ease, transform 0.1s ease;
    pointer-events: none;
    z-index: 9;
    &--active {
        opacity: 1;
        transform: translateY(-100%);
    }
    &--hidden {
        display: none;
    }
}
