.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	border: 1px solid transparent;
	border-radius: 4px;
	cursor: pointer;
	font-weight: 600;
	outline: 0;
	transition: all 0.3s ease;
	font-size: 14px;
	line-height: 24px;
	padding: 8px 20px;
	width: fit-content;
	height: 40px;
	i {
		font-weight: normal;
		font-size: 16px;
	}
	i[class*="sl-icon-"].sl-icon-formula {
		font-weight: normal;
		font-size: 20px;
		margin-right: 8px;
	}

	&-primary,
	&-primary:focus,
	&-primary:active {
		background-color: #387B77;
    	color: #ffffff;
		border-color: transparent;
		&:hover {
			background-color: #3D9487;
			border: 1px solid transparent;
		}
	}

	&-inverse,
	&-default {
		background-color: #D5DFE1;
		color: #2E383A;
		border-color: transparent;
		&:focus {
			background-color: #EAEEF0;
			color: #2E383A;
			border: 1px solid transparent!important;
		}
		&:active {
			background-color: #EAEEF0;
			color: #2E383A;
			border: 1px solid transparent!important;
		}
		&:hover {
			background-color: #EAEEF0;
			color: #2E383A;
			border: 1px solid transparent!important;
		}
		&[disabled] {
			background-color: #EAEEF0;
			color: #93AFB4;
			cursor: default;
			opacity: 1;
			border-color: transparent;
		}
	}

	&-danger {
		background-color: #FDEDED;
		color: #D2322C;
		&:hover {
			color: #D2322C;
			background-color: #FAD7D6;
			border: 1px solid transparent;
		}
		&:focus {
			color: #D2322C;
			background-color: #FDEDED;
			border: 1px solid #3EAC93;
		}
	}
	&-warning {
		background-color: #D5DFE1;
		color: #2E383A;
		&:hover {
			background-color: #EAEEF0;
			color: #2E383A;
			border: 1px solid transparent;
		}
		&:focus {
			background-color: #D5DFE1;
			color: #2E383A;
			border: 1px solid #3EAC93;
			border-radius: 4px;
		}
	}

	&-tertiary,
	&-tertiary:focus,
	&-tertiary:active {
		background-color: transparent;
    	color: #2E383A;
		&:hover {
			background-color: #EAEEF0;
			color: #2E383A;
			border: 1px solid transparent;
		}
	}

	&-sm {
		height: 40px;
	}
	&-small {
		display: flex;
    	align-items: center;
		padding: 8px 16px;
    	font-size: 12px;
    	line-height: 16px;
		font-weight: 600;
    	border-radius: 4px;
		height: 32px;
		i {
			font-size: 16px;
			font-weight: 400;
		}
	}
	&-xs {
		display: flex;
    	align-items: center;
		padding: 4px 10px;
    	font-size: 12px;
    	line-height: 16px;
		font-weight: 600;
    	border-radius: 4px;
		height: 24px;

		i {
			font-size: 14px;
			font-weight: 400;
		}
	}

	&-xl {
		padding: 6px 10px;
		i {
			font-size: 20px;
		}
	}

	.icon-left {
		margin-right: 4px;
	}
}

.input-group-btn .btn-default {
	background-color: #D5DFE1;
	color: #2E383A;
	font-size: 14px;
	line-height: 24px;
	padding: 8px 16px;
	height: 40px;
	border: 1px solid #D5DFE1;
	&:hover {
		background-color: #EAEEF0;
		color: #2E383A;
		border: 1px solid #EAEEF0;
	}
	&:focus {
		background-color: #D5DFE1;
		color: #2E383A;
		border: 1px solid #D5DFE1;
	}
	&:active {
		background-color: #CFDADD;
		color: #2E383A;
		border: 1px solid #CFDADD;
	}
	&.sl-btn-icon-only {
		padding: 10px;
	}
}

.btn-wide {
    min-width: unset;
}

.btn-group > .dropdown-menu, .btn-group > .popover {
	font-weight: 600;
}

.input-group-btn:last-child > .btn {
	border-left-width: 1px;
}

.input-group-btn > .btn-default + .btn-default {
	border-left: 1px solid #D5DFE1;
}

.input-group.focus .input-group-btn .btn,
.form-group.focus .input-group-btn .btn {
	border-color: #3EAC93;
	color: #FFFFFF;

	&:hover {
		background-color: #3D9487;
		border-color: #3D9487;
	}
}

.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
  background-color: #D5DFE1;
  border-color: #D5DFE1;
  color: #4C6569;
}


.input-group.focus .input-group-btn .btn:hover {

	background-color: #EAEEF0;
	border-color: #EAEEF0;
	color: #4C6569;
}

.input-group .form-control:first-child {
	border-right-width: 1px;
}

.input-group-btn:last-child > .btn {
	margin-left: 0px;
}

.btn-group.open .dropdown-toggle {
	color: #2E383A!important;
}

.btn-group.open.select.md_highlight button.dropdown-toggle {
	color: white!important;
}

.btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
	background: #CFDADD;
	border-color: #3EAC93;
	color: #2E383A;
}

.input-group.focus .input-group-btn .btn span {
  border-color: #3EAC93;
  color: #2E383A;
}

.mrm, .mhm, .mam {
	margin-right: unset !important;
}

.fui-checkbox-unchecked:before {
	font-family: 'sally-icon';
    content: "\e91a"!important;
	color: #3D9487!important;
}

.fui-checkbox-checked:before {
	font-family: 'sally-icon';
    content: "\e91b"!important;
}


.fui-radio-unchecked,
.fui-radio-checked {
  position: relative;
}

.fui-checkbox-unchecked:before {
  font-family: "sally-icon";
  content: "\e91a" !important;
  color: #CFDADD !important;
}

.fui-checkbox-checked:before {
  font-family: "sally-icon";
  content: "\e91b" !important;
}

.fui-radio-unchecked:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border: solid 1px #D5DFE1;
  border-radius: 50%;
  left: 0;
  top: calc(50% - -3px);
}

.radio:not(.disabled):hover .fui-radio-unchecked,
.radio:not(.disabled):hover .fui-radio-checked {
	transition: color 0.25s linear;
  	color: #3D9487 !important;
}
.radio:not(.disabled):hover .fui-radio-unchecked:before,
.radio:not(.disabled):hover .fui-radio-checked:before {
	transition: color 0.25s linear;
  	border-color: #3D9487 !important;
}


.radio.disabled .fui-radio-unchecked,
.radio.disabled .fui-radio-checked {
  color: #EAEEF0 !important;
}
.radio.disabled .fui-radio-unchecked:before,
.radio.disabled .fui-radio-checked:before {
  border-color: #EAEEF0 !important;
}

.radio.disabled .fui-radio-checked:before {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: #ABC0C4;
	border: solid 4px #EAEEF0;
	border-radius: 50%;
	color: #EAEEF0;
	left: 0;
	top: calc(50% - -3px);
}

.radio.disabled .fui-radio-unchecked:before {
  content: "";
	position: absolute;
    width: 16px;
    height: 16px;
    background-color: #EAEEF0;
	border: solid 0px #EAEEF0;
    border-radius: 50%;
    left: 0;
	top: calc(50% - -3px);
}

.fui-radio-unchecked {
  color: #CFDADD !important;
}

.fui-radio-checked {
  color: #387B77 !important;
}

.fui-radio-checked:before {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: white;
	border: solid 4px #387B77;
	border-radius: 50%;
	left: 0;
	top: calc(50% - -3px);
}

.icons span {
    font-size: 20px!important;
    line-height: unset;
}

.checkbox,
.radio {
	margin-bottom: 12px;
	padding-left: 28px;
	position: relative;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
	font-size: 14px;
	line-height: 1.5;
}

.checkbox {
	line-height: 1.4;
}
.checkbox input,
.radio input {
	outline: none !important;
	display: none;
}
.checkbox .icons,
.radio .icons {
	color: #CFDADD;
	display: block;
	height: 20px;
	left: 0;
	position: absolute;
	top: 0;
	width: 20px;
	text-align: center;
	line-height: 21px;
	font-size: 20px;
	cursor: pointer;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}
.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
	display: inline-table;
	position: absolute;
	left: 0;
	top: 0;
	background-color: transparent;
	margin: 0;
	opacity: 1;
	filter: alpha(opacity=100);
}
.checkbox .icons .second-icon,
.radio .icons .second-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}
.checkbox:hover,
.radio:hover {
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}
.radio:hover .first-icon {
	opacity: 1;
	filter: alpha(opacity=100);
}
.radio:hover .second-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}
.checkbox:hover .first-icon:before,
.checkbox:hover .second-icon:before {
	color: #3D9487 !important;
}
.checkbox:focus .first-icon::before {
	color: #3EAC93;
}
.checkbox.checked:focus .second-icon {
	z-index: -1;
}

.checkbox.checked,
.radio.checked {
	color: #2E383A;
}

label.checkbox:before {
	background: white;
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 2px;
	width: 16px;
	height: 16px;
	content: "";
}

label.checkbox.checked:before {
	background: transparent;
}

label.checkbox.disabled:before,
label.checkbox.disabled:hover:before {
	background: #EAEEF0;
}

label.checkbox.checked.disabled:before,
label.checkbox.checked.disabled:hover:before {
	background: #ABC0C4;
    z-index: 1;
}

label.checkbox.disabled .first-icon:before,
label.checkbox.checked.disabled .second-icon:before,
label.checkbox.checked.disabled:hover .second-icon:before {
	color: #EAEEF0 !important;
}

label.checkbox.disabled:hover .first-icon:before {
	color: #3EAC93 !important;
}

label.checkbox .first-icon {
	z-index: 3;
}

label.checkbox .second-icon {
	z-index: 2;
}

label.checkbox.disabled:hover .first-icon,
label.checkbox.checked .first-icon {
	opacity: 0;
}

label.checkbox.checked:focus .first-icon {
	opacity: 1;
}

.radio.checked .first-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}
.checkbox.checked .second-icon,
.radio.checked .second-icon {
	opacity: 1;
	filter: alpha(opacity=100);
	color: #387B77;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}
.radio.disabled {
	cursor: default;
	color: #e6e8ea;
}
.radio.disabled .icons {
	color: #e6e8ea;
}
.radio.disabled .first-icon {
	opacity: 1;
	filter: alpha(opacity=100);
}
.radio.disabled .second-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}
.radio.disabled.checked .icons {
	color: #e6e8ea;
}
.radio.disabled.checked .first-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}
.radio.disabled.checked .second-icon {
	opacity: 1;
	filter: alpha(opacity=100);
	color: #e6e8ea;
}

.checkbox.disabled.checked .second-icon,
.checkbox.disabled .first-icon,
.checkbox.disabled .second-icon {
	color: #EAEEF0;
}

.checkbox.disabled.checked .second-icon,
.checkbox.disabled .first-icon,
.checkbox.disabled .second-icon {
	color: #EAEEF0;
}

.checkbox.primary .icons,
.radio.primary .icons {
	color: #34495e;
}
.checkbox.primary.checked,
.radio.primary.checked {
	color: #387B77;
}
.checkbox.primary.checked .icons,
.radio.primary.checked .icons {
	color: #387B77;
}
.checkbox.primary.disabled,
.radio.primary.disabled {
	cursor: default;
	color: #D5DFE1;
}
.checkbox.primary.disabled .icons,
.radio.primary.disabled .icons {
	color: #D5DFE1;
}
.checkbox.primary.disabled.checked .icons,
.radio.primary.disabled.checked .icons {
	color: #D5DFE1;
}
.radio + .radio,
.checkbox + .checkbox {
	margin-top: 10px;
}

.border-active {
	border: 1px solid #3D9487!important;
}

.dropdown-menu {
	min-width: 128px!important;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 15px rgb(128 128 128 / 25%);
    padding: 12px 0 12px 0;
}
.box_pagination.box_list_ops .btn-group.select .dropdown-menu{
	max-width: 300px;
}
.dropdown-menu li:first-child > a {
	border-radius: 0;
}

.dropdown-menu li > a {
	display: flex;
	font-size: 14px;
	padding: 8px 16px;
	margin: 0 8px;
	white-space: nowrap;
	align-items: center;
	height: 40px;
	i {
		font-size: 20px;
		line-height: 20px;
	}
}

.dropdown-menu li > a > span.pull-left {
	display: flex;
	align-items: center;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:active,
.dropdown-menu li > a:focus,
.dropdown-menu li > a:hover i,
.dropdown-menu li > a:active i,
.dropdown-menu li > a:focus i,
.box_list_ops .btn-group.select .dropdown-menu li a:hover i,
.box_list_ops .btn-group.select .dropdown-menu li a:active i,
.box_list_ops .btn-group.select .dropdown-menu li a:focus i {
	background-color: #F8F8F8;
    color: #3D9487;
	border-radius: 4px;
}

.dropdown-menu li > {
	a {
		&.sl-button-highlight-medium {
			color: #5A4B35;
			&:focus, &:hover, &:active {
				background-color: #F7EABB;
				color: #5A4B35;
				border-color: transparent;
				border-radius: 4px;
			}
			i {
				background-color: transparent;
				color: #5A4B35;
				border-color: transparent;
				border-radius: 4px;
			}
		}
	}
}

.dropdown-menu li > a.sl-button-highlight-medium,
.dropdown-menu li:last-child > a.sl-button-highlight-medium {
    border-radius: 4px;
}



.dropdown-menu li h5 {
	margin: 4px 0px 4px 8px;
}

.dropdown-menu li h6 {
	margin: 4px 0px 16px 8px;
}

.pagination ul {
	display: flex;
    background: #EAEEF0;
	background: #fff;
	border: 1px solid #D5DFE1;
	li {

		margin-right: 0px;
		border-right: 1px solid #D5DFE1;
		&:first-child {
			border-left: none;
		}
		&:last-child {
			border-left: none;
			border-right: none;
		}
	}
}

.pagination ul li:first-child > a, .pagination ul li:first-child > span {
    border-radius: 4px 0 0 4px;
}

.pagination ul li:last-child > a, .pagination ul li:last-child > span, .pagination ul li:last-child > a:hover, .pagination ul li:last-child > span:hover, .pagination ul li:last-child > a:focus, .pagination ul li:last-child > span:focus {
    border-radius: 0 4px 4px 0;
}

.pagination ul li > a, .pagination ul li > span {
	color: #2E383A;
	min-height: unset;
	border-left: none;
    min-width: 39px;
    height: 38px;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
}

.pagination ul li > a:hover,
.pagination ul li > span:hover,
.pagination ul li > a:focus,
.pagination ul li > span:focus,
.pagination ul li.active > a:hover,
.pagination ul li.active > span:hover,
.pagination ul li.active > a:focus,
.pagination ul li.active > span:focus {
	color: #2E383A;
	background-color: #EAEEF0;

}

.pagination ul li.active > a, .pagination ul li.active > span {
    background-color: #D5DFE1;
    color: #2E383A;
	border-radius: unset;
}

.progress-bar-warning {
	background-color: #FFD9A0;
}

a {
	color: unset;
}

a:hover,
a:focus {
	color: unset;
}

.select .btn .filter-option {
    height: unset;
    left: unset;
    overflow: unset;
    position: unset;
    right: unset;
    text-align: unset;
    top: unset;
}

.nav-tabs {
	margin-bottom: 20px;
	border: none;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	padding: 6px 16px 0 0;
	display: flex;
	align-items: center;
}

.nav-tabs > li {
	margin-bottom: -3px;
	border-bottom: 2px solid transparent;
	padding-right: 0;
}

.nav-tabs > li > a {
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	cursor: pointer;
	border-bottom: 1px solid transparent;
	color: #4C6569;
	border-width: 2px;
	font-weight: normal;
}

.nav-tabs > li:hover {
	> a {
		color: #2E383A;
	}
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	color: #3D9487;
	background: none;
}

.nav-tabs > li.disabled.disabledTab  {
	cursor: not-allowed;
}

.nav > li.disabled > a,
.nav > li.disabled > a:hover {
	color: #ABC0C4;
}

.crumbs .nav-tabs > li.active,
.crumbs .nav-tabs > li.active:hover,
.crumbs .nav-tabs > li.active:focus {
	border-color: transparent;
	border-bottom: 2px solid #3D9487;
	color: #3D9487;
	background: transparent;
	font-weight: normal;
}

.nav-tabs > li.active,
.nav-tabs > li.active:hover,
.nav-tabs > li.active:focus {
	border-color: transparent;
	border-bottom: 2px solid #3D9487;
	color: #3D9487;
	background: transparent;
	font-weight: normal;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border: none;
	color: #3D9487;
	background: transparent;
	font-weight: normal;
}

.modal-content .modal-body .nav-accordion,
.form .sl_form_edit_group ul.nav-accordion,
.nav-accordion {
	font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border: 1px solid #D5DFE1;
    border-radius: 4px;
    background-color: white;
    padding: 8px 0;
    color: #2E383A;
	+.page_fields {
		padding: 0 16px;
	}
	&.quick_edit_form {
		margin-bottom: 16px;
	}
	.makefield_ops.dropdown {
		margin-left: -8px;
	}
	&.nav-tabs {
		> li {
			padding: 0;
			margin-right: 0px;
			a {
				padding: 0 16px;
				.sl-icon-chevron-up, .sl-icon-chevron-down {
					font-size: 24px;
				}
				label {
					font-weight: 600;
				}
			}
		}
	}
	> li {
		width: 100%;
		margin-bottom: -2px;
		a {
			display: flex;
			justify-content: space-between;
			padding: 8px 16px;
			button {
				flex: 5;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				i {
					font-size: 24px;
					color: #2E383A;
					padding: 0!important;
					margin: 0!important;
				}
				&.btn-clean.check {
					margin-right: 0px;
					min-height: 40px;
					position: relative;
				}
			}
			label {
				margin: 0!important;
			}
		}
	}
	> div {
		padding-right: 16px;
	}
	> li.active,
	> li.active:hover {
		border-bottom: none;
	}

	&:hover {
		background-color: #fff;
		border: 1px solid #CFDADD;
	}

	&.is_open {
		border-color: #3EAC93;
		border-bottom-color: #3EAC93 !important;
	}
}


.alert-container-blank {
	background-color: #fff;
	padding: 20px 44px 0 44px;
    margin-bottom: -20px;
	&.alert-container-blank-no-gutters {
		margin-bottom: 0;
		padding-top: 0;
	}
}


.alert {
	border-radius: 4px;
	padding: 16px 48px 16px 16px;
	position: relative;
	font-size: 14px;
	line-height: 24px;
	ul {
		margin-bottom: 0;
		li {
			margin-top: 8px;
			line-height: 24px;
		}
	}
	.alert-cont {
		display: flex;
		align-items: flex-start;
	}
	& + #slyr_conf_slc_ops + .box_list_max {
		margin-top: 40px;
	}
	a {
		font-weight: normal;
		text-decoration: underline;
	}
	h4 {
		font-size: 14px;
		line-height: 24px;
	}

	.alert-title {
		font-weight: bold;
	}
	h6 {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 700;
		display: inline-block;
		margin-bottom: 4px;
		line-height: 24px;
	}
	h6:not(.inactive-service--title) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&.alert-no-alert {
		h6 {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			padding-bottom: 5px;
			.icon {
				font-size: 20px;
				margin-right: 14px;
			}
		}
	}
	span.legacy-h7 {
		font-size: 14px;
		line-height: 24px;
	}
	.icon {
		font-size: 24px;
		line-height: 24px;
		margin-right: 12px;
		font-weight: normal;
		&[class*="sl-icon"] {
			position: relative;
			margin-right: 12px;
			min-width: 24px;
			&:before {
				position: absolute;
				display: contents;
			}
		}
		&.sl-icon-message-round {
			font-size: 32px;
			line-height: 32px;
		}
	}

	.sl-icon-close {
		font-size: 16px;
	}

	.close {
		position: absolute;
		top: 16px;
		right: 16px;
		font-size: 16px;
		line-height: 16px;

		&:hover {
			opacity: 1;
		}
	}

	.btn-close-ok {
		color: #4C6569;
		text-decoration: underline;
		cursor: pointer;
		padding-left: 47px;
		margin-top: 12px;
	}

	&.alert-success {
		border: 1px solid #9BE8A3;
		background-color: #E6FAE8;
		color: #05570A;

		.close,
		.close:hover {
			color: #05570A;
		}
	}

	&.alert-info {
		border: 1px solid #BAEBF5;
		background-color: #E9F9FB;
		color: #005583;

		.close,
		.close:hover {
			color: #005583;
		}
		h6.inactive-service--title {
			color: #005583;
		}
	}

	&.alert-warning {
		border: 1px solid #FFD9A0;
		background-color: #FFF1DB;
		color: #653600;

		.close,
		.close:hover {
			color: #653600;
		}
	}

	&.alert-danger {
		border: 1px solid #F7BCBA;
		background-color: #FDEDED;
		color: #55201E;

		.close,
		.close:hover {
			color: #55201E;
		}
	}

	&.alert-error {
		border: 1px solid #F7BCBA;
		background-color: #FDEDED;
		color: #55201E;

		.close,
		.close:hover {
			color: #55201E;
		}
	}

	&.alert-msg {
		border: 1px solid #EEE5C0;
		background-color: #FCF8ED;
		color: #2E383A;
	}
}

.modal-body {
	.crumbs_formula_conn {
		&+ .alert {
			.icon {
				&.sl-icon-close {
					margin-right: 0;
				}
			}
		}
	}
}


.alert.alert-no-alert {
	border-radius: 0px;
	padding: 0px;
	text-shadow: none;
	background-color: transparent;
	border: none;
    margin-bottom: 20px;
	>i.icon.ini.glyphicons.ok {
		display: none;
	}
	> i.sl-icon-info {
		display: none;
	}
	> i.sl-icon-warning {
		display: none;
	}
	> i.sl-icon-error {
		display: none;
	}
	h5 {
		font-size: 20px;
		line-height: 28px;
		color: #2E383A;
	}
	.icon {
		color: #ABC0C4;
	}
}

.dropdown-menu .divider,
.btn-group.select .dropdown-menu .divider {
	max-width: 90%;
	margin: 0 auto;
    background-color: #D5DFE1;
}

.table {
    overflow: auto;
    width: 100%;
    border-collapse: separate;
    border: 1px solid #D5DFE1;
    border-radius: 8px;
    background: white;
	tr th {
		font-size: 12px;
		line-height: 20px;
		color: #4C6569!important;
		font-style: normal;
		font-weight: 600;
		text-align: left;
		vertical-align: middle;
		text-transform: uppercase;
		padding: 22px 16px;
	}
	> * > tr > td,
	> * > tr > th {
		padding: 0 16px;
		height: 64px;
		border-top: 1px solid #D5DFE1!important;
		min-width: 32px!important;
	}

	> thead > tr > th {
		border-bottom: none!important;
		border-top: none!important;
	}

	&.sl-mb-600 {
		margin-bottom: 24px !important;
	}
}

.table .label {
	font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    max-height: 24px;
		font-weight: 600;
}


.label-primary,
.label-primary[href],
.label-primary[href]:hover,
.label-inverse,
.label-inverse[href],
.label-inverse[href]:hover {
	background-color: #FBF2D0;
    color: #5A4B35;
}

.label-primary:focus,
.label-primary[href]:focus,
.label-inverse:focus,
.label-inverse[href]:focus {
	background-color: #FBF2D0;
    color: #5A4B35;
}

.breadcrumb-text {
	margin-bottom: 4px;

	p {
		display: flex;
		font-size: 14px;
		line-height: 24px;
		color: #4C6569;
		font-weight: 400;
		margin-bottom: 0;

		a:last-child {
			font-weight: 600;

			&::after {
				display: none;
			}
		}

		a:after {
			color: #4C6569;
			font-family: "sally-icon";
			content: "\e91e";
			font-weight: 400;
			margin: 0 12px 0 12px;
		}
	}
}

.glyphicons.exclamation-sign:before {
	font-family: "sally-icon";
	content: "\e92d";
}

.dropdown-menu li > a {
	color: #2E383A;
}

.dropdown-menu li.active > a,
.dropdown-menu li.selected > a,
.dropdown-menu li.active > a.highlighted,
.dropdown-menu li.selected > a.highlighted {
	background-color: #F8F8F8;
  color: #3D9487;

	&:hover, &:active, &:focus {
		background-color: #F8F8F8;
  		color: #3D9487;
	}
}

.open > .dropdown-menu {
	margin-top: 8px!important;
}

.col-fld {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

label {
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	color: #4C6569;
	margin: 0 8px 8px 0;
}
label strong {
	font-weight: 600;
}

.form-control {
	border: 1px solid #D5DFE1;
	color: #2E383A;
	font-size: 14px;
	line-height: 24px;
	padding: 8px 12px;
	height: 40px;
	border-radius: 4px;
	outline: none;
}

.form-group {
	margin-bottom: 24px;
}

.form-group.focus .form-control,
.form-control:focus {
	border: 1px solid #3EAC93;
}

.form-group.focus .form-control,
.form-control:focus-visible {
	border: 1px solid #3EAC93;
}

.not_apply .form-control {
	background-color: #EAEEF0;
	color: #ABC0C4;

	&:hover {
		border: 1px solid #D5DFE1;
	}
}

.has-error .form-control {
	color: #D2322C;
	border-color: #D2322C;
}

.has-switch > div.switch-on label,
.has-switch > div.switch-off label {
	background-color: white;
    border-radius: 50%;
	top: -2px;
}

.has-switch {
	width: 42px;
	height: 25px;
}

.has-switch > div.switch-on {
    left: -36px;
}

.has-switch > div.switch-off {
    left: -52px;
}

.has-switch > div label {
	border-width: 6px;
	transition: unset;
	cursor: pointer;
}

.has-switch > div.switch-on label {
	border-color: #387B77;
}

.has-switch > div.switch-off label {
	border-color: #ABC0C4;
}

.has-switch span.switch-left {
	background-color: #387B77;
}

.has-switch span.switch-right {
	background-color: #ABC0C4;
}

.has-switch span.switch-left .fui-check:before {
	content:"";
}

.has-switch span.switch-right .fui-cross:before {
	content:"";
}

.has-switch > div.switch-animate {
	transition: unset;
}

.not_valid {
	background: #ffffff !important;
	color: #55201E !important;
	border-color: #D2322C !important;
	margin-left: 0px !important;
}

.input-group .form-control:last-child {
	border-left: none;
}

.tooltip {
	font-size: 14px;
	line-height: 1.286;
}

.tooltip.in {
	opacity: 1;
}





.tooltip.top {
	padding-bottom: 0px;
	margin-top: -8px;
}

.tooltip.top .tooltip-arrow {
	display: none;
}

.tooltip.right .tooltip-arrow {
	display: none;
}

.tooltip.bottom {
	padding-top: 0px;
}

.tooltip.bottom .tooltip-arrow {
	display: none;
}

.tooltip.left .tooltip-arrow {
	display: none;
}

.tooltip-inner {
    background-color: #2E383A;
    color: white;
    font-size: 12px;
    line-height: 20px;
    padding: 8px 12px;
    border-radius: 4px;
	width: max-content !important;
    max-width: 240px !important;
    z-index: 900;
    word-break: break-word;
    text-align: left;
}

.tooltip-light .tooltip-inner {
    background-color: #2E383A;
    color: white;
}

.alert-info a {
	color: #005583;
}

@keyframes progress-bar-stripes {
	from {
		background-position: -100% 0;
	}
	to {
		background-position: 200% 0;
	}
}

.progress-bar, .progress {
	background: #EAEEF0;
	height: 8px;
	max-width: 480px;
}

.progress-striped .progress-bar {
	background-image: linear-gradient(90deg, rgba(123, 155, 161, 1) 100%, transparent );
}

.progress.active .progress-bar, .progress-bar.active {
    height: 8px;
    border-radius: 4px;
}

.progress-bar-success,
.progress-bar-warning,
.progress-bar-danger,
.progress-bar-info {
	background-color: #9DB2FF;
}

.has-warning .form-control {
	border-color: #F7C983;

	&:focus {
		border-color: #3EAC93;
	}
}

.table .selected-row > td {
	background-color: transparent;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: transparent;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr.selected-row > td,
.table-striped > tbody > tr:nth-child(even) > th,
.table-striped > tbody > tr.selected-row > th {
	background-color: transparent;
}

.table-striped > tbody > tr:nth-child(even):hover > td,
.table-striped > tbody > tr.selected-row:hover > td,
.table-striped > tbody > tr:nth-child(odd):hover > td,
.table .selected-row:hover > td {
	background-color: #F8F8F8;
}

.table tbody tr:last-child td:last-child {
	border-radius: 0px 0px 8px 0px;
}

.table tbody tr:last-child td:first-child {
	border-radius: 0px 0px 0px 8px;
}

.label-info {
	background-color: #D7F3F9;
  color: #5A4B35;
}

.label-warning {
	background-color: #FBF2D0;
	color: #653600;
}

.label-info-2 {
	background-color: #D7F3F9 !important;
	color: #005583 !important;
}

.label {
	border-radius: 4px;
}

.label-success {
	background-color: #BFF5C5;
	color: #05570A;
}

.label-danger {
	color: #55201E;
	background-color: #F7BCBA;
}

.label-neutral {
	color: #2E383A;
	background-color: #D5DFE1;
}

.btn-inverse:hover, .btn-inverse.hover {
	background: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}

.btn-inverse.active, .open .dropdown-toggle.btn-inverse, .btn-inverse.active, .open .dropdown-toggle.btn-inverse {
	background: #D5DFE1;
  color: #2E383A;
  border-color: transparent;
}

.btn-inverse:focus {
	background: #D5DFE1;
  color: #2E383A;
  border-color: #3EAC93;
}

.btn-inverse:active {
	background: #CFDADD;
	color: #2E383A;
	border-color: transparent;
}

.iconlight-color {
	color: #CFDADD;
}

.sync_li {
	color: #4C6569;
	font-size: 14px;
    display: flex;
    align-items: center;
	margin-bottom: 12px;
}

.sync_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 4px 0 12px 0;
}

.input-group span.select2 {
    border-bottom-right-radius: 0!important;
    border-top-right-radius: 0!important;
}

.input-group.conn_perm_input span.select2 {
	border-bottom-right-radius: 4px!important;
    border-top-right-radius: 4px!important;
}