/* Workflow messages */

.wkf_msgs {
    padding: 0 44px;
}

form .wkf_msgs_hdr                { 
    font-size: 13px; 
    line-height: 16px; 
    font-style: italic; 
    color: rgba(0,0,0,0.5); 
    padding: 0px 44px; 
}

form .wkf_msgs_hdr .btn           { font-style: normal }
form .wkf_msgs_hdr .btn-xs        { padding: 3px 6px }
form .wkf_msgs .alert             { font-size: 12px; line-height: 20px }
form .wkf_msgs .alert label       { 
    font-size: 12px;
    line-height: 14px; 
    color: #2E383A;
}
form .wkf_msgs .alert label .icon { color: #BAAA8A; top: 10px; }
form .wkf_msgs .alert .alert-msg-button { margin-left: 25px; margin-top: 12px!important; }
form .wkf_msgs .alert             { color: rgba(0,0,0,0.7) }

/*

    ## Caixa Workflows info

*/

#box_wrkflw {
    position: fixed;
    z-index: 5;
    top: 80px;
    left: 224px;
    right: 0;
    min-height: 60px;
    padding: 24px 44px 16px 44px;
    overflow: visible;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D5DFE1;
    display: flex;
}

#box_wrkflw.hist_vw_act {
    left: 524px;
}

#box_wrkflw .btn-wkf-group-revision {
  min-width: 76px;
  #btn_wkf_act_pr {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    padding: 8px;
  }
  #btn_wkf_act_rv {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    padding: 8px;
  }
  .btn-tertiary {
    border: 1px solid #D5DFE1;
  }
}

.minus_left #box_wrkflw {
    left: 80px
}

.minus_left #box_wrkflw.hist_vw_act {
    left: 380px;
} 

#box_wrkflw .btn-group.rgt          { float: right }
#box_wrkflw .btn-group.mrg_rgt      { margin-right: 10px }

#box_wrkflw .btn {
    height: 32px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;

    &:disabled {
        border: 1px solid transparent;
        background-color: #D5DFE1;
        color: #2E383A;
    }
}

#box_wrkflw .icon {
    font-size: 16px;
    line-height: 16px;
}

#box_wrkflw .btn .icon              { padding-right: 0 }

#box_wrkflw .btn em {
    display: none;
    font-style: normal;
    font-weight: 600;
}

#pop_wkfl_sign, #sign_gpr_st {
    .check_list {
        label {
            margin: 4px 0 8px 0;
        }
    }
}
    
#box_wrkflw .btn-group button {     
  margin: 0px 4px 8px 4px;
}

.btn-wkf-warning {
    background-color: #FBF2D0;
    color: #5A4B35;
  }
  .btn-wkf-warning:hover {
    background-color: #FCF8ED;
    color: #5A4B35;
    border: 1px solid transparent;
  }
  .btn-wkf-warning:focus {
    background-color: #FCF8ED;
    color: #5A4B35;
    border: 1px solid #3EAC93;  
  }
  .btn-wkf-success {
    background-color: #BFF5C5;
    color: #05570A;
  }
  .btn-wkf-success:hover {
    background-color: #E6FAE8;
    color: #05570A;
    border: 1px solid transparent;
  }
  .btn-wkf-success:focus {
    background-color: #E6FAE8;
    color: #05570A;
    border: 1px solid #3EAC93;
  }
  .btn-wkf-danger {
    background-color: #F7BCBA;
    color: #55201E;
  }
  .btn-wkf-danger:hover {
    background-color: #FAD7D6;
    color: #55201E;
    border: 1px solid transparent;
  }
  .btn-wkf-danger:focus {
    background-color: #FAD7D6;
    color: #55201E;
    border: 1px solid #3EAC93;  
  }
  .btn-wkf-blocked {
    background-color: #EAEEF0;
    color: #ABC0C4;
  }
  .btn-wkf-blocked:hover {
    background-color: #EAEEF0;
    color: #ABC0C4;
    border: 1px solid transparent;
  }
  .btn-wkf-blocked:focus {
    background-color: #EAEEF0;
    color: #ABC0C4;
    border: 1px solid #3EAC93;  
  }
  .btn-wkf-default {
    background-color: #D5DFE1;
    color: #2E383A;
  }
  .btn-wkf-default:hover {
    background-color: #EAEEF0;
    color: #2E383A;
    border: 1px solid transparent;
  }
  .btn-wkf-default:focus {
    background-color: #EAEEF0;
    color: #2E383A;
    border: 1px solid #3EAC93;  
  }


form {
  .wkf_msgs_hdr {
    margin-top: 44px;
    cursor: pointer;
    color: #2E383A;
    .wkf_msgs_hdr_in {
      display: flex;
      align-items: center;
    }
    a {
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      i.sl-icon-message-round {
        font-size: 20px;
        color: #2E383A;
      }
      .view_link {
        color: #387B77;
        font-weight: 600;
      }
    }
  }
  .wkf_msgs .alert {
    padding: 20px;
    p, label {
      color: #2E383A;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      strong {
        font-weight: 600;
      }
    }
    .btn-close-ok {
      padding-left: 0;
      display: block;
      font-weight: 600;
      color: #4C6569;
      text-decoration: underline;
    }
    .col-auto.sl-no-gutters {
      padding-left: 0;
      padding-right: 0;
    }
    .icon[class*=sl-icon] {
      margin-right: 0;
      &.sl-icon-message-round {
        color: #BAAA8A;
      }
    }
    a {
      text-decoration: none;
    }
  }
}