/*

	Formularios

*/

#box_form_content {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    background-color: #fff !important;
    -webkit-text-fill-color: #34495e; color: #34495e !important;
}

/* Reajuste de las cajas de campos en resoluciones pequeñas */


.form-group .col-sm-1,
.form-group .col-sm-2,
.form-group .col-sm-3,
.form-group .col-sm-4,
.form-group .col-sm-5,
.form-group .col-sm-6,
.form-group .col-sm-7,
.form-group .col-sm-8,
.form-group .col-sm-9,
.form-group .col-sm-10,
.form-group .col-sm-11                { padding-top: 15px }/*
.form-group .col-sm-1:first-of-type,
.form-group .col-sm-2:first-of-type,
.form-group .col-sm-3:first-of-type,
.form-group .col-sm-4:first-of-type,
.form-group .col-sm-5:first-of-type,
.form-group .col-sm-6:first-of-type,
.form-group .col-sm-7:first-of-type,
.form-group .col-sm-8:first-of-type,
.form-group .col-sm-9:first-of-type,
.form-group .col-sm-10:first-of-type,
.form-group .col-sm-11:first-of-type { padding-top: 0 }*/

@media (min-width:768px){

    .form-group .col-sm-1,
    .form-group .col-sm-2,
    .form-group .col-sm-3,
    .form-group .col-sm-4,
    .form-group .col-sm-5,
    .form-group .col-sm-6,
    .form-group .col-sm-7,
    .form-group .col-sm-8,
    .form-group .col-sm-9,
    .form-group .col-sm-10,
    .form-group .col-sm-11 { padding-top: 0 }
}

/* Reajustes individualizados de los campos */

.imp-xs-1, .imp-sm-1, .imp-md-1, .imp-lg-1, .imp-xs-2, .imp-sm-2, .imp-md-2, .imp-lg-2,
.imp-xs-3, .imp-sm-3, .imp-md-3, .imp-lg-3, .imp-xs-4, .imp-sm-4, .imp-md-4, .imp-lg-4,
.imp-xs-5, .imp-sm-5, .imp-md-5, .imp-lg-5, .imp-xs-6, .imp-sm-6, .imp-md-6, .imp-lg-6,
.imp-xs-7, .imp-sm-7, .imp-md-7, .imp-lg-7, .imp-xs-8, .imp-sm-8, .imp-md-8, .imp-lg-8,
.imp-xs-9, .imp-sm-9, .imp-md-9, .imp-lg-9, .imp-xs-10, .imp-sm-10, .imp-md-10, .imp-lg-10,
.imp-xs-11, .imp-sm-11, .imp-md-11, .imp-lg-11, .imp-xs-12, .imp-sm-12, .imp-md-12, .imp-lg-12 {
  padding-right: 20px
}
.imp-xs-12{width:100%}.imp-xs-11{width:91.66666666666666%}.imp-xs-10{width:83.33333333333334%}.imp-xs-9{width:75%}
.imp-xs-8{width:66.66666666666666%}.imp-xs-7{width:58.333333333333336%}.imp-xs-6{width:50%}.imp-xs-5{width:41.66666666666667%}
.imp-xs-4{width:33.33333333333333%}.imp-xs-3{width:25%}.imp-xs-2{width:16.666666666666664%}.imp-xs-1{width:8.333333333333332%}
.imp-xs-pull-12{right:100%}.imp-xs-pull-11{right:91.66666666666666%}.imp-xs-pull-10{right:83.33333333333334%}
.imp-xs-pull-9{right:75%}.imp-xs-pull-8{right:66.66666666666666%}.imp-xs-pull-7{right:58.333333333333336%}.imp-xs-pull-6{right:50%}
.imp-xs-pull-5{right:41.66666666666667%}.imp-xs-pull-4{right:33.33333333333333%}.imp-xs-pull-3{right:25%}.imp-xs-pull-2{right:16.666666666666664%}
.imp-xs-pull-1{right:8.333333333333332%}.imp-xs-pull-0{right:0}.imp-xs-push-12{left:100%}.imp-xs-push-11{left:91.66666666666666%}
.imp-xs-push-10{left:83.33333333333334%}.imp-xs-push-9{left:75%}.imp-xs-push-8{left:66.66666666666666%}.imp-xs-push-7{left:58.333333333333336%}
.imp-xs-push-6{left:50%}.imp-xs-push-5{left:41.66666666666667%}.imp-xs-push-4{left:33.33333333333333%}.imp-xs-push-3{left:25%}
.imp-xs-push-2{left:16.666666666666664%}.imp-xs-push-1{left:8.333333333333332%}.imp-xs-push-0{left:0}.imp-xs-offset-12{margin-left:100%}
.imp-xs-offset-11{margin-left:91.66666666666666%}.imp-xs-offset-10{margin-left:83.33333333333334%}.imp-xs-offset-9{margin-left:75%}
.imp-xs-offset-8{margin-left:66.66666666666666%}.imp-xs-offset-7{margin-left:58.333333333333336%}.imp-xs-offset-6{margin-left:50%}
.imp-xs-offset-5{margin-left:41.66666666666667%}.imp-xs-offset-4{margin-left:33.33333333333333%}.imp-xs-offset-3{margin-left:25%}
.imp-xs-offset-2{margin-left:16.666666666666664%}.imp-xs-offset-1{margin-left:8.333333333333332%}.imp-xs-offset-0{margin-left:0}
@media (min-width:768px){.imp-sm-12{width:100%}.imp-sm-11{width:91.66666666666666%}.imp-sm-10{width:83.33333333333334%}.imp-sm-9{width:75%}
.imp-sm-8{width:66.66666666666666%}.imp-sm-7{width:58.333333333333336%}.imp-sm-6{width:50%}.imp-sm-5{width:41.66666666666667%}
.imp-sm-4{width:33.33333333333333%}.imp-sm-3{width:25%}.imp-sm-2{width:16.666666666666664%}.imp-sm-1{width:8.333333333333332%}
.imp-sm-pull-12{right:100%}.imp-sm-pull-11{right:91.66666666666666%}.imp-sm-pull-10{right:83.33333333333334%}.imp-sm-pull-9{right:75%}
.imp-sm-pull-8{right:66.66666666666666%}.imp-sm-pull-7{right:58.333333333333336%}.imp-sm-pull-6{right:50%}.imp-sm-pull-5{right:41.66666666666667%}
.imp-sm-pull-4{right:33.33333333333333%}.imp-sm-pull-3{right:25%}.imp-sm-pull-2{right:16.666666666666664%}.imp-sm-pull-1{right:8.333333333333332%}
.imp-sm-pull-0{right:0}.imp-sm-push-12{left:100%}.imp-sm-push-11{left:91.66666666666666%}.imp-sm-push-10{left:83.33333333333334%}.imp-sm-push-9{left:75%}
.imp-sm-push-8{left:66.66666666666666%}.imp-sm-push-7{left:58.333333333333336%}.imp-sm-push-6{left:50%}.imp-sm-push-5{left:41.66666666666667%}
.imp-sm-push-4{left:33.33333333333333%}.imp-sm-push-3{left:25%}.imp-sm-push-2{left:16.666666666666664%}.imp-sm-push-1{left:8.333333333333332%}
.imp-sm-push-0{left:0}.imp-sm-offset-12{margin-left:100%}.imp-sm-offset-11{margin-left:91.66666666666666%}.imp-sm-offset-10{margin-left:83.33333333333334%}
.imp-sm-offset-9{margin-left:75%}.imp-sm-offset-8{margin-left:66.66666666666666%}.imp-sm-offset-7{margin-left:58.333333333333336%}
.imp-sm-offset-6{margin-left:50%}.imp-sm-offset-5{margin-left:41.66666666666667%}.imp-sm-offset-4{margin-left:33.33333333333333%}
.imp-sm-offset-3{margin-left:25%}.imp-sm-offset-2{margin-left:16.666666666666664%}.imp-sm-offset-1{margin-left:8.333333333333332%}
.imp-sm-offset-0{margin-left:0}}@media (min-width:992px){.imp-md-12{width:100%}.imp-md-11{width:91.66666666666666%}.imp-md-10{width:83.33333333333334%}
.imp-md-9{width:75%}.imp-md-8{width:66.66666666666666%}.imp-md-7{width:58.333333333333336%}.imp-md-6{width:50%}.imp-md-5{width:41.66666666666667%}
.imp-md-4{width:33.33333333333333%}.imp-md-3{width:25%}.imp-md-2{width:16.666666666666664%}.imp-md-1{width:8.333333333333332%}.imp-md-pull-12{right:100%}
.imp-md-pull-11{right:91.66666666666666%}.imp-md-pull-10{right:83.33333333333334%}.imp-md-pull-9{right:75%}.imp-md-pull-8{right:66.66666666666666%}
.imp-md-pull-7{right:58.333333333333336%}.imp-md-pull-6{right:50%}.imp-md-pull-5{right:41.66666666666667%}.imp-md-pull-4{right:33.33333333333333%}
.imp-md-pull-3{right:25%}.imp-md-pull-2{right:16.666666666666664%}.imp-md-pull-1{right:8.333333333333332%}.imp-md-pull-0{right:0}.imp-md-push-12{left:100%}
.imp-md-push-11{left:91.66666666666666%}.imp-md-push-10{left:83.33333333333334%}.imp-md-push-9{left:75%}.imp-md-push-8{left:66.66666666666666%}
.imp-md-push-7{left:58.333333333333336%}.imp-md-push-6{left:50%}.imp-md-push-5{left:41.66666666666667%}.imp-md-push-4{left:33.33333333333333%}
.imp-md-push-3{left:25%}.imp-md-push-2{left:16.666666666666664%}.imp-md-push-1{left:8.333333333333332%}.imp-md-push-0{left:0}.imp-md-offset-12{margin-left:100%}
.imp-md-offset-11{margin-left:91.66666666666666%}.imp-md-offset-10{margin-left:83.33333333333334%}.imp-md-offset-9{margin-left:75%}
.imp-md-offset-8{margin-left:66.66666666666666%}.imp-md-offset-7{margin-left:58.333333333333336%}.imp-md-offset-6{margin-left:50%}
.imp-md-offset-5{margin-left:41.66666666666667%}.imp-md-offset-4{margin-left:33.33333333333333%}.imp-md-offset-3{margin-left:25%}
.imp-md-offset-2{margin-left:16.666666666666664%}.imp-md-offset-1{margin-left:8.333333333333332%}.imp-md-offset-0{margin-left:0}

    .col-md-1.no_lft_pd, .col-md-2.no_lft_pd, .col-md-3.no-lft_pd, .col-md-4.no_lft_pd, .col-md-5.no_lft_pd, .col-md-6.no_lft_pd, .col-md-7.no_lft_pd,
    .col-md-8.no_lft_pd, .col-md-9.no_lft_pd, .col-md-10.no_lft_pd, .col-md-11.no-lft_pd { padding-left: 0 }
}
@media (min-width:1200px){.imp-lg-12{width:100%}.imp-lg-11{width:91.66666666666666%}.imp-lg-10{width:83.33333333333334%}.imp-lg-9{width:75%}
.imp-lg-8{width:66.66666666666666%}.imp-lg-7{width:58.333333333333336%}.imp-lg-6{width:50%}.imp-lg-5{width:41.66666666666667%}
.imp-lg-4{width:33.33333333333333%}.imp-lg-3{width:25%}.imp-lg-2{width:16.666666666666664%}.imp-lg-1{width:8.333333333333332%}
.imp-lg-pull-12{right:100%}.imp-lg-pull-11{right:91.66666666666666%}.imp-lg-pull-10{right:83.33333333333334%}.imp-lg-pull-9{right:75%}
.imp-lg-pull-8{right:66.66666666666666%}.imp-lg-pull-7{right:58.333333333333336%}.imp-lg-pull-6{right:50%}.imp-lg-pull-5{right:41.66666666666667%}
.imp-lg-pull-4{right:33.33333333333333%}.imp-lg-pull-3{right:25%}.imp-lg-pull-2{right:16.666666666666664%}.imp-lg-pull-1{right:8.333333333333332%}
.imp-lg-pull-0{right:0}.imp-lg-push-12{left:100%}.imp-lg-push-11{left:91.66666666666666%}.imp-lg-push-10{left:83.33333333333334%}.imp-lg-push-9{left:75%}
.imp-lg-push-8{left:66.66666666666666%}.imp-lg-push-7{left:58.333333333333336%}.imp-lg-push-6{left:50%}.imp-lg-push-5{left:41.66666666666667%}
.imp-lg-push-4{left:33.33333333333333%}.imp-lg-push-3{left:25%}.imp-lg-push-2{left:16.666666666666664%}.imp-lg-push-1{left:8.333333333333332%}
.imp-lg-push-0{left:0}.imp-lg-offset-12{margin-left:100%}.imp-lg-offset-11{margin-left:91.66666666666666%}.imp-lg-offset-10{margin-left:83.33333333333334%}
.imp-lg-offset-9{margin-left:75%}.imp-lg-offset-8{margin-left:66.66666666666666%}.imp-lg-offset-7{margin-left:58.333333333333336%}
.imp-lg-offset-6{margin-left:50%}.imp-lg-offset-5{margin-left:41.66666666666667%}.imp-lg-offset-4{margin-left:33.33333333333333%}.imp-lg-offset-3{margin-left:25%}
.imp-lg-offset-2{margin-left:16.666666666666664%}.imp-lg-offset-1{margin-left:8.333333333333332%}.imp-lg-offset-0{margin-left:0}

    .col-lg-1.no_lft_pd, .col-lg-2.no_lft_pd, .col-lg-3.no-lft_pd, .col-lg-4.no_lft_pd, .col-lg-5.no_lft_pd, .col-lg-6.no_lft_pd, .col-lg-7.no_lft_pd,
    .col-lg-8.no_lft_pd, .col-lg-9.no_lft_pd, .col-lg-10.no_lft_pd, .col-lg-11.no-lft_pd { padding-left: 0 }
}

.row .nopadding { padding-right: 0 !important; }

.col-file-box {
    display: flex;
}

.dfield_prod_archivo .col-field-file,
.dfield_prod_image .col-field-file,
.form-group .col-file-box .col-fld  {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form-group .col-file-box .col-field-file {
    padding-right: 0;
}
form .col-field-file .makefield_ops, form .col-field-file .sl-ct-chat-icon-container {
    right: 0;
}

form.mgbform .form-group.row {
    div[class^="dfield_"] {
        .box_thumb {
            padding-left: 0;
        }
    }
}


.dfield_prod_image .col-field-file {
    align-self: flex-start;
}

.box-field-flex,
.col-field-file .box_desc {
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
}

.col-field-file .box-field-flex {
    width: 100%;
    pointer-events: auto;

    .input-group {
        display: flex;

        .input-group-btn {
            width: fit-content;
            display: flex;
        }
    }
}

form .box-field-flex > .input-group      { padding-left: 0 }

form .col-info label { color: rgba(0,0,0,0.35); width: 100%; border-bottom-color: #eee; border-bottom-width: 1px; border-bottom-style: solid; }

.row_btm {margin-bottom: 20px}

#crumbs_box_table_active .wrapper { margin-bottom: 0 }

/*.crumbs {z-index: 3} .box_form {z-index: 1}*/

.crumbs {
    padding: 26px 44px 0px 44px;
    background-color: #FFFFFF;
    .nav-tabs {
        padding-top: 0;
    }
}

.crumbs .nav li a[data-toggle="tab"] .icon {
    font-size: 100%;
    line-height: 1;
    padding-right: 10px;
    display: none;
}

.crumbs .wrapper          {
    position: relative;
    margin: 0 auto 20px auto;
    overflow: hidden; padding: 0;
    height: 50px;
    max-width: 100%;
    overflow-x: hidden;
}

.crumbs .wrapper .nav {
    position: absolute;
    left: 0px;
    top: 0px;
    min-width: 5000px;
    margin: 0;
    border: none;
    height: 55px;
}

.crumbs .wrapper .nav > li  {
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    margin-right: 20px;
}

.crumbs .scr {
    text-align: center;
    cursor: pointer;
    display: none;
    padding: 17px 0 0 0;
    white-space: no-wrap;
    vertical-align: middle;
    color: #2E383A;
}
.crumbs .scr:hover        { color: #387B77 }
.crumbs .scr-right        { float: right; padding-left: 10px }
.crumbs .scr-left         { float: left; padding-right: 10px }

.crumbs .makefield_ops {
    float: right;
    padding-left: 5px;
    position: relative;
    top: 14px;
    right: auto;
    display: inline;
    margin-left: 10px;

    ul.dropdown-menu {
        left: auto;
    }
}

.makefield_ops .dropdown-menu li > a { color: #2E383A }

.makefield_ops .dropdown-menu li .icon       { vertical-align: middle }
.makefield_ops .dropdown-arrow               { border-color: rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) #F1C40F !important }
.makefield_ops .dropdown-arrow:after {
	content: " ";
	border-style: solid;
	border-width: 0 9px 9px 9px;
	border-color: rgba(255,255,255,0) rgba(255,255,255,0) #fff rgba(255,255,255,0);
	position: absolute; right: -9px; top: 0; width: 0; height: 0; margin-top: 1px;
}
.makefield_ops.dropup .dropdown-menu  {
    margin-bottom: 9px;
    z-index: 999;
}
.makefield_ops.dropup .dropdown-arrow {
    margin: 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #F1C40F rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) !important;
}
.makefield_ops.dropup .dropdown-arrow:after {
    margin: -10px 0 0 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) !important;
}

.paragrh .form .mgbform h4.title {
    padding: 40px 44px 16px 44px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #2E383A;
    font-weight: 700;
    font-size: 29px;
    line-height: 36px;
    i {
        font-weight: normal;
    }
}

.paragrh .form .mgbform h4.title.crbTp {
    padding: 20px 44px 8px 44px;
    margin: 0;
    font-size: 29px;
    line-height: 36px;
    font-weight: 700;
    color: #161F27;
}

.mgbform             { margin-bottom: 20px }
.modal-body .mgbform { margin-bottom: 0 }



.mgbform[name="locations"] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name="mat_test"] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name="catalogue"] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name="product_formats"] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name="products"] .box_form .form-group.row:not(.sl_item_info) {
    display: flex;
    align-content: flex-start;
    .col-fld {
        align-content: flex-start;
        height: fit-content;
    }
}

.chk_opt,
.chk_opt_sts_mod,
.chk_opt_sts_new,
.chk_opt_onl_upd,
.chk_opt_sts_new ~ .col-xs-12,
.chk_opt_sts_new ~ .col-xs-12 ~ .col-xs-12 {
    align-self: start;
}


.form .fld_combi { position: relative; display: block; width: 100% }

.has_preview input,
.has_preview textarea { border-color: rgba(0,0,0,0.15) }

.has-warning .form-control,
.has_error   .form-control {color: #34495e}

.has-warning .select2 {
    color: #f1c40f;
    border-color: #f1c40f;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .input-group-btn .btn {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
}

.has-error .fileinput .input-group-btn .btn {
    border-top-color: #D5DFE1;
    border-right-color: #D5DFE1;
    border-bottom-color: #D5DFE1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:hover {
        border-top-color: #EAEEF0;
        border-right-color: #EAEEF0;
        border-bottom-color: #EAEEF0;
        background-color: #EAEEF0;
        color: #4C6569;
    }
}

 .has-error .fileinput .input-group .form-control:first-child {
    border-right-width: 1px;
}

.fileinput .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle):not(.fileinput-btn-change),
.fileinput .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle):not(.fileinput-btn-change) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


.has-error .fileinput .input-group-btn .btn {
    background-color: #D5DFE1;
    color: #2E383A;
}

.has-error .input-group-btn .btn {
    border-top-color: #D2322C;
    border-right-color: #D2322C;
    border-bottom-color: #D2322C;

    &:focus {
        border-top-color: #3EAC93;
        border-right-color: #3EAC93;
        border-bottom-color: #3EAC93;
    }
}

.input-group .form-control:first-child {

    border-right-width: 0;
}

.input-group-btn > .btn {
    z-index: 2;
}

textarea           { min-width: 100%; max-width: 100% }
textarea.area_hmed { height: 200px }
textarea.area_hmax { height: 100% }

.form label em   { color: rgba(52,73,94,0.5) }

.form-control.tmp_disabled,
.form-control.form-disabled,
.form-control[disabled],
.form-control[readonly],
.fieldset.tmp_disabled .form-control,
.fieldset[disabled]    .form-control { display: inline-block; color: #99a7a7 }

select[multiple].form-control { height: 42px }

.form-group input[type="date"],
.form-group input[type="time"],
.form-group input[type="datetime-local"],
.form-group input[type="month"]           { line-height: 1.467 }

form .form-control.form-disabled,
     .form-control.form-disabled {

    background-color: #f4f6f6;
    border-color: #d5dbdb;
    color: #d5dbdb;
    cursor: default;
    opacity: 0.7;
}

                   .input-group-btn .btn.into-disabled,
.has-warning       .input-group-btn .btn.into-disabled,
.has-error         .input-group-btn .btn.into-disabled,
.input-group.focus .input-group-btn .btn.into-disabled {
    border-color: transparent;
    background-color: #EAEEF0;
    color: #ABC0C4;
}

.box_input_medium { position: relative; width: 80%; min-width: 800px; max-width: 1200px; margin: 20px auto; }

.box_input_medium.height_center {margin-top: 125px}
.box_input_medium.height_top    {margin-top: 0}

#body {
    .box_center {
        &.long {
            max-width: 100%;
            padding-left: 44px;
            padding-right: 44px;
            overflow: initial;
            position: relative;
        }
        &.box_create_company {
            h4 {
                width: 100%;
            }
            .box_cat_reg {
                width: 100%;
                max-width: 996px;
            }
        }
        .box_input_medium {
            min-width: initial;
            width: 100%;
        }
    }
}


.box_info_required             { position: relative; display: inline-block; width: 100%; color: #aaa; font-size: 70%; line-height: 70%; font-style: italic; padding-bottom: 15px }
.box_info_required span        { color: #D2322C; }
.box_info_required span:before { content: '['; color: #ccc }
.box_info_required span:after  { content: ']'; color: #ccc }
.required_flag                 { color: #D2322C; }

.modal-body .box_info_required { padding-left: 20px }

.alert.alert-warning h3,
.alert.alert-danger  h3,
.alert.alert-error   h3 { font-size: 110% }
.alert.alert-warning ul,
.alert.alert-danger  ul,
.alert.alert-error   ul { padding-left: 0; list-style: none }

.sl-payment-paragrh {
    .alert-warning {
        h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        display: flex;
        }

        span.legacy-h7 {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            margin: 4px 0 0 36px;
        }
    }

    .alert-info {
        span.legacy-h7 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        display: flex;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            margin: 4px 0 0 36px;
        }
    }
}

form.mgbform div#error_div {
    margin: 44px 44px 40px 44px;
    display: flex;
}

form.mgbform[name=upd_img] div#error_div,
form.mgbform[name=upd_fld] div#error_div {
    margin: 0px 0px 24px 0px;
  }


form.mgbform {
    .has-error .form-control.uneditable-input {
        border-right: 1px solid #D2322C;
        border-right-width: 1;
    }
    .uneditable-input {
        border-right: #fff;
        border-right-width: 0;
        &:hover {
            border-right: #fff;
            border-right-width: 0;
        }
    }
    .btn-tertiary {
        border-left: none;
        border-right: none;
        &:hover {
            border-left: none;
            border-right: none;
        }
    }
}

#file_sel {
    .btn-tertiary.sl-button-icon-only {
        padding: 8px 16px;
        i {
            font-size: 16px;
            margin-right: 0;
        }
    }
}


.modal form.mgbform[name=upd_img],
.modal form.mgbform[name=upd_fld] {
    .frm_gnr_btns.sl-block {
        margin-top: 24px;
    }

}

.modal form[name=upd_img],
.modal form[name=upd_fld] {
     .frm_gnr_btns.sl-block.btn-group .btn {
          border-radius: 4px !important;
          margin-right: 12px !important;
          margin-left: 0px !important;
     }
}

.crumbs + script + form.mgbform div#error_div {
    margin: 44px 44px 40px;
}

label .icon              { margin-right: 5px }
label .icon:not(.txt_st) { color: #7c8ea0 }
label + .icon.sl-icon-error:not(.txt_st) {
    color: #4C6569;
    font-size: 20px;
}
.row-horizontal label    { text-align: right; padding-right: 0 }

form h2 .icon:not(.ini), form h3 .icon:not(.ini), form h4 .icon:not(.ini), form h5 .icon:not(.ini), form h6 .icon:not(.ini), form span.legacy-h7 .icon:not(.ini) { margin-left: 5px }
form h2 .icon.nomrg, form h3 .icon.nomrg, form h4 .icon.nomrg, form h5 .icon.nomrg, form h6 .icon.nomrg, form span.legacy-h7 .icon.nomrg { margin: 0 }

.box_sz_bt_del         { width: 100px; padding-right: 0 }
.offset_sz_bt_ops.cols { margin-left: 155px }
.offset_sz_bt_del      { margin-left: 100px }

.box_sz_bt_ops     button          { vertical-align: 0; text-align: left }
.box_sz_bt_ops     button.act_act  { margin-top: 3px }


.offset_sz_bt_ops .select2.select2-container--bootstrap { min-height: 36px; max-height: 36px }
.offset_sz_bt_ops .select2-container--bootstrap .select2-selection--single .select2-selection__rendered { padding-top: 3px; font-size: 14px }

.form-group.fld_dsbl {
    .col-frm {
        input.form-control[readonly], .form-control.form-disabled {
            color: #ABC0C4;
            background-color: #EAEEF0;
            border: 1px solid #D5DFE1;
            opacity: 1;
        }
        select.form-control.form-disabled {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }
        .check_list {
            .checkbox.disabled {
                color: #ABC0C4;
            }
        }
    }
}

.col-frm {
    .form-control[disabled], .form-control.form-disabled {
        color: #2E383A;
        background-color: #EAEEF0;
        border: 1px solid #D5DFE1;
        opacity: 1;
        -webkit-text-fill-color: #2E383A;
    }
    .check_list {
        .checkbox.disabled {
            color: #2E383A;
        }
    }
}

form .form-group.act_moving,
.page_fields .act_moving                   { position: fixed; z-index: 100000; padding-top: 10px }
form .form-group button.act_mov:hover      { color: rgba(0, 0, 0, 0.6); }
form .form-group.line_mov:not(.act_moving) { transition: all 0.25s ease 0s }

form .form-group.line_mov.move_in {
    border-bottom: 55px solid #EDF8F3;
    transition: all 0.25s ease 0s;
}

form .form-group.line_mov.move_fix {
    border-bottom:  1px solid rgba(0,0,0, 0.05);
    transition: none !important;
}

form .form-group.in_change > div {
    padding: 10px 0;
    background: #EDF8F3;
    transition: all 0.25s ease 0s;
}

form .form-group.act_moving,
.page_fields .act_moving .field            { box-shadow: -10px 10px rgba(0,0,0,0.20); background: rgba(236,240,241,0.50);}

.edt-form {
    margin-bottom: 16px!important;
}

input.inp_verify {
    text-transform: uppercase;
    margin-top: 8px;
}

form .top_bt      {margin-top:    32px}
form .fld_mrg_top {margin-top:    20px}
form .row-slim    {margin-bottom: 12px}
form .btn-group   {margin-top:    15px}

form .fld_sep_top    {margin-top:    20px; border-top:    2px dotted #fff; padding-top:    20px}
form .fld_sep_top_gr {margin-top:    20px; border-top:    2px dotted #ddd; padding-top:    20px}
form .fld_sep_btm    {margin-bottom: 20px; border-bottom: 2px dotted #fff; padding-bottom: 20px}

form .slim_sp_btm {margin-bottom: 5px}

form .row-slim .input-group-btn .btn { padding-left: 10px; padding-right: 10px }

.fileinput-new .form-control.uneditable-input {width: 100%}

form .field_ops {
    margin: 0 8px 8px 0;
}

form .field_ops button.ehide  { display: none; }

form .field_ops button {
    display: flex;
    align-items: center;
}

sup {
    position: unset;
    margin-left: 2px;
}


form .field_ops button,
form .makefield_ops button {
    padding: 4px;
    height: 24px;
    border-radius: 4px;

    &:disabled {
        background-color: #D5DFE1;
        border-color: transparent;
        color: #2E383A;
    }
}

form .field_ops button i,
form .makefield_ops button i {
    font-size: 16px;
    line-height: 16px;
}

form .field_ops.connector_link { float: right; }

form .field_ops.connector_link.connector_link_modify {
    color: #5A4B35;
    margin: 2px 8px 0 0;
    position: absolute;
    right: 40px;
    top: 0;
}

form .field_ops.connector_link_forms_editor { margin: 4px 80px 0px 0px; }

form .field_ops button.bt_fld_connector {
    background-color: #D5DFE1;
    color: #2E383A;
    font-weight: normal;
}

form .field_ops button.bt_fld_connector_forms_editor { background: #ffffff; }

.form-group label.lb_check {
    padding: 0 0 0 8px;
    margin: 0;
    vertical-align: middle
}

form .switch_box {
    margin-top: 20px
}

form .form-group + .form-group .switch_box {
    margin-top: 0px;
}
     .switch                  {
        // margin-right: 15px;
        vertical-align: middle
    }
form .mrg_no_ttl              { margin-top: 47px }

form label.lb_check.st_title_md {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #2E383A;
    padding: 0;
}

form        label.st_title_mn,
.form-group label.st_title_mn { font-size: 110%; line-height: 115%; padding-top: 3px }
form label .md_off            { color: rgba(0,0,0,0.3) }
form label.lb_check .md_off {
    color: inherit;
}
form .ttl_sep {
    margin-bottom: 0;
    margin-left: 20px;
}

form .ttl_prg {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #4C6569;
    font-weight: 400;
    margin: 0 0 0 16px;

    i {
        font-size: 16px;
        line-height: 16px;
        margin: 0 8px;
    }
}


form h6.ttl_prg {
    color: #2E383A;
    font-weight: 700;
    font-size: 20px;
    margin: 20px 15px 10px;
    &.sl-body-large-semibold {
        font-size: 16px;
    }
}



form .ttl_item .field_ops.bx_btn_not_apply {
    button {
        margin-left: auto;
        // padding-bottom: 0;
    }
}

form .field_ops.bx_btn_not_apply {
    button {
        padding: 4px 10px;

        i {
            font-size: 16px;
            margin-right: 4px;
            &.sl-icon-stop {
                margin-right: 0px;
            }
            &.sl-button-icon-alone {
                margin: 0 -4px;
            }
        }
    }
}


form .makefield_ops { position: absolute; top: 0; right: 15px; width: auto }

form .box_extra                 { position: relative; overflow: hidden; padding-left: 2px }
form .box_extra,
form .box_extra a               { font-size: 90%; line-height: 100% }
form .box_extra .st_file:before { content: "\E416"; font-family: 'Glyphicons Regular'; padding-right: 10px; vertical-align: middle; color: rgba(52,73,94,0.5) }

/* caixa text html */

form .btn_scroller {
	position: absolute; overflow: hidden; bottom: 0px; right: 20px; width: 28px; height: 25px; cursor: row-resize;
	background: url(../../../img/jQueryUi/ui-icons_454545_256x240.png) -76px -225px no-repeat;
}

.modal-dialog form[name=form_tlang] .box_form {
    margin-top: 28px;
}
.modal-dialog form[name=form_tlang] .row      { margin-left: 0; margin-right: 0 }

/* preview imatge */

form .box_thumb {
    position: relative;
    display: flex;
}

form .box_thumb .box_img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #D5DFE1;
    border-radius: 6px;
	background-image: url(../../../img/placeholder_02.png);
	background-repeat: no-repeat;
	background-position: center;
    background-color: #fff;
    background-size: auto;
    width: 160px;
    height: 133px;
    overflow: hidden;
	cursor: pointer
}
table

form .box_thumb.frm_rdnly .box_img,
form .box_thumb.frm_rdnly .box_fld { cursor: default }

form .box_thumb .box_img.img_on { background: #fff url(../../../img/loader.gif) center center no-repeat }

form .box_thumb .box_img img {
    width: 100%;
}

form .box_thumb .badge {
    position: absolute;
    top: -6px;
    right: 5px;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
    background-color: #EAEEF0;
    color: #4C6569;
}

form .box_thumb .box_fld {
	display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #D5DFE1;
    border-radius: 6px;
	background-image: url(../../../img/placeholder_file_02.png);
	background-repeat: no-repeat;
	background-position: center;
    background-color: #fff;
    background-size: auto;
    width: 160px;
    height: 133px;
    overflow: hidden;
	cursor: pointer;
    img {
        width: 61px;
    }
}

form .box_thumb .box_fld.box_fld_little {
    background-image: url(../../../img/placeholder_file_01.png);
}

form .box_thumb .box_img.box_img_little {
    background-image: url(../../../img/placeholder_01.png);
}

form .row-img-file-trans {
    .box_thumb {
        .box_img {
            background-image: url(../../../img/placeholder_01.png);
        }
        .box_fld {
            background-image: url(../../../img/placeholder_file_01.png);
        }
    }
    label {
        strong {
            font-weight: 600;
        }
    }
    .box-field-flex {
        .input-group {
            display: flex;
            .input-group-btn {
                width: fit-content;
                display: flex;
            }
        }
    }
}

.modal-content form .box_thumb .box_img     { margin: 0 }
.modal-content form .box_thumb .box_img img { width: 165px }

.radio, .checkbox { min-height: 22px }

form .checkbox,
form .radio                       { display: inline-block }
form h1 .checkbox, form h1 .radio { vertical-align: middle }
form h2 .checkbox, form h2 .radio { vertical-align: middle }
form h3 .checkbox, form h3 .radio { vertical-align: middle }
form h4 .checkbox, form h4 .radio { vertical-align: middle }
form h5 .checkbox, form h5 .radio { vertical-align: middle }
form h6 .checkbox, form h6 .radio { vertical-align: middle }
form span.legacy-h7 .checkbox, form span.legacy-h7 .radio { vertical-align: middle }
form .checkbox.disabled .icons,
form .radio.disabled    .icons,
form .checkbox.disabled.checked .first-icon,
form .radio.disabled.checked    .first-icon,
form .checkbox.disabled.checked .second-icon,
form .radio.disabled.checked    .second-icon { color: rgba(0,0,0,0.2); cursor: default }

form                       .form-control.tmp_disabled,
form fieldset.tmp_disabled .form-control,
form                       .form-control.form-disabled,
form                       .form-control[disabled],
form fieldset[disabled]    .form-control                         {
    display: block;
    color: #ABC0C4;
    background-color: #EAEEF0;
    border-color: #D5DFE1;
}
form                       .form-control[readonly]               { display: block; color: rgba(0,0,0,1) }
form                       .form-control.ev_click:not(:disabled) { opacity: 1; border-color: #E3EAED; background-color: #fff; color: #34495e }

form .checkbox.disabled,
form    .radio.disabled { color: #4C6569 }
                   .input-group-btn .btn.into-disabled,
.input-group.focus .input-group-btn .btn.into-disabled        { border-color: #d5dbdb;}


form input.form-control[readonly], form textarea.form-control[readonly] {
    color: #2E383A;
    background-color: #EAEEF0;
    border: 1px solid #D5DFE1;
    opacity: 1;
}

form input.form-control[disabled], form textarea.form-control[disabled] {
    color: #2E383A;
    background-color: #EAEEF0;
    border: 1px solid #D5DFE1;
    opacity: 1;
}

.input-group {
    .input-date-calendar button {
        border-right: 1px solid transparent!important;
        border-right-width: 1px;
    }
    &:hover:not(.focus) .input-date-calendar:not(:hover) button {
        border: 1px solid #ABC0C4!important;
        border-right: none;
    }
    &:hover:not(.focus) .input-group-addon:not(:hover) {
        border: 1px solid #ABC0C4;
        border-right-color: #D5DFE1;
        transition: none;
    }
    &.focus {
        .input-group-addon {
            background-color: #fff;
            border-color: #3eac93;
        }
    }
    .input-group-addon {
        background-color: #fff;
        border-color: #D5DFE1;
        color: #2E383A;
        transition: none;
        &:first-child {
            border-right: 1px solid #D5DFE1;
        }
    }
}

/* error en input-group */

/*
.has-error                    .input-group-btn,
.has-error .input-group.focus .input-group-btn,
.has-error                    .input-group-addon,
.has-error .input-group.focus .input-group-addon, */
.has-error .input-group-btn .btn,
.has-error .input-group.focus .input-group-btn .btn,
.has-error .input-group-addon .btn,
.has-error .input-group.focus .input-group-addon .btn {
    color: #fff;
    background-color:#D2322C;

    &:hover {
        color: #fff;
        background-color:#ED352E;
    }
}

/* no aplica */

form .not_apply .form-control,
form .not_apply .radio,
form .not_apply .checkbox,
form .not_apply .input-group-btn,
form .not_apply .radio.checked    .second-icon,
form .not_apply .checkbox.checked .second-icon,
form .not_apply .select2-container--bootstrap   { opacity: 0.5; filter: alpha(opacity=5) }
form .not_apply label[ref]                      { text-decoration: line-through }
form .not_apply .field_ops.lang                 { display: none }


/* modificacions caixa timer */

.ui-datepicker { margin-left: -50px }

.ui-datepicker .ui-datepicker-year { border: 0; width: auto; height: auto; text-align: center; background: #387B77; color: #fff }

.ui-datepicker .ui-timepicker-div                     { position: relative; padding: 0 10px; overflow: hidden; border-top: 1px solid #eee; }
.ui-datepicker .ui-timepicker-div dl                  { margin-bottom: 0 }
.ui-datepicker .ui-timepicker-div dt                  { font-size: 80% }
.ui-datepicker .ui-timepicker-div .ui-slider          { margin-bottom: 5px }
.ui-datepicker .ui-timepicker-div .ui_tpicker_hour,
.ui-datepicker .ui-timepicker-div .ui_tpicker_minute,
.ui-datepicker .ui-timepicker-div .ui_tpicker_second  { margin-left: 5px }

.ui-datepicker .ui_tpicker_time_label { display: none }
.ui-datepicker .ui_tpicker_time       { padding-top: 5px; text-align: right; font-size: 100%; line-height: 100% }

.ui-datepicker .ui-datepicker-buttonpane        { position: relative; overflow: hidden; margin: 10px 0 0 }

.ui-datepicker .ui-datepicker-buttonpane button {
	border-radius: 4px;
    padding: 8px 10px;
    margin-top: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    border: 0;
    background: #D5DFE1;
    color: #4C6569;
	-webkit-transition: 0.25s;
    transition: 0.25s;
    &:hover {
        background-color: #EAEEF0;
    }
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-close {
    background: #387B77;
    color: #fff;
    &.ui-priority-primary:hover {
        background-color: #3D9487;
    }
}


.ui-datepicker .ui-datepicker-buttonpane button.ui-priority-primary       { background: #387B77 }
.ui-datepicker .ui-datepicker-buttonpane button.ui-priority-primary:hover { background: #387B77 }

.ui-datepicker.opn_tp:before {
    border-color:  #387B77 transparent transparent transparent;
    border-width:  6px 6px 0 6px;
    top: auto;
    bottom: -13px;
}
.ui-datepicker.opn_lt:before {
    left: auto;
    right: 12px;
}

.ui-slider-handle,
.ui-slider-horizontal .ui-slider-handle,
.ui-slider-handle:focus {
    background-color: #7B9BA1;
    top: -5px;
}


.ui-slider {
    background: #EAEEF0;
    height: 8px;
}

.input-date-calendar {
    .btn {
        padding: 8px 12px;
        .sl-icon-calendar {
            font-size: 20px;
            font-weight: normal;
        }
    }
}

.ui-datepicker td a:hover {
    color: #2E383A;
    background-color: #EAEEF0;
}

.ui-datepicker td a.ui-state-highlight:hover {
    background-color: #3D9487;
    color: white;
}

/* on/off */

.has-switch.block {display: block}

/* multiselect */

form .select-multiple,
     .select-multiple   { margin-top: 0 }
form .st_mltslct select,
     .st_mltslct select { height: 42px; max-height: 42px }
.btn-group.select.select-multiple button.dropdown-toggle {
	border: 0; border-radius: 0; background: 0; width: 100%; margin: 0; padding: 0; color: #34495e; font-size: 15px; line-height: 1.467; }
.btn-group.select.select-multiple button.dropdown-toggle .filter-option { top: 0; left: 0; padding-left: 0 }
.btn-group.select.select-multiple button.dropdown-toggle .caret {right: 0}
.select-multiple .dropdown-menu { width: 100% !important; max-height: 400px }

.extraField { position: relative; width: auto; overflow: hidden; padding: 10px 0 0; font-size: 85%; line-height: 85%; color: #60758a }
.extraField input {display: inline-block; vertical-align: middle}

.box_desc {position: relative; overflow: hidden;}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove       { color: rgba(255,255,255,0.7) }
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover { color: #fff }

.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice                    { cursor: move; cursor: -webkit-grab; cursor: -moz-grab; -webkit-transition: background-color 0.25s linear; transition: background-color 0.25s linear; }
.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice:active             { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing }
.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice:hover              {
    background-color: #FBF2D0 !important
}
.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice.ui-sortable-helper { box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 0.15) }

/* New Description */
.form-control.new-description {
    overflow:hidden;
    resize: none;
}

.form-control.new-description:focus {
    border-color: #3EAC93;
    background-color: white;
}


.form-control:hover,
.form-control:hover,.select2.select2-container--bootstrap:hover,
.tagsinput-add-container input:hover,
.form .box_tags .tagsinput-add-container input:hover,
#fld_bx_srch:hover,
input.new-description:hover,
.box_search .slc_srch:hover {
    border-color: #ABC0C4;
}

.form-control.new-description::placeholder { color: #607589; font-size: 14px; font-style: normal; }
.tooltip-top-arrow-right.tooltip-description{ max-width: 420px; min-width: 150px; width: auto; overflow-wrap: break-word; text-align: left; }

.keep-structure-rb { margin: 20px 0 20px 0; display: none; }

.change-folder-box{
    border: 1px solid #CFDADD;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 8px 15px 20px 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:#FFF;
}

.change-folder-path{
    display: inline-block;
}

.change-folder-link{
    text-align: right;
    font-size: 14px;
    color: #387B77;
}

.change-folder-title {
    font-size: 14px;
    color: #2E383A;
}

.color-success {
    color: #0BA815;
}

.color-alternative {
    color: #BAAA8A;
}

.alert.alert-no-alert .icon.color-alternative {
    color: #BAAA8A;
}

.alert.alert-no-alert h6 .icon.color-alternative {
    font-size: 24px;
}

.alert.alert-no-alert h6.sl-flex {
    display: flex;
    align-items: center;
}

.verifyed input[id^='field_url_'].form-control {
    border: 1px solid #0BA815;
}

/* table options */

.form-group .box_pagination { padding: 0 }
.form-group .table .td_numb { text-align: right }

.col-fld .mce-panel,
.col-fld .form_field_status {
    width: 100%;
}

.col-fld .form_field_status .btn-group {
    width: 100%;

    .btn {
        width: 100%;
        border: 1px solid #D5DFE1;
        background-color: #FFFFFF;
        color: #2E383A;
        font-size: 14px;
        line-height: 24px;
        padding: 8px 12px 8px 0px;
        height: 40px;
        display: flex;
        justify-content: space-between;

        &:hover {
            background-color: #FFFFFF;
            color: #2E383A;
        }

        span {
            display: flex;
            align-items: center;

            i {
                margin-right: 8px;
            }
        }

        i {
            font-size: 20px;
            line-height: 20px;
        }
    }

    ul li a i {
        margin-right: 8px;
    }
}
.form_field_status {
    .dropdown-menu  li > {
        a{
            padding: 10px 16px;
        }
        a i, a:hover i{
            padding: 4px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 15px;
            &.sl-icon-edit{
                background-color: #FBF2D0;
                color: #5A4B35;
            }
            &.sl-icon-eye{
                background-color: #BFF5C5;
                color: #05570A;
            }
            &.sl-icon-eye-close{
                background-color: #D5DFE1;
                color: #2E383A;
            }
        }
        a, a:hover, a:active{
            color: #2E383A;
        }
    }
    .dropdown-menu li.selected >{
        a, a:hover, a:active{
            color: #2E383A;
        }
    }
    div.btn-group button.btn.dropdown-toggle span i.icon.ini{
        padding: 10px;
        border-right: 1px solid #D5DFE1;
        &.sl-icon-edit{
            background-color: #FBF2D0;
            color: #5A4B35;
        }
        &.sl-icon-eye{
            background-color: #BFF5C5;
            color: #05570A;
        }
        &.sl-icon-eye-close{
            background-color: #D5DFE1;
            color: #2E383A;
        }
    }
    div.btn-group button.btn.dropdown-toggle[disabled]{
        background-color: #EAEEF0;
        color:#2E383A;
        opacity:1;
    }
}

[id^="crumbs_box_mat_"].crumbs,
#crumbs_box_locations.crumbs,
#crumbs_box_products.crumbs,
#crumbs_box_catalogue.crumbs {
    padding: 125px 44px 0px 44px;
}
#crumbs_box_product_formats.crumbs {
    padding: 125px 44px 0px 44px;
}


#bx_slct_prd_frmt.header_with_title_only + script + .paragrh #crumbs_box_product_formats.crumbs {
    padding: 125px 44px 0px 44px;
}

[id^="crumbs_box_mat_"].crumbs.sl_no_breadcrumb,
#crumbs_box_locations.crumbs.sl_no_breadcrumb,
#crumbs_box_products.crumbs.sl_no_breadcrumb,
#crumbs_box_catalogue.crumbs.sl_no_breadcrumb,
#crumbs_box_product_formats.crumbs.sl_no_breadcrumb {
    padding-top: 27px;
}


.sec-prd-frm_edt .mgbform[name="product_formats"] #crumbs_box_product_formats.crumbs.sl_no_breadcrumb {
    padding-top: 187px;
}

#crumbs_box_products.crumbs + script + #error_div {
    margin-top: 44px;
}

[id^="crumbs_box_mat_"].crumbs {
    margin-top: -40px;
}

#crumbs_box_companies_connectors .wrapper {
    margin: 0 auto;
}

#crumbs_box_table_active.crumbs {
    margin: 0 -44px 20px;
    padding: 0px 44px 0px 44px;
}

.form-group.overflow.row.crumbs_conn {
    margin-bottom: 0;
    background-color: #FFFFFF;
    padding: 20px 44px;
    margin-top: -60px;
}

.sl_breadcrumb {
	max-width: calc(100% - 88px);
    width: 100%;
    z-index: 999;
	.precrumb, .postcrumb {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 50%;
        z-index: 900;
	}
	.titlecrumb {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: fit-content;
        max-width: 100%;
	}
	strong {
		font-weight: 600;
		color: #205A5A;
	}
}

.mgbform[name=product_formats] .sl_breadcrumb {
    width: calc(62% - 88px);
}

#bx_slct_prd_frmt.header_with_title_only .box_head_sec h1 {
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        display: block;
    }
}

#tags_menu.sl-button-secondary-medium {
    height: 40px;
    padding: 8px 20px;

    i {
        font-size: 20px;
        line-height: 20px;
    }

    &:hover {
        background-color: #EAEEF0 !important;
    }
}

form.mgbform .box_form h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #2E383A;
}

.form-group.sl_item_info {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #2E383A;
    position: absolute;
    top: 45px;
    margin: 0;
    padding-bottom: 1px;

    .ttl_prg.no_mrtp {
        margin: 0 0 4px 0;

        i {
            font-weight: 400;
        }

        strong {
            font-weight: 600;
        }
    }
}

div#success_div {
    margin-left: 44px;
    margin-right: 44px;
}

.mgbform .box_form .form-group.row.dbl.sl_form_edit_group,
.mgbform .box_form .form-group.row.sep_btm.dbl.sl_form_edit_group {
    flex-direction: column;
    align-items: self-start;
}

.form .sl_form_edit_group .box_id_tags div.tagsinput {
    display: flex;
}

.form .sl_form_edit_group {

    ul.nav li {
    background-color: #FFFFFF;
    width: 100%;
    border: transparent;
    padding: 16px;
    border-radius: 4px;

        a {
            padding: 0;
            justify-content: flex-start;

            button.btn-clean {
                position: absolute;
                right: 0;
                margin: 0;

                i {
                    padding: 0;
                    color: #2E383A;
                }
            }

            label.checkbox {
                line-height: 24px;
                font-weight: 600;
                color: #2E383A;
            }
        }
    }

    .page_fields {
        ul.check_list {
            li label {
                color: #2E383A;
                line-height: 24px;
                margin: 8px 0;
            }
        }
    }
}

.box_thumb.field_company_logo {
    padding-left: 15px;
}

.alert-container-blank {
    .paragrh.sl-payment-alert {
        padding: 40px 0px 0 0px;
    }
}

.paragrh.sl-payment-alert {
    padding: 24px 44px 0 44px;

    .alert.alert-danger {
        h6 {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            display: flex;
        }
    }
}

.sync_info {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.box_sync_card {
    background: white;
    padding: 32px;
    border: 1px solid #D5DDE5;
    border-radius: 8px;
    margin-bottom: 20px;
}


.tbl-wrapper {
    width: 100%;
    margin-top: 5px;
}

form .tbl-field.handsontable {
    background: transparent !important;
}

form .handsontable {
    tr:first-child td {
        border-top: 1px solid #D5DFE1;
    }
    td:first-of-type {
        border-left: 1px solid #D5DFE1;
    }
    td {
        border-right: 1px solid #D5DFE1;
        border-bottom: 1px solid #D5DFE1;
    }
    table > tbody > tr:nth-child(2n) > td,
    table > tbody > tr.selected-row > td,
    table > tbody > tr:nth-child(2n) > th,
    table > tbody > tr.selected-row > th {
        background: #f8f8f8;
    }
}

.box_login {
    .btn-ext-provider-container {
        a {
            width: 100%;
        }
    }
}


/* TODO Comentar con Frontends */
form.form-validation {
    .form-group {
        label.noTitle {
            margin-top: 25px;
        }
        .checkbox.noTitle {
            margin-top: 40px;
        }
        .noPadding {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.select2_static_tag {
    background-color: #FBF2D0;
    color: #5A4B35;
    border: 0;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin: 2px 8px 2px 0;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
}

.columns_to_display_helper {
    color: #8E979E;
    font-size: 12px;
}

.fld_vl {
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.fld_vl::-webkit-scrollbar {
    display: none;
}

.radio_cont {
    label {
        // font-weight: 600;
        // color: #000;
    }
}

.modal-content {
    .modal-body {
        button {
            &.btn_config {
                padding: 8px 16px;
                font-size: 12px;
                line-height: 16px;
                height: 32px;
                margin-bottom: 24px;
            }
        }
        .form_config {
            .nav-tabs>li {
                &>a {
                    justify-content: left;
                }
                &.active,
                &.active:hover,
                &.active:focus {
                    border-radius: 4px;
                    width: 100%;
                }
            }
        }
    }
}

.form_langs {
    .checkbox.checked {
        color: #2E383A;
    }
}

select.sl-content-light {
    color: #7B9BA1;
  }

 
select.form-control {
    transition: color 0s linear;
  }

#body_content .form form[name=sales_material] .frm_gnr_btns .btn[name=submit_cancel] {
    background-color: transparent;
    color: #2E383A;
    border: 1px solid transparent;
    &:hover {
        background-color: #EAEEF0;
        color: #2E383A;
        border: 1px solid transparent;
    }
}

label.radio.sl-content-default.checked{
    color: #2E383A;
}

.bulk_form_group > *:not(:first-child):not(:last-child) {
    margin-bottom: 12px;
}

.modal-body .tbl_field_grid .handsontable {
    background-image: unset!important;
}

label.sl-checkbox {
        margin:inherit;
}

p.sl-radio-text {
    font-size: 14px;
    color: #2E383A;
}

label.sl-radio {
    margin: unset;
}

.tooltip_editor.sl-icon-info  {
    margin: 0 8px 8px 0;
    height: 20px !important;
    display: inline-block;
    vertical-align: middle;
}
