/*

	## Caja login y registro

*/

.modal .box_login,
.modal .box_warning       { position: relative; left: auto; top: auto; margin: 20px auto }
.modal .box_warning       { position: relative; margin-top: 20px; margin-bottom: 20px }
.modal .box_warning .logo { display: none }

.box_register,
.box_register.size_long { 
	position: relative;
	width: 564px;
	margin: 92px auto 0;
}

.box_register .logo {
	position: relative;
  width: 194px;
  margin: 0 auto 48px auto;
  text-align: center;
}

.box_register .logo img {
	width: 194px;
}

.box_register .box_form {
	position: relative;
	width: 100%;
	display: block;
	border-radius: 8px;
	padding: 88px 48px;
	background-color: #FFFFFF;
	text-align: center;

	h2 {
		font-size: 29px;
		font-weight: 700;
		line-height: 36px;
		color: #2E383A;
		margin: 0 0 20px 0;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		margin: 0 0 24px 0;
		color: #4C6569;
	}

	button {
		margin-top: 8px;
	}

	#mssg_bt {
		margin: 32px 0 0 0;
	}

	strong {
		font-weight: 700;
	}
}

.box_login.size_long { width: 410px }

.box_login        .logo, 
.box_input_medium .logo, 
.box_warning      .logo  { position: relative; overflow: hidden; width: 169px; margin: 80px auto 32px auto; text-align: center }
.box_login        .logo, 
.box_warning      .logo  { margin-top: 0 }


.box_login    .box_form  {
	position: relative; width: 100%; display: block;  padding: 40px;
	border-radius: 6px; background: rgba(255, 255, 255, 0.9); _background: #f9fafc;
}
.box_login .image_code { padding-bottom: 20px }

.box_login    .box_info, 
.box_register .box_info             { position: relative; display: block; width: 100% }
.box_login    .box_info.pos_bottom, 
.box_login    .box_info.min, 
.box_login    .box_info.min p,
.box_register .box_info.pos_bottom, 
.box_register .box_info.min, 
.box_register .box_info.min p       { position: relative; margin-top: 16px; max-width: 100%; width: auto; text-align: left; color: #4C6569; font-size: 14px; line-height: 18px }

.box_login    .box_info.pos_top, 
.box_register .box_info.pos_top     { margin: 0 0 20px 0 }

.box_warning                        { margin-top: -200px; width: 80%; margin-left: -40% }
.box_warning .box_info              { position: relative; overflow: hidden; max-width: 800px; margin: 40px auto 0}

.form-control + .input-icon { background: transparent }

.box_login.hmax { 
	position: fixed; 
	left: 0; 
	top: 0; 
	width: 100%; 
	height: 100%; 
	bottom: 0; 
	margin: 0; 
	overflow: auto; 
}

.modal .box_login.hmax              { position: relative; }
.box_login.hmax .box_form           { width: 320px; margin: 0 auto 40px }
.box_login.hmax.size_long .box_form { width: 410px }

.box_success { top: 30%; }
.box_register { position: relative; width: 320px; margin: 20px auto 0 }
.box_login    .box_form, 
.box_register .box_form {
	position: relative; width: 100%; display: block;  padding: 40px;
	border-radius: 6px; 
	background: #fff; 
	_background: #f9fafc;
	border: 1px solid #D5DFE1;
}

hr.box-login-separator{ margin: 32px 0px 32px 0px; border: 0; border-top: 1px solid #eee; }

.external-providers { margin: 16px 0px 0px 0px; }
.btn-access{ text-transform: capitalize; }
.btn-ext-provider-container { margin: 10px 0px 10px 0px; width: 100%; }

.btn-ext-provider { 
	background: transparent; 
	border-radius: 4px; 
	width: 100%; 
	color: #2E383A;
	border-color: #D5DFE1 !important;
}

.btn-ext-provider-text { 
	margin-left: 10px; 
	font-family: Lato; 
	color: #34495E; 
}
.btn-frm-bottom { position: relative; margin: 43px 0px 3px 0px; }
.forgotten-password-text { margin-top: 9px;	font-size: 14px; text-align: right; }
.still-no-account-text { text-align: center; margin-top: 32px}
.checkbox.checked, .radio.checked{ color: #16a085 }
.alert-error-login{ background: #FFEEED; border: 0; border-radius: 4px; padding: 16px; margin-bottom: 20px; font-size: 14px; line-height: 1.4; }

div.grecaptcha-badge  { display: block }

.box_input_medium .form {
	background-color: transparent;
	display: flex;
	justify-content: center;
	margin-top: 84px;

	.box_form {
		p {
			color: #2E383A;
			font-size: 20px;
			font-weight: 700;
			line-height: 28px;
			text-align: center;
			margin-bottom: 60px;
		}
	}

	form.mgbform div#error_div {
		margin: 0 0 24px 0;
	}
}

#logo_reg {
	height: 80px;
  display: flex;
  align-items: center;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 44px;
  width: 120px;

	img {
		width: 117px;
	}
}

form[name="users"] h4.title {
	color: #2E383A;
}


@media (max-height: 760px) {
	.box_login.hmax {
		align-items: start !important;
		margin-top: 40px;
	}
  }