/*  ## Dropdown normal */

.dropdown-left { float: left }

.btn-group.select               .dropdown-menu,
.form-group .dropdown           .dropdown-menu,
.makefield_ops                  .dropdown-menu               { z-index: 5; width: auto; border-radius: 6px; }
.btn-group.select.open >        .dropdown-menu               { margin-top: 8px !important}
.table-striped td               .dropdown-menu  li,
.btn-group.select               .dropdown-menu  li           { float: none; margin: 0; }


.btn-group.select .dropdown-menu li.sl-mb-50 {
    margin-bottom: 2px;
}

.box_head_sec .btn-group.select .dropdown-short li,
.box_search   .btn-group.select .dropdown-short li,
.box_head_sec .btn-group.select .dropdown-large li,
.box_search   .btn-group.select .dropdown-large li           { margin-right: 0 }
                                .dropdown-short li > a,
                                .dropdown-large li > a       { padding-right: 30px }
.table-striped td               .dropdown-menu  li.disabled,
.btn-group.select               .dropdown-menu  li.disabled  { display: inline }

.makefield_ops .dropdown-menu .icon,
.btn-group.select .dropdown-menu .icon {
    padding-right: 8px;
    font-size: 20px;
    line-height: 20px;
}

.makefield_ops .sl-icon-setting {
    padding: 0;
    position: relative;
}

.btn-group.select               .dropdown-menu a             { text-align: left }
.btn-group.select .dropdown-menu .disabled a,
.btn-group.select .dropdown-menu .disabled a:hover i { 
    color: #ABC0C4; 
    background-color: transparent;
}
.btn-group.select               .dropdown-menu a.inactive    { background: transparent; color: rgba(0, 0, 0, 0.20); cursor: default }
.btn-group.select               .dropdown-menu p             { padding: 6px 15px 8px; margin: 0; font-size: 90%; color: rgba(0,0,0,0.60); width: 290px }
.btn-group.select.drp_mn_rght   .dropdown-menu,
.crumbs                         .dropdown-menu,
.box_form                       .dropdown-menu               { left: auto; right: 0 }

#head                 .dropdown-arrow:after,
.btn-group            .dropdown-arrow:after,
.form-group .dropdown .dropdown-arrow:after {
	content: " ";
	border-style: solid;
	border-width: 0 9px 9px 9px;
	border-color: rgba(255,255,255,0) rgba(255,255,255,0) #fff rgba(255,255,255,0);
	position: absolute; right: -9px; top: 0; width: 0; height: 0; margin-top: 1px;
}

.dropdown-menu    {box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.15)}

.dropdown-menu em {
    font-style: normal;
    font-size: 10px;
    line-height: 20px;
    color: #4C6569;
}

.dropdown-menu em.sl-icon-line-curved {
    font-size: 16px;
}

.btn-group.dropup .dropdown-menu        { margin-bottom: 15px } 
.btn-group.dropup .dropdown-arrow       { margin-bottom: 8px; left: auto; right: 5px; border-color: #bbb rgba(68,68,68,0) rgba(68,68,68,0) rgba(68,68,68,0); }
.btn-group.dropup .dropdown-arrow:after {

    margin: -9px 0 0 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff rgba(255,255,255, 0) rgba(255,255,255, 0) rgba(255,255,255, 0);
}

strong.prld {opacity: 0.3; filter: alpha(opacity=30); font-weight: normal}

/* 

    Dropdown ajuda cerca

*/

.dropdown-menu.adv-srch-dropdown          { 
    max-width: 450px; 
    margin-top: 5px !important; 
    left: -35px;
}

.dropdown-menu.adv-srch-dropdown li a {
    align-items: center;
}

.dropdown-menu.adv-srch-dropdown li a i {
    margin-right: 8px;
}


.dropdown-menu.adv-srch-dropdown .divider {
    margin: 12px auto 8px auto;
    padding: 0
}

.btn-group.select.drop_right.open > .dropdown-arrow { left: 13px; right: auto }

.slc_srch .btn-group.select .dropdown-menu p { width: 450px }

/* 

    Dropdown invisible
    
*/

.btn-more { position: absolute; left: unset; top: 50%; right: 0; bottom: unset }

.box_search .btn-group.select.btn-more button.dropdown-toggle,
            .btn-group.select.btn-more button.dropdown-toggle        { 
                border: 0; 
                border-radius: 0; 
                background: 0; 
                padding: 0; 
                margin: -12px 0 0 0; 
                width: 25px; 
                height: 24px 
            }
.box_search .btn-group.select.btn-more button.dropdown-toggle:hover,
            .btn-group.select.btn-more button.dropdown-toggle:hover  { border: 0; border-radius: 0; background: 0 }
            .btn-group.select.btn-more button.dropdown-toggle .caret { right: 0 }
.box_search .btn-group.select.btn-more .dropdown-menu,
            .btn-group.select.btn-more .dropdown-menu                { left: auto; right: 0 }
.box_search .btn-group.select.btn-more .dropdown-arrow,
            .btn-group.select.btn-more .dropdown-arrow               { right: 10px }

.toggle-menu-tree{ display: none; }

div#slct_ops_cats::before {
    display: none;
}

#slct_ops_cats ul.dropdown-menu.dropdown-large .icon {
    padding-right: 0px;
    margin-right: 0px;
}

#slct_ops_cats ul.dropdown-menu.dropdown-large button .icon {
    padding-right: 8px;
    margin-right: 0px;
}

.sl-card-channel {
    .sl-dropdown {
        ul {
            width: auto;
        }
    }
}