/*

    ## Històric

*/

#box_hist_vw { z-index: 6;
	display: none;
	position: fixed;
	top: 80px;
	left: 224px;
	bottom: 61px;
	width: 250px;
	border-right: 1px solid #D5DFE1;
	background-color: #FFFFFF;
}

.minus_left #box_hist_vw {
	left: 80px;
	top: 80px;
}

#box_content.hist_vw_act #box_hist_vw  { display: block }
#box_content.hist_vw_act .box_head_sec,
#box_content.hist_vw_act .box_msg_action,
#box_content.hist_vw_act .form         { margin-left: 250px; width: auto }

#box_content.hist_vw_act .box_head_sec {
	width: calc(100% - 250px);
}

#box_hist_vw .hst_head {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	padding: 32px 20px;
	font-size: 14px;
	line-height: 16px;
}

#box_hist_vw .hst_head select {
	width: 100%;
	height: 40px;
	max-width: 260px;
	min-width: auto;
}

#box_hist_vw .hst_list {
	position: absolute;
	top: 104px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto }

#box_hist_vw .hst_head > span { display: block; padding-top: 10px }

#box_hist_vw option[value=""] { color: #444; background: #eee }

#box_hist_vw .hst_list ul {
	position: relative;
	display: block;
	padding-left: 0;
	list-style: none;
	margin: 0 20px;
}

#box_hist_vw .hst_list ul li {
	position: relative;
	display: block;
	padding: 20px 4px 24px;
	border-bottom: 1px solid #D5DFE1;
	font-size: 12px;
	line-height: 20px;
}

#box_hist_vw .hst_list ul li .nm_ttl {
	color: #2E383A;
}


#box_hist_vw .hst_list ul li .dt_ttl {
	color: #4C6569;
}

#box_hist_vw .hst_list ul li .dt_ttl,
#box_hist_vw .hst_list ul li .nm_ttl {
	font-weight: 400;
	position: relative;
	display: flex;
	align-items: flex-start;
}

#box_hist_vw .hst_list ul li .nm_ttl strong a {
	font-weight: 600;
}

#box_hist_vw .hst_list ul li .nm_ttl .icon {
	padding-right: 4px;
	vertical-align: -1px;
}

#box_hist_vw .hst_list ul li .nm_ttl strong a i {
	font-size: 14px;
}

#box_hist_vw .hst_list ul.fld_lst                      { padding-left: 15px }
/*
#box_hist_vw .hst_list ul.fld_lst.mlt                  {}
*/
#box_hist_vw .hst_list ul.fld_lst li {
	padding: 4px 0;
	border-bottom: none;
}

#box_hist_vw .hst_list ul.fld_lst li:last-child      { border: none }

#box_hist_vw .hst_list ul.fld_lst li em {
	display: block;
	font-style: normal;
	color: #4C6569;
}

#box_hist_vw .hst_list ul.fld_lst li em.link_sim:hover {
	cursor: pointer;
	color: #3D9487;
}

#box_hist_vw .hst_list ul.fld_lst ol    { padding-left: 10px }
#box_hist_vw .hst_list ul.fld_lst ol li { padding: 5px 0 0 0; border: none }


#box_hist_vw          .ico_lst_opn,
#box_hist_vw          .ico_lst_cls              { display: none; vertical-align: sub }
#box_hist_vw          .ico_lst_cls,
#box_hist_vw .fls_opn .ico_lst_opn              { display: inline }
#box_hist_vw .fls_opn .ico_lst_cls,
#box_hist_vw          ul.fld_lst                { display: none }
#box_hist_vw .fls_opn ul.fld_lst {
	display: block;
	padding: 24px 0 0 0;
  margin: 0;
}

#box_hist_vw textarea {
	border: 0;
	padding: 0;
	padding-right:5px;
	background: none;
	font-size: 12px;
	line-height: 20px;
	color: #2E383A;
	overflow:hidden;
	&::-webkit-scrollbar {
		display: none;
	}
}

.bx_act { background: none; text-align: right; display: flex!important; justify-content: flex-end; }

.bx_wrk_data {
	display: flex;
	flex-direction: column;
}

.bx_wrk_avatar {
	width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ABC0C4;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
	margin-right: 10px;
	min-width: 32px;
	i {
		padding-right: 0!important;
		font-size: 16px;
	}
}

#box_hist_vw .bx_pgs {
	position: relative;
	display: block;
	width: 100%;	
	padding: 24px;
	margin: 0;

}

#box_hist_vw .bx_pgs .btn-group { margin: 0 }
#box_hist_vw .bx_pgs .icon      { padding: 0; overflow: hidden; font-size: 90%; line-height: 90% }

.wrk_empty_state {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px 48px;
}

.wrk_empty_icon {
	font-size: 48px;
	color: #BAAA8A;
	max-height: 48px;
	margin-bottom: 16px;
}

.wrk_empty_content {
	color: #4C6569;
	text-align: center;
}
