@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@font-face {
  font-family: "remixicon";
  src: url("../assets/remixicon/remixicon.woff2?t=1590207869815") format("woff2"), url("../assets/remixicon/remixicon.woff?t=1590207869815") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "sally-icon";
  src: url("../assets/sally-icon/sally-icon.woff") format("woff"), url("../assets/sally-icon/sally-icon.woff2") format("woff2");
  font-display: swap;
}
html, body {
  box-sizing: border-box;
  background: #F8F8F8 !important;
  font-size: unset;
  line-height: unset;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* scrollbar global */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}

*::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin: unset;
}

p {
  margin: unset;
}

button {
  background-color: unset;
  margin: unset;
  padding: unset;
  border-width: unset;
  border-style: unset;
  border-color: unset;
  border-image: unset;
}

.sl-modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 700;
  background-color: #2E383A;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  top: 0;
}
.sl-modal-backdrop.sl-modal-show {
  opacity: 0.8;
  pointer-events: auto;
}
.sl-modal-backdrop.sl-modal-hide {
  opacity: 0;
  pointer-events: none;
}

.sl-modal, .sl-modal-acknowledgment, .sl-modal-acknowledgment-warning, .sl-modal-acknowledgment-info, .sl-modal-acknowledgment-success {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  z-index: 800;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  pointer-events: none;
  max-height: calc(100% - 64px);
}
.sl-modal.sl-modal-show, .sl-modal-show.sl-modal-acknowledgment, .sl-modal-show.sl-modal-acknowledgment-warning, .sl-modal-show.sl-modal-acknowledgment-info, .sl-modal-show.sl-modal-acknowledgment-success {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.sl-modal.sl-modal-show.sl-modal-top, .sl-modal-show.sl-modal-top.sl-modal-acknowledgment, .sl-modal-show.sl-modal-top.sl-modal-acknowledgment-warning, .sl-modal-show.sl-modal-top.sl-modal-acknowledgment-info, .sl-modal-show.sl-modal-top.sl-modal-acknowledgment-success {
  margin: 0 auto;
  transform: translateY(52px);
}
.sl-modal.sl-modal-hide, .sl-modal-hide.sl-modal-acknowledgment, .sl-modal-hide.sl-modal-acknowledgment-warning, .sl-modal-hide.sl-modal-acknowledgment-info, .sl-modal-hide.sl-modal-acknowledgment-success {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}
.sl-modal.sl-modal-hide.sl-modal-top, .sl-modal-hide.sl-modal-top.sl-modal-acknowledgment, .sl-modal-hide.sl-modal-top.sl-modal-acknowledgment-warning, .sl-modal-hide.sl-modal-top.sl-modal-acknowledgment-info, .sl-modal-hide.sl-modal-top.sl-modal-acknowledgment-success {
  margin: 0 auto;
  transform: translateY(-100%);
}
.sl-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px 0 32px;
  color: #2E383A;
}
.sl-modal-header .sl-modal-close {
  font-size: 20px;
  color: #2E383A;
}
.sl-modal-body {
  padding: 28px 32px 0 32px;
  flex: 2;
  overflow-y: auto;
}
.sl-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
  padding: 20px 32px;
}
.sl-modal-footer-buttons {
  margin-top: 28px;
  background-color: #F8F8F8;
}
.sl-modal-small {
  max-width: 500px;
}
.sl-modal-medium {
  max-width: 680px;
}
.sl-modal-large {
  max-width: 832px;
}
.sl-modal-xlarge {
  max-width: 1024px;
}
.sl-modal-subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.sl-modal-subtitle-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.sl-modal-acknowledgment .sl-modal-body, .sl-modal-acknowledgment-warning .sl-modal-body, .sl-modal-acknowledgment-info .sl-modal-body, .sl-modal-acknowledgment-success .sl-modal-body {
  text-align: center;
  padding-bottom: 0;
}
.sl-modal-acknowledgment .sl-modal-body i, .sl-modal-acknowledgment-warning .sl-modal-body i, .sl-modal-acknowledgment-info .sl-modal-body i, .sl-modal-acknowledgment-success .sl-modal-body i {
  font-size: 45px;
  display: inline-block;
}
.sl-modal-acknowledgment .sl-modal-footer, .sl-modal-acknowledgment-warning .sl-modal-footer, .sl-modal-acknowledgment-info .sl-modal-footer, .sl-modal-acknowledgment-success .sl-modal-footer {
  background-color: #ffffff;
  justify-content: center;
}
.sl-modal-acknowledgment-success i {
  color: #0BA815;
}
.sl-modal-acknowledgment-info i {
  color: #2977D1;
}
.sl-modal-acknowledgment-warning i {
  color: #D57300;
}
.sl-modal-doublecheck .alert {
  display: flex;
}
.sl-modal-warning {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin: 0px 0px 0px 4px;
}
.sl-modal-warning-container {
  display: flex;
  align-items: flex-start;
}
.sl-modal-promo-header {
  padding: 16px 16px;
  position: absolute;
  top: 0;
  right: 0;
}
.sl-modal-promo-header button:hover {
  background-color: transparent;
  color: #638688;
}
.sl-modal-promo-body img {
  max-width: 100%;
}
.sl-modal-promo-footer .sl-content-secondary {
  max-width: 362px;
}

.sl-modal-draganddrop .modal-body-new {
  padding: 0px !important;
  margin-top: 0px !important;
}
.sl-modal-draganddrop .modal-body-new .sl-draganddrop-cont {
  display: inline-block;
  padding: 28px 32px;
}
.sl-modal-draganddrop .form_slim form {
  margin: 28px 32px;
}
.sl-modal-draganddrop .form_slim.form_slim_hide form {
  margin: 0 32px;
}
.sl-modal-draganddrop .form_slim.form_slim_hide form .btn-group {
  margin-top: 0 !important;
}
.sl-modal-draganddrop .form_slim.form_slim_hide form .alert {
  margin-top: 28px !important;
}

/*
	## Generate Typography Types ##
	Generates font-size and line-height equal to respective tokens in design system.
	Implementation example:
	@include generate-type("large");
*/
.sl-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
}
.sl-card-top-left {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
}
.sl-card-top-right {
  position: absolute;
  left: auto;
  right: 12px;
  top: 12px;
  z-index: 1;
}
.sl-card.sl-card-fluid {
  width: 100%;
  overflow: hidden;
}
.sl-card.sl-card-fluid .sl-card-content {
  width: 100%;
}
.sl-card.sl-card-catalog {
  max-width: 350px;
  min-width: 188px;
}
.sl-card.sl-card-catalog .sl-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.sl-card.sl-card-catalog:hover {
  background-color: #EAEEF0;
  transition: all 0.3s;
}
.sl-card.sl-card-catalog:hover .sl-card-selector {
  display: flex;
}
.sl-card.sl-card-catalog:hover .sl-card-subtitle.sl-card-subtitle-hide {
  display: none;
  height: 22px;
}
.sl-card.sl-card-catalog:hover .sl-card-details {
  display: flex;
}
.sl-card .sl-card-content {
  display: flex;
  flex-direction: column;
  width: 228px;
  margin: auto;
}
.sl-card .sl-card-error-icon {
  position: absolute;
  left: auto;
  right: 0;
  top: 33px;
  background: #FDEDED;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  color: #D2322C;
  text-align: center;
  line-height: 1.4;
  border: 1px solid #FDEDED;
}
.sl-card .sl-card-image {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 1px solid #D5DFE1;
  overflow: hidden;
}
.sl-card .sl-card-image a {
  width: 100%;
  height: 100%;
}
.sl-card .sl-thumbnail {
  border-radius: 0;
}
.sl-card .sl-card-icon-bar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 12px;
}
.sl-card .sl-card-info {
  padding: 12px 0 0 0;
  display: flex;
}
.sl-card .sl-card-selector {
  display: none;
  height: 24px;
}
.sl-card .sl-card-selector .sl-checkbox-small {
  margin: 0px;
}
.sl-card .sl-card-selector .sl-checkbox-small .sl-checkbox-icon-check-small {
  top: 0px;
  left: 0px;
  margin: 0px;
}
.sl-card .sl-card-details {
  display: none;
}
.sl-card .sl-card-details {
  height: 22px;
}
.sl-card .sl-card-subtitle.sl-card-subtitle-hide {
  height: 22px;
}
.sl-card.sl-card-selected > .sl-card-content > .sl-card-image {
  border: 1px solid #3D9487;
}
.sl-card.sl-card-selected .sl-card-selector {
  display: flex;
}

.sl-card-frame {
  background: white;
  border: 1px solid #D5DFE1;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.sl-card-activity {
  justify-content: space-between;
}
.sl-card-activity-top {
  justify-content: flex-start;
}

.sl-card-channel {
  max-width: 307px;
  min-height: 238px;
  justify-content: space-between;
}
.sl-card-channel-connect {
  width: 307px;
}
.sl-card-channel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.sl-card-channel-header-left .sl-avatar {
  background: #F8F8F8;
  color: #CFDADD;
}
.sl-card-channel-header-right i {
  color: #4C6569;
}
.sl-card-channel-header-logo img {
  height: 32px;
  width: auto;
}
.sl-card-channel-header-notification {
  position: relative;
}
.sl-card-channel-header-notification-badge {
  background-color: #D2322C;
  border-radius: 100%;
  height: 6px;
  width: 6px;
  position: absolute;
  top: 4px;
  right: 0px;
  border: 1px solid #fff;
}
.sl-card-channel-title {
  color: #2E383A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  max-width: 100%;
  word-break: break-word;
}
.sl-card-channel-content {
  margin-bottom: 14px;
}
.sl-card-channel-content-subtitle {
  margin-bottom: 4px;
  color: #4C6569;
  font-size: 12px;
  line-height: 20px;
}
.sl-card-channel-content-centered {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sl-card-channel-content-logo {
  max-height: 22px;
  width: auto;
}
.sl-card-channel-content-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
}
.sl-card-channel-content-item.sl-title {
  font-size: 16px;
  line-height: 24px;
  color: #2E383A;
  margin-bottom: 8px;
  font-weight: 700;
}
.sl-card-channel-content-item.sl-success,
.sl-card-channel-content-item .sl-content-success i {
  color: #2977D1;
}
.sl-card-channel-content-item i {
  vertical-align: middle;
  font-size: 16px;
}
.sl-card-channel-content-item.no-flex {
  display: block;
  align-items: none;
}
.sl-card-channel-content i {
  /*color: $linkSecondaryDefault; <-- quitado porque no deja marcar el icono en rojo de alerta cuando es necesario en Control de Precios, por ejemplo */
}
.sl-card-channel-content .sl-success,
.sl-card-channel-content .sl-content-success,
.sl-card-channel-content .sl-content-success i {
  color: #2977D1;
}
.sl-card-channel-footer {
  margin-top: auto;
  display: flex;
  align-items: center;
}
.sl-card-channel.not-active .sl-avatar {
  width: 40px;
  height: 40px;
}
.sl-card-channel.not-active .sl-card-channel-content {
  min-height: 96px;
}
.sl-card-channel.not-active .sl-card-channel-content-item.sl-title, .sl-card-channel.blocked .sl-card-channel-content-item.sl-title, .sl-card-channel.disabled .sl-card-channel-content-item.sl-title {
  margin-bottom: 4px;
}
.sl-card-channel.blocked, .sl-card-channel.disabled {
  padding: 40px 24px 24px 24px;
  align-items: center;
}
.sl-card-channel.blocked .sl-card-channel-content, .sl-card-channel.disabled .sl-card-channel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sl-card-channel.blocked .sl-card-channel-content i, .sl-card-channel.disabled .sl-card-channel-content i {
  font-size: 60px;
  color: #4C6569;
  margin-bottom: 4px;
}
.sl-card-channel:hover {
  border: 1px solid #3D9487;
}

.sl-button, .sl-button-group, .sl-button-group-right, .sl-button-group-right-two, .sl-button-group-center, .sl-button-group-left, .sl-button-header, .sl-button-header-tiny, .sl-button-header-small, .sl-button-header-medium, .sl-button-header-large, .sl-button-state-visible, .sl-button-state-visible-tiny, .sl-button-state-visible-small, .sl-button-state-visible-medium, .sl-button-state-visible-large, .sl-button-state-draft, .sl-button-state-draft-tiny, .sl-button-state-draft-small, .sl-button-state-draft-medium, .sl-button-state-draft-large, .sl-button-state-invisible, .sl-button-state-invisible-tiny, .sl-button-state-invisible-small, .sl-button-state-invisible-medium, .sl-button-state-invisible-large, .sl-button-outline-transparent, .sl-button-outline-transparent-tiny, .sl-button-outline-transparent-small, .sl-button-outline-transparent-medium, .sl-button-outline-transparent-large, .sl-button-outline, .sl-button-outline-tiny, .sl-button-outline-small, .sl-button-outline-medium, .sl-button-outline-large, .sl-button-highlight, .sl-button-highlight-tiny, .sl-button-highlight-small, .sl-button-highlight-medium, .sl-button-highlight-large, .sl-button-danger, .sl-button-danger-tiny, .sl-button-danger-small, .sl-button-danger-medium, .sl-button-danger-large, .sl-button-tertiary, .sl-button-tertiary-tiny, .sl-button-tertiary-small, .sl-button-tertiary-medium, .sl-button-tertiary-large, .sl-button-secondary, .sl-button-secondary-tiny, .sl-button-secondary-small, .sl-button-secondary-medium, .sl-button-secondary-large, .sl-button-primary, .sl-button-primary-tiny, .sl-button-primary-small, .sl-button-primary-medium, .sl-button-primary-large {
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  outline: 0;
  transition: all 0.3s ease;
  position: relative;
}
.sl-button-primary, .sl-button-primary-tiny, .sl-button-primary-small, .sl-button-primary-medium, .sl-button-primary-large {
  background-color: #387B77;
  color: #ffffff;
}
.sl-button-primary-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-primary-large:hover {
  background-color: #3D9487;
  color: #ffffff;
  border-color: transparent;
}
.sl-button-primary-large:active {
  background-color: #205A5A;
}
.sl-button-primary-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-primary-large:focus {
  color: #ffffff;
}
.sl-button-primary-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-primary-medium:hover {
  background-color: #3D9487;
  color: #ffffff;
  border-color: transparent;
}
.sl-button-primary-medium:active {
  background-color: #205A5A;
}
.sl-button-primary-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-primary-medium:focus {
  color: #ffffff;
}
.sl-button-primary-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-primary-small:hover {
  background-color: #3D9487;
  color: #ffffff;
  border-color: transparent;
}
.sl-button-primary-small:active {
  background-color: #205A5A;
}
.sl-button-primary-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-primary-small:focus {
  color: #ffffff;
}
.sl-button-primary-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-primary-tiny:hover {
  background-color: #3D9487;
  color: #ffffff;
  border-color: transparent;
}
.sl-button-primary-tiny:active {
  background-color: #205A5A;
}
.sl-button-primary-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-primary-tiny:focus {
  color: #ffffff;
}
.sl-button-secondary, .sl-button-secondary-tiny, .sl-button-secondary-small, .sl-button-secondary-medium, .sl-button-secondary-large {
  background-color: #D5DFE1;
  color: #2E383A;
}
.sl-button-secondary-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-secondary-large:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-secondary-large:active {
  background-color: #CFDADD;
}
.sl-button-secondary-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-secondary-large:focus {
  color: #2E383A;
}
.sl-button-secondary-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-secondary-medium:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-secondary-medium:active {
  background-color: #CFDADD;
}
.sl-button-secondary-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-secondary-medium:focus {
  color: #2E383A;
}
.sl-button-secondary-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-secondary-small:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-secondary-small:active {
  background-color: #CFDADD;
}
.sl-button-secondary-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-secondary-small:focus {
  color: #2E383A;
}
.sl-button-secondary-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-secondary-tiny:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-secondary-tiny:active {
  background-color: #CFDADD;
}
.sl-button-secondary-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-secondary-tiny:focus {
  color: #2E383A;
}
.sl-button-tertiary, .sl-button-tertiary-tiny, .sl-button-tertiary-small, .sl-button-tertiary-medium, .sl-button-tertiary-large {
  background-color: transparent;
  color: #2E383A;
}
.sl-button-tertiary-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-tertiary-large:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-tertiary-large:active {
  background-color: #D5DFE1;
}
.sl-button-tertiary-large:disabled {
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-tertiary-large:focus {
  color: #2E383A;
}
.sl-button-tertiary-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-tertiary-medium:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-tertiary-medium:active {
  background-color: #D5DFE1;
}
.sl-button-tertiary-medium:disabled {
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-tertiary-medium:focus {
  color: #2E383A;
}
.sl-button-tertiary-medium.active {
  background-color: #D5DFE1;
}
.sl-button-tertiary-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-tertiary-small:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-tertiary-small:active {
  background-color: #D5DFE1;
}
.sl-button-tertiary-small:disabled {
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-tertiary-small:focus {
  color: #2E383A;
}
.sl-button-tertiary-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-tertiary-tiny:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-tertiary-tiny:active {
  background-color: #D5DFE1;
}
.sl-button-tertiary-tiny:disabled {
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-tertiary-tiny:focus {
  color: #2E383A;
}
.sl-button-danger, .sl-button-danger-tiny, .sl-button-danger-small, .sl-button-danger-medium, .sl-button-danger-large {
  background-color: #FDEDED;
  color: #D2322C;
}
.sl-button-danger-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-danger-large:hover {
  background-color: #FAD7D6;
  color: #D2322C;
  border-color: transparent;
}
.sl-button-danger-large:active {
  background-color: #F7BCBA;
}
.sl-button-danger-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-danger-large:focus {
  color: #D2322C;
}
.sl-button-danger-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-danger-medium:hover {
  background-color: #FAD7D6;
  color: #D2322C;
  border-color: transparent;
}
.sl-button-danger-medium:active {
  background-color: #F7BCBA;
}
.sl-button-danger-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-danger-medium:focus {
  color: #D2322C;
}
.sl-button-danger-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-danger-small:hover {
  background-color: #FAD7D6;
  color: #D2322C;
  border-color: transparent;
}
.sl-button-danger-small:active {
  background-color: #F7BCBA;
}
.sl-button-danger-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-danger-small:focus {
  color: #D2322C;
}
.sl-button-danger-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-danger-tiny:hover {
  background-color: #FAD7D6;
  color: #D2322C;
  border-color: transparent;
}
.sl-button-danger-tiny:active {
  background-color: #F7BCBA;
}
.sl-button-danger-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-danger-tiny:focus {
  color: #D2322C;
}
.sl-button-highlight, .sl-button-highlight-tiny, .sl-button-highlight-small, .sl-button-highlight-medium, .sl-button-highlight-large {
  background-color: #FBF2D0;
  color: #5A4B35;
}
.sl-button-highlight-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-highlight-large:hover {
  background-color: #F7EABB;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-highlight-large:active {
  background-color: #EEE5C0;
}
.sl-button-highlight-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-highlight-large:focus {
  color: #5A4B35;
}
.sl-button-highlight-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-highlight-medium:hover {
  background-color: #F7EABB;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-highlight-medium:active {
  background-color: #EEE5C0;
}
.sl-button-highlight-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-highlight-medium:focus {
  color: #5A4B35;
}
.sl-button-highlight-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-highlight-small:hover {
  background-color: #F7EABB;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-highlight-small:active {
  background-color: #EEE5C0;
}
.sl-button-highlight-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-highlight-small:focus {
  color: #5A4B35;
}
.sl-button-highlight-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-highlight-tiny:hover {
  background-color: #F7EABB;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-highlight-tiny:active {
  background-color: #EEE5C0;
}
.sl-button-highlight-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-highlight-tiny:focus {
  color: #5A4B35;
}
.sl-button-outline, .sl-button-outline-tiny, .sl-button-outline-small, .sl-button-outline-medium, .sl-button-outline-large {
  background-color: #ffffff;
  color: #2E383A;
  outline: 1px solid #D5DFE1 !important;
}
.sl-button-outline:focus, .sl-button-outline-tiny:focus, .sl-button-outline-small:focus, .sl-button-outline-medium:focus, .sl-button-outline-large:focus {
  outline: 1px solid #D5DFE1 !important;
}
.sl-button-outline-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-outline-large:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-large:active {
  background-color: #D5DFE1;
  background-color: #D5DFE1;
  outline: 0;
}
.sl-button-outline-large:disabled {
  background-color: #EAEEF0;
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-large:focus {
  color: #2E383A;
}
.sl-button-outline-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-outline-medium:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-medium:active {
  background-color: #D5DFE1;
  background-color: #D5DFE1;
  outline: 0;
}
.sl-button-outline-medium:disabled {
  background-color: #EAEEF0;
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-medium:focus {
  color: #2E383A;
}
.sl-button-outline-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-outline-small:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-small:active {
  background-color: #D5DFE1;
  background-color: #D5DFE1;
  outline: 0;
}
.sl-button-outline-small:disabled {
  background-color: #EAEEF0;
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-small:focus {
  color: #2E383A;
}
.sl-button-outline-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-outline-tiny:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-tiny:active {
  background-color: #D5DFE1;
  background-color: #D5DFE1;
  outline: 0;
}
.sl-button-outline-tiny:disabled {
  background-color: #EAEEF0;
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-tiny:focus {
  color: #2E383A;
}
.sl-button-outline-transparent, .sl-button-outline-transparent-tiny, .sl-button-outline-transparent-small, .sl-button-outline-transparent-medium, .sl-button-outline-transparent-large {
  background-color: transparent;
  color: #2E383A;
  outline: 1px solid #D5DFE1 !important;
}
.sl-button-outline-transparent:focus, .sl-button-outline-transparent-tiny:focus, .sl-button-outline-transparent-small:focus, .sl-button-outline-transparent-medium:focus, .sl-button-outline-transparent-large:focus {
  outline: 1px solid #D5DFE1 !important;
}
.sl-button-outline-transparent-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-outline-transparent-large:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-transparent-large:active {
  background-color: #D5DFE1;
}
.sl-button-outline-transparent-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-transparent-large:focus {
  color: #2E383A;
}
.sl-button-outline-transparent-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-outline-transparent-medium:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-transparent-medium:active {
  background-color: #D5DFE1;
}
.sl-button-outline-transparent-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-transparent-medium:focus {
  color: #2E383A;
}
.sl-button-outline-transparent-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-outline-transparent-small:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-transparent-small:active {
  background-color: #D5DFE1;
}
.sl-button-outline-transparent-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-transparent-small:focus {
  color: #2E383A;
}
.sl-button-outline-transparent-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-outline-transparent-tiny:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-outline-transparent-tiny:active {
  background-color: #D5DFE1;
}
.sl-button-outline-transparent-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-outline-transparent-tiny:focus {
  color: #2E383A;
}
.sl-button-state-invisible, .sl-button-state-invisible-tiny, .sl-button-state-invisible-small, .sl-button-state-invisible-medium, .sl-button-state-invisible-large {
  background-color: #D5DFE1;
  color: #2E383A;
}
.sl-button-state-invisible-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-state-invisible-large:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-state-invisible-large:active {
  background-color: #D5DFE1;
}
.sl-button-state-invisible-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-invisible-large:focus {
  color: #2E383A;
}
.sl-button-state-invisible-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-state-invisible-medium:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-state-invisible-medium:active {
  background-color: #D5DFE1;
}
.sl-button-state-invisible-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-invisible-medium:focus {
  color: #2E383A;
}
.sl-button-state-invisible-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-state-invisible-small:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-state-invisible-small:active {
  background-color: #D5DFE1;
}
.sl-button-state-invisible-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-invisible-small:focus {
  color: #2E383A;
}
.sl-button-state-invisible-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-state-invisible-tiny:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-state-invisible-tiny:active {
  background-color: #D5DFE1;
}
.sl-button-state-invisible-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-invisible-tiny:focus {
  color: #2E383A;
}
.sl-button-state-draft, .sl-button-state-draft-tiny, .sl-button-state-draft-small, .sl-button-state-draft-medium, .sl-button-state-draft-large {
  background-color: #FBF2D0;
  color: #5A4B35;
}
.sl-button-state-draft-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-state-draft-large:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-state-draft-large:active {
  background-color: #FBF2D0;
}
.sl-button-state-draft-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-draft-large:focus {
  color: #5A4B35;
}
.sl-button-state-draft-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-state-draft-medium:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-state-draft-medium:active {
  background-color: #FBF2D0;
}
.sl-button-state-draft-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-draft-medium:focus {
  color: #5A4B35;
}
.sl-button-state-draft-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-state-draft-small:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-state-draft-small:active {
  background-color: #FBF2D0;
}
.sl-button-state-draft-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-draft-small:focus {
  color: #5A4B35;
}
.sl-button-state-draft-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-state-draft-tiny:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border-color: transparent;
}
.sl-button-state-draft-tiny:active {
  background-color: #FBF2D0;
}
.sl-button-state-draft-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-draft-tiny:focus {
  color: #5A4B35;
}
.sl-button-state-visible, .sl-button-state-visible-tiny, .sl-button-state-visible-small, .sl-button-state-visible-medium, .sl-button-state-visible-large {
  background-color: #BFF5C5;
  color: #05570A;
}
.sl-button-state-visible-large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  height: 48px;
}
.sl-button-state-visible-large:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border-color: transparent;
}
.sl-button-state-visible-large:active {
  background-color: #BFF5C5;
}
.sl-button-state-visible-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-visible-large:focus {
  color: #05570A;
}
.sl-button-state-visible-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
}
.sl-button-state-visible-medium:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border-color: transparent;
}
.sl-button-state-visible-medium:active {
  background-color: #BFF5C5;
}
.sl-button-state-visible-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-visible-medium:focus {
  color: #05570A;
}
.sl-button-state-visible-small {
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-state-visible-small:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border-color: transparent;
}
.sl-button-state-visible-small:active {
  background-color: #BFF5C5;
}
.sl-button-state-visible-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-visible-small:focus {
  color: #05570A;
}
.sl-button-state-visible-tiny {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px;
  height: 24px;
}
.sl-button-state-visible-tiny:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border-color: transparent;
}
.sl-button-state-visible-tiny:active {
  background-color: #BFF5C5;
}
.sl-button-state-visible-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-state-visible-tiny:focus {
  color: #05570A;
}
.sl-button-header, .sl-button-header-tiny, .sl-button-header-small, .sl-button-header-medium, .sl-button-header-large {
  color: #2E383A;
}
.sl-button-header-large {
  font-size: 16px;
  line-height: 24px;
  position: relative;
}
.sl-button-header-large:hover {
  background-color: transparent;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-header-large:active {
  background-color: transparent;
}
.sl-button-header-large:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-header-large:focus {
  color: #2E383A;
}
.sl-button-header-medium {
  font-size: 14px;
  line-height: 24px;
  position: relative;
}
.sl-button-header-medium:hover {
  background-color: transparent;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-header-medium:active {
  background-color: transparent;
}
.sl-button-header-medium:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-header-medium:focus {
  color: #2E383A;
}
.sl-button-header-small {
  font-size: 12px;
  line-height: 20px;
  position: relative;
}
.sl-button-header-small:hover {
  background-color: transparent;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-header-small:active {
  background-color: transparent;
}
.sl-button-header-small:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-header-small:focus {
  color: #2E383A;
}
.sl-button-header-tiny {
  font-size: 12px;
  line-height: 20px;
  position: relative;
}
.sl-button-header-tiny:hover {
  background-color: transparent;
  color: #2E383A;
  border-color: transparent;
}
.sl-button-header-tiny:active {
  background-color: transparent;
}
.sl-button-header-tiny:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-header-tiny:focus {
  color: #2E383A;
}
.sl-button-group, .sl-button-group-right, .sl-button-group-right-two, .sl-button-group-center, .sl-button-group-left {
  background-color: #ffffff;
  color: #4C6569;
  border: 1px solid #D5DFE1;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  height: 32px;
}
.sl-button-group.selected, .selected.sl-button-group-right, .selected.sl-button-group-right-two, .selected.sl-button-group-center, .selected.sl-button-group-left {
  background-color: #D5DFE1;
}
.sl-button-group:hover, .sl-button-group-right:hover, .sl-button-group-right-two:hover, .sl-button-group-center:hover, .sl-button-group-left:hover {
  background-color: #EAEEF0;
  color: #4C6569;
  border: 1px solid transparent;
}
.sl-button-group:active, .sl-button-group-right:active, .sl-button-group-right-two:active, .sl-button-group-center:active, .sl-button-group-left:active {
  background-color: #D5DFE1;
}
.sl-button-group:disabled, .sl-button-group-right:disabled, .sl-button-group-right-two:disabled, .sl-button-group-center:disabled, .sl-button-group-left:disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
  cursor: default;
}
.sl-button-group:focus, .sl-button-group-right:focus, .sl-button-group-right-two:focus, .sl-button-group-center:focus, .sl-button-group-left:focus {
  background-color: #ffffff;
  color: #4C6569;
}
.sl-button-group-left {
  border-right: 1px solid #D5DFE1;
  border-radius: 4px 0px 0px 4px;
}
.sl-button-group-left:focus {
  border: 1px solid #3EAC93;
}
.sl-button-group-center {
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  order: 2;
}
.sl-button-group-center:hover {
  border-left: 0px;
  border-right: 0px;
}
.sl-button-group-center:focus {
  border-top: 1px solid #3EAC93;
  border-bottom: 1px solid #3EAC93;
}
.sl-button-group-center:focus ~ .sl-button-group-left {
  border-right: 1px solid #3EAC93;
}
.sl-button-group-center:focus ~ .sl-button-group-right, .sl-button-group-center:focus ~ .sl-button-group-right-two {
  border-left: 1px solid #3EAC93;
}
.sl-button-group-right, .sl-button-group-right-two {
  border-left: 1px solid #D5DFE1;
  border-radius: 0px 4px 4px 0px;
  order: 3;
}
.sl-button-group-right:focus, .sl-button-group-right-two:focus {
  border: 1px solid #3EAC93;
}
.sl-button-group-right-two {
  border-left: 0px;
}
.sl-button-group-right-two:focus {
  border-left: 0px;
}
.sl-button-group-right-two:focus ~ .sl-button-group-left {
  border-right: 1px solid #3EAC93;
}
.sl-button-header-medium {
  font-size: 24px;
  font-weight: 400;
}
.sl-button-icon, .sl-button-icon-right, .sl-button-icon-right-tiny, .sl-button-icon-right-small, .sl-button-icon-right-medium, .sl-button-icon-right-large, .sl-button-icon-left, .sl-button-icon-left-tiny, .sl-button-icon-left-small, .sl-button-icon-left-medium, .sl-button-icon-left-large, .sl-button-icon-only, .sl-button-icon-only-tiny, .sl-button-icon-only-small, .sl-button-icon-only-medium, .sl-button-icon-only-large {
  font-weight: 400;
  font-size: 24px;
}
.sl-button-icon-only-large {
  padding: 11px;
  width: 48px;
}
.sl-button-icon-only-medium {
  font-size: 20px;
  padding: 9px;
  width: 40px;
}
.sl-button-icon-only-small {
  font-size: 16px;
  padding: 7px;
  width: 32px;
}
.sl-button-icon-only-tiny {
  font-size: 16px;
  padding: 3px;
  width: 24px;
}
.sl-button-icon-left, .sl-button-icon-left-tiny, .sl-button-icon-left-small, .sl-button-icon-left-medium, .sl-button-icon-left-large {
  margin-right: 8px;
}
.sl-button-icon-left-medium {
  font-size: 20px;
}
.sl-button-icon-left-small {
  font-size: 16px;
}
.sl-button-icon-left-tiny {
  font-size: 16px;
  margin-right: 4px;
}
.sl-button-icon-right, .sl-button-icon-right-tiny, .sl-button-icon-right-small, .sl-button-icon-right-medium, .sl-button-icon-right-large {
  margin-left: 8px;
}
.sl-button-icon-right-medium {
  font-size: 20px;
}
.sl-button-icon-right-small {
  font-size: 16px;
}
.sl-button-icon-right-tiny {
  font-size: 16px;
  margin-left: 4px;
}

.sl-button-split-right {
  border-radius: 0 4px 4px 0 !important;
  margin-left: 0px !important;
}

.sl-button-split-left {
  border-radius: 4px 0 0 4px !important;
  margin-right: 1px !important;
}

.input-group.focus .input-group-btn .sl-button-extension.not-click.btn, .btn.sl-button-extension.not-click {
  border: 1px solid #D5DFE1;
  border-left: 0;
  color: #2E383A;
}

.input-group.focus .input-group-btn .btn.sl-button-extension:hover {
  background-color: white !important;
}

.sl-icon-only-link {
  color: #4C6569;
  cursor: pointer;
}
.sl-icon-only-link:hover {
  color: #2E383A;
}

.sl-tag, .sl-tag-disabled, .sl-tag-neutral-secondary, .sl-tag-neutral-secondary-small, .sl-tag-neutral-secondary-medium, .sl-tag-upgrade, .sl-tag-upgrade-small, .sl-tag-upgrade-medium, .sl-tag-success, .sl-tag-success-small, .sl-tag-success-medium, .sl-tag-warning, .sl-tag-warning-small, .sl-tag-warning-medium, .sl-tag-critical, .sl-tag-critical-small, .sl-tag-critical-medium, .sl-tag-info, .sl-tag-info-small, .sl-tag-info-medium, .sl-tag-normal, .sl-tag-normal-small, .sl-tag-normal-medium {
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
  text-transform: uppercase;
}
.sl-tag-lowercase {
  text-transform: none;
}
.sl-tag-normal, .sl-tag-normal-small, .sl-tag-normal-medium {
  background-color: #FBF2D0;
  color: #5A4B35;
}
.sl-tag-normal-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-normal-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-normal-removable {
  font-size: 16px;
  color: #2E383A;
  cursor: pointer;
}
.sl-tag-normal-removable:hover {
  color: #5A4B35;
}
.sl-tag-normal:hover, .sl-tag-normal-medium:hover, .sl-tag-normal-small:hover {
  color: #5A4B35;
}
.sl-tag-info, .sl-tag-info-small, .sl-tag-info-medium {
  background-color: #D7F3F9;
  color: #005583;
}
.sl-tag-info-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-info-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-info-removable {
  font-size: 16px;
  color: #2977D1;
  cursor: pointer;
}
.sl-tag-info-removable:hover {
  color: #2977D1;
}
.sl-tag-info:hover, .sl-tag-info-medium:hover, .sl-tag-info-small:hover {
  color: #2977D1;
}
.sl-tag-critical, .sl-tag-critical-small, .sl-tag-critical-medium {
  background-color: #F7BCBA;
  color: #55201E;
}
.sl-tag-critical-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-critical-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-critical-removable {
  font-size: 16px;
  color: #2E383A;
  cursor: pointer;
}
.sl-tag-critical-removable:hover {
  color: #55201E;
}
.sl-tag-critical:hover, .sl-tag-critical-medium:hover, .sl-tag-critical-small:hover {
  color: #55201E;
}
.sl-tag-warning, .sl-tag-warning-small, .sl-tag-warning-medium {
  background-color: #FFD9A0;
  color: #653600;
}
.sl-tag-warning-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-warning-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-warning-removable {
  font-size: 16px;
  color: #2E383A;
  cursor: pointer;
}
.sl-tag-warning-removable:hover {
  color: #653600;
}
.sl-tag-warning:hover, .sl-tag-warning-medium:hover, .sl-tag-warning-small:hover {
  color: #653600;
}
.sl-tag-success, .sl-tag-success-small, .sl-tag-success-medium {
  background-color: #BFF5C5;
  color: #05570A;
}
.sl-tag-success-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-success-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-success-removable {
  font-size: 16px;
  color: #2E383A;
  cursor: pointer;
}
.sl-tag-success-removable:hover {
  color: #BFF5C5;
}
.sl-tag-success:hover, .sl-tag-success-medium:hover, .sl-tag-success-small:hover {
  color: #05570A;
}
.sl-tag-upgrade, .sl-tag-upgrade-small, .sl-tag-upgrade-medium {
  background-color: #E6ECFF;
  color: #506099;
}
.sl-tag-upgrade-medium {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  max-height: 24px;
}
.sl-tag-upgrade-small {
  font-size: 10px;
  line-height: 20px;
  padding: 2px 8px;
  max-height: 20px;
}
.sl-tag-upgrade:hover, .sl-tag-upgrade-medium:hover, .sl-tag-upgrade-small:hover {
  background-color: #CED9FF;
}
.sl-tag-neutral-secondary, .sl-tag-neutral-secondary-small, .sl-tag-neutral-secondary-medium {
  background-color: #EAEEF0;
  color: #2E383A;
}
.sl-tag-neutral-secondary-medium {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  max-height: 40px;
}
.sl-tag-neutral-secondary-small {
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
}
.sl-tag-neutral-secondary-removable {
  font-size: 16px;
  color: #2E383A;
  cursor: pointer;
}
.sl-tag-neutral-secondary-removable:hover {
  color: #2E383A;
}
.sl-tag-neutral-secondary:hover, .sl-tag-neutral-secondary-medium:hover, .sl-tag-neutral-secondary-small:hover {
  color: #2E383A;
}
.sl-tag-disabled {
  background-color: #EAEEF0;
  color: #ABC0C4;
}

.sl-avatar, .sl-avatar-tiny, .sl-avatar-small, .sl-avatar-medium, .sl-avatar-large {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ABC0C4;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.sl-avatar .sl-avatar-image, .sl-avatar-tiny .sl-avatar-image, .sl-avatar-small .sl-avatar-image, .sl-avatar-medium .sl-avatar-image, .sl-avatar-large .sl-avatar-image {
  clip-path: circle(50% at center);
}
.sl-avatar .sl-avatar-initials, .sl-avatar-tiny .sl-avatar-initials, .sl-avatar-small .sl-avatar-initials, .sl-avatar-medium .sl-avatar-initials, .sl-avatar-large .sl-avatar-initials {
  text-transform: uppercase;
}
.sl-avatar-large {
  width: 72px;
  height: 72px;
}
.sl-avatar-large span {
  font-size: 24px;
}
.sl-avatar-large i {
  font-size: 36px;
}
.sl-avatar-medium {
  width: 48px;
  height: 48px;
}
.sl-avatar-medium span {
  font-size: 16px;
}
.sl-avatar-medium i {
  font-size: 22px;
}
.sl-avatar-small {
  width: 32px;
  height: 32px;
}
.sl-avatar-small span {
  font-size: 14px;
}
.sl-avatar-small i {
  font-size: 16px;
}
.sl-avatar-tiny {
  width: 24px;
  height: 24px;
}
.sl-avatar-tiny span {
  font-size: 12px;
}
.sl-avatar-tiny i {
  font-size: 12px;
}

.sl-avatar-group {
  display: flex;
}
.sl-avatar-group .sl-avatar-labeled:not(:first-child) {
  margin-left: -6px;
}
.sl-avatar-group .sl-avatar-labeled .sl-avatar-count {
  background-color: #EAEEF0;
}
.sl-avatar-group .sl-avatar-labeled .sl-avatar-count span {
  color: #4C6569;
  margin: 0;
}
.sl-avatar-group .sl-avatar-labeled .sl-avatar, .sl-avatar-group .sl-avatar-labeled .sl-avatar-large, .sl-avatar-group .sl-avatar-labeled .sl-avatar-medium, .sl-avatar-group .sl-avatar-labeled .sl-avatar-small, .sl-avatar-group .sl-avatar-labeled .sl-avatar-tiny {
  border: 2px solid #ffffff;
}
.sl-avatar-group .sl-avatar-labeled .sl-avatar .sl-avatar-image, .sl-avatar-group .sl-avatar-labeled .sl-avatar-large .sl-avatar-image, .sl-avatar-group .sl-avatar-labeled .sl-avatar-medium .sl-avatar-image, .sl-avatar-group .sl-avatar-labeled .sl-avatar-small .sl-avatar-image, .sl-avatar-group .sl-avatar-labeled .sl-avatar-tiny .sl-avatar-image {
  clip-path: circle(50% at center);
}

.sl-avatar-labeled {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}
.sl-avatar-labeled p {
  margin-left: 8px;
  color: #4C6569;
  flex: 1;
}

.sl-checkbox, .sl-checkbox-small {
  display: flex;
  min-height: 20px;
  min-width: 20px;
  position: relative;
  cursor: pointer;
}
.sl-checkbox-disabled {
  pointer-events: none !important;
}
.sl-checkbox-disabled * {
  transition: none !important;
}
.sl-checkbox-disabled span {
  color: #ABC0C4 !important;
}
.sl-checkbox-disabled .fui-checkbox-unchecked:before {
  color: #ABC0C4 !important;
}
.sl-checkbox-small {
  min-height: 16px;
  min-width: 16px;
}
.sl-checkbox input[type=checkbox], .sl-checkbox-small input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
}
.sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-check, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-check-small, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-check, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-check-small, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-line, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-line-small, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-line, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-line-small {
  background-color: #387B77;
  border: 1px solid #387B77;
}
.sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-check:before, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-check-small:before, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-check:before, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-check-small:before, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-line:before, .sl-checkbox input[type=checkbox]:checked ~ .sl-checkbox-icon-line-small:before, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-line:before, .sl-checkbox-small input[type=checkbox]:checked ~ .sl-checkbox-icon-line-small:before {
  display: block;
}
.sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small {
  background-color: #EAEEF0;
  border: none;
  cursor: default;
}
.sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check:after, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small:after, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line:after, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small:after {
  border: solid #ABC0C4;
}
.sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check:after, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-check-small:after {
  border-width: 0 2px 2px 0;
}
.sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line:after, .sl-checkbox input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line:after, .sl-checkbox-small input[type=checkbox][disabled] ~ .sl-checkbox-icon-line-small:after {
  border-width: 0 2px 0 0;
}
.sl-checkbox .sl-checkbox-icon, .sl-checkbox .sl-checkbox-icon-line, .sl-checkbox .sl-checkbox-icon-line-small, .sl-checkbox .sl-checkbox-icon-check, .sl-checkbox .sl-checkbox-icon-check-small, .sl-checkbox-small .sl-checkbox-icon, .sl-checkbox-small .sl-checkbox-icon-line, .sl-checkbox-small .sl-checkbox-icon-line-small, .sl-checkbox-small .sl-checkbox-icon-check, .sl-checkbox-small .sl-checkbox-icon-check-small {
  border: 1px solid #CFDADD;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 2px;
}
.sl-checkbox .sl-checkbox-icon:hover, .sl-checkbox .sl-checkbox-icon-line:hover, .sl-checkbox .sl-checkbox-icon-line-small:hover, .sl-checkbox .sl-checkbox-icon-check:hover, .sl-checkbox .sl-checkbox-icon-check-small:hover, .sl-checkbox-small .sl-checkbox-icon:hover, .sl-checkbox-small .sl-checkbox-icon-line:hover, .sl-checkbox-small .sl-checkbox-icon-line-small:hover, .sl-checkbox-small .sl-checkbox-icon-check:hover, .sl-checkbox-small .sl-checkbox-icon-check-small:hover {
  border: 1px solid #387B77;
}
.sl-checkbox .sl-checkbox-icon:focus, .sl-checkbox .sl-checkbox-icon-line:focus, .sl-checkbox .sl-checkbox-icon-line-small:focus, .sl-checkbox .sl-checkbox-icon-check:focus, .sl-checkbox .sl-checkbox-icon-check-small:focus, .sl-checkbox-small .sl-checkbox-icon:focus, .sl-checkbox-small .sl-checkbox-icon-line:focus, .sl-checkbox-small .sl-checkbox-icon-line-small:focus, .sl-checkbox-small .sl-checkbox-icon-check:focus, .sl-checkbox-small .sl-checkbox-icon-check-small:focus {
  outline: 1px solid #3EAC93;
}
.sl-checkbox .sl-checkbox-icon-check, .sl-checkbox .sl-checkbox-icon-check-small, .sl-checkbox-small .sl-checkbox-icon-check, .sl-checkbox-small .sl-checkbox-icon-check-small {
  transition: all 0.3s ease;
}
.sl-checkbox .sl-checkbox-icon-check:before, .sl-checkbox .sl-checkbox-icon-check-small:before, .sl-checkbox-small .sl-checkbox-icon-check:before, .sl-checkbox-small .sl-checkbox-icon-check-small:before {
  content: "\e919";
  font-family: "sally-icon";
  color: white;
  top: -3px;
  left: 1px;
  position: absolute;
  font-size: 16px;
}
.sl-checkbox .sl-checkbox-icon-check-small, .sl-checkbox-small .sl-checkbox-icon-check-small {
  height: 16px;
  width: 16px;
  left: 2px;
  top: 3px;
}
.sl-checkbox .sl-checkbox-icon-check-small:before, .sl-checkbox-small .sl-checkbox-icon-check-small:before {
  top: -5px;
  font-size: 12px;
  left: 1px;
}
.sl-checkbox .sl-checkbox-icon-line:before, .sl-checkbox .sl-checkbox-icon-line-small:before, .sl-checkbox-small .sl-checkbox-icon-line:before, .sl-checkbox-small .sl-checkbox-icon-line-small:before {
  content: "\e039";
  font-family: "sally-icon";
  color: white;
  top: -7.5px;
  left: 2px;
  position: absolute;
  font-size: 14px;
}
.sl-checkbox .sl-checkbox-icon-line-small, .sl-checkbox-small .sl-checkbox-icon-line-small {
  height: 16px;
  width: 16px;
  left: 2px;
  top: 2px;
}
.sl-checkbox .sl-checkbox-icon-line-small:before, .sl-checkbox-small .sl-checkbox-icon-line-small:before {
  top: -8px;
  left: 0.5px;
  font-size: 13px;
}
.sl-checkbox .sl-checkbox-label, .sl-checkbox-small .sl-checkbox-label {
  margin-left: 28px;
}

details {
  width: 100%;
}

details[open] > summary {
  border: 1px solid #3EAC93;
}

details[open] > summary:after {
  content: "\e018";
}

details p {
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding: 28px 16px 16px 16px;
}

summary {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px;
  cursor: pointer;
  color: #2E383A;
}

summary:hover {
  background-color: #EAEEF0;
}

summary:after {
  font-family: "sally-icon" !important;
  content: "\e015";
  font-size: 24px;
  font-weight: 400;
}

.sl-summary-wrapper {
  display: flex;
  align-items: center;
}

/*
	## Dropdown Animation ##
	Generates correct dropdown animation depending on direction.
	Implementation example:
	&[direction=down]{
		...
		@include dropdown-animate("down");
	}
*/
.sl-dropdown {
  display: inline-block;
  position: relative;
  /*fix dropdown library styles*/
}
.sl-dropdown.sl-dropdown-disabled {
  pointer-events: none;
}
.sl-dropdown.sl-dropdown-disabled button {
  background-color: #EAEEF0;
}
.sl-dropdown.sl-dropdown-disabled button span, .sl-dropdown.sl-dropdown-disabled button i {
  color: #ABC0C4;
}
.sl-dropdown.open .sl-dropdown-menu {
  display: flex !important;
}
.sl-dropdown-menu, .sl-dropdown-menu[data-direction=up-left], .sl-dropdown-menu[data-direction=up], .sl-dropdown-menu[data-direction=down-left], .sl-dropdown-menu[data-direction=down] {
  position: absolute;
  display: none;
  flex-direction: column;
  min-width: 208px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 12px 0 12px 0;
  z-index: 500;
  margin-top: 12px;
}
.sl-dropdown-menu[data-direction=down] {
  margin-top: 8px;
}
.sl-dropdown-menu[data-direction=down].sl-animate-menu-in {
  animation: animate-menu-in-down 0.3s ease forwards;
}
.sl-dropdown-menu[data-direction=down].sl-animate-menu-out {
  animation: animate-menu-out-down 0.3s ease forwards;
}
@keyframes animate-menu-in-down {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 8px;
  }
}
@keyframes animate-menu-out-down {
  from {
    opacity: 1;
    margin-top: 8px;
  }
  to {
    opacity: 0;
    margin-top: 0;
  }
}
.sl-dropdown-menu[data-direction=down-left] {
  right: 0;
  margin-right: 0;
}
.sl-dropdown-menu[data-direction=down-left].sl-animate-menu-in {
  animation: animate-menu-in-down 0.3s ease forwards;
}
.sl-dropdown-menu[data-direction=down-left].sl-animate-menu-out {
  animation: animate-menu-out-down 0.3s ease forwards;
}
@keyframes animate-menu-in-down {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 8px;
  }
}
@keyframes animate-menu-out-down {
  from {
    opacity: 1;
    margin-top: 8px;
  }
  to {
    opacity: 0;
    margin-top: 0;
  }
}
.sl-dropdown-menu[data-direction=up] {
  bottom: 100%;
  margin-bottom: 8px;
}
.sl-dropdown-menu[data-direction=up].sl-animate-menu-in {
  animation: animate-menu-in-up 0.3s ease forwards;
}
.sl-dropdown-menu[data-direction=up].sl-animate-menu-out {
  animation: animate-menu-out-up 0.3s ease forwards;
}
@keyframes animate-menu-in-up {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 8px;
  }
}
@keyframes animate-menu-out-up {
  from {
    opacity: 1;
    margin-bottom: 8px;
  }
  to {
    opacity: 0;
    margin-bottom: 0;
  }
}
.sl-dropdown-menu[data-direction=up-left] {
  bottom: 100%;
  right: 0;
  margin-right: 0;
}
.sl-dropdown-menu[data-direction=up-left].sl-animate-menu-in {
  animation: animate-menu-in-up 0.3s ease forwards;
}
.sl-dropdown-menu[data-direction=up-left].sl-animate-menu-out {
  animation: animate-menu-out-up 0.3s ease forwards;
}
@keyframes animate-menu-in-up {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 8px;
  }
}
@keyframes animate-menu-out-up {
  from {
    opacity: 1;
    margin-bottom: 8px;
  }
  to {
    opacity: 0;
    margin-bottom: 0;
  }
}
.sl-dropdown-menu .sl-link-icon-left {
  margin-right: 8px;
}
.sl-dropdown li.sl-dropdown-divider {
  margin: 8px 24px !important;
  background-color: #D5DFE1;
  height: 1px;
  display: block;
  position: relative;
}
.sl-dropdown-item {
  font-size: 14px;
  line-height: 24px;
  margin: 0 12px;
  white-space: nowrap;
  cursor: pointer;
}
.sl-dropdown-item:hover {
  background-color: #F8F8F8;
  color: #3D9487;
}
.sl-dropdown-item-selected {
  background-color: #F8F8F8;
  color: #3D9487;
}
.sl-dropdown-item a {
  padding: 10px 24px;
  width: 100%;
}
.sl-dropdown-group-title {
  text-transform: uppercase;
  padding: 12px 24px;
}
.sl-dropdown-search {
  padding: 12px 20px;
}
.sl-dropdown.sl-dropdown-text .sl-dropdown-toggle:hover i {
  color: #3D9487;
}
.sl-dropdown .sl-button-tertiary-medium:hover {
  background-color: #F8F8F8;
}
.sl-dropdown .sl-button-tertiary-medium:focus {
  border-color: transparent;
}
.sl-dropdown hr {
  margin: 8px auto;
  color: #D5DFE1;
  border: 1px solid;
  width: 80%;
  border-top: none;
  opacity: 1;
}
.sl-dropdown .dropdown-toggle {
  cursor: pointer;
  align-items: center;
  display: flex;
}
.sl-dropdown .dropdown-toggle i {
  display: inline-block;
  line-height: 1em;
}
.sl-dropdown .dropdown-toggle .sl-dropdown-chevron {
  position: absolute;
  right: 20px;
}
.sl-dropdown .dropdown-menu {
  min-width: auto !important;
  width: auto !important;
  right: initial !important;
}
.sl-dropdown .dropdown-menu li {
  float: none;
}
.sl-dropdown .dropdown-menu li > a {
  padding: 10px 16px;
}
.sl-dropdown [class^=sl-tag-upgrade-] {
  display: inline-flex;
}

.dropdown-menu li .sl-link-icon-left {
  margin-right: 8px !important;
}

#actions.sl-dropdown-disabled, #actions.md_highlight #actions.sl-dropdown-disabled {
  pointer-events: none;
}
#actions.sl-dropdown-disabled *, #actions.md_highlight #actions.sl-dropdown-disabled * {
  transition: none !important;
}
#actions.sl-dropdown-disabled button, #actions.md_highlight #actions.sl-dropdown-disabled button {
  background-color: #EAEEF0 !important;
  border-color: #EAEEF0 !important;
}
#actions.sl-dropdown-disabled button span, #actions.sl-dropdown-disabled button i, #actions.md_highlight #actions.sl-dropdown-disabled button span, #actions.md_highlight #actions.sl-dropdown-disabled button i {
  color: #ABC0C4;
}

.sl-alert-banner {
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
}
.sl-alert-toast {
  width: 400px;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.2509803922);
}
.sl-alert-page {
  position: relative;
  border: none !important;
  width: 100%;
}
.sl-alert-success {
  background-color: #E6FAE8;
  border: 1px solid #9BE8A3;
}
.sl-alert-success .sl-alert-content, .sl-alert-success .sl-alert-icon-state, .sl-alert-success .sl-alert-action {
  color: #05570A;
}
.sl-alert-info {
  background-color: #E9F9FB;
  border: 1px solid #BAEBF5;
}
.sl-alert-info .sl-alert-content, .sl-alert-info .sl-alert-icon-state, .sl-alert-info .sl-alert-action {
  color: #005583 !important;
}
.sl-alert-info .sl-alert-action {
  text-decoration: underline;
}
.sl-alert-warning {
  background-color: #FFF1DB;
  border: 1px solid #FFD9A0;
}
.sl-alert-warning .sl-alert-content, .sl-alert-warning .sl-alert-icon-state, .sl-alert-warning .sl-alert-action {
  color: #653600;
}
.sl-alert-error {
  background-color: #FDEDED;
  border: 1px solid #F7BCBA;
}
.sl-alert-error .sl-alert-content, .sl-alert-error .sl-alert-icon-state, .sl-alert-error .sl-alert-action {
  color: #55201E;
}
.sl-alert-content {
  padding: 16px 44px 16px 52px;
}
.sl-alert-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}
.sl-alert-text {
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
}
.sl-alert-action {
  text-decoration: underline !important;
  font-size: 14px;
}
.sl-alert-icon-state {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 24px;
}
.sl-alert-icon-close, .sl-alert-icon-close-error, .sl-alert-icon-close-warning, .sl-alert-icon-close-info, .sl-alert-icon-close-success {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  cursor: pointer;
}
.sl-alert-icon-close-success {
  color: #05570A;
}
.sl-alert-icon-close-info {
  color: #1C303B;
}
.sl-alert-icon-close-warning {
  color: #653600;
}
.sl-alert-icon-close-error {
  color: #55201E;
}

.sl-link {
  font-weight: 400;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;
}
.sl-link p {
  font-size: unset;
  line-height: unset;
}
.sl-link-primary, .sl-link-primary-small, .sl-link-primary-medium {
  color: #387B77;
}
.sl-link-primary:hover, .sl-link-primary-small:hover, .sl-link-primary-medium:hover {
  color: #3D9487;
}
.sl-link-primary:active, .sl-link-primary-small:active, .sl-link-primary-medium:active {
  color: #2E383A;
}
.sl-link-primary .sl-link-icon-right:hover, .sl-link-primary-small .sl-link-icon-right:hover, .sl-link-primary-medium .sl-link-icon-right:hover {
  color: #3D9487;
}
.sl-link-primary-medium {
  font-size: 14px;
  line-height: 24px;
}
.sl-link-primary-small {
  font-size: 12px;
  line-height: 20px;
}
.sl-link-primary-simple:hover {
  color: #3D9487;
}
.sl-link-primary-underline {
  text-decoration: underline;
}
.sl-link-primary-underline:hover {
  color: #3D9487;
}
.sl-link-primary-title {
  font-weight: 600;
}
.sl-link-primary-title:hover {
  color: #3D9487;
}
.sl-link-primary-lefticon {
  display: flex;
  align-items: center;
}
.sl-link-primary-icon {
  display: flex;
  align-items: center;
}
.sl-link-primary-icon-external {
  font-size: 16px;
}
.sl-link-primary-icon-left {
  font-size: 16px;
}
.sl-link-primary-icon:hover {
  color: #3D9487;
}
.sl-link-secondary, .sl-link-secondary-small, .sl-link-secondary-medium {
  color: #4C6569;
}
.sl-link-secondary:hover, .sl-link-secondary-small:hover, .sl-link-secondary-medium:hover {
  color: #3D9487;
}
.sl-link-secondary .sl-link-icon-right:hover, .sl-link-secondary-small .sl-link-icon-right:hover, .sl-link-secondary-medium .sl-link-icon-right:hover {
  color: #3D9487;
}
.sl-link-secondary-medium {
  font-size: 14px;
  line-height: 24px;
}
.sl-link-secondary-small {
  font-size: 12px;
  line-height: 20px;
}
.sl-link-secondary-simple:hover {
  color: #3D9487;
  text-decoration: none;
}
.sl-link-secondary-simple:active {
  color: #205A5A;
}
.sl-link-secondary-underline {
  text-decoration: underline;
}
.sl-link-secondary-underline:hover {
  color: #2E383A;
}
.sl-link-secondary-underline:active {
  color: #2E383A;
}
.sl-link-secondary-title {
  font-weight: 600;
}
.sl-link-secondary-title:hover {
  color: #2E383A;
}
.sl-link-secondary-title:active {
  color: #2E383A;
}
.sl-link-secondary-lefticon {
  display: flex;
  align-items: center;
}
.sl-link-secondary-icon {
  display: flex;
  align-items: center;
}
.sl-link-secondary-icon-external {
  font-size: 16px;
  margin-left: 8px;
}
.sl-link-secondary-icon-left {
  font-size: 16px;
  margin-right: 8px;
}
.sl-link-secondary-icon:hover {
  color: #2E383A;
}
.sl-link-secondary-icon:active {
  color: #2E383A;
}
.sl-link-header-nav {
  color: #2E383A;
}
.sl-link-header-nav:hover {
  color: #3D9487;
}
.sl-link-header-nav .sl-link-icon-right:hover {
  color: #3D9487;
}
.sl-link-info {
  color: #005583;
}
.sl-link-medium {
  font-size: 14px;
  line-height: 24px;
}
.sl-link-small {
  font-size: 12px;
  line-height: 20px;
}
.sl-link-small .sl-link-icon-right {
  font-size: 12px;
  line-height: 20px;
}
.sl-link-large {
  font-size: 16px;
  line-height: 24px;
}
.sl-link-large .sl-link-icon-right {
  font-size: 16px;
  line-height: 24px;
}
.sl-link-h5 {
  font-size: 20px;
  line-height: 28px;
}
.sl-link-h5 .sl-link-icon-right {
  font-size: 20px;
  line-height: 28px;
}
.sl-link-underline {
  text-decoration: underline !important;
}
.sl-link-title {
  font-weight: 600;
}
.sl-link-in {
  display: flex;
  align-items: center;
}
.sl-link-in span {
  display: inline-flex;
}
.sl-link-disabled {
  color: #ABC0C4 !important;
  pointer-events: none;
  text-decoration: none;
}
.sl-link-disabled i:before {
  color: #ABC0C4;
}
.sl-link-nolink {
  cursor: unset;
}
.sl-link.sl-ellipsis p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sl-tooltip {
  position: relative;
}
.sl-tooltip-tiny {
  font-size: 16px;
}
.sl-tooltip-content {
  position: absolute;
  background-color: #2E383A;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  font-family: "Open Sans";
  padding: 8px 12px;
  border-radius: 4px;
  width: max-content;
  max-width: 240px;
  z-index: 900;
  word-break: break-word;
}
.sl-tooltip-content-light {
  background-color: #ffffff;
  color: #1E413E;
  border: 1px solid #1E413E;
}
.sl-tooltip-content-left {
  margin-left: -8px;
}
.sl-tooltip-content-right {
  margin-left: 8px;
}
.sl-tooltip-content-top-right {
  transform: translateX(-100%);
  margin-top: 0px;
}
.sl-tooltip-content-top-left {
  margin-top: 0px;
}
.sl-tooltip-content-top-center {
  transform: translateX(-50%);
  margin-top: 0px;
}
.sl-tooltip-content-bottom-right {
  transform: translateX(-100%);
  margin-top: 0px;
}
.sl-tooltip-content-bottom-left {
  margin-top: 0px;
}
.sl-tooltip-content-bottom-center {
  transform: translateX(-50%);
  margin-top: 0px;
}
.sl-tooltip-content-tiny {
  font-size: 16px;
}
.sl-tooltip:hover .sl-tooltip-content {
  visibility: visible;
}
.sl-tooltip-list-item {
  display: flex;
  margin-bottom: 8px;
}
.sl-tooltip-list-item:last-child {
  margin-bottom: 0px;
}
.sl-tooltip-list-icon {
  font-size: 20px;
  padding-right: 12px;
}

.sl-radio {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
.sl-radio-input {
  font: inherit;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-color: #ffffff;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #CFDADD;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sl-radio-input::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.3s ease;
  box-shadow: inset 1em 1em #ffffff;
}
.sl-radio-input:hover {
  border: 1px solid #387B77;
}
.sl-radio-input:disabled {
  background-color: #EAEEF0;
  border: none;
}
.sl-radio-input:disabled ~ .radio-text {
  color: #ABC0C4;
}
.sl-radio-input:focus {
  border: 1px solid #3EAC93;
}
.sl-radio-input:checked {
  background-color: #387B77;
  border: none;
}
.sl-radio-input:checked::before {
  transform: scale(1);
}
.sl-radio-input:checked:hover {
  background-color: #3D9487;
}
.sl-radio-input:checked:focus {
  border: 1px solid #3EAC93;
}
.sl-radio-input:checked:disabled {
  background-color: #EAEEF0;
  border: none;
}
.sl-radio-input:checked:disabled::before {
  box-shadow: inset 1em 1em #ABC0C4;
}
.sl-radio-text {
  margin-left: 8px;
  color: #2E383A;
}

.sl-toggle {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sl-toggle-input {
  font: inherit;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-color: #ABC0C4;
  margin: 0;
  width: 42px;
  height: 24px;
  border: none;
  border-radius: 16px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sl-toggle-input:before {
  content: "";
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  margin: 0px 4px;
}
.sl-toggle-input:checked {
  background-color: #387B77;
  justify-content: flex-end;
}
.sl-toggle-input:checked:active {
  background-color: #2E383A;
}
.sl-toggle-input:checked:hover {
  background-color: #3D9487;
}
.sl-toggle-input:checked:disabled {
  background-color: #EAEEF0;
}
.sl-toggle-input:checked:disabled::before {
  background-color: #ABC0C4;
}
.sl-toggle-input:hover {
  background-color: #CFDADD;
}
.sl-toggle-input:focus {
  border: 1px solid #3EAC93;
}
.sl-toggle-input:disabled {
  background-color: #EAEEF0;
}
.sl-toggle-input:disabled::before {
  background-color: #ABC0C4;
}
.sl-toggle-input:disabled ~ .toggle-text {
  color: #ABC0C4;
}
.sl-toggle-text {
  color: #2E383A;
  margin-left: 8px;
}

.sl-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 467px;
  margin: 0 auto;
  position: absolute;
  top: 20vh;
  height: 0px;
  left: 50%;
  transform: translate(-50%, -150%);
}
.sl-empty-img {
  width: 150px;
  text-align: center;
}
.sl-empty-icon {
  font-size: 64px;
  color: #CFDADD;
}
.sl-empty-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sl-empty-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #2E383A;
}
.sl-empty-description {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #2E383A;
}
.sl-empty-actions {
  display: flex;
}

.sl-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAEEF0;
  color: #4C6569;
  padding: 0px 4px;
  border-radius: 8px;
  min-width: 20px;
  text-align: center;
  height: 16px;
  font-weight: 600;
  font-size: 10px;
}
.sl-badge span {
  line-height: 20px;
}
.sl-badge-info {
  background-color: #005583;
}
.sl-badge-critical {
  background-color: #D2322C;
}
.sl-badge-success {
  background-color: #18C925;
}
.sl-badge-warning {
  background-color: #D57300;
}
.sl-badge-info span, .sl-badge-critical span, .sl-badge-success span, .sl-badge-warning span {
  color: #ffffff;
}

.sl-text-field-wrapper {
  display: flex;
  flex-direction: column;
}
.sl-text-field-label {
  display: flex;
  flex-direction: column;
  min-width: 292px;
  color: #4C6569;
}
.sl-text-field-input, .sl-text-field-input-search, .sl-text-field-input-small {
  padding: 8px 12px;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  color: #2E383A;
  min-width: 292px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  height: 40px;
  width: 100%;
}
.sl-text-field-input[disabled], [disabled].sl-text-field-input-search, [disabled].sl-text-field-input-small {
  background-color: #EAEEF0;
}
.sl-text-field-input-fluid {
  min-width: auto;
  max-width: 100%;
  width: 100%;
}
.sl-text-field-input:focus, .sl-text-field-input-search:focus, .sl-text-field-input-small:focus {
  outline: none;
  border: 1px solid #3EAC93;
}
.sl-text-field-input:focus + .sl-text-field-input-icon-left i:before, .sl-text-field-input-search:focus + .sl-text-field-input-icon-left i:before, .sl-text-field-input-small:focus + .sl-text-field-input-icon-left i:before {
  color: #2E383A;
}
.sl-text-field-input::placeholder, .sl-text-field-input-search::placeholder, .sl-text-field-input-small::placeholder {
  color: #93AFB4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.sl-text-field-input-small {
  font-size: 12px;
  line-height: 20px;
  height: 36px;
}
.sl-text-field-input-wrapper {
  position: relative;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease;
  background: white;
}
.sl-text-field-input-wrapper:focus-within {
  border: 1px solid #3EAC93;
  transition: all 0.3s ease;
}
.sl-text-field-input-wrapper.sl-has-error {
  border: 1px solid #D2322C;
}
.sl-text-field-input-wrapper.sl-is-valid {
  border: 1px solid #18C925;
}
.sl-text-field-input-wrapper .sl-text-field-input, .sl-text-field-input-wrapper .sl-text-field-input-search, .sl-text-field-input-wrapper .sl-text-field-input-small {
  border: none;
}
.sl-text-field-input-wrapper .sl-text-field-input:focus, .sl-text-field-input-wrapper .sl-text-field-input-search:focus, .sl-text-field-input-wrapper .sl-text-field-input-small:focus {
  border: none;
}
.sl-text-field-input-prev-icon-left {
  margin-left: 24px;
}
.sl-text-field-input-search {
  border: none;
}
.sl-text-field-input-search:focus {
  border: none;
}
.sl-text-field-input-icon, .sl-text-field-input-icon-right, .sl-text-field-input-icon-left {
  display: flex;
  align-items: center;
}
.sl-text-field-input-icon-left {
  margin-left: 12px;
  color: #ABC0C4;
  position: absolute;
  left: 0;
}
.sl-text-field-input-icon-right {
  margin-right: 12px;
  color: #2E383A;
}
.sl-text-field-valid {
  color: #18C925;
}
.sl-text-field-error {
  color: #D2322C;
}
.sl-text-field-help {
  color: #4C6569;
}

.sl-textarea-wrapper {
  display: flex;
  flex-direction: column;
}
.sl-textarea-label {
  display: flex;
  flex-direction: column;
  min-width: 292px;
  color: #4C6569;
  margin-bottom: 8px;
}
.sl-textarea-input {
  border-radius: 4px;
  color: #2E383A;
  min-width: 292px;
  min-height: 136px;
  padding: 12px 16px;
  border: 1px solid #D5DFE1;
  transition: all 0.3s ease;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.sl-textarea-input:focus {
  border: 1px solid #3EAC93;
  transition: all 0.3s ease;
  outline: none;
}
.sl-textarea-input::placeholder {
  color: #93AFB4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.sl-textarea-input.sl-has-error {
  border: 1px solid #D2322C;
}
.sl-textarea-input.sl-is-valid {
  border: 1px solid #18C925;
}
.sl-textarea-valid {
  color: #18C925;
}
.sl-textarea-error {
  color: #D2322C;
}
.sl-textarea-help {
  color: #4C6569;
}

.sl-pagination {
  display: flex;
  flex-direction: row;
}
.sl-pagination a:first-child .sl-pagination-item {
  border-radius: 4px 0 0 4px;
}
.sl-pagination a:last-child .sl-pagination-item {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #D5DFE1;
}
.sl-pagination a:last-child .sl-pagination-item:disabled {
  border-right: 1px solid #D5DFE1;
}
.sl-pagination a:last-child .sl-pagination-item:hover:disabled {
  border: 1px solid #D5DFE1;
}
.sl-pagination-item {
  border: 1px solid #D5DFE1;
  border-right: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: 0;
  transition: all 0.3s ease;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  color: #2E383A;
  border-radius: 0;
}
.sl-pagination-item:hover {
  background-color: #D5DFE1;
}
.sl-pagination-item:hover:disabled {
  border: 1px solid #D5DFE1;
  border-right: 0;
}
.sl-pagination-item:active {
  background-color: #D5DFE1;
}
.sl-pagination-item:disabled {
  background-color: transparent;
  color: #ABC0C4;
  cursor: default;
  border-right: 0;
}
.sl-pagination-item.active {
  background-color: #D5DFE1;
}
.sl-pagination-item.arrow {
  font-size: 16px;
}
.sl-pagination-item.more {
  font-size: 16px;
  font-weight: 700;
  cursor: unset;
}
.sl-pagination-item.more:hover {
  background-color: transparent;
}
.sl-pagination-active {
  background-color: #D5DFE1;
}

.sl-breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.sl-breadcrumbs > * {
  margin-right: 12px;
}

.sl-popover {
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  width: 352px;
}
.sl-popover-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.sl-popover-header h5 {
  color: #2E383A;
}
.sl-popover-body {
  color: #4C6569;
}
.sl-popover-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.sl-search-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  max-height: 40px;
  width: 292px;
  border: 1px solid #D5DFE1;
  padding: 8px 12px;
  border-radius: 4px;
}
.sl-search-box-focus {
  border: 1px solid #3EAC93;
}
.sl-search-icon {
  font-size: 16px;
  color: #ABC0C4;
}
.sl-search-icon-focus {
  color: #2E383A;
}
.sl-search-input {
  border: none;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  margin-left: 8px;
  width: 100%;
}
.sl-search-input::placeholder {
  font-size: 14px;
  line-height: 24px;
  font-style: 400;
  color: #93AFB4;
}
.sl-search-input-focus {
  color: #2E383A;
}
.sl-search-button, .sl-search-button-advanced, .sl-search-button-left, .sl-search-button-right, .sl-search-button-close {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  background-color: #ffffff;
  color: #ABC0C4;
}
.sl-search-button-close {
  display: none;
}
.sl-search-button-left, .sl-search-button-right {
  cursor: default;
}
.sl-search-button-left-focus, .sl-search-button-right-focus {
  color: #2E383A;
  cursor: pointer;
}
.sl-search-button-advanced {
  color: #2E383A;
}
.sl-search-button-visible {
  display: flex;
}

.sl-thumbnail, .sl-thumbnail-tiny, .sl-thumbnail-tiny-border, .sl-thumbnail-small, .sl-thumbnail-small-border, .sl-thumbnail-medium, .sl-thumbnail-medium-border, .sl-thumbnail-large, .sl-thumbnail-large-border {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.sl-thumbnail-img {
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  max-width: 100%;
}
.sl-thumbnail-large, .sl-thumbnail-large-border {
  width: 226px;
  height: 188px;
}
.sl-thumbnail-large-border {
  border: 1px solid #D5DFE1;
}
.sl-thumbnail-large .sl-icon-warning-filled, .sl-thumbnail-large-border .sl-icon-warning-filled {
  position: absolute;
  right: 14px;
  top: 14px;
  color: #D2322C;
  font-size: 20px;
  height: 18px;
  width: 20px;
  display: block;
  line-height: 18px;
}
.sl-thumbnail-large .sl-thumbnail-placeholder, .sl-thumbnail-large-border .sl-thumbnail-placeholder {
  width: 88px;
  height: 88px;
}
.sl-thumbnail-medium, .sl-thumbnail-medium-border {
  width: 160px;
  height: 133px;
}
.sl-thumbnail-medium-border {
  border: 1px solid #D5DFE1;
}
.sl-thumbnail-medium .sl-icon-warning-filled, .sl-thumbnail-medium-border .sl-icon-warning-filled {
  position: absolute;
  right: 4px;
  top: 0;
  color: #D2322C;
  font-size: 16px;
}
.sl-thumbnail-medium .sl-thumbnail-placeholder, .sl-thumbnail-medium-border .sl-thumbnail-placeholder {
  width: 61px;
  height: 61px;
}
.sl-thumbnail-small, .sl-thumbnail-small-border {
  width: 77px;
  height: 64px;
}
.sl-thumbnail-small-border {
  border: 1px solid #D5DFE1;
}
.sl-thumbnail-small .sl-icon-warning-filled, .sl-thumbnail-small-border .sl-icon-warning-filled {
  position: absolute;
  right: 4px;
  top: 0;
  color: #D2322C;
  font-size: 16px;
}
.sl-thumbnail-small .sl-thumbnail-placeholder, .sl-thumbnail-small-border .sl-thumbnail-placeholder {
  width: 30px;
  height: 30px;
}
.sl-thumbnail-tiny, .sl-thumbnail-tiny-border {
  width: 58px;
  height: 48px;
}
.sl-thumbnail-tiny-border {
  border: 1px solid #D5DFE1;
  border-radius: 4px;
}
.sl-thumbnail-tiny .sl-icon-warning-filled, .sl-thumbnail-tiny-border .sl-icon-warning-filled {
  position: absolute;
  right: 4px;
  top: 0;
  color: #D2322C;
  font-size: 16px;
}
.sl-thumbnail-tiny .sl-thumbnail-placeholder, .sl-thumbnail-tiny-border .sl-thumbnail-placeholder {
  width: 22px;
  height: 22px;
}
.sl-thumbnail-icon {
  background-color: transparent;
}
.sl-thumbnail-icon .sl-thumbnail-placeholder {
  width: 42px;
  height: 42px;
}
.sl-thumbnail-icon .sl-icon-warning-filled {
  position: absolute;
  right: 4px;
  top: 0;
  color: #D2322C;
  font-size: 16px;
}

.sl-progress-bar {
  position: relative;
  background-color: #EAEEF0;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
}
.sl-progress-bar-status {
  position: absolute;
  background-color: #7B9BA1;
  width: 25%;
  height: 8px;
  border-radius: 4px;
}
.sl-progress-bar-indeterminate .sl-progress-bar-status {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  animation: indeterminateAnimation 1s infinite linear;
  -webkit-animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.sl-progress-bar .progress-bar {
  background-image: linear-gradient(90deg, rgb(123, 155, 161) 100%, transparent);
}

.sl-loader-upload {
  top: 0;
  min-height: 200px;
  position: absolute;
  z-index: 99;
  background: white;
  width: 100%;
  height: 100%;
}
.sl-loader-upload svg {
  width: 48px;
}

@keyframes indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -webkit-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}
@-webkit-keyframes indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -webkit-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}
@-moz-keyframes indeterminateAnimation {
  0% {
    -moz-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -moz-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -moz-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}
@-o-keyframes indeterminateAnimation {
  0% {
    -o-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -o-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -o-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}
.sl-loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.sl-loading-dots .sl-loading-dot {
  width: 4px;
  height: 4px;
  margin: 0 4px;
  background-color: #387B77;
  border-radius: 5px;
  animation: sl-bounce 1.5s infinite ease-in-out;
}
.sl-loading-dots .sl-loading-dot:nth-child(1) {
  animation-delay: 0s;
}
.sl-loading-dots .sl-loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.sl-loading-dots .sl-loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes sl-bounce {
  0%, 100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

.sl-table {
  min-width: 500px;
  overflow: auto;
  width: 100%;
  border-collapse: separate;
  border: 1px solid #D5DFE1;
  border-radius: 8px;
  background: white;
}
.sl-table > * > * > * {
  padding: 0 16px;
  height: 64px;
}
.sl-table > thead > * > * {
  border-bottom: 1px solid #D5DFE1;
}
.sl-table > tbody > :not(:last-child) > * {
  border-bottom: 1px solid #D5DFE1;
}
.sl-table thead tr th {
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 22px 16px;
}
.sl-table thead > * > :first-child {
  min-width: 64px;
}
.sl-table tbody > * > :first-child {
  padding: 0px 20px;
}
.sl-table tbody tr td {
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #4C6569;
  vertical-align: middle;
  padding: 20px 16px;
}
.sl-table tbody:last-child > * {
  border-bottom: none;
}
.sl-table .checkbox.disabled {
  display: block;
}
.sl-table .checkbox .icons,
.sl-table .checkbox .icons:hover {
  cursor: default;
}
.sl-table.sl-table-hover tr:hover td {
  background-color: #F8F8F8;
}
.sl-table.sl-table-hover tr:first-child td:first-child {
  border-top-left-radius: 8px;
}
.sl-table.sl-table-hover tr:first-child td:last-child {
  border-top-right-radius: 8px;
}
.sl-table.sl-table-hover tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.sl-table.sl-table-hover tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.sl-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sl-tab-container {
  border-bottom: 1px solid #D5DFE1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.sl-tab-content {
  overflow-y: auto;
  max-height: 100%;
  flex-grow: 1;
}
.sl-tab-label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  color: #4C6569;
  margin: 0;
}
.sl-tab-label:hover {
  color: #2E383A;
}
.sl-tab-label.active {
  color: #3D9487;
  border-bottom: 2px solid #3D9487;
  margin-bottom: -2px;
}

.form table .btn-group.select {
  margin: 0;
}
.form table .btn-group.select button.dropdown-toggle {
  background: transparent;
}
.form table .btn-group.select button.dropdown-toggle:hover {
  background: rgba(0, 0, 0, 0.05);
}
.form table .btn-group.select .dropdown-menu a:hover {
  cursor: pointer;
}
.form table .btn-group.select td.td_numb {
  text-align: right;
}

.sl-select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}

.sl-select-label {
  margin-bottom: 8px;
  color: #4C6569;
}

.sl-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #D5DFE1;
  padding: 8px 12px;
  width: 292px;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  border-radius: 4px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.sl-select-sm {
  font-size: 12px;
  line-height: 20px;
}

.sl-select.sl-auto {
  width: unset;
}

.sl-select:focus-within {
  border-color: #3EAC93;
}

/* label component */
.sl-label {
  display: flex;
  align-items: center;
  color: #4C6569;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
}
.sl-label-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sl-label-edit {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.sl-admin-box {
  background: #ffffff;
  border: 2px solid;
  border-color: #FBF2D0;
  border-radius: 4px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
}

.sl-text-field-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sl-text-field-group-right .text-field-input-wrapper, .sl-text-field-group-right .select {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.sl-text-field-group-left .text-field-input-wrapper, .sl-text-field-group-left .select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.sl-text-field-group label, .sl-text-field-group .sl-text-field-help, .sl-text-field-group .sl-text-field-error {
  width: 100%;
}
.sl-text-field-group input {
  height: 38px;
}
.sl-text-field-group button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sl-text-field-group .sl-prefix {
  border: 1px solid #D5DFE1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
.sl-text-field-group .sl-suffix {
  border: 1px solid #D5DFE1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
.sl-text-field-group .sl-suffix, .sl-text-field-group .sl-prefix {
  font-size: 14px;
  line-height: 24px;
  background-color: #ffffff;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}
.sl-text-field-group .sl-select {
  margin-bottom: 8px;
}
.sl-text-field-group.disabled .sl-text-field-input-wrapper, .sl-text-field-group.disabled .sl-prefix, .sl-text-field-group.disabled .sl-suffix, .sl-text-field-group.disabled .select {
  background: #EAEEF0;
}
.sl-text-field-group.disabled label, .sl-text-field-group.disabled .sl-prefix, .sl-text-field-group.disabled .sl-suffix, .sl-text-field-group.disabled select {
  color: #ABC0C4;
}
.sl-text-field-group.disabled button {
  color: #ABC0C4;
  background: #D5DFE1;
}

.select2-container--default .select2-selection--multiple {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  border: 1px solid #D5DFE1;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  border-radius: 4px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 20px);
  overflow: hidden;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  max-height: 40px;
  border: none;
  background-color: #FBF2D0;
  color: #5A4B35;
  border-radius: 2px;
  display: block;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0px;
  margin-right: 8px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  order: 2;
  background-color: transparent;
  border: none;
  border-right: none;
  color: #5A4B35;
  cursor: pointer;
  padding: 0;
  position: relative;
  color: #2E383A;
  color: #5A4B35;
  font-size: 0px;
  display: flex;
  align-items: center;
  margin-left: 4px;
  float: right;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.select2-container--default.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: #EAEEF0;
  color: #ABC0C4;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
  color: #ABC0C4;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple.select2-container--focus .select2-selection--multiple {
  border: 1px solid #D5DFE1;
  border: 1px solid #3EAC93;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: transparent;
  color: #5A4B35;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #3EAC93;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  font-family: "sally-icon" !important;
  content: "\e01b";
  font-size: 16px;
}

.select2-results__option.select2-results__option[aria-selected=true] {
  background-color: #D5DFE1;
  font-weight: 600;
}

.select2-results__option.select2-results__option--highlighted[aria-selected] {
  background-color: #FBF2D0;
}

.select2-results__option.select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  background-color: #D5DFE1;
}

.select2-results__option {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #2E383A;
}

.select2-dropdown {
  background-color: #ffffff;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 12px 0;
  z-index: 100;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-bottom: 2px;
}

.sl-custom-icon-status {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}
.sl-custom-icon-status.sl-custom-icon-status-warning {
  background-color: #FFF1DB;
}
.sl-custom-icon-status.sl-custom-icon-status-warning:before {
  background-color: #FFAA46;
}
.sl-custom-icon-status.sl-custom-icon-status-success {
  background-color: #E6FAE8;
}
.sl-custom-icon-status.sl-custom-icon-status-success:before {
  background-color: #37D046;
}
.sl-custom-icon-status.sl-custom-icon-status-critical {
  background-color: #FDEDED;
}
.sl-custom-icon-status.sl-custom-icon-status-critical:before {
  background-color: #ED352E;
}
.sl-custom-icon-status.sl-custom-icon-status-info {
  background-color: #E9F9FB;
}
.sl-custom-icon-status.sl-custom-icon-status-info:before {
  background-color: #2977D1;
}
.sl-custom-icon-status:before {
  position: absolute;
  border-radius: 50%;
  background-color: black;
  top: 3px;
  left: 3px;
  height: 9px;
  width: 9px;
  content: "";
}

.sl-status-accordion {
  width: 26px;
  overflow: hidden;
  transition: 0.1s all ease-out;
}
.sl-status-accordion.sl-status-opened {
  width: 80px;
}
.sl-status-disabled button {
  opacity: 0.5;
  cursor: default;
}
.sl-status-disabled button:focus {
  border: 1px solid transparent;
}

.sl-doughnut {
  position: relative;
  width: 20px;
  height: 20px;
}
.sl-doughnut-chart {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(#849FFF 0% 45%, #EAEEF0 45% 100%);
}
.sl-doughnut-hole {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.sl-modal-process {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 700;
  width: 408px;
  padding: 24px 32px 32px 32px;
  transition: transform 0.5s ease-out, opacity 0.2s ease-out;
}
.sl-modal-process-container {
  max-width: 340px;
}
.sl-modal-process-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sl-modal-process-icon i {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #4C6569;
}
.sl-modal-process-titles {
  max-width: calc(100% - 56px);
}
.sl-modal-process-title-fail {
  color: #D2322C !important;
  line-height: 20px;
}
.sl-modal-process-text-desc-fail {
  color: #2E383A;
  line-height: 20px;
}
.sl-modal-process-progress .sl-progress-bar .progress-bar {
  background-color: #9DB2FF !important;
  background-image: none !important;
}
.sl-modal-process-progress .sl-modal-process-step, .sl-modal-process-progress .sl-modal-process-percentage {
  color: #2977D1 !important;
}
.sl-modal-process-success .sl-progress-bar .progress-bar {
  background-color: #18C925 !important;
  background-image: none !important;
}
.sl-modal-process-success .sl-modal-process-step {
  color: #0BA815 !important;
}
.sl-modal-process-success .sl-modal-process-text-warning {
  color: #D57300 !important;
}
.sl-modal-process-fail .sl-progress-bar .progress-bar {
  background-color: #F28790 !important;
  background-image: none !important;
}
.sl-modal-process-fail .sl-modal-process-step {
  color: #D2322C !important;
}
.sl-modal-process-fail .sl-modal-process-title {
  color: #D2322C !important;
}
.sl-modal-process-bar-success {
  background-color: #18C925 !important;
  background-image: none !important;
}
.sl-modal-process-bar-fail {
  background-color: #F28790 !important;
  background-image: none !important;
}
.sl-modal-process-bar-progress {
  background-color: #9DB2FF !important;
  background-image: none !important;
}
.sl-modal-process-text {
  display: block;
}
.sl-modal-process-text-success {
  color: #0BA815 !important;
}
.sl-modal-process-text-progress {
  color: #9DB2FF !important;
}
.sl-modal-process-text-fail {
  color: #D2322C !important;
}
.sl-modal-process-text-warning {
  color: #D57300 !important;
}
.sl-modal-process .mgbform {
  margin-bottom: 0;
}

.sl-fade-out {
  transform: translateY(120%);
}

.sl-dropzone {
  position: relative !important;
  width: calc(100% - 5px) !important;
  min-height: 50vh !important;
}
.sl-dropzone.sl-dropzone-default {
  min-height: initial !important;
  width: 100% !important;
}
.sl-dropzone.sl-dropzone-default .sl-empty-drop {
  height: 100%;
  top: initial;
  padding-top: 0;
}
.sl-dropzone .sl-empty-drop {
  padding-top: 32px;
  width: 100%;
  height: calc(100% - 80px);
  position: absolute;
  z-index: 999;
  max-width: 100%;
  max-height: 100%;
  border: 2px dashed #2977D1;
  top: 80px;
}

.sl-onboarding {
  position: absolute;
  z-index: 850;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  max-width: 375px;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-20px);
  animation: onboardingFadeOut 0.2s forwards;
}
.sl-onboarding.sl-onboarding-show {
  opacity: 1;
  visibility: visible;
  animation: onboardingFadeIn 0.2s forwards;
  transform: translateY(0);
}
.sl-onboarding.sl-onboarding-active {
  pointer-events: auto;
}
.sl-onboarding-content {
  padding: 24px;
  background: #ffffff;
}
.sl-onboarding-icon {
  border-radius: 5px;
  padding: 6px;
  background-color: #849FFF;
  display: inline-flex;
}
.sl-onboarding-icon img {
  width: 16px;
  height: 16px;
}
.sl-onboarding-title {
  font-size: 16px;
  font-weight: 700;
}
.sl-onboarding-text {
  font-size: 12px;
  color: #2E383A;
}
.sl-onboarding-text p {
  font-size: unset;
  color: unset;
  margin-bottom: 20px;
}

@keyframes onboardingFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes onboardingFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
*[data-onboarding] {
  cursor: pointer;
}

.sl-popout {
  position: absolute;
  background-color: #2E383A;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  font-family: "Open Sans";
  padding: 8px 12px;
  border-radius: 4px;
  width: max-content;
  max-width: 240px;
  word-break: break-all;
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.2s ease, transform 0.1s ease;
  pointer-events: none;
  z-index: 9;
}
.sl-popout--active {
  opacity: 1;
  transform: translateY(-100%);
}
.sl-popout--hidden {
  display: none;
}

.calendar {
  max-width: 392px;
  position: absolute;
  z-index: 999;
}
.calendar .pika-single.is-hidden {
  display: none;
}
.calendar .is-inrange .pika-button {
  background-color: #EAEEF0;
}
.calendar .pika-button:hover {
  background-color: #3EAC93;
  color: #ffffff;
}
.calendar .is-disabled .pika-button:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  cursor: not-allowed;
}
.calendar .is-startrange .pika-button,
.calendar .is-endrange .pika-button {
  background-color: #CFDADD;
  color: #2E383A;
}
.calendar .is-selected .pika-button {
  background-color: #387B77;
  color: #ffffff;
}
.calendar .is-startrange .pika-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.calendar .is-endrange .pika-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.calendar .pika-table {
  width: 100%;
}
.calendar .pika-table th {
  padding-bottom: 8px;
  text-align: center;
}
.calendar .pika-table td {
  padding-top: 40px;
  position: relative;
  width: 14.25%;
}
.calendar .pika-single button {
  display: inline-block;
  padding: 0;
  height: 32px;
  margin: 9px 0;
}
.calendar .pika-button {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  color: #2E383A;
  font-weight: 600;
  font-size: 14px;
}
.calendar .pika-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 43px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}
.calendar .pika-title .pika-label {
  padding: 0 8px;
  position: relative;
  height: 31px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.calendar .pika-title .pika-label:first-child {
  padding-right: 0px;
}
.calendar .pika-title button {
  padding: 8px;
  position: absolute;
  top: 0;
  margin: 0;
  height: auto;
}
.calendar .pika-title .pika-prev {
  left: 4px;
}
.calendar .pika-title .pika-prev:before {
  font-family: "sally-icon" !important;
  content: "\e91d";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-size: 24px;
}
.calendar .pika-title .pika-next {
  right: 4px;
}
.calendar .pika-title .pika-next:before {
  font-family: "sally-icon" !important;
  content: "\e91e";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-size: 24px;
}
.calendar .pika-select {
  cursor: pointer;
  position: absolute;
  z-index: 9998;
  margin: 0;
  left: 0;
  top: 10px;
  opacity: 0;
  overflow: visible !important;
  display: inline-block;
  padding: 0 10px;
  width: 100%;
}
.calendar .calendar__container {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 24px;
  border-radius: 6px;
  margin: 8px 0 0 0;
}
.calendar .pika-single {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 24px;
  border-radius: 6px;
  margin: 8px 0 0 0;
}
.calendar abbr[title], .calendar abbr[data-original-title] {
  cursor: default;
  border-bottom: none;
  text-decoration: none;
  text-transform: uppercase;
  color: #4C6569;
  font-weight: 600;
  font-size: 12px;
}

.sl-loader {
  display: none;
  z-index: 100000;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 240, 241, 0.6);
}
.sl-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.sl-loader-container-img {
  display: block;
  height: 100px;
  width: 100px;
  margin-top: -50px;
  background: url(../../../img/sl_loading_bl.png) 0 0 no-repeat;
  -webkit-animation: ldnga 0.9s steps(12) infinite;
  -moz-animation: ldnga 0.9s steps(12) infinite;
  -ms-animation: ldnga 0.9s steps(12) infinite;
  -o-animation: ldnga 0.9s steps(12) infinite;
  animation: ldnga 0.9s steps(12) infinite;
}

.sl-panel {
  background-color: white;
  width: 404px;
  height: calc(100% - 79px);
  top: 79px;
  position: fixed;
  right: -404px;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
}
.sl-panel-top {
  margin-bottom: 18px;
  margin-top: 14px;
  margin-left: 32px;
  margin-right: 32px;
}
.sl-panel-content {
  max-height: calc(100% - 100px);
  margin-left: 16px;
  margin-right: 8px;
  padding-bottom: 28px;
  flex: 1;
}
.sl-panel-header {
  margin-left: 32px;
  margin-right: 32px;
}

.js-makeform-on .sl-ct-chat-icon-container {
  display: none !important;
}

.sl-ct-chat-icon-container {
  position: absolute;
  top: -1px;
  right: 15px;
  width: auto;
  cursor: pointer;
}
.sl-ct-chat-icon-container i {
  color: #4C6569;
  font-size: 20px;
}

.sl-ct-chat-icon-header {
  /*   position: relative;
     right: 0px;
     margin-left: 10px;
     top: 0;*/
  z-index: 901;
  position: absolute;
  right: unset;
  top: unset;
  bottom: -1px;
  width: 20px;
  cursor: pointer;
}

.js-makeform-on .makefield_ops:not(.ehide) ~ .sl-ct-chat-icon-container {
  right: 72px;
}

.js-makeform-on .makefield_ops:not(.ehide) + .sl-ct-chat-icon-container {
  right: 48px;
}

.js-makeform-on .sl-ct-chat-icon-container {
  right: 72px;
}

.js-makeform-on .in_connector + .makefield_ops + .sl-ct-chat-icon-container {
  right: 72px;
}

.sl-ct-chat-icon-active:after {
  content: "\a";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  position: relative;
  background: #3BC693;
  border: 1px solid white;
  top: -23px;
  right: -13px;
}

.sl-ct-reply-box-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  padding: 12px;
  position: relative;
  background-color: white;
}

.sl-ct-frame .sl-ct-reply-box-container {
  border: 1px solid #D5DFE1;
}

.sl-ct-reply-box-input {
  width: 286px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  max-height: 130px;
  max-width: 230px;
  font-weight: normal;
  cursor: text;
  color: #7B9BA1;
  cursor: text;
}
.sl-ct-reply-box-input:focus {
  color: #2E383A;
}

.sl-ct-reply-box-context-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 12px;
  gap: 12px;
  border-top: 1px solid #CFDADD;
}

.sl-ct-popover-container {
  color: #2E383A;
  white-space: wrap;
  position: absolute;
  z-index: 99;
  width: 286px;
  z-index: 2001;
}
.sl-ct-popover-container-background {
  background-color: white;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 217px;
  font-weight: normal;
  transition: max-height 0.2s ease-out;
}
.sl-ct-popover-container-background.sl-ct-content-loaded {
  max-height: 700px;
}
.sl-ct-popover-container .sl-ct-inline-message {
  align-items: center;
  display: none;
  padding: 0 12px 12px 12px;
  margin-top: -11px;
  padding-top: 12px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
}
.sl-ct-popover-container.sl-ct-frame .sl-ct-popover-container-background {
  padding: 8px 16px 16px 16px;
}
.sl-ct-popover-container.sl-ct-frame .sl-ct-inline-message {
  margin-top: 0;
  padding-top: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
.sl-ct-popover-container .sl-ct-popover-loader {
  position: absolute;
  display: block;
  padding-left: 16px;
  margin-top: -8px;
  font-weight: 400;
}

.sl-ct-popover-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
}

.sl-ct-popover-comments-box-container {
  overflow-y: auto;
  overflow-x: hidden;
  /*fix for scrollbar appears*/
  margin-right: -16px;
  padding-right: 16px;
}

.sl-ct-comment-box--ellipse-grey {
  opacity: 0.4;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #4C6569;
}

.sl-ct-comment-box--ellipse {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #EAEEF0;
}

.sl-ct-comment-box-container {
  border-bottom: 1px solid #CFDADD;
  word-break: break-word;
  position: relative;
}
.sl-ct-comment-box-container .sl-avatar {
  width: 32px;
  height: 32px;
}
.sl-ct-comment-box-container .sl-ct-edit-delete-comment {
  position: relative;
}

.sl-ct-popover-comments-box-container .sl-ct-comment-box-container:last-child {
  border-bottom: none;
}

.sl-ct-edit-comment-box-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  padding: 12px;
}

.sl-ct-edit-comment-box-input {
  width: 286px;
  max-width: 100%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  max-height: 130px;
  color: #7B9BA1;
  cursor: text;
}
.sl-ct-edit-comment-box-input:focus {
  color: #2E383A;
}

.sl-ct-edit-comment-box-context-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 12px;
  margin-top: 12px;
  gap: 12px;
  border-top: 1px solid #CFDADD;
}

.sl-ct-comment-box-new-comment-opacity-transition {
  transition: opacity 3s;
  opacity: 0;
}

.sl-ct-mention-users-popover {
  position: absolute;
  width: 254px;
  height: 156px;
  overflow-y: auto;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
  left: 0;
  padding: 8px;
  border-radius: 4px;
  z-index: 100;
}
.sl-ct-mention-users-popover .sl-avatar {
  width: 32px;
  height: 32px;
}

.sl-ct-mention-users-author-section {
  cursor: pointer;
}
.sl-ct-mention-users-author-section:hover {
  background-color: #F8F8F8;
  border-radius: 4px;
  color: #3D9487;
}

.sl-ct-user-highlight {
  color: #3D9487 !important;
}

.mentiony-container [contenteditable=true]:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #7B9BA1;
  pointer-events: none;
}

.mentiony-container {
  position: relative;
  height: auto;
  max-width: 100%;
  width: 100% !important;
  /* NOTE: All top-right-bottom-left must be equal */
}
.mentiony-container .mentiony-content {
  max-width: 100%;
  font-size: 14px;
  width: 100% !important;
  font-weight: normal;
  max-height: 130px;
  overflow-y: auto;
}

.mentiony-container .pl0 {
  padding-left: 0;
}

.mentiony-container .mention-input-hidden {
  float: left;
  display: inline-block;
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
}

.mentiony-container .mentiony-popover {
  position: absolute;
  width: 266px;
  height: 156px;
  overflow-y: auto;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
  left: -12px !important;
  padding: 8px;
  border-radius: 4px;
  z-index: 100;
  overflow-y: scroll;
  display: none;
}

.mentiony-list {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

.mentiony-item {
  cursor: pointer;
  overflow: hidden;
}
.mentiony-item .sl-avatar {
  width: 32px;
  height: 32px;
}

.mentiony-item:first-child {
  border: none;
}

.mentiony-item.active {
  background-color: #F8F8F8;
}

.mentiony-item img {
  width: 40px;
  height: 40px;
}

.mentiony-item .title, .mentiony-item .help-block {
  margin: 0;
}

.mentiony-container .mentiony-content {
  outline: none;
}
.mentiony-container .mentiony-content font, .mentiony-container .mentiony-content span {
  color: #2E383A;
}

.mention-area .highlight .mentiony-link {
  color: #3D9487;
}

.sl-panel-comment {
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
  cursor: pointer;
}
.sl-panel-comment-header .sl-flag {
  top: 0px;
  right: 0px;
}
.sl-panel-comment-header-right {
  position: relative;
  display: grid;
}
.sl-panel-comment-header-right-bottom {
  line-height: 1em;
}
.sl-panel-comment-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  white-space: normal;
}
.sl-panel-comment:hover {
  background-color: #F8F8F8;
}

.sl-product-fruits-container {
  height: 80px;
  width: 24px;
}
.sl-product-fruits-container .sl-product-fruits-button {
  width: 24px;
  height: 24px;
}

.sl-custom-life-ring-launcher {
  z-index: 900;
  transition: none;
}
.sl-custom-life-ring-launcher-disabled {
  opacity: 0;
  pointer-events: none;
}

.sl-draganddrop-cont li {
  float: left;
}

.sl-image-placeholder {
  background-color: white !important;
  border-radius: 4px;
  width: 160px;
  height: 130px;
  margin: 0 24px 24px 0;
  background-image: repeating-linear-gradient(90deg, #3EAC93, #3EAC93 8px, transparent 8px, transparent 12px), repeating-linear-gradient(180deg, #3EAC93, #3EAC93 8px, transparent 8px, transparent 12px), repeating-linear-gradient(90deg, #3EAC93, #3EAC93 8px, transparent 8px, transparent 12px), repeating-linear-gradient(180deg, #3EAC93, #3EAC93 8px, transparent 8px, transparent 12px);
  background-position: left top, right top, left bottom, left top;
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
}

[class^=sl-icon-]:before,
[class*=" sl-icon-"]:before {
  font-family: "sally-icon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sl-icon-connect:before {
  content: "\e97b";
}

.sl-icon-connect-filled:before {
  content: "\e97c";
}

.sl-icon-box:before {
  content: "\e97d";
}

.sl-icon-folderupload:before {
  content: "\e97e";
}

.sl-icon-play:before {
  content: "\e97f";
}

.sl-icon-shoppingbasket:before {
  content: "\e980";
}

.sl-icon-forward:before {
  content: "\e977";
}

.sl-icon-rewind:before {
  content: "\e978";
}

.sl-icon-star:before {
  content: "\e979";
}

.sl-icon-star-filled:before {
  content: "\e97a";
}

.sl-icon-database:before {
  content: "\e900";
}

.sl-icon-drag:before {
  content: "\e901";
}

.sl-icon-hr:before {
  content: "\e902";
}

.sl-icon-line-curved:before {
  content: "\e903";
}

.sl-icon-resize:before {
  content: "\e904";
}

.sl-icon-row:before {
  content: "\e905";
}

.sl-icon-users:before {
  content: "\e906";
}

.sl-icon-add:before {
  content: "\e907";
}

.sl-icon-arrow-down:before {
  content: "\e908";
}

.sl-icon-arrow-left-right:before {
  content: "\e909";
}

.sl-icon-arrow-up-circle:before {
  content: "\e90a";
}

.sl-icon-arrow-up-down:before {
  content: "\e90b";
}

.sl-icon-arrow-up:before {
  content: "\e90c";
}

.sl-icon-bar-chart-filled:before {
  content: "\e90d";
}

.sl-icon-bar-chart:before {
  content: "\e90e";
}

.sl-icon-bill:before {
  content: "\e90f";
}

.sl-icon-book-open:before {
  content: "\e910";
}

.sl-icon-bookmark:before {
  content: "\e911";
}

.sl-icon-boolean:before {
  content: "\e912";
}

.sl-icon-branch-filled:before {
  content: "\e913";
}

.sl-icon-branch:before {
  content: "\e914";
}

.sl-icon-building:before {
  content: "\e915";
}

.sl-icon-calendar:before {
  content: "\e916";
}

.sl-icon-chat-check:before {
  content: "\e917";
}

.sl-icon-check-double:before {
  content: "\e918";
}

.sl-icon-check:before {
  content: "\e919";
}

.sl-icon-checkbox-blank:before {
  content: "\e91a";
}

.sl-icon-checkbox:before {
  content: "\e91b";
}

.sl-icon-chevron-down:before {
  content: "\e91c";
}

.sl-icon-chevron-left:before {
  content: "\e91d";
}

.sl-icon-chevron-right:before {
  content: "\e91e";
}

.sl-icon-chevron-up:before {
  content: "\e91f";
}

.sl-icon-clock-filled:before {
  content: "\e920";
}

.sl-icon-clock:before {
  content: "\e921";
}

.sl-icon-close:before {
  content: "\e922";
}

.sl-icon-code:before {
  content: "\e923";
}

.sl-icon-copy-filled:before {
  content: "\e924";
}

.sl-icon-copy:before {
  content: "\e925";
}

.sl-icon-divide:before {
  content: "\e926";
}

.sl-icon-download:before {
  content: "\e927";
}

.sl-icon-earth:before {
  content: "\e928";
}

.sl-icon-edit-2:before {
  content: "\e929";
}

.sl-icon-edit-box:before {
  content: "\e92a";
}

.sl-icon-edit:before {
  content: "\e92b";
}

.sl-icon-enter:before {
  content: "\e92c";
}

.sl-icon-error:before {
  content: "\e92d";
}

.sl-icon-exportar:before {
  content: "\e92e";
}

.sl-icon-external-link:before {
  content: "\e92f";
}

.sl-icon-eye-close:before {
  content: "\e930";
}

.sl-icon-eye:before {
  content: "\e931";
}

.sl-icon-file:before {
  content: "\e932";
}

.sl-icon-filter:before {
  content: "\e933";
}

.sl-icon-fingerprint:before {
  content: "\e934";
}

.sl-icon-folder-filled:before {
  content: "\e935";
}

.sl-icon-folder-open:before {
  content: "\e936";
}

.sl-icon-folder:before {
  content: "\e937";
}

.sl-icon-font:before {
  content: "\e938";
}

.sl-icon-formula:before {
  content: "\e939";
}

.sl-icon-fullscreen:before {
  content: "\e93a";
}

.sl-icon-gift:before {
  content: "\e93b";
}

.sl-icon-grid:before {
  content: "\e93c";
}

.sl-icon-home-filled:before {
  content: "\e93d";
}

.sl-icon-home:before {
  content: "\e93e";
}

.sl-icon-image-filled:before {
  content: "\e93f";
}

.sl-icon-image:before {
  content: "\e940";
}

.sl-icon-import:before {
  content: "\e941";
}

.sl-icon-info:before {
  content: "\e942";
}

.sl-icon-layer:before {
  content: "\e943";
}

.sl-icon-layer-filled:before {
  content: "\e944";
}

.sl-icon-layout-2:before {
  content: "\e945";
}

.sl-icon-layout-filled:before {
  content: "\e946";
}

.sl-icon-layout:before {
  content: "\e947";
}

.sl-icon-leave:before {
  content: "\e948";
}

.sl-icon-link:before {
  content: "\e949";
}

.sl-icon-links:before {
  content: "\e94a";
}

.sl-icon-list-2:before {
  content: "\e94b";
}

.sl-icon-list:before {
  content: "\e94c";
}

.sl-icon-lock:before {
  content: "\e94d";
}

.sl-icon-unlock:before {
  content: "\e981";
}

.sl-icon-log-out:before {
  content: "\e94e";
}

.sl-icon-menu:before {
  content: "\e94f";
}

.sl-icon-message-filled:before {
  content: "\e950";
}

.sl-icon-message-round:before {
  content: "\e951";
}

.sl-icon-message:before {
  content: "\e952";
}

.sl-icon-more-option-horizontal:before {
  content: "\e953";
}

.sl-icon-more-option-vetical:before {
  content: "\e954";
}

.sl-icon-move:before {
  content: "\e955";
}

.sl-icon-node:before {
  content: "\e956";
}

.sl-icon-notification:before {
  content: "\e957";
}

.sl-icon-number-1:before {
  content: "\e958";
}

.sl-icon-numbers:before {
  content: "\e959";
}

.sl-icon-pin-filled:before {
  content: "\e95a";
}

.sl-icon-pin:before {
  content: "\e95b";
}

.sl-icon-placeholder:before {
  content: "\e95c";
}

.sl-icon-printer:before {
  content: "\e95d";
}

.sl-icon-question:before {
  content: "\e95e";
}

.sl-icon-redo:before {
  content: "\e95f";
}

.sl-icon-refresh:before {
  content: "\e960";
}

.sl-icon-related:before {
  content: "\e961";
}

.sl-icon-restore:before {
  content: "\e962";
}

.sl-icon-ruler:before {
  content: "\e963";
}

.sl-icon-search:before {
  content: "\e964";
}

.sl-icon-setting:before {
  content: "\e966";
}

.sl-icon-settings-2:before {
  content: "\e966";
}

.sl-icon-stop:before {
  content: "\e967";
}

.sl-icon-table:before {
  content: "\e968";
}

.sl-icon-tag-filled:before {
  content: "\e969";
}

.sl-icon-tag:before {
  content: "\e96a";
}

.sl-icon-testing:before {
  content: "\e96b";
}

.sl-icon-text:before {
  content: "\e96c";
}

.sl-icon-tools:before {
  content: "\e96d";
}

.sl-icon-trash:before {
  content: "\e96e";
}

.sl-icon-undo:before {
  content: "\e96f";
}

.sl-icon-unlink:before {
  content: "\e970";
}

.sl-icon-upload:before {
  content: "\e971";
}

.sl-icon-user:before {
  content: "\e972";
}

.sl-icon-variants-filled:before {
  content: "\e973";
}

.sl-icon-variants:before {
  content: "\e974";
}

.sl-icon-warning-filled:before {
  content: "\e975";
}

.sl-icon-warning:before {
  content: "\e976";
}

.sl-icon-radioblank:before {
  content: "\e983";
}

.sl-icon-radioselected:before {
  content: "\e984";
}

.sl-icon-account:before {
  content: "\e985";
}

.sl-icon-add-category:before {
  content: "\e986";
}

.sl-icon-add-company:before {
  content: "\e987";
}

.sl-icon-add-page:before {
  content: "\e988";
}

.sl-icon-add-point-of-sale:before {
  content: "\e989";
}

.sl-icon-add-product:before {
  content: "\e98a";
}

.sl-icon-add-user:before {
  content: "\e98b";
}

.sl-icon-add-variant:before {
  content: "\e98c";
}

.sl-icon-checkbox-multiple:before {
  content: "\e98d";
}

.sl-icon-color-picker:before {
  content: "\e98e";
}

.sl-icon-download-database:before {
  content: "\e98f";
}

.sl-icon-download-file:before {
  content: "\e990";
}

.sl-icon-download-image:before {
  content: "\e991";
}

.sl-icon-download-information:before {
  content: "\e992";
}

.sl-icon-export-configuration:before {
  content: "\e993";
}

.sl-icon-export-csv:before {
  content: "\e994";
}

.sl-icon-export-excel:before {
  content: "\e995";
}

.sl-icon-filter-off:before {
  content: "\e996";
}

.sl-icon-key:before {
  content: "\e997";
}

.sl-icon-merge:before {
  content: "\e998";
}

.sl-icon-organization:before {
  content: "\e999";
}

.sl-icon-page-import:before {
  content: "\e99a";
}

.sl-icon-ai:before {
  content: "\e99b";
}

.sl-icon-arrow-right:before {
  content: "\e99c";
}

.sl-icon-connect1:before {
  content: "\e99d";
}

.sl-icon-connect1-filled:before {
  content: "\e99e";
}

.sl-icon-email:before {
  content: "\e99f";
}

.sl-icon-money:before {
  content: "\e9a2";
}

.sl-icon-arrowDown:before {
  content: "\e9a0";
}

.sl-icon-arrowUp:before {
  content: "\e9a1";
}

.sl-icon-chat:before {
  content: "\e9a3";
}

.sl-icon-dashboard:before {
  content: "\e9a4";
}

.sl-icon-integration:before {
  content: "\e9a5";
}

.sl-icon-cart:before {
  content: "\e9a6";
}

.sl-icon-store:before {
  content: "\e9a7";
}

.sl-icon-share:before {
  content: "\e9a8";
}

.sl-icon-file-import:before {
  content: "\e9a9";
}

.sl-icon-zip:before {
  content: "\e9aa";
}

.sl-icon-family:before {
  content: "\e9ab";
}

.sl-icon-exportexcel:before {
  content: "\e9ac";
}

.sl-icon-downloadfile:before {
  content: "\e9ad";
}

.sl-icon-downloadimage:before {
  content: "\e9ae";
}

.sl-icon-exportcsv:before {
  content: "\e9af";
}

.sl-icon-exportpdf:before {
  content: "\e9b0";
}

.sl-icon-filter-line:before {
  content: "\e9b1";
}

.sl-block, .sl-block-column, .sl-block-column-evenly, .sl-block-column-evenly-center, .sl-block-column-evenly-end, .sl-block-column-evenly-start, .sl-block-column-around, .sl-block-column-around-center, .sl-block-column-around-end, .sl-block-column-around-start, .sl-block-column-between, .sl-block-column-between-center, .sl-block-column-between-end, .sl-block-column-between-start, .sl-block-column-center, .sl-block-column-center-center, .sl-block-column-center-end, .sl-block-column-center-start, .sl-block-column-end, .sl-block-column-end-center, .sl-block-column-end-end, .sl-block-column-end-start, .sl-block-column-start, .sl-block-column-start-center, .sl-block-column-start-end, .sl-block-column-start-start, .sl-block-row, .sl-block-row-evenly, .sl-block-row-evenly-center, .sl-block-row-evenly-end, .sl-block-row-evenly-start, .sl-block-row-around, .sl-block-row-around-center, .sl-block-row-around-end, .sl-block-row-around-start, .sl-block-row-between, .sl-block-row-between-center, .sl-block-row-between-end, .sl-block-row-between-start, .sl-block-row-center, .sl-block-row-center-center, .sl-block-row-center-end, .sl-block-row-center-start, .sl-block-row-end, .sl-block-row-end-center, .sl-block-row-end-end, .sl-block-row-end-start, .sl-block-row-start, .sl-block-row-start-center, .sl-block-row-start-end, .sl-block-row-start-start {
  display: flex !important;
}
.sl-block-row, .sl-block-row-evenly, .sl-block-row-evenly-center, .sl-block-row-evenly-end, .sl-block-row-evenly-start, .sl-block-row-around, .sl-block-row-around-center, .sl-block-row-around-end, .sl-block-row-around-start, .sl-block-row-between, .sl-block-row-between-center, .sl-block-row-between-end, .sl-block-row-between-start, .sl-block-row-center, .sl-block-row-center-center, .sl-block-row-center-end, .sl-block-row-center-start, .sl-block-row-end, .sl-block-row-end-center, .sl-block-row-end-end, .sl-block-row-end-start, .sl-block-row-start, .sl-block-row-start-center, .sl-block-row-start-end, .sl-block-row-start-start {
  flex-direction: row;
}
.sl-block-row-start, .sl-block-row-start-center, .sl-block-row-start-end, .sl-block-row-start-start {
  justify-content: flex-start;
}
.sl-block-row-start-start {
  align-items: flex-start;
}
.sl-block-row-start-end {
  align-items: flex-end;
}
.sl-block-row-start-center {
  align-items: center;
}
.sl-block-row-end, .sl-block-row-end-center, .sl-block-row-end-end, .sl-block-row-end-start {
  justify-content: flex-end;
}
.sl-block-row-end-start {
  align-items: flex-start;
}
.sl-block-row-end-end {
  align-items: flex-end;
}
.sl-block-row-end-center {
  align-items: center;
}
.sl-block-row-center, .sl-block-row-center-center, .sl-block-row-center-end, .sl-block-row-center-start {
  justify-content: center;
}
.sl-block-row-center-start {
  align-items: flex-start;
}
.sl-block-row-center-end {
  align-items: flex-end;
}
.sl-block-row-center-center {
  align-items: center;
}
.sl-block-row-between, .sl-block-row-between-center, .sl-block-row-between-end, .sl-block-row-between-start {
  justify-content: space-between;
}
.sl-block-row-between-start {
  align-items: flex-start;
}
.sl-block-row-between-end {
  align-items: flex-end;
}
.sl-block-row-between-center {
  align-items: center;
}
.sl-block-row-around, .sl-block-row-around-center, .sl-block-row-around-end, .sl-block-row-around-start {
  justify-content: space-around;
}
.sl-block-row-around-start {
  align-items: flex-start;
}
.sl-block-row-around-end {
  align-items: flex-end;
}
.sl-block-row-around-center {
  align-items: center;
}
.sl-block-row-evenly, .sl-block-row-evenly-center, .sl-block-row-evenly-end, .sl-block-row-evenly-start {
  justify-content: space-evenly;
}
.sl-block-row-evenly-start {
  align-items: flex-start;
}
.sl-block-row-evenly-end {
  align-items: flex-end;
}
.sl-block-row-evenly-center {
  align-items: center;
}
.sl-block-column, .sl-block-column-evenly, .sl-block-column-evenly-center, .sl-block-column-evenly-end, .sl-block-column-evenly-start, .sl-block-column-around, .sl-block-column-around-center, .sl-block-column-around-end, .sl-block-column-around-start, .sl-block-column-between, .sl-block-column-between-center, .sl-block-column-between-end, .sl-block-column-between-start, .sl-block-column-center, .sl-block-column-center-center, .sl-block-column-center-end, .sl-block-column-center-start, .sl-block-column-end, .sl-block-column-end-center, .sl-block-column-end-end, .sl-block-column-end-start, .sl-block-column-start, .sl-block-column-start-center, .sl-block-column-start-end, .sl-block-column-start-start {
  flex-direction: column;
}
.sl-block-column-start, .sl-block-column-start-center, .sl-block-column-start-end, .sl-block-column-start-start {
  justify-content: flex-start;
}
.sl-block-column-start-start {
  align-items: flex-start;
}
.sl-block-column-start-end {
  align-items: flex-end;
}
.sl-block-column-start-center {
  align-items: center;
}
.sl-block-column-end, .sl-block-column-end-center, .sl-block-column-end-end, .sl-block-column-end-start {
  justify-content: flex-end;
}
.sl-block-column-end-start {
  align-items: flex-start;
}
.sl-block-column-end-end {
  align-items: flex-end;
}
.sl-block-column-end-center {
  align-items: center;
}
.sl-block-column-center, .sl-block-column-center-center, .sl-block-column-center-end, .sl-block-column-center-start {
  justify-content: center;
}
.sl-block-column-center-start {
  align-items: flex-start;
}
.sl-block-column-center-end {
  align-items: flex-end;
}
.sl-block-column-center-center {
  align-items: center;
}
.sl-block-column-between, .sl-block-column-between-center, .sl-block-column-between-end, .sl-block-column-between-start {
  justify-content: space-between;
}
.sl-block-column-between-start {
  align-items: flex-start;
}
.sl-block-column-between-end {
  align-items: flex-end;
}
.sl-block-column-between-center {
  align-items: center;
}
.sl-block-column-around, .sl-block-column-around-center, .sl-block-column-around-end, .sl-block-column-around-start {
  justify-content: space-around;
}
.sl-block-column-around-start {
  align-items: flex-start;
}
.sl-block-column-around-end {
  align-items: flex-end;
}
.sl-block-column-around-center {
  align-items: center;
}
.sl-block-column-evenly, .sl-block-column-evenly-center, .sl-block-column-evenly-end, .sl-block-column-evenly-start {
  justify-content: space-evenly;
}
.sl-block-column-evenly-start {
  align-items: flex-start;
}
.sl-block-column-evenly-end {
  align-items: flex-end;
}
.sl-block-column-evenly-center {
  align-items: center;
}

.sl-display-block {
  display: block !important;
}

.sl-row {
  flex-direction: row !important;
}

.sl-column {
  flex-direction: column !important;
}

.sl-justify-start {
  justify-content: flex-start !important;
}

.sl-justify-end {
  justify-content: flex-end !important;
}

.sl-justify-center {
  justify-content: center !important;
}

.sl-justify-between {
  justify-content: space-between !important;
}

.sl-justify-evenly {
  justify-content: space-evenly !important;
}

.sl-justify-around {
  justify-content: space-around !important;
}

.sl-align-start {
  align-items: flex-start !important;
}

.sl-align-end {
  align-items: flex-end !important;
}

.sl-align-center {
  align-items: center !important;
}

.sl-wrap {
  flex-wrap: wrap !important;
}

.sl-w-100 {
  width: 100% !important;
}

.sl-w-75 {
  width: 75% !important;
}

.sl-w-66 {
  width: 66% !important;
}

.sl-w-50 {
  width: 50% !important;
}

.sl-w-33 {
  width: 33% !important;
}

.sl-w-25 {
  width: 25% !important;
}

.sl-maxw-100 {
  max-width: 100% !important;
}

.sl-h-100 {
  height: 100% !important;
}

.sl-hide {
  display: none !important;
}

.sl-flex-1 {
  flex: 1 !important;
}

.sl-flex-2 {
  flex: 2 !important;
}

.sl-flex-3 {
  flex: 3 !important;
}

.sl-flex-4 {
  flex: 4 !important;
}

.sl-flex-5 {
  flex: 5 !important;
}

.sl-flex-6 {
  flex: 6 !important;
}

.sl-flex-7 {
  flex: 7 !important;
}

.sl-flex-8 {
  flex: 8 !important;
}

.sl-flex-9 {
  flex: 9 !important;
}

.sl-flex-10 {
  flex: 10 !important;
}

.sl-flex-11 {
  flex: 11 !important;
}

.sl-flex-12 {
  flex: 12 !important;
}

.sl-order-1 {
  order: 1 !important;
}

.sl-order-2 {
  order: 2 !important;
}

.sl-order-3 {
  order: 3 !important;
}

.sl-order-4 {
  order: 4 !important;
}

.sl-order-5 {
  order: 5 !important;
}

.sl-order-6 {
  order: 6 !important;
}

.sl-col-1 {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr) !important;
}

.sl-col-2 {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
}

.sl-col-3 {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
}

.sl-col-4 {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
}

.sl-col-5 {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
}

.sl-col-6 {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr) !important;
}

.sl-col-7 {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
}

.sl-col-8 {
  display: grid !important;
  grid-template-columns: repeat(8, 1fr) !important;
}

.sl-col-9 {
  display: grid !important;
  grid-template-columns: repeat(9, 1fr) !important;
}

.sl-col-10 {
  display: grid !important;
  grid-template-columns: repeat(10, 1fr) !important;
}

.sl-col-11 {
  display: grid !important;
  grid-template-columns: repeat(11, 1fr) !important;
}

.sl-col-12 {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr) !important;
}

.sl-col-auto {
  display: grid !important;
  grid-template-columns: max-content 1fr;
}

@media (min-width: 576px) {
  .sl-col-xs-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-xs-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-xs-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-xs-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-xs-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-xs-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-xs-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-xs-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-xs-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-xs-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-xs-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-xs-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 940px) {
  .sl-col-sm-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-sm-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-sm-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-sm-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-sm-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-sm-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-sm-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-sm-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-sm-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-sm-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-sm-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-sm-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 1150px) {
  .sl-col-md-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-md-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-md-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-md-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-md-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-md-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-md-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-md-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-md-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-md-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-md-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-md-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 1440px) {
  .sl-col-lg-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-lg-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-lg-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-lg-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-lg-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-lg-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-lg-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-lg-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-lg-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-lg-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-lg-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-lg-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 1700px) {
  .sl-col-xl-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-xl-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-xl-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-xl-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-xl-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-xl-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-xl-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-xl-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-xl-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-xl-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-xl-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-xl-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 2240px) {
  .sl-col-xxl-1 {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .sl-col-xxl-2 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .sl-col-xxl-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .sl-col-xxl-4 {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .sl-col-xxl-5 {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
  }
  .sl-col-xxl-6 {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .sl-col-xxl-7 {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
  }
  .sl-col-xxl-8 {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
  .sl-col-xxl-9 {
    display: grid !important;
    grid-template-columns: repeat(9, 1fr) !important;
  }
  .sl-col-xxl-10 {
    display: grid !important;
    grid-template-columns: repeat(10, 1fr) !important;
  }
  .sl-col-xxl-11 {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr) !important;
  }
  .sl-col-xxl-12 {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .sl-col-xs-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 940px) {
  .sl-col-sm-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 1150px) {
  .sl-col-md-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 1440px) {
  .sl-col-lg-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 1700px) {
  .sl-col-xl-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 2240px) {
  .sl-col-xxl-auto {
    display: grid !important;
    grid-template-columns: max-content 1fr;
  }
}
.sl-gap-100 {
  gap: 4px !important;
}

.sl-gap-200 {
  gap: 8px !important;
}

.sl-gap-300 {
  gap: 12px !important;
}

.sl-gap-400 {
  gap: 16px !important;
}

.sl-gap-450 {
  gap: 18px !important;
}

.sl-gap-500 {
  gap: 20px !important;
}

.sl-gap-550 {
  gap: 22px !important;
}

.sl-gap-600 {
  gap: 24px !important;
}

.sl-gap-650 {
  gap: 28px !important;
}

.sl-gap-700 {
  gap: 32px !important;
}

.sl-gap-750 {
  gap: 36px !important;
}

.sl-gap-800 {
  gap: 40px !important;
}

.sl-gap-825 {
  gap: 44px !important;
}

.sl-gap-850 {
  gap: 48px !important;
}

.sl-gap-900 {
  gap: 56px !important;
}

.sl-gap-925 {
  gap: 60px !important;
}

.sl-gap-950 {
  gap: 64px !important;
}

.sl-gap-1200 {
  gap: 96px !important;
}

.sl-no-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sl-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sl-mw-50 {
  min-width: 50px !important;
}

.sl-mw-100 {
  min-width: 100px !important;
}

.sl-mw-150 {
  min-width: 150px !important;
}

.sl-mw-200 {
  min-width: 200px !important;
}

.sl-mw-250 {
  min-width: 250px !important;
}

.sl-mw-300 {
  min-width: 300px !important;
}

.sl-mw-350 {
  min-width: 350px !important;
}

.sl-mw-400 {
  min-width: 400px !important;
}

.sl-mw-450 {
  min-width: 450px !important;
}

.sl-mw-500 {
  min-width: 500px !important;
}

.sl-mw-550 {
  min-width: 550px !important;
}

.sl-width-50 {
  width: 50px !important;
}

.sl-width-100 {
  width: 100px !important;
}

.sl-width-150 {
  width: 150px !important;
}

.sl-width-200 {
  width: 200px !important;
}

.sl-width-250 {
  width: 250px !important;
}

.sl-width-300 {
  width: 300px !important;
}

.sl-width-350 {
  width: 350px !important;
}

.sl-width-400 {
  width: 400px !important;
}

.sl-width-450 {
  width: 450px !important;
}

.sl-width-500 {
  width: 500px !important;
}

.sl-width-550 {
  width: 550px !important;
}

.sl-float-left {
  float: left !important;
}

.sl-float-right {
  float: right !important;
}

.sl-float-none {
  float: none !important;
}

.sl-bs-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 940px) {
  .sl-bs-container {
    max-width: 540px;
  }
}
@media (min-width: 1150px) {
  .sl-bs-container {
    max-width: 720px;
  }
}
@media (min-width: 1440px) {
  .sl-bs-container {
    max-width: 960px;
  }
}
@media (min-width: 1700px) {
  .sl-bs-container {
    max-width: 1140px;
  }
}
@media (min-width: 2240px) {
  .sl-bs-container {
    max-width: 1500px;
  }
}

.sl-bs-container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sl-bs-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.sl-bs-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.sl-bs-no-gutters > .sl-bs-col,
.sl-bs-no-gutters > [class*=bs-col-] {
  padding-right: 0;
  padding-left: 0;
}

.sl-bs-col-xxl,
.sl-bs-col-xxl-auto, .sl-bs-col-xxl-12, .sl-bs-col-xxl-11, .sl-bs-col-xxl-10, .sl-bs-col-xxl-9, .sl-bs-col-xxl-8, .sl-bs-col-xxl-7, .sl-bs-col-xxl-6, .sl-bs-col-xxl-5, .sl-bs-col-xxl-4, .sl-bs-col-xxl-3, .sl-bs-col-xxl-2, .sl-bs-col-xxl-1, .sl-bs-col-xl,
.sl-bs-col-xl-auto, .sl-bs-col-xl-12, .sl-bs-col-xl-11, .sl-bs-col-xl-10, .sl-bs-col-xl-9, .sl-bs-col-xl-8, .sl-bs-col-xl-7, .sl-bs-col-xl-6, .sl-bs-col-xl-5, .sl-bs-col-xl-4, .sl-bs-col-xl-3, .sl-bs-col-xl-2, .sl-bs-col-xl-1, .sl-bs-col-lg,
.sl-bs-col-lg-auto, .sl-bs-col-lg-12, .sl-bs-col-lg-11, .sl-bs-col-lg-10, .sl-bs-col-lg-9, .sl-bs-col-lg-8, .sl-bs-col-lg-7, .sl-bs-col-lg-6, .sl-bs-col-lg-5, .sl-bs-col-lg-4, .sl-bs-col-lg-3, .sl-bs-col-lg-2, .sl-bs-col-lg-1, .sl-bs-col-md,
.sl-bs-col-md-auto, .sl-bs-col-md-12, .sl-bs-col-md-11, .sl-bs-col-md-10, .sl-bs-col-md-9, .sl-bs-col-md-8, .sl-bs-col-md-7, .sl-bs-col-md-6, .sl-bs-col-md-5, .sl-bs-col-md-4, .sl-bs-col-md-3, .sl-bs-col-md-2, .sl-bs-col-md-1, .sl-bs-col-sm,
.sl-bs-col-sm-auto, .sl-bs-col-sm-12, .sl-bs-col-sm-11, .sl-bs-col-sm-10, .sl-bs-col-sm-9, .sl-bs-col-sm-8, .sl-bs-col-sm-7, .sl-bs-col-sm-6, .sl-bs-col-sm-5, .sl-bs-col-sm-4, .sl-bs-col-sm-3, .sl-bs-col-sm-2, .sl-bs-col-sm-1, .sl-bs-col-xs,
.sl-bs-col-xs-auto, .sl-bs-col-xs-12, .sl-bs-col-xs-11, .sl-bs-col-xs-10, .sl-bs-col-xs-9, .sl-bs-col-xs-8, .sl-bs-col-xs-7, .sl-bs-col-xs-6, .sl-bs-col-xs-5, .sl-bs-col-xs-4, .sl-bs-col-xs-3, .sl-bs-col-xs-2, .sl-bs-col-xs-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .sl-bs-col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-xs-first {
    order: -1;
  }
  .sl-bs-order-xs-last {
    order: 13;
  }
  .sl-bs-order-xs-0 {
    order: 0;
  }
  .sl-bs-order-xs-1 {
    order: 1;
  }
  .sl-bs-order-xs-2 {
    order: 2;
  }
  .sl-bs-order-xs-3 {
    order: 3;
  }
  .sl-bs-order-xs-4 {
    order: 4;
  }
  .sl-bs-order-xs-5 {
    order: 5;
  }
  .sl-bs-order-xs-6 {
    order: 6;
  }
  .sl-bs-order-xs-7 {
    order: 7;
  }
  .sl-bs-order-xs-8 {
    order: 8;
  }
  .sl-bs-order-xs-9 {
    order: 9;
  }
  .sl-bs-order-xs-10 {
    order: 10;
  }
  .sl-bs-order-xs-11 {
    order: 11;
  }
  .sl-bs-order-xs-12 {
    order: 12;
  }
  .sl-bs-offset-xs-0 {
    margin-left: 0;
  }
  .sl-bs-offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-xs-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-xs-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-xs-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-xs-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 940px) {
  .sl-bs-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-sm-first {
    order: -1;
  }
  .sl-bs-order-sm-last {
    order: 13;
  }
  .sl-bs-order-sm-0 {
    order: 0;
  }
  .sl-bs-order-sm-1 {
    order: 1;
  }
  .sl-bs-order-sm-2 {
    order: 2;
  }
  .sl-bs-order-sm-3 {
    order: 3;
  }
  .sl-bs-order-sm-4 {
    order: 4;
  }
  .sl-bs-order-sm-5 {
    order: 5;
  }
  .sl-bs-order-sm-6 {
    order: 6;
  }
  .sl-bs-order-sm-7 {
    order: 7;
  }
  .sl-bs-order-sm-8 {
    order: 8;
  }
  .sl-bs-order-sm-9 {
    order: 9;
  }
  .sl-bs-order-sm-10 {
    order: 10;
  }
  .sl-bs-order-sm-11 {
    order: 11;
  }
  .sl-bs-order-sm-12 {
    order: 12;
  }
  .sl-bs-offset-sm-0 {
    margin-left: 0;
  }
  .sl-bs-offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-sm-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-sm-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-sm-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1150px) {
  .sl-bs-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-md-first {
    order: -1;
  }
  .sl-bs-order-md-last {
    order: 13;
  }
  .sl-bs-order-md-0 {
    order: 0;
  }
  .sl-bs-order-md-1 {
    order: 1;
  }
  .sl-bs-order-md-2 {
    order: 2;
  }
  .sl-bs-order-md-3 {
    order: 3;
  }
  .sl-bs-order-md-4 {
    order: 4;
  }
  .sl-bs-order-md-5 {
    order: 5;
  }
  .sl-bs-order-md-6 {
    order: 6;
  }
  .sl-bs-order-md-7 {
    order: 7;
  }
  .sl-bs-order-md-8 {
    order: 8;
  }
  .sl-bs-order-md-9 {
    order: 9;
  }
  .sl-bs-order-md-10 {
    order: 10;
  }
  .sl-bs-order-md-11 {
    order: 11;
  }
  .sl-bs-order-md-12 {
    order: 12;
  }
  .sl-bs-offset-md-0 {
    margin-left: 0;
  }
  .sl-bs-offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-md-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-md-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-md-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1440px) {
  .sl-bs-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-lg-first {
    order: -1;
  }
  .sl-bs-order-lg-last {
    order: 13;
  }
  .sl-bs-order-lg-0 {
    order: 0;
  }
  .sl-bs-order-lg-1 {
    order: 1;
  }
  .sl-bs-order-lg-2 {
    order: 2;
  }
  .sl-bs-order-lg-3 {
    order: 3;
  }
  .sl-bs-order-lg-4 {
    order: 4;
  }
  .sl-bs-order-lg-5 {
    order: 5;
  }
  .sl-bs-order-lg-6 {
    order: 6;
  }
  .sl-bs-order-lg-7 {
    order: 7;
  }
  .sl-bs-order-lg-8 {
    order: 8;
  }
  .sl-bs-order-lg-9 {
    order: 9;
  }
  .sl-bs-order-lg-10 {
    order: 10;
  }
  .sl-bs-order-lg-11 {
    order: 11;
  }
  .sl-bs-order-lg-12 {
    order: 12;
  }
  .sl-bs-offset-lg-0 {
    margin-left: 0;
  }
  .sl-bs-offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-lg-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-lg-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-lg-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1700px) {
  .sl-bs-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-xl-first {
    order: -1;
  }
  .sl-bs-order-xl-last {
    order: 13;
  }
  .sl-bs-order-xl-0 {
    order: 0;
  }
  .sl-bs-order-xl-1 {
    order: 1;
  }
  .sl-bs-order-xl-2 {
    order: 2;
  }
  .sl-bs-order-xl-3 {
    order: 3;
  }
  .sl-bs-order-xl-4 {
    order: 4;
  }
  .sl-bs-order-xl-5 {
    order: 5;
  }
  .sl-bs-order-xl-6 {
    order: 6;
  }
  .sl-bs-order-xl-7 {
    order: 7;
  }
  .sl-bs-order-xl-8 {
    order: 8;
  }
  .sl-bs-order-xl-9 {
    order: 9;
  }
  .sl-bs-order-xl-10 {
    order: 10;
  }
  .sl-bs-order-xl-11 {
    order: 11;
  }
  .sl-bs-order-xl-12 {
    order: 12;
  }
  .sl-bs-offset-xl-0 {
    margin-left: 0;
  }
  .sl-bs-offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-xl-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-xl-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-xl-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 2240px) {
  .sl-bs-col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .sl-bs-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .sl-bs-col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sl-bs-col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sl-bs-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sl-bs-col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sl-bs-col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sl-bs-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-bs-col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sl-bs-col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sl-bs-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .sl-bs-col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sl-bs-col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sl-bs-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sl-bs-order-xxl-first {
    order: -1;
  }
  .sl-bs-order-xxl-last {
    order: 13;
  }
  .sl-bs-order-xxl-0 {
    order: 0;
  }
  .sl-bs-order-xxl-1 {
    order: 1;
  }
  .sl-bs-order-xxl-2 {
    order: 2;
  }
  .sl-bs-order-xxl-3 {
    order: 3;
  }
  .sl-bs-order-xxl-4 {
    order: 4;
  }
  .sl-bs-order-xxl-5 {
    order: 5;
  }
  .sl-bs-order-xxl-6 {
    order: 6;
  }
  .sl-bs-order-xxl-7 {
    order: 7;
  }
  .sl-bs-order-xxl-8 {
    order: 8;
  }
  .sl-bs-order-xxl-9 {
    order: 9;
  }
  .sl-bs-order-xxl-10 {
    order: 10;
  }
  .sl-bs-order-xxl-11 {
    order: 11;
  }
  .sl-bs-order-xxl-12 {
    order: 12;
  }
  .sl-bs-offset-xxl-0 {
    margin-left: 0;
  }
  .sl-bs-offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .sl-bs-offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .sl-bs-offset-xxl-3 {
    margin-left: 25%;
  }
  .sl-bs-offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .sl-bs-offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .sl-bs-offset-xxl-6 {
    margin-left: 50%;
  }
  .sl-bs-offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .sl-bs-offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .sl-bs-offset-xxl-9 {
    margin-left: 75%;
  }
  .sl-bs-offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .sl-bs-offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.sl-white {
  color: #ffffff !important;
}

.sl-primary50 {
  color: #ECFEEE !important;
}

.sl-primary100 {
  color: #CBFBD6 !important;
}

.sl-primary200 {
  color: #A3F5BE !important;
}

.sl-primary300 {
  color: #76EAAA !important;
}

.sl-primary400 {
  color: #61DAA5 !important;
}

.sl-primary500 {
  color: #3BC693 !important;
}

.sl-primary600 {
  color: #3EAC93 !important;
}

.sl-primary700 {
  color: #3D9487 !important;
}

.sl-primary800 {
  color: #387B77 !important;
}

.sl-primary900 {
  color: #205A5A !important;
}

.sl-icon-default {
  color: #2E383A !important;
}

.sl-icon-secondary {
  color: #4C6569 !important;
}

.sl-icon-tertiary {
  color: #5A4B35 !important;
}

.sl-icon-neutral {
  color: #ABC0C4 !important;
}

.sl-icon-neutrallight {
  color: #CFDADD !important;
}

.sl-icon-highlight {
  color: #76EAAA !important;
}

.sl-icon-alternative {
  color: #5A4B35 !important;
}

.sl-icon-light {
  color: #7B9BA1 !important;
}

.sl-icon-disable {
  color: #ABC0C4 !important;
}

.sl-icon-info-01 {
  color: #005583 !important;
}

.sl-icon-info-02 {
  color: #0067AA !important;
}

.sl-icon-success-01 {
  color: #05570A !important;
}

.sl-icon-success-02 {
  color: #0BA815 !important;
}

.sl-icon-warning-01 {
  color: #D57300 !important;
}

.sl-icon-warning-02 {
  color: #653600 !important;
}

.sl-icon-error-01 {
  color: #55201E !important;
}

.sl-icon-error-02 {
  color: #D2322C !important;
}

.sl-content-default {
  color: #2E383A !important;
}

.sl-content-secondary {
  color: #4C6569 !important;
}

.sl-content-tertiary {
  color: #5A4B35 !important;
}

.sl-content-light {
  color: #7B9BA1 !important;
}

.sl-content-disable {
  color: #ABC0C4 !important;
}

.sl-content-info-01 {
  color: #005583 !important;
}

.sl-content-info-02 {
  color: #2977D1 !important;
}

.sl-content-success-01 {
  color: #0BA815 !important;
}

.sl-content-success-02 {
  color: #05570A !important;
}

.sl-content-warning-01 {
  color: #653600 !important;
}

.sl-content-warning-02 {
  color: #D57300 !important;
}

.sl-content-error-01 {
  color: #D2322C !important;
}

.sl-content-error-02 {
  color: #55201E !important;
}

.sl-background-info-01 {
  background-color: #E9F9FB !important;
}

.sl-background-info-02 {
  background-color: #2977D1 !important;
}

.sl-background-info-03 {
  background-color: #D7F3F9 !important;
}

.sl-background-success-01 {
  background-color: #E6FAE8 !important;
}

.sl-background-success-02 {
  background-color: #BFF5C5 !important;
}

.sl-background-success-03 {
  background-color: #9BE8A3 !important;
}

.sl-background-success-04 {
  background-color: #18C925 !important;
}

.sl-background-success-05 {
  background-color: #D7F9DB !important;
}

.sl-background-warning-01 {
  background-color: #FFF1DB !important;
}

.sl-background-warning-02 {
  background-color: #FFD9A0 !important;
}

.sl-background-warning-03 {
  background-color: #F7C983 !important;
}

.sl-background-warning-04 {
  background-color: #FFAA46 !important;
}

.sl-background-warning-05 {
  background-color: #D57300 !important;
}

.sl-background-error-01 {
  background-color: #FDEDED !important;
}

.sl-background-error-02 {
  background-color: #F7BCBA !important;
}

.sl-background-error-03 {
  background-color: #E89E9B !important;
}

.sl-background-error-04 {
  background-color: #ED352E !important;
}

.sl-background-error-05 {
  background-color: #FAD7D6 !important;
}

.sl-border-info-01 {
  border: 1px solid #BAEBF5 !important;
}

.sl-border-neutral-01 {
  border: 1px solid #D5DFE1 !important;
}

.sl-border-success-01 {
  border: 1px solid #9BE8A3 !important;
}

.sl-border-success-02 {
  border: 1px solid #0BA815 !important;
}

.sl-border-warning-01 {
  border: 1px solid #FFD9A0 !important;
}

.sl-border-warning-02 {
  border: 1px solid #F7C983 !important;
}

.sl-border-error-01 {
  border: 1px solid #F7BCBA !important;
}

.sl-border-error-02 {
  border: 1px solid #D2322C !important;
}

.sl-icon-light {
  color: #CFDADD !important;
}

.sl-content-default {
  color: #2E383A !important;
}

.sl-content-secondary {
  color: #4C6569 !important;
}

.sl-content-light {
  color: #7B9BA1 !important;
}

.sl-content-disable {
  color: #ABC0C4 !important;
}

.sl-semantic-error-01 {
  color: #D2322C !important;
}

.sl-semantic-warning-01 {
  color: #D57300 !important;
}

.sl-semantic-info-01 {
  color: #005583 !important;
}

.sl-semantic-success-01 {
  color: #0BA815 !important;
}

.sl-content-success {
  color: #0BA815;
}

.sl-m-0 {
  margin: 0px !important;
}

.sl-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.sl-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sl-mt-0 {
  margin-top: 0px !important;
}

.sl-mb-0 {
  margin-bottom: 0px !important;
}

.sl-ml-0 {
  margin-left: 0px !important;
}

.sl-mr-0 {
  margin-right: 0px !important;
}

.sl-m-50 {
  margin: 2px !important;
}

.sl-my-50 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.sl-mx-50 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.sl-mt-50 {
  margin-top: 2px !important;
}

.sl-mb-50 {
  margin-bottom: 2px !important;
}

.sl-ml-50 {
  margin-left: 2px !important;
}

.sl-mr-50 {
  margin-right: 2px !important;
}

.sl-m-100 {
  margin: 4px !important;
}

.sl-my-100 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.sl-mx-100 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.sl-mt-100 {
  margin-top: 4px !important;
}

.sl-mb-100 {
  margin-bottom: 4px !important;
}

.sl-ml-100 {
  margin-left: 4px !important;
}

.sl-mr-100 {
  margin-right: 4px !important;
}

.sl-m-150 {
  margin: 6px !important;
}

.sl-my-150 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.sl-mx-150 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.sl-mt-150 {
  margin-top: 6px !important;
}

.sl-mb-150 {
  margin-bottom: 6px !important;
}

.sl-ml-150 {
  margin-left: 6px !important;
}

.sl-mr-150 {
  margin-right: 6px !important;
}

.sl-m-200 {
  margin: 8px !important;
}

.sl-my-200 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.sl-mx-200 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.sl-mt-200 {
  margin-top: 8px !important;
}

.sl-mb-200 {
  margin-bottom: 8px !important;
}

.sl-ml-200 {
  margin-left: 8px !important;
}

.sl-mr-200 {
  margin-right: 8px !important;
}

.sl-m-250 {
  margin: 10px !important;
}

.sl-my-250 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.sl-mx-250 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.sl-mt-250 {
  margin-top: 10px !important;
}

.sl-mb-250 {
  margin-bottom: 10px !important;
}

.sl-ml-250 {
  margin-left: 10px !important;
}

.sl-mr-250 {
  margin-right: 10px !important;
}

.sl-m-300 {
  margin: 12px !important;
}

.sl-my-300 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.sl-mx-300 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.sl-mt-300 {
  margin-top: 12px !important;
}

.sl-mb-300 {
  margin-bottom: 12px !important;
}

.sl-ml-300 {
  margin-left: 12px !important;
}

.sl-mr-300 {
  margin-right: 12px !important;
}

.sl-m-350 {
  margin: 14px !important;
}

.sl-my-350 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.sl-mx-350 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.sl-mt-350 {
  margin-top: 14px !important;
}

.sl-mb-350 {
  margin-bottom: 14px !important;
}

.sl-ml-350 {
  margin-left: 14px !important;
}

.sl-mr-350 {
  margin-right: 14px !important;
}

.sl-m-400 {
  margin: 16px !important;
}

.sl-my-400 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.sl-mx-400 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.sl-mt-400 {
  margin-top: 16px !important;
}

.sl-mb-400 {
  margin-bottom: 16px !important;
}

.sl-ml-400 {
  margin-left: 16px !important;
}

.sl-mr-400 {
  margin-right: 16px !important;
}

.sl-m-450 {
  margin: 18px !important;
}

.sl-my-450 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.sl-mx-450 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.sl-mt-450 {
  margin-top: 18px !important;
}

.sl-mb-450 {
  margin-bottom: 18px !important;
}

.sl-ml-450 {
  margin-left: 18px !important;
}

.sl-mr-450 {
  margin-right: 18px !important;
}

.sl-m-500 {
  margin: 20px !important;
}

.sl-my-500 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.sl-mx-500 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.sl-mt-500 {
  margin-top: 20px !important;
}

.sl-mb-500 {
  margin-bottom: 20px !important;
}

.sl-ml-500 {
  margin-left: 20px !important;
}

.sl-mr-500 {
  margin-right: 20px !important;
}

.sl-m-550 {
  margin: 22px !important;
}

.sl-my-550 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.sl-mx-550 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.sl-mt-550 {
  margin-top: 22px !important;
}

.sl-mb-550 {
  margin-bottom: 22px !important;
}

.sl-ml-550 {
  margin-left: 22px !important;
}

.sl-mr-550 {
  margin-right: 22px !important;
}

.sl-m-600 {
  margin: 24px !important;
}

.sl-my-600 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.sl-mx-600 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.sl-mt-600 {
  margin-top: 24px !important;
}

.sl-mb-600 {
  margin-bottom: 24px !important;
}

.sl-ml-600 {
  margin-left: 24px !important;
}

.sl-mr-600 {
  margin-right: 24px !important;
}

.sl-m-650 {
  margin: 28px !important;
}

.sl-my-650 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.sl-mx-650 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.sl-mt-650 {
  margin-top: 28px !important;
}

.sl-mb-650 {
  margin-bottom: 28px !important;
}

.sl-ml-650 {
  margin-left: 28px !important;
}

.sl-mr-650 {
  margin-right: 28px !important;
}

.sl-m-700 {
  margin: 32px !important;
}

.sl-my-700 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.sl-mx-700 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.sl-mt-700 {
  margin-top: 32px !important;
}

.sl-mb-700 {
  margin-bottom: 32px !important;
}

.sl-ml-700 {
  margin-left: 32px !important;
}

.sl-mr-700 {
  margin-right: 32px !important;
}

.sl-m-750 {
  margin: 36px !important;
}

.sl-my-750 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.sl-mx-750 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.sl-mt-750 {
  margin-top: 36px !important;
}

.sl-mb-750 {
  margin-bottom: 36px !important;
}

.sl-ml-750 {
  margin-left: 36px !important;
}

.sl-mr-750 {
  margin-right: 36px !important;
}

.sl-m-800 {
  margin: 40px !important;
}

.sl-my-800 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.sl-mx-800 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.sl-mt-800 {
  margin-top: 40px !important;
}

.sl-mb-800 {
  margin-bottom: 40px !important;
}

.sl-ml-800 {
  margin-left: 40px !important;
}

.sl-mr-800 {
  margin-right: 40px !important;
}

.sl-m-825 {
  margin: 44px !important;
}

.sl-my-825 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.sl-mx-825 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.sl-mt-825 {
  margin-top: 44px !important;
}

.sl-mb-825 {
  margin-bottom: 44px !important;
}

.sl-ml-825 {
  margin-left: 44px !important;
}

.sl-mr-825 {
  margin-right: 44px !important;
}

.sl-m-850 {
  margin: 48px !important;
}

.sl-my-850 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.sl-mx-850 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.sl-mt-850 {
  margin-top: 48px !important;
}

.sl-mb-850 {
  margin-bottom: 48px !important;
}

.sl-ml-850 {
  margin-left: 48px !important;
}

.sl-mr-850 {
  margin-right: 48px !important;
}

.sl-m-900 {
  margin: 56px !important;
}

.sl-my-900 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.sl-mx-900 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.sl-mt-900 {
  margin-top: 56px !important;
}

.sl-mb-900 {
  margin-bottom: 56px !important;
}

.sl-ml-900 {
  margin-left: 56px !important;
}

.sl-mr-900 {
  margin-right: 56px !important;
}

.sl-m-925 {
  margin: 60px !important;
}

.sl-my-925 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.sl-mx-925 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.sl-mt-925 {
  margin-top: 60px !important;
}

.sl-mb-925 {
  margin-bottom: 60px !important;
}

.sl-ml-925 {
  margin-left: 60px !important;
}

.sl-mr-925 {
  margin-right: 60px !important;
}

.sl-m-950 {
  margin: 64px !important;
}

.sl-my-950 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.sl-mx-950 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.sl-mt-950 {
  margin-top: 64px !important;
}

.sl-mb-950 {
  margin-bottom: 64px !important;
}

.sl-ml-950 {
  margin-left: 64px !important;
}

.sl-mr-950 {
  margin-right: 64px !important;
}

.sl-m-1200 {
  margin: 96px !important;
}

.sl-my-1200 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.sl-mx-1200 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.sl-mt-1200 {
  margin-top: 96px !important;
}

.sl-mb-1200 {
  margin-bottom: 96px !important;
}

.sl-ml-1200 {
  margin-left: 96px !important;
}

.sl-mr-1200 {
  margin-right: 96px !important;
}

.sl-m-n50 {
  margin: -2px !important;
}

.sl-my-n50 {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.sl-mx-n50 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.sl-mt-n50 {
  margin-top: -2px !important;
}

.sl-mb-n50 {
  margin-bottom: -2px !important;
}

.sl-ml-n50 {
  margin-left: -2px !important;
}

.sl-mr-n50 {
  margin-right: -2px !important;
}

.sl-m-n100 {
  margin: -4px !important;
}

.sl-my-n100 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.sl-mx-n100 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.sl-mt-n100 {
  margin-top: -4px !important;
}

.sl-mb-n100 {
  margin-bottom: -4px !important;
}

.sl-ml-n100 {
  margin-left: -4px !important;
}

.sl-mr-n100 {
  margin-right: -4px !important;
}

.sl-m-n150 {
  margin: -6px !important;
}

.sl-my-n150 {
  margin-top: -6px !important;
  margin-bottom: -6px !important;
}

.sl-mx-n150 {
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.sl-mt-n150 {
  margin-top: -6px !important;
}

.sl-mb-n150 {
  margin-bottom: -6px !important;
}

.sl-ml-n150 {
  margin-left: -6px !important;
}

.sl-mr-n150 {
  margin-right: -6px !important;
}

.sl-m-n200 {
  margin: -8px !important;
}

.sl-my-n200 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.sl-mx-n200 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.sl-mt-n200 {
  margin-top: -8px !important;
}

.sl-mb-n200 {
  margin-bottom: -8px !important;
}

.sl-ml-n200 {
  margin-left: -8px !important;
}

.sl-mr-n200 {
  margin-right: -8px !important;
}

.sl-m-n250 {
  margin: -10px !important;
}

.sl-my-n250 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.sl-mx-n250 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.sl-mt-n250 {
  margin-top: -10px !important;
}

.sl-mb-n250 {
  margin-bottom: -10px !important;
}

.sl-ml-n250 {
  margin-left: -10px !important;
}

.sl-mr-n250 {
  margin-right: -10px !important;
}

.sl-m-n300 {
  margin: -12px !important;
}

.sl-my-n300 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.sl-mx-n300 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.sl-mt-n300 {
  margin-top: -12px !important;
}

.sl-mb-n300 {
  margin-bottom: -12px !important;
}

.sl-ml-n300 {
  margin-left: -12px !important;
}

.sl-mr-n300 {
  margin-right: -12px !important;
}

.sl-m-n350 {
  margin: -14px !important;
}

.sl-my-n350 {
  margin-top: -14px !important;
  margin-bottom: -14px !important;
}

.sl-mx-n350 {
  margin-left: -14px !important;
  margin-right: -14px !important;
}

.sl-mt-n350 {
  margin-top: -14px !important;
}

.sl-mb-n350 {
  margin-bottom: -14px !important;
}

.sl-ml-n350 {
  margin-left: -14px !important;
}

.sl-mr-n350 {
  margin-right: -14px !important;
}

.sl-m-n400 {
  margin: -16px !important;
}

.sl-my-n400 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.sl-mx-n400 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.sl-mt-n400 {
  margin-top: -16px !important;
}

.sl-mb-n400 {
  margin-bottom: -16px !important;
}

.sl-ml-n400 {
  margin-left: -16px !important;
}

.sl-mr-n400 {
  margin-right: -16px !important;
}

.sl-m-n450 {
  margin: -18px !important;
}

.sl-my-n450 {
  margin-top: -18px !important;
  margin-bottom: -18px !important;
}

.sl-mx-n450 {
  margin-left: -18px !important;
  margin-right: -18px !important;
}

.sl-mt-n450 {
  margin-top: -18px !important;
}

.sl-mb-n450 {
  margin-bottom: -18px !important;
}

.sl-ml-n450 {
  margin-left: -18px !important;
}

.sl-mr-n450 {
  margin-right: -18px !important;
}

.sl-m-n500 {
  margin: -20px !important;
}

.sl-my-n500 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.sl-mx-n500 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.sl-mt-n500 {
  margin-top: -20px !important;
}

.sl-mb-n500 {
  margin-bottom: -20px !important;
}

.sl-ml-n500 {
  margin-left: -20px !important;
}

.sl-mr-n500 {
  margin-right: -20px !important;
}

.sl-m-n550 {
  margin: -22px !important;
}

.sl-my-n550 {
  margin-top: -22px !important;
  margin-bottom: -22px !important;
}

.sl-mx-n550 {
  margin-left: -22px !important;
  margin-right: -22px !important;
}

.sl-mt-n550 {
  margin-top: -22px !important;
}

.sl-mb-n550 {
  margin-bottom: -22px !important;
}

.sl-ml-n550 {
  margin-left: -22px !important;
}

.sl-mr-n550 {
  margin-right: -22px !important;
}

.sl-m-n600 {
  margin: -24px !important;
}

.sl-my-n600 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.sl-mx-n600 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.sl-mt-n600 {
  margin-top: -24px !important;
}

.sl-mb-n600 {
  margin-bottom: -24px !important;
}

.sl-ml-n600 {
  margin-left: -24px !important;
}

.sl-mr-n600 {
  margin-right: -24px !important;
}

.sl-m-n650 {
  margin: -28px !important;
}

.sl-my-n650 {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}

.sl-mx-n650 {
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.sl-mt-n650 {
  margin-top: -28px !important;
}

.sl-mb-n650 {
  margin-bottom: -28px !important;
}

.sl-ml-n650 {
  margin-left: -28px !important;
}

.sl-mr-n650 {
  margin-right: -28px !important;
}

.sl-m-n700 {
  margin: -32px !important;
}

.sl-my-n700 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.sl-mx-n700 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.sl-mt-n700 {
  margin-top: -32px !important;
}

.sl-mb-n700 {
  margin-bottom: -32px !important;
}

.sl-ml-n700 {
  margin-left: -32px !important;
}

.sl-mr-n700 {
  margin-right: -32px !important;
}

.sl-m-n750 {
  margin: -36px !important;
}

.sl-my-n750 {
  margin-top: -36px !important;
  margin-bottom: -36px !important;
}

.sl-mx-n750 {
  margin-left: -36px !important;
  margin-right: -36px !important;
}

.sl-mt-n750 {
  margin-top: -36px !important;
}

.sl-mb-n750 {
  margin-bottom: -36px !important;
}

.sl-ml-n750 {
  margin-left: -36px !important;
}

.sl-mr-n750 {
  margin-right: -36px !important;
}

.sl-m-n800 {
  margin: -40px !important;
}

.sl-my-n800 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.sl-mx-n800 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.sl-mt-n800 {
  margin-top: -40px !important;
}

.sl-mb-n800 {
  margin-bottom: -40px !important;
}

.sl-ml-n800 {
  margin-left: -40px !important;
}

.sl-mr-n800 {
  margin-right: -40px !important;
}

.sl-m-n850 {
  margin: -48px !important;
}

.sl-my-n850 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.sl-mx-n850 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.sl-mt-n850 {
  margin-top: -48px !important;
}

.sl-mb-n850 {
  margin-bottom: -48px !important;
}

.sl-ml-n850 {
  margin-left: -48px !important;
}

.sl-mr-n850 {
  margin-right: -48px !important;
}

.sl-m-n900 {
  margin: -56px !important;
}

.sl-my-n900 {
  margin-top: -56px !important;
  margin-bottom: -56px !important;
}

.sl-mx-n900 {
  margin-left: -56px !important;
  margin-right: -56px !important;
}

.sl-mt-n900 {
  margin-top: -56px !important;
}

.sl-mb-n900 {
  margin-bottom: -56px !important;
}

.sl-ml-n900 {
  margin-left: -56px !important;
}

.sl-mr-n900 {
  margin-right: -56px !important;
}

.sl-m-n925 {
  margin: -60px !important;
}

.sl-my-n925 {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}

.sl-mx-n925 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

.sl-mt-n925 {
  margin-top: -60px !important;
}

.sl-mb-n925 {
  margin-bottom: -60px !important;
}

.sl-ml-n925 {
  margin-left: -60px !important;
}

.sl-mr-n925 {
  margin-right: -60px !important;
}

.sl-m-n950 {
  margin: -64px !important;
}

.sl-my-n950 {
  margin-top: -64px !important;
  margin-bottom: -64px !important;
}

.sl-mx-n950 {
  margin-left: -64px !important;
  margin-right: -64px !important;
}

.sl-mt-n950 {
  margin-top: -64px !important;
}

.sl-mb-n950 {
  margin-bottom: -64px !important;
}

.sl-ml-n950 {
  margin-left: -64px !important;
}

.sl-mr-n950 {
  margin-right: -64px !important;
}

.sl-m-n1200 {
  margin: -96px !important;
}

.sl-my-n1200 {
  margin-top: -96px !important;
  margin-bottom: -96px !important;
}

.sl-mx-n1200 {
  margin-left: -96px !important;
  margin-right: -96px !important;
}

.sl-mt-n1200 {
  margin-top: -96px !important;
}

.sl-mb-n1200 {
  margin-bottom: -96px !important;
}

.sl-ml-n1200 {
  margin-left: -96px !important;
}

.sl-mr-n1200 {
  margin-right: -96px !important;
}

.sl-m-auto {
  margin: auto !important;
}

.sl-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.sl-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.sl-mt-auto {
  margin-top: auto !important;
}

.sl-mb-auto {
  margin-bottom: auto !important;
}

.sl-ml-auto {
  margin-left: auto !important;
}

.sl-mr-auto {
  margin-right: auto !important;
}

.sl-p-0 {
  padding: 0px !important;
}

.sl-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sl-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.sl-pt-0 {
  padding-top: 0px !important;
}

.sl-pb-0 {
  padding-bottom: 0px !important;
}

.sl-pl-0 {
  padding-left: 0px !important;
}

.sl-pr-0 {
  padding-right: 0px !important;
}

.sl-p-50 {
  padding: 2px !important;
}

.sl-py-50 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.sl-px-50 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.sl-pt-50 {
  padding-top: 2px !important;
}

.sl-pb-50 {
  padding-bottom: 2px !important;
}

.sl-pl-50 {
  padding-left: 2px !important;
}

.sl-pr-50 {
  padding-right: 2px !important;
}

.sl-p-100 {
  padding: 4px !important;
}

.sl-py-100 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.sl-px-100 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.sl-pt-100 {
  padding-top: 4px !important;
}

.sl-pb-100 {
  padding-bottom: 4px !important;
}

.sl-pl-100 {
  padding-left: 4px !important;
}

.sl-pr-100 {
  padding-right: 4px !important;
}

.sl-p-150 {
  padding: 6px !important;
}

.sl-py-150 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.sl-px-150 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.sl-pt-150 {
  padding-top: 6px !important;
}

.sl-pb-150 {
  padding-bottom: 6px !important;
}

.sl-pl-150 {
  padding-left: 6px !important;
}

.sl-pr-150 {
  padding-right: 6px !important;
}

.sl-p-200 {
  padding: 8px !important;
}

.sl-py-200 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.sl-px-200 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.sl-pt-200 {
  padding-top: 8px !important;
}

.sl-pb-200 {
  padding-bottom: 8px !important;
}

.sl-pl-200 {
  padding-left: 8px !important;
}

.sl-pr-200 {
  padding-right: 8px !important;
}

.sl-p-250 {
  padding: 10px !important;
}

.sl-py-250 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.sl-px-250 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sl-pt-250 {
  padding-top: 10px !important;
}

.sl-pb-250 {
  padding-bottom: 10px !important;
}

.sl-pl-250 {
  padding-left: 10px !important;
}

.sl-pr-250 {
  padding-right: 10px !important;
}

.sl-p-300 {
  padding: 12px !important;
}

.sl-py-300 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.sl-px-300 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.sl-pt-300 {
  padding-top: 12px !important;
}

.sl-pb-300 {
  padding-bottom: 12px !important;
}

.sl-pl-300 {
  padding-left: 12px !important;
}

.sl-pr-300 {
  padding-right: 12px !important;
}

.sl-p-350 {
  padding: 14px !important;
}

.sl-py-350 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.sl-px-350 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.sl-pt-350 {
  padding-top: 14px !important;
}

.sl-pb-350 {
  padding-bottom: 14px !important;
}

.sl-pl-350 {
  padding-left: 14px !important;
}

.sl-pr-350 {
  padding-right: 14px !important;
}

.sl-p-400 {
  padding: 16px !important;
}

.sl-py-400 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.sl-px-400 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sl-pt-400 {
  padding-top: 16px !important;
}

.sl-pb-400 {
  padding-bottom: 16px !important;
}

.sl-pl-400 {
  padding-left: 16px !important;
}

.sl-pr-400 {
  padding-right: 16px !important;
}

.sl-p-450 {
  padding: 18px !important;
}

.sl-py-450 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.sl-px-450 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.sl-pt-450 {
  padding-top: 18px !important;
}

.sl-pb-450 {
  padding-bottom: 18px !important;
}

.sl-pl-450 {
  padding-left: 18px !important;
}

.sl-pr-450 {
  padding-right: 18px !important;
}

.sl-p-500 {
  padding: 20px !important;
}

.sl-py-500 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.sl-px-500 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sl-pt-500 {
  padding-top: 20px !important;
}

.sl-pb-500 {
  padding-bottom: 20px !important;
}

.sl-pl-500 {
  padding-left: 20px !important;
}

.sl-pr-500 {
  padding-right: 20px !important;
}

.sl-p-550 {
  padding: 22px !important;
}

.sl-py-550 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.sl-px-550 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.sl-pt-550 {
  padding-top: 22px !important;
}

.sl-pb-550 {
  padding-bottom: 22px !important;
}

.sl-pl-550 {
  padding-left: 22px !important;
}

.sl-pr-550 {
  padding-right: 22px !important;
}

.sl-p-600 {
  padding: 24px !important;
}

.sl-py-600 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.sl-px-600 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.sl-pt-600 {
  padding-top: 24px !important;
}

.sl-pb-600 {
  padding-bottom: 24px !important;
}

.sl-pl-600 {
  padding-left: 24px !important;
}

.sl-pr-600 {
  padding-right: 24px !important;
}

.sl-p-650 {
  padding: 28px !important;
}

.sl-py-650 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.sl-px-650 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.sl-pt-650 {
  padding-top: 28px !important;
}

.sl-pb-650 {
  padding-bottom: 28px !important;
}

.sl-pl-650 {
  padding-left: 28px !important;
}

.sl-pr-650 {
  padding-right: 28px !important;
}

.sl-p-700 {
  padding: 32px !important;
}

.sl-py-700 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.sl-px-700 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.sl-pt-700 {
  padding-top: 32px !important;
}

.sl-pb-700 {
  padding-bottom: 32px !important;
}

.sl-pl-700 {
  padding-left: 32px !important;
}

.sl-pr-700 {
  padding-right: 32px !important;
}

.sl-p-750 {
  padding: 36px !important;
}

.sl-py-750 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.sl-px-750 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.sl-pt-750 {
  padding-top: 36px !important;
}

.sl-pb-750 {
  padding-bottom: 36px !important;
}

.sl-pl-750 {
  padding-left: 36px !important;
}

.sl-pr-750 {
  padding-right: 36px !important;
}

.sl-p-800 {
  padding: 40px !important;
}

.sl-py-800 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.sl-px-800 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.sl-pt-800 {
  padding-top: 40px !important;
}

.sl-pb-800 {
  padding-bottom: 40px !important;
}

.sl-pl-800 {
  padding-left: 40px !important;
}

.sl-pr-800 {
  padding-right: 40px !important;
}

.sl-p-825 {
  padding: 44px !important;
}

.sl-py-825 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.sl-px-825 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.sl-pt-825 {
  padding-top: 44px !important;
}

.sl-pb-825 {
  padding-bottom: 44px !important;
}

.sl-pl-825 {
  padding-left: 44px !important;
}

.sl-pr-825 {
  padding-right: 44px !important;
}

.sl-p-850 {
  padding: 48px !important;
}

.sl-py-850 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.sl-px-850 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.sl-pt-850 {
  padding-top: 48px !important;
}

.sl-pb-850 {
  padding-bottom: 48px !important;
}

.sl-pl-850 {
  padding-left: 48px !important;
}

.sl-pr-850 {
  padding-right: 48px !important;
}

.sl-p-900 {
  padding: 56px !important;
}

.sl-py-900 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.sl-px-900 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.sl-pt-900 {
  padding-top: 56px !important;
}

.sl-pb-900 {
  padding-bottom: 56px !important;
}

.sl-pl-900 {
  padding-left: 56px !important;
}

.sl-pr-900 {
  padding-right: 56px !important;
}

.sl-p-925 {
  padding: 60px !important;
}

.sl-py-925 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.sl-px-925 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.sl-pt-925 {
  padding-top: 60px !important;
}

.sl-pb-925 {
  padding-bottom: 60px !important;
}

.sl-pl-925 {
  padding-left: 60px !important;
}

.sl-pr-925 {
  padding-right: 60px !important;
}

.sl-p-950 {
  padding: 64px !important;
}

.sl-py-950 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.sl-px-950 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.sl-pt-950 {
  padding-top: 64px !important;
}

.sl-pb-950 {
  padding-bottom: 64px !important;
}

.sl-pl-950 {
  padding-left: 64px !important;
}

.sl-pr-950 {
  padding-right: 64px !important;
}

.sl-p-1200 {
  padding: 96px !important;
}

.sl-py-1200 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.sl-px-1200 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.sl-pt-1200 {
  padding-top: 96px !important;
}

.sl-pb-1200 {
  padding-bottom: 96px !important;
}

.sl-pl-1200 {
  padding-left: 96px !important;
}

.sl-pr-1200 {
  padding-right: 96px !important;
}

.sl-p-n50 {
  padding: -2px !important;
}

.sl-py-n50 {
  padding-top: -2px !important;
  padding-bottom: -2px !important;
}

.sl-px-n50 {
  padding-left: -2px !important;
  padding-right: -2px !important;
}

.sl-pt-n50 {
  padding-top: -2px !important;
}

.sl-pb-n50 {
  padding-bottom: -2px !important;
}

.sl-pl-n50 {
  padding-left: -2px !important;
}

.sl-pr-n50 {
  padding-right: -2px !important;
}

.sl-p-n100 {
  padding: -4px !important;
}

.sl-py-n100 {
  padding-top: -4px !important;
  padding-bottom: -4px !important;
}

.sl-px-n100 {
  padding-left: -4px !important;
  padding-right: -4px !important;
}

.sl-pt-n100 {
  padding-top: -4px !important;
}

.sl-pb-n100 {
  padding-bottom: -4px !important;
}

.sl-pl-n100 {
  padding-left: -4px !important;
}

.sl-pr-n100 {
  padding-right: -4px !important;
}

.sl-p-n150 {
  padding: -6px !important;
}

.sl-py-n150 {
  padding-top: -6px !important;
  padding-bottom: -6px !important;
}

.sl-px-n150 {
  padding-left: -6px !important;
  padding-right: -6px !important;
}

.sl-pt-n150 {
  padding-top: -6px !important;
}

.sl-pb-n150 {
  padding-bottom: -6px !important;
}

.sl-pl-n150 {
  padding-left: -6px !important;
}

.sl-pr-n150 {
  padding-right: -6px !important;
}

.sl-p-n200 {
  padding: -8px !important;
}

.sl-py-n200 {
  padding-top: -8px !important;
  padding-bottom: -8px !important;
}

.sl-px-n200 {
  padding-left: -8px !important;
  padding-right: -8px !important;
}

.sl-pt-n200 {
  padding-top: -8px !important;
}

.sl-pb-n200 {
  padding-bottom: -8px !important;
}

.sl-pl-n200 {
  padding-left: -8px !important;
}

.sl-pr-n200 {
  padding-right: -8px !important;
}

.sl-p-n250 {
  padding: -10px !important;
}

.sl-py-n250 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.sl-px-n250 {
  padding-left: -10px !important;
  padding-right: -10px !important;
}

.sl-pt-n250 {
  padding-top: -10px !important;
}

.sl-pb-n250 {
  padding-bottom: -10px !important;
}

.sl-pl-n250 {
  padding-left: -10px !important;
}

.sl-pr-n250 {
  padding-right: -10px !important;
}

.sl-p-n300 {
  padding: -12px !important;
}

.sl-py-n300 {
  padding-top: -12px !important;
  padding-bottom: -12px !important;
}

.sl-px-n300 {
  padding-left: -12px !important;
  padding-right: -12px !important;
}

.sl-pt-n300 {
  padding-top: -12px !important;
}

.sl-pb-n300 {
  padding-bottom: -12px !important;
}

.sl-pl-n300 {
  padding-left: -12px !important;
}

.sl-pr-n300 {
  padding-right: -12px !important;
}

.sl-p-n350 {
  padding: -14px !important;
}

.sl-py-n350 {
  padding-top: -14px !important;
  padding-bottom: -14px !important;
}

.sl-px-n350 {
  padding-left: -14px !important;
  padding-right: -14px !important;
}

.sl-pt-n350 {
  padding-top: -14px !important;
}

.sl-pb-n350 {
  padding-bottom: -14px !important;
}

.sl-pl-n350 {
  padding-left: -14px !important;
}

.sl-pr-n350 {
  padding-right: -14px !important;
}

.sl-p-n400 {
  padding: -16px !important;
}

.sl-py-n400 {
  padding-top: -16px !important;
  padding-bottom: -16px !important;
}

.sl-px-n400 {
  padding-left: -16px !important;
  padding-right: -16px !important;
}

.sl-pt-n400 {
  padding-top: -16px !important;
}

.sl-pb-n400 {
  padding-bottom: -16px !important;
}

.sl-pl-n400 {
  padding-left: -16px !important;
}

.sl-pr-n400 {
  padding-right: -16px !important;
}

.sl-p-n450 {
  padding: -18px !important;
}

.sl-py-n450 {
  padding-top: -18px !important;
  padding-bottom: -18px !important;
}

.sl-px-n450 {
  padding-left: -18px !important;
  padding-right: -18px !important;
}

.sl-pt-n450 {
  padding-top: -18px !important;
}

.sl-pb-n450 {
  padding-bottom: -18px !important;
}

.sl-pl-n450 {
  padding-left: -18px !important;
}

.sl-pr-n450 {
  padding-right: -18px !important;
}

.sl-p-n500 {
  padding: -20px !important;
}

.sl-py-n500 {
  padding-top: -20px !important;
  padding-bottom: -20px !important;
}

.sl-px-n500 {
  padding-left: -20px !important;
  padding-right: -20px !important;
}

.sl-pt-n500 {
  padding-top: -20px !important;
}

.sl-pb-n500 {
  padding-bottom: -20px !important;
}

.sl-pl-n500 {
  padding-left: -20px !important;
}

.sl-pr-n500 {
  padding-right: -20px !important;
}

.sl-p-n550 {
  padding: -22px !important;
}

.sl-py-n550 {
  padding-top: -22px !important;
  padding-bottom: -22px !important;
}

.sl-px-n550 {
  padding-left: -22px !important;
  padding-right: -22px !important;
}

.sl-pt-n550 {
  padding-top: -22px !important;
}

.sl-pb-n550 {
  padding-bottom: -22px !important;
}

.sl-pl-n550 {
  padding-left: -22px !important;
}

.sl-pr-n550 {
  padding-right: -22px !important;
}

.sl-p-n600 {
  padding: -24px !important;
}

.sl-py-n600 {
  padding-top: -24px !important;
  padding-bottom: -24px !important;
}

.sl-px-n600 {
  padding-left: -24px !important;
  padding-right: -24px !important;
}

.sl-pt-n600 {
  padding-top: -24px !important;
}

.sl-pb-n600 {
  padding-bottom: -24px !important;
}

.sl-pl-n600 {
  padding-left: -24px !important;
}

.sl-pr-n600 {
  padding-right: -24px !important;
}

.sl-p-n650 {
  padding: -28px !important;
}

.sl-py-n650 {
  padding-top: -28px !important;
  padding-bottom: -28px !important;
}

.sl-px-n650 {
  padding-left: -28px !important;
  padding-right: -28px !important;
}

.sl-pt-n650 {
  padding-top: -28px !important;
}

.sl-pb-n650 {
  padding-bottom: -28px !important;
}

.sl-pl-n650 {
  padding-left: -28px !important;
}

.sl-pr-n650 {
  padding-right: -28px !important;
}

.sl-p-n700 {
  padding: -32px !important;
}

.sl-py-n700 {
  padding-top: -32px !important;
  padding-bottom: -32px !important;
}

.sl-px-n700 {
  padding-left: -32px !important;
  padding-right: -32px !important;
}

.sl-pt-n700 {
  padding-top: -32px !important;
}

.sl-pb-n700 {
  padding-bottom: -32px !important;
}

.sl-pl-n700 {
  padding-left: -32px !important;
}

.sl-pr-n700 {
  padding-right: -32px !important;
}

.sl-p-n750 {
  padding: -36px !important;
}

.sl-py-n750 {
  padding-top: -36px !important;
  padding-bottom: -36px !important;
}

.sl-px-n750 {
  padding-left: -36px !important;
  padding-right: -36px !important;
}

.sl-pt-n750 {
  padding-top: -36px !important;
}

.sl-pb-n750 {
  padding-bottom: -36px !important;
}

.sl-pl-n750 {
  padding-left: -36px !important;
}

.sl-pr-n750 {
  padding-right: -36px !important;
}

.sl-p-n800 {
  padding: -40px !important;
}

.sl-py-n800 {
  padding-top: -40px !important;
  padding-bottom: -40px !important;
}

.sl-px-n800 {
  padding-left: -40px !important;
  padding-right: -40px !important;
}

.sl-pt-n800 {
  padding-top: -40px !important;
}

.sl-pb-n800 {
  padding-bottom: -40px !important;
}

.sl-pl-n800 {
  padding-left: -40px !important;
}

.sl-pr-n800 {
  padding-right: -40px !important;
}

.sl-p-n850 {
  padding: -48px !important;
}

.sl-py-n850 {
  padding-top: -48px !important;
  padding-bottom: -48px !important;
}

.sl-px-n850 {
  padding-left: -48px !important;
  padding-right: -48px !important;
}

.sl-pt-n850 {
  padding-top: -48px !important;
}

.sl-pb-n850 {
  padding-bottom: -48px !important;
}

.sl-pl-n850 {
  padding-left: -48px !important;
}

.sl-pr-n850 {
  padding-right: -48px !important;
}

.sl-p-n900 {
  padding: -56px !important;
}

.sl-py-n900 {
  padding-top: -56px !important;
  padding-bottom: -56px !important;
}

.sl-px-n900 {
  padding-left: -56px !important;
  padding-right: -56px !important;
}

.sl-pt-n900 {
  padding-top: -56px !important;
}

.sl-pb-n900 {
  padding-bottom: -56px !important;
}

.sl-pl-n900 {
  padding-left: -56px !important;
}

.sl-pr-n900 {
  padding-right: -56px !important;
}

.sl-p-n925 {
  padding: -60px !important;
}

.sl-py-n925 {
  padding-top: -60px !important;
  padding-bottom: -60px !important;
}

.sl-px-n925 {
  padding-left: -60px !important;
  padding-right: -60px !important;
}

.sl-pt-n925 {
  padding-top: -60px !important;
}

.sl-pb-n925 {
  padding-bottom: -60px !important;
}

.sl-pl-n925 {
  padding-left: -60px !important;
}

.sl-pr-n925 {
  padding-right: -60px !important;
}

.sl-p-n950 {
  padding: -64px !important;
}

.sl-py-n950 {
  padding-top: -64px !important;
  padding-bottom: -64px !important;
}

.sl-px-n950 {
  padding-left: -64px !important;
  padding-right: -64px !important;
}

.sl-pt-n950 {
  padding-top: -64px !important;
}

.sl-pb-n950 {
  padding-bottom: -64px !important;
}

.sl-pl-n950 {
  padding-left: -64px !important;
}

.sl-pr-n950 {
  padding-right: -64px !important;
}

.sl-p-n1200 {
  padding: -96px !important;
}

.sl-py-n1200 {
  padding-top: -96px !important;
  padding-bottom: -96px !important;
}

.sl-px-n1200 {
  padding-left: -96px !important;
  padding-right: -96px !important;
}

.sl-pt-n1200 {
  padding-top: -96px !important;
}

.sl-pb-n1200 {
  padding-bottom: -96px !important;
}

.sl-pl-n1200 {
  padding-left: -96px !important;
}

.sl-pr-n1200 {
  padding-right: -96px !important;
}

.sl-p-auto {
  padding: auto !important;
}

.sl-py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.sl-px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.sl-pt-auto {
  padding-top: auto !important;
}

.sl-pb-auto {
  padding-bottom: auto !important;
}

.sl-pl-auto {
  padding-left: auto !important;
}

.sl-pr-auto {
  padding-right: auto !important;
}

@media (min-width: 940px) {
  .sl-m-sm-0 {
    margin: 0px !important;
  }
  .sl-my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sl-mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sl-mt-sm-0 {
    margin-top: 0px !important;
  }
  .sl-mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .sl-ml-sm-0 {
    margin-left: 0px !important;
  }
  .sl-mr-sm-0 {
    margin-right: 0px !important;
  }
  .sl-m-sm-50 {
    margin: 2px !important;
  }
  .sl-my-sm-50 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .sl-mx-sm-50 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .sl-mt-sm-50 {
    margin-top: 2px !important;
  }
  .sl-mb-sm-50 {
    margin-bottom: 2px !important;
  }
  .sl-ml-sm-50 {
    margin-left: 2px !important;
  }
  .sl-mr-sm-50 {
    margin-right: 2px !important;
  }
  .sl-m-sm-100 {
    margin: 4px !important;
  }
  .sl-my-sm-100 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .sl-mx-sm-100 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .sl-mt-sm-100 {
    margin-top: 4px !important;
  }
  .sl-mb-sm-100 {
    margin-bottom: 4px !important;
  }
  .sl-ml-sm-100 {
    margin-left: 4px !important;
  }
  .sl-mr-sm-100 {
    margin-right: 4px !important;
  }
  .sl-m-sm-150 {
    margin: 6px !important;
  }
  .sl-my-sm-150 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .sl-mx-sm-150 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .sl-mt-sm-150 {
    margin-top: 6px !important;
  }
  .sl-mb-sm-150 {
    margin-bottom: 6px !important;
  }
  .sl-ml-sm-150 {
    margin-left: 6px !important;
  }
  .sl-mr-sm-150 {
    margin-right: 6px !important;
  }
  .sl-m-sm-200 {
    margin: 8px !important;
  }
  .sl-my-sm-200 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sl-mx-sm-200 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sl-mt-sm-200 {
    margin-top: 8px !important;
  }
  .sl-mb-sm-200 {
    margin-bottom: 8px !important;
  }
  .sl-ml-sm-200 {
    margin-left: 8px !important;
  }
  .sl-mr-sm-200 {
    margin-right: 8px !important;
  }
  .sl-m-sm-250 {
    margin: 10px !important;
  }
  .sl-my-sm-250 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sl-mx-sm-250 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sl-mt-sm-250 {
    margin-top: 10px !important;
  }
  .sl-mb-sm-250 {
    margin-bottom: 10px !important;
  }
  .sl-ml-sm-250 {
    margin-left: 10px !important;
  }
  .sl-mr-sm-250 {
    margin-right: 10px !important;
  }
  .sl-m-sm-300 {
    margin: 12px !important;
  }
  .sl-my-sm-300 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .sl-mx-sm-300 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .sl-mt-sm-300 {
    margin-top: 12px !important;
  }
  .sl-mb-sm-300 {
    margin-bottom: 12px !important;
  }
  .sl-ml-sm-300 {
    margin-left: 12px !important;
  }
  .sl-mr-sm-300 {
    margin-right: 12px !important;
  }
  .sl-m-sm-350 {
    margin: 14px !important;
  }
  .sl-my-sm-350 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .sl-mx-sm-350 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .sl-mt-sm-350 {
    margin-top: 14px !important;
  }
  .sl-mb-sm-350 {
    margin-bottom: 14px !important;
  }
  .sl-ml-sm-350 {
    margin-left: 14px !important;
  }
  .sl-mr-sm-350 {
    margin-right: 14px !important;
  }
  .sl-m-sm-400 {
    margin: 16px !important;
  }
  .sl-my-sm-400 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sl-mx-sm-400 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sl-mt-sm-400 {
    margin-top: 16px !important;
  }
  .sl-mb-sm-400 {
    margin-bottom: 16px !important;
  }
  .sl-ml-sm-400 {
    margin-left: 16px !important;
  }
  .sl-mr-sm-400 {
    margin-right: 16px !important;
  }
  .sl-m-sm-450 {
    margin: 18px !important;
  }
  .sl-my-sm-450 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .sl-mx-sm-450 {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .sl-mt-sm-450 {
    margin-top: 18px !important;
  }
  .sl-mb-sm-450 {
    margin-bottom: 18px !important;
  }
  .sl-ml-sm-450 {
    margin-left: 18px !important;
  }
  .sl-mr-sm-450 {
    margin-right: 18px !important;
  }
  .sl-m-sm-500 {
    margin: 20px !important;
  }
  .sl-my-sm-500 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sl-mx-sm-500 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sl-mt-sm-500 {
    margin-top: 20px !important;
  }
  .sl-mb-sm-500 {
    margin-bottom: 20px !important;
  }
  .sl-ml-sm-500 {
    margin-left: 20px !important;
  }
  .sl-mr-sm-500 {
    margin-right: 20px !important;
  }
  .sl-m-sm-550 {
    margin: 22px !important;
  }
  .sl-my-sm-550 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .sl-mx-sm-550 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .sl-mt-sm-550 {
    margin-top: 22px !important;
  }
  .sl-mb-sm-550 {
    margin-bottom: 22px !important;
  }
  .sl-ml-sm-550 {
    margin-left: 22px !important;
  }
  .sl-mr-sm-550 {
    margin-right: 22px !important;
  }
  .sl-m-sm-600 {
    margin: 24px !important;
  }
  .sl-my-sm-600 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sl-mx-sm-600 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sl-mt-sm-600 {
    margin-top: 24px !important;
  }
  .sl-mb-sm-600 {
    margin-bottom: 24px !important;
  }
  .sl-ml-sm-600 {
    margin-left: 24px !important;
  }
  .sl-mr-sm-600 {
    margin-right: 24px !important;
  }
  .sl-m-sm-650 {
    margin: 28px !important;
  }
  .sl-my-sm-650 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .sl-mx-sm-650 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .sl-mt-sm-650 {
    margin-top: 28px !important;
  }
  .sl-mb-sm-650 {
    margin-bottom: 28px !important;
  }
  .sl-ml-sm-650 {
    margin-left: 28px !important;
  }
  .sl-mr-sm-650 {
    margin-right: 28px !important;
  }
  .sl-m-sm-700 {
    margin: 32px !important;
  }
  .sl-my-sm-700 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sl-mx-sm-700 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sl-mt-sm-700 {
    margin-top: 32px !important;
  }
  .sl-mb-sm-700 {
    margin-bottom: 32px !important;
  }
  .sl-ml-sm-700 {
    margin-left: 32px !important;
  }
  .sl-mr-sm-700 {
    margin-right: 32px !important;
  }
  .sl-m-sm-750 {
    margin: 36px !important;
  }
  .sl-my-sm-750 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .sl-mx-sm-750 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .sl-mt-sm-750 {
    margin-top: 36px !important;
  }
  .sl-mb-sm-750 {
    margin-bottom: 36px !important;
  }
  .sl-ml-sm-750 {
    margin-left: 36px !important;
  }
  .sl-mr-sm-750 {
    margin-right: 36px !important;
  }
  .sl-m-sm-800 {
    margin: 40px !important;
  }
  .sl-my-sm-800 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sl-mx-sm-800 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sl-mt-sm-800 {
    margin-top: 40px !important;
  }
  .sl-mb-sm-800 {
    margin-bottom: 40px !important;
  }
  .sl-ml-sm-800 {
    margin-left: 40px !important;
  }
  .sl-mr-sm-800 {
    margin-right: 40px !important;
  }
  .sl-m-sm-825 {
    margin: 44px !important;
  }
  .sl-my-sm-825 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .sl-mx-sm-825 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }
  .sl-mt-sm-825 {
    margin-top: 44px !important;
  }
  .sl-mb-sm-825 {
    margin-bottom: 44px !important;
  }
  .sl-ml-sm-825 {
    margin-left: 44px !important;
  }
  .sl-mr-sm-825 {
    margin-right: 44px !important;
  }
  .sl-m-sm-850 {
    margin: 48px !important;
  }
  .sl-my-sm-850 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sl-mx-sm-850 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sl-mt-sm-850 {
    margin-top: 48px !important;
  }
  .sl-mb-sm-850 {
    margin-bottom: 48px !important;
  }
  .sl-ml-sm-850 {
    margin-left: 48px !important;
  }
  .sl-mr-sm-850 {
    margin-right: 48px !important;
  }
  .sl-m-sm-900 {
    margin: 56px !important;
  }
  .sl-my-sm-900 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sl-mx-sm-900 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sl-mt-sm-900 {
    margin-top: 56px !important;
  }
  .sl-mb-sm-900 {
    margin-bottom: 56px !important;
  }
  .sl-ml-sm-900 {
    margin-left: 56px !important;
  }
  .sl-mr-sm-900 {
    margin-right: 56px !important;
  }
  .sl-m-sm-925 {
    margin: 60px !important;
  }
  .sl-my-sm-925 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sl-mx-sm-925 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sl-mt-sm-925 {
    margin-top: 60px !important;
  }
  .sl-mb-sm-925 {
    margin-bottom: 60px !important;
  }
  .sl-ml-sm-925 {
    margin-left: 60px !important;
  }
  .sl-mr-sm-925 {
    margin-right: 60px !important;
  }
  .sl-m-sm-950 {
    margin: 64px !important;
  }
  .sl-my-sm-950 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .sl-mx-sm-950 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sl-mt-sm-950 {
    margin-top: 64px !important;
  }
  .sl-mb-sm-950 {
    margin-bottom: 64px !important;
  }
  .sl-ml-sm-950 {
    margin-left: 64px !important;
  }
  .sl-mr-sm-950 {
    margin-right: 64px !important;
  }
  .sl-m-sm-1200 {
    margin: 96px !important;
  }
  .sl-my-sm-1200 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .sl-mx-sm-1200 {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .sl-mt-sm-1200 {
    margin-top: 96px !important;
  }
  .sl-mb-sm-1200 {
    margin-bottom: 96px !important;
  }
  .sl-ml-sm-1200 {
    margin-left: 96px !important;
  }
  .sl-mr-sm-1200 {
    margin-right: 96px !important;
  }
  .sl-m-sm-n50 {
    margin: -2px !important;
  }
  .sl-my-sm-n50 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .sl-mx-sm-n50 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .sl-mt-sm-n50 {
    margin-top: -2px !important;
  }
  .sl-mb-sm-n50 {
    margin-bottom: -2px !important;
  }
  .sl-ml-sm-n50 {
    margin-left: -2px !important;
  }
  .sl-mr-sm-n50 {
    margin-right: -2px !important;
  }
  .sl-m-sm-n100 {
    margin: -4px !important;
  }
  .sl-my-sm-n100 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .sl-mx-sm-n100 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .sl-mt-sm-n100 {
    margin-top: -4px !important;
  }
  .sl-mb-sm-n100 {
    margin-bottom: -4px !important;
  }
  .sl-ml-sm-n100 {
    margin-left: -4px !important;
  }
  .sl-mr-sm-n100 {
    margin-right: -4px !important;
  }
  .sl-m-sm-n150 {
    margin: -6px !important;
  }
  .sl-my-sm-n150 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .sl-mx-sm-n150 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .sl-mt-sm-n150 {
    margin-top: -6px !important;
  }
  .sl-mb-sm-n150 {
    margin-bottom: -6px !important;
  }
  .sl-ml-sm-n150 {
    margin-left: -6px !important;
  }
  .sl-mr-sm-n150 {
    margin-right: -6px !important;
  }
  .sl-m-sm-n200 {
    margin: -8px !important;
  }
  .sl-my-sm-n200 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .sl-mx-sm-n200 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .sl-mt-sm-n200 {
    margin-top: -8px !important;
  }
  .sl-mb-sm-n200 {
    margin-bottom: -8px !important;
  }
  .sl-ml-sm-n200 {
    margin-left: -8px !important;
  }
  .sl-mr-sm-n200 {
    margin-right: -8px !important;
  }
  .sl-m-sm-n250 {
    margin: -10px !important;
  }
  .sl-my-sm-n250 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sl-mx-sm-n250 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sl-mt-sm-n250 {
    margin-top: -10px !important;
  }
  .sl-mb-sm-n250 {
    margin-bottom: -10px !important;
  }
  .sl-ml-sm-n250 {
    margin-left: -10px !important;
  }
  .sl-mr-sm-n250 {
    margin-right: -10px !important;
  }
  .sl-m-sm-n300 {
    margin: -12px !important;
  }
  .sl-my-sm-n300 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .sl-mx-sm-n300 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .sl-mt-sm-n300 {
    margin-top: -12px !important;
  }
  .sl-mb-sm-n300 {
    margin-bottom: -12px !important;
  }
  .sl-ml-sm-n300 {
    margin-left: -12px !important;
  }
  .sl-mr-sm-n300 {
    margin-right: -12px !important;
  }
  .sl-m-sm-n350 {
    margin: -14px !important;
  }
  .sl-my-sm-n350 {
    margin-top: -14px !important;
    margin-bottom: -14px !important;
  }
  .sl-mx-sm-n350 {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
  .sl-mt-sm-n350 {
    margin-top: -14px !important;
  }
  .sl-mb-sm-n350 {
    margin-bottom: -14px !important;
  }
  .sl-ml-sm-n350 {
    margin-left: -14px !important;
  }
  .sl-mr-sm-n350 {
    margin-right: -14px !important;
  }
  .sl-m-sm-n400 {
    margin: -16px !important;
  }
  .sl-my-sm-n400 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .sl-mx-sm-n400 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .sl-mt-sm-n400 {
    margin-top: -16px !important;
  }
  .sl-mb-sm-n400 {
    margin-bottom: -16px !important;
  }
  .sl-ml-sm-n400 {
    margin-left: -16px !important;
  }
  .sl-mr-sm-n400 {
    margin-right: -16px !important;
  }
  .sl-m-sm-n450 {
    margin: -18px !important;
  }
  .sl-my-sm-n450 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .sl-mx-sm-n450 {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .sl-mt-sm-n450 {
    margin-top: -18px !important;
  }
  .sl-mb-sm-n450 {
    margin-bottom: -18px !important;
  }
  .sl-ml-sm-n450 {
    margin-left: -18px !important;
  }
  .sl-mr-sm-n450 {
    margin-right: -18px !important;
  }
  .sl-m-sm-n500 {
    margin: -20px !important;
  }
  .sl-my-sm-n500 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sl-mx-sm-n500 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sl-mt-sm-n500 {
    margin-top: -20px !important;
  }
  .sl-mb-sm-n500 {
    margin-bottom: -20px !important;
  }
  .sl-ml-sm-n500 {
    margin-left: -20px !important;
  }
  .sl-mr-sm-n500 {
    margin-right: -20px !important;
  }
  .sl-m-sm-n550 {
    margin: -22px !important;
  }
  .sl-my-sm-n550 {
    margin-top: -22px !important;
    margin-bottom: -22px !important;
  }
  .sl-mx-sm-n550 {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
  .sl-mt-sm-n550 {
    margin-top: -22px !important;
  }
  .sl-mb-sm-n550 {
    margin-bottom: -22px !important;
  }
  .sl-ml-sm-n550 {
    margin-left: -22px !important;
  }
  .sl-mr-sm-n550 {
    margin-right: -22px !important;
  }
  .sl-m-sm-n600 {
    margin: -24px !important;
  }
  .sl-my-sm-n600 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .sl-mx-sm-n600 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .sl-mt-sm-n600 {
    margin-top: -24px !important;
  }
  .sl-mb-sm-n600 {
    margin-bottom: -24px !important;
  }
  .sl-ml-sm-n600 {
    margin-left: -24px !important;
  }
  .sl-mr-sm-n600 {
    margin-right: -24px !important;
  }
  .sl-m-sm-n650 {
    margin: -28px !important;
  }
  .sl-my-sm-n650 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .sl-mx-sm-n650 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }
  .sl-mt-sm-n650 {
    margin-top: -28px !important;
  }
  .sl-mb-sm-n650 {
    margin-bottom: -28px !important;
  }
  .sl-ml-sm-n650 {
    margin-left: -28px !important;
  }
  .sl-mr-sm-n650 {
    margin-right: -28px !important;
  }
  .sl-m-sm-n700 {
    margin: -32px !important;
  }
  .sl-my-sm-n700 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .sl-mx-sm-n700 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }
  .sl-mt-sm-n700 {
    margin-top: -32px !important;
  }
  .sl-mb-sm-n700 {
    margin-bottom: -32px !important;
  }
  .sl-ml-sm-n700 {
    margin-left: -32px !important;
  }
  .sl-mr-sm-n700 {
    margin-right: -32px !important;
  }
  .sl-m-sm-n750 {
    margin: -36px !important;
  }
  .sl-my-sm-n750 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .sl-mx-sm-n750 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }
  .sl-mt-sm-n750 {
    margin-top: -36px !important;
  }
  .sl-mb-sm-n750 {
    margin-bottom: -36px !important;
  }
  .sl-ml-sm-n750 {
    margin-left: -36px !important;
  }
  .sl-mr-sm-n750 {
    margin-right: -36px !important;
  }
  .sl-m-sm-n800 {
    margin: -40px !important;
  }
  .sl-my-sm-n800 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sl-mx-sm-n800 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sl-mt-sm-n800 {
    margin-top: -40px !important;
  }
  .sl-mb-sm-n800 {
    margin-bottom: -40px !important;
  }
  .sl-ml-sm-n800 {
    margin-left: -40px !important;
  }
  .sl-mr-sm-n800 {
    margin-right: -40px !important;
  }
  .sl-m-sm-n850 {
    margin: -48px !important;
  }
  .sl-my-sm-n850 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .sl-mx-sm-n850 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }
  .sl-mt-sm-n850 {
    margin-top: -48px !important;
  }
  .sl-mb-sm-n850 {
    margin-bottom: -48px !important;
  }
  .sl-ml-sm-n850 {
    margin-left: -48px !important;
  }
  .sl-mr-sm-n850 {
    margin-right: -48px !important;
  }
  .sl-m-sm-n900 {
    margin: -56px !important;
  }
  .sl-my-sm-n900 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .sl-mx-sm-n900 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }
  .sl-mt-sm-n900 {
    margin-top: -56px !important;
  }
  .sl-mb-sm-n900 {
    margin-bottom: -56px !important;
  }
  .sl-ml-sm-n900 {
    margin-left: -56px !important;
  }
  .sl-mr-sm-n900 {
    margin-right: -56px !important;
  }
  .sl-m-sm-n925 {
    margin: -60px !important;
  }
  .sl-my-sm-n925 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .sl-mx-sm-n925 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sl-mt-sm-n925 {
    margin-top: -60px !important;
  }
  .sl-mb-sm-n925 {
    margin-bottom: -60px !important;
  }
  .sl-ml-sm-n925 {
    margin-left: -60px !important;
  }
  .sl-mr-sm-n925 {
    margin-right: -60px !important;
  }
  .sl-m-sm-n950 {
    margin: -64px !important;
  }
  .sl-my-sm-n950 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .sl-mx-sm-n950 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }
  .sl-mt-sm-n950 {
    margin-top: -64px !important;
  }
  .sl-mb-sm-n950 {
    margin-bottom: -64px !important;
  }
  .sl-ml-sm-n950 {
    margin-left: -64px !important;
  }
  .sl-mr-sm-n950 {
    margin-right: -64px !important;
  }
  .sl-m-sm-n1200 {
    margin: -96px !important;
  }
  .sl-my-sm-n1200 {
    margin-top: -96px !important;
    margin-bottom: -96px !important;
  }
  .sl-mx-sm-n1200 {
    margin-left: -96px !important;
    margin-right: -96px !important;
  }
  .sl-mt-sm-n1200 {
    margin-top: -96px !important;
  }
  .sl-mb-sm-n1200 {
    margin-bottom: -96px !important;
  }
  .sl-ml-sm-n1200 {
    margin-left: -96px !important;
  }
  .sl-mr-sm-n1200 {
    margin-right: -96px !important;
  }
  .sl-m-sm-auto {
    margin: auto !important;
  }
  .sl-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .sl-mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sl-mt-sm-auto {
    margin-top: auto !important;
  }
  .sl-mb-sm-auto {
    margin-bottom: auto !important;
  }
  .sl-ml-sm-auto {
    margin-left: auto !important;
  }
  .sl-mr-sm-auto {
    margin-right: auto !important;
  }
  .sl-p-sm-0 {
    padding: 0px !important;
  }
  .sl-py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sl-px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sl-pt-sm-0 {
    padding-top: 0px !important;
  }
  .sl-pb-sm-0 {
    padding-bottom: 0px !important;
  }
  .sl-pl-sm-0 {
    padding-left: 0px !important;
  }
  .sl-pr-sm-0 {
    padding-right: 0px !important;
  }
  .sl-p-sm-50 {
    padding: 2px !important;
  }
  .sl-py-sm-50 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .sl-px-sm-50 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .sl-pt-sm-50 {
    padding-top: 2px !important;
  }
  .sl-pb-sm-50 {
    padding-bottom: 2px !important;
  }
  .sl-pl-sm-50 {
    padding-left: 2px !important;
  }
  .sl-pr-sm-50 {
    padding-right: 2px !important;
  }
  .sl-p-sm-100 {
    padding: 4px !important;
  }
  .sl-py-sm-100 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sl-px-sm-100 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sl-pt-sm-100 {
    padding-top: 4px !important;
  }
  .sl-pb-sm-100 {
    padding-bottom: 4px !important;
  }
  .sl-pl-sm-100 {
    padding-left: 4px !important;
  }
  .sl-pr-sm-100 {
    padding-right: 4px !important;
  }
  .sl-p-sm-150 {
    padding: 6px !important;
  }
  .sl-py-sm-150 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .sl-px-sm-150 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .sl-pt-sm-150 {
    padding-top: 6px !important;
  }
  .sl-pb-sm-150 {
    padding-bottom: 6px !important;
  }
  .sl-pl-sm-150 {
    padding-left: 6px !important;
  }
  .sl-pr-sm-150 {
    padding-right: 6px !important;
  }
  .sl-p-sm-200 {
    padding: 8px !important;
  }
  .sl-py-sm-200 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sl-px-sm-200 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sl-pt-sm-200 {
    padding-top: 8px !important;
  }
  .sl-pb-sm-200 {
    padding-bottom: 8px !important;
  }
  .sl-pl-sm-200 {
    padding-left: 8px !important;
  }
  .sl-pr-sm-200 {
    padding-right: 8px !important;
  }
  .sl-p-sm-250 {
    padding: 10px !important;
  }
  .sl-py-sm-250 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sl-px-sm-250 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sl-pt-sm-250 {
    padding-top: 10px !important;
  }
  .sl-pb-sm-250 {
    padding-bottom: 10px !important;
  }
  .sl-pl-sm-250 {
    padding-left: 10px !important;
  }
  .sl-pr-sm-250 {
    padding-right: 10px !important;
  }
  .sl-p-sm-300 {
    padding: 12px !important;
  }
  .sl-py-sm-300 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sl-px-sm-300 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sl-pt-sm-300 {
    padding-top: 12px !important;
  }
  .sl-pb-sm-300 {
    padding-bottom: 12px !important;
  }
  .sl-pl-sm-300 {
    padding-left: 12px !important;
  }
  .sl-pr-sm-300 {
    padding-right: 12px !important;
  }
  .sl-p-sm-350 {
    padding: 14px !important;
  }
  .sl-py-sm-350 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .sl-px-sm-350 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sl-pt-sm-350 {
    padding-top: 14px !important;
  }
  .sl-pb-sm-350 {
    padding-bottom: 14px !important;
  }
  .sl-pl-sm-350 {
    padding-left: 14px !important;
  }
  .sl-pr-sm-350 {
    padding-right: 14px !important;
  }
  .sl-p-sm-400 {
    padding: 16px !important;
  }
  .sl-py-sm-400 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sl-px-sm-400 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sl-pt-sm-400 {
    padding-top: 16px !important;
  }
  .sl-pb-sm-400 {
    padding-bottom: 16px !important;
  }
  .sl-pl-sm-400 {
    padding-left: 16px !important;
  }
  .sl-pr-sm-400 {
    padding-right: 16px !important;
  }
  .sl-p-sm-450 {
    padding: 18px !important;
  }
  .sl-py-sm-450 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .sl-px-sm-450 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .sl-pt-sm-450 {
    padding-top: 18px !important;
  }
  .sl-pb-sm-450 {
    padding-bottom: 18px !important;
  }
  .sl-pl-sm-450 {
    padding-left: 18px !important;
  }
  .sl-pr-sm-450 {
    padding-right: 18px !important;
  }
  .sl-p-sm-500 {
    padding: 20px !important;
  }
  .sl-py-sm-500 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sl-px-sm-500 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sl-pt-sm-500 {
    padding-top: 20px !important;
  }
  .sl-pb-sm-500 {
    padding-bottom: 20px !important;
  }
  .sl-pl-sm-500 {
    padding-left: 20px !important;
  }
  .sl-pr-sm-500 {
    padding-right: 20px !important;
  }
  .sl-p-sm-550 {
    padding: 22px !important;
  }
  .sl-py-sm-550 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .sl-px-sm-550 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .sl-pt-sm-550 {
    padding-top: 22px !important;
  }
  .sl-pb-sm-550 {
    padding-bottom: 22px !important;
  }
  .sl-pl-sm-550 {
    padding-left: 22px !important;
  }
  .sl-pr-sm-550 {
    padding-right: 22px !important;
  }
  .sl-p-sm-600 {
    padding: 24px !important;
  }
  .sl-py-sm-600 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sl-px-sm-600 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sl-pt-sm-600 {
    padding-top: 24px !important;
  }
  .sl-pb-sm-600 {
    padding-bottom: 24px !important;
  }
  .sl-pl-sm-600 {
    padding-left: 24px !important;
  }
  .sl-pr-sm-600 {
    padding-right: 24px !important;
  }
  .sl-p-sm-650 {
    padding: 28px !important;
  }
  .sl-py-sm-650 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sl-px-sm-650 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sl-pt-sm-650 {
    padding-top: 28px !important;
  }
  .sl-pb-sm-650 {
    padding-bottom: 28px !important;
  }
  .sl-pl-sm-650 {
    padding-left: 28px !important;
  }
  .sl-pr-sm-650 {
    padding-right: 28px !important;
  }
  .sl-p-sm-700 {
    padding: 32px !important;
  }
  .sl-py-sm-700 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sl-px-sm-700 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sl-pt-sm-700 {
    padding-top: 32px !important;
  }
  .sl-pb-sm-700 {
    padding-bottom: 32px !important;
  }
  .sl-pl-sm-700 {
    padding-left: 32px !important;
  }
  .sl-pr-sm-700 {
    padding-right: 32px !important;
  }
  .sl-p-sm-750 {
    padding: 36px !important;
  }
  .sl-py-sm-750 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .sl-px-sm-750 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .sl-pt-sm-750 {
    padding-top: 36px !important;
  }
  .sl-pb-sm-750 {
    padding-bottom: 36px !important;
  }
  .sl-pl-sm-750 {
    padding-left: 36px !important;
  }
  .sl-pr-sm-750 {
    padding-right: 36px !important;
  }
  .sl-p-sm-800 {
    padding: 40px !important;
  }
  .sl-py-sm-800 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sl-px-sm-800 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sl-pt-sm-800 {
    padding-top: 40px !important;
  }
  .sl-pb-sm-800 {
    padding-bottom: 40px !important;
  }
  .sl-pl-sm-800 {
    padding-left: 40px !important;
  }
  .sl-pr-sm-800 {
    padding-right: 40px !important;
  }
  .sl-p-sm-825 {
    padding: 44px !important;
  }
  .sl-py-sm-825 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .sl-px-sm-825 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .sl-pt-sm-825 {
    padding-top: 44px !important;
  }
  .sl-pb-sm-825 {
    padding-bottom: 44px !important;
  }
  .sl-pl-sm-825 {
    padding-left: 44px !important;
  }
  .sl-pr-sm-825 {
    padding-right: 44px !important;
  }
  .sl-p-sm-850 {
    padding: 48px !important;
  }
  .sl-py-sm-850 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sl-px-sm-850 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sl-pt-sm-850 {
    padding-top: 48px !important;
  }
  .sl-pb-sm-850 {
    padding-bottom: 48px !important;
  }
  .sl-pl-sm-850 {
    padding-left: 48px !important;
  }
  .sl-pr-sm-850 {
    padding-right: 48px !important;
  }
  .sl-p-sm-900 {
    padding: 56px !important;
  }
  .sl-py-sm-900 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sl-px-sm-900 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sl-pt-sm-900 {
    padding-top: 56px !important;
  }
  .sl-pb-sm-900 {
    padding-bottom: 56px !important;
  }
  .sl-pl-sm-900 {
    padding-left: 56px !important;
  }
  .sl-pr-sm-900 {
    padding-right: 56px !important;
  }
  .sl-p-sm-925 {
    padding: 60px !important;
  }
  .sl-py-sm-925 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sl-px-sm-925 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sl-pt-sm-925 {
    padding-top: 60px !important;
  }
  .sl-pb-sm-925 {
    padding-bottom: 60px !important;
  }
  .sl-pl-sm-925 {
    padding-left: 60px !important;
  }
  .sl-pr-sm-925 {
    padding-right: 60px !important;
  }
  .sl-p-sm-950 {
    padding: 64px !important;
  }
  .sl-py-sm-950 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sl-px-sm-950 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sl-pt-sm-950 {
    padding-top: 64px !important;
  }
  .sl-pb-sm-950 {
    padding-bottom: 64px !important;
  }
  .sl-pl-sm-950 {
    padding-left: 64px !important;
  }
  .sl-pr-sm-950 {
    padding-right: 64px !important;
  }
  .sl-p-sm-1200 {
    padding: 96px !important;
  }
  .sl-py-sm-1200 {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .sl-px-sm-1200 {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .sl-pt-sm-1200 {
    padding-top: 96px !important;
  }
  .sl-pb-sm-1200 {
    padding-bottom: 96px !important;
  }
  .sl-pl-sm-1200 {
    padding-left: 96px !important;
  }
  .sl-pr-sm-1200 {
    padding-right: 96px !important;
  }
  .sl-p-sm-n50 {
    padding: -2px !important;
  }
  .sl-py-sm-n50 {
    padding-top: -2px !important;
    padding-bottom: -2px !important;
  }
  .sl-px-sm-n50 {
    padding-left: -2px !important;
    padding-right: -2px !important;
  }
  .sl-pt-sm-n50 {
    padding-top: -2px !important;
  }
  .sl-pb-sm-n50 {
    padding-bottom: -2px !important;
  }
  .sl-pl-sm-n50 {
    padding-left: -2px !important;
  }
  .sl-pr-sm-n50 {
    padding-right: -2px !important;
  }
  .sl-p-sm-n100 {
    padding: -4px !important;
  }
  .sl-py-sm-n100 {
    padding-top: -4px !important;
    padding-bottom: -4px !important;
  }
  .sl-px-sm-n100 {
    padding-left: -4px !important;
    padding-right: -4px !important;
  }
  .sl-pt-sm-n100 {
    padding-top: -4px !important;
  }
  .sl-pb-sm-n100 {
    padding-bottom: -4px !important;
  }
  .sl-pl-sm-n100 {
    padding-left: -4px !important;
  }
  .sl-pr-sm-n100 {
    padding-right: -4px !important;
  }
  .sl-p-sm-n150 {
    padding: -6px !important;
  }
  .sl-py-sm-n150 {
    padding-top: -6px !important;
    padding-bottom: -6px !important;
  }
  .sl-px-sm-n150 {
    padding-left: -6px !important;
    padding-right: -6px !important;
  }
  .sl-pt-sm-n150 {
    padding-top: -6px !important;
  }
  .sl-pb-sm-n150 {
    padding-bottom: -6px !important;
  }
  .sl-pl-sm-n150 {
    padding-left: -6px !important;
  }
  .sl-pr-sm-n150 {
    padding-right: -6px !important;
  }
  .sl-p-sm-n200 {
    padding: -8px !important;
  }
  .sl-py-sm-n200 {
    padding-top: -8px !important;
    padding-bottom: -8px !important;
  }
  .sl-px-sm-n200 {
    padding-left: -8px !important;
    padding-right: -8px !important;
  }
  .sl-pt-sm-n200 {
    padding-top: -8px !important;
  }
  .sl-pb-sm-n200 {
    padding-bottom: -8px !important;
  }
  .sl-pl-sm-n200 {
    padding-left: -8px !important;
  }
  .sl-pr-sm-n200 {
    padding-right: -8px !important;
  }
  .sl-p-sm-n250 {
    padding: -10px !important;
  }
  .sl-py-sm-n250 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .sl-px-sm-n250 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
  .sl-pt-sm-n250 {
    padding-top: -10px !important;
  }
  .sl-pb-sm-n250 {
    padding-bottom: -10px !important;
  }
  .sl-pl-sm-n250 {
    padding-left: -10px !important;
  }
  .sl-pr-sm-n250 {
    padding-right: -10px !important;
  }
  .sl-p-sm-n300 {
    padding: -12px !important;
  }
  .sl-py-sm-n300 {
    padding-top: -12px !important;
    padding-bottom: -12px !important;
  }
  .sl-px-sm-n300 {
    padding-left: -12px !important;
    padding-right: -12px !important;
  }
  .sl-pt-sm-n300 {
    padding-top: -12px !important;
  }
  .sl-pb-sm-n300 {
    padding-bottom: -12px !important;
  }
  .sl-pl-sm-n300 {
    padding-left: -12px !important;
  }
  .sl-pr-sm-n300 {
    padding-right: -12px !important;
  }
  .sl-p-sm-n350 {
    padding: -14px !important;
  }
  .sl-py-sm-n350 {
    padding-top: -14px !important;
    padding-bottom: -14px !important;
  }
  .sl-px-sm-n350 {
    padding-left: -14px !important;
    padding-right: -14px !important;
  }
  .sl-pt-sm-n350 {
    padding-top: -14px !important;
  }
  .sl-pb-sm-n350 {
    padding-bottom: -14px !important;
  }
  .sl-pl-sm-n350 {
    padding-left: -14px !important;
  }
  .sl-pr-sm-n350 {
    padding-right: -14px !important;
  }
  .sl-p-sm-n400 {
    padding: -16px !important;
  }
  .sl-py-sm-n400 {
    padding-top: -16px !important;
    padding-bottom: -16px !important;
  }
  .sl-px-sm-n400 {
    padding-left: -16px !important;
    padding-right: -16px !important;
  }
  .sl-pt-sm-n400 {
    padding-top: -16px !important;
  }
  .sl-pb-sm-n400 {
    padding-bottom: -16px !important;
  }
  .sl-pl-sm-n400 {
    padding-left: -16px !important;
  }
  .sl-pr-sm-n400 {
    padding-right: -16px !important;
  }
  .sl-p-sm-n450 {
    padding: -18px !important;
  }
  .sl-py-sm-n450 {
    padding-top: -18px !important;
    padding-bottom: -18px !important;
  }
  .sl-px-sm-n450 {
    padding-left: -18px !important;
    padding-right: -18px !important;
  }
  .sl-pt-sm-n450 {
    padding-top: -18px !important;
  }
  .sl-pb-sm-n450 {
    padding-bottom: -18px !important;
  }
  .sl-pl-sm-n450 {
    padding-left: -18px !important;
  }
  .sl-pr-sm-n450 {
    padding-right: -18px !important;
  }
  .sl-p-sm-n500 {
    padding: -20px !important;
  }
  .sl-py-sm-n500 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .sl-px-sm-n500 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .sl-pt-sm-n500 {
    padding-top: -20px !important;
  }
  .sl-pb-sm-n500 {
    padding-bottom: -20px !important;
  }
  .sl-pl-sm-n500 {
    padding-left: -20px !important;
  }
  .sl-pr-sm-n500 {
    padding-right: -20px !important;
  }
  .sl-p-sm-n550 {
    padding: -22px !important;
  }
  .sl-py-sm-n550 {
    padding-top: -22px !important;
    padding-bottom: -22px !important;
  }
  .sl-px-sm-n550 {
    padding-left: -22px !important;
    padding-right: -22px !important;
  }
  .sl-pt-sm-n550 {
    padding-top: -22px !important;
  }
  .sl-pb-sm-n550 {
    padding-bottom: -22px !important;
  }
  .sl-pl-sm-n550 {
    padding-left: -22px !important;
  }
  .sl-pr-sm-n550 {
    padding-right: -22px !important;
  }
  .sl-p-sm-n600 {
    padding: -24px !important;
  }
  .sl-py-sm-n600 {
    padding-top: -24px !important;
    padding-bottom: -24px !important;
  }
  .sl-px-sm-n600 {
    padding-left: -24px !important;
    padding-right: -24px !important;
  }
  .sl-pt-sm-n600 {
    padding-top: -24px !important;
  }
  .sl-pb-sm-n600 {
    padding-bottom: -24px !important;
  }
  .sl-pl-sm-n600 {
    padding-left: -24px !important;
  }
  .sl-pr-sm-n600 {
    padding-right: -24px !important;
  }
  .sl-p-sm-n650 {
    padding: -28px !important;
  }
  .sl-py-sm-n650 {
    padding-top: -28px !important;
    padding-bottom: -28px !important;
  }
  .sl-px-sm-n650 {
    padding-left: -28px !important;
    padding-right: -28px !important;
  }
  .sl-pt-sm-n650 {
    padding-top: -28px !important;
  }
  .sl-pb-sm-n650 {
    padding-bottom: -28px !important;
  }
  .sl-pl-sm-n650 {
    padding-left: -28px !important;
  }
  .sl-pr-sm-n650 {
    padding-right: -28px !important;
  }
  .sl-p-sm-n700 {
    padding: -32px !important;
  }
  .sl-py-sm-n700 {
    padding-top: -32px !important;
    padding-bottom: -32px !important;
  }
  .sl-px-sm-n700 {
    padding-left: -32px !important;
    padding-right: -32px !important;
  }
  .sl-pt-sm-n700 {
    padding-top: -32px !important;
  }
  .sl-pb-sm-n700 {
    padding-bottom: -32px !important;
  }
  .sl-pl-sm-n700 {
    padding-left: -32px !important;
  }
  .sl-pr-sm-n700 {
    padding-right: -32px !important;
  }
  .sl-p-sm-n750 {
    padding: -36px !important;
  }
  .sl-py-sm-n750 {
    padding-top: -36px !important;
    padding-bottom: -36px !important;
  }
  .sl-px-sm-n750 {
    padding-left: -36px !important;
    padding-right: -36px !important;
  }
  .sl-pt-sm-n750 {
    padding-top: -36px !important;
  }
  .sl-pb-sm-n750 {
    padding-bottom: -36px !important;
  }
  .sl-pl-sm-n750 {
    padding-left: -36px !important;
  }
  .sl-pr-sm-n750 {
    padding-right: -36px !important;
  }
  .sl-p-sm-n800 {
    padding: -40px !important;
  }
  .sl-py-sm-n800 {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }
  .sl-px-sm-n800 {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }
  .sl-pt-sm-n800 {
    padding-top: -40px !important;
  }
  .sl-pb-sm-n800 {
    padding-bottom: -40px !important;
  }
  .sl-pl-sm-n800 {
    padding-left: -40px !important;
  }
  .sl-pr-sm-n800 {
    padding-right: -40px !important;
  }
  .sl-p-sm-n850 {
    padding: -48px !important;
  }
  .sl-py-sm-n850 {
    padding-top: -48px !important;
    padding-bottom: -48px !important;
  }
  .sl-px-sm-n850 {
    padding-left: -48px !important;
    padding-right: -48px !important;
  }
  .sl-pt-sm-n850 {
    padding-top: -48px !important;
  }
  .sl-pb-sm-n850 {
    padding-bottom: -48px !important;
  }
  .sl-pl-sm-n850 {
    padding-left: -48px !important;
  }
  .sl-pr-sm-n850 {
    padding-right: -48px !important;
  }
  .sl-p-sm-n900 {
    padding: -56px !important;
  }
  .sl-py-sm-n900 {
    padding-top: -56px !important;
    padding-bottom: -56px !important;
  }
  .sl-px-sm-n900 {
    padding-left: -56px !important;
    padding-right: -56px !important;
  }
  .sl-pt-sm-n900 {
    padding-top: -56px !important;
  }
  .sl-pb-sm-n900 {
    padding-bottom: -56px !important;
  }
  .sl-pl-sm-n900 {
    padding-left: -56px !important;
  }
  .sl-pr-sm-n900 {
    padding-right: -56px !important;
  }
  .sl-p-sm-n925 {
    padding: -60px !important;
  }
  .sl-py-sm-n925 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .sl-px-sm-n925 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .sl-pt-sm-n925 {
    padding-top: -60px !important;
  }
  .sl-pb-sm-n925 {
    padding-bottom: -60px !important;
  }
  .sl-pl-sm-n925 {
    padding-left: -60px !important;
  }
  .sl-pr-sm-n925 {
    padding-right: -60px !important;
  }
  .sl-p-sm-n950 {
    padding: -64px !important;
  }
  .sl-py-sm-n950 {
    padding-top: -64px !important;
    padding-bottom: -64px !important;
  }
  .sl-px-sm-n950 {
    padding-left: -64px !important;
    padding-right: -64px !important;
  }
  .sl-pt-sm-n950 {
    padding-top: -64px !important;
  }
  .sl-pb-sm-n950 {
    padding-bottom: -64px !important;
  }
  .sl-pl-sm-n950 {
    padding-left: -64px !important;
  }
  .sl-pr-sm-n950 {
    padding-right: -64px !important;
  }
  .sl-p-sm-n1200 {
    padding: -96px !important;
  }
  .sl-py-sm-n1200 {
    padding-top: -96px !important;
    padding-bottom: -96px !important;
  }
  .sl-px-sm-n1200 {
    padding-left: -96px !important;
    padding-right: -96px !important;
  }
  .sl-pt-sm-n1200 {
    padding-top: -96px !important;
  }
  .sl-pb-sm-n1200 {
    padding-bottom: -96px !important;
  }
  .sl-pl-sm-n1200 {
    padding-left: -96px !important;
  }
  .sl-pr-sm-n1200 {
    padding-right: -96px !important;
  }
  .sl-p-sm-auto {
    padding: auto !important;
  }
  .sl-py-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .sl-px-sm-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .sl-pt-sm-auto {
    padding-top: auto !important;
  }
  .sl-pb-sm-auto {
    padding-bottom: auto !important;
  }
  .sl-pl-sm-auto {
    padding-left: auto !important;
  }
  .sl-pr-sm-auto {
    padding-right: auto !important;
  }
}
@media (min-width: 1150px) {
  .sl-m-md-0 {
    margin: 0px !important;
  }
  .sl-my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sl-mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sl-mt-md-0 {
    margin-top: 0px !important;
  }
  .sl-mb-md-0 {
    margin-bottom: 0px !important;
  }
  .sl-ml-md-0 {
    margin-left: 0px !important;
  }
  .sl-mr-md-0 {
    margin-right: 0px !important;
  }
  .sl-m-md-50 {
    margin: 2px !important;
  }
  .sl-my-md-50 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .sl-mx-md-50 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .sl-mt-md-50 {
    margin-top: 2px !important;
  }
  .sl-mb-md-50 {
    margin-bottom: 2px !important;
  }
  .sl-ml-md-50 {
    margin-left: 2px !important;
  }
  .sl-mr-md-50 {
    margin-right: 2px !important;
  }
  .sl-m-md-100 {
    margin: 4px !important;
  }
  .sl-my-md-100 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .sl-mx-md-100 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .sl-mt-md-100 {
    margin-top: 4px !important;
  }
  .sl-mb-md-100 {
    margin-bottom: 4px !important;
  }
  .sl-ml-md-100 {
    margin-left: 4px !important;
  }
  .sl-mr-md-100 {
    margin-right: 4px !important;
  }
  .sl-m-md-150 {
    margin: 6px !important;
  }
  .sl-my-md-150 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .sl-mx-md-150 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .sl-mt-md-150 {
    margin-top: 6px !important;
  }
  .sl-mb-md-150 {
    margin-bottom: 6px !important;
  }
  .sl-ml-md-150 {
    margin-left: 6px !important;
  }
  .sl-mr-md-150 {
    margin-right: 6px !important;
  }
  .sl-m-md-200 {
    margin: 8px !important;
  }
  .sl-my-md-200 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sl-mx-md-200 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sl-mt-md-200 {
    margin-top: 8px !important;
  }
  .sl-mb-md-200 {
    margin-bottom: 8px !important;
  }
  .sl-ml-md-200 {
    margin-left: 8px !important;
  }
  .sl-mr-md-200 {
    margin-right: 8px !important;
  }
  .sl-m-md-250 {
    margin: 10px !important;
  }
  .sl-my-md-250 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sl-mx-md-250 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sl-mt-md-250 {
    margin-top: 10px !important;
  }
  .sl-mb-md-250 {
    margin-bottom: 10px !important;
  }
  .sl-ml-md-250 {
    margin-left: 10px !important;
  }
  .sl-mr-md-250 {
    margin-right: 10px !important;
  }
  .sl-m-md-300 {
    margin: 12px !important;
  }
  .sl-my-md-300 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .sl-mx-md-300 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .sl-mt-md-300 {
    margin-top: 12px !important;
  }
  .sl-mb-md-300 {
    margin-bottom: 12px !important;
  }
  .sl-ml-md-300 {
    margin-left: 12px !important;
  }
  .sl-mr-md-300 {
    margin-right: 12px !important;
  }
  .sl-m-md-350 {
    margin: 14px !important;
  }
  .sl-my-md-350 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .sl-mx-md-350 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .sl-mt-md-350 {
    margin-top: 14px !important;
  }
  .sl-mb-md-350 {
    margin-bottom: 14px !important;
  }
  .sl-ml-md-350 {
    margin-left: 14px !important;
  }
  .sl-mr-md-350 {
    margin-right: 14px !important;
  }
  .sl-m-md-400 {
    margin: 16px !important;
  }
  .sl-my-md-400 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sl-mx-md-400 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sl-mt-md-400 {
    margin-top: 16px !important;
  }
  .sl-mb-md-400 {
    margin-bottom: 16px !important;
  }
  .sl-ml-md-400 {
    margin-left: 16px !important;
  }
  .sl-mr-md-400 {
    margin-right: 16px !important;
  }
  .sl-m-md-450 {
    margin: 18px !important;
  }
  .sl-my-md-450 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .sl-mx-md-450 {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .sl-mt-md-450 {
    margin-top: 18px !important;
  }
  .sl-mb-md-450 {
    margin-bottom: 18px !important;
  }
  .sl-ml-md-450 {
    margin-left: 18px !important;
  }
  .sl-mr-md-450 {
    margin-right: 18px !important;
  }
  .sl-m-md-500 {
    margin: 20px !important;
  }
  .sl-my-md-500 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sl-mx-md-500 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sl-mt-md-500 {
    margin-top: 20px !important;
  }
  .sl-mb-md-500 {
    margin-bottom: 20px !important;
  }
  .sl-ml-md-500 {
    margin-left: 20px !important;
  }
  .sl-mr-md-500 {
    margin-right: 20px !important;
  }
  .sl-m-md-550 {
    margin: 22px !important;
  }
  .sl-my-md-550 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .sl-mx-md-550 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .sl-mt-md-550 {
    margin-top: 22px !important;
  }
  .sl-mb-md-550 {
    margin-bottom: 22px !important;
  }
  .sl-ml-md-550 {
    margin-left: 22px !important;
  }
  .sl-mr-md-550 {
    margin-right: 22px !important;
  }
  .sl-m-md-600 {
    margin: 24px !important;
  }
  .sl-my-md-600 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sl-mx-md-600 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sl-mt-md-600 {
    margin-top: 24px !important;
  }
  .sl-mb-md-600 {
    margin-bottom: 24px !important;
  }
  .sl-ml-md-600 {
    margin-left: 24px !important;
  }
  .sl-mr-md-600 {
    margin-right: 24px !important;
  }
  .sl-m-md-650 {
    margin: 28px !important;
  }
  .sl-my-md-650 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .sl-mx-md-650 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .sl-mt-md-650 {
    margin-top: 28px !important;
  }
  .sl-mb-md-650 {
    margin-bottom: 28px !important;
  }
  .sl-ml-md-650 {
    margin-left: 28px !important;
  }
  .sl-mr-md-650 {
    margin-right: 28px !important;
  }
  .sl-m-md-700 {
    margin: 32px !important;
  }
  .sl-my-md-700 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sl-mx-md-700 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sl-mt-md-700 {
    margin-top: 32px !important;
  }
  .sl-mb-md-700 {
    margin-bottom: 32px !important;
  }
  .sl-ml-md-700 {
    margin-left: 32px !important;
  }
  .sl-mr-md-700 {
    margin-right: 32px !important;
  }
  .sl-m-md-750 {
    margin: 36px !important;
  }
  .sl-my-md-750 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .sl-mx-md-750 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .sl-mt-md-750 {
    margin-top: 36px !important;
  }
  .sl-mb-md-750 {
    margin-bottom: 36px !important;
  }
  .sl-ml-md-750 {
    margin-left: 36px !important;
  }
  .sl-mr-md-750 {
    margin-right: 36px !important;
  }
  .sl-m-md-800 {
    margin: 40px !important;
  }
  .sl-my-md-800 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sl-mx-md-800 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sl-mt-md-800 {
    margin-top: 40px !important;
  }
  .sl-mb-md-800 {
    margin-bottom: 40px !important;
  }
  .sl-ml-md-800 {
    margin-left: 40px !important;
  }
  .sl-mr-md-800 {
    margin-right: 40px !important;
  }
  .sl-m-md-825 {
    margin: 44px !important;
  }
  .sl-my-md-825 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .sl-mx-md-825 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }
  .sl-mt-md-825 {
    margin-top: 44px !important;
  }
  .sl-mb-md-825 {
    margin-bottom: 44px !important;
  }
  .sl-ml-md-825 {
    margin-left: 44px !important;
  }
  .sl-mr-md-825 {
    margin-right: 44px !important;
  }
  .sl-m-md-850 {
    margin: 48px !important;
  }
  .sl-my-md-850 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sl-mx-md-850 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sl-mt-md-850 {
    margin-top: 48px !important;
  }
  .sl-mb-md-850 {
    margin-bottom: 48px !important;
  }
  .sl-ml-md-850 {
    margin-left: 48px !important;
  }
  .sl-mr-md-850 {
    margin-right: 48px !important;
  }
  .sl-m-md-900 {
    margin: 56px !important;
  }
  .sl-my-md-900 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sl-mx-md-900 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sl-mt-md-900 {
    margin-top: 56px !important;
  }
  .sl-mb-md-900 {
    margin-bottom: 56px !important;
  }
  .sl-ml-md-900 {
    margin-left: 56px !important;
  }
  .sl-mr-md-900 {
    margin-right: 56px !important;
  }
  .sl-m-md-925 {
    margin: 60px !important;
  }
  .sl-my-md-925 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sl-mx-md-925 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sl-mt-md-925 {
    margin-top: 60px !important;
  }
  .sl-mb-md-925 {
    margin-bottom: 60px !important;
  }
  .sl-ml-md-925 {
    margin-left: 60px !important;
  }
  .sl-mr-md-925 {
    margin-right: 60px !important;
  }
  .sl-m-md-950 {
    margin: 64px !important;
  }
  .sl-my-md-950 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .sl-mx-md-950 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sl-mt-md-950 {
    margin-top: 64px !important;
  }
  .sl-mb-md-950 {
    margin-bottom: 64px !important;
  }
  .sl-ml-md-950 {
    margin-left: 64px !important;
  }
  .sl-mr-md-950 {
    margin-right: 64px !important;
  }
  .sl-m-md-1200 {
    margin: 96px !important;
  }
  .sl-my-md-1200 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .sl-mx-md-1200 {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .sl-mt-md-1200 {
    margin-top: 96px !important;
  }
  .sl-mb-md-1200 {
    margin-bottom: 96px !important;
  }
  .sl-ml-md-1200 {
    margin-left: 96px !important;
  }
  .sl-mr-md-1200 {
    margin-right: 96px !important;
  }
  .sl-m-md-n50 {
    margin: -2px !important;
  }
  .sl-my-md-n50 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .sl-mx-md-n50 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .sl-mt-md-n50 {
    margin-top: -2px !important;
  }
  .sl-mb-md-n50 {
    margin-bottom: -2px !important;
  }
  .sl-ml-md-n50 {
    margin-left: -2px !important;
  }
  .sl-mr-md-n50 {
    margin-right: -2px !important;
  }
  .sl-m-md-n100 {
    margin: -4px !important;
  }
  .sl-my-md-n100 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .sl-mx-md-n100 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .sl-mt-md-n100 {
    margin-top: -4px !important;
  }
  .sl-mb-md-n100 {
    margin-bottom: -4px !important;
  }
  .sl-ml-md-n100 {
    margin-left: -4px !important;
  }
  .sl-mr-md-n100 {
    margin-right: -4px !important;
  }
  .sl-m-md-n150 {
    margin: -6px !important;
  }
  .sl-my-md-n150 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .sl-mx-md-n150 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .sl-mt-md-n150 {
    margin-top: -6px !important;
  }
  .sl-mb-md-n150 {
    margin-bottom: -6px !important;
  }
  .sl-ml-md-n150 {
    margin-left: -6px !important;
  }
  .sl-mr-md-n150 {
    margin-right: -6px !important;
  }
  .sl-m-md-n200 {
    margin: -8px !important;
  }
  .sl-my-md-n200 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .sl-mx-md-n200 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .sl-mt-md-n200 {
    margin-top: -8px !important;
  }
  .sl-mb-md-n200 {
    margin-bottom: -8px !important;
  }
  .sl-ml-md-n200 {
    margin-left: -8px !important;
  }
  .sl-mr-md-n200 {
    margin-right: -8px !important;
  }
  .sl-m-md-n250 {
    margin: -10px !important;
  }
  .sl-my-md-n250 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sl-mx-md-n250 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sl-mt-md-n250 {
    margin-top: -10px !important;
  }
  .sl-mb-md-n250 {
    margin-bottom: -10px !important;
  }
  .sl-ml-md-n250 {
    margin-left: -10px !important;
  }
  .sl-mr-md-n250 {
    margin-right: -10px !important;
  }
  .sl-m-md-n300 {
    margin: -12px !important;
  }
  .sl-my-md-n300 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .sl-mx-md-n300 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .sl-mt-md-n300 {
    margin-top: -12px !important;
  }
  .sl-mb-md-n300 {
    margin-bottom: -12px !important;
  }
  .sl-ml-md-n300 {
    margin-left: -12px !important;
  }
  .sl-mr-md-n300 {
    margin-right: -12px !important;
  }
  .sl-m-md-n350 {
    margin: -14px !important;
  }
  .sl-my-md-n350 {
    margin-top: -14px !important;
    margin-bottom: -14px !important;
  }
  .sl-mx-md-n350 {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
  .sl-mt-md-n350 {
    margin-top: -14px !important;
  }
  .sl-mb-md-n350 {
    margin-bottom: -14px !important;
  }
  .sl-ml-md-n350 {
    margin-left: -14px !important;
  }
  .sl-mr-md-n350 {
    margin-right: -14px !important;
  }
  .sl-m-md-n400 {
    margin: -16px !important;
  }
  .sl-my-md-n400 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .sl-mx-md-n400 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .sl-mt-md-n400 {
    margin-top: -16px !important;
  }
  .sl-mb-md-n400 {
    margin-bottom: -16px !important;
  }
  .sl-ml-md-n400 {
    margin-left: -16px !important;
  }
  .sl-mr-md-n400 {
    margin-right: -16px !important;
  }
  .sl-m-md-n450 {
    margin: -18px !important;
  }
  .sl-my-md-n450 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .sl-mx-md-n450 {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .sl-mt-md-n450 {
    margin-top: -18px !important;
  }
  .sl-mb-md-n450 {
    margin-bottom: -18px !important;
  }
  .sl-ml-md-n450 {
    margin-left: -18px !important;
  }
  .sl-mr-md-n450 {
    margin-right: -18px !important;
  }
  .sl-m-md-n500 {
    margin: -20px !important;
  }
  .sl-my-md-n500 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sl-mx-md-n500 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sl-mt-md-n500 {
    margin-top: -20px !important;
  }
  .sl-mb-md-n500 {
    margin-bottom: -20px !important;
  }
  .sl-ml-md-n500 {
    margin-left: -20px !important;
  }
  .sl-mr-md-n500 {
    margin-right: -20px !important;
  }
  .sl-m-md-n550 {
    margin: -22px !important;
  }
  .sl-my-md-n550 {
    margin-top: -22px !important;
    margin-bottom: -22px !important;
  }
  .sl-mx-md-n550 {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
  .sl-mt-md-n550 {
    margin-top: -22px !important;
  }
  .sl-mb-md-n550 {
    margin-bottom: -22px !important;
  }
  .sl-ml-md-n550 {
    margin-left: -22px !important;
  }
  .sl-mr-md-n550 {
    margin-right: -22px !important;
  }
  .sl-m-md-n600 {
    margin: -24px !important;
  }
  .sl-my-md-n600 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .sl-mx-md-n600 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .sl-mt-md-n600 {
    margin-top: -24px !important;
  }
  .sl-mb-md-n600 {
    margin-bottom: -24px !important;
  }
  .sl-ml-md-n600 {
    margin-left: -24px !important;
  }
  .sl-mr-md-n600 {
    margin-right: -24px !important;
  }
  .sl-m-md-n650 {
    margin: -28px !important;
  }
  .sl-my-md-n650 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .sl-mx-md-n650 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }
  .sl-mt-md-n650 {
    margin-top: -28px !important;
  }
  .sl-mb-md-n650 {
    margin-bottom: -28px !important;
  }
  .sl-ml-md-n650 {
    margin-left: -28px !important;
  }
  .sl-mr-md-n650 {
    margin-right: -28px !important;
  }
  .sl-m-md-n700 {
    margin: -32px !important;
  }
  .sl-my-md-n700 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .sl-mx-md-n700 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }
  .sl-mt-md-n700 {
    margin-top: -32px !important;
  }
  .sl-mb-md-n700 {
    margin-bottom: -32px !important;
  }
  .sl-ml-md-n700 {
    margin-left: -32px !important;
  }
  .sl-mr-md-n700 {
    margin-right: -32px !important;
  }
  .sl-m-md-n750 {
    margin: -36px !important;
  }
  .sl-my-md-n750 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .sl-mx-md-n750 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }
  .sl-mt-md-n750 {
    margin-top: -36px !important;
  }
  .sl-mb-md-n750 {
    margin-bottom: -36px !important;
  }
  .sl-ml-md-n750 {
    margin-left: -36px !important;
  }
  .sl-mr-md-n750 {
    margin-right: -36px !important;
  }
  .sl-m-md-n800 {
    margin: -40px !important;
  }
  .sl-my-md-n800 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sl-mx-md-n800 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sl-mt-md-n800 {
    margin-top: -40px !important;
  }
  .sl-mb-md-n800 {
    margin-bottom: -40px !important;
  }
  .sl-ml-md-n800 {
    margin-left: -40px !important;
  }
  .sl-mr-md-n800 {
    margin-right: -40px !important;
  }
  .sl-m-md-n850 {
    margin: -48px !important;
  }
  .sl-my-md-n850 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .sl-mx-md-n850 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }
  .sl-mt-md-n850 {
    margin-top: -48px !important;
  }
  .sl-mb-md-n850 {
    margin-bottom: -48px !important;
  }
  .sl-ml-md-n850 {
    margin-left: -48px !important;
  }
  .sl-mr-md-n850 {
    margin-right: -48px !important;
  }
  .sl-m-md-n900 {
    margin: -56px !important;
  }
  .sl-my-md-n900 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .sl-mx-md-n900 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }
  .sl-mt-md-n900 {
    margin-top: -56px !important;
  }
  .sl-mb-md-n900 {
    margin-bottom: -56px !important;
  }
  .sl-ml-md-n900 {
    margin-left: -56px !important;
  }
  .sl-mr-md-n900 {
    margin-right: -56px !important;
  }
  .sl-m-md-n925 {
    margin: -60px !important;
  }
  .sl-my-md-n925 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .sl-mx-md-n925 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sl-mt-md-n925 {
    margin-top: -60px !important;
  }
  .sl-mb-md-n925 {
    margin-bottom: -60px !important;
  }
  .sl-ml-md-n925 {
    margin-left: -60px !important;
  }
  .sl-mr-md-n925 {
    margin-right: -60px !important;
  }
  .sl-m-md-n950 {
    margin: -64px !important;
  }
  .sl-my-md-n950 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .sl-mx-md-n950 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }
  .sl-mt-md-n950 {
    margin-top: -64px !important;
  }
  .sl-mb-md-n950 {
    margin-bottom: -64px !important;
  }
  .sl-ml-md-n950 {
    margin-left: -64px !important;
  }
  .sl-mr-md-n950 {
    margin-right: -64px !important;
  }
  .sl-m-md-n1200 {
    margin: -96px !important;
  }
  .sl-my-md-n1200 {
    margin-top: -96px !important;
    margin-bottom: -96px !important;
  }
  .sl-mx-md-n1200 {
    margin-left: -96px !important;
    margin-right: -96px !important;
  }
  .sl-mt-md-n1200 {
    margin-top: -96px !important;
  }
  .sl-mb-md-n1200 {
    margin-bottom: -96px !important;
  }
  .sl-ml-md-n1200 {
    margin-left: -96px !important;
  }
  .sl-mr-md-n1200 {
    margin-right: -96px !important;
  }
  .sl-m-md-auto {
    margin: auto !important;
  }
  .sl-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .sl-mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sl-mt-md-auto {
    margin-top: auto !important;
  }
  .sl-mb-md-auto {
    margin-bottom: auto !important;
  }
  .sl-ml-md-auto {
    margin-left: auto !important;
  }
  .sl-mr-md-auto {
    margin-right: auto !important;
  }
  .sl-p-md-0 {
    padding: 0px !important;
  }
  .sl-py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sl-px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sl-pt-md-0 {
    padding-top: 0px !important;
  }
  .sl-pb-md-0 {
    padding-bottom: 0px !important;
  }
  .sl-pl-md-0 {
    padding-left: 0px !important;
  }
  .sl-pr-md-0 {
    padding-right: 0px !important;
  }
  .sl-p-md-50 {
    padding: 2px !important;
  }
  .sl-py-md-50 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .sl-px-md-50 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .sl-pt-md-50 {
    padding-top: 2px !important;
  }
  .sl-pb-md-50 {
    padding-bottom: 2px !important;
  }
  .sl-pl-md-50 {
    padding-left: 2px !important;
  }
  .sl-pr-md-50 {
    padding-right: 2px !important;
  }
  .sl-p-md-100 {
    padding: 4px !important;
  }
  .sl-py-md-100 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sl-px-md-100 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sl-pt-md-100 {
    padding-top: 4px !important;
  }
  .sl-pb-md-100 {
    padding-bottom: 4px !important;
  }
  .sl-pl-md-100 {
    padding-left: 4px !important;
  }
  .sl-pr-md-100 {
    padding-right: 4px !important;
  }
  .sl-p-md-150 {
    padding: 6px !important;
  }
  .sl-py-md-150 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .sl-px-md-150 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .sl-pt-md-150 {
    padding-top: 6px !important;
  }
  .sl-pb-md-150 {
    padding-bottom: 6px !important;
  }
  .sl-pl-md-150 {
    padding-left: 6px !important;
  }
  .sl-pr-md-150 {
    padding-right: 6px !important;
  }
  .sl-p-md-200 {
    padding: 8px !important;
  }
  .sl-py-md-200 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sl-px-md-200 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sl-pt-md-200 {
    padding-top: 8px !important;
  }
  .sl-pb-md-200 {
    padding-bottom: 8px !important;
  }
  .sl-pl-md-200 {
    padding-left: 8px !important;
  }
  .sl-pr-md-200 {
    padding-right: 8px !important;
  }
  .sl-p-md-250 {
    padding: 10px !important;
  }
  .sl-py-md-250 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sl-px-md-250 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sl-pt-md-250 {
    padding-top: 10px !important;
  }
  .sl-pb-md-250 {
    padding-bottom: 10px !important;
  }
  .sl-pl-md-250 {
    padding-left: 10px !important;
  }
  .sl-pr-md-250 {
    padding-right: 10px !important;
  }
  .sl-p-md-300 {
    padding: 12px !important;
  }
  .sl-py-md-300 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sl-px-md-300 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sl-pt-md-300 {
    padding-top: 12px !important;
  }
  .sl-pb-md-300 {
    padding-bottom: 12px !important;
  }
  .sl-pl-md-300 {
    padding-left: 12px !important;
  }
  .sl-pr-md-300 {
    padding-right: 12px !important;
  }
  .sl-p-md-350 {
    padding: 14px !important;
  }
  .sl-py-md-350 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .sl-px-md-350 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sl-pt-md-350 {
    padding-top: 14px !important;
  }
  .sl-pb-md-350 {
    padding-bottom: 14px !important;
  }
  .sl-pl-md-350 {
    padding-left: 14px !important;
  }
  .sl-pr-md-350 {
    padding-right: 14px !important;
  }
  .sl-p-md-400 {
    padding: 16px !important;
  }
  .sl-py-md-400 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sl-px-md-400 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sl-pt-md-400 {
    padding-top: 16px !important;
  }
  .sl-pb-md-400 {
    padding-bottom: 16px !important;
  }
  .sl-pl-md-400 {
    padding-left: 16px !important;
  }
  .sl-pr-md-400 {
    padding-right: 16px !important;
  }
  .sl-p-md-450 {
    padding: 18px !important;
  }
  .sl-py-md-450 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .sl-px-md-450 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .sl-pt-md-450 {
    padding-top: 18px !important;
  }
  .sl-pb-md-450 {
    padding-bottom: 18px !important;
  }
  .sl-pl-md-450 {
    padding-left: 18px !important;
  }
  .sl-pr-md-450 {
    padding-right: 18px !important;
  }
  .sl-p-md-500 {
    padding: 20px !important;
  }
  .sl-py-md-500 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sl-px-md-500 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sl-pt-md-500 {
    padding-top: 20px !important;
  }
  .sl-pb-md-500 {
    padding-bottom: 20px !important;
  }
  .sl-pl-md-500 {
    padding-left: 20px !important;
  }
  .sl-pr-md-500 {
    padding-right: 20px !important;
  }
  .sl-p-md-550 {
    padding: 22px !important;
  }
  .sl-py-md-550 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .sl-px-md-550 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .sl-pt-md-550 {
    padding-top: 22px !important;
  }
  .sl-pb-md-550 {
    padding-bottom: 22px !important;
  }
  .sl-pl-md-550 {
    padding-left: 22px !important;
  }
  .sl-pr-md-550 {
    padding-right: 22px !important;
  }
  .sl-p-md-600 {
    padding: 24px !important;
  }
  .sl-py-md-600 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sl-px-md-600 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sl-pt-md-600 {
    padding-top: 24px !important;
  }
  .sl-pb-md-600 {
    padding-bottom: 24px !important;
  }
  .sl-pl-md-600 {
    padding-left: 24px !important;
  }
  .sl-pr-md-600 {
    padding-right: 24px !important;
  }
  .sl-p-md-650 {
    padding: 28px !important;
  }
  .sl-py-md-650 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sl-px-md-650 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sl-pt-md-650 {
    padding-top: 28px !important;
  }
  .sl-pb-md-650 {
    padding-bottom: 28px !important;
  }
  .sl-pl-md-650 {
    padding-left: 28px !important;
  }
  .sl-pr-md-650 {
    padding-right: 28px !important;
  }
  .sl-p-md-700 {
    padding: 32px !important;
  }
  .sl-py-md-700 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sl-px-md-700 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sl-pt-md-700 {
    padding-top: 32px !important;
  }
  .sl-pb-md-700 {
    padding-bottom: 32px !important;
  }
  .sl-pl-md-700 {
    padding-left: 32px !important;
  }
  .sl-pr-md-700 {
    padding-right: 32px !important;
  }
  .sl-p-md-750 {
    padding: 36px !important;
  }
  .sl-py-md-750 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .sl-px-md-750 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .sl-pt-md-750 {
    padding-top: 36px !important;
  }
  .sl-pb-md-750 {
    padding-bottom: 36px !important;
  }
  .sl-pl-md-750 {
    padding-left: 36px !important;
  }
  .sl-pr-md-750 {
    padding-right: 36px !important;
  }
  .sl-p-md-800 {
    padding: 40px !important;
  }
  .sl-py-md-800 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sl-px-md-800 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sl-pt-md-800 {
    padding-top: 40px !important;
  }
  .sl-pb-md-800 {
    padding-bottom: 40px !important;
  }
  .sl-pl-md-800 {
    padding-left: 40px !important;
  }
  .sl-pr-md-800 {
    padding-right: 40px !important;
  }
  .sl-p-md-825 {
    padding: 44px !important;
  }
  .sl-py-md-825 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .sl-px-md-825 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .sl-pt-md-825 {
    padding-top: 44px !important;
  }
  .sl-pb-md-825 {
    padding-bottom: 44px !important;
  }
  .sl-pl-md-825 {
    padding-left: 44px !important;
  }
  .sl-pr-md-825 {
    padding-right: 44px !important;
  }
  .sl-p-md-850 {
    padding: 48px !important;
  }
  .sl-py-md-850 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sl-px-md-850 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sl-pt-md-850 {
    padding-top: 48px !important;
  }
  .sl-pb-md-850 {
    padding-bottom: 48px !important;
  }
  .sl-pl-md-850 {
    padding-left: 48px !important;
  }
  .sl-pr-md-850 {
    padding-right: 48px !important;
  }
  .sl-p-md-900 {
    padding: 56px !important;
  }
  .sl-py-md-900 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sl-px-md-900 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sl-pt-md-900 {
    padding-top: 56px !important;
  }
  .sl-pb-md-900 {
    padding-bottom: 56px !important;
  }
  .sl-pl-md-900 {
    padding-left: 56px !important;
  }
  .sl-pr-md-900 {
    padding-right: 56px !important;
  }
  .sl-p-md-925 {
    padding: 60px !important;
  }
  .sl-py-md-925 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sl-px-md-925 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sl-pt-md-925 {
    padding-top: 60px !important;
  }
  .sl-pb-md-925 {
    padding-bottom: 60px !important;
  }
  .sl-pl-md-925 {
    padding-left: 60px !important;
  }
  .sl-pr-md-925 {
    padding-right: 60px !important;
  }
  .sl-p-md-950 {
    padding: 64px !important;
  }
  .sl-py-md-950 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sl-px-md-950 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sl-pt-md-950 {
    padding-top: 64px !important;
  }
  .sl-pb-md-950 {
    padding-bottom: 64px !important;
  }
  .sl-pl-md-950 {
    padding-left: 64px !important;
  }
  .sl-pr-md-950 {
    padding-right: 64px !important;
  }
  .sl-p-md-1200 {
    padding: 96px !important;
  }
  .sl-py-md-1200 {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .sl-px-md-1200 {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .sl-pt-md-1200 {
    padding-top: 96px !important;
  }
  .sl-pb-md-1200 {
    padding-bottom: 96px !important;
  }
  .sl-pl-md-1200 {
    padding-left: 96px !important;
  }
  .sl-pr-md-1200 {
    padding-right: 96px !important;
  }
  .sl-p-md-n50 {
    padding: -2px !important;
  }
  .sl-py-md-n50 {
    padding-top: -2px !important;
    padding-bottom: -2px !important;
  }
  .sl-px-md-n50 {
    padding-left: -2px !important;
    padding-right: -2px !important;
  }
  .sl-pt-md-n50 {
    padding-top: -2px !important;
  }
  .sl-pb-md-n50 {
    padding-bottom: -2px !important;
  }
  .sl-pl-md-n50 {
    padding-left: -2px !important;
  }
  .sl-pr-md-n50 {
    padding-right: -2px !important;
  }
  .sl-p-md-n100 {
    padding: -4px !important;
  }
  .sl-py-md-n100 {
    padding-top: -4px !important;
    padding-bottom: -4px !important;
  }
  .sl-px-md-n100 {
    padding-left: -4px !important;
    padding-right: -4px !important;
  }
  .sl-pt-md-n100 {
    padding-top: -4px !important;
  }
  .sl-pb-md-n100 {
    padding-bottom: -4px !important;
  }
  .sl-pl-md-n100 {
    padding-left: -4px !important;
  }
  .sl-pr-md-n100 {
    padding-right: -4px !important;
  }
  .sl-p-md-n150 {
    padding: -6px !important;
  }
  .sl-py-md-n150 {
    padding-top: -6px !important;
    padding-bottom: -6px !important;
  }
  .sl-px-md-n150 {
    padding-left: -6px !important;
    padding-right: -6px !important;
  }
  .sl-pt-md-n150 {
    padding-top: -6px !important;
  }
  .sl-pb-md-n150 {
    padding-bottom: -6px !important;
  }
  .sl-pl-md-n150 {
    padding-left: -6px !important;
  }
  .sl-pr-md-n150 {
    padding-right: -6px !important;
  }
  .sl-p-md-n200 {
    padding: -8px !important;
  }
  .sl-py-md-n200 {
    padding-top: -8px !important;
    padding-bottom: -8px !important;
  }
  .sl-px-md-n200 {
    padding-left: -8px !important;
    padding-right: -8px !important;
  }
  .sl-pt-md-n200 {
    padding-top: -8px !important;
  }
  .sl-pb-md-n200 {
    padding-bottom: -8px !important;
  }
  .sl-pl-md-n200 {
    padding-left: -8px !important;
  }
  .sl-pr-md-n200 {
    padding-right: -8px !important;
  }
  .sl-p-md-n250 {
    padding: -10px !important;
  }
  .sl-py-md-n250 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .sl-px-md-n250 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
  .sl-pt-md-n250 {
    padding-top: -10px !important;
  }
  .sl-pb-md-n250 {
    padding-bottom: -10px !important;
  }
  .sl-pl-md-n250 {
    padding-left: -10px !important;
  }
  .sl-pr-md-n250 {
    padding-right: -10px !important;
  }
  .sl-p-md-n300 {
    padding: -12px !important;
  }
  .sl-py-md-n300 {
    padding-top: -12px !important;
    padding-bottom: -12px !important;
  }
  .sl-px-md-n300 {
    padding-left: -12px !important;
    padding-right: -12px !important;
  }
  .sl-pt-md-n300 {
    padding-top: -12px !important;
  }
  .sl-pb-md-n300 {
    padding-bottom: -12px !important;
  }
  .sl-pl-md-n300 {
    padding-left: -12px !important;
  }
  .sl-pr-md-n300 {
    padding-right: -12px !important;
  }
  .sl-p-md-n350 {
    padding: -14px !important;
  }
  .sl-py-md-n350 {
    padding-top: -14px !important;
    padding-bottom: -14px !important;
  }
  .sl-px-md-n350 {
    padding-left: -14px !important;
    padding-right: -14px !important;
  }
  .sl-pt-md-n350 {
    padding-top: -14px !important;
  }
  .sl-pb-md-n350 {
    padding-bottom: -14px !important;
  }
  .sl-pl-md-n350 {
    padding-left: -14px !important;
  }
  .sl-pr-md-n350 {
    padding-right: -14px !important;
  }
  .sl-p-md-n400 {
    padding: -16px !important;
  }
  .sl-py-md-n400 {
    padding-top: -16px !important;
    padding-bottom: -16px !important;
  }
  .sl-px-md-n400 {
    padding-left: -16px !important;
    padding-right: -16px !important;
  }
  .sl-pt-md-n400 {
    padding-top: -16px !important;
  }
  .sl-pb-md-n400 {
    padding-bottom: -16px !important;
  }
  .sl-pl-md-n400 {
    padding-left: -16px !important;
  }
  .sl-pr-md-n400 {
    padding-right: -16px !important;
  }
  .sl-p-md-n450 {
    padding: -18px !important;
  }
  .sl-py-md-n450 {
    padding-top: -18px !important;
    padding-bottom: -18px !important;
  }
  .sl-px-md-n450 {
    padding-left: -18px !important;
    padding-right: -18px !important;
  }
  .sl-pt-md-n450 {
    padding-top: -18px !important;
  }
  .sl-pb-md-n450 {
    padding-bottom: -18px !important;
  }
  .sl-pl-md-n450 {
    padding-left: -18px !important;
  }
  .sl-pr-md-n450 {
    padding-right: -18px !important;
  }
  .sl-p-md-n500 {
    padding: -20px !important;
  }
  .sl-py-md-n500 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .sl-px-md-n500 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .sl-pt-md-n500 {
    padding-top: -20px !important;
  }
  .sl-pb-md-n500 {
    padding-bottom: -20px !important;
  }
  .sl-pl-md-n500 {
    padding-left: -20px !important;
  }
  .sl-pr-md-n500 {
    padding-right: -20px !important;
  }
  .sl-p-md-n550 {
    padding: -22px !important;
  }
  .sl-py-md-n550 {
    padding-top: -22px !important;
    padding-bottom: -22px !important;
  }
  .sl-px-md-n550 {
    padding-left: -22px !important;
    padding-right: -22px !important;
  }
  .sl-pt-md-n550 {
    padding-top: -22px !important;
  }
  .sl-pb-md-n550 {
    padding-bottom: -22px !important;
  }
  .sl-pl-md-n550 {
    padding-left: -22px !important;
  }
  .sl-pr-md-n550 {
    padding-right: -22px !important;
  }
  .sl-p-md-n600 {
    padding: -24px !important;
  }
  .sl-py-md-n600 {
    padding-top: -24px !important;
    padding-bottom: -24px !important;
  }
  .sl-px-md-n600 {
    padding-left: -24px !important;
    padding-right: -24px !important;
  }
  .sl-pt-md-n600 {
    padding-top: -24px !important;
  }
  .sl-pb-md-n600 {
    padding-bottom: -24px !important;
  }
  .sl-pl-md-n600 {
    padding-left: -24px !important;
  }
  .sl-pr-md-n600 {
    padding-right: -24px !important;
  }
  .sl-p-md-n650 {
    padding: -28px !important;
  }
  .sl-py-md-n650 {
    padding-top: -28px !important;
    padding-bottom: -28px !important;
  }
  .sl-px-md-n650 {
    padding-left: -28px !important;
    padding-right: -28px !important;
  }
  .sl-pt-md-n650 {
    padding-top: -28px !important;
  }
  .sl-pb-md-n650 {
    padding-bottom: -28px !important;
  }
  .sl-pl-md-n650 {
    padding-left: -28px !important;
  }
  .sl-pr-md-n650 {
    padding-right: -28px !important;
  }
  .sl-p-md-n700 {
    padding: -32px !important;
  }
  .sl-py-md-n700 {
    padding-top: -32px !important;
    padding-bottom: -32px !important;
  }
  .sl-px-md-n700 {
    padding-left: -32px !important;
    padding-right: -32px !important;
  }
  .sl-pt-md-n700 {
    padding-top: -32px !important;
  }
  .sl-pb-md-n700 {
    padding-bottom: -32px !important;
  }
  .sl-pl-md-n700 {
    padding-left: -32px !important;
  }
  .sl-pr-md-n700 {
    padding-right: -32px !important;
  }
  .sl-p-md-n750 {
    padding: -36px !important;
  }
  .sl-py-md-n750 {
    padding-top: -36px !important;
    padding-bottom: -36px !important;
  }
  .sl-px-md-n750 {
    padding-left: -36px !important;
    padding-right: -36px !important;
  }
  .sl-pt-md-n750 {
    padding-top: -36px !important;
  }
  .sl-pb-md-n750 {
    padding-bottom: -36px !important;
  }
  .sl-pl-md-n750 {
    padding-left: -36px !important;
  }
  .sl-pr-md-n750 {
    padding-right: -36px !important;
  }
  .sl-p-md-n800 {
    padding: -40px !important;
  }
  .sl-py-md-n800 {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }
  .sl-px-md-n800 {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }
  .sl-pt-md-n800 {
    padding-top: -40px !important;
  }
  .sl-pb-md-n800 {
    padding-bottom: -40px !important;
  }
  .sl-pl-md-n800 {
    padding-left: -40px !important;
  }
  .sl-pr-md-n800 {
    padding-right: -40px !important;
  }
  .sl-p-md-n850 {
    padding: -48px !important;
  }
  .sl-py-md-n850 {
    padding-top: -48px !important;
    padding-bottom: -48px !important;
  }
  .sl-px-md-n850 {
    padding-left: -48px !important;
    padding-right: -48px !important;
  }
  .sl-pt-md-n850 {
    padding-top: -48px !important;
  }
  .sl-pb-md-n850 {
    padding-bottom: -48px !important;
  }
  .sl-pl-md-n850 {
    padding-left: -48px !important;
  }
  .sl-pr-md-n850 {
    padding-right: -48px !important;
  }
  .sl-p-md-n900 {
    padding: -56px !important;
  }
  .sl-py-md-n900 {
    padding-top: -56px !important;
    padding-bottom: -56px !important;
  }
  .sl-px-md-n900 {
    padding-left: -56px !important;
    padding-right: -56px !important;
  }
  .sl-pt-md-n900 {
    padding-top: -56px !important;
  }
  .sl-pb-md-n900 {
    padding-bottom: -56px !important;
  }
  .sl-pl-md-n900 {
    padding-left: -56px !important;
  }
  .sl-pr-md-n900 {
    padding-right: -56px !important;
  }
  .sl-p-md-n925 {
    padding: -60px !important;
  }
  .sl-py-md-n925 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .sl-px-md-n925 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .sl-pt-md-n925 {
    padding-top: -60px !important;
  }
  .sl-pb-md-n925 {
    padding-bottom: -60px !important;
  }
  .sl-pl-md-n925 {
    padding-left: -60px !important;
  }
  .sl-pr-md-n925 {
    padding-right: -60px !important;
  }
  .sl-p-md-n950 {
    padding: -64px !important;
  }
  .sl-py-md-n950 {
    padding-top: -64px !important;
    padding-bottom: -64px !important;
  }
  .sl-px-md-n950 {
    padding-left: -64px !important;
    padding-right: -64px !important;
  }
  .sl-pt-md-n950 {
    padding-top: -64px !important;
  }
  .sl-pb-md-n950 {
    padding-bottom: -64px !important;
  }
  .sl-pl-md-n950 {
    padding-left: -64px !important;
  }
  .sl-pr-md-n950 {
    padding-right: -64px !important;
  }
  .sl-p-md-n1200 {
    padding: -96px !important;
  }
  .sl-py-md-n1200 {
    padding-top: -96px !important;
    padding-bottom: -96px !important;
  }
  .sl-px-md-n1200 {
    padding-left: -96px !important;
    padding-right: -96px !important;
  }
  .sl-pt-md-n1200 {
    padding-top: -96px !important;
  }
  .sl-pb-md-n1200 {
    padding-bottom: -96px !important;
  }
  .sl-pl-md-n1200 {
    padding-left: -96px !important;
  }
  .sl-pr-md-n1200 {
    padding-right: -96px !important;
  }
  .sl-p-md-auto {
    padding: auto !important;
  }
  .sl-py-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .sl-px-md-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .sl-pt-md-auto {
    padding-top: auto !important;
  }
  .sl-pb-md-auto {
    padding-bottom: auto !important;
  }
  .sl-pl-md-auto {
    padding-left: auto !important;
  }
  .sl-pr-md-auto {
    padding-right: auto !important;
  }
}
@media (min-width: 1440px) {
  .sl-m-lg-0 {
    margin: 0px !important;
  }
  .sl-my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sl-mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sl-mt-lg-0 {
    margin-top: 0px !important;
  }
  .sl-mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .sl-ml-lg-0 {
    margin-left: 0px !important;
  }
  .sl-mr-lg-0 {
    margin-right: 0px !important;
  }
  .sl-m-lg-50 {
    margin: 2px !important;
  }
  .sl-my-lg-50 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .sl-mx-lg-50 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .sl-mt-lg-50 {
    margin-top: 2px !important;
  }
  .sl-mb-lg-50 {
    margin-bottom: 2px !important;
  }
  .sl-ml-lg-50 {
    margin-left: 2px !important;
  }
  .sl-mr-lg-50 {
    margin-right: 2px !important;
  }
  .sl-m-lg-100 {
    margin: 4px !important;
  }
  .sl-my-lg-100 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .sl-mx-lg-100 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .sl-mt-lg-100 {
    margin-top: 4px !important;
  }
  .sl-mb-lg-100 {
    margin-bottom: 4px !important;
  }
  .sl-ml-lg-100 {
    margin-left: 4px !important;
  }
  .sl-mr-lg-100 {
    margin-right: 4px !important;
  }
  .sl-m-lg-150 {
    margin: 6px !important;
  }
  .sl-my-lg-150 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .sl-mx-lg-150 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .sl-mt-lg-150 {
    margin-top: 6px !important;
  }
  .sl-mb-lg-150 {
    margin-bottom: 6px !important;
  }
  .sl-ml-lg-150 {
    margin-left: 6px !important;
  }
  .sl-mr-lg-150 {
    margin-right: 6px !important;
  }
  .sl-m-lg-200 {
    margin: 8px !important;
  }
  .sl-my-lg-200 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sl-mx-lg-200 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sl-mt-lg-200 {
    margin-top: 8px !important;
  }
  .sl-mb-lg-200 {
    margin-bottom: 8px !important;
  }
  .sl-ml-lg-200 {
    margin-left: 8px !important;
  }
  .sl-mr-lg-200 {
    margin-right: 8px !important;
  }
  .sl-m-lg-250 {
    margin: 10px !important;
  }
  .sl-my-lg-250 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sl-mx-lg-250 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sl-mt-lg-250 {
    margin-top: 10px !important;
  }
  .sl-mb-lg-250 {
    margin-bottom: 10px !important;
  }
  .sl-ml-lg-250 {
    margin-left: 10px !important;
  }
  .sl-mr-lg-250 {
    margin-right: 10px !important;
  }
  .sl-m-lg-300 {
    margin: 12px !important;
  }
  .sl-my-lg-300 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .sl-mx-lg-300 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .sl-mt-lg-300 {
    margin-top: 12px !important;
  }
  .sl-mb-lg-300 {
    margin-bottom: 12px !important;
  }
  .sl-ml-lg-300 {
    margin-left: 12px !important;
  }
  .sl-mr-lg-300 {
    margin-right: 12px !important;
  }
  .sl-m-lg-350 {
    margin: 14px !important;
  }
  .sl-my-lg-350 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .sl-mx-lg-350 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .sl-mt-lg-350 {
    margin-top: 14px !important;
  }
  .sl-mb-lg-350 {
    margin-bottom: 14px !important;
  }
  .sl-ml-lg-350 {
    margin-left: 14px !important;
  }
  .sl-mr-lg-350 {
    margin-right: 14px !important;
  }
  .sl-m-lg-400 {
    margin: 16px !important;
  }
  .sl-my-lg-400 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sl-mx-lg-400 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sl-mt-lg-400 {
    margin-top: 16px !important;
  }
  .sl-mb-lg-400 {
    margin-bottom: 16px !important;
  }
  .sl-ml-lg-400 {
    margin-left: 16px !important;
  }
  .sl-mr-lg-400 {
    margin-right: 16px !important;
  }
  .sl-m-lg-450 {
    margin: 18px !important;
  }
  .sl-my-lg-450 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .sl-mx-lg-450 {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .sl-mt-lg-450 {
    margin-top: 18px !important;
  }
  .sl-mb-lg-450 {
    margin-bottom: 18px !important;
  }
  .sl-ml-lg-450 {
    margin-left: 18px !important;
  }
  .sl-mr-lg-450 {
    margin-right: 18px !important;
  }
  .sl-m-lg-500 {
    margin: 20px !important;
  }
  .sl-my-lg-500 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sl-mx-lg-500 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sl-mt-lg-500 {
    margin-top: 20px !important;
  }
  .sl-mb-lg-500 {
    margin-bottom: 20px !important;
  }
  .sl-ml-lg-500 {
    margin-left: 20px !important;
  }
  .sl-mr-lg-500 {
    margin-right: 20px !important;
  }
  .sl-m-lg-550 {
    margin: 22px !important;
  }
  .sl-my-lg-550 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .sl-mx-lg-550 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .sl-mt-lg-550 {
    margin-top: 22px !important;
  }
  .sl-mb-lg-550 {
    margin-bottom: 22px !important;
  }
  .sl-ml-lg-550 {
    margin-left: 22px !important;
  }
  .sl-mr-lg-550 {
    margin-right: 22px !important;
  }
  .sl-m-lg-600 {
    margin: 24px !important;
  }
  .sl-my-lg-600 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sl-mx-lg-600 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sl-mt-lg-600 {
    margin-top: 24px !important;
  }
  .sl-mb-lg-600 {
    margin-bottom: 24px !important;
  }
  .sl-ml-lg-600 {
    margin-left: 24px !important;
  }
  .sl-mr-lg-600 {
    margin-right: 24px !important;
  }
  .sl-m-lg-650 {
    margin: 28px !important;
  }
  .sl-my-lg-650 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .sl-mx-lg-650 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .sl-mt-lg-650 {
    margin-top: 28px !important;
  }
  .sl-mb-lg-650 {
    margin-bottom: 28px !important;
  }
  .sl-ml-lg-650 {
    margin-left: 28px !important;
  }
  .sl-mr-lg-650 {
    margin-right: 28px !important;
  }
  .sl-m-lg-700 {
    margin: 32px !important;
  }
  .sl-my-lg-700 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sl-mx-lg-700 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sl-mt-lg-700 {
    margin-top: 32px !important;
  }
  .sl-mb-lg-700 {
    margin-bottom: 32px !important;
  }
  .sl-ml-lg-700 {
    margin-left: 32px !important;
  }
  .sl-mr-lg-700 {
    margin-right: 32px !important;
  }
  .sl-m-lg-750 {
    margin: 36px !important;
  }
  .sl-my-lg-750 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .sl-mx-lg-750 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .sl-mt-lg-750 {
    margin-top: 36px !important;
  }
  .sl-mb-lg-750 {
    margin-bottom: 36px !important;
  }
  .sl-ml-lg-750 {
    margin-left: 36px !important;
  }
  .sl-mr-lg-750 {
    margin-right: 36px !important;
  }
  .sl-m-lg-800 {
    margin: 40px !important;
  }
  .sl-my-lg-800 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sl-mx-lg-800 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sl-mt-lg-800 {
    margin-top: 40px !important;
  }
  .sl-mb-lg-800 {
    margin-bottom: 40px !important;
  }
  .sl-ml-lg-800 {
    margin-left: 40px !important;
  }
  .sl-mr-lg-800 {
    margin-right: 40px !important;
  }
  .sl-m-lg-825 {
    margin: 44px !important;
  }
  .sl-my-lg-825 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .sl-mx-lg-825 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }
  .sl-mt-lg-825 {
    margin-top: 44px !important;
  }
  .sl-mb-lg-825 {
    margin-bottom: 44px !important;
  }
  .sl-ml-lg-825 {
    margin-left: 44px !important;
  }
  .sl-mr-lg-825 {
    margin-right: 44px !important;
  }
  .sl-m-lg-850 {
    margin: 48px !important;
  }
  .sl-my-lg-850 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sl-mx-lg-850 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sl-mt-lg-850 {
    margin-top: 48px !important;
  }
  .sl-mb-lg-850 {
    margin-bottom: 48px !important;
  }
  .sl-ml-lg-850 {
    margin-left: 48px !important;
  }
  .sl-mr-lg-850 {
    margin-right: 48px !important;
  }
  .sl-m-lg-900 {
    margin: 56px !important;
  }
  .sl-my-lg-900 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sl-mx-lg-900 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sl-mt-lg-900 {
    margin-top: 56px !important;
  }
  .sl-mb-lg-900 {
    margin-bottom: 56px !important;
  }
  .sl-ml-lg-900 {
    margin-left: 56px !important;
  }
  .sl-mr-lg-900 {
    margin-right: 56px !important;
  }
  .sl-m-lg-925 {
    margin: 60px !important;
  }
  .sl-my-lg-925 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sl-mx-lg-925 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sl-mt-lg-925 {
    margin-top: 60px !important;
  }
  .sl-mb-lg-925 {
    margin-bottom: 60px !important;
  }
  .sl-ml-lg-925 {
    margin-left: 60px !important;
  }
  .sl-mr-lg-925 {
    margin-right: 60px !important;
  }
  .sl-m-lg-950 {
    margin: 64px !important;
  }
  .sl-my-lg-950 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .sl-mx-lg-950 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sl-mt-lg-950 {
    margin-top: 64px !important;
  }
  .sl-mb-lg-950 {
    margin-bottom: 64px !important;
  }
  .sl-ml-lg-950 {
    margin-left: 64px !important;
  }
  .sl-mr-lg-950 {
    margin-right: 64px !important;
  }
  .sl-m-lg-1200 {
    margin: 96px !important;
  }
  .sl-my-lg-1200 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .sl-mx-lg-1200 {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .sl-mt-lg-1200 {
    margin-top: 96px !important;
  }
  .sl-mb-lg-1200 {
    margin-bottom: 96px !important;
  }
  .sl-ml-lg-1200 {
    margin-left: 96px !important;
  }
  .sl-mr-lg-1200 {
    margin-right: 96px !important;
  }
  .sl-m-lg-n50 {
    margin: -2px !important;
  }
  .sl-my-lg-n50 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .sl-mx-lg-n50 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .sl-mt-lg-n50 {
    margin-top: -2px !important;
  }
  .sl-mb-lg-n50 {
    margin-bottom: -2px !important;
  }
  .sl-ml-lg-n50 {
    margin-left: -2px !important;
  }
  .sl-mr-lg-n50 {
    margin-right: -2px !important;
  }
  .sl-m-lg-n100 {
    margin: -4px !important;
  }
  .sl-my-lg-n100 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .sl-mx-lg-n100 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .sl-mt-lg-n100 {
    margin-top: -4px !important;
  }
  .sl-mb-lg-n100 {
    margin-bottom: -4px !important;
  }
  .sl-ml-lg-n100 {
    margin-left: -4px !important;
  }
  .sl-mr-lg-n100 {
    margin-right: -4px !important;
  }
  .sl-m-lg-n150 {
    margin: -6px !important;
  }
  .sl-my-lg-n150 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .sl-mx-lg-n150 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .sl-mt-lg-n150 {
    margin-top: -6px !important;
  }
  .sl-mb-lg-n150 {
    margin-bottom: -6px !important;
  }
  .sl-ml-lg-n150 {
    margin-left: -6px !important;
  }
  .sl-mr-lg-n150 {
    margin-right: -6px !important;
  }
  .sl-m-lg-n200 {
    margin: -8px !important;
  }
  .sl-my-lg-n200 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .sl-mx-lg-n200 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .sl-mt-lg-n200 {
    margin-top: -8px !important;
  }
  .sl-mb-lg-n200 {
    margin-bottom: -8px !important;
  }
  .sl-ml-lg-n200 {
    margin-left: -8px !important;
  }
  .sl-mr-lg-n200 {
    margin-right: -8px !important;
  }
  .sl-m-lg-n250 {
    margin: -10px !important;
  }
  .sl-my-lg-n250 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sl-mx-lg-n250 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sl-mt-lg-n250 {
    margin-top: -10px !important;
  }
  .sl-mb-lg-n250 {
    margin-bottom: -10px !important;
  }
  .sl-ml-lg-n250 {
    margin-left: -10px !important;
  }
  .sl-mr-lg-n250 {
    margin-right: -10px !important;
  }
  .sl-m-lg-n300 {
    margin: -12px !important;
  }
  .sl-my-lg-n300 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .sl-mx-lg-n300 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .sl-mt-lg-n300 {
    margin-top: -12px !important;
  }
  .sl-mb-lg-n300 {
    margin-bottom: -12px !important;
  }
  .sl-ml-lg-n300 {
    margin-left: -12px !important;
  }
  .sl-mr-lg-n300 {
    margin-right: -12px !important;
  }
  .sl-m-lg-n350 {
    margin: -14px !important;
  }
  .sl-my-lg-n350 {
    margin-top: -14px !important;
    margin-bottom: -14px !important;
  }
  .sl-mx-lg-n350 {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
  .sl-mt-lg-n350 {
    margin-top: -14px !important;
  }
  .sl-mb-lg-n350 {
    margin-bottom: -14px !important;
  }
  .sl-ml-lg-n350 {
    margin-left: -14px !important;
  }
  .sl-mr-lg-n350 {
    margin-right: -14px !important;
  }
  .sl-m-lg-n400 {
    margin: -16px !important;
  }
  .sl-my-lg-n400 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .sl-mx-lg-n400 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .sl-mt-lg-n400 {
    margin-top: -16px !important;
  }
  .sl-mb-lg-n400 {
    margin-bottom: -16px !important;
  }
  .sl-ml-lg-n400 {
    margin-left: -16px !important;
  }
  .sl-mr-lg-n400 {
    margin-right: -16px !important;
  }
  .sl-m-lg-n450 {
    margin: -18px !important;
  }
  .sl-my-lg-n450 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .sl-mx-lg-n450 {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .sl-mt-lg-n450 {
    margin-top: -18px !important;
  }
  .sl-mb-lg-n450 {
    margin-bottom: -18px !important;
  }
  .sl-ml-lg-n450 {
    margin-left: -18px !important;
  }
  .sl-mr-lg-n450 {
    margin-right: -18px !important;
  }
  .sl-m-lg-n500 {
    margin: -20px !important;
  }
  .sl-my-lg-n500 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sl-mx-lg-n500 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sl-mt-lg-n500 {
    margin-top: -20px !important;
  }
  .sl-mb-lg-n500 {
    margin-bottom: -20px !important;
  }
  .sl-ml-lg-n500 {
    margin-left: -20px !important;
  }
  .sl-mr-lg-n500 {
    margin-right: -20px !important;
  }
  .sl-m-lg-n550 {
    margin: -22px !important;
  }
  .sl-my-lg-n550 {
    margin-top: -22px !important;
    margin-bottom: -22px !important;
  }
  .sl-mx-lg-n550 {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
  .sl-mt-lg-n550 {
    margin-top: -22px !important;
  }
  .sl-mb-lg-n550 {
    margin-bottom: -22px !important;
  }
  .sl-ml-lg-n550 {
    margin-left: -22px !important;
  }
  .sl-mr-lg-n550 {
    margin-right: -22px !important;
  }
  .sl-m-lg-n600 {
    margin: -24px !important;
  }
  .sl-my-lg-n600 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .sl-mx-lg-n600 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .sl-mt-lg-n600 {
    margin-top: -24px !important;
  }
  .sl-mb-lg-n600 {
    margin-bottom: -24px !important;
  }
  .sl-ml-lg-n600 {
    margin-left: -24px !important;
  }
  .sl-mr-lg-n600 {
    margin-right: -24px !important;
  }
  .sl-m-lg-n650 {
    margin: -28px !important;
  }
  .sl-my-lg-n650 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .sl-mx-lg-n650 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }
  .sl-mt-lg-n650 {
    margin-top: -28px !important;
  }
  .sl-mb-lg-n650 {
    margin-bottom: -28px !important;
  }
  .sl-ml-lg-n650 {
    margin-left: -28px !important;
  }
  .sl-mr-lg-n650 {
    margin-right: -28px !important;
  }
  .sl-m-lg-n700 {
    margin: -32px !important;
  }
  .sl-my-lg-n700 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .sl-mx-lg-n700 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }
  .sl-mt-lg-n700 {
    margin-top: -32px !important;
  }
  .sl-mb-lg-n700 {
    margin-bottom: -32px !important;
  }
  .sl-ml-lg-n700 {
    margin-left: -32px !important;
  }
  .sl-mr-lg-n700 {
    margin-right: -32px !important;
  }
  .sl-m-lg-n750 {
    margin: -36px !important;
  }
  .sl-my-lg-n750 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .sl-mx-lg-n750 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }
  .sl-mt-lg-n750 {
    margin-top: -36px !important;
  }
  .sl-mb-lg-n750 {
    margin-bottom: -36px !important;
  }
  .sl-ml-lg-n750 {
    margin-left: -36px !important;
  }
  .sl-mr-lg-n750 {
    margin-right: -36px !important;
  }
  .sl-m-lg-n800 {
    margin: -40px !important;
  }
  .sl-my-lg-n800 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sl-mx-lg-n800 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sl-mt-lg-n800 {
    margin-top: -40px !important;
  }
  .sl-mb-lg-n800 {
    margin-bottom: -40px !important;
  }
  .sl-ml-lg-n800 {
    margin-left: -40px !important;
  }
  .sl-mr-lg-n800 {
    margin-right: -40px !important;
  }
  .sl-m-lg-n850 {
    margin: -48px !important;
  }
  .sl-my-lg-n850 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .sl-mx-lg-n850 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }
  .sl-mt-lg-n850 {
    margin-top: -48px !important;
  }
  .sl-mb-lg-n850 {
    margin-bottom: -48px !important;
  }
  .sl-ml-lg-n850 {
    margin-left: -48px !important;
  }
  .sl-mr-lg-n850 {
    margin-right: -48px !important;
  }
  .sl-m-lg-n900 {
    margin: -56px !important;
  }
  .sl-my-lg-n900 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .sl-mx-lg-n900 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }
  .sl-mt-lg-n900 {
    margin-top: -56px !important;
  }
  .sl-mb-lg-n900 {
    margin-bottom: -56px !important;
  }
  .sl-ml-lg-n900 {
    margin-left: -56px !important;
  }
  .sl-mr-lg-n900 {
    margin-right: -56px !important;
  }
  .sl-m-lg-n925 {
    margin: -60px !important;
  }
  .sl-my-lg-n925 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .sl-mx-lg-n925 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sl-mt-lg-n925 {
    margin-top: -60px !important;
  }
  .sl-mb-lg-n925 {
    margin-bottom: -60px !important;
  }
  .sl-ml-lg-n925 {
    margin-left: -60px !important;
  }
  .sl-mr-lg-n925 {
    margin-right: -60px !important;
  }
  .sl-m-lg-n950 {
    margin: -64px !important;
  }
  .sl-my-lg-n950 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .sl-mx-lg-n950 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }
  .sl-mt-lg-n950 {
    margin-top: -64px !important;
  }
  .sl-mb-lg-n950 {
    margin-bottom: -64px !important;
  }
  .sl-ml-lg-n950 {
    margin-left: -64px !important;
  }
  .sl-mr-lg-n950 {
    margin-right: -64px !important;
  }
  .sl-m-lg-n1200 {
    margin: -96px !important;
  }
  .sl-my-lg-n1200 {
    margin-top: -96px !important;
    margin-bottom: -96px !important;
  }
  .sl-mx-lg-n1200 {
    margin-left: -96px !important;
    margin-right: -96px !important;
  }
  .sl-mt-lg-n1200 {
    margin-top: -96px !important;
  }
  .sl-mb-lg-n1200 {
    margin-bottom: -96px !important;
  }
  .sl-ml-lg-n1200 {
    margin-left: -96px !important;
  }
  .sl-mr-lg-n1200 {
    margin-right: -96px !important;
  }
  .sl-m-lg-auto {
    margin: auto !important;
  }
  .sl-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .sl-mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sl-mt-lg-auto {
    margin-top: auto !important;
  }
  .sl-mb-lg-auto {
    margin-bottom: auto !important;
  }
  .sl-ml-lg-auto {
    margin-left: auto !important;
  }
  .sl-mr-lg-auto {
    margin-right: auto !important;
  }
  .sl-p-lg-0 {
    padding: 0px !important;
  }
  .sl-py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sl-px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sl-pt-lg-0 {
    padding-top: 0px !important;
  }
  .sl-pb-lg-0 {
    padding-bottom: 0px !important;
  }
  .sl-pl-lg-0 {
    padding-left: 0px !important;
  }
  .sl-pr-lg-0 {
    padding-right: 0px !important;
  }
  .sl-p-lg-50 {
    padding: 2px !important;
  }
  .sl-py-lg-50 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .sl-px-lg-50 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .sl-pt-lg-50 {
    padding-top: 2px !important;
  }
  .sl-pb-lg-50 {
    padding-bottom: 2px !important;
  }
  .sl-pl-lg-50 {
    padding-left: 2px !important;
  }
  .sl-pr-lg-50 {
    padding-right: 2px !important;
  }
  .sl-p-lg-100 {
    padding: 4px !important;
  }
  .sl-py-lg-100 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sl-px-lg-100 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sl-pt-lg-100 {
    padding-top: 4px !important;
  }
  .sl-pb-lg-100 {
    padding-bottom: 4px !important;
  }
  .sl-pl-lg-100 {
    padding-left: 4px !important;
  }
  .sl-pr-lg-100 {
    padding-right: 4px !important;
  }
  .sl-p-lg-150 {
    padding: 6px !important;
  }
  .sl-py-lg-150 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .sl-px-lg-150 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .sl-pt-lg-150 {
    padding-top: 6px !important;
  }
  .sl-pb-lg-150 {
    padding-bottom: 6px !important;
  }
  .sl-pl-lg-150 {
    padding-left: 6px !important;
  }
  .sl-pr-lg-150 {
    padding-right: 6px !important;
  }
  .sl-p-lg-200 {
    padding: 8px !important;
  }
  .sl-py-lg-200 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sl-px-lg-200 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sl-pt-lg-200 {
    padding-top: 8px !important;
  }
  .sl-pb-lg-200 {
    padding-bottom: 8px !important;
  }
  .sl-pl-lg-200 {
    padding-left: 8px !important;
  }
  .sl-pr-lg-200 {
    padding-right: 8px !important;
  }
  .sl-p-lg-250 {
    padding: 10px !important;
  }
  .sl-py-lg-250 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sl-px-lg-250 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sl-pt-lg-250 {
    padding-top: 10px !important;
  }
  .sl-pb-lg-250 {
    padding-bottom: 10px !important;
  }
  .sl-pl-lg-250 {
    padding-left: 10px !important;
  }
  .sl-pr-lg-250 {
    padding-right: 10px !important;
  }
  .sl-p-lg-300 {
    padding: 12px !important;
  }
  .sl-py-lg-300 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sl-px-lg-300 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sl-pt-lg-300 {
    padding-top: 12px !important;
  }
  .sl-pb-lg-300 {
    padding-bottom: 12px !important;
  }
  .sl-pl-lg-300 {
    padding-left: 12px !important;
  }
  .sl-pr-lg-300 {
    padding-right: 12px !important;
  }
  .sl-p-lg-350 {
    padding: 14px !important;
  }
  .sl-py-lg-350 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .sl-px-lg-350 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sl-pt-lg-350 {
    padding-top: 14px !important;
  }
  .sl-pb-lg-350 {
    padding-bottom: 14px !important;
  }
  .sl-pl-lg-350 {
    padding-left: 14px !important;
  }
  .sl-pr-lg-350 {
    padding-right: 14px !important;
  }
  .sl-p-lg-400 {
    padding: 16px !important;
  }
  .sl-py-lg-400 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sl-px-lg-400 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sl-pt-lg-400 {
    padding-top: 16px !important;
  }
  .sl-pb-lg-400 {
    padding-bottom: 16px !important;
  }
  .sl-pl-lg-400 {
    padding-left: 16px !important;
  }
  .sl-pr-lg-400 {
    padding-right: 16px !important;
  }
  .sl-p-lg-450 {
    padding: 18px !important;
  }
  .sl-py-lg-450 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .sl-px-lg-450 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .sl-pt-lg-450 {
    padding-top: 18px !important;
  }
  .sl-pb-lg-450 {
    padding-bottom: 18px !important;
  }
  .sl-pl-lg-450 {
    padding-left: 18px !important;
  }
  .sl-pr-lg-450 {
    padding-right: 18px !important;
  }
  .sl-p-lg-500 {
    padding: 20px !important;
  }
  .sl-py-lg-500 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sl-px-lg-500 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sl-pt-lg-500 {
    padding-top: 20px !important;
  }
  .sl-pb-lg-500 {
    padding-bottom: 20px !important;
  }
  .sl-pl-lg-500 {
    padding-left: 20px !important;
  }
  .sl-pr-lg-500 {
    padding-right: 20px !important;
  }
  .sl-p-lg-550 {
    padding: 22px !important;
  }
  .sl-py-lg-550 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .sl-px-lg-550 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .sl-pt-lg-550 {
    padding-top: 22px !important;
  }
  .sl-pb-lg-550 {
    padding-bottom: 22px !important;
  }
  .sl-pl-lg-550 {
    padding-left: 22px !important;
  }
  .sl-pr-lg-550 {
    padding-right: 22px !important;
  }
  .sl-p-lg-600 {
    padding: 24px !important;
  }
  .sl-py-lg-600 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sl-px-lg-600 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sl-pt-lg-600 {
    padding-top: 24px !important;
  }
  .sl-pb-lg-600 {
    padding-bottom: 24px !important;
  }
  .sl-pl-lg-600 {
    padding-left: 24px !important;
  }
  .sl-pr-lg-600 {
    padding-right: 24px !important;
  }
  .sl-p-lg-650 {
    padding: 28px !important;
  }
  .sl-py-lg-650 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sl-px-lg-650 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sl-pt-lg-650 {
    padding-top: 28px !important;
  }
  .sl-pb-lg-650 {
    padding-bottom: 28px !important;
  }
  .sl-pl-lg-650 {
    padding-left: 28px !important;
  }
  .sl-pr-lg-650 {
    padding-right: 28px !important;
  }
  .sl-p-lg-700 {
    padding: 32px !important;
  }
  .sl-py-lg-700 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sl-px-lg-700 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sl-pt-lg-700 {
    padding-top: 32px !important;
  }
  .sl-pb-lg-700 {
    padding-bottom: 32px !important;
  }
  .sl-pl-lg-700 {
    padding-left: 32px !important;
  }
  .sl-pr-lg-700 {
    padding-right: 32px !important;
  }
  .sl-p-lg-750 {
    padding: 36px !important;
  }
  .sl-py-lg-750 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .sl-px-lg-750 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .sl-pt-lg-750 {
    padding-top: 36px !important;
  }
  .sl-pb-lg-750 {
    padding-bottom: 36px !important;
  }
  .sl-pl-lg-750 {
    padding-left: 36px !important;
  }
  .sl-pr-lg-750 {
    padding-right: 36px !important;
  }
  .sl-p-lg-800 {
    padding: 40px !important;
  }
  .sl-py-lg-800 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sl-px-lg-800 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sl-pt-lg-800 {
    padding-top: 40px !important;
  }
  .sl-pb-lg-800 {
    padding-bottom: 40px !important;
  }
  .sl-pl-lg-800 {
    padding-left: 40px !important;
  }
  .sl-pr-lg-800 {
    padding-right: 40px !important;
  }
  .sl-p-lg-825 {
    padding: 44px !important;
  }
  .sl-py-lg-825 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .sl-px-lg-825 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .sl-pt-lg-825 {
    padding-top: 44px !important;
  }
  .sl-pb-lg-825 {
    padding-bottom: 44px !important;
  }
  .sl-pl-lg-825 {
    padding-left: 44px !important;
  }
  .sl-pr-lg-825 {
    padding-right: 44px !important;
  }
  .sl-p-lg-850 {
    padding: 48px !important;
  }
  .sl-py-lg-850 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sl-px-lg-850 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sl-pt-lg-850 {
    padding-top: 48px !important;
  }
  .sl-pb-lg-850 {
    padding-bottom: 48px !important;
  }
  .sl-pl-lg-850 {
    padding-left: 48px !important;
  }
  .sl-pr-lg-850 {
    padding-right: 48px !important;
  }
  .sl-p-lg-900 {
    padding: 56px !important;
  }
  .sl-py-lg-900 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sl-px-lg-900 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sl-pt-lg-900 {
    padding-top: 56px !important;
  }
  .sl-pb-lg-900 {
    padding-bottom: 56px !important;
  }
  .sl-pl-lg-900 {
    padding-left: 56px !important;
  }
  .sl-pr-lg-900 {
    padding-right: 56px !important;
  }
  .sl-p-lg-925 {
    padding: 60px !important;
  }
  .sl-py-lg-925 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sl-px-lg-925 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sl-pt-lg-925 {
    padding-top: 60px !important;
  }
  .sl-pb-lg-925 {
    padding-bottom: 60px !important;
  }
  .sl-pl-lg-925 {
    padding-left: 60px !important;
  }
  .sl-pr-lg-925 {
    padding-right: 60px !important;
  }
  .sl-p-lg-950 {
    padding: 64px !important;
  }
  .sl-py-lg-950 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sl-px-lg-950 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sl-pt-lg-950 {
    padding-top: 64px !important;
  }
  .sl-pb-lg-950 {
    padding-bottom: 64px !important;
  }
  .sl-pl-lg-950 {
    padding-left: 64px !important;
  }
  .sl-pr-lg-950 {
    padding-right: 64px !important;
  }
  .sl-p-lg-1200 {
    padding: 96px !important;
  }
  .sl-py-lg-1200 {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .sl-px-lg-1200 {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .sl-pt-lg-1200 {
    padding-top: 96px !important;
  }
  .sl-pb-lg-1200 {
    padding-bottom: 96px !important;
  }
  .sl-pl-lg-1200 {
    padding-left: 96px !important;
  }
  .sl-pr-lg-1200 {
    padding-right: 96px !important;
  }
  .sl-p-lg-n50 {
    padding: -2px !important;
  }
  .sl-py-lg-n50 {
    padding-top: -2px !important;
    padding-bottom: -2px !important;
  }
  .sl-px-lg-n50 {
    padding-left: -2px !important;
    padding-right: -2px !important;
  }
  .sl-pt-lg-n50 {
    padding-top: -2px !important;
  }
  .sl-pb-lg-n50 {
    padding-bottom: -2px !important;
  }
  .sl-pl-lg-n50 {
    padding-left: -2px !important;
  }
  .sl-pr-lg-n50 {
    padding-right: -2px !important;
  }
  .sl-p-lg-n100 {
    padding: -4px !important;
  }
  .sl-py-lg-n100 {
    padding-top: -4px !important;
    padding-bottom: -4px !important;
  }
  .sl-px-lg-n100 {
    padding-left: -4px !important;
    padding-right: -4px !important;
  }
  .sl-pt-lg-n100 {
    padding-top: -4px !important;
  }
  .sl-pb-lg-n100 {
    padding-bottom: -4px !important;
  }
  .sl-pl-lg-n100 {
    padding-left: -4px !important;
  }
  .sl-pr-lg-n100 {
    padding-right: -4px !important;
  }
  .sl-p-lg-n150 {
    padding: -6px !important;
  }
  .sl-py-lg-n150 {
    padding-top: -6px !important;
    padding-bottom: -6px !important;
  }
  .sl-px-lg-n150 {
    padding-left: -6px !important;
    padding-right: -6px !important;
  }
  .sl-pt-lg-n150 {
    padding-top: -6px !important;
  }
  .sl-pb-lg-n150 {
    padding-bottom: -6px !important;
  }
  .sl-pl-lg-n150 {
    padding-left: -6px !important;
  }
  .sl-pr-lg-n150 {
    padding-right: -6px !important;
  }
  .sl-p-lg-n200 {
    padding: -8px !important;
  }
  .sl-py-lg-n200 {
    padding-top: -8px !important;
    padding-bottom: -8px !important;
  }
  .sl-px-lg-n200 {
    padding-left: -8px !important;
    padding-right: -8px !important;
  }
  .sl-pt-lg-n200 {
    padding-top: -8px !important;
  }
  .sl-pb-lg-n200 {
    padding-bottom: -8px !important;
  }
  .sl-pl-lg-n200 {
    padding-left: -8px !important;
  }
  .sl-pr-lg-n200 {
    padding-right: -8px !important;
  }
  .sl-p-lg-n250 {
    padding: -10px !important;
  }
  .sl-py-lg-n250 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .sl-px-lg-n250 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
  .sl-pt-lg-n250 {
    padding-top: -10px !important;
  }
  .sl-pb-lg-n250 {
    padding-bottom: -10px !important;
  }
  .sl-pl-lg-n250 {
    padding-left: -10px !important;
  }
  .sl-pr-lg-n250 {
    padding-right: -10px !important;
  }
  .sl-p-lg-n300 {
    padding: -12px !important;
  }
  .sl-py-lg-n300 {
    padding-top: -12px !important;
    padding-bottom: -12px !important;
  }
  .sl-px-lg-n300 {
    padding-left: -12px !important;
    padding-right: -12px !important;
  }
  .sl-pt-lg-n300 {
    padding-top: -12px !important;
  }
  .sl-pb-lg-n300 {
    padding-bottom: -12px !important;
  }
  .sl-pl-lg-n300 {
    padding-left: -12px !important;
  }
  .sl-pr-lg-n300 {
    padding-right: -12px !important;
  }
  .sl-p-lg-n350 {
    padding: -14px !important;
  }
  .sl-py-lg-n350 {
    padding-top: -14px !important;
    padding-bottom: -14px !important;
  }
  .sl-px-lg-n350 {
    padding-left: -14px !important;
    padding-right: -14px !important;
  }
  .sl-pt-lg-n350 {
    padding-top: -14px !important;
  }
  .sl-pb-lg-n350 {
    padding-bottom: -14px !important;
  }
  .sl-pl-lg-n350 {
    padding-left: -14px !important;
  }
  .sl-pr-lg-n350 {
    padding-right: -14px !important;
  }
  .sl-p-lg-n400 {
    padding: -16px !important;
  }
  .sl-py-lg-n400 {
    padding-top: -16px !important;
    padding-bottom: -16px !important;
  }
  .sl-px-lg-n400 {
    padding-left: -16px !important;
    padding-right: -16px !important;
  }
  .sl-pt-lg-n400 {
    padding-top: -16px !important;
  }
  .sl-pb-lg-n400 {
    padding-bottom: -16px !important;
  }
  .sl-pl-lg-n400 {
    padding-left: -16px !important;
  }
  .sl-pr-lg-n400 {
    padding-right: -16px !important;
  }
  .sl-p-lg-n450 {
    padding: -18px !important;
  }
  .sl-py-lg-n450 {
    padding-top: -18px !important;
    padding-bottom: -18px !important;
  }
  .sl-px-lg-n450 {
    padding-left: -18px !important;
    padding-right: -18px !important;
  }
  .sl-pt-lg-n450 {
    padding-top: -18px !important;
  }
  .sl-pb-lg-n450 {
    padding-bottom: -18px !important;
  }
  .sl-pl-lg-n450 {
    padding-left: -18px !important;
  }
  .sl-pr-lg-n450 {
    padding-right: -18px !important;
  }
  .sl-p-lg-n500 {
    padding: -20px !important;
  }
  .sl-py-lg-n500 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .sl-px-lg-n500 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .sl-pt-lg-n500 {
    padding-top: -20px !important;
  }
  .sl-pb-lg-n500 {
    padding-bottom: -20px !important;
  }
  .sl-pl-lg-n500 {
    padding-left: -20px !important;
  }
  .sl-pr-lg-n500 {
    padding-right: -20px !important;
  }
  .sl-p-lg-n550 {
    padding: -22px !important;
  }
  .sl-py-lg-n550 {
    padding-top: -22px !important;
    padding-bottom: -22px !important;
  }
  .sl-px-lg-n550 {
    padding-left: -22px !important;
    padding-right: -22px !important;
  }
  .sl-pt-lg-n550 {
    padding-top: -22px !important;
  }
  .sl-pb-lg-n550 {
    padding-bottom: -22px !important;
  }
  .sl-pl-lg-n550 {
    padding-left: -22px !important;
  }
  .sl-pr-lg-n550 {
    padding-right: -22px !important;
  }
  .sl-p-lg-n600 {
    padding: -24px !important;
  }
  .sl-py-lg-n600 {
    padding-top: -24px !important;
    padding-bottom: -24px !important;
  }
  .sl-px-lg-n600 {
    padding-left: -24px !important;
    padding-right: -24px !important;
  }
  .sl-pt-lg-n600 {
    padding-top: -24px !important;
  }
  .sl-pb-lg-n600 {
    padding-bottom: -24px !important;
  }
  .sl-pl-lg-n600 {
    padding-left: -24px !important;
  }
  .sl-pr-lg-n600 {
    padding-right: -24px !important;
  }
  .sl-p-lg-n650 {
    padding: -28px !important;
  }
  .sl-py-lg-n650 {
    padding-top: -28px !important;
    padding-bottom: -28px !important;
  }
  .sl-px-lg-n650 {
    padding-left: -28px !important;
    padding-right: -28px !important;
  }
  .sl-pt-lg-n650 {
    padding-top: -28px !important;
  }
  .sl-pb-lg-n650 {
    padding-bottom: -28px !important;
  }
  .sl-pl-lg-n650 {
    padding-left: -28px !important;
  }
  .sl-pr-lg-n650 {
    padding-right: -28px !important;
  }
  .sl-p-lg-n700 {
    padding: -32px !important;
  }
  .sl-py-lg-n700 {
    padding-top: -32px !important;
    padding-bottom: -32px !important;
  }
  .sl-px-lg-n700 {
    padding-left: -32px !important;
    padding-right: -32px !important;
  }
  .sl-pt-lg-n700 {
    padding-top: -32px !important;
  }
  .sl-pb-lg-n700 {
    padding-bottom: -32px !important;
  }
  .sl-pl-lg-n700 {
    padding-left: -32px !important;
  }
  .sl-pr-lg-n700 {
    padding-right: -32px !important;
  }
  .sl-p-lg-n750 {
    padding: -36px !important;
  }
  .sl-py-lg-n750 {
    padding-top: -36px !important;
    padding-bottom: -36px !important;
  }
  .sl-px-lg-n750 {
    padding-left: -36px !important;
    padding-right: -36px !important;
  }
  .sl-pt-lg-n750 {
    padding-top: -36px !important;
  }
  .sl-pb-lg-n750 {
    padding-bottom: -36px !important;
  }
  .sl-pl-lg-n750 {
    padding-left: -36px !important;
  }
  .sl-pr-lg-n750 {
    padding-right: -36px !important;
  }
  .sl-p-lg-n800 {
    padding: -40px !important;
  }
  .sl-py-lg-n800 {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }
  .sl-px-lg-n800 {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }
  .sl-pt-lg-n800 {
    padding-top: -40px !important;
  }
  .sl-pb-lg-n800 {
    padding-bottom: -40px !important;
  }
  .sl-pl-lg-n800 {
    padding-left: -40px !important;
  }
  .sl-pr-lg-n800 {
    padding-right: -40px !important;
  }
  .sl-p-lg-n850 {
    padding: -48px !important;
  }
  .sl-py-lg-n850 {
    padding-top: -48px !important;
    padding-bottom: -48px !important;
  }
  .sl-px-lg-n850 {
    padding-left: -48px !important;
    padding-right: -48px !important;
  }
  .sl-pt-lg-n850 {
    padding-top: -48px !important;
  }
  .sl-pb-lg-n850 {
    padding-bottom: -48px !important;
  }
  .sl-pl-lg-n850 {
    padding-left: -48px !important;
  }
  .sl-pr-lg-n850 {
    padding-right: -48px !important;
  }
  .sl-p-lg-n900 {
    padding: -56px !important;
  }
  .sl-py-lg-n900 {
    padding-top: -56px !important;
    padding-bottom: -56px !important;
  }
  .sl-px-lg-n900 {
    padding-left: -56px !important;
    padding-right: -56px !important;
  }
  .sl-pt-lg-n900 {
    padding-top: -56px !important;
  }
  .sl-pb-lg-n900 {
    padding-bottom: -56px !important;
  }
  .sl-pl-lg-n900 {
    padding-left: -56px !important;
  }
  .sl-pr-lg-n900 {
    padding-right: -56px !important;
  }
  .sl-p-lg-n925 {
    padding: -60px !important;
  }
  .sl-py-lg-n925 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .sl-px-lg-n925 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .sl-pt-lg-n925 {
    padding-top: -60px !important;
  }
  .sl-pb-lg-n925 {
    padding-bottom: -60px !important;
  }
  .sl-pl-lg-n925 {
    padding-left: -60px !important;
  }
  .sl-pr-lg-n925 {
    padding-right: -60px !important;
  }
  .sl-p-lg-n950 {
    padding: -64px !important;
  }
  .sl-py-lg-n950 {
    padding-top: -64px !important;
    padding-bottom: -64px !important;
  }
  .sl-px-lg-n950 {
    padding-left: -64px !important;
    padding-right: -64px !important;
  }
  .sl-pt-lg-n950 {
    padding-top: -64px !important;
  }
  .sl-pb-lg-n950 {
    padding-bottom: -64px !important;
  }
  .sl-pl-lg-n950 {
    padding-left: -64px !important;
  }
  .sl-pr-lg-n950 {
    padding-right: -64px !important;
  }
  .sl-p-lg-n1200 {
    padding: -96px !important;
  }
  .sl-py-lg-n1200 {
    padding-top: -96px !important;
    padding-bottom: -96px !important;
  }
  .sl-px-lg-n1200 {
    padding-left: -96px !important;
    padding-right: -96px !important;
  }
  .sl-pt-lg-n1200 {
    padding-top: -96px !important;
  }
  .sl-pb-lg-n1200 {
    padding-bottom: -96px !important;
  }
  .sl-pl-lg-n1200 {
    padding-left: -96px !important;
  }
  .sl-pr-lg-n1200 {
    padding-right: -96px !important;
  }
  .sl-p-lg-auto {
    padding: auto !important;
  }
  .sl-py-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .sl-px-lg-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .sl-pt-lg-auto {
    padding-top: auto !important;
  }
  .sl-pb-lg-auto {
    padding-bottom: auto !important;
  }
  .sl-pl-lg-auto {
    padding-left: auto !important;
  }
  .sl-pr-lg-auto {
    padding-right: auto !important;
  }
}
@media (min-width: 1700px) {
  .sl-m-xl-0 {
    margin: 0px !important;
  }
  .sl-my-xl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sl-mx-xl-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sl-mt-xl-0 {
    margin-top: 0px !important;
  }
  .sl-mb-xl-0 {
    margin-bottom: 0px !important;
  }
  .sl-ml-xl-0 {
    margin-left: 0px !important;
  }
  .sl-mr-xl-0 {
    margin-right: 0px !important;
  }
  .sl-m-xl-50 {
    margin: 2px !important;
  }
  .sl-my-xl-50 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .sl-mx-xl-50 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .sl-mt-xl-50 {
    margin-top: 2px !important;
  }
  .sl-mb-xl-50 {
    margin-bottom: 2px !important;
  }
  .sl-ml-xl-50 {
    margin-left: 2px !important;
  }
  .sl-mr-xl-50 {
    margin-right: 2px !important;
  }
  .sl-m-xl-100 {
    margin: 4px !important;
  }
  .sl-my-xl-100 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .sl-mx-xl-100 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .sl-mt-xl-100 {
    margin-top: 4px !important;
  }
  .sl-mb-xl-100 {
    margin-bottom: 4px !important;
  }
  .sl-ml-xl-100 {
    margin-left: 4px !important;
  }
  .sl-mr-xl-100 {
    margin-right: 4px !important;
  }
  .sl-m-xl-150 {
    margin: 6px !important;
  }
  .sl-my-xl-150 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .sl-mx-xl-150 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .sl-mt-xl-150 {
    margin-top: 6px !important;
  }
  .sl-mb-xl-150 {
    margin-bottom: 6px !important;
  }
  .sl-ml-xl-150 {
    margin-left: 6px !important;
  }
  .sl-mr-xl-150 {
    margin-right: 6px !important;
  }
  .sl-m-xl-200 {
    margin: 8px !important;
  }
  .sl-my-xl-200 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sl-mx-xl-200 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sl-mt-xl-200 {
    margin-top: 8px !important;
  }
  .sl-mb-xl-200 {
    margin-bottom: 8px !important;
  }
  .sl-ml-xl-200 {
    margin-left: 8px !important;
  }
  .sl-mr-xl-200 {
    margin-right: 8px !important;
  }
  .sl-m-xl-250 {
    margin: 10px !important;
  }
  .sl-my-xl-250 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sl-mx-xl-250 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sl-mt-xl-250 {
    margin-top: 10px !important;
  }
  .sl-mb-xl-250 {
    margin-bottom: 10px !important;
  }
  .sl-ml-xl-250 {
    margin-left: 10px !important;
  }
  .sl-mr-xl-250 {
    margin-right: 10px !important;
  }
  .sl-m-xl-300 {
    margin: 12px !important;
  }
  .sl-my-xl-300 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .sl-mx-xl-300 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .sl-mt-xl-300 {
    margin-top: 12px !important;
  }
  .sl-mb-xl-300 {
    margin-bottom: 12px !important;
  }
  .sl-ml-xl-300 {
    margin-left: 12px !important;
  }
  .sl-mr-xl-300 {
    margin-right: 12px !important;
  }
  .sl-m-xl-350 {
    margin: 14px !important;
  }
  .sl-my-xl-350 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .sl-mx-xl-350 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .sl-mt-xl-350 {
    margin-top: 14px !important;
  }
  .sl-mb-xl-350 {
    margin-bottom: 14px !important;
  }
  .sl-ml-xl-350 {
    margin-left: 14px !important;
  }
  .sl-mr-xl-350 {
    margin-right: 14px !important;
  }
  .sl-m-xl-400 {
    margin: 16px !important;
  }
  .sl-my-xl-400 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sl-mx-xl-400 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sl-mt-xl-400 {
    margin-top: 16px !important;
  }
  .sl-mb-xl-400 {
    margin-bottom: 16px !important;
  }
  .sl-ml-xl-400 {
    margin-left: 16px !important;
  }
  .sl-mr-xl-400 {
    margin-right: 16px !important;
  }
  .sl-m-xl-450 {
    margin: 18px !important;
  }
  .sl-my-xl-450 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .sl-mx-xl-450 {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .sl-mt-xl-450 {
    margin-top: 18px !important;
  }
  .sl-mb-xl-450 {
    margin-bottom: 18px !important;
  }
  .sl-ml-xl-450 {
    margin-left: 18px !important;
  }
  .sl-mr-xl-450 {
    margin-right: 18px !important;
  }
  .sl-m-xl-500 {
    margin: 20px !important;
  }
  .sl-my-xl-500 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sl-mx-xl-500 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sl-mt-xl-500 {
    margin-top: 20px !important;
  }
  .sl-mb-xl-500 {
    margin-bottom: 20px !important;
  }
  .sl-ml-xl-500 {
    margin-left: 20px !important;
  }
  .sl-mr-xl-500 {
    margin-right: 20px !important;
  }
  .sl-m-xl-550 {
    margin: 22px !important;
  }
  .sl-my-xl-550 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .sl-mx-xl-550 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .sl-mt-xl-550 {
    margin-top: 22px !important;
  }
  .sl-mb-xl-550 {
    margin-bottom: 22px !important;
  }
  .sl-ml-xl-550 {
    margin-left: 22px !important;
  }
  .sl-mr-xl-550 {
    margin-right: 22px !important;
  }
  .sl-m-xl-600 {
    margin: 24px !important;
  }
  .sl-my-xl-600 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sl-mx-xl-600 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sl-mt-xl-600 {
    margin-top: 24px !important;
  }
  .sl-mb-xl-600 {
    margin-bottom: 24px !important;
  }
  .sl-ml-xl-600 {
    margin-left: 24px !important;
  }
  .sl-mr-xl-600 {
    margin-right: 24px !important;
  }
  .sl-m-xl-650 {
    margin: 28px !important;
  }
  .sl-my-xl-650 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .sl-mx-xl-650 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .sl-mt-xl-650 {
    margin-top: 28px !important;
  }
  .sl-mb-xl-650 {
    margin-bottom: 28px !important;
  }
  .sl-ml-xl-650 {
    margin-left: 28px !important;
  }
  .sl-mr-xl-650 {
    margin-right: 28px !important;
  }
  .sl-m-xl-700 {
    margin: 32px !important;
  }
  .sl-my-xl-700 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sl-mx-xl-700 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sl-mt-xl-700 {
    margin-top: 32px !important;
  }
  .sl-mb-xl-700 {
    margin-bottom: 32px !important;
  }
  .sl-ml-xl-700 {
    margin-left: 32px !important;
  }
  .sl-mr-xl-700 {
    margin-right: 32px !important;
  }
  .sl-m-xl-750 {
    margin: 36px !important;
  }
  .sl-my-xl-750 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .sl-mx-xl-750 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .sl-mt-xl-750 {
    margin-top: 36px !important;
  }
  .sl-mb-xl-750 {
    margin-bottom: 36px !important;
  }
  .sl-ml-xl-750 {
    margin-left: 36px !important;
  }
  .sl-mr-xl-750 {
    margin-right: 36px !important;
  }
  .sl-m-xl-800 {
    margin: 40px !important;
  }
  .sl-my-xl-800 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sl-mx-xl-800 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sl-mt-xl-800 {
    margin-top: 40px !important;
  }
  .sl-mb-xl-800 {
    margin-bottom: 40px !important;
  }
  .sl-ml-xl-800 {
    margin-left: 40px !important;
  }
  .sl-mr-xl-800 {
    margin-right: 40px !important;
  }
  .sl-m-xl-825 {
    margin: 44px !important;
  }
  .sl-my-xl-825 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .sl-mx-xl-825 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }
  .sl-mt-xl-825 {
    margin-top: 44px !important;
  }
  .sl-mb-xl-825 {
    margin-bottom: 44px !important;
  }
  .sl-ml-xl-825 {
    margin-left: 44px !important;
  }
  .sl-mr-xl-825 {
    margin-right: 44px !important;
  }
  .sl-m-xl-850 {
    margin: 48px !important;
  }
  .sl-my-xl-850 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sl-mx-xl-850 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sl-mt-xl-850 {
    margin-top: 48px !important;
  }
  .sl-mb-xl-850 {
    margin-bottom: 48px !important;
  }
  .sl-ml-xl-850 {
    margin-left: 48px !important;
  }
  .sl-mr-xl-850 {
    margin-right: 48px !important;
  }
  .sl-m-xl-900 {
    margin: 56px !important;
  }
  .sl-my-xl-900 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sl-mx-xl-900 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sl-mt-xl-900 {
    margin-top: 56px !important;
  }
  .sl-mb-xl-900 {
    margin-bottom: 56px !important;
  }
  .sl-ml-xl-900 {
    margin-left: 56px !important;
  }
  .sl-mr-xl-900 {
    margin-right: 56px !important;
  }
  .sl-m-xl-925 {
    margin: 60px !important;
  }
  .sl-my-xl-925 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sl-mx-xl-925 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sl-mt-xl-925 {
    margin-top: 60px !important;
  }
  .sl-mb-xl-925 {
    margin-bottom: 60px !important;
  }
  .sl-ml-xl-925 {
    margin-left: 60px !important;
  }
  .sl-mr-xl-925 {
    margin-right: 60px !important;
  }
  .sl-m-xl-950 {
    margin: 64px !important;
  }
  .sl-my-xl-950 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .sl-mx-xl-950 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sl-mt-xl-950 {
    margin-top: 64px !important;
  }
  .sl-mb-xl-950 {
    margin-bottom: 64px !important;
  }
  .sl-ml-xl-950 {
    margin-left: 64px !important;
  }
  .sl-mr-xl-950 {
    margin-right: 64px !important;
  }
  .sl-m-xl-1200 {
    margin: 96px !important;
  }
  .sl-my-xl-1200 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .sl-mx-xl-1200 {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .sl-mt-xl-1200 {
    margin-top: 96px !important;
  }
  .sl-mb-xl-1200 {
    margin-bottom: 96px !important;
  }
  .sl-ml-xl-1200 {
    margin-left: 96px !important;
  }
  .sl-mr-xl-1200 {
    margin-right: 96px !important;
  }
  .sl-m-xl-n50 {
    margin: -2px !important;
  }
  .sl-my-xl-n50 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .sl-mx-xl-n50 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .sl-mt-xl-n50 {
    margin-top: -2px !important;
  }
  .sl-mb-xl-n50 {
    margin-bottom: -2px !important;
  }
  .sl-ml-xl-n50 {
    margin-left: -2px !important;
  }
  .sl-mr-xl-n50 {
    margin-right: -2px !important;
  }
  .sl-m-xl-n100 {
    margin: -4px !important;
  }
  .sl-my-xl-n100 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .sl-mx-xl-n100 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .sl-mt-xl-n100 {
    margin-top: -4px !important;
  }
  .sl-mb-xl-n100 {
    margin-bottom: -4px !important;
  }
  .sl-ml-xl-n100 {
    margin-left: -4px !important;
  }
  .sl-mr-xl-n100 {
    margin-right: -4px !important;
  }
  .sl-m-xl-n150 {
    margin: -6px !important;
  }
  .sl-my-xl-n150 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .sl-mx-xl-n150 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .sl-mt-xl-n150 {
    margin-top: -6px !important;
  }
  .sl-mb-xl-n150 {
    margin-bottom: -6px !important;
  }
  .sl-ml-xl-n150 {
    margin-left: -6px !important;
  }
  .sl-mr-xl-n150 {
    margin-right: -6px !important;
  }
  .sl-m-xl-n200 {
    margin: -8px !important;
  }
  .sl-my-xl-n200 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .sl-mx-xl-n200 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .sl-mt-xl-n200 {
    margin-top: -8px !important;
  }
  .sl-mb-xl-n200 {
    margin-bottom: -8px !important;
  }
  .sl-ml-xl-n200 {
    margin-left: -8px !important;
  }
  .sl-mr-xl-n200 {
    margin-right: -8px !important;
  }
  .sl-m-xl-n250 {
    margin: -10px !important;
  }
  .sl-my-xl-n250 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sl-mx-xl-n250 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sl-mt-xl-n250 {
    margin-top: -10px !important;
  }
  .sl-mb-xl-n250 {
    margin-bottom: -10px !important;
  }
  .sl-ml-xl-n250 {
    margin-left: -10px !important;
  }
  .sl-mr-xl-n250 {
    margin-right: -10px !important;
  }
  .sl-m-xl-n300 {
    margin: -12px !important;
  }
  .sl-my-xl-n300 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .sl-mx-xl-n300 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .sl-mt-xl-n300 {
    margin-top: -12px !important;
  }
  .sl-mb-xl-n300 {
    margin-bottom: -12px !important;
  }
  .sl-ml-xl-n300 {
    margin-left: -12px !important;
  }
  .sl-mr-xl-n300 {
    margin-right: -12px !important;
  }
  .sl-m-xl-n350 {
    margin: -14px !important;
  }
  .sl-my-xl-n350 {
    margin-top: -14px !important;
    margin-bottom: -14px !important;
  }
  .sl-mx-xl-n350 {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
  .sl-mt-xl-n350 {
    margin-top: -14px !important;
  }
  .sl-mb-xl-n350 {
    margin-bottom: -14px !important;
  }
  .sl-ml-xl-n350 {
    margin-left: -14px !important;
  }
  .sl-mr-xl-n350 {
    margin-right: -14px !important;
  }
  .sl-m-xl-n400 {
    margin: -16px !important;
  }
  .sl-my-xl-n400 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .sl-mx-xl-n400 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .sl-mt-xl-n400 {
    margin-top: -16px !important;
  }
  .sl-mb-xl-n400 {
    margin-bottom: -16px !important;
  }
  .sl-ml-xl-n400 {
    margin-left: -16px !important;
  }
  .sl-mr-xl-n400 {
    margin-right: -16px !important;
  }
  .sl-m-xl-n450 {
    margin: -18px !important;
  }
  .sl-my-xl-n450 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .sl-mx-xl-n450 {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .sl-mt-xl-n450 {
    margin-top: -18px !important;
  }
  .sl-mb-xl-n450 {
    margin-bottom: -18px !important;
  }
  .sl-ml-xl-n450 {
    margin-left: -18px !important;
  }
  .sl-mr-xl-n450 {
    margin-right: -18px !important;
  }
  .sl-m-xl-n500 {
    margin: -20px !important;
  }
  .sl-my-xl-n500 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sl-mx-xl-n500 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sl-mt-xl-n500 {
    margin-top: -20px !important;
  }
  .sl-mb-xl-n500 {
    margin-bottom: -20px !important;
  }
  .sl-ml-xl-n500 {
    margin-left: -20px !important;
  }
  .sl-mr-xl-n500 {
    margin-right: -20px !important;
  }
  .sl-m-xl-n550 {
    margin: -22px !important;
  }
  .sl-my-xl-n550 {
    margin-top: -22px !important;
    margin-bottom: -22px !important;
  }
  .sl-mx-xl-n550 {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
  .sl-mt-xl-n550 {
    margin-top: -22px !important;
  }
  .sl-mb-xl-n550 {
    margin-bottom: -22px !important;
  }
  .sl-ml-xl-n550 {
    margin-left: -22px !important;
  }
  .sl-mr-xl-n550 {
    margin-right: -22px !important;
  }
  .sl-m-xl-n600 {
    margin: -24px !important;
  }
  .sl-my-xl-n600 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .sl-mx-xl-n600 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .sl-mt-xl-n600 {
    margin-top: -24px !important;
  }
  .sl-mb-xl-n600 {
    margin-bottom: -24px !important;
  }
  .sl-ml-xl-n600 {
    margin-left: -24px !important;
  }
  .sl-mr-xl-n600 {
    margin-right: -24px !important;
  }
  .sl-m-xl-n650 {
    margin: -28px !important;
  }
  .sl-my-xl-n650 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .sl-mx-xl-n650 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }
  .sl-mt-xl-n650 {
    margin-top: -28px !important;
  }
  .sl-mb-xl-n650 {
    margin-bottom: -28px !important;
  }
  .sl-ml-xl-n650 {
    margin-left: -28px !important;
  }
  .sl-mr-xl-n650 {
    margin-right: -28px !important;
  }
  .sl-m-xl-n700 {
    margin: -32px !important;
  }
  .sl-my-xl-n700 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .sl-mx-xl-n700 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }
  .sl-mt-xl-n700 {
    margin-top: -32px !important;
  }
  .sl-mb-xl-n700 {
    margin-bottom: -32px !important;
  }
  .sl-ml-xl-n700 {
    margin-left: -32px !important;
  }
  .sl-mr-xl-n700 {
    margin-right: -32px !important;
  }
  .sl-m-xl-n750 {
    margin: -36px !important;
  }
  .sl-my-xl-n750 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .sl-mx-xl-n750 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }
  .sl-mt-xl-n750 {
    margin-top: -36px !important;
  }
  .sl-mb-xl-n750 {
    margin-bottom: -36px !important;
  }
  .sl-ml-xl-n750 {
    margin-left: -36px !important;
  }
  .sl-mr-xl-n750 {
    margin-right: -36px !important;
  }
  .sl-m-xl-n800 {
    margin: -40px !important;
  }
  .sl-my-xl-n800 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sl-mx-xl-n800 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sl-mt-xl-n800 {
    margin-top: -40px !important;
  }
  .sl-mb-xl-n800 {
    margin-bottom: -40px !important;
  }
  .sl-ml-xl-n800 {
    margin-left: -40px !important;
  }
  .sl-mr-xl-n800 {
    margin-right: -40px !important;
  }
  .sl-m-xl-n850 {
    margin: -48px !important;
  }
  .sl-my-xl-n850 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .sl-mx-xl-n850 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }
  .sl-mt-xl-n850 {
    margin-top: -48px !important;
  }
  .sl-mb-xl-n850 {
    margin-bottom: -48px !important;
  }
  .sl-ml-xl-n850 {
    margin-left: -48px !important;
  }
  .sl-mr-xl-n850 {
    margin-right: -48px !important;
  }
  .sl-m-xl-n900 {
    margin: -56px !important;
  }
  .sl-my-xl-n900 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .sl-mx-xl-n900 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }
  .sl-mt-xl-n900 {
    margin-top: -56px !important;
  }
  .sl-mb-xl-n900 {
    margin-bottom: -56px !important;
  }
  .sl-ml-xl-n900 {
    margin-left: -56px !important;
  }
  .sl-mr-xl-n900 {
    margin-right: -56px !important;
  }
  .sl-m-xl-n925 {
    margin: -60px !important;
  }
  .sl-my-xl-n925 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .sl-mx-xl-n925 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sl-mt-xl-n925 {
    margin-top: -60px !important;
  }
  .sl-mb-xl-n925 {
    margin-bottom: -60px !important;
  }
  .sl-ml-xl-n925 {
    margin-left: -60px !important;
  }
  .sl-mr-xl-n925 {
    margin-right: -60px !important;
  }
  .sl-m-xl-n950 {
    margin: -64px !important;
  }
  .sl-my-xl-n950 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .sl-mx-xl-n950 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }
  .sl-mt-xl-n950 {
    margin-top: -64px !important;
  }
  .sl-mb-xl-n950 {
    margin-bottom: -64px !important;
  }
  .sl-ml-xl-n950 {
    margin-left: -64px !important;
  }
  .sl-mr-xl-n950 {
    margin-right: -64px !important;
  }
  .sl-m-xl-n1200 {
    margin: -96px !important;
  }
  .sl-my-xl-n1200 {
    margin-top: -96px !important;
    margin-bottom: -96px !important;
  }
  .sl-mx-xl-n1200 {
    margin-left: -96px !important;
    margin-right: -96px !important;
  }
  .sl-mt-xl-n1200 {
    margin-top: -96px !important;
  }
  .sl-mb-xl-n1200 {
    margin-bottom: -96px !important;
  }
  .sl-ml-xl-n1200 {
    margin-left: -96px !important;
  }
  .sl-mr-xl-n1200 {
    margin-right: -96px !important;
  }
  .sl-m-xl-auto {
    margin: auto !important;
  }
  .sl-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .sl-mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sl-mt-xl-auto {
    margin-top: auto !important;
  }
  .sl-mb-xl-auto {
    margin-bottom: auto !important;
  }
  .sl-ml-xl-auto {
    margin-left: auto !important;
  }
  .sl-mr-xl-auto {
    margin-right: auto !important;
  }
  .sl-p-xl-0 {
    padding: 0px !important;
  }
  .sl-py-xl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sl-px-xl-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sl-pt-xl-0 {
    padding-top: 0px !important;
  }
  .sl-pb-xl-0 {
    padding-bottom: 0px !important;
  }
  .sl-pl-xl-0 {
    padding-left: 0px !important;
  }
  .sl-pr-xl-0 {
    padding-right: 0px !important;
  }
  .sl-p-xl-50 {
    padding: 2px !important;
  }
  .sl-py-xl-50 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .sl-px-xl-50 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .sl-pt-xl-50 {
    padding-top: 2px !important;
  }
  .sl-pb-xl-50 {
    padding-bottom: 2px !important;
  }
  .sl-pl-xl-50 {
    padding-left: 2px !important;
  }
  .sl-pr-xl-50 {
    padding-right: 2px !important;
  }
  .sl-p-xl-100 {
    padding: 4px !important;
  }
  .sl-py-xl-100 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sl-px-xl-100 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sl-pt-xl-100 {
    padding-top: 4px !important;
  }
  .sl-pb-xl-100 {
    padding-bottom: 4px !important;
  }
  .sl-pl-xl-100 {
    padding-left: 4px !important;
  }
  .sl-pr-xl-100 {
    padding-right: 4px !important;
  }
  .sl-p-xl-150 {
    padding: 6px !important;
  }
  .sl-py-xl-150 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .sl-px-xl-150 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .sl-pt-xl-150 {
    padding-top: 6px !important;
  }
  .sl-pb-xl-150 {
    padding-bottom: 6px !important;
  }
  .sl-pl-xl-150 {
    padding-left: 6px !important;
  }
  .sl-pr-xl-150 {
    padding-right: 6px !important;
  }
  .sl-p-xl-200 {
    padding: 8px !important;
  }
  .sl-py-xl-200 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sl-px-xl-200 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sl-pt-xl-200 {
    padding-top: 8px !important;
  }
  .sl-pb-xl-200 {
    padding-bottom: 8px !important;
  }
  .sl-pl-xl-200 {
    padding-left: 8px !important;
  }
  .sl-pr-xl-200 {
    padding-right: 8px !important;
  }
  .sl-p-xl-250 {
    padding: 10px !important;
  }
  .sl-py-xl-250 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sl-px-xl-250 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sl-pt-xl-250 {
    padding-top: 10px !important;
  }
  .sl-pb-xl-250 {
    padding-bottom: 10px !important;
  }
  .sl-pl-xl-250 {
    padding-left: 10px !important;
  }
  .sl-pr-xl-250 {
    padding-right: 10px !important;
  }
  .sl-p-xl-300 {
    padding: 12px !important;
  }
  .sl-py-xl-300 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sl-px-xl-300 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sl-pt-xl-300 {
    padding-top: 12px !important;
  }
  .sl-pb-xl-300 {
    padding-bottom: 12px !important;
  }
  .sl-pl-xl-300 {
    padding-left: 12px !important;
  }
  .sl-pr-xl-300 {
    padding-right: 12px !important;
  }
  .sl-p-xl-350 {
    padding: 14px !important;
  }
  .sl-py-xl-350 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .sl-px-xl-350 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sl-pt-xl-350 {
    padding-top: 14px !important;
  }
  .sl-pb-xl-350 {
    padding-bottom: 14px !important;
  }
  .sl-pl-xl-350 {
    padding-left: 14px !important;
  }
  .sl-pr-xl-350 {
    padding-right: 14px !important;
  }
  .sl-p-xl-400 {
    padding: 16px !important;
  }
  .sl-py-xl-400 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sl-px-xl-400 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sl-pt-xl-400 {
    padding-top: 16px !important;
  }
  .sl-pb-xl-400 {
    padding-bottom: 16px !important;
  }
  .sl-pl-xl-400 {
    padding-left: 16px !important;
  }
  .sl-pr-xl-400 {
    padding-right: 16px !important;
  }
  .sl-p-xl-450 {
    padding: 18px !important;
  }
  .sl-py-xl-450 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .sl-px-xl-450 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .sl-pt-xl-450 {
    padding-top: 18px !important;
  }
  .sl-pb-xl-450 {
    padding-bottom: 18px !important;
  }
  .sl-pl-xl-450 {
    padding-left: 18px !important;
  }
  .sl-pr-xl-450 {
    padding-right: 18px !important;
  }
  .sl-p-xl-500 {
    padding: 20px !important;
  }
  .sl-py-xl-500 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sl-px-xl-500 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sl-pt-xl-500 {
    padding-top: 20px !important;
  }
  .sl-pb-xl-500 {
    padding-bottom: 20px !important;
  }
  .sl-pl-xl-500 {
    padding-left: 20px !important;
  }
  .sl-pr-xl-500 {
    padding-right: 20px !important;
  }
  .sl-p-xl-550 {
    padding: 22px !important;
  }
  .sl-py-xl-550 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .sl-px-xl-550 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .sl-pt-xl-550 {
    padding-top: 22px !important;
  }
  .sl-pb-xl-550 {
    padding-bottom: 22px !important;
  }
  .sl-pl-xl-550 {
    padding-left: 22px !important;
  }
  .sl-pr-xl-550 {
    padding-right: 22px !important;
  }
  .sl-p-xl-600 {
    padding: 24px !important;
  }
  .sl-py-xl-600 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sl-px-xl-600 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sl-pt-xl-600 {
    padding-top: 24px !important;
  }
  .sl-pb-xl-600 {
    padding-bottom: 24px !important;
  }
  .sl-pl-xl-600 {
    padding-left: 24px !important;
  }
  .sl-pr-xl-600 {
    padding-right: 24px !important;
  }
  .sl-p-xl-650 {
    padding: 28px !important;
  }
  .sl-py-xl-650 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sl-px-xl-650 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sl-pt-xl-650 {
    padding-top: 28px !important;
  }
  .sl-pb-xl-650 {
    padding-bottom: 28px !important;
  }
  .sl-pl-xl-650 {
    padding-left: 28px !important;
  }
  .sl-pr-xl-650 {
    padding-right: 28px !important;
  }
  .sl-p-xl-700 {
    padding: 32px !important;
  }
  .sl-py-xl-700 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sl-px-xl-700 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sl-pt-xl-700 {
    padding-top: 32px !important;
  }
  .sl-pb-xl-700 {
    padding-bottom: 32px !important;
  }
  .sl-pl-xl-700 {
    padding-left: 32px !important;
  }
  .sl-pr-xl-700 {
    padding-right: 32px !important;
  }
  .sl-p-xl-750 {
    padding: 36px !important;
  }
  .sl-py-xl-750 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .sl-px-xl-750 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .sl-pt-xl-750 {
    padding-top: 36px !important;
  }
  .sl-pb-xl-750 {
    padding-bottom: 36px !important;
  }
  .sl-pl-xl-750 {
    padding-left: 36px !important;
  }
  .sl-pr-xl-750 {
    padding-right: 36px !important;
  }
  .sl-p-xl-800 {
    padding: 40px !important;
  }
  .sl-py-xl-800 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sl-px-xl-800 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sl-pt-xl-800 {
    padding-top: 40px !important;
  }
  .sl-pb-xl-800 {
    padding-bottom: 40px !important;
  }
  .sl-pl-xl-800 {
    padding-left: 40px !important;
  }
  .sl-pr-xl-800 {
    padding-right: 40px !important;
  }
  .sl-p-xl-825 {
    padding: 44px !important;
  }
  .sl-py-xl-825 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .sl-px-xl-825 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .sl-pt-xl-825 {
    padding-top: 44px !important;
  }
  .sl-pb-xl-825 {
    padding-bottom: 44px !important;
  }
  .sl-pl-xl-825 {
    padding-left: 44px !important;
  }
  .sl-pr-xl-825 {
    padding-right: 44px !important;
  }
  .sl-p-xl-850 {
    padding: 48px !important;
  }
  .sl-py-xl-850 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sl-px-xl-850 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sl-pt-xl-850 {
    padding-top: 48px !important;
  }
  .sl-pb-xl-850 {
    padding-bottom: 48px !important;
  }
  .sl-pl-xl-850 {
    padding-left: 48px !important;
  }
  .sl-pr-xl-850 {
    padding-right: 48px !important;
  }
  .sl-p-xl-900 {
    padding: 56px !important;
  }
  .sl-py-xl-900 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sl-px-xl-900 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sl-pt-xl-900 {
    padding-top: 56px !important;
  }
  .sl-pb-xl-900 {
    padding-bottom: 56px !important;
  }
  .sl-pl-xl-900 {
    padding-left: 56px !important;
  }
  .sl-pr-xl-900 {
    padding-right: 56px !important;
  }
  .sl-p-xl-925 {
    padding: 60px !important;
  }
  .sl-py-xl-925 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sl-px-xl-925 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sl-pt-xl-925 {
    padding-top: 60px !important;
  }
  .sl-pb-xl-925 {
    padding-bottom: 60px !important;
  }
  .sl-pl-xl-925 {
    padding-left: 60px !important;
  }
  .sl-pr-xl-925 {
    padding-right: 60px !important;
  }
  .sl-p-xl-950 {
    padding: 64px !important;
  }
  .sl-py-xl-950 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sl-px-xl-950 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sl-pt-xl-950 {
    padding-top: 64px !important;
  }
  .sl-pb-xl-950 {
    padding-bottom: 64px !important;
  }
  .sl-pl-xl-950 {
    padding-left: 64px !important;
  }
  .sl-pr-xl-950 {
    padding-right: 64px !important;
  }
  .sl-p-xl-1200 {
    padding: 96px !important;
  }
  .sl-py-xl-1200 {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .sl-px-xl-1200 {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .sl-pt-xl-1200 {
    padding-top: 96px !important;
  }
  .sl-pb-xl-1200 {
    padding-bottom: 96px !important;
  }
  .sl-pl-xl-1200 {
    padding-left: 96px !important;
  }
  .sl-pr-xl-1200 {
    padding-right: 96px !important;
  }
  .sl-p-xl-n50 {
    padding: -2px !important;
  }
  .sl-py-xl-n50 {
    padding-top: -2px !important;
    padding-bottom: -2px !important;
  }
  .sl-px-xl-n50 {
    padding-left: -2px !important;
    padding-right: -2px !important;
  }
  .sl-pt-xl-n50 {
    padding-top: -2px !important;
  }
  .sl-pb-xl-n50 {
    padding-bottom: -2px !important;
  }
  .sl-pl-xl-n50 {
    padding-left: -2px !important;
  }
  .sl-pr-xl-n50 {
    padding-right: -2px !important;
  }
  .sl-p-xl-n100 {
    padding: -4px !important;
  }
  .sl-py-xl-n100 {
    padding-top: -4px !important;
    padding-bottom: -4px !important;
  }
  .sl-px-xl-n100 {
    padding-left: -4px !important;
    padding-right: -4px !important;
  }
  .sl-pt-xl-n100 {
    padding-top: -4px !important;
  }
  .sl-pb-xl-n100 {
    padding-bottom: -4px !important;
  }
  .sl-pl-xl-n100 {
    padding-left: -4px !important;
  }
  .sl-pr-xl-n100 {
    padding-right: -4px !important;
  }
  .sl-p-xl-n150 {
    padding: -6px !important;
  }
  .sl-py-xl-n150 {
    padding-top: -6px !important;
    padding-bottom: -6px !important;
  }
  .sl-px-xl-n150 {
    padding-left: -6px !important;
    padding-right: -6px !important;
  }
  .sl-pt-xl-n150 {
    padding-top: -6px !important;
  }
  .sl-pb-xl-n150 {
    padding-bottom: -6px !important;
  }
  .sl-pl-xl-n150 {
    padding-left: -6px !important;
  }
  .sl-pr-xl-n150 {
    padding-right: -6px !important;
  }
  .sl-p-xl-n200 {
    padding: -8px !important;
  }
  .sl-py-xl-n200 {
    padding-top: -8px !important;
    padding-bottom: -8px !important;
  }
  .sl-px-xl-n200 {
    padding-left: -8px !important;
    padding-right: -8px !important;
  }
  .sl-pt-xl-n200 {
    padding-top: -8px !important;
  }
  .sl-pb-xl-n200 {
    padding-bottom: -8px !important;
  }
  .sl-pl-xl-n200 {
    padding-left: -8px !important;
  }
  .sl-pr-xl-n200 {
    padding-right: -8px !important;
  }
  .sl-p-xl-n250 {
    padding: -10px !important;
  }
  .sl-py-xl-n250 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .sl-px-xl-n250 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
  .sl-pt-xl-n250 {
    padding-top: -10px !important;
  }
  .sl-pb-xl-n250 {
    padding-bottom: -10px !important;
  }
  .sl-pl-xl-n250 {
    padding-left: -10px !important;
  }
  .sl-pr-xl-n250 {
    padding-right: -10px !important;
  }
  .sl-p-xl-n300 {
    padding: -12px !important;
  }
  .sl-py-xl-n300 {
    padding-top: -12px !important;
    padding-bottom: -12px !important;
  }
  .sl-px-xl-n300 {
    padding-left: -12px !important;
    padding-right: -12px !important;
  }
  .sl-pt-xl-n300 {
    padding-top: -12px !important;
  }
  .sl-pb-xl-n300 {
    padding-bottom: -12px !important;
  }
  .sl-pl-xl-n300 {
    padding-left: -12px !important;
  }
  .sl-pr-xl-n300 {
    padding-right: -12px !important;
  }
  .sl-p-xl-n350 {
    padding: -14px !important;
  }
  .sl-py-xl-n350 {
    padding-top: -14px !important;
    padding-bottom: -14px !important;
  }
  .sl-px-xl-n350 {
    padding-left: -14px !important;
    padding-right: -14px !important;
  }
  .sl-pt-xl-n350 {
    padding-top: -14px !important;
  }
  .sl-pb-xl-n350 {
    padding-bottom: -14px !important;
  }
  .sl-pl-xl-n350 {
    padding-left: -14px !important;
  }
  .sl-pr-xl-n350 {
    padding-right: -14px !important;
  }
  .sl-p-xl-n400 {
    padding: -16px !important;
  }
  .sl-py-xl-n400 {
    padding-top: -16px !important;
    padding-bottom: -16px !important;
  }
  .sl-px-xl-n400 {
    padding-left: -16px !important;
    padding-right: -16px !important;
  }
  .sl-pt-xl-n400 {
    padding-top: -16px !important;
  }
  .sl-pb-xl-n400 {
    padding-bottom: -16px !important;
  }
  .sl-pl-xl-n400 {
    padding-left: -16px !important;
  }
  .sl-pr-xl-n400 {
    padding-right: -16px !important;
  }
  .sl-p-xl-n450 {
    padding: -18px !important;
  }
  .sl-py-xl-n450 {
    padding-top: -18px !important;
    padding-bottom: -18px !important;
  }
  .sl-px-xl-n450 {
    padding-left: -18px !important;
    padding-right: -18px !important;
  }
  .sl-pt-xl-n450 {
    padding-top: -18px !important;
  }
  .sl-pb-xl-n450 {
    padding-bottom: -18px !important;
  }
  .sl-pl-xl-n450 {
    padding-left: -18px !important;
  }
  .sl-pr-xl-n450 {
    padding-right: -18px !important;
  }
  .sl-p-xl-n500 {
    padding: -20px !important;
  }
  .sl-py-xl-n500 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .sl-px-xl-n500 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .sl-pt-xl-n500 {
    padding-top: -20px !important;
  }
  .sl-pb-xl-n500 {
    padding-bottom: -20px !important;
  }
  .sl-pl-xl-n500 {
    padding-left: -20px !important;
  }
  .sl-pr-xl-n500 {
    padding-right: -20px !important;
  }
  .sl-p-xl-n550 {
    padding: -22px !important;
  }
  .sl-py-xl-n550 {
    padding-top: -22px !important;
    padding-bottom: -22px !important;
  }
  .sl-px-xl-n550 {
    padding-left: -22px !important;
    padding-right: -22px !important;
  }
  .sl-pt-xl-n550 {
    padding-top: -22px !important;
  }
  .sl-pb-xl-n550 {
    padding-bottom: -22px !important;
  }
  .sl-pl-xl-n550 {
    padding-left: -22px !important;
  }
  .sl-pr-xl-n550 {
    padding-right: -22px !important;
  }
  .sl-p-xl-n600 {
    padding: -24px !important;
  }
  .sl-py-xl-n600 {
    padding-top: -24px !important;
    padding-bottom: -24px !important;
  }
  .sl-px-xl-n600 {
    padding-left: -24px !important;
    padding-right: -24px !important;
  }
  .sl-pt-xl-n600 {
    padding-top: -24px !important;
  }
  .sl-pb-xl-n600 {
    padding-bottom: -24px !important;
  }
  .sl-pl-xl-n600 {
    padding-left: -24px !important;
  }
  .sl-pr-xl-n600 {
    padding-right: -24px !important;
  }
  .sl-p-xl-n650 {
    padding: -28px !important;
  }
  .sl-py-xl-n650 {
    padding-top: -28px !important;
    padding-bottom: -28px !important;
  }
  .sl-px-xl-n650 {
    padding-left: -28px !important;
    padding-right: -28px !important;
  }
  .sl-pt-xl-n650 {
    padding-top: -28px !important;
  }
  .sl-pb-xl-n650 {
    padding-bottom: -28px !important;
  }
  .sl-pl-xl-n650 {
    padding-left: -28px !important;
  }
  .sl-pr-xl-n650 {
    padding-right: -28px !important;
  }
  .sl-p-xl-n700 {
    padding: -32px !important;
  }
  .sl-py-xl-n700 {
    padding-top: -32px !important;
    padding-bottom: -32px !important;
  }
  .sl-px-xl-n700 {
    padding-left: -32px !important;
    padding-right: -32px !important;
  }
  .sl-pt-xl-n700 {
    padding-top: -32px !important;
  }
  .sl-pb-xl-n700 {
    padding-bottom: -32px !important;
  }
  .sl-pl-xl-n700 {
    padding-left: -32px !important;
  }
  .sl-pr-xl-n700 {
    padding-right: -32px !important;
  }
  .sl-p-xl-n750 {
    padding: -36px !important;
  }
  .sl-py-xl-n750 {
    padding-top: -36px !important;
    padding-bottom: -36px !important;
  }
  .sl-px-xl-n750 {
    padding-left: -36px !important;
    padding-right: -36px !important;
  }
  .sl-pt-xl-n750 {
    padding-top: -36px !important;
  }
  .sl-pb-xl-n750 {
    padding-bottom: -36px !important;
  }
  .sl-pl-xl-n750 {
    padding-left: -36px !important;
  }
  .sl-pr-xl-n750 {
    padding-right: -36px !important;
  }
  .sl-p-xl-n800 {
    padding: -40px !important;
  }
  .sl-py-xl-n800 {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }
  .sl-px-xl-n800 {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }
  .sl-pt-xl-n800 {
    padding-top: -40px !important;
  }
  .sl-pb-xl-n800 {
    padding-bottom: -40px !important;
  }
  .sl-pl-xl-n800 {
    padding-left: -40px !important;
  }
  .sl-pr-xl-n800 {
    padding-right: -40px !important;
  }
  .sl-p-xl-n850 {
    padding: -48px !important;
  }
  .sl-py-xl-n850 {
    padding-top: -48px !important;
    padding-bottom: -48px !important;
  }
  .sl-px-xl-n850 {
    padding-left: -48px !important;
    padding-right: -48px !important;
  }
  .sl-pt-xl-n850 {
    padding-top: -48px !important;
  }
  .sl-pb-xl-n850 {
    padding-bottom: -48px !important;
  }
  .sl-pl-xl-n850 {
    padding-left: -48px !important;
  }
  .sl-pr-xl-n850 {
    padding-right: -48px !important;
  }
  .sl-p-xl-n900 {
    padding: -56px !important;
  }
  .sl-py-xl-n900 {
    padding-top: -56px !important;
    padding-bottom: -56px !important;
  }
  .sl-px-xl-n900 {
    padding-left: -56px !important;
    padding-right: -56px !important;
  }
  .sl-pt-xl-n900 {
    padding-top: -56px !important;
  }
  .sl-pb-xl-n900 {
    padding-bottom: -56px !important;
  }
  .sl-pl-xl-n900 {
    padding-left: -56px !important;
  }
  .sl-pr-xl-n900 {
    padding-right: -56px !important;
  }
  .sl-p-xl-n925 {
    padding: -60px !important;
  }
  .sl-py-xl-n925 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .sl-px-xl-n925 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .sl-pt-xl-n925 {
    padding-top: -60px !important;
  }
  .sl-pb-xl-n925 {
    padding-bottom: -60px !important;
  }
  .sl-pl-xl-n925 {
    padding-left: -60px !important;
  }
  .sl-pr-xl-n925 {
    padding-right: -60px !important;
  }
  .sl-p-xl-n950 {
    padding: -64px !important;
  }
  .sl-py-xl-n950 {
    padding-top: -64px !important;
    padding-bottom: -64px !important;
  }
  .sl-px-xl-n950 {
    padding-left: -64px !important;
    padding-right: -64px !important;
  }
  .sl-pt-xl-n950 {
    padding-top: -64px !important;
  }
  .sl-pb-xl-n950 {
    padding-bottom: -64px !important;
  }
  .sl-pl-xl-n950 {
    padding-left: -64px !important;
  }
  .sl-pr-xl-n950 {
    padding-right: -64px !important;
  }
  .sl-p-xl-n1200 {
    padding: -96px !important;
  }
  .sl-py-xl-n1200 {
    padding-top: -96px !important;
    padding-bottom: -96px !important;
  }
  .sl-px-xl-n1200 {
    padding-left: -96px !important;
    padding-right: -96px !important;
  }
  .sl-pt-xl-n1200 {
    padding-top: -96px !important;
  }
  .sl-pb-xl-n1200 {
    padding-bottom: -96px !important;
  }
  .sl-pl-xl-n1200 {
    padding-left: -96px !important;
  }
  .sl-pr-xl-n1200 {
    padding-right: -96px !important;
  }
  .sl-p-xl-auto {
    padding: auto !important;
  }
  .sl-py-xl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .sl-px-xl-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .sl-pt-xl-auto {
    padding-top: auto !important;
  }
  .sl-pb-xl-auto {
    padding-bottom: auto !important;
  }
  .sl-pl-xl-auto {
    padding-left: auto !important;
  }
  .sl-pr-xl-auto {
    padding-right: auto !important;
  }
}
@media (min-width: 2240px) {
  .sl-m-xxl-0 {
    margin: 0px !important;
  }
  .sl-my-xxl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sl-mx-xxl-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sl-mt-xxl-0 {
    margin-top: 0px !important;
  }
  .sl-mb-xxl-0 {
    margin-bottom: 0px !important;
  }
  .sl-ml-xxl-0 {
    margin-left: 0px !important;
  }
  .sl-mr-xxl-0 {
    margin-right: 0px !important;
  }
  .sl-m-xxl-50 {
    margin: 2px !important;
  }
  .sl-my-xxl-50 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .sl-mx-xxl-50 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .sl-mt-xxl-50 {
    margin-top: 2px !important;
  }
  .sl-mb-xxl-50 {
    margin-bottom: 2px !important;
  }
  .sl-ml-xxl-50 {
    margin-left: 2px !important;
  }
  .sl-mr-xxl-50 {
    margin-right: 2px !important;
  }
  .sl-m-xxl-100 {
    margin: 4px !important;
  }
  .sl-my-xxl-100 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .sl-mx-xxl-100 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .sl-mt-xxl-100 {
    margin-top: 4px !important;
  }
  .sl-mb-xxl-100 {
    margin-bottom: 4px !important;
  }
  .sl-ml-xxl-100 {
    margin-left: 4px !important;
  }
  .sl-mr-xxl-100 {
    margin-right: 4px !important;
  }
  .sl-m-xxl-150 {
    margin: 6px !important;
  }
  .sl-my-xxl-150 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .sl-mx-xxl-150 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .sl-mt-xxl-150 {
    margin-top: 6px !important;
  }
  .sl-mb-xxl-150 {
    margin-bottom: 6px !important;
  }
  .sl-ml-xxl-150 {
    margin-left: 6px !important;
  }
  .sl-mr-xxl-150 {
    margin-right: 6px !important;
  }
  .sl-m-xxl-200 {
    margin: 8px !important;
  }
  .sl-my-xxl-200 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sl-mx-xxl-200 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sl-mt-xxl-200 {
    margin-top: 8px !important;
  }
  .sl-mb-xxl-200 {
    margin-bottom: 8px !important;
  }
  .sl-ml-xxl-200 {
    margin-left: 8px !important;
  }
  .sl-mr-xxl-200 {
    margin-right: 8px !important;
  }
  .sl-m-xxl-250 {
    margin: 10px !important;
  }
  .sl-my-xxl-250 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sl-mx-xxl-250 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sl-mt-xxl-250 {
    margin-top: 10px !important;
  }
  .sl-mb-xxl-250 {
    margin-bottom: 10px !important;
  }
  .sl-ml-xxl-250 {
    margin-left: 10px !important;
  }
  .sl-mr-xxl-250 {
    margin-right: 10px !important;
  }
  .sl-m-xxl-300 {
    margin: 12px !important;
  }
  .sl-my-xxl-300 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .sl-mx-xxl-300 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .sl-mt-xxl-300 {
    margin-top: 12px !important;
  }
  .sl-mb-xxl-300 {
    margin-bottom: 12px !important;
  }
  .sl-ml-xxl-300 {
    margin-left: 12px !important;
  }
  .sl-mr-xxl-300 {
    margin-right: 12px !important;
  }
  .sl-m-xxl-350 {
    margin: 14px !important;
  }
  .sl-my-xxl-350 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .sl-mx-xxl-350 {
    margin-left: 14px !important;
    margin-right: 14px !important;
  }
  .sl-mt-xxl-350 {
    margin-top: 14px !important;
  }
  .sl-mb-xxl-350 {
    margin-bottom: 14px !important;
  }
  .sl-ml-xxl-350 {
    margin-left: 14px !important;
  }
  .sl-mr-xxl-350 {
    margin-right: 14px !important;
  }
  .sl-m-xxl-400 {
    margin: 16px !important;
  }
  .sl-my-xxl-400 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sl-mx-xxl-400 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sl-mt-xxl-400 {
    margin-top: 16px !important;
  }
  .sl-mb-xxl-400 {
    margin-bottom: 16px !important;
  }
  .sl-ml-xxl-400 {
    margin-left: 16px !important;
  }
  .sl-mr-xxl-400 {
    margin-right: 16px !important;
  }
  .sl-m-xxl-450 {
    margin: 18px !important;
  }
  .sl-my-xxl-450 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .sl-mx-xxl-450 {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .sl-mt-xxl-450 {
    margin-top: 18px !important;
  }
  .sl-mb-xxl-450 {
    margin-bottom: 18px !important;
  }
  .sl-ml-xxl-450 {
    margin-left: 18px !important;
  }
  .sl-mr-xxl-450 {
    margin-right: 18px !important;
  }
  .sl-m-xxl-500 {
    margin: 20px !important;
  }
  .sl-my-xxl-500 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sl-mx-xxl-500 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sl-mt-xxl-500 {
    margin-top: 20px !important;
  }
  .sl-mb-xxl-500 {
    margin-bottom: 20px !important;
  }
  .sl-ml-xxl-500 {
    margin-left: 20px !important;
  }
  .sl-mr-xxl-500 {
    margin-right: 20px !important;
  }
  .sl-m-xxl-550 {
    margin: 22px !important;
  }
  .sl-my-xxl-550 {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .sl-mx-xxl-550 {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }
  .sl-mt-xxl-550 {
    margin-top: 22px !important;
  }
  .sl-mb-xxl-550 {
    margin-bottom: 22px !important;
  }
  .sl-ml-xxl-550 {
    margin-left: 22px !important;
  }
  .sl-mr-xxl-550 {
    margin-right: 22px !important;
  }
  .sl-m-xxl-600 {
    margin: 24px !important;
  }
  .sl-my-xxl-600 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sl-mx-xxl-600 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sl-mt-xxl-600 {
    margin-top: 24px !important;
  }
  .sl-mb-xxl-600 {
    margin-bottom: 24px !important;
  }
  .sl-ml-xxl-600 {
    margin-left: 24px !important;
  }
  .sl-mr-xxl-600 {
    margin-right: 24px !important;
  }
  .sl-m-xxl-650 {
    margin: 28px !important;
  }
  .sl-my-xxl-650 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .sl-mx-xxl-650 {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .sl-mt-xxl-650 {
    margin-top: 28px !important;
  }
  .sl-mb-xxl-650 {
    margin-bottom: 28px !important;
  }
  .sl-ml-xxl-650 {
    margin-left: 28px !important;
  }
  .sl-mr-xxl-650 {
    margin-right: 28px !important;
  }
  .sl-m-xxl-700 {
    margin: 32px !important;
  }
  .sl-my-xxl-700 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sl-mx-xxl-700 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sl-mt-xxl-700 {
    margin-top: 32px !important;
  }
  .sl-mb-xxl-700 {
    margin-bottom: 32px !important;
  }
  .sl-ml-xxl-700 {
    margin-left: 32px !important;
  }
  .sl-mr-xxl-700 {
    margin-right: 32px !important;
  }
  .sl-m-xxl-750 {
    margin: 36px !important;
  }
  .sl-my-xxl-750 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .sl-mx-xxl-750 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .sl-mt-xxl-750 {
    margin-top: 36px !important;
  }
  .sl-mb-xxl-750 {
    margin-bottom: 36px !important;
  }
  .sl-ml-xxl-750 {
    margin-left: 36px !important;
  }
  .sl-mr-xxl-750 {
    margin-right: 36px !important;
  }
  .sl-m-xxl-800 {
    margin: 40px !important;
  }
  .sl-my-xxl-800 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sl-mx-xxl-800 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sl-mt-xxl-800 {
    margin-top: 40px !important;
  }
  .sl-mb-xxl-800 {
    margin-bottom: 40px !important;
  }
  .sl-ml-xxl-800 {
    margin-left: 40px !important;
  }
  .sl-mr-xxl-800 {
    margin-right: 40px !important;
  }
  .sl-m-xxl-825 {
    margin: 44px !important;
  }
  .sl-my-xxl-825 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .sl-mx-xxl-825 {
    margin-left: 44px !important;
    margin-right: 44px !important;
  }
  .sl-mt-xxl-825 {
    margin-top: 44px !important;
  }
  .sl-mb-xxl-825 {
    margin-bottom: 44px !important;
  }
  .sl-ml-xxl-825 {
    margin-left: 44px !important;
  }
  .sl-mr-xxl-825 {
    margin-right: 44px !important;
  }
  .sl-m-xxl-850 {
    margin: 48px !important;
  }
  .sl-my-xxl-850 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sl-mx-xxl-850 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sl-mt-xxl-850 {
    margin-top: 48px !important;
  }
  .sl-mb-xxl-850 {
    margin-bottom: 48px !important;
  }
  .sl-ml-xxl-850 {
    margin-left: 48px !important;
  }
  .sl-mr-xxl-850 {
    margin-right: 48px !important;
  }
  .sl-m-xxl-900 {
    margin: 56px !important;
  }
  .sl-my-xxl-900 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sl-mx-xxl-900 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sl-mt-xxl-900 {
    margin-top: 56px !important;
  }
  .sl-mb-xxl-900 {
    margin-bottom: 56px !important;
  }
  .sl-ml-xxl-900 {
    margin-left: 56px !important;
  }
  .sl-mr-xxl-900 {
    margin-right: 56px !important;
  }
  .sl-m-xxl-925 {
    margin: 60px !important;
  }
  .sl-my-xxl-925 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sl-mx-xxl-925 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .sl-mt-xxl-925 {
    margin-top: 60px !important;
  }
  .sl-mb-xxl-925 {
    margin-bottom: 60px !important;
  }
  .sl-ml-xxl-925 {
    margin-left: 60px !important;
  }
  .sl-mr-xxl-925 {
    margin-right: 60px !important;
  }
  .sl-m-xxl-950 {
    margin: 64px !important;
  }
  .sl-my-xxl-950 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .sl-mx-xxl-950 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sl-mt-xxl-950 {
    margin-top: 64px !important;
  }
  .sl-mb-xxl-950 {
    margin-bottom: 64px !important;
  }
  .sl-ml-xxl-950 {
    margin-left: 64px !important;
  }
  .sl-mr-xxl-950 {
    margin-right: 64px !important;
  }
  .sl-m-xxl-1200 {
    margin: 96px !important;
  }
  .sl-my-xxl-1200 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .sl-mx-xxl-1200 {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .sl-mt-xxl-1200 {
    margin-top: 96px !important;
  }
  .sl-mb-xxl-1200 {
    margin-bottom: 96px !important;
  }
  .sl-ml-xxl-1200 {
    margin-left: 96px !important;
  }
  .sl-mr-xxl-1200 {
    margin-right: 96px !important;
  }
  .sl-m-xxl-n50 {
    margin: -2px !important;
  }
  .sl-my-xxl-n50 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .sl-mx-xxl-n50 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .sl-mt-xxl-n50 {
    margin-top: -2px !important;
  }
  .sl-mb-xxl-n50 {
    margin-bottom: -2px !important;
  }
  .sl-ml-xxl-n50 {
    margin-left: -2px !important;
  }
  .sl-mr-xxl-n50 {
    margin-right: -2px !important;
  }
  .sl-m-xxl-n100 {
    margin: -4px !important;
  }
  .sl-my-xxl-n100 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .sl-mx-xxl-n100 {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .sl-mt-xxl-n100 {
    margin-top: -4px !important;
  }
  .sl-mb-xxl-n100 {
    margin-bottom: -4px !important;
  }
  .sl-ml-xxl-n100 {
    margin-left: -4px !important;
  }
  .sl-mr-xxl-n100 {
    margin-right: -4px !important;
  }
  .sl-m-xxl-n150 {
    margin: -6px !important;
  }
  .sl-my-xxl-n150 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
  }
  .sl-mx-xxl-n150 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .sl-mt-xxl-n150 {
    margin-top: -6px !important;
  }
  .sl-mb-xxl-n150 {
    margin-bottom: -6px !important;
  }
  .sl-ml-xxl-n150 {
    margin-left: -6px !important;
  }
  .sl-mr-xxl-n150 {
    margin-right: -6px !important;
  }
  .sl-m-xxl-n200 {
    margin: -8px !important;
  }
  .sl-my-xxl-n200 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .sl-mx-xxl-n200 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .sl-mt-xxl-n200 {
    margin-top: -8px !important;
  }
  .sl-mb-xxl-n200 {
    margin-bottom: -8px !important;
  }
  .sl-ml-xxl-n200 {
    margin-left: -8px !important;
  }
  .sl-mr-xxl-n200 {
    margin-right: -8px !important;
  }
  .sl-m-xxl-n250 {
    margin: -10px !important;
  }
  .sl-my-xxl-n250 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sl-mx-xxl-n250 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sl-mt-xxl-n250 {
    margin-top: -10px !important;
  }
  .sl-mb-xxl-n250 {
    margin-bottom: -10px !important;
  }
  .sl-ml-xxl-n250 {
    margin-left: -10px !important;
  }
  .sl-mr-xxl-n250 {
    margin-right: -10px !important;
  }
  .sl-m-xxl-n300 {
    margin: -12px !important;
  }
  .sl-my-xxl-n300 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .sl-mx-xxl-n300 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .sl-mt-xxl-n300 {
    margin-top: -12px !important;
  }
  .sl-mb-xxl-n300 {
    margin-bottom: -12px !important;
  }
  .sl-ml-xxl-n300 {
    margin-left: -12px !important;
  }
  .sl-mr-xxl-n300 {
    margin-right: -12px !important;
  }
  .sl-m-xxl-n350 {
    margin: -14px !important;
  }
  .sl-my-xxl-n350 {
    margin-top: -14px !important;
    margin-bottom: -14px !important;
  }
  .sl-mx-xxl-n350 {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
  .sl-mt-xxl-n350 {
    margin-top: -14px !important;
  }
  .sl-mb-xxl-n350 {
    margin-bottom: -14px !important;
  }
  .sl-ml-xxl-n350 {
    margin-left: -14px !important;
  }
  .sl-mr-xxl-n350 {
    margin-right: -14px !important;
  }
  .sl-m-xxl-n400 {
    margin: -16px !important;
  }
  .sl-my-xxl-n400 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .sl-mx-xxl-n400 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .sl-mt-xxl-n400 {
    margin-top: -16px !important;
  }
  .sl-mb-xxl-n400 {
    margin-bottom: -16px !important;
  }
  .sl-ml-xxl-n400 {
    margin-left: -16px !important;
  }
  .sl-mr-xxl-n400 {
    margin-right: -16px !important;
  }
  .sl-m-xxl-n450 {
    margin: -18px !important;
  }
  .sl-my-xxl-n450 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
  }
  .sl-mx-xxl-n450 {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .sl-mt-xxl-n450 {
    margin-top: -18px !important;
  }
  .sl-mb-xxl-n450 {
    margin-bottom: -18px !important;
  }
  .sl-ml-xxl-n450 {
    margin-left: -18px !important;
  }
  .sl-mr-xxl-n450 {
    margin-right: -18px !important;
  }
  .sl-m-xxl-n500 {
    margin: -20px !important;
  }
  .sl-my-xxl-n500 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sl-mx-xxl-n500 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sl-mt-xxl-n500 {
    margin-top: -20px !important;
  }
  .sl-mb-xxl-n500 {
    margin-bottom: -20px !important;
  }
  .sl-ml-xxl-n500 {
    margin-left: -20px !important;
  }
  .sl-mr-xxl-n500 {
    margin-right: -20px !important;
  }
  .sl-m-xxl-n550 {
    margin: -22px !important;
  }
  .sl-my-xxl-n550 {
    margin-top: -22px !important;
    margin-bottom: -22px !important;
  }
  .sl-mx-xxl-n550 {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
  .sl-mt-xxl-n550 {
    margin-top: -22px !important;
  }
  .sl-mb-xxl-n550 {
    margin-bottom: -22px !important;
  }
  .sl-ml-xxl-n550 {
    margin-left: -22px !important;
  }
  .sl-mr-xxl-n550 {
    margin-right: -22px !important;
  }
  .sl-m-xxl-n600 {
    margin: -24px !important;
  }
  .sl-my-xxl-n600 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .sl-mx-xxl-n600 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .sl-mt-xxl-n600 {
    margin-top: -24px !important;
  }
  .sl-mb-xxl-n600 {
    margin-bottom: -24px !important;
  }
  .sl-ml-xxl-n600 {
    margin-left: -24px !important;
  }
  .sl-mr-xxl-n600 {
    margin-right: -24px !important;
  }
  .sl-m-xxl-n650 {
    margin: -28px !important;
  }
  .sl-my-xxl-n650 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .sl-mx-xxl-n650 {
    margin-left: -28px !important;
    margin-right: -28px !important;
  }
  .sl-mt-xxl-n650 {
    margin-top: -28px !important;
  }
  .sl-mb-xxl-n650 {
    margin-bottom: -28px !important;
  }
  .sl-ml-xxl-n650 {
    margin-left: -28px !important;
  }
  .sl-mr-xxl-n650 {
    margin-right: -28px !important;
  }
  .sl-m-xxl-n700 {
    margin: -32px !important;
  }
  .sl-my-xxl-n700 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .sl-mx-xxl-n700 {
    margin-left: -32px !important;
    margin-right: -32px !important;
  }
  .sl-mt-xxl-n700 {
    margin-top: -32px !important;
  }
  .sl-mb-xxl-n700 {
    margin-bottom: -32px !important;
  }
  .sl-ml-xxl-n700 {
    margin-left: -32px !important;
  }
  .sl-mr-xxl-n700 {
    margin-right: -32px !important;
  }
  .sl-m-xxl-n750 {
    margin: -36px !important;
  }
  .sl-my-xxl-n750 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .sl-mx-xxl-n750 {
    margin-left: -36px !important;
    margin-right: -36px !important;
  }
  .sl-mt-xxl-n750 {
    margin-top: -36px !important;
  }
  .sl-mb-xxl-n750 {
    margin-bottom: -36px !important;
  }
  .sl-ml-xxl-n750 {
    margin-left: -36px !important;
  }
  .sl-mr-xxl-n750 {
    margin-right: -36px !important;
  }
  .sl-m-xxl-n800 {
    margin: -40px !important;
  }
  .sl-my-xxl-n800 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sl-mx-xxl-n800 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sl-mt-xxl-n800 {
    margin-top: -40px !important;
  }
  .sl-mb-xxl-n800 {
    margin-bottom: -40px !important;
  }
  .sl-ml-xxl-n800 {
    margin-left: -40px !important;
  }
  .sl-mr-xxl-n800 {
    margin-right: -40px !important;
  }
  .sl-m-xxl-n850 {
    margin: -48px !important;
  }
  .sl-my-xxl-n850 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .sl-mx-xxl-n850 {
    margin-left: -48px !important;
    margin-right: -48px !important;
  }
  .sl-mt-xxl-n850 {
    margin-top: -48px !important;
  }
  .sl-mb-xxl-n850 {
    margin-bottom: -48px !important;
  }
  .sl-ml-xxl-n850 {
    margin-left: -48px !important;
  }
  .sl-mr-xxl-n850 {
    margin-right: -48px !important;
  }
  .sl-m-xxl-n900 {
    margin: -56px !important;
  }
  .sl-my-xxl-n900 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .sl-mx-xxl-n900 {
    margin-left: -56px !important;
    margin-right: -56px !important;
  }
  .sl-mt-xxl-n900 {
    margin-top: -56px !important;
  }
  .sl-mb-xxl-n900 {
    margin-bottom: -56px !important;
  }
  .sl-ml-xxl-n900 {
    margin-left: -56px !important;
  }
  .sl-mr-xxl-n900 {
    margin-right: -56px !important;
  }
  .sl-m-xxl-n925 {
    margin: -60px !important;
  }
  .sl-my-xxl-n925 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .sl-mx-xxl-n925 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sl-mt-xxl-n925 {
    margin-top: -60px !important;
  }
  .sl-mb-xxl-n925 {
    margin-bottom: -60px !important;
  }
  .sl-ml-xxl-n925 {
    margin-left: -60px !important;
  }
  .sl-mr-xxl-n925 {
    margin-right: -60px !important;
  }
  .sl-m-xxl-n950 {
    margin: -64px !important;
  }
  .sl-my-xxl-n950 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .sl-mx-xxl-n950 {
    margin-left: -64px !important;
    margin-right: -64px !important;
  }
  .sl-mt-xxl-n950 {
    margin-top: -64px !important;
  }
  .sl-mb-xxl-n950 {
    margin-bottom: -64px !important;
  }
  .sl-ml-xxl-n950 {
    margin-left: -64px !important;
  }
  .sl-mr-xxl-n950 {
    margin-right: -64px !important;
  }
  .sl-m-xxl-n1200 {
    margin: -96px !important;
  }
  .sl-my-xxl-n1200 {
    margin-top: -96px !important;
    margin-bottom: -96px !important;
  }
  .sl-mx-xxl-n1200 {
    margin-left: -96px !important;
    margin-right: -96px !important;
  }
  .sl-mt-xxl-n1200 {
    margin-top: -96px !important;
  }
  .sl-mb-xxl-n1200 {
    margin-bottom: -96px !important;
  }
  .sl-ml-xxl-n1200 {
    margin-left: -96px !important;
  }
  .sl-mr-xxl-n1200 {
    margin-right: -96px !important;
  }
  .sl-m-xxl-auto {
    margin: auto !important;
  }
  .sl-my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .sl-mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sl-mt-xxl-auto {
    margin-top: auto !important;
  }
  .sl-mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .sl-ml-xxl-auto {
    margin-left: auto !important;
  }
  .sl-mr-xxl-auto {
    margin-right: auto !important;
  }
  .sl-p-xxl-0 {
    padding: 0px !important;
  }
  .sl-py-xxl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sl-px-xxl-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sl-pt-xxl-0 {
    padding-top: 0px !important;
  }
  .sl-pb-xxl-0 {
    padding-bottom: 0px !important;
  }
  .sl-pl-xxl-0 {
    padding-left: 0px !important;
  }
  .sl-pr-xxl-0 {
    padding-right: 0px !important;
  }
  .sl-p-xxl-50 {
    padding: 2px !important;
  }
  .sl-py-xxl-50 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .sl-px-xxl-50 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .sl-pt-xxl-50 {
    padding-top: 2px !important;
  }
  .sl-pb-xxl-50 {
    padding-bottom: 2px !important;
  }
  .sl-pl-xxl-50 {
    padding-left: 2px !important;
  }
  .sl-pr-xxl-50 {
    padding-right: 2px !important;
  }
  .sl-p-xxl-100 {
    padding: 4px !important;
  }
  .sl-py-xxl-100 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sl-px-xxl-100 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sl-pt-xxl-100 {
    padding-top: 4px !important;
  }
  .sl-pb-xxl-100 {
    padding-bottom: 4px !important;
  }
  .sl-pl-xxl-100 {
    padding-left: 4px !important;
  }
  .sl-pr-xxl-100 {
    padding-right: 4px !important;
  }
  .sl-p-xxl-150 {
    padding: 6px !important;
  }
  .sl-py-xxl-150 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .sl-px-xxl-150 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .sl-pt-xxl-150 {
    padding-top: 6px !important;
  }
  .sl-pb-xxl-150 {
    padding-bottom: 6px !important;
  }
  .sl-pl-xxl-150 {
    padding-left: 6px !important;
  }
  .sl-pr-xxl-150 {
    padding-right: 6px !important;
  }
  .sl-p-xxl-200 {
    padding: 8px !important;
  }
  .sl-py-xxl-200 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sl-px-xxl-200 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sl-pt-xxl-200 {
    padding-top: 8px !important;
  }
  .sl-pb-xxl-200 {
    padding-bottom: 8px !important;
  }
  .sl-pl-xxl-200 {
    padding-left: 8px !important;
  }
  .sl-pr-xxl-200 {
    padding-right: 8px !important;
  }
  .sl-p-xxl-250 {
    padding: 10px !important;
  }
  .sl-py-xxl-250 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sl-px-xxl-250 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sl-pt-xxl-250 {
    padding-top: 10px !important;
  }
  .sl-pb-xxl-250 {
    padding-bottom: 10px !important;
  }
  .sl-pl-xxl-250 {
    padding-left: 10px !important;
  }
  .sl-pr-xxl-250 {
    padding-right: 10px !important;
  }
  .sl-p-xxl-300 {
    padding: 12px !important;
  }
  .sl-py-xxl-300 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sl-px-xxl-300 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sl-pt-xxl-300 {
    padding-top: 12px !important;
  }
  .sl-pb-xxl-300 {
    padding-bottom: 12px !important;
  }
  .sl-pl-xxl-300 {
    padding-left: 12px !important;
  }
  .sl-pr-xxl-300 {
    padding-right: 12px !important;
  }
  .sl-p-xxl-350 {
    padding: 14px !important;
  }
  .sl-py-xxl-350 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .sl-px-xxl-350 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sl-pt-xxl-350 {
    padding-top: 14px !important;
  }
  .sl-pb-xxl-350 {
    padding-bottom: 14px !important;
  }
  .sl-pl-xxl-350 {
    padding-left: 14px !important;
  }
  .sl-pr-xxl-350 {
    padding-right: 14px !important;
  }
  .sl-p-xxl-400 {
    padding: 16px !important;
  }
  .sl-py-xxl-400 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sl-px-xxl-400 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sl-pt-xxl-400 {
    padding-top: 16px !important;
  }
  .sl-pb-xxl-400 {
    padding-bottom: 16px !important;
  }
  .sl-pl-xxl-400 {
    padding-left: 16px !important;
  }
  .sl-pr-xxl-400 {
    padding-right: 16px !important;
  }
  .sl-p-xxl-450 {
    padding: 18px !important;
  }
  .sl-py-xxl-450 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .sl-px-xxl-450 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .sl-pt-xxl-450 {
    padding-top: 18px !important;
  }
  .sl-pb-xxl-450 {
    padding-bottom: 18px !important;
  }
  .sl-pl-xxl-450 {
    padding-left: 18px !important;
  }
  .sl-pr-xxl-450 {
    padding-right: 18px !important;
  }
  .sl-p-xxl-500 {
    padding: 20px !important;
  }
  .sl-py-xxl-500 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sl-px-xxl-500 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sl-pt-xxl-500 {
    padding-top: 20px !important;
  }
  .sl-pb-xxl-500 {
    padding-bottom: 20px !important;
  }
  .sl-pl-xxl-500 {
    padding-left: 20px !important;
  }
  .sl-pr-xxl-500 {
    padding-right: 20px !important;
  }
  .sl-p-xxl-550 {
    padding: 22px !important;
  }
  .sl-py-xxl-550 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  .sl-px-xxl-550 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .sl-pt-xxl-550 {
    padding-top: 22px !important;
  }
  .sl-pb-xxl-550 {
    padding-bottom: 22px !important;
  }
  .sl-pl-xxl-550 {
    padding-left: 22px !important;
  }
  .sl-pr-xxl-550 {
    padding-right: 22px !important;
  }
  .sl-p-xxl-600 {
    padding: 24px !important;
  }
  .sl-py-xxl-600 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sl-px-xxl-600 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sl-pt-xxl-600 {
    padding-top: 24px !important;
  }
  .sl-pb-xxl-600 {
    padding-bottom: 24px !important;
  }
  .sl-pl-xxl-600 {
    padding-left: 24px !important;
  }
  .sl-pr-xxl-600 {
    padding-right: 24px !important;
  }
  .sl-p-xxl-650 {
    padding: 28px !important;
  }
  .sl-py-xxl-650 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sl-px-xxl-650 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sl-pt-xxl-650 {
    padding-top: 28px !important;
  }
  .sl-pb-xxl-650 {
    padding-bottom: 28px !important;
  }
  .sl-pl-xxl-650 {
    padding-left: 28px !important;
  }
  .sl-pr-xxl-650 {
    padding-right: 28px !important;
  }
  .sl-p-xxl-700 {
    padding: 32px !important;
  }
  .sl-py-xxl-700 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sl-px-xxl-700 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sl-pt-xxl-700 {
    padding-top: 32px !important;
  }
  .sl-pb-xxl-700 {
    padding-bottom: 32px !important;
  }
  .sl-pl-xxl-700 {
    padding-left: 32px !important;
  }
  .sl-pr-xxl-700 {
    padding-right: 32px !important;
  }
  .sl-p-xxl-750 {
    padding: 36px !important;
  }
  .sl-py-xxl-750 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .sl-px-xxl-750 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .sl-pt-xxl-750 {
    padding-top: 36px !important;
  }
  .sl-pb-xxl-750 {
    padding-bottom: 36px !important;
  }
  .sl-pl-xxl-750 {
    padding-left: 36px !important;
  }
  .sl-pr-xxl-750 {
    padding-right: 36px !important;
  }
  .sl-p-xxl-800 {
    padding: 40px !important;
  }
  .sl-py-xxl-800 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sl-px-xxl-800 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sl-pt-xxl-800 {
    padding-top: 40px !important;
  }
  .sl-pb-xxl-800 {
    padding-bottom: 40px !important;
  }
  .sl-pl-xxl-800 {
    padding-left: 40px !important;
  }
  .sl-pr-xxl-800 {
    padding-right: 40px !important;
  }
  .sl-p-xxl-825 {
    padding: 44px !important;
  }
  .sl-py-xxl-825 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .sl-px-xxl-825 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .sl-pt-xxl-825 {
    padding-top: 44px !important;
  }
  .sl-pb-xxl-825 {
    padding-bottom: 44px !important;
  }
  .sl-pl-xxl-825 {
    padding-left: 44px !important;
  }
  .sl-pr-xxl-825 {
    padding-right: 44px !important;
  }
  .sl-p-xxl-850 {
    padding: 48px !important;
  }
  .sl-py-xxl-850 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sl-px-xxl-850 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sl-pt-xxl-850 {
    padding-top: 48px !important;
  }
  .sl-pb-xxl-850 {
    padding-bottom: 48px !important;
  }
  .sl-pl-xxl-850 {
    padding-left: 48px !important;
  }
  .sl-pr-xxl-850 {
    padding-right: 48px !important;
  }
  .sl-p-xxl-900 {
    padding: 56px !important;
  }
  .sl-py-xxl-900 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sl-px-xxl-900 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sl-pt-xxl-900 {
    padding-top: 56px !important;
  }
  .sl-pb-xxl-900 {
    padding-bottom: 56px !important;
  }
  .sl-pl-xxl-900 {
    padding-left: 56px !important;
  }
  .sl-pr-xxl-900 {
    padding-right: 56px !important;
  }
  .sl-p-xxl-925 {
    padding: 60px !important;
  }
  .sl-py-xxl-925 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sl-px-xxl-925 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sl-pt-xxl-925 {
    padding-top: 60px !important;
  }
  .sl-pb-xxl-925 {
    padding-bottom: 60px !important;
  }
  .sl-pl-xxl-925 {
    padding-left: 60px !important;
  }
  .sl-pr-xxl-925 {
    padding-right: 60px !important;
  }
  .sl-p-xxl-950 {
    padding: 64px !important;
  }
  .sl-py-xxl-950 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sl-px-xxl-950 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sl-pt-xxl-950 {
    padding-top: 64px !important;
  }
  .sl-pb-xxl-950 {
    padding-bottom: 64px !important;
  }
  .sl-pl-xxl-950 {
    padding-left: 64px !important;
  }
  .sl-pr-xxl-950 {
    padding-right: 64px !important;
  }
  .sl-p-xxl-1200 {
    padding: 96px !important;
  }
  .sl-py-xxl-1200 {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .sl-px-xxl-1200 {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .sl-pt-xxl-1200 {
    padding-top: 96px !important;
  }
  .sl-pb-xxl-1200 {
    padding-bottom: 96px !important;
  }
  .sl-pl-xxl-1200 {
    padding-left: 96px !important;
  }
  .sl-pr-xxl-1200 {
    padding-right: 96px !important;
  }
  .sl-p-xxl-n50 {
    padding: -2px !important;
  }
  .sl-py-xxl-n50 {
    padding-top: -2px !important;
    padding-bottom: -2px !important;
  }
  .sl-px-xxl-n50 {
    padding-left: -2px !important;
    padding-right: -2px !important;
  }
  .sl-pt-xxl-n50 {
    padding-top: -2px !important;
  }
  .sl-pb-xxl-n50 {
    padding-bottom: -2px !important;
  }
  .sl-pl-xxl-n50 {
    padding-left: -2px !important;
  }
  .sl-pr-xxl-n50 {
    padding-right: -2px !important;
  }
  .sl-p-xxl-n100 {
    padding: -4px !important;
  }
  .sl-py-xxl-n100 {
    padding-top: -4px !important;
    padding-bottom: -4px !important;
  }
  .sl-px-xxl-n100 {
    padding-left: -4px !important;
    padding-right: -4px !important;
  }
  .sl-pt-xxl-n100 {
    padding-top: -4px !important;
  }
  .sl-pb-xxl-n100 {
    padding-bottom: -4px !important;
  }
  .sl-pl-xxl-n100 {
    padding-left: -4px !important;
  }
  .sl-pr-xxl-n100 {
    padding-right: -4px !important;
  }
  .sl-p-xxl-n150 {
    padding: -6px !important;
  }
  .sl-py-xxl-n150 {
    padding-top: -6px !important;
    padding-bottom: -6px !important;
  }
  .sl-px-xxl-n150 {
    padding-left: -6px !important;
    padding-right: -6px !important;
  }
  .sl-pt-xxl-n150 {
    padding-top: -6px !important;
  }
  .sl-pb-xxl-n150 {
    padding-bottom: -6px !important;
  }
  .sl-pl-xxl-n150 {
    padding-left: -6px !important;
  }
  .sl-pr-xxl-n150 {
    padding-right: -6px !important;
  }
  .sl-p-xxl-n200 {
    padding: -8px !important;
  }
  .sl-py-xxl-n200 {
    padding-top: -8px !important;
    padding-bottom: -8px !important;
  }
  .sl-px-xxl-n200 {
    padding-left: -8px !important;
    padding-right: -8px !important;
  }
  .sl-pt-xxl-n200 {
    padding-top: -8px !important;
  }
  .sl-pb-xxl-n200 {
    padding-bottom: -8px !important;
  }
  .sl-pl-xxl-n200 {
    padding-left: -8px !important;
  }
  .sl-pr-xxl-n200 {
    padding-right: -8px !important;
  }
  .sl-p-xxl-n250 {
    padding: -10px !important;
  }
  .sl-py-xxl-n250 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .sl-px-xxl-n250 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
  .sl-pt-xxl-n250 {
    padding-top: -10px !important;
  }
  .sl-pb-xxl-n250 {
    padding-bottom: -10px !important;
  }
  .sl-pl-xxl-n250 {
    padding-left: -10px !important;
  }
  .sl-pr-xxl-n250 {
    padding-right: -10px !important;
  }
  .sl-p-xxl-n300 {
    padding: -12px !important;
  }
  .sl-py-xxl-n300 {
    padding-top: -12px !important;
    padding-bottom: -12px !important;
  }
  .sl-px-xxl-n300 {
    padding-left: -12px !important;
    padding-right: -12px !important;
  }
  .sl-pt-xxl-n300 {
    padding-top: -12px !important;
  }
  .sl-pb-xxl-n300 {
    padding-bottom: -12px !important;
  }
  .sl-pl-xxl-n300 {
    padding-left: -12px !important;
  }
  .sl-pr-xxl-n300 {
    padding-right: -12px !important;
  }
  .sl-p-xxl-n350 {
    padding: -14px !important;
  }
  .sl-py-xxl-n350 {
    padding-top: -14px !important;
    padding-bottom: -14px !important;
  }
  .sl-px-xxl-n350 {
    padding-left: -14px !important;
    padding-right: -14px !important;
  }
  .sl-pt-xxl-n350 {
    padding-top: -14px !important;
  }
  .sl-pb-xxl-n350 {
    padding-bottom: -14px !important;
  }
  .sl-pl-xxl-n350 {
    padding-left: -14px !important;
  }
  .sl-pr-xxl-n350 {
    padding-right: -14px !important;
  }
  .sl-p-xxl-n400 {
    padding: -16px !important;
  }
  .sl-py-xxl-n400 {
    padding-top: -16px !important;
    padding-bottom: -16px !important;
  }
  .sl-px-xxl-n400 {
    padding-left: -16px !important;
    padding-right: -16px !important;
  }
  .sl-pt-xxl-n400 {
    padding-top: -16px !important;
  }
  .sl-pb-xxl-n400 {
    padding-bottom: -16px !important;
  }
  .sl-pl-xxl-n400 {
    padding-left: -16px !important;
  }
  .sl-pr-xxl-n400 {
    padding-right: -16px !important;
  }
  .sl-p-xxl-n450 {
    padding: -18px !important;
  }
  .sl-py-xxl-n450 {
    padding-top: -18px !important;
    padding-bottom: -18px !important;
  }
  .sl-px-xxl-n450 {
    padding-left: -18px !important;
    padding-right: -18px !important;
  }
  .sl-pt-xxl-n450 {
    padding-top: -18px !important;
  }
  .sl-pb-xxl-n450 {
    padding-bottom: -18px !important;
  }
  .sl-pl-xxl-n450 {
    padding-left: -18px !important;
  }
  .sl-pr-xxl-n450 {
    padding-right: -18px !important;
  }
  .sl-p-xxl-n500 {
    padding: -20px !important;
  }
  .sl-py-xxl-n500 {
    padding-top: -20px !important;
    padding-bottom: -20px !important;
  }
  .sl-px-xxl-n500 {
    padding-left: -20px !important;
    padding-right: -20px !important;
  }
  .sl-pt-xxl-n500 {
    padding-top: -20px !important;
  }
  .sl-pb-xxl-n500 {
    padding-bottom: -20px !important;
  }
  .sl-pl-xxl-n500 {
    padding-left: -20px !important;
  }
  .sl-pr-xxl-n500 {
    padding-right: -20px !important;
  }
  .sl-p-xxl-n550 {
    padding: -22px !important;
  }
  .sl-py-xxl-n550 {
    padding-top: -22px !important;
    padding-bottom: -22px !important;
  }
  .sl-px-xxl-n550 {
    padding-left: -22px !important;
    padding-right: -22px !important;
  }
  .sl-pt-xxl-n550 {
    padding-top: -22px !important;
  }
  .sl-pb-xxl-n550 {
    padding-bottom: -22px !important;
  }
  .sl-pl-xxl-n550 {
    padding-left: -22px !important;
  }
  .sl-pr-xxl-n550 {
    padding-right: -22px !important;
  }
  .sl-p-xxl-n600 {
    padding: -24px !important;
  }
  .sl-py-xxl-n600 {
    padding-top: -24px !important;
    padding-bottom: -24px !important;
  }
  .sl-px-xxl-n600 {
    padding-left: -24px !important;
    padding-right: -24px !important;
  }
  .sl-pt-xxl-n600 {
    padding-top: -24px !important;
  }
  .sl-pb-xxl-n600 {
    padding-bottom: -24px !important;
  }
  .sl-pl-xxl-n600 {
    padding-left: -24px !important;
  }
  .sl-pr-xxl-n600 {
    padding-right: -24px !important;
  }
  .sl-p-xxl-n650 {
    padding: -28px !important;
  }
  .sl-py-xxl-n650 {
    padding-top: -28px !important;
    padding-bottom: -28px !important;
  }
  .sl-px-xxl-n650 {
    padding-left: -28px !important;
    padding-right: -28px !important;
  }
  .sl-pt-xxl-n650 {
    padding-top: -28px !important;
  }
  .sl-pb-xxl-n650 {
    padding-bottom: -28px !important;
  }
  .sl-pl-xxl-n650 {
    padding-left: -28px !important;
  }
  .sl-pr-xxl-n650 {
    padding-right: -28px !important;
  }
  .sl-p-xxl-n700 {
    padding: -32px !important;
  }
  .sl-py-xxl-n700 {
    padding-top: -32px !important;
    padding-bottom: -32px !important;
  }
  .sl-px-xxl-n700 {
    padding-left: -32px !important;
    padding-right: -32px !important;
  }
  .sl-pt-xxl-n700 {
    padding-top: -32px !important;
  }
  .sl-pb-xxl-n700 {
    padding-bottom: -32px !important;
  }
  .sl-pl-xxl-n700 {
    padding-left: -32px !important;
  }
  .sl-pr-xxl-n700 {
    padding-right: -32px !important;
  }
  .sl-p-xxl-n750 {
    padding: -36px !important;
  }
  .sl-py-xxl-n750 {
    padding-top: -36px !important;
    padding-bottom: -36px !important;
  }
  .sl-px-xxl-n750 {
    padding-left: -36px !important;
    padding-right: -36px !important;
  }
  .sl-pt-xxl-n750 {
    padding-top: -36px !important;
  }
  .sl-pb-xxl-n750 {
    padding-bottom: -36px !important;
  }
  .sl-pl-xxl-n750 {
    padding-left: -36px !important;
  }
  .sl-pr-xxl-n750 {
    padding-right: -36px !important;
  }
  .sl-p-xxl-n800 {
    padding: -40px !important;
  }
  .sl-py-xxl-n800 {
    padding-top: -40px !important;
    padding-bottom: -40px !important;
  }
  .sl-px-xxl-n800 {
    padding-left: -40px !important;
    padding-right: -40px !important;
  }
  .sl-pt-xxl-n800 {
    padding-top: -40px !important;
  }
  .sl-pb-xxl-n800 {
    padding-bottom: -40px !important;
  }
  .sl-pl-xxl-n800 {
    padding-left: -40px !important;
  }
  .sl-pr-xxl-n800 {
    padding-right: -40px !important;
  }
  .sl-p-xxl-n850 {
    padding: -48px !important;
  }
  .sl-py-xxl-n850 {
    padding-top: -48px !important;
    padding-bottom: -48px !important;
  }
  .sl-px-xxl-n850 {
    padding-left: -48px !important;
    padding-right: -48px !important;
  }
  .sl-pt-xxl-n850 {
    padding-top: -48px !important;
  }
  .sl-pb-xxl-n850 {
    padding-bottom: -48px !important;
  }
  .sl-pl-xxl-n850 {
    padding-left: -48px !important;
  }
  .sl-pr-xxl-n850 {
    padding-right: -48px !important;
  }
  .sl-p-xxl-n900 {
    padding: -56px !important;
  }
  .sl-py-xxl-n900 {
    padding-top: -56px !important;
    padding-bottom: -56px !important;
  }
  .sl-px-xxl-n900 {
    padding-left: -56px !important;
    padding-right: -56px !important;
  }
  .sl-pt-xxl-n900 {
    padding-top: -56px !important;
  }
  .sl-pb-xxl-n900 {
    padding-bottom: -56px !important;
  }
  .sl-pl-xxl-n900 {
    padding-left: -56px !important;
  }
  .sl-pr-xxl-n900 {
    padding-right: -56px !important;
  }
  .sl-p-xxl-n925 {
    padding: -60px !important;
  }
  .sl-py-xxl-n925 {
    padding-top: -60px !important;
    padding-bottom: -60px !important;
  }
  .sl-px-xxl-n925 {
    padding-left: -60px !important;
    padding-right: -60px !important;
  }
  .sl-pt-xxl-n925 {
    padding-top: -60px !important;
  }
  .sl-pb-xxl-n925 {
    padding-bottom: -60px !important;
  }
  .sl-pl-xxl-n925 {
    padding-left: -60px !important;
  }
  .sl-pr-xxl-n925 {
    padding-right: -60px !important;
  }
  .sl-p-xxl-n950 {
    padding: -64px !important;
  }
  .sl-py-xxl-n950 {
    padding-top: -64px !important;
    padding-bottom: -64px !important;
  }
  .sl-px-xxl-n950 {
    padding-left: -64px !important;
    padding-right: -64px !important;
  }
  .sl-pt-xxl-n950 {
    padding-top: -64px !important;
  }
  .sl-pb-xxl-n950 {
    padding-bottom: -64px !important;
  }
  .sl-pl-xxl-n950 {
    padding-left: -64px !important;
  }
  .sl-pr-xxl-n950 {
    padding-right: -64px !important;
  }
  .sl-p-xxl-n1200 {
    padding: -96px !important;
  }
  .sl-py-xxl-n1200 {
    padding-top: -96px !important;
    padding-bottom: -96px !important;
  }
  .sl-px-xxl-n1200 {
    padding-left: -96px !important;
    padding-right: -96px !important;
  }
  .sl-pt-xxl-n1200 {
    padding-top: -96px !important;
  }
  .sl-pb-xxl-n1200 {
    padding-bottom: -96px !important;
  }
  .sl-pl-xxl-n1200 {
    padding-left: -96px !important;
  }
  .sl-pr-xxl-n1200 {
    padding-right: -96px !important;
  }
  .sl-p-xxl-auto {
    padding: auto !important;
  }
  .sl-py-xxl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .sl-px-xxl-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .sl-pt-xxl-auto {
    padding-top: auto !important;
  }
  .sl-pb-xxl-auto {
    padding-bottom: auto !important;
  }
  .sl-pl-xxl-auto {
    padding-left: auto !important;
  }
  .sl-pr-xxl-auto {
    padding-right: auto !important;
  }
}
.sl-opacity-0 {
  opacity: 0% !important;
}

.sl-opacity-100 {
  opacity: 20% !important;
}

.sl-opacity-500 {
  opacity: 50% !important;
}

.sl-opacity-900 {
  opacity: 80% !important;
}

.sl-font-capitalize {
  text-transform: capitalize !important;
  text-transform: capitalize !important;
}

.sl-font-size-100 {
  font-size: 10px !important;
}

.sl-font-size-200 {
  font-size: 12px !important;
}

.sl-font-size-300 {
  font-size: 14px !important;
}

.sl-font-size-400 {
  font-size: 16px !important;
}

.sl-font-size-450 {
  font-size: 18px !important;
}

.sl-font-size-500 {
  font-size: 20px !important;
}

.sl-font-size-600 {
  font-size: 24px !important;
}

.sl-font-size-700 {
  font-size: 29px !important;
}

.sl-font-size-800 {
  font-size: 35px !important;
}

.sl-font-size-900 {
  font-size: 42px !important;
}

.sl-font-size-1000 {
  font-size: 44px !important;
}

.sl-font-size-1100 {
  font-size: 46px !important;
}

.sl-font-size-1200 {
  font-size: 48px !important;
}

.sl-line-height-00 {
  line-height: 0px !important;
}

.sl-line-height-100 {
  line-height: 20px !important;
}

.sl-line-height-300 {
  line-height: 24px !important;
}

.sl-line-height-500 {
  line-height: 28px !important;
}

.sl-line-height-700 {
  line-height: 36px !important;
}

.sl-line-height-900 {
  line-height: 40px !important;
}

.sl-line-height-950 {
  line-height: 48px !important;
}

.sl-text-align-left {
  text-align: left;
}

.sl-text-align-right {
  text-align: right;
}

.sl-text-align-center {
  text-align: center;
}

.sl-font-regular {
  font-weight: 400 !important;
}

.sl-font-semibold {
  font-weight: 600 !important;
}

.sl-font-bold {
  font-weight: 700 !important;
}

.sl-h1, .sl-h1-bold, .sl-h1-semibold {
  color: #2E383A;
  font-size: 42px;
  line-height: 48px;
}
.sl-h1-semibold {
  font-weight: 600;
}
.sl-h1-bold {
  font-weight: 700;
}

.sl-h2, .sl-h2-bold, .sl-h2-semibold {
  color: #2E383A;
  font-size: 35px;
  line-height: 40px;
}
.sl-h2-semibold {
  font-weight: 600;
}
.sl-h2-bold {
  font-weight: 700;
}

.sl-h3, .sl-h3-bold, .sl-h3-semibold {
  color: #2E383A;
  font-size: 29px;
  line-height: 36px;
}
.sl-h3-semibold {
  font-weight: 600;
}
.sl-h3-bold {
  font-weight: 700;
}

.sl-h4, .sl-h4-bold, .sl-h4-semibold {
  color: #2E383A;
  font-size: 24px;
  line-height: 28px;
}
.sl-h4-semibold {
  font-weight: 600;
}
.sl-h4-bold {
  font-weight: 700;
}

.sl-h5, .sl-h5-bold, .sl-h5-semibold {
  color: #2E383A;
  font-size: 20px;
  line-height: 28px;
}
.sl-h5-semibold {
  font-weight: 600;
}
.sl-h5-bold {
  font-weight: 700;
}

.sl-h6, .sl-h6-bold, .sl-h6-semibold {
  color: #2E383A;
  font-size: 18px;
  line-height: 28px;
}
.sl-h6-semibold {
  font-weight: 600;
}
.sl-h6-bold {
  font-weight: 700;
}

.sl-body-large, .sl-body-large-bold, .sl-body-large-semibold, .sl-body-large-regular {
  color: #4C6569;
  font-size: 16px;
  line-height: 24px;
}
.sl-body-large-regular {
  color: #2E383A;
  font-weight: 400;
}
.sl-body-large-semibold {
  color: #2E383A;
  font-weight: 600;
}
.sl-body-large-bold {
  font-weight: 700;
}

.sl-body-medium, .sl-body-medium-bold, .sl-body-medium-semibold, .sl-body-medium-regular {
  color: #4C6569;
  font-size: 14px;
  line-height: 24px;
}
.sl-body-medium-regular {
  color: #2E383A;
  font-weight: 400;
}
.sl-body-medium-semibold {
  color: #2E383A;
  font-weight: 600;
}
.sl-body-medium-bold {
  font-weight: 700;
}

.sl-body-small, .sl-body-small-bold, .sl-body-small-semibold, .sl-body-small-regular {
  color: #4C6569;
  font-size: 12px;
  line-height: 20px;
}
.sl-body-small-regular {
  color: #2E383A;
  font-weight: 400;
}
.sl-body-small-semibold {
  color: #2E383A;
  font-weight: 600;
}
.sl-body-small-bold {
  font-weight: 700;
}

.sl-button-large, .sl-button-large-bold, .sl-button-large-semibold, .sl-button-large-regular {
  font-size: 16px;
  line-height: 24px;
}
.sl-button-large-regular {
  font-weight: 400;
}
.sl-button-large-semibold {
  font-weight: 600;
}
.sl-button-large-bold {
  font-weight: 700;
}

.sl-button-medium, .sl-button-medium-bold, .sl-button-medium-semibold, .sl-button-medium-regular {
  font-size: 14px;
  line-height: 24px;
}
.sl-button-medium-regular {
  font-weight: 400;
}
.sl-button-medium-semibold {
  font-weight: 600;
}
.sl-button-medium-bold {
  font-weight: 700;
}

.sl-button-small, .sl-button-small-bold, .sl-button-small-semibold, .sl-button-small-regular {
  font-size: 12px;
  line-height: 20px;
}
.sl-button-small-regular {
  font-weight: 400;
}
.sl-button-small-semibold {
  font-weight: 600;
}
.sl-button-small-bold {
  font-weight: 700;
}

.sl-label-medium, .sl-label-medium-semibold, .sl-label-medium-regular {
  font-size: 14px;
  line-height: 24px;
}
.sl-label-medium-regular {
  font-weight: 400;
}
.sl-label-medium-semibold {
  font-weight: 600;
}

.sl-label-small, .sl-label-small-semibold, .sl-label-small-regular {
  font-size: 12px;
  line-height: 20px;
}
.sl-label-small-regular {
  font-weight: 400;
}
.sl-label-small-semibold {
  font-weight: 600;
}

.sl-caption, .sl-caption-bold, .sl-caption-semibold, .sl-caption-regular {
  font-size: 10px;
  line-height: 20px;
}
.sl-caption-regular {
  font-weight: 400;
}
.sl-caption-semibold {
  font-weight: 600;
}
.sl-caption-bold {
  font-weight: 700;
}

.sl-link-medium, .sl-link-medium-semibold, .sl-link-medium-regular {
  font-size: 14px;
  line-height: 24px;
}
.sl-link-medium-regular {
  font-weight: 400;
}
.sl-link-medium-semibold {
  font-weight: 600;
}

.sl-h4-bold {
  color: #2E383A;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.sl-h3-bold {
  color: #2E383A;
  font-size: 29px;
  font-weight: 700;
  line-height: 36px;
}

.sl-h5-bold {
  color: #2E383A;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.sl-h5-semibold {
  color: #2E383A;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.sl-word-break-all {
  word-break: break-all;
}

.sl-elevation-01 {
  background: #ffffff;
  border: 1px solid #D5DFE1;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
}

.sl-border-radius-50 {
  border-radius: 0px !important;
}

.sl-border-radius-100 {
  border-radius: 2px !important;
}

.sl-border-radius-300 {
  border-radius: 4px !important;
}

.sl-border-radius-500 {
  border-radius: 6px !important;
}

.sl-border-radius-700 {
  border-radius: 8px !important;
}

.sl-border-radius-900 {
  border-radius: 50% !important;
}

.sl-border-radius-50-bottom {
  border-radius: 0 0 0px 0px !important;
}

.sl-border-radius-100-bottom {
  border-radius: 0 0 2px 2px !important;
}

.sl-border-radius-300-bottom {
  border-radius: 0 0 4px 4px !important;
}

.sl-border-radius-500-bottom {
  border-radius: 0 0 6px 6px !important;
}

.sl-border-radius-700-bottom {
  border-radius: 0 0 8px 8px !important;
}

.sl-border-radius-900-bottom {
  border-radius: 0 0 50% 50% !important;
}

.sl-border-radius50-top {
  border-radius: 0px 0px 0 0 !important;
}

.sl-border-radius100-top {
  border-radius: 2px 2px 0 0 !important;
}

.sl-border-radius300-top {
  border-radius: 4px 4px 0 0 !important;
}

.sl-border-radius500-top {
  border-radius: 6px 6px 0 0 !important;
}

.sl-border-radius700-top {
  border-radius: 8px 8px 0 0 !important;
}

.sl-border-radius900-top {
  border-radius: 50% 50% 0 0 !important;
}

.sl-background-01 {
  background: #F8F8F8 !important;
}

.sl-background-02 {
  background: #EAEEF0 !important;
}

.sl-background-03 {
  background: #ABC0C4 !important;
}

.sl-background-04 {
  background: #ffffff !important;
}

.sl-background-05 {
  background: #FBF2D0 !important;
}

.sl-background-06 {
  background: #CFDADD !important;
}

.sl-background-07 {
  background: #93AFB4 !important;
}

.sl-background-08 {
  background: linear-gradient(#3EAC93, #387B77) !important;
}

.sl-background-09 {
  background: #2E383A !important;
}

.sl-background-10 {
  background: #387B77 !important;
}

.sl-background-11 {
  background: #EEE5C0 !important;
}

.sl-background-12 {
  background: #5A4B35 !important;
}

.sl-background-13 {
  background: #7B9BA1 !important;
}

.sl-background-14 {
  background: #FCF8ED !important;
}

.sl-background-15 {
  background: #F7EABB !important;
}

.sl-background-16 {
  background: #3BC693 !important;
}

.sl-background-dataviz-1 {
  background: #9DB2FF !important;
}

.sl-background-dataviz-2 {
  background: #FFA984 !important;
}

.sl-background-dataviz-3 {
  background: #EFC5FD !important;
}

.sl-background-dataviz-4 {
  background: #FFDA7D !important;
}

.sl-background-dataviz-5 {
  background: #F28790 !important;
}

.sl-background-dataviz-6 {
  background: #849FFF !important;
}

.sl-background-dataviz-7 {
  background: #FF9365 !important;
}

.sl-background-dataviz-8 {
  background: #EBB6FD !important;
}

.sl-background-dataviz-9 {
  background: #FFD15D !important;
}

.sl-background-dataviz-10 {
  background: #EF6773 !important;
}

.sl-background-success-02 {
  background: #BFF5C5 !important;
}

.sl-action-secondary-default {
  background: #D5DFE1 !important;
}

.sl-border-01 {
  border: 1px solid #3EAC93 !important;
}

.sl-border-02 {
  border: 1px solid #ffffff !important;
}

.sl-border-03 {
  border: 1px solid #CFDADD !important;
}

.sl-border-04 {
  border: 1px solid #D5DFE1 !important;
}

.sl-border-05 {
  border: 1px solid #ECFEEE !important;
}

.sl-border-06 {
  border: 1px solid #EEE5C0 !important;
}

.sl-border-top-01 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #3EAC93;
}

.sl-border-top-02 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #ffffff;
}

.sl-border-top-03 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #CFDADD;
}

.sl-border-top-04 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #D5DFE1;
}

.sl-border-top-05 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #ECFEEE;
}

.sl-border-top-06 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #EEE5C0;
}

.sl-border-bottom-01 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #3EAC93;
}

.sl-border-bottom-02 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #ffffff;
}

.sl-border-bottom-03 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #CFDADD;
}

.sl-border-bottom-04 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #D5DFE1;
}

.sl-border-bottom-05 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #ECFEEE;
}

.sl-border-bottom-06 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #EEE5C0;
}

.sl-border-left-01 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #3EAC93;
}

.sl-border-left-02 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #ffffff;
}

.sl-border-left-03 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #CFDADD;
}

.sl-border-left-04 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #D5DFE1;
}

.sl-border-left-05 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #ECFEEE;
}

.sl-border-left-06 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-left: 1px solid #EEE5C0;
}

.sl-border-right-01 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #3EAC93;
}

.sl-border-right-02 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #ffffff;
}

.sl-border-right-03 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #CFDADD;
}

.sl-border-right-04 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #D5DFE1;
}

.sl-border-right-05 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #ECFEEE;
}

.sl-border-right-06 {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-right: 1px solid #EEE5C0;
}

.sl-icon-xxs {
  font-size: 12px !important;
}

.sl-icon-xs {
  font-size: 16px !important;
}

.sl-icon-sm {
  font-size: 20px !important;
}

.sl-icon-md {
  font-size: 24px !important;
}

.sl-icon-lg {
  font-size: 32px !important;
}

.sl-icon-size-xxs {
  width: 12px;
  height: 12px;
}

.sl-icon-size-xs {
  width: 16px;
  height: 16px;
}

.sl-icon-size-sm {
  width: 20px;
  height: 20px;
}

.sl-icon-size-md {
  width: 24px;
  height: 24px;
}

.sl-icon-size-lg {
  width: 32px;
  height: 32px;
}

.sl-flag {
  content: "\a";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  background: #ED352E;
  border: 2px solid white;
}

.sl-flag-icon .sl-flag {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}
.sl-flag-icon .sl-flag-hide {
  animation: flagDisappear 0.5s forwards;
}

@keyframes flagDisappear {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
/*custom positions icons flag*/
.sl-icon-chat .sl-flag {
  top: 0px;
  right: 0px;
}

.sl-icon-user .sl-flag {
  top: 0px;
  right: 0px;
}

.sl-zindex-100 {
  z-index: 100 !important;
}

.sl-zindex-200 {
  z-index: 200 !important;
}

.sl-zindex-300 {
  z-index: 300 !important;
}

.sl-zindex-400 {
  z-index: 400 !important;
}

.sl-zindex-500 {
  z-index: 500 !important;
}

.sl-zindex-600 {
  z-index: 600 !important;
}

.sl-zindex-700 {
  z-index: 700 !important;
}

.sl-zindex-800 {
  z-index: 800 !important;
}

.sl-zindex-900 {
  z-index: 900 !important;
}

/*

    Sales Layer color palette

*/
.color-green-100 {
  color: #EDF9F5;
}

.color-green-200 {
  color: #DBF4EB;
}

.color-green-300 {
  color: #A5E5CD;
}

.color-green-400 {
  color: #70D5B0;
}

.color-green-500 {
  color: #3BC693;
}

.color-green-600 {
  color: #36B486;
}

.color-green-700 {
  color: #31A381;
}

.color-green-800 {
  color: #267F6D;
}

.color-green-900 {
  color: #1B5A55;
}

.color-charcoal-100 {
  color: #AAB8C4;
}

.color-charcoal-200 {
  color: #899BAD;
}

.color-charcoal-300 {
  color: #677F96;
}

.color-charcoal-400 {
  color: #46637F;
}

.color-charcoal-500 {
  color: #405A74;
}

.color-charcoal-600 {
  color: #3A5268;
}

.color-charcoal-700 {
  color: #2D4051;
}

.color-charcoal-800 {
  color: #202D3A;
}

.color-silver-100 {
  color: #F9FAFC;
}

.color-silver-200 {
  color: #F4F6F9;
}

.color-silver-300 {
  color: #EAEEF2;
}

.color-silver-400 {
  color: #DFE5EC;
}

.color-silver-500 {
  color: #D5DDE5;
}

.color-silver-600 {
  color: #CBD4DE;
}

.color-silver-700 {
  color: #C1CBD7;
}

.color-silver-800 {
  color: #B6C3D1;
}

.color-cyan-100 {
  color: #EFFCFC;
}

.color-cyan-200 {
  color: #D1F7F5;
}

.color-cyan-300 {
  color: #A3EFEC;
}

.color-cyan-400 {
  color: #85E9E4;
}

.color-cyan-500 {
  color: #58E2D8;
}

.color-cyan-600 {
  color: #50CEC8;
}

.color-cyan-700 {
  color: #49B9B6;
}

.color-cyan-800 {
  color: #399098;
}

.color-cyan-900 {
  color: #2A666F;
}

.color-blue-100 {
  color: #EEF7FF;
}

.color-blue-200 {
  color: #CAE6FF;
}

.color-blue-300 {
  color: #85C4FF;
}

.color-blue-400 {
  color: #3378FF;
}

.color-blue-500 {
  color: #193BFF;
}

.color-blue-600 {
  color: #1736E8;
}

.color-blue-700 {
  color: #1531D1;
}

.color-blue-800 {
  color: #132BBA;
}

.color-blue-900 {
  color: #0E218C;
}

.color-red-100 {
  color: #FFEEED;
}

.color-red-200 {
  color: #FFCCCB;
}

.color-red-300 {
  color: #FF9A97;
}

.color-red-400 {
  color: #FF6561;
}

.color-red-500 {
  color: #FF4742;
}

.color-red-600 {
  color: #E8413C;
}

.color-red-700 {
  color: #D33B37;
}

.color-red-800 {
  color: #A92D29;
}

.color-red-900 {
  color: #791D1B;
}

.color-yellow-100 {
  color: #FFFAF0;
}

.color-yellow-200 {
  color: #FFF2D3;
}

.color-yellow-300 {
  color: #FFE6A8;
}

.color-yellow-400 {
  color: #FFDA7D;
}

.color-yellow-500 {
  color: #FFD261;
}

.color-yellow-600 {
  color: #F0B459;
}

.color-yellow-700 {
  color: #C88240;
}

.color-yellow-800 {
  color: #9B5831;
}

.color-yellow-900 {
  color: #843F26;
}

.color-orange-100 {
  color: #FFF5EF;
}

.color-orange-200 {
  color: #FFECDF;
}

.color-orange-300 {
  color: #FFBF92;
}

.color-orange-400 {
  color: #FFAD73;
}

.color-orange-500 {
  color: #FF9B54;
}

.color-orange-600 {
  color: #FA6F0C;
}

.color-orange-700 {
  color: #CA590A;
}

.color-orange-800 {
  color: #984408;
}

.color-orange-900 {
  color: #633204;
}

.color-info-500 {
  color: #2977D1;
}

@font-face {
  font-family: "SFMono";
  src: url("legacy/fonts/SFMonoLight.woff") format("woff");
}
html {
  overflow-y: auto;
}

/* previous chrome */
html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  background: #f0f0f0;
}

html::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}

html::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

html, body {
  background: #ecf0f1;
}

body {
  overflow: hidden !important;
} /* overflow: auto !important */
.nm-ie8-bug {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1px;
  line-height: 1px;
  height: 1px;
}

a, a:link, input:focus, select:focus, option:focus, textarea:focus, .btn:focus, .btn.active:focus {
  text-decoration: none;
  outline: 0 !important;
}

::-moz-focus-inner {
  text-decoration: none;
  outline: 0 !important;
  border: 0 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0 !important;
  text-shadow: 0 0 0 transparent !important;
}

select:not(:focus) {
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}

option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn {
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn.not-click {
  cursor: default;
}

h1, h2, h3, h4, h5, h6, span.legacy-h7 {
  display: block;
}

span.legacy-h7 {
  font-size: 16px;
}

hr {
  border-color: #D5DFE1;
}

hr.min {
  margin: 5px 0;
}

hr.form_hid {
  margin: 0;
  border-color: rgba(0, 0, 0, 0);
}

hr.form_min {
  margin: 0 0 10px;
}

hr.form_med {
  margin: 20px 0;
}

hr.form_med.and_ttl {
  margin-bottom: 0;
}

hr.form_max {
  margin: 40px 0;
}

hr.form_max.and_ttl {
  margin-bottom: 20px;
}

em.prsbc {
  font-size: 16px;
  line-height: 16px;
  padding-right: 10px;
}

i.tab {
  position: relative;
  overflow: hidden;
  display: inline-block;
  min-width: 15px;
}

.subx {
  font-weight: normal;
  font-size: 70%;
}

.icon.dml {
  font-size: 18px;
  line-height: 22px;
}

.icon.ini_med {
  vertical-align: middle;
  font-size: 115%;
  margin-right: 5px;
}

.icon.med {
  vertical-align: middle;
}

.icon.ini.mrg_lft,
.icon.mrg_lft {
  margin-right: 0;
  margin-left: 10px;
}

.icon.ini.no_mrg {
  margin: 0;
}

.icon.no_pdg,
.btn .icon.no_pdg {
  padding: 0 !important;
}

.icon.thin {
  color: #60758a;
}

.icon.good {
  color: #36B486;
}

.icon.wrng {
  color: #f1c40f;
}

.icon.alrt {
  color: #e74c3c;
}

.icon.low {
  color: rgba(0, 0, 0, 0.3);
}

.icon.clr {
  color: rgba(0, 0, 0, 0.1);
}

.icon.mrg_rgt {
  margin-right: 5px;
}

.help-block .icon {
  vertical-align: middle;
  padding-right: 5px;
}

.image_icon {
  position: relative;
  overflow: hidden;
  float: left;
  margin: 0 20px 20px 0;
}

.txt_icon {
  display: inline-block;
  padding-left: 10px;
}

.txt_icon strong {
  font-weight: 600;
}

.to_ico {
  margin-left: 10px;
  font-style: normal;
}

table th .glyphicons::before {
  vertical-align: -2px;
}

.disabled, .data-info {
  display: none;
}

.input-group .disabled {
  display: inline;
  background-color: #f4f6f6;
  border-color: #d5dbdb;
}

.text-low {
  color: rgba(0, 0, 0, 0.3);
}

.text-med {
  color: rgba(0, 0, 0, 0.5);
}

.text-ornt-rgt {
  direction: rtl !important;
}

.form-group.focus .input-group-btn .btn.disabled,
.input-group.focus .input-group-btn .btn.disabled {
  color: #bdc3c7;
  background-color: #f4f6f6;
  border-color: #d5dbdb;
}

.alert-danger .icon.ini,
.alert-error .icon.ini,
.alert .icon.ini.alrt,
.icon.alrt {
  color: #55201E;
}

.icon.low {
  color: #bdc3c7;
}

.alert .icon.ini.sl-icon-info {
  color: #005583;
}
.alert .icon.ini.sl-icon-warning {
  color: #653600;
}
.alert .icon.ini.sl-icon-error {
  color: #55201E;
}
.alert .icon.ini.sl-icon-success {
  color: #05570A;
}

.bx_alrt_ttl {
  display: inline-block;
  width: 100%;
}

.alert h6.bx_alrt_ttl {
  margin-bottom: 0;
}

.bx_alrt_ttl .icon {
  display: block;
  float: left;
  margin-right: 15px;
}

.bx_alrt_ttl .tx_ttl {
  display: block;
  float: left;
  width: 90%;
}

.label-half {
  font-size: 50%;
}

.label-default {
  background: #FBF2D0;
  color: #5A4B35;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 2px 12px;
}

.label-neutral {
  background-color: #D5DFE1;
  color: #2E383A;
}

#box_menu .label-default {
  background-color: #ebedef;
  color: #7b8996;
}

.btn .icon.left {
  padding: 0 0 0 5px;
}

.btn .icon.right {
  padding: 0 5px 0 0;
}

.btn .icon.alone {
  padding: 0;
}

.btn.btn-xs.txt {
  padding: 2px 8px;
  vertical-align: middle;
  margin: 0 5px;
}

.btn.auto_trn_fld .icon {
  padding: 0;
  font-size: 130%;
  line-height: 100%;
}

.btn.btn-slc {
  height: 36px;
  padding: 6px 15px;
}

.form-group .btn-group select {
  display: inline;
  max-width: 250px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn .label {
  border-radius: 50%;
  margin-left: 5px;
}

.dropdown-toggle .filter-option.title.med {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}

.icon.equ, .btn .icon.equ {
  font-size: 100%;
  line-height: 100%;
}

.btn .icon.equ {
  margin-right: 8px;
}

.icon.med, .btn .icon.med {
  font-size: 80%;
  vertical-align: middle;
}

.icon.lft {
  font-size: 100%;
  line-height: 100%;
  vertical-align: middle;
  padding-right: 5px;
}

.icon.deco {
  color: rgba(0, 0, 0, 0.3);
}

.icon.bullet {
  background: #999;
  color: #fff;
  padding: 10px 10px 6px;
  border-radius: 50%;
  font-size: 115%;
  line-height: 115%;
}

.icon.bullet.low {
  background: #bbb;
  font-size: 85%;
  line-height: 85%;
}

.icon.bullet.low.alrt {
  background: #f1c40f;
}

.icon.invert {
  overflow: hidden;
  padding: 3px;
  position: relative;
  border-radius: 5px;
  float: left;
  width: 25px;
  height: 23px;
  background: #999;
  color: #fff;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
}

.link_sim:hover {
  cursor: pointer;
  color: #387B77;
}

.glyphicons.rotate90::before {
  transform: rotate(90deg);
}

.pagination {
  height: 42px;
}

.pagination ul {
  overflow: hidden;
}

.pagination ul li > a,
.pagination ul li > span {
  height: 40px;
}

.pagination.no_mrgn {
  margin: 0 0 0 8px;
}

.untouch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.sep_top,
.alert.sep_top {
  margin-top: 20px;
}

.sep_bottom {
  margin-bottom: 24px;
}

.pad_bottom {
  padding-bottom: 20px;
}

.no_pdg {
  padding: 0 !important;
}

.bg-white {
  background-color: white;
}

@media (max-width: 767px) {
  .pagination ul {
    padding: 0;
  }
}
.no_mrtp {
  margin-top: 0 !important;
}

.no_mrbt {
  margin-bottom: 0 !important;
}

.form {
  position: relative;
  display: block;
}

.form.clr {
  overflow: hidden;
}

.sep_btm,
.form.sep_btm {
  margin-bottom: 10px;
}

.form-group.sep_btm {
  margin-bottom: 12px;
}

.sep_btm.dbl,
.form.sep_btm.dbl,
.form-group.sep_btm.dbl {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.form-group.sep_top {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 20px;
}

.sep_ln_top,
.form-group.sep_ln_top {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 10px;
}

.form-group.sep_cl_top {
  margin-top: 20px;
}

/* Quitar el overflow: hidden genera problemas. Si es preciso reponerlo habría que ver de aplicarlo en el sitio exacto donde se requiere la modicación */
.form-group.overflow {
  overflow: hidden;
}

.form-group.block .row {
  padding-bottom: 10px;
}

.form-group.no_sep {
  margin-bottom: 0;
}

.sep_ul_btm > ul {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.btn-middle {
  vertical-align: middle;
}

.btn-clean {
  display: inline;
  margin-right: 10px;
  padding: 0;
  border: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0.8);
}

.btn-clean.check {
  color: rgba(0, 0, 0, 0.3);
  line-height: 1;
  font-size: 17px;
  margin-right: 8px; /*vertical-align:-9px;*/
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}

.btn-clean.check:hover {
  color: rgba(0, 0, 0, 0.6);
}

.btn-clean.check i {
  float: left;
}

.btn-clean[disabled] {
  background: transparent;
  opacity: 1;
}

label .btn-clean.check {
  margin: 0;
  min-height: 32px;
}

label .btn-clean.check i {
  padding: 0;
  line-height: 60%;
}

.btn-group.bx_left ul {
  right: auto;
  left: 0;
}

.btn-group .btn_rgt {
  float: right;
}

.form .btn-group .dropdown-menu {
  min-width: 100%;
  overflow: hidden;
  z-index: 1;
}

.input-group-btn .btn {
  border: 1px solid #D5DFE1;
  border-right: none;
  color: #2E383A;
  height: 40px;
  overflow: hidden;
}

.input-group-btn .btn .icon {
  padding: 0;
  font-size: 14px;
  line-height: 14px;
}

form[name=product_formats] .input-group-btn .btn .icon {
  font-size: 20px;
}
form[name=product_formats] .input-group-btn .btn-default {
  padding: 8px 9px;
}

.input-group-btn .btn.btn-icon {
  padding: 10px;
}
.input-group-btn .btn.btn-icon .icon {
  font-size: 20px;
}

.form .msg_void {
  color: #7B9BA1;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.form .box_desc {
  position: relative;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #4C6569;
  margin-top: 8px;
  margin-bottom: 16px;
}

.form .button_rfrsh_conn + .box_desc {
  position: absolute;
  top: -36px;
  right: 0;
}

label.hdr {
  display: block;
}

input, textarea, select, option {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select, select.form-control {
  -ms-overflow-style: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  /*min-width: fit-content;*/
  width: 100%;
  padding: 8px 26px 8px 12px;
}

select.form-control[disabled] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

select::-ms-expand {
  display: none;
}

button:focus, :focus {
  outline: 0 !important;
}

option {
  padding: 5px 10px;
}

select.input-sm {
  line-height: 100%;
  min-height: 40px;
}

ul.check_list {
  position: relative;
  padding-left: 0;
  list-style: none;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.check_list_vertical ul.check_list li {
  width: 100%;
}

ul.check_list li {
  display: inline-block;
  vertical-align: top;
  padding-right: 30px;
  min-height: 32px;
}

ul.check_list li label {
  margin: 8px 0;
}

.col_list ul.check_list li {
  width: 31%;
}

.form ul.check_list {
  margin-bottom: 0;
  width: 100%;
}

.form .page_fields ul.check_list {
  margin-bottom: 15px;
}

.page_fields ul.check_list li {
  width: 25%;
  max-width: 272px;
}

.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  padding-left: 1px;
}

ul.check_list li.checkbox_head {
  display: inline-block;
  overflow: hidden;
  float: none;
  width: 100%;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  font-size: 90%;
  line-height: 90%;
  padding: 15px 0 0 0;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

ul.check_list li.checkbox_head label.checkbox {
  display: inline-block;
  margin-top: 0;
}

ul.check_list label.list_not_translated {
  font-style: italic;
  color: #AAA;
}

.nav-tabs > li > a {
  color: #387B77;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: 0.1s linear;
  transition: color 0.1s linear, background 0.1s linear;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background: #387B77;
  color: #fff;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: #d5d9dc #E1E4E7 #ecf0f1;
  border-bottom-width: 3px;
  border-top: 3px solid #d5d9dc;
  background: #ecf0f1;
}

.nav-tabs > li.disabled > a {
  color: #c0c5ca;
}

.nav-tabs > li em {
  font-weight: normal;
}

.nav-tabs label {
  margin: 0;
}

.box_msg_action .alert:last-child {
  margin-bottom: 0;
}

.modal-body .paragrh.box_msg_action {
  padding: 0;
}

.modal_prld {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 35px;
  background: url(../../../img/prld_mina.gif) center center no-repeat;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content {
  background-color: #fff;
  border: 0;
}

.modal-footer button,
.modal-footer a {
  height: 40px;
}

.modal-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal-title .icon {
  display: none;
}

.modal-title i {
  font-weight: 400;
}

.modal-footer .logo {
  position: relative;
  float: left;
  overflow: hidden;
  height: 80%;
  margin-right: 10px;
}

.dropdown-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-backdrop {
  display: none;
}

.dropdown-header {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 24px;
  margin: 0 12px;
  color: #2E383A;
  font-weight: 600;
}

.form .btn-group .dropdown-large, .dropdown-large {
  max-height: 350px;
  overflow-y: auto;
}

.form .btn-group .dropdown-short, .dropdown-short {
  max-height: 150px;
  overflow-y: auto;
}

.alertLag,
img.prld {
  display: none;
}

img.xld {
  visibility: hidden;
}

.nav-tabs .icon {
  font-size: 115%;
  vertical-align: middle;
}

.nav-tabs .icon.med {
  font-size: 100%;
  line-height: 100%;
}

.nav-tabs a > button.btn-clean {
  vertical-align: bottom;
}

.tooltip, .sl-tooltip-content {
  z-index: 1000000;
}

.tooltip.image,
.tooltip.image .tooltip-inner {
  width: auto;
  max-width: none;
}

.msg_tooltip {
  cursor: pointer;
}

ul.tooltip-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
}

.tooltip-list-item {
  margin-top: 8px;
  text-align: left;
}

.tooltip-list-item:nth-child(1) {
  margin-top: 0px;
}

.tooltip-list-icon {
  margin-right: 10px;
  color: #A7AEB2;
}

.tooltip strong {
  font-weight: 600;
  white-space: nowrap;
}

.ui-slider {
  background-color: rgba(0, 0, 0, 0.1);
}

.ui-slider-segment {
  background-color: rgba(0, 0, 0, 0.3);
}

/* select accions */
.btn-group.select {
  margin-bottom: 0;
}

.btn-group.select button.dropdown-toggle, .btn-box {
  position: relative;
  overflow: hidden;
  width: fit-content;
  border-radius: 4px;
  color: #2E383A;
}

.btn-box {
  padding: 4px 10px 0 5px;
  height: 30px;
}

.btn-box label {
  padding-left: 27px;
  cursor: pointer;
}

.btn-box label .icons .fui-checkbox-unchecked:before {
  content: "\e034";
  color: rgba(0, 0, 0, 0.1);
}

.btn-box label .title {
  color: #2E383A;
}

.btn-box label .title em,
#head .op_btn em {
  font-style: normal;
}

.btn-group select.lft {
  float: left;
}

.btn-group.select button span {
  margin: 0;
}

.btn-group.select .filter-option {
  padding: 0 8px 0 0;
}

.btn-group.select .caret {
  margin-top: 3px;
  right: 10px;
  border-bottom-color: #ccc;
  border-top-color: #ccc;
}

.btn-group.select button .caret {
  margin-top: -3px;
}

.btn-group.select button:hover .caret {
  margin-top: -1px;
  border-bottom-color: #555;
  border-top-color: #555;
}

.btn-group.select .dropdown-arrow {
  z-index: 6;
  margin-top: 0;
}

.btn-group.select.open > .dropdown-arrow {
  margin-top: 4px;
}

.dropdown-menu li {
  position: relative;
  display: block;
}

.dropdown-menu li.ehide {
  display: none;
}

.dropdown-menu li > a {
  white-space: nowrap;
}

.dropdown-menu li > a.right button {
  position: absolute;
  right: 16px;
}

.dropdown-menu li > a.right button i.icon {
  color: #4C6569;
}

.dropdown-menu .box_ul_sub {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-weight: 400;
  line-height: 1.42857;
}

.dropdown-menu .box_ul_sub li {
  display: block;
}

.dropdown-menu .box_ul_sub li a {
  display: block;
  border-radius: 0;
  padding-right: 30px !important;
}

.dropdown-menu .box_ul_sub li.prld,
.dropdown-menu li.prld,
#box_hist_vw .prld {
  position: relative;
  width: 100%;
  height: 22px;
  background: url(../../../img/prld_mina.gif) center center no-repeat;
}

.dropdown > .btn-xs {
  text-overflow: clip;
}

.btn-group {
  z-index: 1;
}

.btn-group.open {
  z-index: 3;
}

.modal.fade.in {
  background-color: rgba(46, 56, 58, 0.8);
}

.close {
  float: unset;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  color: unset;
  text-shadow: unset;
  filter: unset;
  opacity: unset;
}

.modal-small {
  max-width: 500px;
}
.modal-medium {
  max-width: 680px;
}
.modal-large {
  max-width: 832px;
}
.modal-xlarge {
  max-width: 1024px;
}

.modal-dialog {
  position: relative;
  z-index: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 40px auto 30px auto;
}

.modal-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px 4px;
  color: #2E383A;
  background-color: #ffffff;
  border-bottom: unset;
  border-radius: 8px 8px 0 0;
}
.modal-header .close {
  margin: unset;
  color: #2E383A;
  font-size: 20px;
  line-height: 20px;
}
.modal-header .close:disabled {
  cursor: default;
}
.modal-header .close:disabled:hover {
  color: #ABC0C4 !important;
  opacity: 1;
}

.modal-header-mini {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.modal-header-mini .close {
  margin: auto;
  color: #2E383A;
  font-size: 20px;
  line-height: 20px;
}

.modal-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.title_text {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  margin-bottom: 8px;
}

.avis {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
}
.avis strong {
  font-weight: 700;
  text-transform: capitalize;
}

.modal-content {
  border: unset;
  min-height: 50px;
}
.modal-content .modal-body {
  padding: 28px 32px;
  flex: 2;
  background-color: #ffffff;
  color: #2E383A;
}
.modal-content .modal-body p {
  font-size: 14px;
  list-style: 24px;
}
.modal-content .modal-body .row-img-file-trans .input-group-btn .btn.btn-icon {
  padding: 10px;
}
.modal-content .modal-body .row-img-file-trans .input-group-btn .btn.btn-icon i {
  margin-right: 0px;
}
.modal-content .modal-body button:not(.sl-icon-close):not(.btn-xs):not(.close) {
  height: 40px;
  font-weight: 600;
}
.modal-content .modal-body button:not(.sl-icon-close):not(.btn-xs):not(.close) i:not(.sl-icon-search):not(.sl-icon-chevron-down) {
  margin-right: 8px;
}
.modal-content .modal-body button:not(.sl-icon-close):not(.btn-xs):not(.close) i:not(.sl-icon-chevron-down) {
  font-size: 20px;
}
.modal-content .modal-body .btn:not(.sl-icon-close):not(.btn-xs) {
  height: 40px;
  font-weight: 600;
}
.modal-content .modal-body .btn:not(.sl-icon-close):not(.btn-xs) i:not(.sl-icon-search):not(.sl-icon-chevron-down) {
  margin-right: 8px;
}
.modal-content .modal-body .btn:not(.sl-icon-close):not(.btn-xs) i {
  font-size: 20px;
}
.modal-content .modal-body .avis .form-group {
  margin-bottom: 0;
}
.modal-content .modal-body .nav-tabs {
  border-bottom: 1px solid #D5DFE1;
  margin-bottom: 24px;
}

.modal-footer {
  display: flex !important;
  background-color: #F8F8F8;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
  padding: 20px 32px;
}
.modal-footer button.btn-primary {
  order: 6;
  margin-left: 12px;
}

.modal-dialog .modal-content iframe {
  height: 70vh !important;
}

.msng_refs_btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 12px;
}
.msng_refs_btns a {
  background-color: #FBF2D0 !important;
  color: #5A4B35 !important;
}

.modal-body h6, .modal-body span.legacy-h7 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.modal-body .sl-alert-info h6, .modal-body .sl-alert-info span.legacy-h7 {
  color: #1C303B;
}
.modal-body .sl-alert-success h6, .modal-body .sl-alert-success span.legacy-h7 {
  color: #05570A;
}
.modal-body .sl-alert-error h6, .modal-body .sl-alert-error span.legacy-h7 {
  color: #55201E;
}
.modal-body .sl-alert-warning h6, .modal-body .sl-alert-warning span.legacy-h7 {
  color: #653600;
}
.modal-body div[class^=sl-alert-] a.sl-link {
  text-decoration: underline;
}

.modal-body .alert span.legacy-h7 {
  color: #653600;
  font-weight: 400 !important;
}

#comps_perms > label {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #2E383A;
  margin: 36px 0 0 0;
}

#comps_perms .checkbox_head {
  background-color: #FFFFFF;
  padding: 20px 18px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #D5DFE1;
  margin: 24px 0 0 0;
}
#comps_perms .checkbox_head label {
  color: #2E383A;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  font-style: normal;
}
#comps_perms .checkbox_head label > span {
  margin-top: 2px;
}
#comps_perms .checkbox_list {
  padding: 32px 18px;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;
  border: 1px solid #D5DFE1;
  border-top: none;
}
#comps_perms .checkbox_list li {
  padding-bottom: 16px;
}
#comps_perms .checkbox_list label {
  color: #2E383A;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  font-style: normal;
}

#comps_check div > label {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px 32px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #D5DFE1;
  margin: 24px 0 0 0;
  color: #2E383A;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  font-style: normal;
}

#comps_check .check_list {
  padding: 32px;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;
  border: 1px solid #D5DFE1;
  border-top: none;
}
#comps_check .check_list label {
  color: #2E383A;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  font-style: normal;
}

.alert.alert-danger .sl-payment-alert-action {
  font-size: 14px;
  line-height: 24px;
  color: #55201E;
}

.box_msg_action.pbtm {
  padding: 20px 44px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-right: 16px;
  padding-left: 16px;
}

.row {
  margin-right: -16px;
  margin-left: -16px;
}

.sl-position-relative {
  position: relative;
}

.sl-position-absolute {
  position: absolute;
}

.sl-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.label_hidden {
  height: 19px;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.sl-icon-size-xs {
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
}

/*

    ## Form buttons

*/
#box_content .form .box_form {
  z-index: 1;
  padding: 24px 44px 40px 44px;
}

#body .form .frm_gnr_btns,
#body .form .frm_max_btns,
#body .frm_gnr_btns,
#body .frm_max_btns {
  display: flex;
  justify-content: end;
  z-index: 2000;
  position: fixed;
  bottom: 0;
  right: 0;
  top: auto;
  left: 224px;
  margin: 0;
  padding: 10px 20px;
  background: #F8F8F8;
  border-top: 1px solid #D5DFE1;
}

.minus_left #body .form .frm_gnr_btns {
  z-index: 902;
}

#body .frm_gnr_btns,
#body .frm_max_btns {
  z-index: 50;
}

#body .form .frm_max_btns,
#body .box_input_medium .form .frm_gnr_btns {
  left: 0;
}

#body_content .form .frm_gnr_btns,
#body_content .form .frm_max_btns {
  text-align: right;
  word-spacing: -1em;
}

#body_content .form .frm_gnr_btns .btn,
#body_content .form .frm_max_btns .btn {
  float: none;
  word-spacing: 0;
}

#body .frm_max_btns,
#body .box_input_medium .frm_gnr_btns {
  left: 0;
}

#body_content .frm_gnr_btns,
#body_content .frm_max_btns {
  text-align: right;
  word-spacing: -1em;
}

#body_content .frm_gnr_btns .btn,
#body_content .frm_max_btns .btn {
  float: none;
  word-spacing: 0;
}

.minus_left #body .form .frm_gnr_btns {
  left: 80px;
}

.minus_left #body #body_content.pop_mode .form .frm_gnr_btns {
  left: 0;
  background: #fff;
  padding-bottom: 0;
}

.box_input_medium .form {
  padding-bottom: 5px;
}

.box_input_medium form {
  margin-bottom: 0;
}

button.disabled,
form button.disabled button.readonly,
form button.readonly {
  cursor: auto !important;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

form .box_usr_inf,
form .box_grp_inf {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  color: #4C6569;
  margin: 12px 12px 0 0;
}
form .box_usr_inf em,
form .box_grp_inf em {
  font-style: normal;
}

form .box_usr_inf.ehide,
form .box_grp_inf.ehide {
  display: none;
}

form .is_dsbld .box_usr_inf.tp_ref,
form .is_dsbld .box_grp_inf.tp_ref {
  padding-top: 10px;
}

form[name=form_tlang] .box_usr_inf.tp_ref,
form[name=form_tlang] .box_grp_inf.tp_ref {
  padding: 10px 10px 0 0;
}

form .box_usr_inf em.tm {
  color: #4C6569;
  cursor: help;
}

form .box_usr_inf .hst_ops {
  display: inline;
}

form .box_usr_inf .hst_ops .btn {
  color: #4C6569;
  padding: 0 0 0 8px;
  margin: 0 0 0 0;
}

form .is_dsbld .box_usr_inf .hst_ops .btn {
  color: #2E383A;
  background: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
}
form .is_dsbld .box_usr_inf .hst_ops .btn.btn-xs {
  padding: 4px;
}
form .is_dsbld .box_usr_inf .hst_ops .btn .icon.alone {
  padding-right: 0;
}
form .is_dsbld .box_usr_inf .hst_ops .btn.sl-ml-100 {
  margin-left: 4px;
}
form .box_usr_inf .hst_ops .btn.btn-xs {
  padding: 4px;
}
form .box_usr_inf .hst_ops .btn .icon.alone {
  padding-right: 0;
}
form .box_usr_inf .hst_ops .btn.sl-ml-100 {
  margin-left: 4px;
}

form .box_usr_inf .hst_ops .icon {
  padding-right: 2px;
}

form .input-group-btn .icon.glasses,
form .input-group-btn .icon.conversation {
  font-size: 18px;
}

form .box_usr_inf .icon,
form .box_grp_inf .icon {
  font-size: 14px;
  padding-right: 8px;
  vertical-align: -1px;
}

form .nav-tabs li a .icon {
  padding-right: 5px;
}

form .form-group.unused label {
  color: #bbb;
}

form .form-group.unused input.form-control {
  color: #bbb;
  border-color: #ddd;
}

.btn-group.frm_gnr_btns button.btn.btn-lg.btn-primary,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-inverse,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-primary,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-inverse,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-default {
  height: 42px;
  background-color: #FFFFFF;
  color: #4C6569;
  border: 1px solid #D5DFE1;
  border-right: 1px solid transparent;
  font-weight: 600;
}
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-primary:hover,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-inverse:hover,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default:hover,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-primary:hover,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-inverse:hover,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-default:hover {
  background-color: #EAEEF0;
  color: #4C6569;
  border: 1px solid #D5DFE1;
}
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-primary:focus,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-inverse:focus,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default:focus,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-primary:focus,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-inverse:focus,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-default:focus {
  border: 1px solid #3EAC93;
}

.btn-secondary,
#body_content .form .frm_gnr_btns .btn.btn-secondary {
  height: 42px;
  background-color: #D5DFE1;
  color: #2E383A;
  border: 1px solid #D5DFE1;
  border-right: 1px solid transparent;
  font-weight: 600;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus,
#body_content .form .frm_gnr_btns .btn.btn-secondary:hover,
#body_content .form .frm_gnr_btns .btn.btn-secondary:active,
#body_content .form .frm_gnr_btns .btn.btn-secondary:focus {
  background-color: #EAEEF0;
  color: #4C6569;
  border: 1px solid #EAEEF0;
}
.btn-secondary:focus,
#body_content .form .frm_gnr_btns .btn.btn-secondary:focus {
  border: 1px solid #3EAC93;
}

.btn-group.frm_gnr_btns > button.btn.btn-hg.btn-default:last-child:not(:first-child) {
  border-right: 1px solid #D5DFE1;
}

.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default {
  border-right: 1px solid #D5DFE1;
}

#bt_add_field button {
  height: 40px;
}
#bt_add_field button i {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}

#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_ok],
#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_previous] {
  display: inherit;
  color: #2E383A;
  background-color: #D5DFE1;
  border: 1px solid #D5DFE1;
}
#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_ok]:hover,
#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_previous]:hover {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  color: #4C6569;
}

#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_cancel] {
  color: #2E383A;
  background-color: transparent;
  border: 1px solid transparent;
}
#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_cancel]:hover {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  color: #2E383A;
}

#body_content .form .frm_gnr_btns .btn.sl-btn-import[name=submit_ok] {
  display: flex;
}

#body_content .form .frm_gnr_btns .btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 8px 20px;
  color: #FFFFFF;
  background-color: #387B77;
  border-radius: 4px;
  order: 3;
  margin-left: 12px;
  border: 1px solid #387B77;
}
#body_content .form .frm_gnr_btns .btn:hover {
  background-color: #3D9487;
  border: 1px solid #3D9487;
}
#body_content .form .frm_gnr_btns .btn:active {
  background-color: #205A5A;
  border: 1px solid #205A5A;
}
#body_content .form .frm_gnr_btns .btn:focus {
  border: 1px solid #3EAC93;
}

.modal .form .frm_gnr_btns.sl-block button.sl-button-danger-medium {
  border: 1px solid #D5DFE1;
  border-radius: 0;
  height: 42px;
  border-right: 0;
}

.modal .form .frm_gnr_btns.sl-block button.btn-default[name=submit_cancel] {
  border: 1px solid #D5DFE1 !important;
}

.sl-block.btn-group,
.sl-block.select,
.sl-block.btn-group.select {
  margin: 0;
}

#body_content .form .frm_gnr_btns .btn[name=submit_cancel] {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 8px 20px;
  color: #2E383A;
  background-color: #D5DFE1;
  border-radius: 4px;
  border: 1px solid #D5DFE1;
  order: 2;
}
#body_content .form .frm_gnr_btns .btn[name=submit_cancel]:hover {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  color: #4C6569;
}
#body_content .form .frm_gnr_btns .btn[name=submit_cancel]:active {
  background-color: #CFDADD;
  border: 1px solid #CFDADD;
  color: #4C6569;
}
#body_content .form .frm_gnr_btns .btn[name=submit_cancel]:focus {
  border: 1px solid #3EAC93;
  color: #4C6569;
}

#body_content .form .frm_gnr_btns .btn.btn_save_and_variants_prd {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 8px 20px;
  color: #2E383A;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
  order: 1;
}
#body_content .form .frm_gnr_btns .btn.btn_save_and_variants_prd:hover {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  color: #2E383A;
}
#body_content .form .frm_gnr_btns .btn.btn_save_and_variants_prd:active {
  background-color: #D5DFE1;
  border: 1px solid #D5DFE1;
  color: #2E383A;
}
#body_content .form .frm_gnr_btns .btn.btn_save_and_variants_prd:focus {
  border: 1px solid #3EAC93;
  color: #2E383A;
}

.btn.btn-addssoaccount .icon {
  font-size: 20px;
  margin-right: 8px;
}

button.btn-danger {
  background-color: #FDEDED;
  color: #D2322C;
}
button.btn-danger:hover {
  background-color: #FAD7D6;
  color: #D2322C;
  border: 1px solid transparent;
}

button.btn-default:hover {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  border-color: #EAEEF0;
  color: #2E383A;
}
button.btn-default:active {
  background-color: #CFDADD;
  border: 1px solid #CFDADD;
  border-color: #CFDADD;
  color: #2E383A;
}
button.btn-default:disabled {
  background-color: #EAEEF0;
  border: 1px solid #EAEEF0;
  border-color: #EAEEF0;
  color: #93AFB4;
}
button.btn-default:focus {
  background-color: #D5DFE1;
  border: 1px solid #3EAC93;
  border-color: #3EAC93;
  color: #2E383A;
}

.btn-ordrst-warning {
  background-color: #FBF2D0;
  color: #5A4B35;
}

.btn-ordrst-warning:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border: 1px solid transparent;
}

.btn-ordrst-warning:focus {
  background-color: #FCF8ED;
  color: #5A4B35;
  border: 1px solid #3EAC93;
}

.btn-ordrst-success {
  background-color: #BFF5C5;
  color: #05570A;
}

.btn-ordrst-success:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border: 1px solid transparent;
}

.btn-ordrst-success:focus {
  background-color: #E6FAE8;
  color: #05570A;
  border: 1px solid #3EAC93;
}

.btn-ordrst-danger {
  background-color: #F7BCBA;
  color: #55201E;
}

.btn-ordrst-danger:hover {
  background-color: #FAD7D6;
  color: #55201E;
  border: 1px solid transparent;
}

.btn-ordrst-danger:focus {
  background-color: #FAD7D6;
  color: #55201E;
  border: 1px solid #3EAC93;
}

.btn-ordrst-default {
  background-color: #D5DFE1;
  color: #2E383A;
}

.btn-ordrst-default:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}

.btn-ordrst-default:focus {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid #3EAC93;
}

.btn-upgrade, .btn-upgrade:focus, .btn-upgrade:active {
  color: #506099;
  background-color: #E6ECFF;
  border-color: transparent;
  text-transform: uppercase;
}

.btn-upgrade:hover, .btn-upgrade:hover, .btn-upgrade:focus:hover, .btn-upgrade:active:hover {
  color: #506099;
  background-color: #CED9FF;
  border: 1px solid transparent;
}

.channel-access-dropdown-menu,
.channel-access-user-dropdown-menu {
  border: 1px solid #D5DDE5;
  border-radius: 6px;
  z-index: 9;
  min-width: unset;
  width: 134px;
}

.channel-access-user-list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.channel-access-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
}

.channel-access-user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.channel-access-user-avatar {
  width: 32px;
  height: 32px;
  background-color: #ABC0C4;
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.channel-access-user-avatar .initials {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

.channel-access-user-data {
  max-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
}
.channel-access-user-data strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
}

.channel-access-user-status {
  font-size: 14px;
  color: #607589;
}

.channel-access-user-dropdown-menu {
  right: 0;
  left: auto;
}

.channel-access-card-lock {
  font-size: 40px;
  color: #CCCCCC;
  margin-bottom: 25px;
}

.channel-access-card-text {
  font-size: 15px;
  color: #586D83;
}

.channel-access-btn-pending {
  background-color: #A7AEB2;
  padding: 12px;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  max-height: 40px;
  justify-content: center;
  align-items: center;
}

.channel-access-btn-pending-icon {
  font-size: 16px;
}

/*

    Charts

*/
.sklt_paragh,
.chart_sklt {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.chart_sklt.left {
  float: left;
  margin-right: 20px;
}

.chart_sklt.right {
  float: right;
  margin-left: 20px;
}

.chart_sklt.pie {
  width: 200px;
}

.chart_sklt.pre_ldng {
  height: 200px;
}

.chart_sklt .prld {
  position: absolute;
  top: 50%;
  height: 20px;
  margin-top: -10px;
  left: 50%;
  width: 60px;
  margin-left: -30px;
  background: url(../../../img/prld_mina.gif) center center no-repeat;
}

.box_info.chart h5,
.box_info.chart h6,
.box_info.chart span.legacy-h7 {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: #4C6569;
}

.box_info.chart h5,
.box_info.chart h6 {
  color: rgba(0, 0, 0, 0.7);
}

.chart_box {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  text-align: center;
  margin: 0 20px 0 0;
}

.chart_box.back {
  background: #fff;
  padding: 10px;
}

.chart_box.back canvas {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.chart_box.hmed {
  height: 150px;
}

.chart_box.hprc {
  height: 250px;
}

.chart_box.hmax {
  height: 300px;
}

.chart_box.no_mrg_btm {
  margin-bottom: 0;
}

.pie .chart_box {
  border-top: 15px solid transparent;
}

.chart-legend {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 10px 0;
  background: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 16px;
}

.chart-legend ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chart-legend li {
  padding: 5px 0;
  margin: 0;
  text-align: left;
}

.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  vertical-align: middle;
}

.chart_sklt.legend_bottom .chart-legend {
  position: relative;
  width: 100%;
  top: auto;
  left: auto;
  overflow: hidden;
  background: transparent;
}

.chart_sklt.legend_bottom .chart-legend ul {
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;
  width: auto;
}

.chart_sklt.legend_bottom .chart-legend li {
  float: left;
  margin-left: 20px;
}

.chart_sklt.pie .chart_box.legend_bottom .chart-legend {
  float: none;
}

/*

	Dashboards

*/
#box_cntrl_dshbrd {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 150px;
}

.box_list .sklt {
  position: relative;
  float: left;
  padding: 0 24px 24px 0;
  width: 25%;
  overflow: hidden;
}

.box_list .sklt.dbl {
  width: 50%;
}

.box_list .sklt.trp {
  width: 75%;
}

.box_list .sklt.mxm {
  width: 100%;
  display: flex;
}

.box_list .sklt.not_avlbl .box_info {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.box_dashb, .box_conn {
  position: relative;
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  background: #ffffff;
  color: #586d83;
  border-radius: 6px;
}

.box_list .sklt.mxm .box_dashb,
.box_list .sklt .box_dashb {
  border-radius: 8px;
  border: 1px solid #D5DFE1;
}

.box_dashb h2,
.box_dashb h3,
.box_conn h3 {
  position: relative;
  overflow: hidden;
  padding: 24px 32px 0px 32px;
  font-size: 20px;
  line-height: 28px;
  /*text-transform: capitalize;*/
  font-weight: 600;
  color: #2E383A;
  display: flex;
  justify-content: space-between;
}

.box_conn h3 {
  padding: 24px;
}

.box_dashb .btn .icon {
  color: #fff;
}

.box_dashb h2 .icon.right, .box_dashb h3 .icon.right {
  font-size: 100%;
  line-height: 90%;
  vertical-align: -15%;
  padding: 0 0 0 5px;
}

.box_dashb h3 .more .icon {
  color: #387B77;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
}

.box_dashb h3 .more:hover .icon {
  color: #3D9487;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

.box_dashb h2 span, .box_dashb h3 span {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.box_dashb h2:first-letter, .box_dashb h3:first-letter,
.box_conn h2:first-letter, .box_conn h3:first-letter {
  text-transform: capitalize;
}

.box_dashb h2 span a, .box_dashb h3 span a {
  display: block;
}

.box_dashb ul {
  padding: 0 15px 15px 15px;
  margin: 0;
}

.box_dashb li {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 12px;
  line-height: 18px;
  color: #777;
}

.box_dashb.hvar {
  min-height: 100px;
  max-height: none;
}

.box_dashb.mvar {
  min-height: 150px;
  max-height: none;
}

.box_dashb.svar {
  min-height: 120px;
  max-height: none;
}

.box_dashb.fvar {
  min-height: none;
  max-height: none;
  height: auto;
}

.box_dashb.bx_bench .bx_elms {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 15px 5px;
  min-height: 50px;
}

.box_dashb.bx_bench .bx_elm {
  position: absolute;
  top: 65px;
  left: 20px;
  width: 45%;
  max-width: 135px;
  height: 30%;
  color: #708397;
}

.box_dashb.bx_bench .bx_elm.rgt {
  left: auto;
  right: 15px;
}

.box_dashb.bx_bench .bx_elm.bm {
  top: auto;
  bottom: 15px;
}

.box_dashb.bx_bench .bx_elm.bm.all {
  width: auto;
  max-width: none;
  left: 15px;
  right: 15px;
  height: 100px;
}

.box_dashb.bx_bench .bx_elm span {
  position: absolute;
  right: 16px;
  bottom: 0;
}

.box_dashb.bx_bench .bx_elm span,
.box_dashb.bx_bench .bx_elm a.nofc,
.box_dashb .box_info a.inf_total {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #7B9BA1;
}

.box_dashb.bx_bench .bx_elm strong {
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  color: #4C6569;
  display: block;
}

.box_dashb.bx_bench .bx_elm a:hover strong {
  color: #3D9487;
  transition: all 0.25s ease 0s;
}

.box_dashb.bx_bench .bx_elm .icon.back {
  position: absolute;
  top: 30px;
  left: 16px;
  color: #CFDADD;
  font-size: 24px;
  line-height: 24px;
}

.box_dashb.bx_bench .bx_elm.st_lst {
  position: relative;
  display: inline-block;
  top: auto;
  left: auto;
  margin: 35px 10px 10px 0;
}

.box_dashb.bx_bench.hvar .bx_elm.st_lst,
.box_dashb.bx_bench.mvar .bx_elm.st_lst {
  margin-top: 0;
}

.box_dashb.bx_bench .bx_elm.st_lst span {
  position: relative;
  left: auto;
  top: auto;
}

.box_dashb .bx_elm .bx_back {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.box_dashb .bx_elm .bx_cntr {
  display: flex;
  justify-content: center;
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -20px;
}

.box_dashb.tl_grt p {
  font-size: 16px;
  line-height: 24px;
  color: #4C6569;
}

.box_conn .box_info {
  position: absolute;
  top: 100px;
  left: 32px;
  right: 32px;
  bottom: 22px;
  overflow: hidden;
}

.box_conn .box_info {
  left: 24px;
  right: 24px;
}

.box_dashb.msg .box_info,
.box_conn.msg .box_info {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  padding: 32px;
}

.box_dashb.tl_grt .box_info {
  top: 70px;
}

.box_dashb .box_info.scroll, .box_conn .box_info.scroll {
  overflow-y: auto;
}

.box_dashb .box_info.btm,
.box_conn .box_info.btm {
  bottom: 60px;
}

.box_dashb .box_btm,
.box_conn .box_btm {
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 10px;
  padding: 15px 24px;
  overflow: hidden;
}

.box_conn .connDescription {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 0;
}

.box_dashb .box_info .top {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #2E383A;
}

.box_dashb .box_info .box {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.box_dashb .box_info .inf_total.lft,
.box_dashb .box_info .sub_inf {
  position: relative;
  overflow: hidden;
  float: left;
  margin-right: 20px;
}

.box_dashb .box_info .sub_inf {
  color: #bbb;
}

.box_dashb .box_info .sub_bx {
  position: relative;
  overflow: hidden;
  float: left;
}

.box_dashb .box_info .sub_bx strong {
  font-size: 150%;
}

.box_dashb .box_info .sub_bx.tri {
  font-size: 12px;
  line-height: 20px;
}

.box_dashb .box_info .sub_bx.rgt {
  float: right;
}

.box_dashb .box_info .sub_bx.st_D strong,
.box_dashb .box_info .sub_bx.st_D a strong,
.box_dashb .box_info .sub_bx.st_V strong,
.box_dashb .box_info .sub_bx.st_V a strong,
.box_dashb .box_info .sub_bx.st_I strong,
.box_dashb .box_info .sub_bx.st_I a strong {
  color: #2E383A;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.box_dashb .box_info .sub_bx.st_V,
.box_dashb .box_info .sub_bx.st_I,
.box_dashb .box_info .sub_bx.st_D {
  text-align: center;
  color: #4C6569;
}

.box_dashb .box_info .msg {
  color: #4C6569;
  font-size: 14px;
  line-height: 24px;
  display: flex;
}

.box_dashb .box_info .msg .icon {
  vertical-align: -8px;
}

.box_dashb .box_info .msg .icon.ok {
  color: #387B77;
}

.box_dashb .box_info .lst_actv .info {
  font-size: 12px;
  line-height: 20px;
}

.box_dashb .progress, .box_dashb .progress-bar {
  position: relative;
  overflow: hidden;
}

.box_dashb .prg_back_img {
  display: none;
}

.box_dashb .progress-bar.st_V {
  background-color: #387B77;
}

.box_dashb .progress-bar.st_I {
  background-color: #2E383A;
}

.box_dashb .progress-bar.st_D {
  background-color: #FF9900;
}

.box_dashb .progress.rgt_mdl {
  margin-top: 15px;
}

.box_dashb .list_tasks {
  margin: 0;
  padding: 0;
}

.box_dashb .list_tasks td {
  vertical-align: top;
  padding-bottom: 12px;
}

.box_dashb .list_tasks .tick {
  padding-right: 10px;
  font-size: 12px;
  line-height: 18px;
}

.box_dashb .list_tasks .tick {
  display: flex;
}
.box_dashb .list_tasks .tick div.label {
  width: 100%;
}
.box_dashb .list_tasks .info {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  font-weight: 400;
}
.box_dashb .list_tasks .info strong.inf_name {
  font-weight: 600;
}

.box_dashb.bx_bench h3 a:hover, .box_dashb.bx_bench h3 a:hover strong,
.box_dashb.bx_bench .box_info a:hover, .box_dashb.bx_bench .box_info a:hover strong {
  color: #53be92;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

h1 small, h2 small, h3 small, h3 small, h5 small, h6 small {
  color: #93AFB4;
}

.box_info h1 small, .box_info h2 small, .box_info h3 small, .box_info h4 small, .box_info h5 small, .box_info h6 small {
  color: #93AFB4;
}

.box_dashb .box_info.center .box_cnt_elm {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: left;
  margin-bottom: 10px;
  vertical-align: top;
  padding-left: 52px;
  margin: 0;
}

.box_dashb .box_info.center .box_cnt_elm .chart {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.box_dashb .box_info.center .box_cnt_elm .chart.hv {
  cursor: pointer;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a strong {
  color: #387B77;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a:hover strong {
  color: #3D9487;
}

.box_dashb .box_info.center .box_cnt_elm .logo {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.box_dashb .box_info.center .box_cnt_elm .logo img {
  width: 80%;
  height: auto;
}

.box_dashb .box_info.center .box_cnt_elm h4,
.box_dashb .box_info.center .box_cnt_elm h4 a {
  color: #4C6569;
}

.box_dashb .box_info.center .box_cnt_elm h4 a:hover {
  color: #3D9487;
  transition: all 0.25s ease 0s;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 {
  font-size: 16px;
  line-height: 20px;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #387B77;
  margin-top: 4px;
}

.box_dashb .box_info.center .box_cnt_elm span.legacy-h7 a .icon {
  color: #bbb;
  font-size: 100%;
  padding: 0;
}

.box_dashb .box_info.center .box_cnt_elm .add_new {
  display: flex;
  align-items: center;
  height: 100%;
}

.box_dashb .box_info.center .box_cnt_elm .add_new a {
  background-color: #D5DFE1;
  border: none;
  height: 40px;
  border-radius: 4px;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .add_new a:hover {
  background-color: #EAEEF0;
}

.box_dashb.md_cnsmpt .box_info.center .box_cnt_elm.last {
  text-align: center;
  padding: 0 0 0 10px;
}

.box_dashb .box_info.center .box_cnt_elm .ttl {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .num {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  font-weight: 600;
}

.box_dashb .box_info.center .box_cnt_elm .num b {
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  color: #2E383A;
}

.box_dashb .box_info.center .box_cnt_elm .num.md_warning {
  color: #D2322C;
}
.box_dashb .box_info.center .box_cnt_elm .num.md_warning b {
  color: #D2322C;
}

.box_dashb .box_info.center .box_cnt_elm .sub_min {
  color: #7B9BA1;
  display: block;
  font-size: 10px;
  font-style: normal;
}

.box_dashb .box_info.center .box_cnt_elm .sub_lmt {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #7B9BA1;
  font-weight: 400;
}

.box_dashb .box_info.center .box_cnt_elm .back {
  z-index: 0;
  position: absolute;
  top: 26px;
  left: 0;
  color: #CFDADD;
  font-size: 32px;
  line-height: 32px;
}

.box_dashb.bnr_wlcm {
  display: flex;
  align-items: center;
}
.box_dashb.bnr_wlcm .left {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding: 0 0 0 80px;
}
.box_dashb.bnr_wlcm .left h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #2E383A;
  margin-bottom: 20px;
  width: 100%;
}
.box_dashb.bnr_wlcm .left h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4C6569;
  margin-bottom: 20px;
  width: 100%;
}
.box_dashb.bnr_wlcm .left a.btn {
  height: 40px;
}
.box_dashb.bnr_wlcm .right {
  padding: 16px;
  width: 50%;
}
.box_dashb.bnr_wlcm .right img {
  width: 100%;
}

.box_list .sklt .box_dashb.box_dashb_services {
  min-height: unset;
  max-height: unset;
  height: 370px;
  width: 33%;
}
.box_list .sklt .box_dashb.box_dashb_services h3 {
  font-size: 18px;
}
.box_list .sklt .box_dashb.box_dashb_services .last {
  padding: 44px 32px;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv b {
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv b i {
  color: #ABC0C4;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  margin-right: 10px;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv span.label-primary {
  align-self: center;
  background-color: #BFF5C5;
  color: #05570A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 2px 8px;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv span.label-default {
  align-self: center;
  background-color: #FBF2D0;
  color: #5A4B35;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 2px 8px;
}
.box_list .sklt .box_dashb.box_dashb_services .last .bx_srv span.label-upgrade {
  align-self: center;
  background-color: #D5DFE1;
  color: #2E383A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 2px 8px;
}

.box_list.md_vw.tp_dasbhoard.tp_dasbhoard_companies .dbl {
  min-width: 521px;
}

.box_list .sklt.dbl .box_dashb.box_dashb_companies {
  min-height: 370px;
  max-height: fit-content;
}

.box_dashb.box_dashb_companies {
  padding: 24px 32px 32px 32px;
}
.box_dashb.box_dashb_companies h2 {
  padding: 0;
  margin: 0;
  font-size: 18px;
}
.box_dashb.box_dashb_companies .box_info {
  position: unset;
  display: flex;
  flex-direction: column;
}
.box_dashb.box_dashb_companies .box_info h6 {
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}
.box_dashb.box_dashb_companies .box_info .lst_actv {
  display: flex;
  flex-direction: column;
}
.box_dashb.box_dashb_companies .box_info .lst_actv_item {
  margin-bottom: 12px;
  display: flex;
}
.box_dashb.box_dashb_companies .box_info .lst_actv_item span.label.date {
  margin-right: 24px;
  min-width: 100px;
}
.box_dashb.box_dashb_companies .box_info .lst_actv_item .info {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
}
.box_dashb.box_dashb_companies .box_info .lst_actv_item .info .inf_name {
  font-weight: 600;
}
.box_dashb.box_dashb_companies .box_info_date {
  color: #4C6569;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 32px;
}
.box_dashb.box_dashb_companies .box_info_numbers {
  display: flex;
  gap: 64px;
  margin-bottom: 24px;
}
.box_dashb.box_dashb_companies .box_info_numbers span {
  display: flex;
  align-items: center;
}
.box_dashb.box_dashb_companies .box_info_numbers span i {
  font-size: 24px;
  line-height: 24px;
  color: #3D9487;
  background-color: #EDF8F3;
  border-radius: 16px;
  padding: 16px;
  margin-right: 16px;
}
.box_dashb.box_dashb_companies .box_info_numbers span p {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
}
.box_dashb.box_dashb_companies .box_info_numbers span p strong {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

@media (max-width: 1500px) {
  .box_dashb.box_dashb_companies .box_info_numbers {
    gap: 25.5px;
  }
}
@media (max-width: 900px) {
  .box_dashb.box_dashb_companies .box_info_numbers {
    gap: 64px;
  }
}
.box_dashb.tl_grt p.sl-companies-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
}
.box_dashb.tl_grt p.sl-companies-info a.btn {
  font-size: 12px;
  line-height: 16px;
  padding: 8 16px;
  height: 32px;
  margin-left: 12px;
}

.box_list.md_vw.tp_dasbhoard {
  padding-top: 42px;
  min-width: 1150px;
}
.box_list.md_vw.tp_dasbhoard .sklt.mxm {
  padding: 0 0 24px 0;
}

.box_list .sklt .box_dashb.box_dashb_consums_totals .box_info {
  display: flex;
  justify-content: space-between;
}

.sl-dashboard-activity {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.box_list .sklt.sl-dashboard-activity-left {
  padding: 0 12px 0 0;
  margin-bottom: 24px;
}
.box_list .sklt.sl-dashboard-activity-left h3 {
  font-size: 18px;
}

.box_list .sklt.sl-dashboard-activity-right {
  padding: 0 0 0 12px;
  margin-bottom: 24px;
}

.box_list .sklt.sl-dashboard-activity-left,
.box_list .sklt.sl-dashboard-activity-right {
  height: 356px;
}
.box_list .sklt.sl-dashboard-activity-left .box_dashb,
.box_list .sklt.sl-dashboard-activity-right .box_dashb {
  max-height: unset;
  min-height: unset;
  height: 100%;
}
.box_list .sklt.sl-dashboard-activity-left .box_dashb .list_tasks .tick,
.box_list .sklt.sl-dashboard-activity-right .box_dashb .list_tasks .tick {
  padding: 0 24px 12px 0;
  vertical-align: middle;
}
.box_list .sklt.sl-dashboard-activity-left .box_dashb .list_tasks .info,
.box_list .sklt.sl-dashboard-activity-right .box_dashb .list_tasks .info {
  white-space: nowrap;
}
.box_list .sklt.sl-dashboard-activity-left .box_info.scroll,
.box_list .sklt.sl-dashboard-activity-right .box_info.scroll {
  margin: 32px;
}

.box_list .sklt.sl-dashboard-activity-right .box_dashb {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.box_list .sklt.sl-dashboard-activity-right .box_dashb .sl-activity-right-content {
  min-width: 255px;
}
.box_list .sklt.sl-dashboard-activity-right .box_dashb .sl-activity-right-content h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #2E383A;
  margin-bottom: 20px;
}
.box_list .sklt.sl-dashboard-activity-right .box_dashb .sl-activity-right-content p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #4C6569;
  margin-bottom: 20px;
}
.box_list .sklt.sl-dashboard-activity-right .box_dashb img {
  max-width: 297px;
  min-width: 130px;
}

.box_dashb.fvar.msg.sl-dashboard-conn h3 {
  font-size: 18px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info {
  padding: 34px 32px 48px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info #dashb_conn_list {
  display: flex;
  overflow: hidden;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm {
  padding-right: 72px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm .logo {
  margin-bottom: 8px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm .logo .url_ldg img {
  height: 36px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  padding: 0;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm .add_new_bnt {
  font-size: 16px;
  line-height: 16px;
  padding: 4px;
  height: 24px;
  width: 24px;
  margin-bottom: 16px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm.box_cnt_elm_last {
  padding-right: 0;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm_empty {
  margin-left: 62px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm_empty .elm_empty_logo {
  width: 24px;
  height: 24px;
  background-color: #EAEEF0;
  border-radius: 4px;
  opacity: 0.7;
  margin-bottom: 16px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm_empty .elm_empty_name {
  width: 124px;
  height: 12px;
  background-color: #EAEEF0;
  border-radius: 2px;
  opacity: 0.5;
  margin-bottom: 16px;
}
.box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_elm_empty .elm_empty_description {
  width: 186px;
  height: 12px;
  background-color: #EAEEF0;
  border-radius: 2px;
  opacity: 0.35;
  margin-bottom: 16px;
}

.box_list .sklt .box_dashb.box_dashb_knowledge.box_dashb_knowledge_full {
  width: calc(66% + 24px);
}

.box_list .sklt .box_dashb.box_dashb_knowledge {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FCF8ED;
  border: 1px solid #EEE5C0;
  min-height: unset;
  max-height: unset;
  height: 370px;
  width: 33%;
  padding: 40px 48px 0 48px;
  margin-right: 24px;
}
.box_list .sklt .box_dashb.box_dashb_knowledge h3 {
  padding: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #2E383A;
  margin-bottom: 20px;
}
.box_list .sklt .box_dashb.box_dashb_knowledge p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4C6569;
  margin-bottom: 20px;
  text-align: center;
  max-width: 300px;
}
.box_list .sklt .box_dashb.box_dashb_knowledge img {
  position: absolute;
  bottom: 0;
}

.box_list .sklt .box_dashb.box_dashb_translations {
  width: 33%;
  margin-left: 24px;
  height: 370px;
}
.box_list .sklt .box_dashb.box_dashb_translations h3 {
  font-size: 18px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info {
  padding: 32px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont {
  display: flex;
  flex-direction: column;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 19px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages .box_cnt_elm {
  display: flex;
  justify-content: center;
  padding: 12px;
  background-color: #F8F8F8;
  border-radius: 4px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages .box_cnt_elm:hover {
  background-color: #EAEEF0;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages .box_cnt_elm h4 {
  display: flex;
  align-items: flex-end;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages .box_cnt_elm h4 a {
  color: #7B9BA1;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_dashb_translations_languages .box_cnt_elm span.legacy-h7 a {
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
  margin-right: 12px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D5DFE1;
  margin-top: 30px;
  padding: 24px 0 0 0;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add h4 a {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: #387B77;
  font-weight: 400;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add h4 a i {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  margin-right: 4px;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add h4 a:hover {
  color: #3D9487;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add span.legacy-h7 a {
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
  font-weight: 400;
}
.box_list .sklt .box_dashb.box_dashb_translations .box_info .box_cnt_cont .box_cnt_elm.add span.legacy-h7 a:hover {
  color: #3D9487;
}

.box_list .sklt .box_dashb.box_dashb_taules {
  width: calc(66% + 24px);
  margin-right: 24px;
}

.box_list .sklt .box_dashb.box_dashb_llibreries {
  width: 33%;
}

.box_list .sklt .box_dashb.box_dashb_taules,
.box_list .sklt .box_dashb.box_dashb_llibreries {
  min-height: unset;
  max-height: unset;
  height: 340px;
}
.box_list .sklt .box_dashb.box_dashb_taules h3,
.box_list .sklt .box_dashb.box_dashb_llibreries h3 {
  font-size: 18px;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info {
  margin-top: 32px;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm .chart,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm .chart {
  margin-bottom: 20px;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm h4,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm h4 a,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm h4 a {
  color: #2E383A;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm span.legacy-h7,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm span.legacy-h7 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.box_list .sklt .box_dashb.box_dashb_taules .box_info .box_cnt_cont .box_cnt_elm span.legacy-h7 a strong,
.box_list .sklt .box_dashb.box_dashb_llibreries .box_info .box_cnt_cont .box_cnt_elm span.legacy-h7 a strong {
  color: #7B9BA1;
}

.box_dashb_leyend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
}
.box_dashb_leyend .box_dashb_leyend_v div {
  background-color: #9DB2FF;
}
.box_dashb_leyend .box_dashb_leyend_d div {
  background-color: #FFA984;
}
.box_dashb_leyend .box_dashb_leyend_i div {
  background-color: #EFC5FD;
}
.box_dashb_leyend .box_dashb_leyend_v,
.box_dashb_leyend .box_dashb_leyend_d,
.box_dashb_leyend .box_dashb_leyend_i {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.box_dashb_leyend .box_dashb_leyend_v div,
.box_dashb_leyend .box_dashb_leyend_d div,
.box_dashb_leyend .box_dashb_leyend_i div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 8px;
}
.box_dashb_leyend .box_dashb_leyend_v p,
.box_dashb_leyend .box_dashb_leyend_d p,
.box_dashb_leyend .box_dashb_leyend_i p {
  color: #4C6569;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.box_dashb .box_info .bx_empty_state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
.box_dashb .box_info .bx_empty_state i {
  font-size: 48px;
  line-height: 48px;
  color: #BAAA8A;
  margin-bottom: 16px;
}
.box_dashb .box_info .bx_empty_state h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #2E383A;
  text-align: center;
  margin-bottom: 4px;
}
.box_dashb .box_info .bx_empty_state p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #4C6569;
  text-align: center;
}

/* Dahboard Connect */
.box_dashb_connect .box_info {
  display: flex;
  justify-content: space-between;
}
.box_dashb_connect .box_info.center .back {
  top: -3px;
}
.box_dashb_connect .box_info.center .box_cnt_elm {
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid #D5DFE1;
}
.box_dashb_connect .box_info.center .box_cnt_elm .num {
  display: flex;
  height: 30px;
  font-weight: 300;
}
.box_dashb_connect .box_info.center .box_cnt_elm .num b, .box_dashb_connect .box_info.center .box_cnt_elm .num strong {
  font-weight: 600;
  font-size: 29px;
  margin-right: 10px;
}
.box_dashb_connect .box_info.center .box_cnt_elm .back {
  top: -3px;
}
.box_dashb_connect .box_info.center .box_cnt_elm:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.box_dashb_connect .box_info.center .chart_highlights_left {
  position: relative;
  float: left;
  max-width: 175px;
  margin-right: 20px;
}
.box_dashb_connect .box_info.center .chart_highlights_left ul {
  padding: 0;
}
.box_dashb_connect .box_info.center .chart_highlights_left li {
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  color: #2E383A;
  border-left: 2px solid #D5DFE1;
  padding-left: 10px;
  padding: 5px 0 5px 10px;
  margin: 0;
}
.box_dashb_connect .box_info.center .chart_highlights_left li strong {
  display: block;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  color: #2E383A;
}
.box_dashb_connect .box_info.center .chart_highlights_left li.active {
  color: #2E383A;
  border-left-color: #9DB2FF;
}
.box_dashb_connect .box_info.center .chart_highlights_left li.active strong {
  color: #9DB2FF;
}
.box_dashb_connect .box_info.center .chart_sklt.all_right {
  margin-top: 10px;
  width: 90%;
}

.box_dashb_connect.md_cnsmpt .box_info {
  display: inline-block;
}

#channel_categories {
  display: inline-block;
  margin-bottom: 0;
}

#channel_categories .btn-group {
  display: flex;
  justify-content: space-between;
  height: 32px;
}

#channel_categories .btn-group.minified button i {
  margin-left: 0;
  margin-right: 0;
}

#channel_categories .btn-group.minified button span {
  display: none;
}

@media (min-width: 1500px) {
  .box_dashb.fvar.msg.sl-dashboard-conn .box_info .box_cnt_cont#dashb_conn_list {
    justify-content: space-between;
  }
}
/*  ## Dropdown normal */
.dropdown-left {
  float: left;
}

.btn-group.select .dropdown-menu,
.form-group .dropdown .dropdown-menu,
.makefield_ops .dropdown-menu {
  z-index: 5;
  width: auto;
  border-radius: 6px;
}

.btn-group.select.open > .dropdown-menu {
  margin-top: 8px !important;
}

.table-striped td .dropdown-menu li,
.btn-group.select .dropdown-menu li {
  float: none;
  margin: 0;
}

.btn-group.select .dropdown-menu li.sl-mb-50 {
  margin-bottom: 2px;
}

.box_head_sec .btn-group.select .dropdown-short li,
.box_search .btn-group.select .dropdown-short li,
.box_head_sec .btn-group.select .dropdown-large li,
.box_search .btn-group.select .dropdown-large li {
  margin-right: 0;
}

.dropdown-short li > a,
.dropdown-large li > a {
  padding-right: 30px;
}

.table-striped td .dropdown-menu li.disabled,
.btn-group.select .dropdown-menu li.disabled {
  display: inline;
}

.makefield_ops .dropdown-menu .icon,
.btn-group.select .dropdown-menu .icon {
  padding-right: 8px;
  font-size: 20px;
  line-height: 20px;
}

.makefield_ops .sl-icon-setting {
  padding: 0;
  position: relative;
}

.btn-group.select .dropdown-menu a {
  text-align: left;
}

.btn-group.select .dropdown-menu .disabled a,
.btn-group.select .dropdown-menu .disabled a:hover i {
  color: #ABC0C4;
  background-color: transparent;
}

.btn-group.select .dropdown-menu a.inactive {
  background: transparent;
  color: rgba(0, 0, 0, 0.2);
  cursor: default;
}

.btn-group.select .dropdown-menu p {
  padding: 6px 15px 8px;
  margin: 0;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.6);
  width: 290px;
}

.btn-group.select.drp_mn_rght .dropdown-menu,
.crumbs .dropdown-menu,
.box_form .dropdown-menu {
  left: auto;
  right: 0;
}

#head .dropdown-arrow:after,
.btn-group .dropdown-arrow:after,
.form-group .dropdown .dropdown-arrow:after {
  content: " ";
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
  position: absolute;
  right: -9px;
  top: 0;
  width: 0;
  height: 0;
  margin-top: 1px;
}

.dropdown-menu {
  box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.15);
}

.dropdown-menu em {
  font-style: normal;
  font-size: 10px;
  line-height: 20px;
  color: #4C6569;
}

.dropdown-menu em.sl-icon-line-curved {
  font-size: 16px;
}

.btn-group.dropup .dropdown-menu {
  margin-bottom: 15px;
}

.btn-group.dropup .dropdown-arrow {
  margin-bottom: 8px;
  left: auto;
  right: 5px;
  border-color: #bbb rgba(68, 68, 68, 0) rgba(68, 68, 68, 0) rgba(68, 68, 68, 0);
}

.btn-group.dropup .dropdown-arrow:after {
  margin: -9px 0 0 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
}

strong.prld {
  opacity: 0.3;
  filter: alpha(opacity=30);
  font-weight: normal;
}

/* 

    Dropdown ajuda cerca

*/
.dropdown-menu.adv-srch-dropdown {
  max-width: 450px;
  margin-top: 5px !important;
  left: -35px;
}

.dropdown-menu.adv-srch-dropdown li a {
  align-items: center;
}

.dropdown-menu.adv-srch-dropdown li a i {
  margin-right: 8px;
}

.dropdown-menu.adv-srch-dropdown .divider {
  margin: 12px auto 8px auto;
  padding: 0;
}

.btn-group.select.drop_right.open > .dropdown-arrow {
  left: 13px;
  right: auto;
}

.slc_srch .btn-group.select .dropdown-menu p {
  width: 450px;
}

/* 

    Dropdown invisible

*/
.btn-more {
  position: absolute;
  left: unset;
  top: 50%;
  right: 0;
  bottom: unset;
}

.box_search .btn-group.select.btn-more button.dropdown-toggle,
.btn-group.select.btn-more button.dropdown-toggle {
  border: 0;
  border-radius: 0;
  background: 0;
  padding: 0;
  margin: -12px 0 0 0;
  width: 25px;
  height: 24px;
}

.box_search .btn-group.select.btn-more button.dropdown-toggle:hover,
.btn-group.select.btn-more button.dropdown-toggle:hover {
  border: 0;
  border-radius: 0;
  background: 0;
}

.btn-group.select.btn-more button.dropdown-toggle .caret {
  right: 0;
}

.box_search .btn-group.select.btn-more .dropdown-menu,
.btn-group.select.btn-more .dropdown-menu {
  left: auto;
  right: 0;
}

.box_search .btn-group.select.btn-more .dropdown-arrow,
.btn-group.select.btn-more .dropdown-arrow {
  right: 10px;
}

.toggle-menu-tree {
  display: none;
}

div#slct_ops_cats::before {
  display: none;
}

#slct_ops_cats ul.dropdown-menu.dropdown-large .icon {
  padding-right: 0px;
  margin-right: 0px;
}

#slct_ops_cats ul.dropdown-menu.dropdown-large button .icon {
  padding-right: 8px;
  margin-right: 0px;
}

.sl-card-channel .sl-dropdown ul {
  width: auto;
}

/*

	Formularios

*/
#box_form_content {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  background-color: #fff !important;
  -webkit-text-fill-color: #34495e;
  color: #34495e !important;
}

/* Reajuste de las cajas de campos en resoluciones pequeñas */
.form-group .col-sm-1,
.form-group .col-sm-2,
.form-group .col-sm-3,
.form-group .col-sm-4,
.form-group .col-sm-5,
.form-group .col-sm-6,
.form-group .col-sm-7,
.form-group .col-sm-8,
.form-group .col-sm-9,
.form-group .col-sm-10,
.form-group .col-sm-11 {
  padding-top: 15px;
} /*
.form-group .col-sm-1:first-of-type,
.form-group .col-sm-2:first-of-type,
.form-group .col-sm-3:first-of-type,
.form-group .col-sm-4:first-of-type,
.form-group .col-sm-5:first-of-type,
.form-group .col-sm-6:first-of-type,
.form-group .col-sm-7:first-of-type,
.form-group .col-sm-8:first-of-type,
.form-group .col-sm-9:first-of-type,
.form-group .col-sm-10:first-of-type,
.form-group .col-sm-11:first-of-type { padding-top: 0 }*/
@media (min-width: 768px) {
  .form-group .col-sm-1,
  .form-group .col-sm-2,
  .form-group .col-sm-3,
  .form-group .col-sm-4,
  .form-group .col-sm-5,
  .form-group .col-sm-6,
  .form-group .col-sm-7,
  .form-group .col-sm-8,
  .form-group .col-sm-9,
  .form-group .col-sm-10,
  .form-group .col-sm-11 {
    padding-top: 0;
  }
}
/* Reajustes individualizados de los campos */
.imp-xs-1, .imp-sm-1, .imp-md-1, .imp-lg-1, .imp-xs-2, .imp-sm-2, .imp-md-2, .imp-lg-2,
.imp-xs-3, .imp-sm-3, .imp-md-3, .imp-lg-3, .imp-xs-4, .imp-sm-4, .imp-md-4, .imp-lg-4,
.imp-xs-5, .imp-sm-5, .imp-md-5, .imp-lg-5, .imp-xs-6, .imp-sm-6, .imp-md-6, .imp-lg-6,
.imp-xs-7, .imp-sm-7, .imp-md-7, .imp-lg-7, .imp-xs-8, .imp-sm-8, .imp-md-8, .imp-lg-8,
.imp-xs-9, .imp-sm-9, .imp-md-9, .imp-lg-9, .imp-xs-10, .imp-sm-10, .imp-md-10, .imp-lg-10,
.imp-xs-11, .imp-sm-11, .imp-md-11, .imp-lg-11, .imp-xs-12, .imp-sm-12, .imp-md-12, .imp-lg-12 {
  padding-right: 20px;
}

.imp-xs-12 {
  width: 100%;
}

.imp-xs-11 {
  width: 91.6666666667%;
}

.imp-xs-10 {
  width: 83.3333333333%;
}

.imp-xs-9 {
  width: 75%;
}

.imp-xs-8 {
  width: 66.6666666667%;
}

.imp-xs-7 {
  width: 58.3333333333%;
}

.imp-xs-6 {
  width: 50%;
}

.imp-xs-5 {
  width: 41.6666666667%;
}

.imp-xs-4 {
  width: 33.3333333333%;
}

.imp-xs-3 {
  width: 25%;
}

.imp-xs-2 {
  width: 16.6666666667%;
}

.imp-xs-1 {
  width: 8.3333333333%;
}

.imp-xs-pull-12 {
  right: 100%;
}

.imp-xs-pull-11 {
  right: 91.6666666667%;
}

.imp-xs-pull-10 {
  right: 83.3333333333%;
}

.imp-xs-pull-9 {
  right: 75%;
}

.imp-xs-pull-8 {
  right: 66.6666666667%;
}

.imp-xs-pull-7 {
  right: 58.3333333333%;
}

.imp-xs-pull-6 {
  right: 50%;
}

.imp-xs-pull-5 {
  right: 41.6666666667%;
}

.imp-xs-pull-4 {
  right: 33.3333333333%;
}

.imp-xs-pull-3 {
  right: 25%;
}

.imp-xs-pull-2 {
  right: 16.6666666667%;
}

.imp-xs-pull-1 {
  right: 8.3333333333%;
}

.imp-xs-pull-0 {
  right: 0;
}

.imp-xs-push-12 {
  left: 100%;
}

.imp-xs-push-11 {
  left: 91.6666666667%;
}

.imp-xs-push-10 {
  left: 83.3333333333%;
}

.imp-xs-push-9 {
  left: 75%;
}

.imp-xs-push-8 {
  left: 66.6666666667%;
}

.imp-xs-push-7 {
  left: 58.3333333333%;
}

.imp-xs-push-6 {
  left: 50%;
}

.imp-xs-push-5 {
  left: 41.6666666667%;
}

.imp-xs-push-4 {
  left: 33.3333333333%;
}

.imp-xs-push-3 {
  left: 25%;
}

.imp-xs-push-2 {
  left: 16.6666666667%;
}

.imp-xs-push-1 {
  left: 8.3333333333%;
}

.imp-xs-push-0 {
  left: 0;
}

.imp-xs-offset-12 {
  margin-left: 100%;
}

.imp-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.imp-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.imp-xs-offset-9 {
  margin-left: 75%;
}

.imp-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.imp-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.imp-xs-offset-6 {
  margin-left: 50%;
}

.imp-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.imp-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.imp-xs-offset-3 {
  margin-left: 25%;
}

.imp-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.imp-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.imp-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .imp-sm-12 {
    width: 100%;
  }
  .imp-sm-11 {
    width: 91.6666666667%;
  }
  .imp-sm-10 {
    width: 83.3333333333%;
  }
  .imp-sm-9 {
    width: 75%;
  }
  .imp-sm-8 {
    width: 66.6666666667%;
  }
  .imp-sm-7 {
    width: 58.3333333333%;
  }
  .imp-sm-6 {
    width: 50%;
  }
  .imp-sm-5 {
    width: 41.6666666667%;
  }
  .imp-sm-4 {
    width: 33.3333333333%;
  }
  .imp-sm-3 {
    width: 25%;
  }
  .imp-sm-2 {
    width: 16.6666666667%;
  }
  .imp-sm-1 {
    width: 8.3333333333%;
  }
  .imp-sm-pull-12 {
    right: 100%;
  }
  .imp-sm-pull-11 {
    right: 91.6666666667%;
  }
  .imp-sm-pull-10 {
    right: 83.3333333333%;
  }
  .imp-sm-pull-9 {
    right: 75%;
  }
  .imp-sm-pull-8 {
    right: 66.6666666667%;
  }
  .imp-sm-pull-7 {
    right: 58.3333333333%;
  }
  .imp-sm-pull-6 {
    right: 50%;
  }
  .imp-sm-pull-5 {
    right: 41.6666666667%;
  }
  .imp-sm-pull-4 {
    right: 33.3333333333%;
  }
  .imp-sm-pull-3 {
    right: 25%;
  }
  .imp-sm-pull-2 {
    right: 16.6666666667%;
  }
  .imp-sm-pull-1 {
    right: 8.3333333333%;
  }
  .imp-sm-pull-0 {
    right: 0;
  }
  .imp-sm-push-12 {
    left: 100%;
  }
  .imp-sm-push-11 {
    left: 91.6666666667%;
  }
  .imp-sm-push-10 {
    left: 83.3333333333%;
  }
  .imp-sm-push-9 {
    left: 75%;
  }
  .imp-sm-push-8 {
    left: 66.6666666667%;
  }
  .imp-sm-push-7 {
    left: 58.3333333333%;
  }
  .imp-sm-push-6 {
    left: 50%;
  }
  .imp-sm-push-5 {
    left: 41.6666666667%;
  }
  .imp-sm-push-4 {
    left: 33.3333333333%;
  }
  .imp-sm-push-3 {
    left: 25%;
  }
  .imp-sm-push-2 {
    left: 16.6666666667%;
  }
  .imp-sm-push-1 {
    left: 8.3333333333%;
  }
  .imp-sm-push-0 {
    left: 0;
  }
  .imp-sm-offset-12 {
    margin-left: 100%;
  }
  .imp-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .imp-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .imp-sm-offset-9 {
    margin-left: 75%;
  }
  .imp-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .imp-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .imp-sm-offset-6 {
    margin-left: 50%;
  }
  .imp-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .imp-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .imp-sm-offset-3 {
    margin-left: 25%;
  }
  .imp-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .imp-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .imp-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .imp-md-12 {
    width: 100%;
  }
  .imp-md-11 {
    width: 91.6666666667%;
  }
  .imp-md-10 {
    width: 83.3333333333%;
  }
  .imp-md-9 {
    width: 75%;
  }
  .imp-md-8 {
    width: 66.6666666667%;
  }
  .imp-md-7 {
    width: 58.3333333333%;
  }
  .imp-md-6 {
    width: 50%;
  }
  .imp-md-5 {
    width: 41.6666666667%;
  }
  .imp-md-4 {
    width: 33.3333333333%;
  }
  .imp-md-3 {
    width: 25%;
  }
  .imp-md-2 {
    width: 16.6666666667%;
  }
  .imp-md-1 {
    width: 8.3333333333%;
  }
  .imp-md-pull-12 {
    right: 100%;
  }
  .imp-md-pull-11 {
    right: 91.6666666667%;
  }
  .imp-md-pull-10 {
    right: 83.3333333333%;
  }
  .imp-md-pull-9 {
    right: 75%;
  }
  .imp-md-pull-8 {
    right: 66.6666666667%;
  }
  .imp-md-pull-7 {
    right: 58.3333333333%;
  }
  .imp-md-pull-6 {
    right: 50%;
  }
  .imp-md-pull-5 {
    right: 41.6666666667%;
  }
  .imp-md-pull-4 {
    right: 33.3333333333%;
  }
  .imp-md-pull-3 {
    right: 25%;
  }
  .imp-md-pull-2 {
    right: 16.6666666667%;
  }
  .imp-md-pull-1 {
    right: 8.3333333333%;
  }
  .imp-md-pull-0 {
    right: 0;
  }
  .imp-md-push-12 {
    left: 100%;
  }
  .imp-md-push-11 {
    left: 91.6666666667%;
  }
  .imp-md-push-10 {
    left: 83.3333333333%;
  }
  .imp-md-push-9 {
    left: 75%;
  }
  .imp-md-push-8 {
    left: 66.6666666667%;
  }
  .imp-md-push-7 {
    left: 58.3333333333%;
  }
  .imp-md-push-6 {
    left: 50%;
  }
  .imp-md-push-5 {
    left: 41.6666666667%;
  }
  .imp-md-push-4 {
    left: 33.3333333333%;
  }
  .imp-md-push-3 {
    left: 25%;
  }
  .imp-md-push-2 {
    left: 16.6666666667%;
  }
  .imp-md-push-1 {
    left: 8.3333333333%;
  }
  .imp-md-push-0 {
    left: 0;
  }
  .imp-md-offset-12 {
    margin-left: 100%;
  }
  .imp-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .imp-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .imp-md-offset-9 {
    margin-left: 75%;
  }
  .imp-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .imp-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .imp-md-offset-6 {
    margin-left: 50%;
  }
  .imp-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .imp-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .imp-md-offset-3 {
    margin-left: 25%;
  }
  .imp-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .imp-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .imp-md-offset-0 {
    margin-left: 0;
  }
  .col-md-1.no_lft_pd, .col-md-2.no_lft_pd, .col-md-3.no-lft_pd, .col-md-4.no_lft_pd, .col-md-5.no_lft_pd, .col-md-6.no_lft_pd, .col-md-7.no_lft_pd,
  .col-md-8.no_lft_pd, .col-md-9.no_lft_pd, .col-md-10.no_lft_pd, .col-md-11.no-lft_pd {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .imp-lg-12 {
    width: 100%;
  }
  .imp-lg-11 {
    width: 91.6666666667%;
  }
  .imp-lg-10 {
    width: 83.3333333333%;
  }
  .imp-lg-9 {
    width: 75%;
  }
  .imp-lg-8 {
    width: 66.6666666667%;
  }
  .imp-lg-7 {
    width: 58.3333333333%;
  }
  .imp-lg-6 {
    width: 50%;
  }
  .imp-lg-5 {
    width: 41.6666666667%;
  }
  .imp-lg-4 {
    width: 33.3333333333%;
  }
  .imp-lg-3 {
    width: 25%;
  }
  .imp-lg-2 {
    width: 16.6666666667%;
  }
  .imp-lg-1 {
    width: 8.3333333333%;
  }
  .imp-lg-pull-12 {
    right: 100%;
  }
  .imp-lg-pull-11 {
    right: 91.6666666667%;
  }
  .imp-lg-pull-10 {
    right: 83.3333333333%;
  }
  .imp-lg-pull-9 {
    right: 75%;
  }
  .imp-lg-pull-8 {
    right: 66.6666666667%;
  }
  .imp-lg-pull-7 {
    right: 58.3333333333%;
  }
  .imp-lg-pull-6 {
    right: 50%;
  }
  .imp-lg-pull-5 {
    right: 41.6666666667%;
  }
  .imp-lg-pull-4 {
    right: 33.3333333333%;
  }
  .imp-lg-pull-3 {
    right: 25%;
  }
  .imp-lg-pull-2 {
    right: 16.6666666667%;
  }
  .imp-lg-pull-1 {
    right: 8.3333333333%;
  }
  .imp-lg-pull-0 {
    right: 0;
  }
  .imp-lg-push-12 {
    left: 100%;
  }
  .imp-lg-push-11 {
    left: 91.6666666667%;
  }
  .imp-lg-push-10 {
    left: 83.3333333333%;
  }
  .imp-lg-push-9 {
    left: 75%;
  }
  .imp-lg-push-8 {
    left: 66.6666666667%;
  }
  .imp-lg-push-7 {
    left: 58.3333333333%;
  }
  .imp-lg-push-6 {
    left: 50%;
  }
  .imp-lg-push-5 {
    left: 41.6666666667%;
  }
  .imp-lg-push-4 {
    left: 33.3333333333%;
  }
  .imp-lg-push-3 {
    left: 25%;
  }
  .imp-lg-push-2 {
    left: 16.6666666667%;
  }
  .imp-lg-push-1 {
    left: 8.3333333333%;
  }
  .imp-lg-push-0 {
    left: 0;
  }
  .imp-lg-offset-12 {
    margin-left: 100%;
  }
  .imp-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .imp-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .imp-lg-offset-9 {
    margin-left: 75%;
  }
  .imp-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .imp-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .imp-lg-offset-6 {
    margin-left: 50%;
  }
  .imp-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .imp-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .imp-lg-offset-3 {
    margin-left: 25%;
  }
  .imp-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .imp-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .imp-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-1.no_lft_pd, .col-lg-2.no_lft_pd, .col-lg-3.no-lft_pd, .col-lg-4.no_lft_pd, .col-lg-5.no_lft_pd, .col-lg-6.no_lft_pd, .col-lg-7.no_lft_pd,
  .col-lg-8.no_lft_pd, .col-lg-9.no_lft_pd, .col-lg-10.no_lft_pd, .col-lg-11.no-lft_pd {
    padding-left: 0;
  }
}
.row .nopadding {
  padding-right: 0 !important;
}

.col-file-box {
  display: flex;
}

.dfield_prod_archivo .col-field-file,
.dfield_prod_image .col-field-file,
.form-group .col-file-box .col-fld {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-group .col-file-box .col-field-file {
  padding-right: 0;
}

form .col-field-file .makefield_ops, form .col-field-file .sl-ct-chat-icon-container {
  right: 0;
}

form.mgbform .form-group.row div[class^=dfield_] .box_thumb {
  padding-left: 0;
}

.dfield_prod_image .col-field-file {
  align-self: flex-start;
}

.box-field-flex,
.col-field-file .box_desc {
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
}

.col-field-file .box-field-flex {
  width: 100%;
  pointer-events: auto;
}
.col-field-file .box-field-flex .input-group {
  display: flex;
}
.col-field-file .box-field-flex .input-group .input-group-btn {
  width: fit-content;
  display: flex;
}

form .box-field-flex > .input-group {
  padding-left: 0;
}

form .col-info label {
  color: rgba(0, 0, 0, 0.35);
  width: 100%;
  border-bottom-color: #eee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.row_btm {
  margin-bottom: 20px;
}

#crumbs_box_table_active .wrapper {
  margin-bottom: 0;
}

/*.crumbs {z-index: 3} .box_form {z-index: 1}*/
.crumbs {
  padding: 26px 44px 0px 44px;
  background-color: #FFFFFF;
}
.crumbs .nav-tabs {
  padding-top: 0;
}

.crumbs .nav li a[data-toggle=tab] .icon {
  font-size: 100%;
  line-height: 1;
  padding-right: 10px;
  display: none;
}

.crumbs .wrapper {
  position: relative;
  margin: 0 auto 20px auto;
  overflow: hidden;
  padding: 0;
  height: 50px;
  max-width: 100%;
  overflow-x: hidden;
}

.crumbs .wrapper .nav {
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 5000px;
  margin: 0;
  border: none;
  height: 55px;
}

.crumbs .wrapper .nav > li {
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  margin-right: 20px;
}

.crumbs .scr {
  text-align: center;
  cursor: pointer;
  display: none;
  padding: 17px 0 0 0;
  white-space: no-wrap;
  vertical-align: middle;
  color: #2E383A;
}

.crumbs .scr:hover {
  color: #387B77;
}

.crumbs .scr-right {
  float: right;
  padding-left: 10px;
}

.crumbs .scr-left {
  float: left;
  padding-right: 10px;
}

.crumbs .makefield_ops {
  float: right;
  padding-left: 5px;
  position: relative;
  top: 14px;
  right: auto;
  display: inline;
  margin-left: 10px;
}
.crumbs .makefield_ops ul.dropdown-menu {
  left: auto;
}

.makefield_ops .dropdown-menu li > a {
  color: #2E383A;
}

.makefield_ops .dropdown-menu li .icon {
  vertical-align: middle;
}

.makefield_ops .dropdown-arrow {
  border-color: rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) #F1C40F !important;
}

.makefield_ops .dropdown-arrow:after {
  content: " ";
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
  position: absolute;
  right: -9px;
  top: 0;
  width: 0;
  height: 0;
  margin-top: 1px;
}

.makefield_ops.dropup .dropdown-menu {
  margin-bottom: 9px;
  z-index: 999;
}

.makefield_ops.dropup .dropdown-arrow {
  margin: 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #F1C40F rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) rgba(241, 204, 204, 0) !important;
}

.makefield_ops.dropup .dropdown-arrow:after {
  margin: -10px 0 0 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) !important;
}

.paragrh .form .mgbform h4.title {
  padding: 40px 44px 16px 44px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  font-weight: 700;
  font-size: 29px;
  line-height: 36px;
}
.paragrh .form .mgbform h4.title i {
  font-weight: normal;
}

.paragrh .form .mgbform h4.title.crbTp {
  padding: 20px 44px 8px 44px;
  margin: 0;
  font-size: 29px;
  line-height: 36px;
  font-weight: 700;
  color: #161F27;
}

.mgbform {
  margin-bottom: 20px;
}

.modal-body .mgbform {
  margin-bottom: 0;
}

.mgbform[name=locations] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name=mat_test] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name=catalogue] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name=product_formats] .box_form .form-group.row:not(.sl_item_info),
.mgbform[name=products] .box_form .form-group.row:not(.sl_item_info) {
  display: flex;
  align-content: flex-start;
}
.mgbform[name=locations] .box_form .form-group.row:not(.sl_item_info) .col-fld,
.mgbform[name=mat_test] .box_form .form-group.row:not(.sl_item_info) .col-fld,
.mgbform[name=catalogue] .box_form .form-group.row:not(.sl_item_info) .col-fld,
.mgbform[name=product_formats] .box_form .form-group.row:not(.sl_item_info) .col-fld,
.mgbform[name=products] .box_form .form-group.row:not(.sl_item_info) .col-fld {
  align-content: flex-start;
  height: fit-content;
}

.chk_opt,
.chk_opt_sts_mod,
.chk_opt_sts_new,
.chk_opt_onl_upd,
.chk_opt_sts_new ~ .col-xs-12,
.chk_opt_sts_new ~ .col-xs-12 ~ .col-xs-12 {
  align-self: start;
}

.form .fld_combi {
  position: relative;
  display: block;
  width: 100%;
}

.has_preview input,
.has_preview textarea {
  border-color: rgba(0, 0, 0, 0.15);
}

.has-warning .form-control,
.has_error .form-control {
  color: #34495e;
}

.has-warning .select2 {
  color: #f1c40f;
  border-color: #f1c40f;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .input-group-btn .btn {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.has-error .fileinput .input-group-btn .btn {
  border-top-color: #D5DFE1;
  border-right-color: #D5DFE1;
  border-bottom-color: #D5DFE1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.has-error .fileinput .input-group-btn .btn:hover {
  border-top-color: #EAEEF0;
  border-right-color: #EAEEF0;
  border-bottom-color: #EAEEF0;
  background-color: #EAEEF0;
  color: #4C6569;
}

.has-error .fileinput .input-group .form-control:first-child {
  border-right-width: 1px;
}

.fileinput .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle):not(.fileinput-btn-change),
.fileinput .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle):not(.fileinput-btn-change) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.has-error .fileinput .input-group-btn .btn {
  background-color: #D5DFE1;
  color: #2E383A;
}

.has-error .input-group-btn .btn {
  border-top-color: #D2322C;
  border-right-color: #D2322C;
  border-bottom-color: #D2322C;
}
.has-error .input-group-btn .btn:focus {
  border-top-color: #3EAC93;
  border-right-color: #3EAC93;
  border-bottom-color: #3EAC93;
}

.input-group .form-control:first-child {
  border-right-width: 0;
}

.input-group-btn > .btn {
  z-index: 2;
}

textarea {
  min-width: 100%;
  max-width: 100%;
}

textarea.area_hmed {
  height: 200px;
}

textarea.area_hmax {
  height: 100%;
}

.form label em {
  color: rgba(52, 73, 94, 0.5);
}

.form-control.tmp_disabled,
.form-control.form-disabled,
.form-control[disabled],
.form-control[readonly],
.fieldset.tmp_disabled .form-control,
.fieldset[disabled] .form-control {
  display: inline-block;
  color: #99a7a7;
}

select[multiple].form-control {
  height: 42px;
}

.form-group input[type=date],
.form-group input[type=time],
.form-group input[type=datetime-local],
.form-group input[type=month] {
  line-height: 1.467;
}

form .form-control.form-disabled,
.form-control.form-disabled {
  background-color: #f4f6f6;
  border-color: #d5dbdb;
  color: #d5dbdb;
  cursor: default;
  opacity: 0.7;
}

.input-group-btn .btn.into-disabled,
.has-warning .input-group-btn .btn.into-disabled,
.has-error .input-group-btn .btn.into-disabled,
.input-group.focus .input-group-btn .btn.into-disabled {
  border-color: transparent;
  background-color: #EAEEF0;
  color: #ABC0C4;
}

.box_input_medium {
  position: relative;
  width: 80%;
  min-width: 800px;
  max-width: 1200px;
  margin: 20px auto;
}

.box_input_medium.height_center {
  margin-top: 125px;
}

.box_input_medium.height_top {
  margin-top: 0;
}

#body .box_center.long {
  max-width: 100%;
  padding-left: 44px;
  padding-right: 44px;
  overflow: initial;
  position: relative;
}
#body .box_center.box_create_company h4 {
  width: 100%;
}
#body .box_center.box_create_company .box_cat_reg {
  width: 100%;
  max-width: 996px;
}
#body .box_center .box_input_medium {
  min-width: initial;
  width: 100%;
}

.box_info_required {
  position: relative;
  display: inline-block;
  width: 100%;
  color: #aaa;
  font-size: 70%;
  line-height: 70%;
  font-style: italic;
  padding-bottom: 15px;
}

.box_info_required span {
  color: #D2322C;
}

.box_info_required span:before {
  content: "[";
  color: #ccc;
}

.box_info_required span:after {
  content: "]";
  color: #ccc;
}

.required_flag {
  color: #D2322C;
}

.modal-body .box_info_required {
  padding-left: 20px;
}

.alert.alert-warning h3,
.alert.alert-danger h3,
.alert.alert-error h3 {
  font-size: 110%;
}

.alert.alert-warning ul,
.alert.alert-danger ul,
.alert.alert-error ul {
  padding-left: 0;
  list-style: none;
}

.sl-payment-paragrh .alert-warning h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
}
.sl-payment-paragrh .alert-warning span.legacy-h7 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 4px 0 0 36px;
}
.sl-payment-paragrh .alert-info span.legacy-h7 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
}
.sl-payment-paragrh .alert-info p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 4px 0 0 36px;
}

form.mgbform div#error_div {
  margin: 44px 44px 40px 44px;
  display: flex;
}

form.mgbform[name=upd_img] div#error_div,
form.mgbform[name=upd_fld] div#error_div {
  margin: 0px 0px 24px 0px;
}

form.mgbform .has-error .form-control.uneditable-input {
  border-right: 1px solid #D2322C;
  border-right-width: 1;
}
form.mgbform .uneditable-input {
  border-right: #fff;
  border-right-width: 0;
}
form.mgbform .uneditable-input:hover {
  border-right: #fff;
  border-right-width: 0;
}
form.mgbform .btn-tertiary {
  border-left: none;
  border-right: none;
}
form.mgbform .btn-tertiary:hover {
  border-left: none;
  border-right: none;
}

#file_sel .btn-tertiary.sl-button-icon-only {
  padding: 8px 16px;
}
#file_sel .btn-tertiary.sl-button-icon-only i {
  font-size: 16px;
  margin-right: 0;
}

.modal form.mgbform[name=upd_img] .frm_gnr_btns.sl-block,
.modal form.mgbform[name=upd_fld] .frm_gnr_btns.sl-block {
  margin-top: 24px;
}

.modal form[name=upd_img] .frm_gnr_btns.sl-block.btn-group .btn,
.modal form[name=upd_fld] .frm_gnr_btns.sl-block.btn-group .btn {
  border-radius: 4px !important;
  margin-right: 12px !important;
  margin-left: 0px !important;
}

.crumbs + script + form.mgbform div#error_div {
  margin: 44px 44px 40px;
}

label .icon {
  margin-right: 5px;
}

label .icon:not(.txt_st) {
  color: #7c8ea0;
}

label + .icon.sl-icon-error:not(.txt_st) {
  color: #4C6569;
  font-size: 20px;
}

.row-horizontal label {
  text-align: right;
  padding-right: 0;
}

form h2 .icon:not(.ini), form h3 .icon:not(.ini), form h4 .icon:not(.ini), form h5 .icon:not(.ini), form h6 .icon:not(.ini), form span.legacy-h7 .icon:not(.ini) {
  margin-left: 5px;
}

form h2 .icon.nomrg, form h3 .icon.nomrg, form h4 .icon.nomrg, form h5 .icon.nomrg, form h6 .icon.nomrg, form span.legacy-h7 .icon.nomrg {
  margin: 0;
}

.box_sz_bt_del {
  width: 100px;
  padding-right: 0;
}

.offset_sz_bt_ops.cols {
  margin-left: 155px;
}

.offset_sz_bt_del {
  margin-left: 100px;
}

.box_sz_bt_ops button {
  vertical-align: 0;
  text-align: left;
}

.box_sz_bt_ops button.act_act {
  margin-top: 3px;
}

.offset_sz_bt_ops .select2.select2-container--bootstrap {
  min-height: 36px;
  max-height: 36px;
}

.offset_sz_bt_ops .select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  padding-top: 3px;
  font-size: 14px;
}

.form-group.fld_dsbl .col-frm input.form-control[readonly], .form-group.fld_dsbl .col-frm .form-control.form-disabled {
  color: #ABC0C4;
  background-color: #EAEEF0;
  border: 1px solid #D5DFE1;
  opacity: 1;
}
.form-group.fld_dsbl .col-frm select.form-control.form-disabled {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-group.fld_dsbl .col-frm .check_list .checkbox.disabled {
  color: #ABC0C4;
}

.col-frm .form-control[disabled], .col-frm .form-control.form-disabled {
  color: #2E383A;
  background-color: #EAEEF0;
  border: 1px solid #D5DFE1;
  opacity: 1;
  -webkit-text-fill-color: #2E383A;
}
.col-frm .check_list .checkbox.disabled {
  color: #2E383A;
}

form .form-group.act_moving,
.page_fields .act_moving {
  position: fixed;
  z-index: 100000;
  padding-top: 10px;
}

form .form-group button.act_mov:hover {
  color: rgba(0, 0, 0, 0.6);
}

form .form-group.line_mov:not(.act_moving) {
  transition: all 0.25s ease 0s;
}

form .form-group.line_mov.move_in {
  border-bottom: 55px solid #EDF8F3;
  transition: all 0.25s ease 0s;
}

form .form-group.line_mov.move_fix {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: none !important;
}

form .form-group.in_change > div {
  padding: 10px 0;
  background: #EDF8F3;
  transition: all 0.25s ease 0s;
}

form .form-group.act_moving,
.page_fields .act_moving .field {
  box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
  background: rgba(236, 240, 241, 0.5);
}

.edt-form {
  margin-bottom: 16px !important;
}

input.inp_verify {
  text-transform: uppercase;
  margin-top: 8px;
}

form .top_bt {
  margin-top: 32px;
}

form .fld_mrg_top {
  margin-top: 20px;
}

form .row-slim {
  margin-bottom: 12px;
}

form .btn-group {
  margin-top: 15px;
}

form .fld_sep_top {
  margin-top: 20px;
  border-top: 2px dotted #fff;
  padding-top: 20px;
}

form .fld_sep_top_gr {
  margin-top: 20px;
  border-top: 2px dotted #ddd;
  padding-top: 20px;
}

form .fld_sep_btm {
  margin-bottom: 20px;
  border-bottom: 2px dotted #fff;
  padding-bottom: 20px;
}

form .slim_sp_btm {
  margin-bottom: 5px;
}

form .row-slim .input-group-btn .btn {
  padding-left: 10px;
  padding-right: 10px;
}

.fileinput-new .form-control.uneditable-input {
  width: 100%;
}

form .field_ops {
  margin: 0 8px 8px 0;
}

form .field_ops button.ehide {
  display: none;
}

form .field_ops button {
  display: flex;
  align-items: center;
}

sup {
  position: unset;
  margin-left: 2px;
}

form .field_ops button,
form .makefield_ops button {
  padding: 4px;
  height: 24px;
  border-radius: 4px;
}
form .field_ops button:disabled,
form .makefield_ops button:disabled {
  background-color: #D5DFE1;
  border-color: transparent;
  color: #2E383A;
}

form .field_ops button i,
form .makefield_ops button i {
  font-size: 16px;
  line-height: 16px;
}

form .field_ops.connector_link {
  float: right;
}

form .field_ops.connector_link.connector_link_modify {
  color: #5A4B35;
  margin: 2px 8px 0 0;
  position: absolute;
  right: 40px;
  top: 0;
}

form .field_ops.connector_link_forms_editor {
  margin: 4px 80px 0px 0px;
}

form .field_ops button.bt_fld_connector {
  background-color: #D5DFE1;
  color: #2E383A;
  font-weight: normal;
}

form .field_ops button.bt_fld_connector_forms_editor {
  background: #ffffff;
}

.form-group label.lb_check {
  padding: 0 0 0 8px;
  margin: 0;
  vertical-align: middle;
}

form .switch_box {
  margin-top: 20px;
}

form .form-group + .form-group .switch_box {
  margin-top: 0px;
}

.switch {
  vertical-align: middle;
}

form .mrg_no_ttl {
  margin-top: 47px;
}

form label.lb_check.st_title_md {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
  padding: 0;
}

form label.st_title_mn,
.form-group label.st_title_mn {
  font-size: 110%;
  line-height: 115%;
  padding-top: 3px;
}

form label .md_off {
  color: rgba(0, 0, 0, 0.3);
}

form label.lb_check .md_off {
  color: inherit;
}

form .ttl_sep {
  margin-bottom: 0;
  margin-left: 20px;
}

form .ttl_prg {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #4C6569;
  font-weight: 400;
  margin: 0 0 0 16px;
}
form .ttl_prg i {
  font-size: 16px;
  line-height: 16px;
  margin: 0 8px;
}

form h6.ttl_prg {
  color: #2E383A;
  font-weight: 700;
  font-size: 20px;
  margin: 20px 15px 10px;
}
form h6.ttl_prg.sl-body-large-semibold {
  font-size: 16px;
}

form .ttl_item .field_ops.bx_btn_not_apply button {
  margin-left: auto;
}

form .field_ops.bx_btn_not_apply button {
  padding: 4px 10px;
}
form .field_ops.bx_btn_not_apply button i {
  font-size: 16px;
  margin-right: 4px;
}
form .field_ops.bx_btn_not_apply button i.sl-icon-stop {
  margin-right: 0px;
}
form .field_ops.bx_btn_not_apply button i.sl-button-icon-alone {
  margin: 0 -4px;
}

form .makefield_ops {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
}

form .box_extra {
  position: relative;
  overflow: hidden;
  padding-left: 2px;
}

form .box_extra,
form .box_extra a {
  font-size: 90%;
  line-height: 100%;
}

form .box_extra .st_file:before {
  content: "\e416";
  font-family: "Glyphicons Regular";
  padding-right: 10px;
  vertical-align: middle;
  color: rgba(52, 73, 94, 0.5);
}

/* caixa text html */
form .btn_scroller {
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  right: 20px;
  width: 28px;
  height: 25px;
  cursor: row-resize;
  background: url(../../../img/jQueryUi/ui-icons_454545_256x240.png) -76px -225px no-repeat;
}

.modal-dialog form[name=form_tlang] .box_form {
  margin-top: 28px;
}

.modal-dialog form[name=form_tlang] .row {
  margin-left: 0;
  margin-right: 0;
}

/* preview imatge */
form .box_thumb {
  position: relative;
  display: flex;
}

form .box_thumb .box_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #D5DFE1;
  border-radius: 6px;
  background-image: url(../../../img/placeholder_02.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  background-size: auto;
  width: 160px;
  height: 133px;
  overflow: hidden;
  cursor: pointer;
}

table form .box_thumb.frm_rdnly .box_img,
form .box_thumb.frm_rdnly .box_fld {
  cursor: default;
}

form .box_thumb .box_img.img_on {
  background: #fff url(../../../img/loader.gif) center center no-repeat;
}

form .box_thumb .box_img img {
  width: 100%;
}

form .box_thumb .badge {
  position: absolute;
  top: -6px;
  right: 5px;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  background-color: #EAEEF0;
  color: #4C6569;
}

form .box_thumb .box_fld {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #D5DFE1;
  border-radius: 6px;
  background-image: url(../../../img/placeholder_file_02.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  background-size: auto;
  width: 160px;
  height: 133px;
  overflow: hidden;
  cursor: pointer;
}
form .box_thumb .box_fld img {
  width: 61px;
}

form .box_thumb .box_fld.box_fld_little {
  background-image: url(../../../img/placeholder_file_01.png);
}

form .box_thumb .box_img.box_img_little {
  background-image: url(../../../img/placeholder_01.png);
}

form .row-img-file-trans .box_thumb .box_img {
  background-image: url(../../../img/placeholder_01.png);
}
form .row-img-file-trans .box_thumb .box_fld {
  background-image: url(../../../img/placeholder_file_01.png);
}
form .row-img-file-trans label strong {
  font-weight: 600;
}
form .row-img-file-trans .box-field-flex .input-group {
  display: flex;
}
form .row-img-file-trans .box-field-flex .input-group .input-group-btn {
  width: fit-content;
  display: flex;
}

.modal-content form .box_thumb .box_img {
  margin: 0;
}

.modal-content form .box_thumb .box_img img {
  width: 165px;
}

.radio, .checkbox {
  min-height: 22px;
}

form .checkbox,
form .radio {
  display: inline-block;
}

form h1 .checkbox, form h1 .radio {
  vertical-align: middle;
}

form h2 .checkbox, form h2 .radio {
  vertical-align: middle;
}

form h3 .checkbox, form h3 .radio {
  vertical-align: middle;
}

form h4 .checkbox, form h4 .radio {
  vertical-align: middle;
}

form h5 .checkbox, form h5 .radio {
  vertical-align: middle;
}

form h6 .checkbox, form h6 .radio {
  vertical-align: middle;
}

form span.legacy-h7 .checkbox, form span.legacy-h7 .radio {
  vertical-align: middle;
}

form .checkbox.disabled .icons,
form .radio.disabled .icons,
form .checkbox.disabled.checked .first-icon,
form .radio.disabled.checked .first-icon,
form .checkbox.disabled.checked .second-icon,
form .radio.disabled.checked .second-icon {
  color: rgba(0, 0, 0, 0.2);
  cursor: default;
}

form .form-control.tmp_disabled,
form fieldset.tmp_disabled .form-control,
form .form-control.form-disabled,
form .form-control[disabled],
form fieldset[disabled] .form-control {
  display: block;
  color: #ABC0C4;
  background-color: #EAEEF0;
  border-color: #D5DFE1;
}

form .form-control[readonly] {
  display: block;
  color: rgb(0, 0, 0);
}

form .form-control.ev_click:not(:disabled) {
  opacity: 1;
  border-color: #E3EAED;
  background-color: #fff;
  color: #34495e;
}

form .checkbox.disabled,
form .radio.disabled {
  color: #4C6569;
}

.input-group-btn .btn.into-disabled,
.input-group.focus .input-group-btn .btn.into-disabled {
  border-color: #d5dbdb;
}

form input.form-control[readonly], form textarea.form-control[readonly] {
  color: #2E383A;
  background-color: #EAEEF0;
  border: 1px solid #D5DFE1;
  opacity: 1;
}

form input.form-control[disabled], form textarea.form-control[disabled] {
  color: #2E383A;
  background-color: #EAEEF0;
  border: 1px solid #D5DFE1;
  opacity: 1;
}

.input-group .input-date-calendar button {
  border-right: 1px solid transparent !important;
  border-right-width: 1px;
}
.input-group:hover:not(.focus) .input-date-calendar:not(:hover) button {
  border: 1px solid #ABC0C4 !important;
  border-right: none;
}
.input-group:hover:not(.focus) .input-group-addon:not(:hover) {
  border: 1px solid #ABC0C4;
  border-right-color: #D5DFE1;
  transition: none;
}
.input-group.focus .input-group-addon {
  background-color: #fff;
  border-color: #3eac93;
}
.input-group .input-group-addon {
  background-color: #fff;
  border-color: #D5DFE1;
  color: #2E383A;
  transition: none;
}
.input-group .input-group-addon:first-child {
  border-right: 1px solid #D5DFE1;
}

/* error en input-group */
/*
.has-error                    .input-group-btn,
.has-error .input-group.focus .input-group-btn,
.has-error                    .input-group-addon,
.has-error .input-group.focus .input-group-addon, */
.has-error .input-group-btn .btn,
.has-error .input-group.focus .input-group-btn .btn,
.has-error .input-group-addon .btn,
.has-error .input-group.focus .input-group-addon .btn {
  color: #fff;
  background-color: #D2322C;
}
.has-error .input-group-btn .btn:hover,
.has-error .input-group.focus .input-group-btn .btn:hover,
.has-error .input-group-addon .btn:hover,
.has-error .input-group.focus .input-group-addon .btn:hover {
  color: #fff;
  background-color: #ED352E;
}

/* no aplica */
form .not_apply .form-control,
form .not_apply .radio,
form .not_apply .checkbox,
form .not_apply .input-group-btn,
form .not_apply .radio.checked .second-icon,
form .not_apply .checkbox.checked .second-icon,
form .not_apply .select2-container--bootstrap {
  opacity: 0.5;
  filter: alpha(opacity=5);
}

form .not_apply label[ref] {
  text-decoration: line-through;
}

form .not_apply .field_ops.lang {
  display: none;
}

/* modificacions caixa timer */
.ui-datepicker {
  margin-left: -50px;
}

.ui-datepicker .ui-datepicker-year {
  border: 0;
  width: auto;
  height: auto;
  text-align: center;
  background: #387B77;
  color: #fff;
}

.ui-datepicker .ui-timepicker-div {
  position: relative;
  padding: 0 10px;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.ui-datepicker .ui-timepicker-div dl {
  margin-bottom: 0;
}

.ui-datepicker .ui-timepicker-div dt {
  font-size: 80%;
}

.ui-datepicker .ui-timepicker-div .ui-slider {
  margin-bottom: 5px;
}

.ui-datepicker .ui-timepicker-div .ui_tpicker_hour,
.ui-datepicker .ui-timepicker-div .ui_tpicker_minute,
.ui-datepicker .ui-timepicker-div .ui_tpicker_second {
  margin-left: 5px;
}

.ui-datepicker .ui_tpicker_time_label {
  display: none;
}

.ui-datepicker .ui_tpicker_time {
  padding-top: 5px;
  text-align: right;
  font-size: 100%;
  line-height: 100%;
}

.ui-datepicker .ui-datepicker-buttonpane {
  position: relative;
  overflow: hidden;
  margin: 10px 0 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  border-radius: 4px;
  padding: 8px 10px;
  margin-top: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  border: 0;
  background: #D5DFE1;
  color: #4C6569;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.ui-datepicker .ui-datepicker-buttonpane button:hover {
  background-color: #EAEEF0;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-close {
  background: #387B77;
  color: #fff;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-close.ui-priority-primary:hover {
  background-color: #3D9487;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-priority-primary {
  background: #387B77;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-priority-primary:hover {
  background: #387B77;
}

.ui-datepicker.opn_tp:before {
  border-color: #387B77 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
  top: auto;
  bottom: -13px;
}

.ui-datepicker.opn_lt:before {
  left: auto;
  right: 12px;
}

.ui-slider-handle,
.ui-slider-horizontal .ui-slider-handle,
.ui-slider-handle:focus {
  background-color: #7B9BA1;
  top: -5px;
}

.ui-slider {
  background: #EAEEF0;
  height: 8px;
}

.input-date-calendar .btn {
  padding: 8px 12px;
}
.input-date-calendar .btn .sl-icon-calendar {
  font-size: 20px;
  font-weight: normal;
}

.ui-datepicker td a:hover {
  color: #2E383A;
  background-color: #EAEEF0;
}

.ui-datepicker td a.ui-state-highlight:hover {
  background-color: #3D9487;
  color: white;
}

/* on/off */
.has-switch.block {
  display: block;
}

/* multiselect */
form .select-multiple,
.select-multiple {
  margin-top: 0;
}

form .st_mltslct select,
.st_mltslct select {
  height: 42px;
  max-height: 42px;
}

.btn-group.select.select-multiple button.dropdown-toggle {
  border: 0;
  border-radius: 0;
  background: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #34495e;
  font-size: 15px;
  line-height: 1.467;
}

.btn-group.select.select-multiple button.dropdown-toggle .filter-option {
  top: 0;
  left: 0;
  padding-left: 0;
}

.btn-group.select.select-multiple button.dropdown-toggle .caret {
  right: 0;
}

.select-multiple .dropdown-menu {
  width: 100% !important;
  max-height: 400px;
}

.extraField {
  position: relative;
  width: auto;
  overflow: hidden;
  padding: 10px 0 0;
  font-size: 85%;
  line-height: 85%;
  color: #60758a;
}

.extraField input {
  display: inline-block;
  vertical-align: middle;
}

.box_desc {
  position: relative;
  overflow: hidden;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  color: rgba(255, 255, 255, 0.7);
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
}

.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  -webkit-transition: background-color 0.25s linear;
  transition: background-color 0.25s linear;
}

.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice:active {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice:hover {
  background-color: #FBF2D0 !important;
}

.select2-container--bootstrap .select2-selection--multiple .ui-sortable .select2-selection__choice.ui-sortable-helper {
  box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 0.15);
}

/* New Description */
.form-control.new-description {
  overflow: hidden;
  resize: none;
}

.form-control.new-description:focus {
  border-color: #3EAC93;
  background-color: white;
}

.form-control:hover,
.form-control:hover, .select2.select2-container--bootstrap:hover,
.tagsinput-add-container input:hover,
.form .box_tags .tagsinput-add-container input:hover,
#fld_bx_srch:hover,
input.new-description:hover,
.box_search .slc_srch:hover {
  border-color: #ABC0C4;
}

.form-control.new-description::placeholder {
  color: #607589;
  font-size: 14px;
  font-style: normal;
}

.tooltip-top-arrow-right.tooltip-description {
  max-width: 420px;
  min-width: 150px;
  width: auto;
  overflow-wrap: break-word;
  text-align: left;
}

.keep-structure-rb {
  margin: 20px 0 20px 0;
  display: none;
}

.change-folder-box {
  border: 1px solid #CFDADD;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px 15px 20px 0;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFF;
}

.change-folder-path {
  display: inline-block;
}

.change-folder-link {
  text-align: right;
  font-size: 14px;
  color: #387B77;
}

.change-folder-title {
  font-size: 14px;
  color: #2E383A;
}

.color-success {
  color: #0BA815;
}

.color-alternative {
  color: #BAAA8A;
}

.alert.alert-no-alert .icon.color-alternative {
  color: #BAAA8A;
}

.alert.alert-no-alert h6 .icon.color-alternative {
  font-size: 24px;
}

.alert.alert-no-alert h6.sl-flex {
  display: flex;
  align-items: center;
}

.verifyed input[id^=field_url_].form-control {
  border: 1px solid #0BA815;
}

/* table options */
.form-group .box_pagination {
  padding: 0;
}

.form-group .table .td_numb {
  text-align: right;
}

.col-fld .mce-panel,
.col-fld .form_field_status {
  width: 100%;
}

.col-fld .form_field_status .btn-group {
  width: 100%;
}
.col-fld .form_field_status .btn-group .btn {
  width: 100%;
  border: 1px solid #D5DFE1;
  background-color: #FFFFFF;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px 8px 0px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.col-fld .form_field_status .btn-group .btn:hover {
  background-color: #FFFFFF;
  color: #2E383A;
}
.col-fld .form_field_status .btn-group .btn span {
  display: flex;
  align-items: center;
}
.col-fld .form_field_status .btn-group .btn span i {
  margin-right: 8px;
}
.col-fld .form_field_status .btn-group .btn i {
  font-size: 20px;
  line-height: 20px;
}
.col-fld .form_field_status .btn-group ul li a i {
  margin-right: 8px;
}

.form_field_status .dropdown-menu li > a {
  padding: 10px 16px;
}
.form_field_status .dropdown-menu li > a i, .form_field_status .dropdown-menu li > a:hover i {
  padding: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 15px;
}
.form_field_status .dropdown-menu li > a i.sl-icon-edit, .form_field_status .dropdown-menu li > a:hover i.sl-icon-edit {
  background-color: #FBF2D0;
  color: #5A4B35;
}
.form_field_status .dropdown-menu li > a i.sl-icon-eye, .form_field_status .dropdown-menu li > a:hover i.sl-icon-eye {
  background-color: #BFF5C5;
  color: #05570A;
}
.form_field_status .dropdown-menu li > a i.sl-icon-eye-close, .form_field_status .dropdown-menu li > a:hover i.sl-icon-eye-close {
  background-color: #D5DFE1;
  color: #2E383A;
}
.form_field_status .dropdown-menu li > a, .form_field_status .dropdown-menu li > a:hover, .form_field_status .dropdown-menu li > a:active {
  color: #2E383A;
}
.form_field_status .dropdown-menu li.selected > a, .form_field_status .dropdown-menu li.selected > a:hover, .form_field_status .dropdown-menu li.selected > a:active {
  color: #2E383A;
}
.form_field_status div.btn-group button.btn.dropdown-toggle span i.icon.ini {
  padding: 10px;
  border-right: 1px solid #D5DFE1;
}
.form_field_status div.btn-group button.btn.dropdown-toggle span i.icon.ini.sl-icon-edit {
  background-color: #FBF2D0;
  color: #5A4B35;
}
.form_field_status div.btn-group button.btn.dropdown-toggle span i.icon.ini.sl-icon-eye {
  background-color: #BFF5C5;
  color: #05570A;
}
.form_field_status div.btn-group button.btn.dropdown-toggle span i.icon.ini.sl-icon-eye-close {
  background-color: #D5DFE1;
  color: #2E383A;
}
.form_field_status div.btn-group button.btn.dropdown-toggle[disabled] {
  background-color: #EAEEF0;
  color: #2E383A;
  opacity: 1;
}

[id^=crumbs_box_mat_].crumbs,
#crumbs_box_locations.crumbs,
#crumbs_box_products.crumbs,
#crumbs_box_catalogue.crumbs {
  padding: 125px 44px 0px 44px;
}

#crumbs_box_product_formats.crumbs {
  padding: 125px 44px 0px 44px;
}

#bx_slct_prd_frmt.header_with_title_only + script + .paragrh #crumbs_box_product_formats.crumbs {
  padding: 125px 44px 0px 44px;
}

[id^=crumbs_box_mat_].crumbs.sl_no_breadcrumb,
#crumbs_box_locations.crumbs.sl_no_breadcrumb,
#crumbs_box_products.crumbs.sl_no_breadcrumb,
#crumbs_box_catalogue.crumbs.sl_no_breadcrumb,
#crumbs_box_product_formats.crumbs.sl_no_breadcrumb {
  padding-top: 27px;
}

.sec-prd-frm_edt .mgbform[name=product_formats] #crumbs_box_product_formats.crumbs.sl_no_breadcrumb {
  padding-top: 187px;
}

#crumbs_box_products.crumbs + script + #error_div {
  margin-top: 44px;
}

[id^=crumbs_box_mat_].crumbs {
  margin-top: -40px;
}

#crumbs_box_companies_connectors .wrapper {
  margin: 0 auto;
}

#crumbs_box_table_active.crumbs {
  margin: 0 -44px 20px;
  padding: 0px 44px 0px 44px;
}

.form-group.overflow.row.crumbs_conn {
  margin-bottom: 0;
  background-color: #FFFFFF;
  padding: 20px 44px;
  margin-top: -60px;
}

.sl_breadcrumb {
  max-width: calc(100% - 88px);
  width: 100%;
  z-index: 999;
}
.sl_breadcrumb .precrumb, .sl_breadcrumb .postcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  z-index: 900;
}
.sl_breadcrumb .titlecrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;
}
.sl_breadcrumb strong {
  font-weight: 600;
  color: #205A5A;
}

.mgbform[name=product_formats] .sl_breadcrumb {
  width: calc(62% - 88px);
}

#bx_slct_prd_frmt.header_with_title_only .box_head_sec h1 span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  display: block;
}

#tags_menu.sl-button-secondary-medium {
  height: 40px;
  padding: 8px 20px;
}
#tags_menu.sl-button-secondary-medium i {
  font-size: 20px;
  line-height: 20px;
}
#tags_menu.sl-button-secondary-medium:hover {
  background-color: #EAEEF0 !important;
}

form.mgbform .box_form h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
}

.form-group.sl_item_info {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  position: absolute;
  top: 45px;
  margin: 0;
  padding-bottom: 1px;
}
.form-group.sl_item_info .ttl_prg.no_mrtp {
  margin: 0 0 4px 0;
}
.form-group.sl_item_info .ttl_prg.no_mrtp i {
  font-weight: 400;
}
.form-group.sl_item_info .ttl_prg.no_mrtp strong {
  font-weight: 600;
}

div#success_div {
  margin-left: 44px;
  margin-right: 44px;
}

.mgbform .box_form .form-group.row.dbl.sl_form_edit_group,
.mgbform .box_form .form-group.row.sep_btm.dbl.sl_form_edit_group {
  flex-direction: column;
  align-items: self-start;
}

.form .sl_form_edit_group .box_id_tags div.tagsinput {
  display: flex;
}

.form .sl_form_edit_group ul.nav li {
  background-color: #FFFFFF;
  width: 100%;
  border: transparent;
  padding: 16px;
  border-radius: 4px;
}
.form .sl_form_edit_group ul.nav li a {
  padding: 0;
  justify-content: flex-start;
}
.form .sl_form_edit_group ul.nav li a button.btn-clean {
  position: absolute;
  right: 0;
  margin: 0;
}
.form .sl_form_edit_group ul.nav li a button.btn-clean i {
  padding: 0;
  color: #2E383A;
}
.form .sl_form_edit_group ul.nav li a label.checkbox {
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
}
.form .sl_form_edit_group .page_fields ul.check_list li label {
  color: #2E383A;
  line-height: 24px;
  margin: 8px 0;
}

.box_thumb.field_company_logo {
  padding-left: 15px;
}

.alert-container-blank .paragrh.sl-payment-alert {
  padding: 40px 0px 0 0px;
}

.paragrh.sl-payment-alert {
  padding: 24px 44px 0 44px;
}
.paragrh.sl-payment-alert .alert.alert-danger h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
}

.sync_info {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.box_sync_card {
  background: white;
  padding: 32px;
  border: 1px solid #D5DDE5;
  border-radius: 8px;
  margin-bottom: 20px;
}

.tbl-wrapper {
  width: 100%;
  margin-top: 5px;
}

form .tbl-field.handsontable {
  background: transparent !important;
}

form .handsontable tr:first-child td {
  border-top: 1px solid #D5DFE1;
}
form .handsontable td:first-of-type {
  border-left: 1px solid #D5DFE1;
}
form .handsontable td {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}
form .handsontable table > tbody > tr:nth-child(2n) > td,
form .handsontable table > tbody > tr.selected-row > td,
form .handsontable table > tbody > tr:nth-child(2n) > th,
form .handsontable table > tbody > tr.selected-row > th {
  background: #f8f8f8;
}

.box_login .btn-ext-provider-container a {
  width: 100%;
}

/* TODO Comentar con Frontends */
form.form-validation .form-group label.noTitle {
  margin-top: 25px;
}
form.form-validation .form-group .checkbox.noTitle {
  margin-top: 40px;
}
form.form-validation .form-group .noPadding {
  padding-left: 0;
  padding-right: 0;
}

.select2_static_tag {
  background-color: #FBF2D0;
  color: #5A4B35;
  border: 0;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin: 2px 8px 2px 0;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

.columns_to_display_helper {
  color: #8E979E;
  font-size: 12px;
}

.fld_vl {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fld_vl::-webkit-scrollbar {
  display: none;
}

.modal-content .modal-body button.btn_config {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  margin-bottom: 24px;
}
.modal-content .modal-body .form_config .nav-tabs > li > a {
  justify-content: left;
}
.modal-content .modal-body .form_config .nav-tabs > li.active, .modal-content .modal-body .form_config .nav-tabs > li.active:hover, .modal-content .modal-body .form_config .nav-tabs > li.active:focus {
  border-radius: 4px;
  width: 100%;
}

.form_langs .checkbox.checked {
  color: #2E383A;
}

select.sl-content-light {
  color: #7B9BA1;
}

select.form-control {
  transition: color 0s linear;
}

#body_content .form form[name=sales_material] .frm_gnr_btns .btn[name=submit_cancel] {
  background-color: transparent;
  color: #2E383A;
  border: 1px solid transparent;
}
#body_content .form form[name=sales_material] .frm_gnr_btns .btn[name=submit_cancel]:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}

label.radio.sl-content-default.checked {
  color: #2E383A;
}

.bulk_form_group > *:not(:first-child):not(:last-child) {
  margin-bottom: 12px;
}

.modal-body .tbl_field_grid .handsontable {
  background-image: unset !important;
}

label.sl-checkbox {
  margin: inherit;
}

p.sl-radio-text {
  font-size: 14px;
  color: #2E383A;
}

label.sl-radio {
  margin: unset;
}

.tooltip_editor.sl-icon-info {
  margin: 0 8px 8px 0;
  height: 20px !important;
  display: inline-block;
  vertical-align: middle;
}

/*

    Rotation icons

*/
.icon_spin_box {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.icon.gly-spin,
.btn .icon.gly-spin,
.form .btn .icon.gly-spin {
  position: relative;
  display: inline-block;
  font-style: normal;
  text-align: center;
  padding: 0;
  margin: 0;
  font-family: "Glyphicons Halflings";
  content: "\e232";
  font-size: 15px; /* vertical-align: -5px; font-size: 20px; */
}

.gly-spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.gly-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.gly-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gly-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.gly-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.gly-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon.info {
  color: #3498db;
}

/*

	Loading

*/
.progress-bar {
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

#sl_loading {
  z-index: 100000;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 240, 241, 0.6);
  _display: none;
}

.modal.mod_popup #sl_loading {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}

#sl_loading.back_body {
  position: fixed;
}

#sl_loading.back_trsp {
  background: transparent;
}

#sl_loading .info_ldng {
  display: none;
}

#sl_loading .btn_cancel,
#sl_loading .progress, #sl_loading .progress-info, #sl_loading .progress-desc {
  display: none;
}

#sl_loading .progress.show, #sl_loading .progress-info.show, #sl_loading .progress-desc.show {
  display: inline;
}

#sl_loading i.img_loading,
#sl_loading .progress,
#sl_loading .progress-info,
#sl_loading .progress-desc,
#sl_loading .btn_cancel {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  margin-left: -50px;
}

#sl_loading .progress {
  margin-top: 60px;
}

#sl_loading .progress-info,
#sl_loading .progress-desc {
  margin-top: 80px;
  font-size: 17px;
  line-height: 17px;
  color: #2E383A;
  font-style: italic;
  text-align: center;
}

#sl_loading .progress-info em {
  font-size: 12px;
}

#sl_loading .progress-desc {
  margin-top: 110px;
  width: 350px;
  margin-left: -175px;
  font-size: 14px;
  text-align: center;
}

#sl_loading .progress-desc em {
  display: inline-block;
  padding: 3px 8px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

#box_content #sl_loading.back_body i.img_loading,
#box_content #sl_loading.back_body .progress,
#box_content #sl_loading.back_body .progress-info,
#box_content #sl_loading.back_body .btn_cancel {
  margin-left: 40px;
}

#box_content #sl_loading.back_body .progress-desc {
  margin-left: -75px;
}

.minus_left #box_content #sl_loading.back_body i.img_loading,
.minus_left #box_content #sl_loading.back_body .progress,
.minus_left #box_content #sl_loading.back_body .progress-info,
.minus_left #box_content #sl_loading.back_body .btn_cancel {
  margin-left: -30px;
}

.minus_left #box_content #sl_loading.back_body .progress-desc {
  margin-left: -155px;
}

@media (min-width: 1000px) {
  #box_content #sl_loading.back_body i.img_loading,
  #box_content #sl_loading.back_body .progress,
  #box_content #sl_loading.back_body .progress-info,
  #box_content #sl_loading.back_body .btn_cancel {
    margin-left: 65px;
  }
  #box_content #sl_loading.back_body .progress-desc {
    margin-left: -60px;
  }
  .minus_left #box_content #sl_loading.back_body i.img_loading,
  .minus_left #box_content #sl_loading.back_body .progress,
  .minus_left #box_content #sl_loading.back_body .progress-info,
  .minus_left #box_content #sl_loading.back_body .btn_cancel {
    margin-left: -15px;
  }
  .minus_left #box_content #sl_loading.back_body .progress-desc {
    margin-left: -140px;
  }
}
@media (min-width: 1500px) {
  #box_content #sl_loading.back_body i.img_loading,
  #box_content #sl_loading.back_body .progress,
  #box_content #sl_loading.back_body .progress-info,
  #box_content #sl_loading.back_body .btn_cancel {
    margin-left: 80px;
  }
  #box_content #sl_loading.back_body .progress-desc {
    margin-left: -45px;
  }
  .minus_left #box_content #sl_loading.back_body i.img_loading,
  .minus_left #box_content #sl_loading.back_body .progress,
  .minus_left #box_content #sl_loading.back_body .progress-info,
  .minus_left #box_content #sl_loading.back_body .btn_cancel {
    margin-left: -15px;
  }
  .minus_left #box_content #sl_loading.back_body .progress-desc {
    margin-left: -140px;
  }
}
#sl_loading .progress-bar, .progress-bar {
  transition: width 0.2s ease;
}

.progress-bar.end {
  transition: none;
}

#sl_loading i.img_loading, .sp_loading {
  display: block;
  height: 100px;
  margin-top: -50px;
  background: url(../../../img/sl_loading_bl.png) 0 0 no-repeat;
  -webkit-animation: ldnga 0.9s steps(12) infinite;
  -moz-animation: ldnga 0.9s steps(12) infinite;
  -ms-animation: ldnga 0.9s steps(12) infinite;
  -o-animation: ldnga 0.9s steps(12) infinite;
  animation: ldnga 0.9s steps(12) infinite;
}

.md_skn #sl_loading i.img_loading, .sp_loading {
  background-image: url(../../../img/sl_loading_bl.png);
}

.sp_loading {
  position: relative;
  overflow: hidden;
  width: 100px;
  margin: 0 auto;
}

.sp_loading.sep_top {
  margin-top: 20px;
}

@-webkit-keyframes ldnga {
  from {
    background-position: 0;
  }
  to {
    background-position: -1200px;
  }
}
@-moz-keyframes ldnga {
  from {
    background-position: 0;
  }
  to {
    background-position: -1200px;
  }
}
@-ms-keyframes ldnga {
  from {
    background-position: 0;
  }
  to {
    background-position: -1200px;
  }
}
@-o-keyframes ldnga {
  from {
    background-position: 0;
  }
  to {
    background-position: -1200px;
  }
}
@keyframes ldnga {
  from {
    background-position: 0;
  }
  to {
    background-position: -1200px;
  }
}
#sl_loading .btn_cancel {
  width: 30px;
  height: 30px;
  margin: 110px 0 0 -15px;
  padding: 0;
  background: transparent;
}

#sl_loading .btn_cancel i {
  width: auto;
  padding: 0;
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  color: #ccc;
}

#sl_loading .btn_cancel:hover i {
  color: #387B77;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

/*

    In-page loading

*/
#box_stats_rfrsh,
.box_stats_prcss {
  height: 20px;
  margin: 20px 0px 0px;
}

.box_default_prcss {
  height: 20px;
  margin: 0px;
}

#box_stats_rfrsh,
.box_stats_prcss,
.box_default_prcss,
#box_stats_rfrsh .info_ldng,
.box_stats_prcss .info_ldng,
.box_default_prcss .info_ldng {
  position: relative;
  width: 100%;
  overflow: hidden;
}

#box_stats_rfrsh .info_ldng .icon,
.box_stats_prcss .info_ldng .icon,
.box_default_prcss .info_ldng .icon {
  position: relative;
  float: left;
  padding: 2px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  font-family: "Glyphicons Halflings";
}

#box_stats_rfrsh .info_ldng .progress,
.box_stats_prcss .info_ldng .progress,
.box_default_prcss .info_ldng .progress {
  position: relative;
  float: left;
  width: 25%;
  margin: 5px 10px 0 0;
}

#box_stats_rfrsh .info_ldng .progress-info,
.box_stats_prcss .info_ldng .progress-info,
.box_default_prcss .info_ldng .progress-info,
#box_stats_rfrsh .info_ldng .progress-desc,
.box_stats_prcss .info_ldng .progress-desc,
.box_default_prcss .info_ldng .progress-desc {
  position: relative;
  float: left;
  margin-top: 6px;
  font-size: 13px;
  line-height: 13px;
  color: #2E383A;
  font-style: italic;
  text-align: center;
}

#box_stats_rfrsh .info_ldng .progress-info,
.box_stats_prcss .info_ldng .progress-info,
.box_default_prcss .info_ldng .progress-info {
  margin-right: 10px;
}

/*

    Secció notificacions

*/
.box_list.tp_news {
  padding: 76px 44px 44px 44px;
}

.box_list.tp_news ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.box_list.tp_news ul li {
  position: relative;
  padding: 24px 32px 28px 32px;
  margin-bottom: 8px;
  list-style-type: none;
  background-color: #FFFFFF;
  border: 1px solid #D5DFE1;
  border-radius: 8px;
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
}

.box_list.tp_news ul li:hover, .box_list.tp_news ul li.new {
  background-color: #FCF8ED;
  border: 1px solid #EEE5C0;
}
.box_list.tp_news ul li:hover button {
  display: block;
}

.box_list.tp_news li p {
  margin: 0;
  padding: 0;
}

.box_list.tp_news li button {
  position: absolute;
  top: 18px;
  right: 32px;
  margin: 0;
  color: #4C6569;
  -webkit-transition: 0.1s linear;
  transition: color 0.1s linear;
  display: none;
}
.box_list.tp_news li button i {
  font-size: 16px;
  line-height: 16px;
}

.box_list.tp_news li .dat {
  display: block;
  margin: 0 0 8px 0;
  font-size: 10px;
  line-height: 16px;
  font-style: normal;
  color: #4C6569;
}

.box_list.tp_news li .ttl {
  display: block;
  margin: 0 0 4px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
}

.box_list.tp_news li .ttl strong {
  font-weight: 700;
}

.box_list.tp_news li .txt {
  display: block;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #2E383A;
  white-space: normal;
}

.box_list.tp_news li .txt strong {
  font-weight: 600;
}

#box_notifs .txt .btn,
#box_notifs .txt .btn:hover {
  color: #fff;
}

/*

	Paginació

*/
/*
#box_content .box_pagination.paragrh  {float: right; width: 49%; padding-left: 0}
*/
#box_content .box_pagination.paragrh,
.box_pagination.block {
  position: relative;
  width: 100%;
  display: inline-block;
}

.box_pagination.block {
  min-height: 42px;
}

.box_pagination .pagination,
.box_pagination .box_rgt,
.pagination.box_rgt,
.box_list_ops .box_rgt,
.form .form-group .box_rgt {
  float: right;
}

.form .btn-group.select.box_rgt,
.box_pagination .box_rgt {
  margin-left: 8px !important;
  margin-right: 0 !important;
}

.box_form .btn em,
.box_pagination .btn em {
  display: inline;
  font-style: normal;
}

.box_pagination .btn em.alter {
  display: none;
}

.form .btn-group.select {
  margin: 0 10px 10px 0;
  vertical-align: top;
}

.form .btn-group.select button.dropdown-toggle,
.box_list_ops .btn-group.select button.dropdown-toggle {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  height: 40px;
  background-color: #D5DFE1;
  color: #2E383A;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  outline: 0;
  transition: all 0.3s ease;
}
.form .btn-group.select button.dropdown-toggle span.icon,
.form .btn-group.select button.dropdown-toggle i.icon,
.box_list_ops .btn-group.select button.dropdown-toggle span.icon,
.box_list_ops .btn-group.select button.dropdown-toggle i.icon {
  font-weight: 400;
  font-size: 20px;
  margin-right: 8px;
}
.form .btn-group.select button.dropdown-toggle span.icon.icon_order_filter,
.form .btn-group.select button.dropdown-toggle i.icon.icon_order_filter,
.box_list_ops .btn-group.select button.dropdown-toggle span.icon.icon_order_filter,
.box_list_ops .btn-group.select button.dropdown-toggle i.icon.icon_order_filter {
  margin-right: 0;
}
.form .btn-group.select button.dropdown-toggle.btn-tertiary,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-tertiary {
  background-color: transparent;
  color: #2E383A;
}
.form .btn-group.select button.dropdown-toggle.btn-tertiary:hover,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-tertiary:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}

.box_list_ops .btn-group.select button.dropdown-toggle#tags_menu {
  padding: 8px 20px;
}
.box_list_ops .btn-group.select button.dropdown-toggle#tags_menu i {
  font-size: 20px;
  line-height: 20px;
}

button.btn.btn-primary.btn_left.btn_refresh {
  height: 40px;
}

.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I {
  background: #EAEEF0;
  color: #4C6569;
}

.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V .icon,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D .icon,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I .icon {
  color: #4C6569;
}

.form .btn-group.select button.dropdown-toggle .caret,
.box_list_ops .btn-group.select button.dropdown-toggle .caret {
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.box_list_ops .box_cont_rgt {
  position: relative;
  display: block;
  float: right;
}

.box_list_ops .btn_left {
  float: left;
}

.box_list_ops > .btn_left,
.box_list_ops > .btn_right,
.box_list_ops .box_rgt,
.box_list_ops .box_lft,
.box_list_ops .pagination.pbtm {
  margin-bottom: 10px;
  height: 40px;
}

.box_list_ops .box_lft {
  float: left;
}

.btn-group.select.md_highlight button.dropdown-toggle {
  background: #387B77;
  border-color: #387B77;
}

#head #actions.btn-group.select.md_highlight button.dropdown-toggle {
  background: #387B77;
  color: #ffffff;
  border-color: inherit;
  border: 0;
  outline: 1px solid transparent !important;
}

.btn-group.select.md_highlight button.dropdown-toggle:hover,
.box_list_ops .btn-group.select button.dropdown-toggle:hover,
.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_V:hover {
  background: #EAEEF0;
  color: #4C6569;
}

.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_D:hover {
  background: #EAEEF0;
}

.box_list_ops .btn-group.select button.dropdown-toggle.btn-st_I:hover {
  background: #EAEEF0;
}

.btn-group.select.md_highlight button.dropdown-toggle .icon {
  color: #2E383A;
}

.btn-group.select.md_highlight button.dropdown-toggle .title,
.box_list_ops .btn-group.select button.dropdown-toggle:hover .title {
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

.btn-group.select.md_highlight button.dropdown-toggle .caret,
.box_list_ops .btn-group.select button.dropdown-toggle:hover .caret {
  border-top-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

.box_list_ops .btn-group.select .dropdown-menu {
  right: 0;
  left: auto;
  min-width: 60px;
}

.box_pagination.block .btn-group {
  margin-top: 0;
}

.drop_right ul.dropdown-menu,
.box_list_ops .btn-group.drop_right ul.dropdown-menu,
.form .btn-group.drop_right ul.dropdown-menu {
  left: 0px;
  right: auto;
  min-width: 125px;
}

.btn-group.btns_xcl button.btn.btn-tertiary {
  font-weight: 600;
  max-height: 40px;
}
.btn-group.btns_xcl button.btn.btn-tertiary.btn_undo:disabled, .btn-group.btns_xcl button.btn.btn-tertiary.btn_redo:disabled {
  background-color: transparent;
  color: #93AFB4;
  cursor: default;
  opacity: 1;
}
.btn-group.btns_xcl button.btn.btn-tertiary.btn_undo .icon, .btn-group.btns_xcl button.btn.btn-tertiary.btn_redo .icon {
  margin-right: 0px;
}
.btn-group.btns_xcl button.btn.btn-tertiary:last-child:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #D5DFE1;
}
.btn-group.btns_xcl button.btn.btn-tertiary:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #D5DFE1;
}
.btn-group.btns_xcl button.btn.btn-tertiary:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 4px;
}

.box_pagination.box_list_ops .btn-group.select.force-bottom {
  margin: 0 8px 0 0;
}

.pagination ul {
  border-radius: 4px;
}

.pndng_pmnt_modal i {
  font-size: 46px;
  padding-top: 40px;
}

.pndg_pmnt_modal_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.pndg_pmnt_modal_info {
  padding: 0 50px;
  font-size: 14px !important;
}

#btn_accept_pndng_pmnt[disabled] {
  display: unset;
  background-color: #ccc;
  color: #000;
}

/*

	## popups ##

*/
.modal.mod_popup {
  z-index: 88888;
}

.modal.mod_popup .modal-content {
  height: 100%;
}

.modal.mod_popup .data-loaded {
  position: relative;
  display: inline-block;
  width: 100%;
}

.modal.mod_popup .modal-body {
  z-index: 2;
  min-height: 250px;
  display: none;
}

.modal.mod_popup .modal-footer {
  z-index: 1;
}

.modal.mod_popup.pop_min .modal-body {
  min-height: 82px;
}

.modal.mod_popup .modal-body.eshow {
  display: block;
}

.modal.mod_popup.direct .modal-body {
  min-height: 0;
  display: block;
}

.modal.mod_popup .modal-body.nobrd {
  /* He comentado esta linea porque no se para que sirve y jode los modals que requieren espacio suficiente para cajas de descrpción */
  /*height: 50vh !important;*/
  padding: 0;
}

.modal .modal-body {
  z-index: 5;
  position: relative;
  overflow-y: auto;
  overflow-x: inherit;
  width: 100%;
  margin-top: 5px;
}

.modal .modal-footer {
  z-index: 4;
}

.modal .modal-footer .btn.disabled,
.modal .modal-footer .btn[disabled],
.modal .modal-footer .sl-button-primary-medium[disabled]:not(.js-move-items-btn),
.modal .modal-footer .sl-button-secondary-medium[disabled] {
  display: none;
}

.form_slim {
  position: relative;
}

.form_slim.sp_top {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.form_slim.sp_btm {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.form_slim .box_info_required,
.form_slim .required_flag {
  display: none;
}

.form_slim .btn-group {
  margin: 0;
}

.box_inf_fld div .img_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.box_inf_fld div .img_box .xld {
  height: 130px;
}

.box_inf_img .table, .box_inf_fld .table {
  border: unset !important;
}

.box_inf_img .table > * > tr > td, .box_inf_fld .table > * > tr > td {
  border-top: unset !important;
}

.box_inf_img .table-hover.no-border, .box_inf_fld .table-hover.no-border {
  width: auto;
}

.box_inf_img .table-hover.no-border td, .box_inf_fld .table-hover.no-border td {
  border: 0;
  background: unset;
}

.box_inf_img .table-hover.st_list_refs thead tr th,
.box_inf_fld .table-hover.st_list_refs thead tr th {
  border-bottom: 1px solid #e1e4e7 !important;
}

.box_inf_img .st_list_refs thead tr th {
  padding: 12px 8px 12px 0px;
  height: auto;
}

.box_inf_img .st_list_refs tbody tr td {
  padding: 12px 8px 0px 0px;
  height: auto;
  background-color: transparent;
}
.box_inf_img .st_list_refs tbody tr td:hover {
  background-color: transparent;
}

.box_inf_img .table-hover.st_list_refs tbody tr td a,
.box_inf_fld .table-hover.st_list_refs tbody tr td a {
  color: #387B77;
}

.box_inf_img .table-hover.st_list_refs tbody tr td a:hover,
.box_inf_fld .table-hover.st_list_refs tbody tr td a:hover {
  color: #3D9487;
}

.img_info_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #2E383A;
  min-width: 100px;
}

.img_info_detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #4C6569;
}

.box_inf_links_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #2E383A;
}

.modal.mod_popup .crumbs .nav-tabs > li.active > a,
.modal.mod_popup .crumbs .nav-tabs > li.active > a:hover,
.modal.mod_popup .crumbs .nav-tabs > li.active > a:focus {
  background: #fff;
  border-bottom-color: #387B77;
}

.modal.mod_popup .table-striped > tbody > tr:nth-child(2n+1) > td,
.modal.mod_popup .table-striped > tbody > tr:nth-child(2n+1) > th {
  background: #fff;
}

.modal.mod_popup .table-striped > tbody > tr:nth-child(2n) > td,
.modal.mod_popup .table-striped > tbody > tr.selected-row > td,
.modal.mod_popup .table-striped > tbody > tr:nth-child(2n) > th,
.modal.mod_popup .table-striped > tbody > tr.selected-row > th {
  background: #F9FAFB;
}

.modal.mod_popup .box_msg_action {
  margin-bottom: 20px;
}

.modal.mod_popup .img_alert {
  display: block;
  padding: 20px;
  border-radius: 6px;
  background: #f0f0f0;
  text-align: center;
  color: #999;
}

.modal.mod_popup table.mod_selector > tbody > tr:hover td,
.table-striped > tbody > tr.selectable:hover td {
  background: #F8F8F8;
  color: #4C6569;
  cursor: pointer;
}

.modal.mod_popup table.mod_selector > tbody > tr:hover td strong,
.table-striped > tbody > tr.selectable:hover td strong {
  color: #4C6569;
}

.modal.mod_popup table.mod_selector > tbody > tr:hover .st_V,
.modal.mod_popup table.mod_selector > tbody > tr:hover .label-primary,
.table-striped > tbody > tr.selectable:hover .st_V,
.table-striped > tbody > tr.selectable:hover .label-primary {
  background: #fff;
  color: #387B77;
}

.modal.mod_popup .modal-body .nav.nav-tabs.nav_tabs_img_size li.active a {
  border-bottom-color: #387B77;
  border-width: 2px;
}

.modal-content .modal-body .nav-tabs.border-green {
  border: 1px solid #3EAC93;
}
.modal-content .modal-body .nav-accordion label.checkbox, .modal-content .modal-body .nav-accordion label.checkbox.checked,
.modal-content .modal-body .page_fields label.checkbox,
.modal-content .modal-body .page_fields label.checkbox.checked {
  color: #2E383A;
}

.bx_trl_fld {
  width: 100%;
}

.field_tp .field_ops button:not(.itemValidationButton) {
  background-color: #D5DFE1;
  color: #2E383A;
  height: 24px !important;
}
.field_tp .field_ops button:not(.itemValidationButton):hover {
  background-color: #EAEEF0;
  color: #4C6569;
  border: 1px solid transparent;
}
.field_tp .field_ops button:not(.itemValidationButton):active {
  background-color: #CFDADD;
  color: #4C6569;
}
.field_tp .field_ops button:not(.itemValidationButton):disabled {
  background-color: #EAEEF0;
  color: #93AFB4;
}
.field_tp .field_ops button:not(.itemValidationButton):focus {
  background-color: #D5DFE1;
  color: #2E383A;
  border: 1px solid #3EAC93;
}
.field_tp .field_ops button:not(.itemValidationButton) i {
  margin: 0;
  font-size: 16px;
  line-height: 16px;
}

.modal-content .modal-body .field_tp .field_ops button i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 0px;
}

#encaix #body #box_content .paragrh {
  background-color: #FFFFFF;
}

#encaix #body .form .frm_gnr_btns,
#encaix #body .form .frm_max_btns,
#encaix #body .frm_gnr_btns,
#encaix #body .frm_max_btns {
  left: 0;
  padding: 20px 32px;
}
#encaix #body .form .frm_gnr_btns button.btn.btn-lg.btn-primary,
#encaix #body .form .frm_max_btns button.btn.btn-lg.btn-primary,
#encaix #body .frm_gnr_btns button.btn.btn-lg.btn-primary,
#encaix #body .frm_max_btns button.btn.btn-lg.btn-primary {
  border: 1px solid #D5DFE1;
  background-color: #387B77;
  color: #FFFFFF;
}
#encaix #body .form .frm_gnr_btns button.btn.btn-lg.btn-primary:hover,
#encaix #body .form .frm_max_btns button.btn.btn-lg.btn-primary:hover,
#encaix #body .frm_gnr_btns button.btn.btn-lg.btn-primary:hover,
#encaix #body .frm_max_btns button.btn.btn-lg.btn-primary:hover {
  background-color: #3D9487;
}

.modal-body form :last-child:is(.form-group):not(.no_mrbt) {
  margin-bottom: 0px;
}

.related_items_table {
  padding-right: 0px !important;
}

.related_items_table label.checkbox .first-icon {
  z-index: 2;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover td strong,
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover td {
  color: #34495e;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td.related_items_sorting {
  width: 0px;
  padding: 0 24px;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move {
  color: #4C6569;
  opacity: 1;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move i {
  font-size: 16px;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move.act_move_disabled {
  opacity: 0.3;
  cursor: default;
}

.modal.mod_popup table.mod_selector > tbody > tr:hover .st_V,
.modal.mod_popup table.mod_selector > tbody > tr:hover .label-primary,
.table-striped > tbody > tr.selectable:hover .st_V,
.table-striped > tbody > tr.selectable:hover .label-primary {
  background: #fff;
  color: #1abc9c;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover .st_V {
  background: #BFF5C5;
  color: #05570A;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover .label-primary {
  background: #D5DFE1;
  color: #4C6569;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row.ui-sortable-helper {
  box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
}

.related_items_placeholder,
.related_items_placeholder * td {
  height: 43px;
  width: 100%;
}

.modal .btn-group button.dropdown-toggle {
  min-height: 42px;
}

.modal .text-center .icon-top {
  font-size: 40px;
}

.modal .text-center .title_text {
  font-size: 1em;
}

.modal .text-center .form-group {
  font-size: 0.8em;
  padding: 0 70px;
}

.link_pattern_test_alert span.legacy-h7 {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.link_pattern_test_alert.alert-success span.legacy-h7 {
  color: #05570A;
  font-weight: 700 !important;
}

.link_pattern_test_alert ul {
  margin-left: 36px;
}

.modal-body .row .sl-alert-banner {
  width: 100%;
}

/*

	Buscador i tags

*/
.box_search {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 65px;
  padding-top: 20px;
}

.box_search label {
  margin: 0 10px 5px 0;
}

.box_search label.end {
  margin: 0;
}

.box_search i.icon {
  font-size: 16px;
  color: #ABC0C4;
}

.box_search .inp_txt {
  background: transparent;
  border: 0;
  width: auto;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
}

.box_search .inp_txt:-moz-placeholder {
  color: #8b989b;
}

.box_search .inp_txt::-moz-placeholder {
  color: #8b989b;
}

.box_search .inp_txt:-ms-input-placeholder {
  color: #8b989b;
}

.box_search .inp_txt::-webkit-input-placeholder {
  color: #8b989b;
}

.box_search .slc_srch {
  position: relative;
  width: 45%;
  height: 45px;
  padding-top: 5px;
  min-width: 300px;
  max-width: 45%;
  background-color: #ffffff;
  max-height: 40px;
  border: 1px solid #D5DFE1;
  padding: 6px 12px;
  border-radius: 4px;
}

.box_search .slc_srch:focus-within {
  border: 1px solid #3EAC93;
}

.box_search .box_srch_npt {
  height: 100%;
  position: absolute;
  left: 35px;
  right: 0;
  bottom: 0;
}

.box_search .box_srch_npt .dropdown-menu p {
  padding: 6px 15px 8px;
  margin: 0;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.6);
}

.box_search .slc_srch input {
  height: 100%;
  width: 100%;
  min-width: none;
  max-width: none;
}

.box_search .slc_srch {
  transition: all 0.15s ease 0s;
}

.box_search .slc_srch.md_mx {
  width: 100%;
  max-width: 100%;
}

.box_search .slc_srch.focus i.sl-icon-search {
  color: #2E383A;
}

.box_list_ops .slc_srch .btn-group .dropdown-arrow {
  right: -5px;
}

.box_list_ops .slc_srch .btn-group.select .dropdown-menu {
  right: auto;
  left: -10px;
}

.box_list_ops .slc_srch.md_mx .btn-group.search-help .dropdown-menu {
  left: auto;
  right: 0;
}

.box_search .btn-group.select .dropdown-toggle {
  height: 35px;
}

.box_search .btn-group.select.btn-more button.dropdown-toggle {
  margin-right: 2px;
}

.search-list,
.search-fields {
  position: absolute;
  height: 30px;
  left: 55px;
  right: auto;
}

.search-list .dropdown-arrow,
.search-fields .dropdown-arrow {
  left: 10px;
}

.search-list a,
.search-list a.active,
.search-fields a,
.search-fields a.active {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
}

.box_conn_srch .search-list,
.box_conn_srch .search-fields {
  top: 36px;
}

.box_conn_srch .search-list ul,
.box_conn_srch .search-fields ul {
  top: 30px;
  left: 0;
  right: auto;
}

.box_conn_srch .search-list .dropdown-short li,
.box_conn_srch .search-fields .dropdown-short li,
.box_conn_srch .search-list .dropdown-large li,
.box_conn_srch .search-fields .dropdown-large li {
  margin-right: 0;
}

.conn_ops_check .tagsinput-add-container,
.box_list_ops .tagsinput-add-container {
  display: none;
}

.conn_ops_check .box_tags.op_create .tagsinput-add-container {
  display: inline;
  margin-right: 10px;
}

.conn_ops_check .box_tags.op_create .tagsinput-add-container input {
  min-width: auto;
  max-width: 150px;
}

.modal-body .conn_ops_check .box_tags.op_create .tagsinput-add-container input {
  min-width: 150px;
}

.conn_ops_check select.form-control.disabled,
.conn_ops_check select.form-control .disabled {
  display: none;
}

.conn_ops_check select .disabled {
  display: none;
}

.box_search .tagsinput {
  margin-bottom: 0;
}

.box_tags {
  position: relative;
  display: block;
  width: auto;
  float: right;
}

.form .box_tags,
.box_tags .tagsinput {
  float: left;
}

.box_tags .btn {
  height: 40px;
}

.box_tags .btn-group.select {
  margin-bottom: 10px;
}

.box_tags .info {
  color: rgba(0, 0, 0, 0.25);
}

.box_tags .btn_slc_logic {
  margin-right: 10px;
}

.form .box_id_tags,
.form .box_id_tags .tagsinput {
  float: none;
  width: auto;
  height: auto;
  min-height: 42px;
  overflow: visible;
  display: inline-block;
}

p.advs-modal-text {
  font-size: 16px;
  font-style: italic;
  margin-top: 16px;
}

.adv-search-actions .dropdown-menu {
  max-width: 175px;
  margin-top: 14px;
  z-index: 9;
}

.adv-search-actions.open > .dropdown-menu {
  margin-top: 18px !important;
}

.adv-search-actions .dropdown-arrow {
  left: 70px;
  margin-top: 6px;
  border-style: solid;
  border-width: 0 10px 9px 10px;
  border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #ccc rgba(204, 204, 204, 0);
  height: 0;
  opacity: 0;
  position: absolute;
  right: 13px;
  top: 100%;
  width: 0;
  z-index: 10;
  -webkit-transform: rotate(360deg);
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.adv-search-actions.open > .dropdown-arrow {
  margin-top: 10px;
  opacity: 1;
}

.adv-search-actions .dropdown-arrow::after {
  content: " ";
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
  position: absolute;
  right: -9px;
  top: 0;
  width: 0;
  height: 0;
  margin-top: 1px;
}

.adv-search-actions ul {
  border-radius: 6px;
}

button#advanced-search i {
  color: #2E383A;
  position: relative;
  font-size: 16px;
  margin: 0;
}

button#advanced-search {
  padding: 2px;
  margin-right: 12px;
  border-radius: 4px;
}
button#advanced-search:hover {
  background-color: #EAEEF0;
}

.adv-new-tooltip .tooltip-inner {
  background-color: #2E383A;
  color: white;
  min-width: 220px;
}

.adv-new-tooltip .tooltip-inner span {
  color: white;
  font-weight: bolder;
}

.adv-new-tooltip.tooltip.top .tooltip-arrow {
  border-top-color: #FFF;
  margin-left: -5px;
}

.adv-danger-tooltip .tooltip-inner {
  background-color: #e74c3c;
  color: #FFF;
  box-shadow: 0px 4px 8px rgba(52, 73, 94, 0.15);
}

.adv-danger-tooltip.tooltip.top .tooltip-arrow {
  border-top-color: #e74c3c;
  margin-left: -5px;
}

#adv-search-conditions a {
  cursor: pointer;
}

#adv-search-conditions .asr_icon {
  text-align: right;
  margin-top: 4px;
  padding-left: 0px;
  padding-right: 8px;
}

#adv-search-conditions .asr_icon a {
  color: #CFDADD;
}

#adv-search-conditions .asr_icon a.act_move {
  cursor: grab;
}

#adv-search-conditions .asr_icon a.act_trash:hover {
  color: #D2322C;
}

#adv-search-conditions .asr_icon a.act_move:hover {
  color: #7B878E;
}

#adv-search-conditions .asr_icon span {
  margin: 0 5px;
  font-size: 24px;
}

#adv-search-conditions .bool-operator {
  border-top: 1px solid #D5DFE1;
  margin: 4px 0 4px 0;
  height: 24px;
  text-align: center;
}

#adv-search-conditions .bool-operator-line {
  margin-top: -13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 4px;
}

#adv-search-conditions .bool-operator a {
  display: inline-block;
  height: 24px;
  min-width: 24px;
  border: 1px solid transparent;
  margin: 0 8px;
  text-align: center;
  padding: 4px 8px;
  color: #005583;
  background-color: #D7F3F9;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
}
#adv-search-conditions .bool-operator a:hover {
  background-color: #E9F9FB;
}

#adv-search-conditions .bool-operator a.selected {
  background-color: #FBF2D0;
  color: #5A4B35;
}

#adv-search-conditions .condition-row {
  margin: 0 -15px 28px -15px;
}

#adv-search-conditions .condition-row select.form-control {
  width: 100%;
}

#adv-search-conditions .date-control, #adv-search-conditions .time-control, #adv-search-conditions .datetime-control {
  opacity: 1;
  background: #fff;
  border: 1px solid #D5DFE1;
  color: #2E383A;
  display: inline-block;
}

#adv-search-conditions .date-control {
  background: url("../../../img/icons/calendar.png") no-repeat;
  background-position: 90%;
  background-size: 20px;
}

#adv-search-conditions .time-control {
  background: url("../../../img/icons/clock.png") no-repeat;
  background-position: 90%;
}

#adv-search-conditions .datetime-control {
  background: url("../../../img/icons/calendar.png") no-repeat;
  background-position: 97%;
  background-size: 20px;
}

#adv-search-conditions .date-control:focus, #adv-search-conditions .time-control:focus, #adv-search-conditions .datetime-control:focus {
  border-color: #387B77;
}

#adv-search-conditions .numeric-control {
  width: auto;
}

#adv-search-conditions .numeric-control-left {
  width: 88%;
  float: left;
}

#adv-search-conditions .numeric-control-right {
  width: 12%;
  float: right;
}

#adv-search-conditions .numeric-control.value-text {
  padding: 9px 0 0 5px;
  font-size: 14px;
  color: #34495E;
}

#adv-search-conditions .check_list {
  padding: 6px 0 0 0;
}

#adv-search-conditions .value-slider {
  margin: 15px 0 0 0;
}

#adv-search-conditions .droppable-container {
  height: 2px;
}

#adv-search-conditions .droppable-container.droppable-active {
  height: 45px;
  background-color: #EFEFEF;
}

.last-droppable-container {
  margin: 0 -15px 8px -15px;
  height: 2px;
}

.last-droppable-container.droppable-active {
  height: 45px;
  background-color: #EFEFEF;
}

#adv-search-conditions .field-container .dropdown-menu {
  max-height: 220px;
  overflow-y: auto;
  border-radius: 6px;
  margin-top: 10px !important;
  /*min-width: fit-content;*/
}

#adv-search-conditions .field-container .dropdown-arrow {
  margin-top: 1px;
}

.select #advanced-search.btn .fui-gear {
  top: 1px;
  color: #387B77;
}

#adv-srch-save-container {
  display: inline-block;
}

#adv-srch-save-container div {
  height: 22px;
  margin-top: 4px;
  top: -50%;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: 25px;
}

#adv-srch-save-container .dropdown-arrow {
  left: 25px;
  margin-top: 10px;
}

#adv-srch-save-container a.btn {
  display: none;
  width: auto;
  padding: 8px 20px;
  background-color: transparent;
  color: #666666;
  border-radius: 4px;
  margin: 0;
  margin-top: -12px;
}
#adv-srch-save-container a.btn:hover {
  background-color: #EAEEF0;
}
#adv-srch-save-container a.btn:hover span {
  color: #2E383A;
}
#adv-srch-save-container a.btn:active {
  background-color: #D5DFE1;
}
#adv-srch-save-container a.btn:active span {
  color: #2E383A;
}
#adv-srch-save-container a.btn:focus {
  border: 1px solid #3EAC93;
}

#adv-srch-save-container a.btn span.sl-icon-star-filled:before {
  color: #BAAA8A;
}

#adv-srch-save-container a.btn {
  height: 40px;
}

#adv-srch-save-container a.btn span {
  font-size: 14px;
  line-height: 24px;
  vertical-align: baseline;
  color: #2E383A;
  display: flex;
}

#adv-srch-save-container a.btn span:before {
  vertical-align: text-bottom;
  font-size: 20px;
  margin-right: 8px;
  color: #2E383A;
}

#adv-srch-save-container a.svb-active {
  display: flex;
  align-items: center;
}

.adv-srch-dropdown li h5 {
  font-size: 14px;
  font-weight: 700;
  color: #2E383A;
  padding: 3px 0 5px 15px;
}

.adv-srch-dropdown li h6 {
  font-size: 14px;
  font-weight: normal;
  padding: 0px 0 5px 15px;
  color: #4C6569;
}

.adv-srch-dropdown li.moreinfo a {
  background-color: transparent;
}

.adv-srch-dropdown li.moreinfo span {
  color: #2E383A;
  font-size: 20px;
  vertical-align: text-top;
  margin-right: 10px;
}
.adv-srch-dropdown li.moreinfo a:hover span {
  color: #3D9487;
}

.adv-srch-dropdown li a.not_srch {
  cursor: pointer;
}

.adv-srch-dropdown i.icon {
  font-size: 14px;
}

.adv-srch-ztop {
  z-index: 99999 !important;
}

#field-lang-option-container {
  position: absolute;
  width: 300px;
  height: 300px;
  border: 1px solid #000;
  z-index: 999999;
}

#adv-search-conditions .col-sm-2, #adv-search-conditions .col-sm-4, #adv-search-conditions .col-sm-5 {
  padding: 0 8px;
}

#adv-search-conditions .form-group div.field-container {
  padding: 0 8px 0 16px;
}

.adv_srch_popup_mess {
  margin: 10px 0 0 0;
  color: #E74C3C;
  font-size: 13px;
}

#ui-datepicker-div {
  z-index: 99999 !important;
}

.ui-datepicker:before {
  left: 50%;
}

/*  ## Bootstrap Select */
.btn-group.bootstrap-select {
  margin-top: 0;
}

.btn-group.bootstrap-select button {
  color: #34495e;
  background: #fff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  padding: 8px 24px 8px 12px;
}

.btn-group.bootstrap-select.open button,
.btn-group.bootstrap-select button:focus {
  border-color: #387B77;
  color: #34495e;
}

.btn-group.bootstrap-select .dropdown-toggle {
  z-index: 101;
}

.btn-group.bootstrap-select .bs-searchbox {
  padding: 12px;
}

.btn-group.bootstrap-select > .dropdown-menu.open {
  z-index: 101;
  margin-top: 5px !important;
  overflow: hidden;
}

.btn-group.bootstrap-select .open > .dropdown-menu {
  margin-top: 0 !important;
  overflow: hidden;
}

.btn-group.bootstrap-select .h-limit .dropdown-menu {
  max-height: 250px;
}

body .btn-group.bootstrap-select {
  z-index: 100000;
}

.bootstrap-select .open {
  display: none;
} /* evitem bug */
.bootstrap-select.open .open {
  display: block;
}

#box_content .box_form .btn-group.bootstrap-select .dropdown-menu {
  margin-bottom: 10px;
}

.sl-select select,
.sl-select select.form-control {
  -ms-overflow-style: unset;
  background-image: unset;
  background-repeat: unset;
  background-position: unset;
  background-size: unset;
  /* min-width: fit-content; */
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

/* tags input modificat */
.tagsinput {
  background: transparent;
  border: 0;
  padding: 0;
}

.tagsinput.disabled {
  display: inline-block;
}

.tagsinput .tag {
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 10px 10px 0;
}

.tagsinput .tag.tag-default {
  background: #7b8996;
}

.tagsinput .tag i.icon {
  vertical-align: middle;
  padding-right: 5px;
}

.tagsinput-add-container {
  vertical-align: top;
}

.tagsinput-add-container div {
  display: none;
}

.tagsinput-add-container input {
  min-width: 175px;
  margin: 0;
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  font-size: 16px;
  line-height: 18px;
}

.tagsinput-add-container input,
.form .box_tags .tagsinput-add-container input {
  color: #93AFB4 !important;
}

.tagsinput-add-container input:focus,
.form .box_tags .tagsinput-add-container input:focus {
  color: #2E383A !important;
}

.tagsinput .tag.disabled:hover {
  padding: 12px 20px !important;
}

.tagsinput .tag.disabled:hover .tagsinput-remove-link,
.tagsinput.form-disabled .tagsinput-remove-link {
  display: none;
}

.tagsinput-default .tag,
.tagsinput-default .tag.disabled:hover {
  background: #bdc3c7;
  color: white;
  cursor: default;
}

.form-disabled .tag,
.form-disabled .tag:hover {
  background-color: #aaa;
  color: #fff;
  padding: 12px 16px 12px 20px;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
  cursor: default;
}

.fileinput .input-group {
  display: flex;
}
.fileinput .input-group .uneditable-input {
  flex: 5;
}
.fileinput .input-group .btn-file {
  flex: 1;
  display: flex;
  width: 100%;
}

.input-group .btn-file input {
  z-index: 0 !important;
}

.form-group .tagsinput-add-container input {
  border-color: #BDC3C7;
}

.tagsinput-add-container input:hover, .tagsinput-add-container input:focus {
  border-color: #ABC0C4;
}

.form .form-group .tagsinput-primary {
  float: left;
}

.form .form-group .box_flt_rgt {
  margin: 0 0 0 0px;
}

.form .form-group .box_flt_rgt.slct_empty {
  margin: 0;
}

.form .box_tags.fld_combi {
  display: inline-block;
  float: none;
}

.form .box_tags select {
  width: 150px;
}

.form .box_tags select.disabled {
  display: none;
}

.form .box_tags select option {
  font-size: 12px;
}

.form .box_tags select option.disabled {
  display: block;
  color: #ccc;
}

.tagsinput-add-container input,
.form .box_tags .tagsinput-add-container input {
  border: 1px solid #D5DFE1;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  height: 40px;
  outline: none;
  margin-bottom: 8px;
}
.tagsinput-add-container input:focus,
.form .box_tags .tagsinput-add-container input:focus {
  border: 1px solid #3EAC93;
}

.form .tagsinput {
  margin-bottom: 0;
}

.form select option.list_not_translated {
  font-style: italic;
  color: #AAA;
}

.form .has-switch label {
  z-index: 2;
}

/* Editable version of tags */
.op_create .tag-options-menu {
  display: none;
  border: 1px solid #000;
  background-color: #CCC;
}

.op_create .tagsinput .tag {
  display: inline-block;
  padding: 0;
  overflow: hidden;
}

.op_create .tagsinput .tag span,
.op_create .tagsinput .tag .tagsinput-edit-link {
  display: inline-block;
}

.op_create .tagsinput .tag .tagsinput-new-remove-link {
  display: inline-block;
  padding: 12px 0;
  line-height: 100%;
  color: #5A4B35;
  min-width: 0px;
  margin: 0;
}

.op_create .tagsinput .tag .tagsinput-edit-link:hover {
  cursor: text;
}

.op_create .tagsinput .tag .tagsinput-new-remove-link {
  margin-left: 4px;
  width: 16px;
}

.op_create .tagsinput .tag .tagsinput-new-remove-link:after {
  content: "\e922";
  font-family: "sally-icon";
  font-size: 16px;
}

.op_create .tagsinput .tagsinput-edit-control {
  border: 1px solid #D5DFE1;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  padding: 10px 15px;
}

.box_tags + .box_usr_inf {
  margin-top: 4px;
}

.box_tags .tagsinput {
  display: flex;
  flex-wrap: wrap;
}
.box_tags .tagsinput .tag {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  background-color: #FBF2D0;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  border: none;
  height: 40px;
  color: #5A4B35;
  margin: 0 8px 0 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.box_tags .tagsinput .tag .tagsinput-remove-link {
  opacity: 1;
  padding: 0;
  position: unset;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.box_tags .tagsinput .tag .tagsinput-remove-link::before {
  font-family: "sally-icon";
  content: "\e922";
  color: #5A4B35;
}
.box_tags .tagsinput .tagsinput-add-container {
  margin: 0 8px 0 0;
}

.box_tags.box_rgt .tagsinput {
  overflow: hidden;
}

.list-tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.tag-drag-handler {
  color: #BAAA8A;
  font-size: 20px;
  margin-right: 8px;
  cursor: grab;
}
.tag-drag-handler:hover, .tag-drag-handler:active, .tag-drag-handler:focus {
  color: #5A4B35;
}

.tag-hidden-placeholder {
  border: 1px solid #D5DFE1 !important;
  background-color: white !important;
}

.is-collapsable {
  display: none;
  opacity: 0;
}

.tree-panel__folders__cont {
  bottom: 0px;
  position: fixed;
  width: 299px;
}

.tree-panel__folders {
  position: absolute;
  left: 0;
  height: 24px;
  z-index: 501;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  background: #fff;
  width: 100%;
}

.tree-panel__toggler {
  position: fixed;
  margin-left: 287px;
  height: 24px;
  z-index: 501;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transition: margin-left 0.1s linear;
}
.tree-panel__toggler.tree-panel--collapsed {
  margin-left: 19px;
  transition: margin-left 0.1s linear;
}

.tree-panel__toggler--icon {
  cursor: pointer;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  color: #2E383A;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  border: 1px solid #D5DFE1;
}
.tree-panel__toggler--icon::before {
  margin-left: 3px;
}
.tree-panel__toggler--icon:hover {
  color: #3D9487;
}

.tree-panel {
  background-color: white;
  padding: 0;
  border-right: 1px solid #D5DFE1;
  position: fixed;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1001;
  min-width: 300px;
  transition: all 0.1s linear;
}
.tree-panel.tree-panel--collapsed {
  max-width: 32px;
  min-width: 32px;
  width: 32px;
  transition: all 0.1s linear;
}

.tree-panel::-webkit-scrollbar {
  display: none;
}

.tree-panel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tree-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 24px 24px;
}

.tree-panel-title {
  font-size: 16px;
  font-weight: bold;
  color: #2E383A;
}

.tree-panel-header button {
  width: 40px;
}
.tree-panel-header button i {
  font-size: 20px;
}

.tree-panel--modal {
  padding: 0;
}

.tree-panel ul,
.tree-panel--modal ul {
  padding-left: 0px;
}

.tree-panel ul li,
.tree-panel--modal ul li {
  list-style-type: none;
}

.tree-panel-folders {
  z-index: 999;
}

.tree-panel-folder,
.tree-panel-folder--modal {
  position: relative;
}

.tree-panel-folder a,
.tree-panel-folder--modal a {
  color: #2e383a;
}

.tree-panel-folder__title--active,
.tree-panel-folder--modal__title--active {
  background-color: #EAEEF0;
}
.tree-panel-folder__title--active .tree-panel-folder__root,
.tree-panel-folder__title--active .tree-panel-folder_title__name--text,
.tree-panel-folder__title--active .tree-panel-folder__caret,
.tree-panel-folder__title--active .tree-panel-folder__caret--root,
.tree-panel-folder__title--active .tree-panel-folder_title__icon,
.tree-panel-folder--modal__title--active .tree-panel-folder__root,
.tree-panel-folder--modal__title--active .tree-panel-folder_title__name--text,
.tree-panel-folder--modal__title--active .tree-panel-folder__caret,
.tree-panel-folder--modal__title--active .tree-panel-folder__caret--root,
.tree-panel-folder--modal__title--active .tree-panel-folder_title__icon {
  color: #3d9487;
}

.tree-panel-folder__title--active,
.tree-panel-folder__title:hover,
.tree-panel-folder--modal__title:hover {
  background-color: #EAEEF0;
}
.tree-panel-folder__title--active .tree-panel-folder__root,
.tree-panel-folder__title--active .tree-panel-folder_title__name--text,
.tree-panel-folder__title--active .tree-panel-folder__caret,
.tree-panel-folder__title--active .tree-panel-folder__caret--root,
.tree-panel-folder__title--active .tree-panel-folder_title__icon,
.tree-panel-folder__title:hover .tree-panel-folder__root,
.tree-panel-folder__title:hover .tree-panel-folder_title__name--text,
.tree-panel-folder__title:hover .tree-panel-folder__caret,
.tree-panel-folder__title:hover .tree-panel-folder__caret--root,
.tree-panel-folder__title:hover .tree-panel-folder_title__icon,
.tree-panel-folder--modal__title:hover .tree-panel-folder__root,
.tree-panel-folder--modal__title:hover .tree-panel-folder_title__name--text,
.tree-panel-folder--modal__title:hover .tree-panel-folder__caret,
.tree-panel-folder--modal__title:hover .tree-panel-folder__caret--root,
.tree-panel-folder--modal__title:hover .tree-panel-folder_title__icon {
  color: #3d9487;
}

.tree-panel-folder__title,
.tree-panel-folder--modal__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0px;
}

.tree-panel-folder_title__name,
.tree-panel-folder--modal_title__name {
  display: flex;
  width: 100%;
}

.tree-panel-folder_title__name--link {
  display: flex;
}

.tree-panel-folder_title__name--text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.tree-panel-folder_breadcrumbs--container {
  height: 24px;
}
.tree-panel-folder_breadcrumbs--container .change-folder-path {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  color: #205A5A;
}
.tree-panel-folder_breadcrumbs--container .change-folder-path .tree-panel-folder_breadcrumbs--modal {
  font-weight: normal;
  color: #4C6569;
}
.tree-panel-folder_breadcrumbs--container .sl-icon-chevron-right,
.tree-panel-folder_breadcrumbs--container .sl-icon-folder {
  line-height: 24px;
  max-height: 24px;
  margin-top: 0;
}

.tree-panel-folder__breadcrumbs,
.tree-panel-folder_breadcrumbs--modal {
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-wrap: nowrap;
  overflow-x: clip;
}
.tree-panel-folder__breadcrumbs .truncate strong,
.tree-panel-folder_breadcrumbs--modal .truncate strong {
  color: #205A5A;
  font-weight: 600;
}

.tree-panel-folder__breadcrumbs_name--link {
  color: #4C6569;
}

.tree-panel-folder__breadcrumbs_name--link:hover {
  color: #3D9487;
}

.tree-panel-folder_breadcrumbs--more {
  position: relative;
  display: none;
  align-items: center;
}

.tree-panel-folder_breadcrumbs__more--container {
  display: flex;
  align-items: center;
}

.tree-panel-folder_breadcrumbs--link {
  color: #8799aa !important;
}

.tree-panel-folder__caret_container {
  display: flex;
  align-items: center;
  min-width: 13px;
  cursor: pointer;
}

.tree-panel-folder__caret,
.tree-panel-folder__caret--root {
  color: #2e383a;
  font-size: 20px;
  transform: rotate(-90deg);
}

.tree-panel-folder__caret--breadcrumbs {
  color: #4C6569;
  font-size: 16px;
}

.tree-panel-folder__caret--root {
  transform: rotate(0deg) !important;
}

.tree-panel-folder__caret--active {
  transform: rotate(0deg);
}

.tree-panel-folder__caret,
.tree-panel-folder__caret--root,
.tree-panel-folder--modal__caret,
.tree-panel-folder--modal__caret--root,
.tree-panel-folder_title__icon,
.tree-panel-folder--modal_title__icon {
  margin-right: 4px;
}

.tree-panel-folder_title__icon,
.tree-panel-folder--modal_title__icon {
  margin-right: 8px;
}

.tree-panel-folder_title__icon {
  font-size: 20px;
  color: #2e383a;
}

.tree-panel-folder_title__menu {
  display: none;
  padding-right: 24px;
  cursor: pointer;
  align-items: center;
  font-size: 20px;
}

.tree-panel-folder__title:hover .tree-panel-folder_title__menu {
  display: flex;
}

.tree-panel-folder_title__menu--active {
  display: block;
}

.tree-panel-folder__root {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 24px;
}

.toggle-menu-tree,
.tree-breadcrumb-menu {
  position: absolute;
  max-width: 195px;
  background-color: white;
  z-index: 1000;
  font-size: 13px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 12px;
  right: -90px;
}

.toggle-menu-tree {
  width: 200px;
  opacity: 0;
  transform: translateY(-5px) translateX(-112px);
  transition: all 0.3s ease;
  visibility: hidden;
  display: block !important;
}

.toggle-menu-tree--arrow {
  border-style: solid;
  border-width: 0 10px 9px 10px;
  border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #d5dde5 rgba(204, 204, 204, 0);
  height: 0;
  margin-top: 0;
  opacity: 1;
  position: absolute;
  right: 6px;
  top: -9%;
  width: 0;
  z-index: 10;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.toggle-menu-tree--arrow:after {
  content: " ";
  border-style: solid;
  border-width: 0 10px 8px 8px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
  position: absolute;
  right: -9px;
  top: 0;
  width: 0;
  height: 0;
  margin-top: 1px;
}

.tree-panel-animate-menu-in {
  opacity: 1;
  transform: translateY(0px) translateX(-112px);
  transition: all 0.3s ease;
  visibility: visible;
}

.tree-panel-animate-menu-out {
  opacity: 0;
  transform: translateY(-5px) translateX(-112px);
  transition: all 0.3s ease;
}

.tree-breadcrumb-menu {
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  right: -58px;
  top: 30px;
  max-width: 121px;
}

.item-tree-option {
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.item-tree-option i {
  font-size: 20px;
}

.item-tree-option,
.item-breadcrumb-option {
  width: 100%;
  padding: 10px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-tree-option:hover {
  background-color: #F8F8F8;
  color: #3d9487;
}

.item-tree-option span {
  margin-right: 5px;
  color: rgba(52, 73, 94, 0.75);
}

.tree-breadcrumb-menu--arrow {
  border-style: solid;
  border-width: 0 10px 9px 10px;
  border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #d5dde5 rgba(204, 204, 204, 0);
  height: 0;
  margin-top: 0;
  opacity: 1;
  position: absolute;
  left: 21px;
  top: -9px;
  width: 0;
  z-index: 10;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.tree-breadcrumb-menu--arrow:after {
  content: " ";
  border-style: solid;
  border-width: 0 10px 8px 8px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0);
  position: absolute;
  left: -9px;
  top: 0.5px;
  width: 0;
  height: 0;
  margin-top: 1px;
}

.move-folder-footer {
  display: flex;
  justify-content: space-between;
}

.btn-create-folder {
  background: transparent;
}
.btn-create-folder:hover {
  color: #3D9487;
}

.tree-panel-modal--btn {
  min-width: 114px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.tree-panel-folder_breadcrumbs--icon {
  color: #4C6569;
  margin-right: 16px;
  width: 24px;
}
.tree-panel-folder_breadcrumbs--icon:before {
  font-size: 24px;
}

.tree-panel-folder_breadcrumbs--container {
  display: flex;
  align-items: center;
}

#error_creating_folder,
#error_moving_items {
  font-size: 13px;
}

.itemValidation_validateOk .itemValidationButton, .itemValidation_validateOk .itemValidationButton:active, .itemValidation_validateOk .itemValidationButton:focus {
  background: #E6FAE8;
}
.itemValidation_validateOk .itemValidationButton i, .itemValidation_validateOk .itemValidationButton:active i, .itemValidation_validateOk .itemValidationButton:focus i {
  color: #0BA815;
}
.itemValidation_validateOk .itemValidationButton:hover, .itemValidation_validateOk .itemValidationButton:active:hover, .itemValidation_validateOk .itemValidationButton:focus:hover {
  background: #BFF5C5;
}
.itemValidation_validateOk input, .itemValidation_validateOk textarea {
  border-color: #0BA815;
}

.itemValidation_validateError .itemValidationButton {
  background: #D2322C;
}
.itemValidation_validateError input, .itemValidation_validateError textarea {
  border-color: #D2322C;
}
.itemValidation_validateError .itemValidationButton, .itemValidation_validateError .itemValidationButton:active, .itemValidation_validateError .itemValidationButton:focus {
  background: #FDEDED;
  width: 24px;
  height: 24px;
  font-size: 16px;
  padding: 0;
}
.itemValidation_validateError .itemValidationButton:after, .itemValidation_validateError .itemValidationButton:active:after, .itemValidation_validateError .itemValidationButton:focus:after {
  content: "\e92d";
  font-family: "sally-icon";
  color: #D2322C;
  font-weight: normal;
}
.itemValidation_validateError .itemValidationButton:hover, .itemValidation_validateError .itemValidationButton:active:hover, .itemValidation_validateError .itemValidationButton:focus:hover {
  background: #FAD7D6;
}
.itemValidation_validateError .itemValidationButton i, .itemValidation_validateError .itemValidationButton:active i, .itemValidation_validateError .itemValidationButton:focus i {
  display: none;
}

.itemValidationTooltip.top .tooltip-arrow {
  border-top-color: #fff;
}
.itemValidationTooltip .tooltip-inner {
  max-width: 180px;
  width: 180px;
  background-color: #fff;
  text-align: left;
  padding: 24px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
}
.itemValidationTooltip .tooltip-inner span {
  color: #4C6569;
  font-size: 12px;
}
.itemValidationTooltip .tooltip-inner span.validateOk {
  color: #0BA815;
}
.itemValidationTooltip .tooltip-inner span.validateError {
  color: #D2322C;
}
.itemValidationTooltip .tooltip-inner span strong {
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.box_list.md_vw .itemValidationAlertList {
  position: absolute;
  left: auto;
  right: 28px;
  top: 59px;
  background: #FDEDED;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  color: #D2322C;
  text-align: center;
  line-height: 1.5;
}
.box_list.md_vw .itemValidationAlertList i {
  font-size: 16px;
}
.box_list.md_ls .itemValidationAlertList {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background: #FDEDED;
  color: #D2322C;
  text-align: center;
  padding: 3px;
  line-height: 1.231;
}
.box_list.md_ls .itemValidationAlertList i {
  font-size: 16px;
}

/* Remove arrows on number inputs */
.form-validation {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.form-validation input::-webkit-outer-spin-button,
.form-validation input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.form-validation input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/*

	## Cuerpo derecho

*/
#box_content {
  z-index: 1;
  position: absolute;
  left: 224px;
  right: 0;
  top: 0;
  padding-top: 80px;
  bottom: 0;
  /*min-width: 1150px;*/
}

#box_content.pop_mode {
  top: 0;
}

.paragrh {
  position: relative;
  width: 100%;
  display: block;
  padding: 0px 0px 40px 0px;
}

.paragrh.box_pagination {
  padding: 0px 44px;
}

.paragrh.box_translations .form {
  padding: 24px 44px 0 44px;
}

.alert-container-blank .paragrh.box_msg_action {
  padding: 40px 0 0 0;
}

.paragrh.ptop {
  padding-top: 0;
}

.paragrh.pbtm {
  padding-bottom: 0;
}

.paragrh.xpdn {
  padding: 0 0 20px;
}

.paragrh.left {
  float: left;
}

.paragrh.cntr {
  float: auto;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.paragrh.mrg_tp {
  margin-top: 100px;
}

.col-auto {
  width: auto;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 1000px) {
  .paragrh.mrg_tp {
    margin-top: 50px;
  }
}
.paragrh .inprg {
  padding: 0;
}

.paragrh > h1:first-child,
.paragrh > h2:first-child,
.paragrh > h3:first-child,
.paragrh > h4:first-child,
.paragrh > h5:first-child {
  margin: 0 0 30px 0;
}

#box_content .box_list .paragrh {
  padding: 0 0 20px;
}

.paragrh .img_back {
  display: block;
  margin-top: 54px;
  min-height: 100px;
  text-align: center;
}

.paragrh.msg_empty h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-top: 20px;
}
.paragrh.msg_empty p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
  margin-top: 20px;
}

.paragrh .box_btns {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.paragrh .box_btns a.btn {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
}

/*.paragrh.is_srch .img_back img { -webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.7; filter: alpha(opacity=70); }*/
.paragrh.is_lttl .img_back img {
  background: rgba(0, 0, 0, 0.04);
  padding: 20px;
  border-radius: 30px;
  height: 125px;
  width: auto;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.paragrh.is_lttl .img_back {
  max-height: 130px;
  margin-top: 10px;
}

.modal-body .paragrh.is_lttl .img_back {
  max-height: none;
  margin-top: 72px;
  height: 176px !important;
}
.modal-body .paragrh.is_lttl .img_back img {
  background: transparent;
  padding: 12px;
  border-radius: 30px;
  height: 176px !important;
  width: auto;
}

@media (min-height: 1000px) and (min-width: 1400px) {
  #box_content > .paragrh.msg_empty:not(.is_srch) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    margin-left: -350px;
    height: 400px;
    margin-top: -200px;
  }
  #box_content > .paragrh.msg_empty:not(.is_srch) .img_back {
    margin-top: 0;
  }
}
.box_head_sec.inprg {
  padding: 0;
}

.sl-payment-paragrh {
  padding: 24px 44px 40px 44px;
}

.sl-payment-noconsumption {
  padding: 30px 44px 40px 44px;
}
.sl-payment-noconsumption h4 {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
}
.sl-payment-noconsumption h6 {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
}

button.sl-link-details {
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  color: #4C6569;
}
button.sl-link-details:hover {
  color: #3D9487;
}

.btn.sl-btn-invoice {
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  padding: 8px 16px;
}

/*

	Capsal seccions
*/
.box_head_sec,
.box_head_subsec,
.box_search,
.num_channels {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 44px 0px 44px;
}

.num_channels {
  padding-top: 20px;
}

.head_channels h1 {
  padding-bottom: 0;
}

.head_channels .box_msg_action {
  padding-bottom: 0;
}

.num_elements.num_channels h3.sl-content-light {
  color: #7B9BA1;
}
.num_elements.num_channels h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #262C2A;
  margin-top: 0px;
}
.num_elements.num_channels h2.sl-content-default {
  color: #2E383A;
}

.box_head_sec {
  z-index: 3;
  padding-top: 40px;
}

.box_report .box_head_sec {
  padding-top: 44px;
}

.box_head_white {
  background-color: white;
}

.box_head_subsec,
.box_search {
  z-index: 2;
}

.box_head_sec.pad_btm,
.box_head_subsec.pad_btm {
  padding-bottom: 20px;
  padding-top: 0;
}

.box_head_subsec.cmpct {
  padding-top: 0;
}

.box_head_sec h1 {
  font-size: 29px;
  line-height: 36px;
  font-weight: 700;
  color: #2E383A;
}

.box_head_sec h1.head_sec_subtitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #2E383A;
  margin-top: 12px;
}

.box_head_sec h2,
.box_head_subsec h2,
h2 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #7B9BA1;
  margin-top: 12px;
}

.box_head_sec h3,
.box_head_subsec h3 {
  margin: 0;
  font-size: 26px;
  line-height: 40px;
  color: #8799ab;
}

.box_head_sec h1.up_tbs {
  padding-bottom: 26px;
}

.box_head_sec h6 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #7B9BA1;
}

.box_head_sec h1 strong, .box_head_subsec h1 strong,
.box_head_sec h2 strong, .box_head_subsec h2 strong,
.box_head_sec h3 strong, .box_head_subsec h3 strong {
  display: inline-block;
}

.box_head_sec h1 .rgt_btn, .box_head_subsec h1 .rgt_btn {
  position: relative;
  overflow: hidden;
  float: right;
}

.box_dashb .box_info h1 {
  margin: 0;
  font-size: 35px;
  line-height: 45px;
}

.box_dashb .box_info h4,
.box_dashb .box_info h5,
.box_dashb .box_info h6 {
  color: #8799ab;
  white-space: nowrap;
}

.box_head_sec h1 a:hover strong, .box_head_sec h1 a:focus strong,
.box_head_sec h2 a:hover strong, .box_head_sec h2 a:focus strong,
.box_head_sec h3 a:hover strong, .box_head_sec h3 a:focus strong,
.box_head_subsec h1 a:hover strong, .box_head_subsec h1 a:focus strong,
.box_head_subsec h2 a:hover strong, .box_head_subsec h2 a:focus strong,
.box_head_subsec h3 a:hover strong, .box_head_subsec h3 a:focus strong,
.box_dashb .box_info h4 a:hover strong, .box_dashb .box_info h4 a:focus strong,
.box_dashb .box_info h5 a:hover strong, .box_dashb .box_info h5 a:focus strong,
.box_dashb .box_info h6 a:hover strong, .box_dashb .box_info h6 a:focus strong {
  color: #387B77;
}

.box_head_sec h1 .text-primary, .box_head_sec h1 .text-primary strong,
.box_head_sec h2 .text-primary, .box_head_sec h2 .text-primary strong,
.box_head_sec h3 .text-primary, .box_head_sec h3 .text-primary strong,
.box_head_subsec h1 .text-primary, .box_head_subsec h1 .text-primary strong,
.box_head_subsec h2 .text-primary, .box_head_subsec h2 .text-primary strong,
.box_head_subsec h3 .text-primary, .box_head_subsec h3 .text-primary strong,
.box_dashb .box_info h4 .text-primary, .box_dashb .box_info h4 .text-primary strong,
.box_dashb .box_info h5 .text-primary, .box_dashb .box_info h5 .text-primary strong,
.box_dashb .box_info h6 .text-primary, .box_dashb .box_info h6 .text-primary strong {
  color: #18C925;
}

.box_head_sec h1 .text-warning, .box_head_sec h1 .text-warning strong,
.box_head_sec h2 .text-warning, .box_head_sec h2 .text-warning strong,
.box_head_sec h3 .text-warning, .box_head_sec h3 .text-warning strong,
.box_head_subsec h1 .text-warning, .box_head_subsec h1 .text-warning strong,
.box_head_subsec h2 .text-warning, .box_head_subsec h2 .text-warning strong,
.box_head_subsec h3 .text-warning, .box_head_subsec h3 .text-warning strong,
.box_dashb .box_info h4 .text-warning, .box_dashb .box_info h4 .text-warning strong,
.box_dashb .box_info h5 .text-warning, .box_dashb .box_info h5 .text-warning strong,
.box_dashb .box_info h6 .text-warning, .box_dashb .box_info h6 .text-warning strong {
  color: #D57300;
}

.box_head_sec h1 .text-danger, .box_head_sec h1 .text-danger strong,
.box_head_sec h2 .text-danger, .box_head_sec h2 .text-danger strong,
.box_head_sec h3 .text-danger, .box_head_sec h3 .text-danger strong,
.box_head_subsec h1 .text-danger, .box_head_subsec h1 .text-danger strong,
.box_head_subsec h2 .text-danger, .box_head_subsec h2 .text-danger strong,
.box_head_subsec h3 .text-danger, .box_head_subsec h3 .text-danger strong,
.box_dashb .box_info h4 .text-danger, .box_dashb .box_info h4 .text-danger strong,
.box_dashb .box_info h5 .text-danger, .box_dashb .box_info h5 .text-danger strong,
.box_dashb .box_info h6 .text-danger, .box_dashb .box_info h6 .text-danger strong {
  color: #D2322C;
}

.box_head_sec.cmpct h2.lft {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #2E383A;
  margin-top: 0;
}

.box_head_sec.cmpct h3.lft,
.box_head_subsec.cmpct h3.lft {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #4C6569;
}

.box_head_sec.cmpct select.form-control {
  width: -webkit-fill-available;
}

.box_head_sec h1 .btn-group,
.box_head_sec h2 .btn-group {
  vertical-align: baseline;
}

.box_head_sec h1.lft, .box_head_subsec h1.lft,
.box_head_sec h2.lft, .box_head_subsec h2.lft,
.box_head_sec h3.lft, .box_head_subsec h3.lft {
  float: left;
  margin-right: 10px;
}

.box_head_sec h1 .btn-group.select button.btn_hide.dropdown-toggle {
  display: flex;
  border-radius: 4px;
  padding: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
}
.box_head_sec h1 .btn-group.select button.btn_hide.dropdown-toggle .title {
  padding: 0;
  color: #2E383A;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  height: 20px;
}
.box_head_sec h1 .btn-group.select button.btn_hide.dropdown-toggle:hover {
  background-color: #EAEEF0;
}

.box_head_sec h1 .btn-group.select .dropdown-arrow {
  top: 35px;
  right: 0;
}

.box_head_sec h1 .btn-group.select .dropdown-menu {
  top: 36px;
}

.box_head_sec h1 .btn-group.select .dropdown-menu.right {
  left: auto;
  right: -20px;
}

.box_head_sec h1 .sublev {
  vertical-align: bottom;
}

.box_head_sec h1 .sublev {
  margin-top: 10px;
}
.box_head_sec h1 .sublev .htitl {
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #2E383A;
}

.box_head_sec h1 .sublev .htitl .icon {
  vertical-align: middle;
  font-size: 20px;
  line-height: 20px;
  color: #2E383A;
  padding-right: 8px;
}

#box_form_content #bx_slct_prd_frmt {
  background-color: #FFFFFF;
}

#bx_slct_prd_frmt .box_head_sec h1 {
  display: flex;
  flex-wrap: wrap;
}
#bx_slct_prd_frmt .box_head_sec h1 .btn-group.select.drop_right {
  margin-left: 8px;
}
#bx_slct_prd_frmt .box_head_sec h1 span.sublev {
  width: 100%;
}

.box_head_sec .dropdown-menu.dropdown-large li a i,
.box_list_ops .dropdown-menu.dropdown-large li a i,
.box_list_ops .btn-group.select .dropdown-menu li a i {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
}

.box_search.box_list_ops .dropdown-menu.adv-srch-dropdown.dropdown-large li a:hover i {
  color: #3D9487;
}
.box_search.box_list_ops .dropdown-menu.adv-srch-dropdown.dropdown-large li a i {
  font-size: 20px;
}

#box_content.box_report .box_head_sec.box_report_head {
  background-color: #FFFFFF;
}
#box_content.box_report .box_head_sec.box_report_head .rgt_btn button.btn {
  padding: 10px;
  background-color: #FFFFFF;
  color: #2E383A;
}
#box_content.box_report .box_head_sec.box_report_head .rgt_btn button.btn:hover {
  background-color: #EAEEF0;
}
#box_content.box_report .box_head_sec.box_report_head .rgt_btn button.btn i {
  font-size: 20px;
  line-height: 20px;
}
#box_content.box_report .box_head_sec.box_report_head .crumbs {
  padding: 0;
}
#box_content.box_report .box_head_sec.box_report_head .crumbs ul.nav {
  border: none;
  margin: 30px 0 0px 0;
}

.box_head_sec.sl_title_info {
  background-color: #FFFFFF;
  padding-bottom: 32px;
}
.box_head_sec.sl_title_info h2 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #7B9BA1;
  margin-top: 12px;
}

.crumbs.sl_title_crumbs {
  padding-top: 0px;
}
.crumbs.sl_title_crumbs ul {
  padding-top: 0px;
}

.paragrh .form .mgbform h4.title.sl-payment-information-title {
  position: absolute;
  z-index: 999;
  top: -133px;
  padding: 0px;
}

.sl-box-head-payment .sl-payment-crumbs {
  padding-top: 110px;
}

.box_head_sec h2.num_elements {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #7B9BA1;
  margin-top: 12px;
}
.box_head_sec h2.num_elements i a {
  color: #387B77;
}
.box_head_sec h2.num_elements i a:hover {
  color: #3D9487;
}
.box_head_sec h2.num_elements i a.sl-with-errors {
  color: #D2322C;
  font-weight: 600;
  font-size: 14px;
  text-transform: lowercase;
}

.sl-with-errors-digit {
  width: 16px;
  height: 16px;
  background-color: #ED352E;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: 600;
  margin: 0px 8px 0px 12px;
}

.box_search.box_list_ops {
  padding-top: 40px;
}

#head, #logo {
  height: 80px;
}

#head {
  z-index: 600;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #D5DFE1;
  background: white;
}

#logo {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 224px;
  background: #0D423E;
}
#logo a img {
  width: auto;
  height: 26px;
}

#logo a {
  overflow: hidden;
  display: block;
  padding: 24px 0 0 0;
  margin-left: 24px;
}

#logo img {
  width: 130px;
}

#logo .lg_icon,
.minus_left #logo .lg_all {
  display: none;
}

#logo .lg_all,
.minus_left #logo .lg_icon {
  display: block;
}

.minus_left #logo .lg_icon {
  margin: 0 auto;
}

#hd_title {
  position: fixed;
  left: 220px;
  top: 18px;
  font-size: 16px;
  line-height: 16px;
  color: #34495e;
  text-transform: uppercase;
}

#hd_title i.icon {
  vertical-align: middle;
  font-style: normal;
  color: #7c95ae;
}

#hd_title i.min {
  font-size: 60%;
  line-height: 60%;
}

#hd_title a,
#hd_title a i.icon {
  color: #4eb38a;
  transition: all 0.25s ease 0s;
}

#hd_title a:hover,
#hd_title a:hover i.icon {
  color: #0b1826;
  transition: all 0.25s ease 0s;
}

#hd_title .title_sel {
  display: inline-block;
  width: 20px;
  margin: 0 5px 0 0;
  vertical-align: -5px;
}

#hd_title .title_sel button {
  width: auto;
  border-radius: 3px;
  padding: 2px 6px;
  background: #fff;
  transition: all 0.2s ease 0s;
}

#hd_title .title_sel button i {
  display: inline-block;
  padding: 0;
  font-size: 112%;
  line-height: 110%;
}

#hd_title .title_sel button i:before {
  color: #4eb38a;
  transition: all 0.2s ease 0s;
}

#hd_title .title_sel button:hover {
  background: #54c195;
  transition: all 0.2s ease 0s;
}

#hd_title .title_sel button:hover i:before {
  color: #fff;
  transition: all 0.2s ease 0s;
}

#hd_title .dropdown-arrow {
  right: 0;
}

#hd_title .dropdown-menu {
  margin-left: -10px;
}

#hd_title .dropdown-menu a {
  color: #34495e;
}

#hd_options {
  height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  left: 264px;
  font-size: 14px;
  line-height: 14px;
}

#hd_options .btn_op {
  padding: 12px !important;
  border: 1px solid #D5DFE1;
  width: 40px;
}
#hd_options .btn_op i {
  margin: 0 !important;
}

#hd_options .btn_op.btn-back {
  border: 1px solid transparent;
}

#hd_options .btn_op:nth-child(2), #hd_options .btn_op:nth-child(2):hover,
#hd_options .btn_op:nth-child(3), #hd_options .btn_op:nth-child(3):hover {
  border-left: none !important;
}

#hd_options .btn_op.btn_default {
  background-color: #EAEEF0 !important;
}
#hd_options .btn_op.btn_default:hover {
  background-color: #EAEEF0;
  color: #2E383A;
}

#hd_options .btn_op:disabled {
  color: #ABC0C4;
}

#hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle {
  font-size: 14px;
  line-height: 24px;
  padding: 0 5px;
  height: 40px;
  background-color: white;
  color: #2E383A;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  outline: 0;
  transition: all 0.3s ease;
}
#hd_options .btn-group.select button.dropdown-toggle i, .btn-group.slc_currency button.dropdown-toggle i {
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  padding: 0;
}
#hd_options .btn-group.select button.dropdown-toggle i + span, .btn-group.slc_currency button.dropdown-toggle i + span {
  margin-left: 8px;
}
#hd_options .btn-group.select button.dropdown-toggle span i, .btn-group.slc_currency button.dropdown-toggle span i {
  margin-right: 8px;
}

#hd_options .btn-group.select button.dropdown-toggle:not(.sl-button-outline-medium),
.btn-group.slc_currency button.dropdown-toggle:not(.sl-button-outline-medium) {
  border: 1px solid #D5DFE1;
}

#hd_options .btn-group.select button.dropdown-toggle {
  padding: 8px 20px;
}

#hd_options .btn-group.select button.dropdown-toggle.btn-lang,
#hd_options .btn-group.select button.dropdown-toggle.btn-filter {
  border: 1px solid transparent;
}
#hd_options .btn-group.select button.dropdown-toggle.btn-lang span.filter-option,
#hd_options .btn-group.select button.dropdown-toggle.btn-filter span.filter-option {
  padding-right: 0 !important;
}

#hd_options .btn-group.select button.dropdown-toggle:hover,
.btn-group.slc_currency button.dropdown-toggle:hover {
  background-color: #EAEEF0;
}

#hd_options .btn-group.select button .icon, .btn-group.slc_currency button.dropdown-toggle .icon {
  top: 6px;
}

#hd_options label {
  margin: 0;
}

#hd_options .btn-box {
  background: white;
}

#hd_options select {
  height: 36px;
  padding: 6px 8px;
}

#slct_stat .st_V:before {
  content: "\e931";
  font-family: "sally-icon";
}

#slct_stat .st_I:before {
  content: "\e930";
  font-family: "sally-icon";
}

#slct_stat .st_D:before {
  content: "\e92b";
  font-family: "sally-icon";
}

#slct_link .st_y:before {
  content: "\e949";
  font-family: "sally-icon";
}

#slct_link .st_n:before {
  content: "\e970";
  font-family: "sally-icon";
}

#slct_link .st_E:before {
  content: "\e92d";
  font-family: "sally-icon";
}

#slct_link .st_Ey:before {
  content: "\e92d";
  font-family: "sally-icon";
}

.btn-group.slc_currency {
  float: right;
  margin: 0 0 0 20px;
}

.btn-group.slc_currency .dropdown-menu {
  left: auto;
  right: 0;
}

#head .btn_rgt {
  height: 40px;
  display: block;
  align-items: center;
  position: relative;
  float: right;
  margin: 16px 20px 0px 0px;
}

#head .btn_rgt.ehide {
  display: none;
}

#head .btn_rgt.nxt {
  margin-right: 5px;
}

#head .btn_rgt button {
  top: 4px;
  width: 200px;
  height: 40px;
  color: #2E383A;
  font-size: 20px;
  line-height: 20px;
}

#head .btn_rgt span {
  top: 6px;
}

#head .btn_rgt span.fui-user {
  top: -5px;
  left: -12px;
  color: #bbb;
  float: left;
  font-size: 14px;
}

#head .btn_rgt .caret {
  margin-top: 6px;
  right: 10px;
  border-bottom-color: #ccc;
  border-top-color: #ccc;
}

#head .btn_rgt button:hover .caret {
  margin-top: 4px;
}

#head .btn_rgt button {
  border-radius: 4px;
  float: right;
  width: auto;
  padding: 6px 10px;
  background: white;
}

#notifications.btn_rgt button {
  padding: 6px 5px;
}

#head .btn_rgt button .fui-user {
  top: -2px;
  left: -6px;
  font-size: 16px;
}

#head .btn_rgt button .bell,
#head .btn_rgt button .gly-icon {
  position: relative;
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  top: -2px;
  left: -1px;
  right: auto;
  bottom: auto;
  float: left;
}

#head .btn_rgt button .npdg {
  padding-right: 0;
}

#head .btn_rgt button .bell {
  padding-right: 0;
  left: 0;
  top: 0;
}

#head #userdat.btn_rgt button i {
  font-size: 20px;
}

#head .btn_rgt button.smpl {
  padding: 6px 5px;
  font-size: 23px;
  margin-top: 6px;
}

#head .btn_rgt button.smpl .filter-option {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  display: inline-block;
  margin: 2px 0 0 0;
  padding: 0;
  max-width: 50px;
  font-size: 100%;
  line-height: 100%;
}

#head .btn_rgt button .filter-option i {
  display: none;
}

#head .btn_rgt button:hover {
  background: #F8F8F8;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
}

#head .btn_rgt #notif_button:hover {
  background: transparent;
}

#head .btn_rgt button:hover .caret {
  position: absolute;
  border-bottom-color: #555;
  border-top-color: #555;
}

#head .btn_rgt .dropdown-arrow {
  right: 7px;
}

#head .dropdown-arrow {
  z-index: 5;
  margin-top: 2px;
}

#head .open > .dropdown-arrow {
  margin-top: 7px;
}

#head .dropdown-menu {
  z-index: 1;
  overflow: hidden;
  min-width: 100%;
}

#head .dropdown-menu.dropdown-large {
  overflow: auto;
}

#head .btn_rgt .dropdown-menu {
  z-index: 1;
  left: auto;
  right: 0;
  min-width: 250px;
}

#head .open > .dropdown-menu {
  margin-top: 8px !important;
}

#slct_stat .dropdown-menu li a i {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #2E383A;
}

#slct_stat .dropdown-menu li a:hover i {
  color: #3D9487;
}

@media (max-width: 1200px) {
  #head .btn-group .btn.dropdown-toggle .icon {
    padding: 0;
  }
}
#head .box_msgs {
  position: relative;
  float: right;
  margin: 25px 5px 0 0;
}

#head .box_min_usr button, #head .box_msgs button {
  width: 30px;
  height: 30px;
  max-height: 30px;
  vertical-align: 2px;
  background: #ABC0C4;
  border: 0;
  border-radius: 50%;
  padding: 0;
  color: white;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
}

#head .box_min_usr_cont .box_min_usr {
  margin-right: -2px;
  box-shadow: 0 0 0px 2px #fff;
  border-radius: 100%;
}
#head .box_min_usr_cont .box_min_usr button {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  width: 32px;
  height: 32px;
  max-height: 32px;
}
#head .box_min_usr_cont .box_min_usr:first-child {
  margin-right: 18px;
}
#head .box_min_usr_cont .box_min_usr:nth-child(1) {
  z-index: 49;
}
#head .box_min_usr_cont .box_min_usr:nth-child(2) {
  z-index: 48;
}
#head .box_min_usr_cont .box_min_usr:nth-child(3) {
  z-index: 47;
}
#head .box_min_usr_cont .box_min_usr:nth-child(4) {
  z-index: 46;
}
#head .box_min_usr_cont .box_min_usr:nth-child(5) {
  z-index: 45;
}
#head .box_min_usr_cont .box_min_usr:nth-child(6) {
  z-index: 44;
}
#head .box_min_usr_cont .box_min_usr:nth-child(7) {
  z-index: 43;
}
#head .box_min_usr_cont .box_min_usr:nth-child(8) {
  z-index: 42;
}
#head .box_min_usr_cont .box_min_usr:nth-child(9) {
  z-index: 41;
}
#head .box_min_usr_cont .box_min_usr:nth-child(10) {
  z-index: 40;
}

#head .box_msgs button {
  background: transparent;
  color: #2E383A;
  font-size: 20px;
  line-height: 15px;
}

#head .box_msgs button#Hubspot_slyr {
  font-size: 24px;
  line-height: 16px;
  margin-top: 2px;
}

#head #box_lst_usr button {
  background: #ddd;
  border-color: #ddd;
  color: #777;
}

#head #box_lst_usr button .fui,
#head #box_lst_usr button .icon {
  color: #aaa;
}

#head #box_lst_usr button:hover {
  background: #e4e4e4;
}

#box_lst_usr .dropdown-menu li a:hover {
  background-color: #f9f9f9;
}

#box_lst_usr .dropdown-menu .icon {
  color: #bbb !important;
}

#box_lst_usr .dropdown-menu .in_sec .icon {
  color: #f1c40f !important;
}

#box_lst_usr .dropdown-menu li em {
  display: inline-block;
  margin-left: 25px;
  font-style: italic;
  color: #999;
}

#head #box_lst_usr button.in_sec,
#head .box_min_usr.in_sec button {
  background: #ABC0C4;
  border-color: #ABC0C4;
  color: #fff;
}

button.button_min_usr {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  max-height: 24px;
  background: #ABC0C4;
  border-color: #ABC0C4;
  color: #fff;
  border: 0;
  border-radius: 50%;
  margin-right: 5px;
  pointer-events: none;
}

#head #box_lst_usr button.in_sec .fui,
#head #box_lst_usr button.in_sec .icon,
#head .btn_rgt.shdw button.in_sec .icon {
  color: #fff;
}

#head #box_lst_usr button.in_sec:hover,
#head .box_min_usr.in_sec button:hover {
  background: #f4d313;
  border-color: #f4d313;
  background: #ABC0C4;
  border-color: #ABC0C4;
  color: #fff;
}

.tooltip-inner .icon.upr {
  font-size: 115%;
  color: rgba(255, 255, 255, 0.7);
  vertical-align: -10%;
}

#Intercom_slyr .glyphicons:before {
  margin-left: -3px;
  margin-top: 3px;
}

#Hubspot_slyr .glyphicons:before {
  margin-left: -3px;
  margin-top: 3px;
}

#head .btn_rgt.shdw button #notif_mark.active.icon {
  color: #2E383A;
}

#head .btn_rgt.shdw button #notif_mark.icon:after {
  content: "\a";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: flex;
  position: relative;
  background: transparent;
  border: 2px solid transparent;
  top: -24px;
  right: -14px;
}

#head .btn_rgt.shdw button #notif_mark.active.icon:after {
  background: #ED352E;
  border: 2px solid white;
}

#notifications .dropdown-menu {
  padding: 0;
}

#notifications .dropdown-menu .void {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#notifications .dropdown-menu .void p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
  margin: 22px 0 0 0;
  text-align: center;
}

#notifications .dropdown-menu,
#notifications.btn_rgt .dropdown-menu {
  min-width: 400px;
  max-width: 600px;
  max-height: 496px;
  overflow-y: auto;
  position: fixed;
  right: 35px;
  top: 60px;
}

#notifications.btn-group.select .dropdown-menu p {
  width: auto;
  color: #2E383A;
}

#notifications li .title {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
  margin-bottom: 4px;
}

#notifications li .date {
  display: block;
  font-size: 10px;
  line-height: 16px;
  font-style: normal;
  color: #4C6569;
}

#notifications li p {
  display: block;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  white-space: normal;
  font-weight: 400;
}

#notifications .notif_title {
  padding: 20px 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
}

#notifications li p a {
  display: inline-block;
  padding-top: 5px;
}

#notifications li > a {
  margin: 0;
  padding: 0;
  border: 0;
  flex-wrap: wrap;
  height: auto;
}

#notifications li > a.nolk {
  cursor: default;
}

#notifications .dropdown-menu {
  margin: 0;
  border-radius: 8px;
  position: relative;
}
#notifications .dropdown-menu .list-footer {
  margin-top: 16px;
  position: sticky;
  width: 100%;
  bottom: 0;
}

#notifications .dropdown-menu .notification_item {
  padding: 0px 16px;
  margin-bottom: 8px;
}
#notifications .dropdown-menu .notification_item a {
  white-space: normal;
}
#notifications .dropdown-menu .notification_item a strong {
  font-weight: 600;
}
#notifications .dropdown-menu .notification_item a .title strong {
  font-weight: 800;
}
#notifications .dropdown-menu .notification_item:first-child {
  padding-top: 16px;
}
#notifications .dropdown-menu .notification_item.new a:not(.btn) {
  background-color: #FCF8ED;
  border-radius: 8px;
}
#notifications .dropdown-menu .notification_item:hover a:not(.btn) {
  background-color: #F8F8F8;
  border-radius: 8px;
}

#notifications .dropdown-menu li > a > span.pull-left {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

#notifications li p .btn {
  width: auto;
  margin-top: 5px;
}

#notifications li p a.btn,
#box_notifs .txt a.btn {
  margin-top: 12px;
  height: 32px;
  padding: 8px 16px;
  border: none;
  font-size: 12px;
  color: #2E383A;
  border-radius: 4px;
}

#notifications li p a.btn-primary,
#box_notifs .txt a.btn-primary {
  color: #fff;
}

#notifications li p a.btn:hover,
#box_notifs .txt a.btn:hover {
  color: #2E383A;
}

#notifications li p a.btn-primary:hover,
#box_notifs .txt a.btn-primary:hover {
  color: #fff;
}

#notifications .tp_Alert {
  border-left: 5px solid rgba(241, 196, 15, 0.5);
}

#notifications .tp_Alert:hover {
  border-left-color: rgba(241, 196, 15, 0.7);
}

#notifications .tp_Error {
  border-left: 5px solid rgba(231, 76, 60, 0.5);
}

#notifications .tp_Error:hover {
  border-left-color: rgba(231, 76, 69, 0.7);
}

#notifications .tp_Alert .title,
#notifications .tp_Alert .date,
#notifications .tp_Alert p,
#notifications .tp_Error .title,
#notifications .tp_Error .date,
#notifications .tp_Error p {
  padding-left: 10px;
}

#notifications .list-footer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8F8F8;
}

#notifications .list-footer a span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #000000;
}

#hd_options .btn_op {
  display: flex;
  align-items: center;
  font-weight: 600;
}
#hd_options .btn_op .icon {
  font-size: 20px;
  margin-right: 4px;
}

#hd_options .btn_op,
#head .elm_qlty,
#head .elm_info {
  height: 40px;
}

#hd_options a.btn.btn_op.btn-warning.url_ldg,
#hd_options .btn-trial {
  background-color: #FBF2D0;
  color: #5A4B35 !important;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  padding: 8px 12px;
  width: fit-content;
  border: none;
  opacity: 1;
}
#hd_options a.btn.btn_op.btn-warning.url_ldg strong,
#hd_options .btn-trial strong {
  margin: 0 4px 0 4px;
}

#hd_options .bx_left .btn_op .icon.alone {
  font-size: 16px;
  line-height: 16px;
}

div#actions.md_highlight button.btn.dropdown-toggle {
  background-color: #387B77;
  color: #ffffff;
}
div#actions.md_highlight button.btn.dropdown-toggle:hover {
  background-color: #3D9487;
}

#hd_options .bx_left.chk_elm_all {
  margin-right: 20px;
  margin-left: 12px;
}
#hd_options .bx_left.chk_elm_all label {
  line-height: 22px;
}

#hd_options #actions button.btn.dropdown-toggle span.filter-option.pull-left.title {
  padding: 0px 10px 0px 0px;
  font-weight: 600;
}

span.dropdown_language {
  font-weight: 400;
}

@media (min-width: 1000px) {
  #hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle {
    padding: 8px 12px;
    height: 42px;
  }
  #hd_options .btn-group.select button.dropdown-toggle {
    padding: 8px 20px;
  }
  #hd_options .btn_op,
  #head .elm_qlty,
  #head .elm_info {
    min-height: unset;
    max-height: unset;
    height: 40px;
  }
}
.sl-payment-title {
  background-color: #FFFFFF;
  padding: 40px 44px 0 44px;
}

.sl-payment-crumbs {
  padding-top: 34px;
}

.bx_translt .crumbs {
  padding: 34px 0 0 0;
}

#box_menu {
  z-index: 50;
  position: fixed;
  left: 0;
  top: 80px;
  bottom: 0;
  width: 224px;
  background: #0D423E;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  padding-bottom: 40px;
  scrollbar-width: thin;
  scrollbar-color: #6d8382 #0D423E;
}

/* Chrome */
#box_menu::-webkit-scrollbar {
  width: 8px;
}

#box_menu::-webkit-scrollbar-track {
  background: #0f3432;
}

#box_menu::-webkit-scrollbar-thumb {
  background: #6d8382;
}

#box_menu::-webkit-scrollbar-thumb:hover {
  background: #5f7170;
}

#menu_pr {
  position: relative;
  z-index: 500;
  pointer-events: none;
}

#box_menu ul li hr {
  border-color: white;
  margin: 12px 24px;
  opacity: 0.2;
}

#box_menu .accordion {
  padding: 10px 0px;
  margin: 0;
}

#box_menu li {
  margin-left: 0;
  padding: 0;
  list-style-type: none;
  pointer-events: auto;
  opacity: 0.6;
  transition: opacity 0.3s;
}
#box_menu li i:before {
  color: #EDF8F3;
  transition: color 0.3s;
}

#box_menu li a {
  display: flex;
  align-items: center;
  padding: 14px 0 14px 24px;
  margin: 0;
  color: white;
}

#box_menu ul li.selected,
#box_menu ul li:hover {
  opacity: 1;
  transition: opacity 0.3s;
}
#box_menu ul li.selected i:before,
#box_menu ul li:hover i:before {
  color: #fff;
}

#box_menu em {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

#box_menu ul > li strong {
  display: block;
  padding-left: 45px;
  color: #ccc;
  font-style: italic;
  font-size: 80%;
  text-transform: none;
}

#box_menu ul > li strong span {
  font-weight: normal;
}

#box_menu .accordion-toggle {
  color: #979ca2;
}

#box_menu .icon {
  color: white;
  padding-right: 12px;
  vertical-align: -5px;
}

#box_menu li.selected a, #menu_pr li a:hover {
  opacity: 1;
}

#box_menu li.selected a .icon, #menu_pr li a:hover .icon {
  opacity: 1;
}

#menu_watermark {
  padding-top: 10px;
}

#box_menu #menu_watermark li a {
  padding-bottom: 10px;
}

#menu_watermark sub {
  bottom: auto;
  color: rgba(255, 255, 255, 0.4);
  font-size: 10px;
  line-height: 12px;
  text-transform: lowercase;
  font-style: italic;
}

#menu_watermark img {
  height: 18px;
  width: auto;
  padding-left: 5px;
  vertical-align: -7px;
}

.minus_left #menu_watermark sub {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.minus_left #box_menu #menu_watermark li a {
  padding-left: 0;
}

.minus_left #box_menu #menu_watermark > li span {
  display: block;
  height: 25px;
  width: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.minus_left #menu_watermark img {
  height: 25px;
  min-height: 25px;
  padding: 0;
  vertical-align: baseline;
}

@media (max-width: 1000px) {
  .minus_left #box_menu #menu_watermark li a {
    padding-top: 10px;
  }
  .minus_left #menu_watermark sub {
    display: none;
  }
}
/*

	Disminuidor lateral

*/
#btn_disms {
  z-index: 5001;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 224px;
  color: white;
  opacity: 0.6;
  cursor: pointer;
  font-family: "sally-icon";
  font-size: 24px;
}

#btn_disms.open {
  width: 80px;
}

#btn_disms i {
  position: absolute;
  overflow: visible;
  display: block;
  right: 24px;
  bottom: 24px;
  text-transform: none;
  font-style: normal;
  text-shadow: none;
  font-size: 24px;
  height: 24px;
}

#btn_disms .opn:after {
  content: "\e91e";
}

#btn_disms .cls:after {
  content: "\e91d";
}

#btn_disms .opn {
  display: none;
  left: 50%;
  margin-left: -3px;
}

#btn_disms:hover {
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  opacity: 1;
}

#btn_disms.open {
  width: 60px;
  -webkit-transition: none;
  transition: none;
}

#btn_disms.open .cls {
  display: none;
}

#btn_disms.open .opn {
  display: block;
}

.minus_left #logo,
.minus_left #box_menu {
  width: 80px;
}

.minus_left #logo a {
  width: 30px;
  height: 80px;
  margin-left: 28px;
}

.minus_left #hd_title,
.minus_left #hd_options {
  left: 124px;
}

.minus_left #logo_reg + #hd_options,
#logo_reg + #hd_options {
  left: 200px;
}

.minus_left #box_menu .icon {
  padding-right: 0;
}

.minus_left #box_menu em {
  display: none;
}

.minus_left #box_menu em.alter {
  display: inline;
}

.minus_left #box_menu li a {
  display: block;
  padding: 14px 0 14px 28px;
}

#box_menu:not([data-scroll="0"]) {
  /* Por si queremos hacer algo con esto en el futuro */
}

.minus_left #box_content {
  left: 80px;
}

.minus_left #box_content.pop_mode {
  left: 0;
}

.minus_left #box_menu ul > li {
  position: relative;
}

.minus_left #box_menu ul > li strong {
  padding-left: 0;
  text-align: center;
  color: #aaa;
}

.minus_left #box_menu ul > li span {
  display: none;
}

.minus_left #box_menu ul > li span.label {
  position: absolute;
  top: 5px;
  right: 8px;
  display: block;
  width: auto;
}

.breadcrumbs {
  margin: 5px 0 10px 0;
}

.panels-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  /* avoid browser level touch actions */
  touch-action: none;
}
.panels-container.panels-files {
  overflow: visible;
}

.splitter {
  flex: 0 0 auto;
  width: 8px;
  background: url(../../../img/vsizegrip.png) center center no-repeat #c7c7c7;
  min-height: 200px;
  cursor: col-resize;
}

.main-panel {
  flex: 1 1 auto;
  /* resizable */
  width: 100%;
  min-height: 200px;
  min-width: 200px;
  margin-left: 300px;
  transition: margin-left 0.1s linear;
}
.main-panel.tree-panel--collapsed {
  margin-left: 32px;
  transition: margin-left 0.1s linear;
}
.main-panel.tree-panel--hidden {
  margin-left: 0px;
}
.main-panel .box_head_sec .breadcrumbs {
  margin: 0 0 4px 0;
}

.box_menu_tooltip {
  margin-left: -20px !important;
  pointer-events: none;
}
.box_menu_tooltip .tooltip-inner {
  border: 1px solid #1E413E;
  background: #FFF;
  color: #1E413E;
}

/* Estados de minificación de filtros de listas de ítems */
/* se requiere evitar el transition del padding para el control de minificación de opciones de lista de ítems */
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle {
  transition-property: background-color, color, border;
}

.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified {
  padding: 4px 9px;
  max-width: 40px;
  min-width: 40px;
}

.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified span.icon,
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified i.icon {
  margin-right: 0;
}

.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle .filter-option.title em.alter,
.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.minified .filter-option.title {
  display: none;
}

.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.semi-minified .filter-option.title em {
  display: none;
}

.box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle.semi-minified .filter-option.title em.alter {
  display: inline;
}

/*
@media (max-width: 1550px) {

  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle { padding: 4px 9px; max-width: 40px; min-width: 40px; }
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle span.icon,
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle    i.icon  { margin-right: 0; }
  .box_pagination.box_list_ops .btn-group.select.box_rgt button.dropdown-toggle .filter-option.title { display: none; }

  #slct_ord .title em       { display: none; }
  #slct_ord .title em.alter { display: inline; }
}
*/
@media (max-height: 900px) {
  #box_menu li a, .minus_left #box_menu li a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
/* Ajax loaders */
@keyframes ajaxLoadingFadeInOut {
  0%, 100% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}
.box_ajax_loader {
  animation: ajaxLoadingFadeInOut 1s infinite;
  display: block;
}

.box_ajax_loader.ajax_title_header {
  content: "";
  display: block;
  width: 300px;
  height: 30px;
  background-image: url("../../../img/ajax/back_ajax_loading_pagination_min.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
}

.box_ajax_loader.ajax_pagination {
  content: "";
  display: block;
  width: 250px;
  height: 40px;
  background-image: url("../../../img/ajax/back_ajax_loading_pagination_min.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}

.box_ajax_loader.ajax_list_table {
  content: "";
  display: block;
  width: 800px;
  height: 300px;
  background-image: url("../../../img/ajax/back_ajax_loading_list_table_min.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
}

/*

	Connectors

*/
.tp_market {
  margin-bottom: 20px;
}

.box_conn.in_wkf {
  background-image: -webkit-linear-gradient(45deg, #fff 25%, #f3f8fc 25%, #f3f8fc 50%, #fff 50%, #fff 75%, #f3f8fc 75%, #f3f8fc);
  background-image: linear-gradient(45deg, #fff 25%, #f3f8fc 25%, #f3f8fc 50%, #fff 50%, #fff 75%, #f3f8fc 75%, #f3f8fc);
  background-size: 41px 41px;
}

.box_conn h3 {
  line-height: 19px;
}

.box_conn .box_info.btm {
  bottom: 70px;
}

.box_conn .box_info .icon {
  margin-right: 0;
}

.box_conn .box_info .md_pk, .box_conn .box_info .md_pk .icon {
  color: #2ECC71;
}

.box_conn .box_info .md_ok, .box_conn .box_info .md_ok .icon {
  color: #E74C3C;
}

.box_conn .box_info p {
  font-size: 12px;
  line-height: 20px;
}

.box_conn .box_btm {
  height: 60px;
  padding-top: 10px;
}

.conn_ops_check, label.conn_ops_check {
  float: left;
  display: inline-block;
  padding-left: 10px;
}

.in_form.conn_ops_check, .in_form label.conn_ops_check {
  float: none;
  display: block;
  padding-left: 0;
  margin-bottom: 0;
}

.in_form.conn_ops_check .box_tags {
  float: left;
  margin-left: 0;
}

.in_form.conn_ops_check.no_label label {
  display: none;
}
.in_form.conn_ops_check.no_label .box_tags {
  margin-top: 26px;
}

.conn_ops_check .box_tags {
  margin: 10px 0 0 10px;
}

.conn_ops_check .tagsinput {
  margin: 0;
}

.table-striped td .box_tags .dropdown-short li,
.table-striped td .box_tags .dropdown-large li,
.box_tags .btn-group.select .dropdown-menu li {
  margin: 0;
}

.table-striped td .box_tags .dropdown-short li.disabled,
.table-striped td .box_tags .dropdown-large li.disabled,
.box_tags .btn-group.select .dropdown-menu li.disabled {
  display: block;
}

.dropdown-short > li:first-child > a,
.dropdown-large > li:first-child > a {
  border-radius: 6px 0 0 0;
}

.dropdown-short > li:last-child > a,
.dropdown-large > li:last-child > a {
  border-radius: 0 0 0 6px;
}

.conn_ops_check .box_tags.md_filter .tagsinput .tag {
  height: 40px;
  padding: 8px 12px;
}
.conn_ops_check .box_tags.md_filter .tagsinput .tag span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 4px;
}
.conn_ops_check .box_tags.md_filter .tagsinput .tag .tagsinput-remove-link {
  font-weight: 600;
}

.conn_ops_check .box_tags .select {
  margin: 0;
  height: 40px;
}

.conn_ops_check .box_tags.md_filter .btn-group.select button .icon {
  top: 8px;
}

.conn_ops_check.clean {
  position: relative;
  width: 100%;
  float: none;
  display: inline-block;
  padding: 0;
}

.conn_ops_check.clean .box_tags {
  width: 100%;
}

.box_conn.mrkt .box_info {
  top: 24px;
  left: 24px;
}

.box_conn.mrkt .box_info p.blogo {
  position: relative;
  width: 100%;
}

.box_conn.mrkt .box_info p.blogo a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  font-weight: 600;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  color: #2E383A;
  font-size: 16px;
  line-height: 24px;
}

.box_conn.mrkt .box_info p.descr {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Número de líneas que quieres mostrar */
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  color: #4C6569;
}

.box_conn .box_info p {
  margin: 0 0 4px 0;
}

.box_conn.stat_alert h3 a,
.box_conn.stat_alert .box_info,
.box_conn.stat_alert .box_info strong {
  color: rgba(0, 0, 0, 0.5);
}

.box_conn.stat_alert h3 i.icon,
.box_conn.stat_alert .box_info i.icon {
  color: rgba(0, 0, 0, 0.3);
}

.box_conn.stat_alert .btn {
  background: rgba(0, 0, 0, 0.4);
}

.box_conn.stat_alert .btn:hover {
  background: rgba(0, 0, 0, 0.3);
}

.box_conn.stat_alert h3 {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.box_conn.stat_alert .box_btm {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.bx_hd_tbl_chn label {
  display: block;
  margin-top: 10px;
}

.bx_hd_tbl_chn .btn {
  padding: 8px 13px;
}

.bx_hd_tbl_chn .select2.select2-container--bootstrap {
  min-height: 35px;
}

.bx_hd_tbl_chn .select2-container--bootstrap .select2-selection--multiple {
  min-height: 20px;
}

.bx_hd_tbl_chn .select2-container--bootstrap {
  font-size: 12px;
}

.bx_hd_tbl_chn .btn i {
  font-size: 20px;
}

/*

    Macros en connectors

 */
.box_functions_info {
  font-size: 14px;
}

.box_functions_info ul {
  list-style-type: none;
  padding-left: 0;
}

.box_functions_info ul ul {
  padding-left: 20px;
  color: #4C6569;
}

.box_functions_info > ul > li {
  padding: 0 0 22px 0;
  margin: 0 0 10px 0;
}

.box_functions_info > ul > li:last-child {
  padding-bottom: 0;
  border: 0;
  margin: 0;
}

.box_functions_info h4 {
  font-size: 14px;
  line-height: 24px;
  margin: 4px 0;
}

.box_functions_info h5 {
  font-size: 12px;
  line-height: 20px;
  color: #4C6569;
  font-weight: normal;
  margin: 4px 0;
}

.box_functions_info h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #2E383A;
  margin: 4px 0;
}

.box_functions_info h4 i {
  color: #2E383A;
  font-weight: normal;
  padding: 0 5px;
  font-style: italic;
}

#cnn_macr_text,
#test_formula textarea {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "SFMono", monospace;
}

.box_colorpiker {
  position: absolute;
  z-index: 9;
  top: -210px;
  right: 35px;
  background: white;
  padding: 1rem;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  border-radius: 8px;
}

.pickerNon {
  display: none;
}

.box_sz_bt_ops.top_bt {
  width: 8%;
  min-width: 120px;
  padding-right: 0;
}

.col-auto.box_sz_bt_ops {
  padding-right: 0;
}

.box_sz_bt_ops {
  display: flex;
  align-items: center;
}
.box_sz_bt_ops.lft {
  width: 8%;
  min-width: 120px;
  padding-right: 0;
}
.box_sz_bt_ops.lft label.checkbox:before {
  width: 19px;
  height: 19px;
  top: 1px !important;
  left: 3px !important;
}
.box_sz_bt_ops.lft .icons span {
  font-size: 24px !important;
}
.box_sz_bt_ops.lft i.icon:before {
  font-size: 24px;
  color: #ABC0C4;
}
.box_sz_bt_ops.lft i.icon:hover:before {
  color: #4C6569;
}
.box_sz_bt_ops button.act_mov {
  padding: 0 14px 0 0;
  color: #ABC0C4;
  max-width: 35px;
}
.box_sz_bt_ops button.act_mov i.icon.no_pdg {
  font-size: 20px;
}
.box_sz_bt_ops label.elm_check.checkbox {
  margin: 0;
  z-index: 0;
}
.box_sz_bt_ops button.btn-xs.act_del, .box_sz_bt_ops button.btn-xs.act_act {
  height: 40px;
  background-color: transparent;
}
.box_sz_bt_ops button.btn-xs.act_del.btn-danger:active, .box_sz_bt_ops button.btn-xs.act_act.btn-danger:active {
  border: none;
}
.box_sz_bt_ops button.btn-xs.act_del .icon, .box_sz_bt_ops button.btn-xs.act_act .icon {
  font-size: 20px;
  color: #ABC0C4;
}
.box_sz_bt_ops button.btn-xs.act_del:hover, .box_sz_bt_ops button.btn-xs.act_act:hover {
  border-color: transparent;
}

.sep_btm.line_mov .col-frm .form-control.input-sm {
  height: 40px;
  width: 100%;
}

.offset_sz_bt_ops button.btn i.icon {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}

.box_sz_bt_ops button.btn_formula_conn {
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  background-color: transparent;
  color: #2E383A;
}
.box_sz_bt_ops button.btn_formula_conn i.icon {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}

.box_sz_bt_ops button.btn_formula_conn {
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  background-color: transparent;
  color: #2E383A;
  border: 1px solid transparent;
}
.box_sz_bt_ops button.btn_formula_conn:focus {
  background-color: transparent;
  border-color: #3EAC93;
  border: 1px solid #3EAC93;
}
.box_sz_bt_ops button.btn_formula_conn i {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}
.box_sz_bt_ops button.btn_formula_conn i.icon {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
  font-weight: initial;
  line-height: 12px;
}
.box_sz_bt_ops button.btn_formula_conn .sl-icon-formula:before {
  content: "";
  background-image: url("../../../img/icons/formula_default.png");
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.box_sz_bt_ops button.btn_formula_conn.btn-danger, .box_sz_bt_ops button.btn_formula_conn.btn-warning {
  background-color: #FDEDED;
  color: #D2322C;
}
.box_sz_bt_ops button.btn_formula_conn.btn-danger .sl-icon-formula:before, .box_sz_bt_ops button.btn_formula_conn.btn-warning .sl-icon-formula:before {
  background-image: url("../../../img/icons/formula_error@2x.png");
}
.box_sz_bt_ops button.btn_formula_conn.btn-danger:hover, .box_sz_bt_ops button.btn_formula_conn.btn-warning:hover {
  background-color: #FAD7D6;
}
.box_sz_bt_ops button.btn_formula_conn.btn-danger:active, .box_sz_bt_ops button.btn_formula_conn.btn-warning:active {
  background-color: #F7BCBA;
}
.box_sz_bt_ops button.btn_formula_conn.btn-default {
  border: 1px solid #D5DFE1;
  color: #2E383A;
}
.box_sz_bt_ops button.btn_formula_conn.btn-default .sl-icon-formula:before {
  font-family: "sally-icon", fantasy !important;
  font-style: normal;
  content: "\e907";
  font-weight: normal;
  font-size: 20px;
  background-image: none;
  line-height: 20px;
}
.box_sz_bt_ops button.btn_formula_conn.btn-default:hover {
  background-color: #EAEEF0;
  border: 1px solid #D5DFE1 !important;
}
.box_sz_bt_ops button.btn_formula_conn.btn-default:active {
  background-color: #D5DFE1;
}
.box_sz_bt_ops button.btn_formula_conn.btn-primary {
  background-color: #D7F9DB;
  color: #05570A;
}
.box_sz_bt_ops button.btn_formula_conn.btn-primary .sl-icon-formula:before {
  background-image: url("../../../img/icons/formula_fill@2x.png");
}
.box_sz_bt_ops button.btn_formula_conn.btn-primary:hover {
  background-color: #E6FAE8;
}
.box_sz_bt_ops button.btn_formula_conn.btn-primary:active {
  background-color: #BFF5C5;
}
.box_sz_bt_ops button.btn_formula_conn.btn-disabled .sl-icon-formula:before, .box_sz_bt_ops button.btn_formula_connreadonly .sl-icon-formula:before, .box_sz_bt_ops button.btn_formula_conndisabled .sl-icon-formula:before {
  background-image: url("../../../img/icons/formula_no@2x.png");
}
.box_sz_bt_ops button.btn_formula_conn.btn-disabled em, .box_sz_bt_ops button.btn_formula_connreadonly em, .box_sz_bt_ops button.btn_formula_conndisabled em {
  color: #93AFB4;
}

.fld_dsbl .box_sz_bt_ops button.btn_formula_conn {
  cursor: initial;
  background: #EAEEF0;
  border: 1px solid transparent !important;
}
.fld_dsbl .box_sz_bt_ops button.btn_formula_conn em, .fld_dsbl .box_sz_bt_ops button.btn_formula_conn i {
  color: #ABC0C4;
}
.fld_dsbl .box_sz_bt_ops button.btn_formula_conn .sl-icon-formula:before {
  background-image: url("../../../img/icons/formula_no@2x.png");
}
.fld_dsbl .box_sz_bt_ops button.btn_formula_conn:hover {
  border: 1px solid transparent !important;
}
.fld_dsbl .box_sz_bt_ops button.btn_formula_conn:active {
  background: #EAEEF0;
  border: 1px solid transparent !important;
}

div.form-group.sep_ln_top {
  border-color: #D5DFE1;
}
div.form-group.sep_ln_top span.legacy-h7 {
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  margin-top: 20px;
}
div.form-group.sep_ln_top a {
  font-size: 14px;
  line-height: 24px;
  color: #387B77;
}
div.form-group.sep_ln_top a:hover {
  text-decoration: underline;
  color: #3D9487;
}

.fld_mrg_top .offset_sz_bt_ops {
  display: flex;
}
.fld_mrg_top .offset_sz_bt_ops button {
  margin-right: 12px;
}

.alert_conn_box {
  background-color: #FFFFFF;
}
.alert_conn_box .alert {
  display: flex;
}

.paragrh.box_msg_action.pbtm:not(.on-header) {
  background-color: transparent;
}

.crumbs_formula_conn {
  padding: 0;
}

@media (max-width: 1200px) {
  .box_list .sklt {
    width: 50%;
  }
  .box_list .sklt.dbl,
  .box_list .sklt.trp,
  .box_list .sklt.mxm {
    width: 100%;
  }
  .minus_left .box_list .sklt {
    width: 25%;
  }
  .minus_left .box_list .sklt.dbl {
    width: 50%;
  }
  .minus_left .box_list .sklt.trp {
    width: 75%;
  }
  .minus_left .box_list .sklt.mxm {
    width: 100%;
  }
  .box_dashb {
    font-size: 14px;
    line-height: 18px;
  }
  .box_dashb, .box_conn {
    min-height: 250px;
    max-height: 250px;
  }
  .box_dashb.msg, .box_conn.msg {
    min-height: none;
    max-height: none;
  }
  .box_dashb.bx_bench.hvar .bx_elm {
    margin-top: 5px;
  }
  .box_form .btn .icon {
    margin: 0;
    padding: 0;
  }
  .box_pagination .btn em.alter {
    display: inline;
  }
  .box_pagination .btn i.icon {
    padding-right: 0;
  }
}
@media (max-width: 1000px) {
  .box_conn .box_info em {
    display: none;
  }
  .box_conn .box_info em.alter {
    display: inline;
  }
  #fld_bx_srch > i.icon {
    font-size: 22px;
    line-height: 24px;
    padding-top: 5px;
  }
  #fld_bx_srch > input {
    width: 200px;
    font-size: 16px;
    line-height: 18px;
    padding-top: 5px;
  }
  #fld_bx_srch button {
    font-size: 20px;
    line-height: 22px;
    padding-top: 5px;
  }
}
@media (max-width: 900px) {
  .minus_left .box_list .sklt {
    width: 50%;
  }
  .minus_left .box_list .sklt.trp,
  .minus_left .box_list .sklt.dbl {
    width: 100%;
  }
  #fld_bx_srch > input {
    width: 125px;
  }
}
@media (max-width: 800px) {
  .box_conn .box_info .icon {
    font-size: 100%;
  }
  .box_conn .box_info p {
    margin-bottom: 5px;
  }
  .box_list .sklt {
    width: 50%;
  }
  .minus_left .box_list .sklt {
    width: 50%;
  }
  .minus_left .box_list .sklt.trp,
  .minus_left .box_list .sklt.dbl {
    width: 100%;
  }
  .offset_sz_bt_del {
    margin-left: 0;
  }
  #fld_bx_srch {
    display: none;
  }
}
@media (max-width: 650px) {
  .box_list .sklt,
  .minus_left .box_list .sklt {
    width: 100%;
  }
  .minus_left .box_list.tp_conn .sklt.dbl,
  .minus_left .box_list.tp_conn .sklt.mxm {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .box_list .sklt,
  .minus_left .box_list .sklt {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .box_dashb.bx_bench .bx_elm.st_lst {
    margin-top: 11px;
  }
  #fld_bx_srch > i.icon {
    font-size: 24px;
    line-height: 26px;
  }
  #fld_bx_srch > input {
    width: 350px;
    font-size: 18px;
    line-height: 20px;
  }
  #fld_bx_srch button {
    font-size: 22px;
    line-height: 24px;
  }
  .box_list.tp_conn .sklt.dbl {
    width: 50%;
  }
  .box_list.tp_conn .sklt.trp {
    width: 75%;
  }
  .box_list.tp_conn .sklt.mxm {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .box_dashb.bx_bench .bx_elm.st_lst {
    margin-top: 2px;
  }
}
.box_dashb.msg, .box_conn.msg {
  min-height: 100%;
  max-height: none;
}

.box_form .sl-conn-title {
  padding-left: 0;
}

.box_form .form-group > h6.title_text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-bottom: 0;
  margin-top: 36px;
}

.box_form .form-group.row > label.title_text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-bottom: 0;
  margin-left: 16px;
  margin-top: 36px;
}
.box_form .form-group.row > h5.title_text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-bottom: 0;
  margin-left: 16px;
  margin-top: 36px;
}
.box_form .form-group.row .id_code_connector > label,
.box_form .form-group.row .private_key_conn > label {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-bottom: 16px;
}
.box_form .form-group.row .id_code_connector > h5.no_mrtp,
.box_form .form-group.row .private_key_conn > h5.no_mrtp {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #2E383A;
}
.box_form .form-group.row.sl-conn-language > .col-sm-12 label[for=output_languages] {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2E383A;
  margin-bottom: 16px;
  margin-top: 36px;
}

.form-group.sl-conn-language ul.check_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.form-group.sl-conn-language div#CTLANG.switch_box {
  margin-top: 24px;
}
.form-group.sl-conn-language label.checkbox .first-icon {
  z-index: 1;
}

.form-group.form_private_key_conn {
  margin-bottom: 16px;
}

.private_key_conn h3 {
  color: #2E383A;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.private_key_conn ul {
  margin: 0;
}
.private_key_conn ul li.td_key {
  align-items: center;
  color: #2E383A;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  justify-content: space-between;
  margin-top: 16px;
}
.private_key_conn ul li.td_key .td_btn_opt {
  height: 20px;
}
.private_key_conn ul li.td_key .td_btn_opt button i {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: #CFDADD;
}
.private_key_conn ul li.td_key:first-child {
  margin-top: 0;
}

#box_form_content .form-group.overflow {
  overflow: visible;
  width: 100%;
  display: inline-block;
}

#box_form_content .form-group.overflow .btn {
  float: left;
}

.form-group.overflow.conn_sdk {
  margin-top: 60px;
}

.form-group .xl-tooltip.conn_icon_info {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: #CFDADD;
  position: absolute;
  top: 2px;
}

/*
select.form-control#select_secret {
    color: #93AFB4;
}
*/
#box_content .form .box_form.box_form_conn {
  padding: 0px 44px 40px 44px;
}

.form_group_plugin {
  margin-top: 44px;
}

.conn_tags_box {
  width: 100%;
}

#conn_move_shadow {
  background-color: transparent !important;
  border: 1px solid #D5DFE1;
}

.paragrh .form .mgbform h4.title.crbTp.sl_conn_title {
  background-color: white;
  padding: 40px 44px 20px 44px;
}

label[for=conn_description] {
  text-transform: capitalize;
}

#crumbs_box_companies_connectors {
  margin-bottom: 44px;
}

div.box_list.tp_conn {
  padding: 24px 28px 44px 44px;
}
div.box_list.tp_conn div.sklt {
  padding: 0 16px 16px 0;
}
div.box_list.tp_conn div.sklt .box_conn {
  border: 1px solid #D5DFE1;
}
div.box_list.tp_conn div.sklt .box_conn:hover {
  border: 1px solid #3D9487;
}
div.box_list.tp_conn div.sklt .box_conn .box_info a img {
  height: 34px;
  width: fit-content;
}
div.box_list.tp_conn div.sklt .box_conn h3 {
  padding: 24px 24px 0 24px;
}
div.box_list.tp_conn div.sklt .box_conn h3 span {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #2E383A;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
div.box_list.tp_conn div.sklt .box_conn h3 span img {
  height: 34px;
  transform-origin: 0 0;
  width: fit-content;
}
div.box_list.tp_conn div.sklt .box_conn h3 span a {
  font-size: 16px;
  font-weight: 700;
  color: #2E383A;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info {
  margin-top: 8px;
  position: unset;
  padding: 0 24px;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info p {
  color: #4C6569;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info p i {
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  margin-right: 8px;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info p em {
  font-style: normal;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info p.md_pk {
  color: #0BA815;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info p.md_pk i {
  color: #0BA815;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info .connDescription {
  top: 32px;
  right: 24px;
  bottom: unset;
  margin: 0;
}
div.box_list.tp_conn div.sklt .box_conn .box_info.btm.chann_box_info .connDescription i {
  margin: 0;
}
div.box_list.tp_conn div.sklt .box_conn .box_btm {
  height: unset;
  padding: 0;
  bottom: 24px;
  left: 24px;
  right: auto;
}
div.box_list.tp_conn div.sklt .box_conn .box_btm .btn {
  height: 32px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .box_info {
  display: none;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert h3 {
  display: none;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .box_info.btm.chann_box_info {
  display: none;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .box_btm {
  position: unset;
  padding: 0;
  margin-bottom: 24px;
  height: unset;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .box_btm .btn {
  background-color: #D5DFE1;
  height: 32px;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .channel_disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .channel_disabled i {
  font-size: 44px;
  line-height: 44px;
  height: 44px;
  color: #4C6569;
  margin-bottom: 4px;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .channel_disabled h4 {
  color: #2E383A;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: center;
  max-height: 48px;
  padding: 0 20px;
  max-width: 300px;
  overflow: hidden;
}
div.box_list.tp_conn div.sklt .box_conn.stat_alert .channel_disabled p {
  color: #4C6569;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (min-width: 1400px) {
  div.box_list.tp_conn div.sklt {
    width: 20%;
  }
}
form[name=companies_connectors] .crumbs_conn {
  display: none;
}
form[name=companies_connectors] #crumbs_box_table_active {
  background-color: transparent;
  margin-bottom: 44px;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper {
  height: auto;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs {
  position: relative;
  padding: 0;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li {
  margin-left: 1px;
  position: relative;
  margin-right: 20px;
  border-radius: 4px;
  margin-bottom: 0;
  border-bottom: 0;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li:hover {
  background-color: #EAEEF0;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li a {
  padding: 4px 16px;
  margin-left: 16px;
  color: #4C6569;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: none;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li:before {
  position: absolute;
  left: 16px;
  top: calc(50% - 5px);
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #CFDADD;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.active {
  background-color: #EAEEF0;
  border-bottom: none;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.active a {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.active:before {
  background-color: #3D9487;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.last-child-tab a {
  margin-left: 0px;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.last-child-tab a em {
  font-style: normal;
}
form[name=companies_connectors] #crumbs_box_table_active .wrapper ul.nav-tabs > li.last-child-tab::before {
  content: initial;
}

.form-group.button-download {
  margin-bottom: 44px;
}

#cXMLFieldsMapContainer.tab-content {
  border: none;
  padding: 0px 0px 10px;
}
#cXMLFieldsMapContainer .inline .form-group {
  float: left;
}
#cXMLFieldsMapContainer .asr_icon .btn {
  padding: 10px 0px;
}
#cXMLFieldsMapContainer .asr_icon a.act_trash {
  color: #BDC3C7;
}
#cXMLFieldsMapContainer .asr_icon a.act_trash:hover {
  color: #FF4742;
}
#cXMLFieldsMapContainer .form-group + .form-group.asr_icon {
  padding-left: 20px;
}
#cXMLFieldsMapContainer option.unselected {
  font-style: italic;
}

#CSVFieldsMapContainer .inline .form-group {
  float: left;
}
#CSVFieldsMapContainer .col-md-1.icon-tiny {
  max-width: 48px;
}
#CSVFieldsMapContainer .select-wrapper::after {
  content: "";
  position: absolute;
  right: 13%;
  top: 33%;
  width: 24px;
  transform: translateY(-50%);
  border-radius: 100px;
  background: #EAEEF0;
  text-align: center;
  font-size: 10px;
}

.visibilityDropdownBtn button.dropdown-toggle i.sl-icon-chevron-down {
  margin-right: 0px !important;
}

.csv-map-arrow {
  font-size: 24px;
  color: #ABC0C4;
}

.form-icon-trash {
  font-size: 24px !important;
  font-weight: normal !important;
  color: #ABC0C4;
}
.form-icon-trash:hover {
  color: #FF4742;
}

.sl-buttons-refresh > .btn {
  margin-right: 12px !important;
}
.sl-buttons-refresh .box_desc {
  width: 100%;
}

.box_form_conn .tooltip-inner {
  position: relative;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  white-space: normal;
}

.box_form_conn .form-group .tooltip-inner {
  width: max-content;
}

/*

	Menú inferior pasos stups

*/
.box_steps {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 62px;
  border-top: 1px solid #D5DFE1;
  background-color: #F8F8F8;
  z-index: 100;
  /*min-width: 1150px;*/
}

.box_steps .btn_pas {
  position: absolute;
  right: 44px;
}

.box_steps .btn_pas.bx_left {
  left: 44px;
}

.box_steps .btn_pas.bx_left.disabled {
  display: unset;
}
.box_steps .btn_pas.bx_left.disabled a {
  color: #93AFB4;
  pointer-events: none;
  cursor: default;
}

.box_steps .btn_pas a {
  height: 40px;
  padding: 10.5px 20px;
  background-color: #387B77;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 4px;
}
.box_steps .btn_pas a:hover {
  background-color: #205A5A;
}

.box_steps .btn_pas.bx_left a {
  background-color: transparent;
  color: #2E383A;
}
.box_steps .btn_pas.bx_left a:hover {
  background-color: #EAEEF0;
}

.box_steps .btns_pas a {
  position: relative;
  overflow: hidden;
  float: left;
  width: fit-content;
  height: 32px;
  margin-left: -10px;
  font-size: 12px;
  line-height: 16px;
}

.box_steps .btns_pas a strong {
  position: relative;
  display: block;
  float: left;
  width: fit-content;
  height: 32px;
  padding: 8px 8px 8px 24px;
  background: #d7dcdf;
  text-align: center;
  color: #4C6569;
  font-weight: normal;
}

.box_steps .btns_pas a.ini {
  margin-left: 0;
}

.box_steps .btns_pas a.ini strong {
  padding-left: 16px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.box_steps .btns_pas a.ini .pre {
  display: none;
}

.box_steps .btns_pas a.end strong {
  width: fit-content;
}

.box_steps .btns_pas a strong .pre,
.box_steps .btns_pas a .suf {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 17px 6px 17px 17px;
  line-height: 0px;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
}

.box_steps .btns_pas a strong .pre {
  position: absolute;
  top: 0;
  left: 0;
  border-color: rgba(236, 240, 241, 0) rgba(236, 240, 241, 0) rgba(236, 240, 241, 0) #F8F8F8;
  _border-color: #000000 #000000 #000000 #F8F8F8;
}

.box_steps .btns_pas a .suf {
  position: relative;
  display: block;
  float: left;
  border-color: rgba(215, 220, 223, 0) rgba(215, 220, 223, 0) rgba(215, 220, 223, 0) #d7dcdf;
  _border-color: #000000 #000000 #000000 #d7dcdf;
}

.box_steps .btns_pas a.next strong {
  background: #FFFFFF;
  color: #4C6569;
}

.box_steps .btns_pas a.next .suf {
  border-color: rgba(136, 147, 153, 0) rgba(136, 147, 153, 0) rgba(136, 147, 153, 0) #FFFFFF;
  _border-color: #000000 #000000 #000000 #FFFFFF;
}

.box_steps .btns_pas a.dissabled {
  cursor: default;
}

.box_steps .btns_pas a.dissabled strong, .box_steps .btns_pas a.dissabled:hover strong {
  background: #FFFFFF;
}

.box_steps .btns_pas a.dissabled .suf, .box_steps .btns_pas a.dissabled:hover .suf {
  border-color: rgba(215, 220, 223, 0) rgba(215, 220, 223, 0) rgba(215, 220, 223, 0) #FFFFFF;
  _border-color: #000000 #000000 #000000 #FFFFFF;
}

.box_steps .btns_pas a.prev strong {
  background-color: #D5DFE1;
  color: #2E383A;
}

.box_steps .btns_pas a.prev .suf {
  border-color: rgba(27, 188, 155, 0) rgba(27, 188, 155, 0) rgba(27, 188, 155, 0) #D5DFE1;
  _border-color: #000000 #000000 #000000 #D5DFE1;
}

.box_steps .btns_pas a.selected strong {
  background: #CFDADD;
  color: #4C6569;
}

.box_steps .btns_pas a.selected .suf {
  border-color: rgba(27, 188, 155, 0) rgba(27, 188, 155, 0) rgba(27, 188, 155, 0) #CFDADD;
  _border-color: #000000 #000000 #000000 #CFDADD;
}

.box_steps .btns_pas .navbar-new {
  margin: -14px 0 0 0;
  background: #e74c3c;
}

.box_steps .btns_pas a.stp_1 {
  z-index: 6;
}

.box_steps .btns_pas a.stp_2 {
  z-index: 5;
}

.box_steps .btns_pas a.stp_3 {
  z-index: 4;
}

.box_steps .btns_pas a.stp_4 {
  z-index: 3;
}

.box_steps .btns_pas a.stp_5 {
  z-index: 2;
}

.box_steps .btns_pas a.stp_6 {
  z-index: 1;
}

/*

	Emp crea db caja idiomas

*/
.box_form_add_db.lng_loc label.checkbox {
  width: 285px;
}

.box_form_add_db label.checkbox .icons {
  top: 7px;
  left: 10px;
}

.box_form_add_db label.checkbox:before {
  top: 10px;
  left: 13px;
}

.box_form_add_db label.box_lang_chk {
  width: fit-content;
  background-color: white;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #2E383A;
}

.box_form_add_db label.box_lang_chk:hover {
  background-color: #EAEEF0;
  transition: all 0.3s ease;
}

.box_form_add_db label.box_lang_chk .icon {
  font-size: 20px;
  color: #2E383A;
}

.box_top_langs, .box_extra_langs {
  position: relative;
  width: 800px;
  overflow: hidden;
  display: none;
}

.box_top_langs {
  display: block;
}

.box_top_langs label, .box_extra_langs label {
  background-color: #EAEEF0;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  width: 100%;
}

.box_form_add_db label.defLg-tit {
  margin: 0 0 8px 0;
  line-height: 24px;
  font-size: 14px;
}

.box_form_add_db select.defLg-sel {
  position: relative;
  float: left;
  width: auto;
}

.box_form_add_db.defLang a.mrk-help {
  margin-left: 8px;
  height: 20px;
}

.mod_popup .defLang {
  margin-bottom: 20px;
}

.mod_popup .box_top_langs,
.mod_popup .box_extra_langs {
  width: auto;
}

.mod_popup .box_form_add_db {
  margin-bottom: 20px;
}

/*

	Emp crea db caja catálogo

*/
.box_form_add_db .scroll {
  z-index: 2;
  position: relative;
  display: none;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 20px;
}

.box_form_add_db .scroll div {
  z-index: 1;
  position: relative;
  overflow: hidden;
  height: 1px;
}

.box_form_add_db .wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  overflow: hidden;
}

.box_form_add_db .box_cat,
.box_form_add_db .box_sub_cat {
  position: relative;
  overflow: hidden;
}

.box_form_add_db .form-group {
  width: 150px;
  margin: 0;
  float: left;
  overflow: hidden;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box_form_add_db .fld_btn_sec,
.box_form_add_db .fld_add_sec {
  margin-bottom: 10px;
}

.box_form_add_db .fld_btn_sec {
  font-weight: 600;
  background-color: #FBF2D0;
  color: #5A4B35;
  border: none;
  margin-bottom: 12px;
  text-align: center;
}

.box_form_add_db .fld_btn_sec:hover,
.box_form_add_db .fld_btn_sec:focus {
  background: #fff;
  color: #34495e;
  border-color: #387B77;
}

.box_form_add_db .box_cat .tagsinput-remove-link {
  display: none;
}

.box_form_add_db .fld_add_sec {
  width: 150px;
}

.box_form_add_db .box_sub_cat {
  position: relative;
  overflow: hidden;
}

.box_form_add_db .box_cat .dec_dtll {
  position: absolute;
  left: 75px;
  top: 55px;
  bottom: 10px;
  border-left: 2px dotted #ABC0C4;
  width: 0;
}

.box_form_add_db .box_cat .sub_dtll {
  position: absolute;
  left: 155px;
  top: 0;
  bottom: 10px;
  width: 10px;
  border-right: 1px solid #ABC0C4;
}

.box_form_add_db .box_cat .sub_dtll .icon_ref {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  border-top: 1px solid #ABC0C4;
}

/*

	Emp summary

*/
.box_form_add_db .ico_status {
  vertical-align: -2px;
  margin-right: 10px;
}

.box_form_add_db .ico_status.fui-check-inverted {
  color: #5CB85C;
}

.box_form_add_db .alert-warning .ico_status.fui-cross-inverted {
  color: #f1c40f;
}

.box_form_add_db .alert-danger .ico_status.fui-cross-inverted {
  color: #E74C3C;
}

.box_form_add_db .btn_continue {
  display: inline-block;
  margin-top: 20px;
}

div.box_cat_reg {
  position: relative;
  overflow: hidden;
  width: 940px;
  margin: 24px auto 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4C6569;
}
div.box_cat_reg #msg_info_import_cats.box_form_message_info {
  display: flex;
  align-items: start;
  padding: 16px;
  border-radius: 4px;
  background-color: #E9F9FB;
  border: 1px solid #BAEBF5;
  color: #005583;
  font-weight: 400;
}
div.box_cat_reg #msg_info_import_cats.box_form_message_info span {
  font-size: 24px;
  line-height: 24px;
  margin-right: 12px;
}
div.box_cat_reg #msg_info_import_cats.box_form_message_info p {
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  padding-top: 0;
  color: #005583;
}

.box_cat_reg .box_btn_more_inf {
  position: relative;
  padding-top: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #387B77;
}
.box_cat_reg .box_btn_more_inf a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_cat_reg .box_btn_more_inf a span {
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
}

.title_create_company {
  margin-bottom: 60px;
  color: #2E383A;
}

.box_center.long.box_create_company {
  padding: 0;
  margin: 164px 0 70px 0;
  margin-bottom: 70px;
  position: relative;
}
.box_center.long.box_create_company h4 {
  font-size: 29px;
  font-weight: 700;
  line-height: 36px;
  color: #2E383A;
  width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.box_center.long.box_create_company p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4C6569;
  padding-top: 24px;
  width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.box_center.long.box_create_company .box_form_add_db.defLang {
  margin-top: 60px;
}
.box_center.long.box_create_company .box_form_add_db {
  margin-top: 24px;
}
.box_center.long.box_create_company .box_form_add_db .form-group-lang {
  display: flex;
  align-items: center;
}
.box_center.long.box_create_company .box_form_add_db .form-group-lang .defLg-sel {
  width: 474px;
}
.box_center.long.box_create_company .box_form_add_db .form-group-lang a span {
  font-size: 20px;
  line-height: 20px;
  color: #4C6569;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag {
  margin-top: 50px;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput {
  display: flex;
  flex-wrap: wrap;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tag {
  align-items: center;
  width: fit-content;
  margin: 10px 8px 8px 0;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  background-color: #FBF2D0;
  color: #5A4B35;
  height: 40px;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tag .tagsinput-remove-link {
  opacity: 1;
  padding: 0;
  position: unset;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tag .tagsinput-remove-link:before {
  font-family: "sally-icon";
  content: "\e922";
  color: #5A4B35;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tagsinput-add-container {
  padding-top: 10px;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tagsinput-add-container input::placeholder {
  opacity: 1;
  color: #7B9BA1;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_tag .tagsinput .tagsinput-add-container input:hover {
  border-color: #CFDADD;
}
.box_center.long.box_create_company .box_form_add_db.selectLang {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang .form-group-lang {
  display: flex;
  align-items: center;
}
.box_center.long.box_create_company .box_form_add_db.selectLang .form-group-lang .defLg-sel {
  width: 474px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang .form-group-lang a span {
  font-size: 20px;
  line-height: 20px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox {
  position: relative;
  float: left;
  width: 228px;
  overflow: hidden;
  margin: 0;
  padding: 8px 10px 8px 40px;
  text-align: left;
  border-radius: 4px;
  background: #EAEEF0;
  cursor: pointer;
  color: #2E383A;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox::before {
  top: 12px;
  left: 13px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox .icons {
  top: 9px;
  left: 10px;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox.box_lang_chk {
  background-color: transparent;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 20px;
  font-weight: 600;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox.box_lang_chk span {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
  font-weight: 400;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox.box_lang_chk::before {
  display: none;
}
.box_center.long.box_create_company .box_form_add_db.selectLang label.checkbox.box_lang_chk:hover {
  background-color: #EAEEF0;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary {
  margin-top: 60px;
  width: 693px;
  margin-left: auto;
  margin-right: auto;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert {
  width: 694px;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-success, .box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-warning {
  border: none;
  background-color: #FFFFFF;
  color: #000000;
  padding: 24px;
  margin-bottom: 8px;
  width: 100%;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-success h6, .box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-warning h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-success h6 a, .box_center.long.box_create_company .box_form_add_db.box_form_add_db_summary .alert.alert-warning h6 a {
  color: #4C6569;
  font-weight: 600;
}
.box_center.long.box_create_company .box_extra_langs_open {
  position: relative;
  overflow: hidden;
  width: 960px;
  margin: 24px auto 90px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}
.box_center.long.box_create_company .box_extra_langs_open label.checkbox {
  position: relative;
  float: left;
  width: 228px;
  overflow: hidden;
  margin: 0;
  padding: 8px 10px 8px 40px;
  text-align: left;
  border-radius: 4px;
  background: #EAEEF0;
  cursor: pointer;
  color: #2E383A;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
}
.box_center.long.box_create_company .box_extra_langs_open label.checkbox::before {
  top: 12px;
  left: 13px;
}
.box_center.long.box_create_company .box_extra_langs_open label.checkbox .icons {
  top: 9px;
  left: 10px;
}

/*

	Importació

*/
.box_tip_mod, .box_tip_fld {
  position: relative;
  float: left;
  overflow: hidden;
  margin-right: 24px;
}

.box_tip_mod button {
  display: flex;
  flex-direction: column;
  border: 1px solid #D5DFE1;
  color: #2E383A;
  background-color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 52px 60px 36px 60px;
  height: 240px;
}
.box_tip_mod button:hover {
  color: #2E383A;
  background-color: #FFFFFF;
  border: 1px solid #3D9487 !important;
}

.box_tip_mod button img {
  width: 104px;
}

.box_list_max {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 320px;
  margin-bottom: 20px;
}

.box_list_max.md_dirct {
  min-height: 370px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.box_list_max.md_scrll {
  min-height: 340px;
}

.box_list_max .scroll {
  z-index: 2;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 22px;
}

.box_list_max .scroll div {
  z-index: 1;
  position: relative;
  overflow: hidden;
  height: 1px;
}

.box_list_max.md_dirct .wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

.box_list_max.md_scrll .wrap {
  position: absolute;
  top: 30px;
  left: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.box_tip_fld {
  width: 250px;
  min-height: 340px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #D5DFE1;
  margin: 0 10px 20px 0 0;
  visibility: hidden;
}

.box_tip_fld .field_back {
  z-index: 2;
  position: absolute;
  top: 30px;
  right: 16px;
  color: #CFDADD;
  font-size: 20px;
  line-height: 20px;
}

.box_tip_fld .mx-inf {
  z-index: 4;
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
  font-style: normal;
}

.box_tip_fld .mx-inf a {
  color: #4C6569;
}

.box_tip_fld .mx-inf a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.box_tip_fld.st_del .mx-inf a {
  cursor: default;
}

.box_tip_fld input.nm {
  z-index: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 30px 16px 12px;
  border: 0;
  border-bottom: 1px solid #D5DFE1;
  margin-bottom: 16px;
  display: block;
  color: #2E383A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.box_tip_fld input.nm:focus {
  z-index: 3;
  background: #fff;
  color: #2E383A;
}

.box_tip_fld.st_fld input.nm:focus {
  z-index: 3;
  background: #E6FAE8;
  color: #2E383A;
}

.box_tip_fld p.sel {
  display: flex;
  padding: 0;
  margin: 0;
  height: 35px;
  text-align: center;
}

.box_tip_fld select {
  border: 1px solid #D5DFE1;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #2E383A;
  height: 32px;
  border-radius: 4px;
  margin: 0px 16px 12px 16px;
  width: 100%;
  display: flex;
}

.box_tip_fld .sl-field-disabled {
  display: block;
}

.box_tip_fld select.disabled {
  opacity: 0.3;
  color: #fff;
}

.box_tip_fld select optgroup {
  padding: 5px;
  font-style: italic;
  color: #888;
}

.box_tip_fld select option.imprt {
  color: #000;
}

.box_tip_fld select option.inctv {
  color: #aaa;
}

.box_tip_fld select option[selected] {
  background: #387B77;
  color: #fff;
}

.box_tip_fld select option {
  font-size: 14px;
  padding: 5px 5px 5px 10px;
  color: #333;
}

.box_tip_fld select optgroup.up option {
  font-size: 14px;
  font-weight: 600;
}

.box_tip_fld select option[value=action_delete] {
  color: #e74c3c;
  font-weight: bold;
}

.box_tip_fld select option[value=action_delete]:before {
  font-family: "Flat-UI-Icons";
  content: "\e00b";
  font-weight: normal;
}

.box_tip_fld p.sel i {
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  margin-top: 70px;
  color: #4C6569;
}

.ops.ops_vrt,
.box_tip_fld p.ops {
  display: flex;
  flex-direction: column;
}

p.ops.ops_vrt .disabled,
.box_tip_fld p.ops .disabled {
  display: block;
}

.ops_checkboxes {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 24px;
}

.ops_vrt .btn_config {
  margin-bottom: 24px;
}

.box_tip_fld .checkbox {
  margin: 0 0 20px 0;
  padding-left: 24px;
  text-align: left;
  color: #4C6569;
}

.box_tip_fld .checkbox .icons .fui-checkbox-unchecked:before {
  content: "\e034";
}

.box_tip_fld .checkbox:hover {
  cursor: pointer;
}

.box_tip_fld .checkbox:hover .icons {
  color: rgba(0, 0, 0, 0.4);
}

.box_tip_fld .checkbox.disabled,
.box_tip_fld .checkbox.disabled .icons span {
  cursor: default;
}

p.ops.ops_vrt .checkbox.fld_sep,
.box_tip_fld p.ops .checkbox.fld_sep {
  display: block;
}

.box_tip_fld .box_exempl {
  padding: 12px 12px 6px 12px;
  margin: 12px 16px;
  background-color: #F8F8F8;
  border-radius: 4px;
}

.box_tip_fld .box_exempl li {
  list-style-type: none;
  padding: 0 0 6px 0;
  margin: 0;
  border-top: none;
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
}

.box_tip_fld .box_exempl li i {
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
}

.box_tip_fld.st_del {
  background-color: #FFFFFF;
  margin: 0px 10px 0px 0px;
}
.box_tip_fld.st_del p.sel i {
  margin-top: 58px;
}
.box_tip_fld.st_del .sl-icon-stop {
  font-size: 44px;
}
.box_tip_fld.st_del .field-disabled {
  margin-top: 12px;
  display: block;
}
.box_tip_fld.st_del .ops_btns {
  margin-top: 78px !important;
}

.box_tip_fld {
  margin: 0px 10px 0px 0px;
}

.box_tip_fld.st_fld {
  background: #E6FAE8;
  margin: 0px 10px 0px 0px;
}
.box_tip_fld.st_fld input {
  background: #E6FAE8;
}
.box_tip_fld.st_fld .box_exempl {
  background-color: #fff;
}

.box_tip_fld.st_del .mx-inf,
.box_tip_fld.st_del input,
.box_tip_fld.st_del select,
.box_tip_fld.st_del li,
.box_tip_fld.st_del li i {
  cursor: default;
  background-color: #FFFFFF;
  opacity: 0.5;
}

.box_tip_fld.st_del .checkbox {
  visibility: hidden;
}

.box_tip_fld.st_del .box_exempl {
  display: none;
}

.box_tip_fld.st_del .ops_btns {
  justify-content: center;
  margin: 36px 0 0 0;
}

.form_config .box_tip_fld {
  visibility: visible;
  width: 400px;
  height: auto;
  border: 0;
  border-radius: 0;
}

.form_config .box_tip_fld .field_back {
  left: 380px;
}

.form_config .box_tip_fld select {
  width: 400px;
  margin: 0;
}

.form_config p.ops.ops_vrt,
.form_config .box_tip_fld p.ops {
  padding: 20px 0 0;
  margin: 0;
  text-align: left;
}

.form_config p.ops.ops_vrt {
  padding: 0 0 20px 0;
}

.form_config p.ops.ops_vrt label,
.form_config .box_tip_fld p.ops label {
  margin: 0 0 20px;
}

.form_config p.ops.ops_vrt .fld_multilang,
.form_config .box_tip_fld p.ops .fld_multilang {
  display: block;
}

.box_tip_fld .ops_btns {
  margin: 0 0 16px 0;
  display: flex;
  justify-content: flex-end;
}

.box_tip_fld .ops_btns button.btn_conmute {
  padding: 8px 16px;
  height: 32px;
}

.box_tip_fld .ops_btns button.btn-tertiary.btn_config {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  height: 32px;
  border: 1px solid transparent;
  margin-right: 6px;
}
.box_tip_fld .ops_btns button.btn-tertiary.btn_config i {
  margin-right: 8px;
}

.import_generic_fin {
  font-size: 16px;
  color: #2E383A;
  margin-bottom: 40px;
}
.import_generic_fin #imprt_fin_title {
  margin: 0 0 8px 0;
}
.import_generic_fin p {
  display: flex;
  align-items: center;
}
.import_generic_fin i {
  font-size: 24px;
  margin-right: 8px;
}
.import_generic_fin i.sl-icon-check {
  color: #3D9487;
}
.import_generic_fin i.sl-icon-close {
  color: #653600;
}
.import_generic_fin span.legacy-h7 {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
}
.import_generic_fin span.legacy-h7 i {
  font-size: 24px;
  line-height: 24px;
}

.form-import-options-checks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1px;
}
.form-import-options-checks .col-lg-12.col-sm-6.col-xs-12 {
  padding-left: 0;
}
.form-import-options-checks .checkbox, .form-import-options-checks .radio {
  margin-bottom: 8px;
}

/*

	## Caja login y registro

*/
.modal .box_login,
.modal .box_warning {
  position: relative;
  left: auto;
  top: auto;
  margin: 20px auto;
}

.modal .box_warning {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal .box_warning .logo {
  display: none;
}

.box_register,
.box_register.size_long {
  position: relative;
  width: 564px;
  margin: 92px auto 0;
}

.box_register .logo {
  position: relative;
  width: 194px;
  margin: 0 auto 48px auto;
  text-align: center;
}

.box_register .logo img {
  width: 194px;
}

.box_register .box_form {
  position: relative;
  width: 100%;
  display: block;
  border-radius: 8px;
  padding: 88px 48px;
  background-color: #FFFFFF;
  text-align: center;
}
.box_register .box_form h2 {
  font-size: 29px;
  font-weight: 700;
  line-height: 36px;
  color: #2E383A;
  margin: 0 0 20px 0;
}
.box_register .box_form p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 24px 0;
  color: #4C6569;
}
.box_register .box_form button {
  margin-top: 8px;
}
.box_register .box_form #mssg_bt {
  margin: 32px 0 0 0;
}
.box_register .box_form strong {
  font-weight: 700;
}

.box_login.size_long {
  width: 410px;
}

.box_login .logo,
.box_input_medium .logo,
.box_warning .logo {
  position: relative;
  overflow: hidden;
  width: 169px;
  margin: 80px auto 32px auto;
  text-align: center;
}

.box_login .logo,
.box_warning .logo {
  margin-top: 0;
}

.box_login .box_form {
  position: relative;
  width: 100%;
  display: block;
  padding: 40px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.9);
  _background: #f9fafc;
}

.box_login .image_code {
  padding-bottom: 20px;
}

.box_login .box_info,
.box_register .box_info {
  position: relative;
  display: block;
  width: 100%;
}

.box_login .box_info.pos_bottom,
.box_login .box_info.min,
.box_login .box_info.min p,
.box_register .box_info.pos_bottom,
.box_register .box_info.min,
.box_register .box_info.min p {
  position: relative;
  margin-top: 16px;
  max-width: 100%;
  width: auto;
  text-align: left;
  color: #4C6569;
  font-size: 14px;
  line-height: 18px;
}

.box_login .box_info.pos_top,
.box_register .box_info.pos_top {
  margin: 0 0 20px 0;
}

.box_warning {
  margin-top: -200px;
  width: 80%;
  margin-left: -40%;
}

.box_warning .box_info {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: 40px auto 0;
}

.form-control + .input-icon {
  background: transparent;
}

.box_login.hmax {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  margin: 0;
  overflow: auto;
}

.modal .box_login.hmax {
  position: relative;
}

.box_login.hmax .box_form {
  width: 320px;
  margin: 0 auto 40px;
}

.box_login.hmax.size_long .box_form {
  width: 410px;
}

.box_success {
  top: 30%;
}

.box_register {
  position: relative;
  width: 320px;
  margin: 20px auto 0;
}

.box_login .box_form,
.box_register .box_form {
  position: relative;
  width: 100%;
  display: block;
  padding: 40px;
  border-radius: 6px;
  background: #fff;
  _background: #f9fafc;
  border: 1px solid #D5DFE1;
}

hr.box-login-separator {
  margin: 32px 0px 32px 0px;
  border: 0;
  border-top: 1px solid #eee;
}

.external-providers {
  margin: 16px 0px 0px 0px;
}

.btn-access {
  text-transform: capitalize;
}

.btn-ext-provider-container {
  margin: 10px 0px 10px 0px;
  width: 100%;
}

.btn-ext-provider {
  background: transparent;
  border-radius: 4px;
  width: 100%;
  color: #2E383A;
  border-color: #D5DFE1 !important;
}

.btn-ext-provider-text {
  margin-left: 10px;
  font-family: Lato;
  color: #34495E;
}

.btn-frm-bottom {
  position: relative;
  margin: 43px 0px 3px 0px;
}

.forgotten-password-text {
  margin-top: 9px;
  font-size: 14px;
  text-align: right;
}

.still-no-account-text {
  text-align: center;
  margin-top: 32px;
}

.checkbox.checked, .radio.checked {
  color: #16a085;
}

.alert-error-login {
  background: #FFEEED;
  border: 0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.4;
}

div.grecaptcha-badge {
  display: block;
}

.box_input_medium .form {
  background-color: transparent;
  display: flex;
  justify-content: center;
  margin-top: 84px;
}
.box_input_medium .form .box_form p {
  color: #2E383A;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 60px;
}
.box_input_medium .form form.mgbform div#error_div {
  margin: 0 0 24px 0;
}

#logo_reg {
  height: 80px;
  display: flex;
  align-items: center;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 44px;
  width: 120px;
}
#logo_reg img {
  width: 117px;
}

form[name=users] h4.title {
  color: #2E383A;
}

@media (max-height: 760px) {
  .box_login.hmax {
    align-items: start !important;
    margin-top: 40px;
  }
}
.box_list.box_list_global_quality {
  padding: 44px 20px 0px 44px;
}
.box_list.box_list_global_quality .box_dashb {
  padding: 24px 32px 32px 32px;
  max-height: unset;
  min-height: unset;
  height: 231px;
}
.box_list.box_list_global_quality .box_dashb h3 {
  padding: 0;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
.box_list.box_list_global_quality .box_dashb .box_info h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2E383A;
}
.box_list.box_list_global_quality .box_dashb .box_info h6 a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_list.box_list_global_quality .box_dashb .box_info h6 small i {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2E383A;
}
.box_list.box_list_global_quality .box_dashb .box_info .progress {
  margin: 4px 0 32px 0;
}
.box_list.box_list_global_quality .box_dashb .box_info h4.msg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E383A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
}
.box_list.box_list_global_quality .box_dashb .box_info h4.msg i {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}
.box_list.box_list_global_quality .box_dashb .box_info .sub_bx_group {
  display: flex;
  justify-content: space-between;
}
.box_list.box_list_global_quality .box_dashb .box_info .sub_bx_group .sub_bx strong {
  font-size: 16px;
  line-height: 24px;
}
.box_list.box_list_global_quality .box_dashb .box_info .sub_bx_group .sub_bx.st_V,
.box_list.box_list_global_quality .box_dashb .box_info .sub_bx_group .sub_bx.st_I,
.box_list.box_list_global_quality .box_dashb .box_info .sub_bx_group .sub_bx.st_D {
  color: #2E383A;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity {
  height: 373px;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity h3 {
  margin-bottom: 22px;
  align-items: center;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity h3 small {
  color: #2E383A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart {
  padding: 0;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.left {
  margin: 0 32px 0 0;
  width: unset;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.left span.legacy-h7 {
  text-transform: uppercase;
  color: #2E383A;
  font-weight: 400;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.left #chart_usr_act {
  border: none;
  margin: 22px 0 0 0;
  display: flex;
  justify-content: center;
  width: unset;
  height: unset;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.left #chart_usr_act .chartjs-render-monitor {
  width: 159px !important;
  height: 159px !important;
  margin: 0;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.all_right span.legacy-h7 {
  text-transform: uppercase;
  color: #2E383A;
  font-weight: 400;
}
.box_list.box_list_global_quality .box_dashb.box_dashb_activity .box_info.chart .chart_sklt.all_right #chart_usr_iter {
  margin-top: 20px;
  height: 235px;
}
.box_list.box_list_global_quality .sklt.mxm {
  padding: 0 24px 24px 0;
}

.qlty_evolution_chart {
  height: auto !important;
}

.box_head_sec h1.sl-h5-bold {
  color: #2E383A;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.box_head_sec.box_head_sec_qrt {
  margin-bottom: 40px;
}

.box_prgph.box_prgph_summary .box_head_sec {
  display: flex;
  align-items: center;
  padding: 0px 44px 0px 44px;
}
.box_prgph.box_prgph_summary .box_head_sec h2 {
  font-size: 18px;
  margin-right: 18px;
}
.box_prgph.box_prgph_summary .box_head_sec h3 {
  display: flex;
  align-items: center;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress {
  width: 235px;
  margin-bottom: 0;
  background-color: #EAEEF0;
  margin: 0 18px 0 0;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress .progress-bar-warning {
  background-color: #F28790;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress .progress-bar-danger {
  background-color: #FFD9A0;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress .progress-bar-info {
  background-color: #3BC693;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress .progress-bar-success {
  background-color: #A9DAD1;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress_percentage {
  display: flex;
  align-items: center;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress_percentage span {
  display: flex;
  align-items: baseline;
  color: #2E383A;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 16px;
}
.box_prgph.box_prgph_summary .box_head_sec h3 .progress_percentage span strong {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 4px;
}
.box_prgph.box_prgph_summary .box_list {
  padding: 24px 24px 44px 44px;
}
.box_prgph.box_prgph_summary .box_list .box_dashb {
  padding: 24px 32px 32px 32px;
  max-height: unset;
  min-height: unset;
  height: 231px;
}
.box_prgph.box_prgph_summary .box_list .box_dashb h3 {
  padding: 0;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2E383A;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info h6 a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info h6 small i {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2E383A;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .progress {
  margin: 4px 0 32px 0;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info h4.msg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E383A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info h4.msg i {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group {
  display: flex;
  justify-content: space-between;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx strong {
  font-size: 16px;
  line-height: 24px;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_V,
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_I,
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_D {
  color: #2E383A;
}
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_V .url_ldg,
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_I .url_ldg,
.box_prgph.box_prgph_summary .box_list .box_dashb .box_info .sub_bx_group .sub_bx.st_D .url_ldg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd {
  width: 100%;
  display: flex;
  padding: 0 24px 0 0;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .box_dashb {
  border: 1px solid #D5DFE1;
  height: 400px;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .box_dashb h3 {
  margin-bottom: 0;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .box_dashb .box_info.chart {
  padding: 0;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .box_dashb .box_info.chart .chart_sklt {
  height: 311px;
  margin-top: 15px;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .box_dashb .box_info.chart .chart_sklt .chart_box {
  height: 311px;
}
.box_prgph.box_prgph_summary .box_chart_dfrrd .sklt.mxm {
  padding: 0 0 24px 0;
}

.box_dashb_activity .chart_sklt {
  width: auto;
}

.box_prgph.box_prgph_qr_channels .box_head_subsec,
.box_prgph.box_prgph_qr_tables .box_head_subsec {
  display: flex;
  align-items: center;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h3,
.box_prgph.box_prgph_qr_tables .box_head_subsec h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #2E383A;
  margin-right: 18px;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #2E383A;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress {
  width: 235px;
  margin: 0 18px 0 0;
  background-color: #EAEEF0;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress .progress-bar-warning,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress .progress-bar-warning {
  background-color: #F28790;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress .progress-bar-danger,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress .progress-bar-danger {
  background-color: #FFD9A0;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress .progress-bar-info,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress .progress-bar-info {
  background-color: #3BC693;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress .progress-bar-success,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress .progress-bar-success {
  background-color: #A9DAD1;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress_percentage,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress_percentage {
  display: flex;
  align-items: center;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress_percentage span,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress_percentage span {
  display: flex;
  align-items: baseline;
  color: #2E383A;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 16px;
}
.box_prgph.box_prgph_qr_channels .box_head_subsec h4 .progress_percentage span strong,
.box_prgph.box_prgph_qr_tables .box_head_subsec h4 .progress_percentage span strong {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 4px;
}
.box_prgph.box_prgph_qr_channels .box_list,
.box_prgph.box_prgph_qr_tables .box_list {
  padding: 24px 44px 44px 44px;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt,
.box_prgph.box_prgph_qr_tables table .tb_prcnt {
  display: flex;
  align-items: center;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt .progress,
.box_prgph.box_prgph_qr_tables table .tb_prcnt .progress {
  width: 64px;
  margin: 0 8px 0 0;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt .progress .progress-bar-warning,
.box_prgph.box_prgph_qr_tables table .tb_prcnt .progress .progress-bar-warning {
  background-color: #FFD9A0;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt .progress .progress-bar-danger,
.box_prgph.box_prgph_qr_tables table .tb_prcnt .progress .progress-bar-danger {
  background-color: #F28790;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt .progress .progress-bar-info,
.box_prgph.box_prgph_qr_tables table .tb_prcnt .progress .progress-bar-info {
  background-color: #3BC693;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt .progress .progress-bar-success,
.box_prgph.box_prgph_qr_tables table .tb_prcnt .progress .progress-bar-success {
  background-color: #9BE8A3;
}
.box_prgph.box_prgph_qr_channels table .tb_prcnt em,
.box_prgph.box_prgph_qr_tables table .tb_prcnt em {
  color: #46637F;
}
.box_prgph.box_prgph_qr_channels table .tb_field,
.box_prgph.box_prgph_qr_tables table .tb_field {
  font-weight: 600;
}
.box_prgph.box_prgph_qr_channels table .td_user_plus,
.box_prgph.box_prgph_qr_tables table .td_user_plus {
  font-weight: 600;
  margin-left: 4px;
}
.box_prgph.box_prgph_qr_channels table .bt_fld_delete,
.box_prgph.box_prgph_qr_tables table .bt_fld_delete {
  margin: 0px 82%;
}
.box_prgph.box_prgph_qr_channels table .bt_fld_delete i,
.box_prgph.box_prgph_qr_tables table .bt_fld_delete i {
  font-size: 16px;
  line-height: 16px;
  color: #CFDADD;
}

.box_prgph_qr_tables .paragrh {
  padding-left: 44px;
}

/* Dahboard Connect */
.box_dashb_connect .box_info {
  display: flex;
  /*justify-content: space-between;*/
}

.box_dashb_connect .box_info.center .box_cnt_elm {
  padding-right: 30px;
  border-right: 1px solid #D5DFE1;
  margin-right: 30px;
}

.box_dashb_connect .box_info.center .box_cnt_elm:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.box_dashb_connect .box_info.center .box_cnt_elm .num {
  display: flex;
  height: 30px;
  font-weight: 300;
}

.box_dashb_connect .box_cnt_elm .num b,
.box_dashb_connect .box_cnt_elm .num strong {
  font-weight: 600;
  font-size: 29px;
  margin-right: 10px;
}

.box_dashb_connect .box_info.center .box_cnt_elm .back {
  top: -3px;
}

.box_dashb_connect .box_info .chart_highlights_left {
  position: relative;
  float: left;
  max-width: 175px;
  margin-right: 20px;
}

.box_dashb_connect .box_info .chart_highlights_left ul {
  padding: 0;
}

.box_dashb_connect .box_info .chart_highlights_left li {
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-transform: capitalize;
  color: #7B9BA1;
  border-left: 2px solid #D5DFE1;
  padding: 5px 0 5px 10px;
  margin: 0;
}

.box_dashb_connect .box_info .chart_highlights_left li strong {
  display: block;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  color: #2E383A;
}

.box_dashb_connect .box_info .chart_highlights_left li.active {
  color: #2E383A;
  border-left-color: #9DB2FF;
}

.box_dashb_connect .box_info .chart_highlights_left li.active strong {
  color: #9DB2FF;
}

.box_dashb_connect .box_info .chart_sklt.all_right {
  margin-top: 10px;
}

.form table .btn-group.select {
  margin: 0;
}

.form table .btn-group.select button.dropdown-toggle {
  background: transparent;
}

.form table .btn-group.select button.dropdown-toggle:hover {
  background: rgba(0, 0, 0, 0.05);
}

.form table .dropdown-menu a:hover {
  cursor: pointer;
}

.form table td.td_numb {
  text-align: right;
}

/* Modal order detail */
#form_connect td .btn-group.select button.dropdown-toggle,
#form_connect td button.btn-clean {
  padding: 0 6px;
}

#form_connect td button.btn-clean:hover,
#form_connect td button.btn-clean:focus,
#connect_order_list td button.btn-clean:hover,
#connect_order_list td button.btn-clean:focus,
#modalStatus button.btn-clean:hover,
#modalStatus button.btn-clean:focus {
  color: rgba(0, 0, 0, 0.8);
  cursor: default;
}

#form_connect label.checkbox .first-icon {
  z-index: 1;
}

#head_connect_order td {
  color: #2E383A;
  border: 0 !important;
}
#head_connect_order td .dropdown-toggle {
  padding: 0;
}

#head_connect_order tr:first-child td {
  color: #4C6569;
  font-weight: normal;
  height: 0;
  padding: 16px 16px 0 16px;
}

#head_connect_order tr:last-child td {
  height: 0;
  padding: 0 16px 8px 16px;
}

#head_connect_order td strong {
  font-weight: 600;
}

#head_connect_order_detail table {
  margin-bottom: 0;
}

#head_connect_order_detail td {
  text-align: left;
}

#connect_order_list .table td.td_opts,
#form_connect .table td.td_opts {
  width: auto;
  vertical-align: middle;
  text-align: right;
  padding: 8px 16px;
}

#connect_order_list .table td.td_opts button.btn,
#form_connect .table td.td_opts button.btn {
  padding: 0;
  height: auto;
}

#form_connect .table td.td_ref,
#form_connect .table td.td_title,
#form_connect .table td.td_date,
#form_connect .table td.td_status {
  text-align: left;
}

#head_connect_order_detail td.td_numb,
#form_connect .table td.td_numb,
#form_connect .table td.td_right {
  text-align: right;
}

#form_connect .table td.td_numb .sl-icon-arrowUp::before,
#form_connect .table td.td_numb .sl-icon-arrowDown::before {
  font-size: 16px;
  vertical-align: bottom;
}

#form_connect .table td.td_title {
  display: flex;
  align-items: center;
}

#form_connect .table td.td_title .thumb {
  margin-right: 20px;
  width: 40px;
  height: auto;
}

#head_connect_order_detail td.sl-card-channel-header-logo {
  padding-right: 0;
}

.sl-card-channel-header-logo img {
  height: 32px;
  width: auto;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  outline: 0;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  width: fit-content;
  height: 40px;
}
.btn i {
  font-weight: normal;
  font-size: 16px;
}
.btn i[class*=sl-icon-].sl-icon-formula {
  font-weight: normal;
  font-size: 20px;
  margin-right: 8px;
}
.btn-primary, .btn-primary:focus, .btn-primary:active {
  background-color: #387B77;
  color: #ffffff;
  border-color: transparent;
}
.btn-primary:hover, .btn-primary:focus:hover, .btn-primary:active:hover {
  background-color: #3D9487;
  border: 1px solid transparent;
}
.btn-inverse, .btn-default {
  background-color: #D5DFE1;
  color: #2E383A;
  border-color: transparent;
}
.btn-inverse:focus, .btn-default:focus {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent !important;
}
.btn-inverse:active, .btn-default:active {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent !important;
}
.btn-inverse:hover, .btn-default:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent !important;
}
.btn-inverse[disabled], .btn-default[disabled] {
  background-color: #EAEEF0;
  color: #93AFB4;
  cursor: default;
  opacity: 1;
  border-color: transparent;
}
.btn-danger {
  background-color: #FDEDED;
  color: #D2322C;
}
.btn-danger:hover {
  color: #D2322C;
  background-color: #FAD7D6;
  border: 1px solid transparent;
}
.btn-danger:focus {
  color: #D2322C;
  background-color: #FDEDED;
  border: 1px solid #3EAC93;
}
.btn-warning {
  background-color: #D5DFE1;
  color: #2E383A;
}
.btn-warning:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}
.btn-warning:focus {
  background-color: #D5DFE1;
  color: #2E383A;
  border: 1px solid #3EAC93;
  border-radius: 4px;
}
.btn-tertiary, .btn-tertiary:focus, .btn-tertiary:active {
  background-color: transparent;
  color: #2E383A;
}
.btn-tertiary:hover, .btn-tertiary:focus:hover, .btn-tertiary:active:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}
.btn-sm {
  height: 40px;
}
.btn-small {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  height: 32px;
}
.btn-small i {
  font-size: 16px;
  font-weight: 400;
}
.btn-xs {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  height: 24px;
}
.btn-xs i {
  font-size: 14px;
  font-weight: 400;
}
.btn-xl {
  padding: 6px 10px;
}
.btn-xl i {
  font-size: 20px;
}
.btn .icon-left {
  margin-right: 4px;
}

.input-group-btn .btn-default {
  background-color: #D5DFE1;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #D5DFE1;
}
.input-group-btn .btn-default:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid #EAEEF0;
}
.input-group-btn .btn-default:focus {
  background-color: #D5DFE1;
  color: #2E383A;
  border: 1px solid #D5DFE1;
}
.input-group-btn .btn-default:active {
  background-color: #CFDADD;
  color: #2E383A;
  border: 1px solid #CFDADD;
}
.input-group-btn .btn-default.sl-btn-icon-only {
  padding: 10px;
}

.btn-wide {
  min-width: unset;
}

.btn-group > .dropdown-menu, .btn-group > .popover {
  font-weight: 600;
}

.input-group-btn:last-child > .btn {
  border-left-width: 1px;
}

.input-group-btn > .btn-default + .btn-default {
  border-left: 1px solid #D5DFE1;
}

.input-group.focus .input-group-btn .btn,
.form-group.focus .input-group-btn .btn {
  border-color: #3EAC93;
  color: #FFFFFF;
}
.input-group.focus .input-group-btn .btn:hover,
.form-group.focus .input-group-btn .btn:hover {
  background-color: #3D9487;
  border-color: #3D9487;
}

.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
  background-color: #D5DFE1;
  border-color: #D5DFE1;
  color: #4C6569;
}

.input-group.focus .input-group-btn .btn:hover {
  background-color: #EAEEF0;
  border-color: #EAEEF0;
  color: #4C6569;
}

.input-group .form-control:first-child {
  border-right-width: 1px;
}

.input-group-btn:last-child > .btn {
  margin-left: 0px;
}

.btn-group.open .dropdown-toggle {
  color: #2E383A !important;
}

.btn-group.open.select.md_highlight button.dropdown-toggle {
  color: white !important;
}

.btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background: #CFDADD;
  border-color: #3EAC93;
  color: #2E383A;
}

.input-group.focus .input-group-btn .btn span {
  border-color: #3EAC93;
  color: #2E383A;
}

.mrm, .mhm, .mam {
  margin-right: unset !important;
}

.fui-checkbox-unchecked:before {
  font-family: "sally-icon";
  content: "\e91a" !important;
  color: #3D9487 !important;
}

.fui-checkbox-checked:before {
  font-family: "sally-icon";
  content: "\e91b" !important;
}

.fui-radio-unchecked,
.fui-radio-checked {
  position: relative;
}

.fui-checkbox-unchecked:before {
  font-family: "sally-icon";
  content: "\e91a" !important;
  color: #CFDADD !important;
}

.fui-checkbox-checked:before {
  font-family: "sally-icon";
  content: "\e91b" !important;
}

.fui-radio-unchecked:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border: solid 1px #D5DFE1;
  border-radius: 50%;
  left: 0;
  top: calc(50% + 3px);
}

.radio:not(.disabled):hover .fui-radio-unchecked,
.radio:not(.disabled):hover .fui-radio-checked {
  transition: color 0.25s linear;
  color: #3D9487 !important;
}

.radio:not(.disabled):hover .fui-radio-unchecked:before,
.radio:not(.disabled):hover .fui-radio-checked:before {
  transition: color 0.25s linear;
  border-color: #3D9487 !important;
}

.radio.disabled .fui-radio-unchecked,
.radio.disabled .fui-radio-checked {
  color: #EAEEF0 !important;
}

.radio.disabled .fui-radio-unchecked:before,
.radio.disabled .fui-radio-checked:before {
  border-color: #EAEEF0 !important;
}

.radio.disabled .fui-radio-checked:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #ABC0C4;
  border: solid 4px #EAEEF0;
  border-radius: 50%;
  color: #EAEEF0;
  left: 0;
  top: calc(50% + 3px);
}

.radio.disabled .fui-radio-unchecked:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #EAEEF0;
  border: solid 0px #EAEEF0;
  border-radius: 50%;
  left: 0;
  top: calc(50% + 3px);
}

.fui-radio-unchecked {
  color: #CFDADD !important;
}

.fui-radio-checked {
  color: #387B77 !important;
}

.fui-radio-checked:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border: solid 4px #387B77;
  border-radius: 50%;
  left: 0;
  top: calc(50% + 3px);
}

.icons span {
  font-size: 20px !important;
  line-height: unset;
}

.checkbox,
.radio {
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  font-size: 14px;
  line-height: 1.5;
}

.checkbox {
  line-height: 1.4;
}

.checkbox input,
.radio input {
  outline: none !important;
  display: none;
}

.checkbox .icons,
.radio .icons {
  color: #CFDADD;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  text-align: center;
  line-height: 21px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}

.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  display: inline-table;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}

.checkbox .icons .second-icon,
.radio .icons .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}

.checkbox:hover,
.radio:hover {
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}

.radio:hover .first-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.radio:hover .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}

.checkbox:hover .first-icon:before,
.checkbox:hover .second-icon:before {
  color: #3D9487 !important;
}

.checkbox:focus .first-icon::before {
  color: #3EAC93;
}

.checkbox.checked:focus .second-icon {
  z-index: -1;
}

.checkbox.checked,
.radio.checked {
  color: #2E383A;
}

label.checkbox:before {
  background: white;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  content: "";
}

label.checkbox.checked:before {
  background: transparent;
}

label.checkbox.disabled:before,
label.checkbox.disabled:hover:before {
  background: #EAEEF0;
}

label.checkbox.checked.disabled:before,
label.checkbox.checked.disabled:hover:before {
  background: #ABC0C4;
  z-index: 1;
}

label.checkbox.disabled .first-icon:before,
label.checkbox.checked.disabled .second-icon:before,
label.checkbox.checked.disabled:hover .second-icon:before {
  color: #EAEEF0 !important;
}

label.checkbox.disabled:hover .first-icon:before {
  color: #3EAC93 !important;
}

label.checkbox .first-icon {
  z-index: 3;
}

label.checkbox .second-icon {
  z-index: 2;
}

label.checkbox.disabled:hover .first-icon,
label.checkbox.checked .first-icon {
  opacity: 0;
}

label.checkbox.checked:focus .first-icon {
  opacity: 1;
}

.radio.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}

.checkbox.checked .second-icon,
.radio.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #387B77;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}

.radio.disabled {
  cursor: default;
  color: #e6e8ea;
}

.radio.disabled .icons {
  color: #e6e8ea;
}

.radio.disabled .first-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.radio.disabled .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}

.radio.disabled.checked .icons {
  color: #e6e8ea;
}

.radio.disabled.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}

.radio.disabled.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #e6e8ea;
}

.checkbox.disabled.checked .second-icon,
.checkbox.disabled .first-icon,
.checkbox.disabled .second-icon {
  color: #EAEEF0;
}

.checkbox.disabled.checked .second-icon,
.checkbox.disabled .first-icon,
.checkbox.disabled .second-icon {
  color: #EAEEF0;
}

.checkbox.primary .icons,
.radio.primary .icons {
  color: #34495e;
}

.checkbox.primary.checked,
.radio.primary.checked {
  color: #387B77;
}

.checkbox.primary.checked .icons,
.radio.primary.checked .icons {
  color: #387B77;
}

.checkbox.primary.disabled,
.radio.primary.disabled {
  cursor: default;
  color: #D5DFE1;
}

.checkbox.primary.disabled .icons,
.radio.primary.disabled .icons {
  color: #D5DFE1;
}

.checkbox.primary.disabled.checked .icons,
.radio.primary.disabled.checked .icons {
  color: #D5DFE1;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}

.border-active {
  border: 1px solid #3D9487 !important;
}

.dropdown-menu {
  min-width: 128px !important;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 12px 0 12px 0;
}

.box_pagination.box_list_ops .btn-group.select .dropdown-menu {
  max-width: 300px;
}

.dropdown-menu li:first-child > a {
  border-radius: 0;
}

.dropdown-menu li > a {
  display: flex;
  font-size: 14px;
  padding: 8px 16px;
  margin: 0 8px;
  white-space: nowrap;
  align-items: center;
  height: 40px;
}
.dropdown-menu li > a i {
  font-size: 20px;
  line-height: 20px;
}

.dropdown-menu li > a > span.pull-left {
  display: flex;
  align-items: center;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:active,
.dropdown-menu li > a:focus,
.dropdown-menu li > a:hover i,
.dropdown-menu li > a:active i,
.dropdown-menu li > a:focus i,
.box_list_ops .btn-group.select .dropdown-menu li a:hover i,
.box_list_ops .btn-group.select .dropdown-menu li a:active i,
.box_list_ops .btn-group.select .dropdown-menu li a:focus i {
  background-color: #F8F8F8;
  color: #3D9487;
  border-radius: 4px;
}

.dropdown-menu li > a.sl-button-highlight-medium {
  color: #5A4B35;
}
.dropdown-menu li > a.sl-button-highlight-medium:focus, .dropdown-menu li > a.sl-button-highlight-medium:hover, .dropdown-menu li > a.sl-button-highlight-medium:active {
  background-color: #F7EABB;
  color: #5A4B35;
  border-color: transparent;
  border-radius: 4px;
}
.dropdown-menu li > a.sl-button-highlight-medium i {
  background-color: transparent;
  color: #5A4B35;
  border-color: transparent;
  border-radius: 4px;
}

.dropdown-menu li > a.sl-button-highlight-medium,
.dropdown-menu li:last-child > a.sl-button-highlight-medium {
  border-radius: 4px;
}

.dropdown-menu li h5 {
  margin: 4px 0px 4px 8px;
}

.dropdown-menu li h6 {
  margin: 4px 0px 16px 8px;
}

.pagination ul {
  display: flex;
  background: #EAEEF0;
  background: #fff;
  border: 1px solid #D5DFE1;
}
.pagination ul li {
  margin-right: 0px;
  border-right: 1px solid #D5DFE1;
}
.pagination ul li:first-child {
  border-left: none;
}
.pagination ul li:last-child {
  border-left: none;
  border-right: none;
}

.pagination ul li:first-child > a, .pagination ul li:first-child > span {
  border-radius: 4px 0 0 4px;
}

.pagination ul li:last-child > a, .pagination ul li:last-child > span, .pagination ul li:last-child > a:hover, .pagination ul li:last-child > span:hover, .pagination ul li:last-child > a:focus, .pagination ul li:last-child > span:focus {
  border-radius: 0 4px 4px 0;
}

.pagination ul li > a, .pagination ul li > span {
  color: #2E383A;
  min-height: unset;
  border-left: none;
  min-width: 39px;
  height: 38px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.pagination ul li > a:hover,
.pagination ul li > span:hover,
.pagination ul li > a:focus,
.pagination ul li > span:focus,
.pagination ul li.active > a:hover,
.pagination ul li.active > span:hover,
.pagination ul li.active > a:focus,
.pagination ul li.active > span:focus {
  color: #2E383A;
  background-color: #EAEEF0;
}

.pagination ul li.active > a, .pagination ul li.active > span {
  background-color: #D5DFE1;
  color: #2E383A;
  border-radius: unset;
}

.progress-bar-warning {
  background-color: #FFD9A0;
}

a {
  color: unset;
}

a:hover,
a:focus {
  color: unset;
}

.select .btn .filter-option {
  height: unset;
  left: unset;
  overflow: unset;
  position: unset;
  right: unset;
  text-align: unset;
  top: unset;
}

.nav-tabs {
  margin-bottom: 20px;
  border: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  padding: 6px 16px 0 0;
  display: flex;
  align-items: center;
}

.nav-tabs > li {
  margin-bottom: -3px;
  border-bottom: 2px solid transparent;
  padding-right: 0;
}

.nav-tabs > li > a {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  color: #4C6569;
  border-width: 2px;
  font-weight: normal;
}

.nav-tabs > li:hover > a {
  color: #2E383A;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #3D9487;
  background: none;
}

.nav-tabs > li.disabled.disabledTab {
  cursor: not-allowed;
}

.nav > li.disabled > a,
.nav > li.disabled > a:hover {
  color: #ABC0C4;
}

.crumbs .nav-tabs > li.active,
.crumbs .nav-tabs > li.active:hover,
.crumbs .nav-tabs > li.active:focus {
  border-color: transparent;
  border-bottom: 2px solid #3D9487;
  color: #3D9487;
  background: transparent;
  font-weight: normal;
}

.nav-tabs > li.active,
.nav-tabs > li.active:hover,
.nav-tabs > li.active:focus {
  border-color: transparent;
  border-bottom: 2px solid #3D9487;
  color: #3D9487;
  background: transparent;
  font-weight: normal;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border: none;
  color: #3D9487;
  background: transparent;
  font-weight: normal;
}

.modal-content .modal-body .nav-accordion,
.form .sl_form_edit_group ul.nav-accordion,
.nav-accordion {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  background-color: white;
  padding: 8px 0;
  color: #2E383A;
}
.modal-content .modal-body .nav-accordion + .page_fields,
.form .sl_form_edit_group ul.nav-accordion + .page_fields,
.nav-accordion + .page_fields {
  padding: 0 16px;
}
.modal-content .modal-body .nav-accordion.quick_edit_form,
.form .sl_form_edit_group ul.nav-accordion.quick_edit_form,
.nav-accordion.quick_edit_form {
  margin-bottom: 16px;
}
.modal-content .modal-body .nav-accordion .makefield_ops.dropdown,
.form .sl_form_edit_group ul.nav-accordion .makefield_ops.dropdown,
.nav-accordion .makefield_ops.dropdown {
  margin-left: -8px;
}
.modal-content .modal-body .nav-accordion.nav-tabs > li,
.form .sl_form_edit_group ul.nav-accordion.nav-tabs > li,
.nav-accordion.nav-tabs > li {
  padding: 0;
  margin-right: 0px;
}
.modal-content .modal-body .nav-accordion.nav-tabs > li a,
.form .sl_form_edit_group ul.nav-accordion.nav-tabs > li a,
.nav-accordion.nav-tabs > li a {
  padding: 0 16px;
}
.modal-content .modal-body .nav-accordion.nav-tabs > li a .sl-icon-chevron-up, .modal-content .modal-body .nav-accordion.nav-tabs > li a .sl-icon-chevron-down,
.form .sl_form_edit_group ul.nav-accordion.nav-tabs > li a .sl-icon-chevron-up,
.form .sl_form_edit_group ul.nav-accordion.nav-tabs > li a .sl-icon-chevron-down,
.nav-accordion.nav-tabs > li a .sl-icon-chevron-up,
.nav-accordion.nav-tabs > li a .sl-icon-chevron-down {
  font-size: 24px;
}
.modal-content .modal-body .nav-accordion.nav-tabs > li a label,
.form .sl_form_edit_group ul.nav-accordion.nav-tabs > li a label,
.nav-accordion.nav-tabs > li a label {
  font-weight: 600;
}
.modal-content .modal-body .nav-accordion > li,
.form .sl_form_edit_group ul.nav-accordion > li,
.nav-accordion > li {
  width: 100%;
  margin-bottom: -2px;
}
.modal-content .modal-body .nav-accordion > li a,
.form .sl_form_edit_group ul.nav-accordion > li a,
.nav-accordion > li a {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}
.modal-content .modal-body .nav-accordion > li a button,
.form .sl_form_edit_group ul.nav-accordion > li a button,
.nav-accordion > li a button {
  flex: 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal-content .modal-body .nav-accordion > li a button i,
.form .sl_form_edit_group ul.nav-accordion > li a button i,
.nav-accordion > li a button i {
  font-size: 24px;
  color: #2E383A;
  padding: 0 !important;
  margin: 0 !important;
}
.modal-content .modal-body .nav-accordion > li a button.btn-clean.check,
.form .sl_form_edit_group ul.nav-accordion > li a button.btn-clean.check,
.nav-accordion > li a button.btn-clean.check {
  margin-right: 0px;
  min-height: 40px;
  position: relative;
}
.modal-content .modal-body .nav-accordion > li a label,
.form .sl_form_edit_group ul.nav-accordion > li a label,
.nav-accordion > li a label {
  margin: 0 !important;
}
.modal-content .modal-body .nav-accordion > div,
.form .sl_form_edit_group ul.nav-accordion > div,
.nav-accordion > div {
  padding-right: 16px;
}
.modal-content .modal-body .nav-accordion > li.active,
.modal-content .modal-body .nav-accordion > li.active:hover,
.form .sl_form_edit_group ul.nav-accordion > li.active,
.form .sl_form_edit_group ul.nav-accordion > li.active:hover,
.nav-accordion > li.active,
.nav-accordion > li.active:hover {
  border-bottom: none;
}
.modal-content .modal-body .nav-accordion:hover,
.form .sl_form_edit_group ul.nav-accordion:hover,
.nav-accordion:hover {
  background-color: #fff;
  border: 1px solid #CFDADD;
}
.modal-content .modal-body .nav-accordion.is_open,
.form .sl_form_edit_group ul.nav-accordion.is_open,
.nav-accordion.is_open {
  border-color: #3EAC93;
  border-bottom-color: #3EAC93 !important;
}

.alert-container-blank {
  background-color: #fff;
  padding: 20px 44px 0 44px;
  margin-bottom: -20px;
}
.alert-container-blank.alert-container-blank-no-gutters {
  margin-bottom: 0;
  padding-top: 0;
}

.alert {
  border-radius: 4px;
  padding: 16px 48px 16px 16px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
}
.alert ul {
  margin-bottom: 0;
}
.alert ul li {
  margin-top: 8px;
  line-height: 24px;
}
.alert .alert-cont {
  display: flex;
  align-items: flex-start;
}
.alert + #slyr_conf_slc_ops + .box_list_max {
  margin-top: 40px;
}
.alert a {
  font-weight: normal;
  text-decoration: underline;
}
.alert h4 {
  font-size: 14px;
  line-height: 24px;
}
.alert .alert-title {
  font-weight: bold;
}
.alert h6 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 4px;
  line-height: 24px;
}
.alert h6:not(.inactive-service--title) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alert.alert-no-alert h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 5px;
}
.alert.alert-no-alert h6 .icon {
  font-size: 20px;
  margin-right: 14px;
}
.alert span.legacy-h7 {
  font-size: 14px;
  line-height: 24px;
}
.alert .icon {
  font-size: 24px;
  line-height: 24px;
  margin-right: 12px;
  font-weight: normal;
}
.alert .icon[class*=sl-icon] {
  position: relative;
  margin-right: 12px;
  min-width: 24px;
}
.alert .icon[class*=sl-icon]:before {
  position: absolute;
  display: contents;
}
.alert .icon.sl-icon-message-round {
  font-size: 32px;
  line-height: 32px;
}
.alert .sl-icon-close {
  font-size: 16px;
}
.alert .close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  line-height: 16px;
}
.alert .close:hover {
  opacity: 1;
}
.alert .btn-close-ok {
  color: #4C6569;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 47px;
  margin-top: 12px;
}
.alert.alert-success {
  border: 1px solid #9BE8A3;
  background-color: #E6FAE8;
  color: #05570A;
}
.alert.alert-success .close,
.alert.alert-success .close:hover {
  color: #05570A;
}
.alert.alert-info {
  border: 1px solid #BAEBF5;
  background-color: #E9F9FB;
  color: #005583;
}
.alert.alert-info .close,
.alert.alert-info .close:hover {
  color: #005583;
}
.alert.alert-info h6.inactive-service--title {
  color: #005583;
}
.alert.alert-warning {
  border: 1px solid #FFD9A0;
  background-color: #FFF1DB;
  color: #653600;
}
.alert.alert-warning .close,
.alert.alert-warning .close:hover {
  color: #653600;
}
.alert.alert-danger {
  border: 1px solid #F7BCBA;
  background-color: #FDEDED;
  color: #55201E;
}
.alert.alert-danger .close,
.alert.alert-danger .close:hover {
  color: #55201E;
}
.alert.alert-error {
  border: 1px solid #F7BCBA;
  background-color: #FDEDED;
  color: #55201E;
}
.alert.alert-error .close,
.alert.alert-error .close:hover {
  color: #55201E;
}
.alert.alert-msg {
  border: 1px solid #EEE5C0;
  background-color: #FCF8ED;
  color: #2E383A;
}

.modal-body .crumbs_formula_conn + .alert .icon.sl-icon-close {
  margin-right: 0;
}

.alert.alert-no-alert {
  border-radius: 0px;
  padding: 0px;
  text-shadow: none;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
}
.alert.alert-no-alert > i.icon.ini.glyphicons.ok {
  display: none;
}
.alert.alert-no-alert > i.sl-icon-info {
  display: none;
}
.alert.alert-no-alert > i.sl-icon-warning {
  display: none;
}
.alert.alert-no-alert > i.sl-icon-error {
  display: none;
}
.alert.alert-no-alert h5 {
  font-size: 20px;
  line-height: 28px;
  color: #2E383A;
}
.alert.alert-no-alert .icon {
  color: #ABC0C4;
}

.dropdown-menu .divider,
.btn-group.select .dropdown-menu .divider {
  max-width: 90%;
  margin: 0 auto;
  background-color: #D5DFE1;
}

.table {
  overflow: auto;
  width: 100%;
  border-collapse: separate;
  border: 1px solid #D5DFE1;
  border-radius: 8px;
  background: white;
}
.table tr th {
  font-size: 12px;
  line-height: 20px;
  color: #4C6569 !important;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 22px 16px;
}
.table > * > tr > td,
.table > * > tr > th {
  padding: 0 16px;
  height: 64px;
  border-top: 1px solid #D5DFE1 !important;
  min-width: 32px !important;
}
.table > thead > tr > th {
  border-bottom: none !important;
  border-top: none !important;
}
.table.sl-mb-600 {
  margin-bottom: 24px !important;
}

.table .label {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  max-height: 24px;
  font-weight: 600;
}

.label-primary,
.label-primary[href],
.label-primary[href]:hover,
.label-inverse,
.label-inverse[href],
.label-inverse[href]:hover {
  background-color: #FBF2D0;
  color: #5A4B35;
}

.label-primary:focus,
.label-primary[href]:focus,
.label-inverse:focus,
.label-inverse[href]:focus {
  background-color: #FBF2D0;
  color: #5A4B35;
}

.breadcrumb-text {
  margin-bottom: 4px;
}
.breadcrumb-text p {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color: #4C6569;
  font-weight: 400;
  margin-bottom: 0;
}
.breadcrumb-text p a:last-child {
  font-weight: 600;
}
.breadcrumb-text p a:last-child::after {
  display: none;
}
.breadcrumb-text p a:after {
  color: #4C6569;
  font-family: "sally-icon";
  content: "\e91e";
  font-weight: 400;
  margin: 0 12px 0 12px;
}

.glyphicons.exclamation-sign:before {
  font-family: "sally-icon";
  content: "\e92d";
}

.dropdown-menu li > a {
  color: #2E383A;
}

.dropdown-menu li.active > a,
.dropdown-menu li.selected > a,
.dropdown-menu li.active > a.highlighted,
.dropdown-menu li.selected > a.highlighted {
  background-color: #F8F8F8;
  color: #3D9487;
}
.dropdown-menu li.active > a:hover, .dropdown-menu li.active > a:active, .dropdown-menu li.active > a:focus,
.dropdown-menu li.selected > a:hover,
.dropdown-menu li.selected > a:active,
.dropdown-menu li.selected > a:focus,
.dropdown-menu li.active > a.highlighted:hover,
.dropdown-menu li.active > a.highlighted:active,
.dropdown-menu li.active > a.highlighted:focus,
.dropdown-menu li.selected > a.highlighted:hover,
.dropdown-menu li.selected > a.highlighted:active,
.dropdown-menu li.selected > a.highlighted:focus {
  background-color: #F8F8F8;
  color: #3D9487;
}

.open > .dropdown-menu {
  margin-top: 8px !important;
}

.col-fld {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #4C6569;
  margin: 0 8px 8px 0;
}

label strong {
  font-weight: 600;
}

.form-control {
  border: 1px solid #D5DFE1;
  color: #2E383A;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  height: 40px;
  border-radius: 4px;
  outline: none;
}

.form-group {
  margin-bottom: 24px;
}

.form-group.focus .form-control,
.form-control:focus {
  border: 1px solid #3EAC93;
}

.form-group.focus .form-control,
.form-control:focus-visible {
  border: 1px solid #3EAC93;
}

.not_apply .form-control {
  background-color: #EAEEF0;
  color: #ABC0C4;
}
.not_apply .form-control:hover {
  border: 1px solid #D5DFE1;
}

.has-error .form-control {
  color: #D2322C;
  border-color: #D2322C;
}

.has-switch > div.switch-on label,
.has-switch > div.switch-off label {
  background-color: white;
  border-radius: 50%;
  top: -2px;
}

.has-switch {
  width: 42px;
  height: 25px;
}

.has-switch > div.switch-on {
  left: -36px;
}

.has-switch > div.switch-off {
  left: -52px;
}

.has-switch > div label {
  border-width: 6px;
  transition: unset;
  cursor: pointer;
}

.has-switch > div.switch-on label {
  border-color: #387B77;
}

.has-switch > div.switch-off label {
  border-color: #ABC0C4;
}

.has-switch span.switch-left {
  background-color: #387B77;
}

.has-switch span.switch-right {
  background-color: #ABC0C4;
}

.has-switch span.switch-left .fui-check:before {
  content: "";
}

.has-switch span.switch-right .fui-cross:before {
  content: "";
}

.has-switch > div.switch-animate {
  transition: unset;
}

.not_valid {
  background: #ffffff !important;
  color: #55201E !important;
  border-color: #D2322C !important;
  margin-left: 0px !important;
}

.input-group .form-control:last-child {
  border-left: none;
}

.tooltip {
  font-size: 14px;
  line-height: 1.286;
}

.tooltip.in {
  opacity: 1;
}

.tooltip.top {
  padding-bottom: 0px;
  margin-top: -8px;
}

.tooltip.top .tooltip-arrow {
  display: none;
}

.tooltip.right .tooltip-arrow {
  display: none;
}

.tooltip.bottom {
  padding-top: 0px;
}

.tooltip.bottom .tooltip-arrow {
  display: none;
}

.tooltip.left .tooltip-arrow {
  display: none;
}

.tooltip-inner {
  background-color: #2E383A;
  color: white;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  width: max-content !important;
  max-width: 240px !important;
  z-index: 900;
  word-break: break-word;
  text-align: left;
}

.tooltip-light .tooltip-inner {
  background-color: #2E383A;
  color: white;
}

.alert-info a {
  color: #005583;
}

@keyframes progress-bar-stripes {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 200% 0;
  }
}
.progress-bar, .progress {
  background: #EAEEF0;
  height: 8px;
  max-width: 480px;
}

.progress-striped .progress-bar {
  background-image: linear-gradient(90deg, rgb(123, 155, 161) 100%, transparent);
}

.progress.active .progress-bar, .progress-bar.active {
  height: 8px;
  border-radius: 4px;
}

.progress-bar-success,
.progress-bar-warning,
.progress-bar-danger,
.progress-bar-info {
  background-color: #9DB2FF;
}

.has-warning .form-control {
  border-color: #F7C983;
}
.has-warning .form-control:focus {
  border-color: #3EAC93;
}

.table .selected-row > td {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr.selected-row > td,
.table-striped > tbody > tr:nth-child(even) > th,
.table-striped > tbody > tr.selected-row > th {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-child(even):hover > td,
.table-striped > tbody > tr.selected-row:hover > td,
.table-striped > tbody > tr:nth-child(odd):hover > td,
.table .selected-row:hover > td {
  background-color: #F8F8F8;
}

.table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 8px;
}

.label-info {
  background-color: #D7F3F9;
  color: #5A4B35;
}

.label-warning {
  background-color: #FBF2D0;
  color: #653600;
}

.label-info-2 {
  background-color: #D7F3F9 !important;
  color: #005583 !important;
}

.label {
  border-radius: 4px;
}

.label-success {
  background-color: #BFF5C5;
  color: #05570A;
}

.label-danger {
  color: #55201E;
  background-color: #F7BCBA;
}

.label-neutral {
  color: #2E383A;
  background-color: #D5DFE1;
}

.btn-inverse:hover, .btn-inverse.hover {
  background: #EAEEF0;
  color: #2E383A;
  border-color: transparent;
}

.btn-inverse.active, .open .dropdown-toggle.btn-inverse, .btn-inverse.active, .open .dropdown-toggle.btn-inverse {
  background: #D5DFE1;
  color: #2E383A;
  border-color: transparent;
}

.btn-inverse:focus {
  background: #D5DFE1;
  color: #2E383A;
  border-color: #3EAC93;
}

.btn-inverse:active {
  background: #CFDADD;
  color: #2E383A;
  border-color: transparent;
}

.iconlight-color {
  color: #CFDADD;
}

.sync_li {
  color: #4C6569;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.sync_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 4px 0 12px 0;
}

.input-group span.select2 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-group.conn_perm_input span.select2 {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

strong {
  font-weight: unset;
}

.input-group {
  width: 100%;
}

.nav-tabs > li > a {
  margin-right: 0;
}

.select2.select2-container.select2-container--bootstrap.select2-container--below,
.select2.select2-container--bootstrap {
  background-color: #ffffff;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  min-height: unset;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding: 0px;
  margin-top: 0;
  box-shadow: none;
}

.select2.select2-container.select2-container--bootstrap.select2-container--below.select2-container--focus {
  border: 1px solid #3EAC93;
}

.select2.select2-container.select2-container--bootstrap.select2-container--below.select2-container--focus.select2-container--open {
  margin-top: unset;
  box-shadow: unset;
}

.select2-container .selection .select2-selection .select2-selection__rendered, .select2-container .select2-dropdown .select2-results ul li, .select2-container .selection .select2-selection ul li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.select2-container--bootstrap.select2-container--open {
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
}

.select2-container--bootstrap > .select2-dropdown {
  background-color: #ffffff;
  line-height: unset;
  background: #ffffff;
  border: none;
}

.select2.select2-container--bootstrap.select2-container--open {
  padding: 0;
}

.select2-container--bootstrap .select2-dropdown .select2-results {
  padding: 0;
  margin: 0;
}

.select2-container--bootstrap .select2-results > .select2-results__options {
  padding: 12px 0 12px 0;
}
.select2-container--bootstrap .select2-results > .select2-results__options li {
  padding: 10px 24px;
  margin: 0 12px;
  color: #2E383A;
}
.select2-container--bootstrap .select2-results > .select2-results__options li {
  padding: 10px 24px;
  margin: 0 12px;
  color: #2E383A;
}
.select2-container--bootstrap .select2-results > .select2-results__options li.select2-results__option.select2-results__option--highlighted, .select2-container--bootstrap .select2-results > .select2-results__options li[aria-selected=true] {
  background-color: #F8F8F8;
  color: #3D9487;
}

.select2-container--bootstrap .select2-selection--multiple {
  padding: 4px 12px 3px 12px;
  height: unset;
  display: flex;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: #FBF2D0;
  color: #5A4B35;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: #5A4B35;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border: 0;
  border-radius: 4px;
  cursor: default;
  float: left;
  /* He modificado margin: 2px 22px 2px 0; por este porque en los listado se muestra mal si ya había un tag seleccionado */
  margin: 2px 8px 2px 0;
  padding: 2px 8px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  font-weight: 400;
  margin-right: 0;
  margin-left: 6px;
  font-size: 0px;
  line-height: 16px;
  height: 16px;
  color: #5A4B35;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove::after {
  font-family: "sally-icon";
  content: "\e922";
  font-size: 16px;
  line-height: 16px;
  height: 16px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #5A4B35;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  padding: 8px 22px 8px 12px;
  font-size: 14px;
  line-height: 24px;
  min-height: 40px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.select2-results__option.select2-results__option[aria-selected=true]:before {
  display: none;
}

.select2-container--bootstrap .select2-results {
  height: fit-content;
}

/*

	## Configuració imatges i arxius ##

*/
.bx_conf_img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bx_conf_img .bx_size {
  position: relative;
  overflow: hidden;
  float: left;
  width: 160px;
  height: 140px;
  margin: 0 5px 20px;
}

.bx_conf_img .sz_img {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.bx_conf_img .sz_img div {
  position: relative;
  margin: 0 auto;
  background: #f8f8f8;
}

.bx_conf_img .sz_img div i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  font-size: 18px;
  line-height: 18px;
  color: #bbb;
}

.bx_conf_img .bx_size i.inf, .bx_conf_img .bx_size i.titl,
.bx_conf_img .bx_image i.inf, .bx_conf_img .bx_image i.titl,
.bx_conf_img .bx_image i.ext {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
}

.bx_conf_img .bx_image i.inf {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
  line-height: 18px;
  white-space: normal;
  color: #4C6569;
}

.bx_conf_img .bx_image i.inf {
  font-size: 12px;
}

.bx_conf_img .bx_size i.titl, .bx_conf_img .bx_image i.titl {
  font-size: 12px;
}

.bx_conf_img .bx_image i.inf a {
  color: #888;
}

.bx_conf_img .bx_size i.inf,
.bx_conf_img .bx_image i.inf,
.bx_conf_img .bx_image i.ext {
  padding-top: 4px;
}

.bx_conf_img .btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  left: 8px;
}

.bx_conf_img .btns.sl-block {
  flex-direction: row;
}

.bx_conf_img .conf-img-resize {
  flex-direction: row;
  margin-top: 32px !important;
}

.bx_conf_img .bx_size.edt {
  float: auto;
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: #f6f6f6;
  padding: 24px;
  margin: 0 0 15px 0;
}

.bx_conf_img .form-group {
  width: auto;
}

.bx_conf_img .bx_size.edt input[disabled] {
  border-color: #ddd;
  color: #555;
}

.bx_conf_img .input-group-sm > .form-control {
  height: 40px;
}

.bx_conf_img .bx_size.edt .btns {
  text-align: right;
  justify-content: flex-end;
}

.bx_conf_img .bx_size.del .sz_img div {
  border-color: #e0e0e0;
  background: #f8f8f8;
}

.bx_conf_img .bx_size.del i.inf, .bx_conf_img .bx_size.del i.titl, .bx_conf_img .bx_size.del i b {
  color: #bbb;
}

.bx_conf_img.md_vm .bx_image:hover i.inf a {
  color: #333;
}

.bx_conf_img .bx_image {
  position: relative;
  float: left;
  margin: 0 22px 22px 0;
}

.bx_conf_img .bx_image .vw_img {
  position: relative;
  overflow: hidden;
  width: 158px;
  height: 132px;
  border: 1px solid #D5DFE1;
  border-radius: 6px;
  background-image: url(../../../img/placeholder.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bx_conf_img.md_vm .bx_image .vw_img {
  width: 100px;
  height: 85px;
}

.bx_conf_img .bx_image .vw_img img {
  width: inherit;
  height: auto;
}

.bx_conf_img.md_vm .bx_image .vw_img img {
  width: 150px;
}

.bx_conf_img.tp_file .bx_image .vw_img img {
  width: 50px;
  height: auto;
}

.bx_conf_img .bx_image .vw_img.ftype {
  text-align: center;
}

.bx_conf_img .bx_image .vw_img span {
  display: block;
  /* padding-top: 60px; */
  font-size: 50px;
  /* line-height: 50px; */
  color: #ccc;
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bx_conf_img.md_vm .bx_image .vw_img span {
  padding-top: 20px;
}

.bx_conf_img .bx_image .vw_img a {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.bx_conf_img .bx_image .vw_img a span {
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.bx_conf_img .bx_image .vw_img a:hover span {
  color: #387B77;
}

.bx_conf_img .bx_image .vw_img span.stat {
  position: absolute;
  top: 3px;
  left: 3px;
  font-size: 13px;
  line-height: 13px;
  padding: 0;
  margin: 0;
  color: #aaa;
}

.bx_conf_img .bx_image.del .vw_img,
.bx_conf_img .bx_image.msng img {
  opacity: 0.3;
}

#box_content .box_head_sec {
  z-index: 900;
}

#box_content .box_search {
  z-index: 800;
}

#box_content .box_list_spc {
  z-index: 750;
  padding: 0 44px 40px 44px;
}

#box_content .box_pagination {
  z-index: 700;
}

#box_content .box_pagination.pbtm {
  z-index: 760;
  margin-top: 24px;
}

#box_content .box_list {
  z-index: 500;
}

.modal-body .box_head_sec {
  padding: 0;
}

.modal-body .box_search { /*overflow: hidden*/ }

.modal-body .box_list,
.modal-body .box_list_ops {
  padding: 20px 0 0;
}

.modal-body .box_list_ops.ptop {
  padding: 8px 0 42px 0;
}

.modal-body .bx_image.disabled {
  display: block;
  opacity: 0.3;
}

.modal-body.no-footer {
  margin-bottom: 10px;
}

form#images_actions .bullet:hover {
  cursor: help;
  background: #444;
}

.selected-file {
  position: absolute;
  left: 8px;
  top: 8px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  height: 16px;
  width: 16px;
}
.selected-file i {
  height: 12px;
}

.selected-file-active {
  background: #387B77;
}

.selected-file-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.selected-file-overlay:hover {
  background: rgba(52, 73, 94, 0.4);
  width: 100%;
  height: 100%;
  transition: 0.5s background;
}

.selected-file-overlay:hover .selected-file-hover {
  background: #F3F6F7;
}

.count-selected-img {
  align-items: center;
  display: flex;
  font-size: 15px;
}

.count-selected-img div {
  align-items: center;
  background: #387B77;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  margin-right: 16px;
  width: 20px;
}
.count-selected-img div i {
  height: 20px;
}

#pop_box_files .btn-group {
  margin: 0px 4px 0px 4px;
}

#pop_box_files .box_cont_rgt .pagination.no_mrgn {
  margin: 0 0 0 4px;
}

.modal-body .box_list.tp_image, .modal-body .box_list.tp_file {
  padding: 32px 0 0 0;
  display: grid; /* 1 */
  grid-template-columns: repeat(auto-fill, 160px); /* 2 */
  grid-gap: 22px; /* 3 */
  justify-content: space-between; /* 4 */
}

.modal-body .box_head_sec h2.num_elements {
  color: #2E383A;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
}

#box_cats .box_list span.img_alert, #box_prods .box_list span.img_alert, #box_frmts .box_list span.img_alert, #box_locs .box_list span.img_alert, #box_regs .box_list span.img_alert {
  display: none;
}
#box_cats .tp_prods .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_cats .tp_cats .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_cats .tp_table .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_prods .tp_prods .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_prods .tp_cats .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_prods .tp_table .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_frmts .tp_prods .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_frmts .tp_cats .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_frmts .tp_table .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_locs .tp_prods .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_locs .tp_cats .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_locs .tp_table .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_regs .tp_prods .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_regs .tp_cats .sl-thumbnail-large-border i.sl-icon-warning-filled, #box_regs .tp_table .sl-thumbnail-large-border i.sl-icon-warning-filled {
  display: none;
}

#box_imgs .box_list .box_elm a, #box_files .box_list .box_elm a {
  position: relative;
}
#box_imgs .box_list span.img_alert, #box_files .box_list span.img_alert {
  position: absolute;
  right: 14px;
  top: 14px;
  color: #D2322C;
}
#box_imgs .box_list span.img_alert i, #box_files .box_list span.img_alert i {
  font-size: 20px;
  height: 18px;
  width: 20px;
  display: block;
  line-height: 18px;
}

.bx_conf_img .bx_image {
  width: 160px;
  height: 163px;
  margin: 0 24px 24px 0;
}
.bx_conf_img .bx_image .vw_img img {
  background-color: #fff;
}
.bx_conf_img .vw_img.ftype {
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bx_conf_img .vw_img.ftype img {
  width: 61px;
  height: auto;
  background-color: #fff;
}

.box_thumb .box_img.box_img_little img {
  background-color: #fff;
}
.box_thumb .js-open-modal {
  cursor: pointer;
}

#pop_box_files .bx_conf_img.tp_file .bx_image .vw_img {
  background-image: none;
}

#pop_box_files .bx_conf_img .bx_image {
  width: auto !important;
  margin: 0;
  height: auto;
  margin-bottom: 24px;
}
#pop_box_files .bx_conf_img .bx_image .inf {
  max-width: 160px;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: #4C6569;
  margin-bottom: 6px;
}
#pop_box_files .bx_conf_img .bx_image .vw_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 130px;
  border: 1px solid #D5DFE1;
  border-radius: 6px;
  background-image: url(../../../img/placeholder.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#pop_box_files .bx_conf_img .bx_image .vw_img img {
  background-color: #fff;
}
#pop_box_files .bx_conf_img .bx_image .vw_img span.stat {
  position: absolute;
  top: 4px;
  right: 0px;
  left: initial;
  padding: 0;
  margin: 0;
}
#pop_box_files .bx_conf_img .bx_image .vw_img span.stat .icon {
  color: #D2322C;
  font-size: 16px;
}
#pop_box_files .bx_conf_img .bx_image i.ext {
  padding-top: 3px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4C6569;
}
#pop_box_files .bx_conf_img .bx_image i.ext b, #pop_box_files .bx_conf_img .bx_image i.ext strong {
  font-weight: 600;
}
#pop_box_files .bx_conf_img .bx_image i.ext .icon {
  margin-right: 4px;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}

#box_imgs .box_list .url_popup {
  position: relative;
}
#box_imgs .box_list .url_popup span.img_alert {
  position: absolute;
  right: 14px;
  top: 14px;
  color: #D2322C;
}
#box_imgs .box_list .url_popup .sl-thumbnail-tiny + span.img_alert {
  right: 2px;
  top: 2px;
}
#box_imgs .box_list .url_popup .sl-thumbnail-tiny + span.img_alert i {
  font-size: 16px;
}

.modal-body .bx_conf_img .bx_image .btns .btn.btn-xs {
  height: 24px;
  padding: 4px;
  margin: 0 0 8px 0;
}
.modal-body .bx_conf_img .bx_image .btns .btn.btn-xs i.icon {
  margin: 0;
  font-size: 16px;
  line-height: 16px;
}

.modal-content .data-loaded .modal-body .bx_conf_img.bx_conf_img_size .bx_size .btns {
  margin-top: 8px;
  position: relative;
  top: 0;
  left: 0;
}
.modal-content .data-loaded .modal-body .bx_conf_img.bx_conf_img_size .bx_size .btn.btn-xs {
  height: 24px;
}
.modal-content .data-loaded .modal-body .bx_conf_img.bx_conf_img_size .bx_size .btn.btn-xs.btn-danger {
  padding: 4px;
}
.modal-content .data-loaded .modal-body .bx_conf_img.bx_conf_img_size .bx_size .btn.btn-xs.btn-danger i.icon.alone {
  font-size: 16px;
  line-height: 16px;
  margin: 0;
}

.box_list_spc .buttons button {
  height: 40px;
}

.box_list_spc #form_add_type,
#qck_edt_ops #form_add_type {
  display: flex;
}
.box_list_spc #form_add_type .form-group,
#qck_edt_ops #form_add_type .form-group {
  display: flex;
}

.box_list_spc #form_add_type.ehide,
#qck_edt_ops #form_add_type.ehide {
  display: none;
}

#form_add_type button {
  height: 40px;
}

/*

    ## Històric

*/
#box_hist_vw {
  z-index: 6;
  display: none;
  position: fixed;
  top: 80px;
  left: 224px;
  bottom: 61px;
  width: 250px;
  border-right: 1px solid #D5DFE1;
  background-color: #FFFFFF;
}

.minus_left #box_hist_vw {
  left: 80px;
  top: 80px;
}

#box_content.hist_vw_act #box_hist_vw {
  display: block;
}

#box_content.hist_vw_act .box_head_sec,
#box_content.hist_vw_act .box_msg_action,
#box_content.hist_vw_act .form {
  margin-left: 250px;
  width: auto;
}

#box_content.hist_vw_act .box_head_sec {
  width: calc(100% - 250px);
}

#box_hist_vw .hst_head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 32px 20px;
  font-size: 14px;
  line-height: 16px;
}

#box_hist_vw .hst_head select {
  width: 100%;
  height: 40px;
  max-width: 260px;
  min-width: auto;
}

#box_hist_vw .hst_list {
  position: absolute;
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

#box_hist_vw .hst_head > span {
  display: block;
  padding-top: 10px;
}

#box_hist_vw option[value=""] {
  color: #444;
  background: #eee;
}

#box_hist_vw .hst_list ul {
  position: relative;
  display: block;
  padding-left: 0;
  list-style: none;
  margin: 0 20px;
}

#box_hist_vw .hst_list ul li {
  position: relative;
  display: block;
  padding: 20px 4px 24px;
  border-bottom: 1px solid #D5DFE1;
  font-size: 12px;
  line-height: 20px;
}

#box_hist_vw .hst_list ul li .nm_ttl {
  color: #2E383A;
}

#box_hist_vw .hst_list ul li .dt_ttl {
  color: #4C6569;
}

#box_hist_vw .hst_list ul li .dt_ttl,
#box_hist_vw .hst_list ul li .nm_ttl {
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: flex-start;
}

#box_hist_vw .hst_list ul li .nm_ttl strong a {
  font-weight: 600;
}

#box_hist_vw .hst_list ul li .nm_ttl .icon {
  padding-right: 4px;
  vertical-align: -1px;
}

#box_hist_vw .hst_list ul li .nm_ttl strong a i {
  font-size: 14px;
}

#box_hist_vw .hst_list ul.fld_lst {
  padding-left: 15px;
}

/*
#box_hist_vw .hst_list ul.fld_lst.mlt                  {}
*/
#box_hist_vw .hst_list ul.fld_lst li {
  padding: 4px 0;
  border-bottom: none;
}

#box_hist_vw .hst_list ul.fld_lst li:last-child {
  border: none;
}

#box_hist_vw .hst_list ul.fld_lst li em {
  display: block;
  font-style: normal;
  color: #4C6569;
}

#box_hist_vw .hst_list ul.fld_lst li em.link_sim:hover {
  cursor: pointer;
  color: #3D9487;
}

#box_hist_vw .hst_list ul.fld_lst ol {
  padding-left: 10px;
}

#box_hist_vw .hst_list ul.fld_lst ol li {
  padding: 5px 0 0 0;
  border: none;
}

#box_hist_vw .ico_lst_opn,
#box_hist_vw .ico_lst_cls {
  display: none;
  vertical-align: sub;
}

#box_hist_vw .ico_lst_cls,
#box_hist_vw .fls_opn .ico_lst_opn {
  display: inline;
}

#box_hist_vw .fls_opn .ico_lst_cls,
#box_hist_vw ul.fld_lst {
  display: none;
}

#box_hist_vw .fls_opn ul.fld_lst {
  display: block;
  padding: 24px 0 0 0;
  margin: 0;
}

#box_hist_vw textarea {
  border: 0;
  padding: 0;
  padding-right: 5px;
  background: none;
  font-size: 12px;
  line-height: 20px;
  color: #2E383A;
  overflow: hidden;
}
#box_hist_vw textarea::-webkit-scrollbar {
  display: none;
}

.bx_act {
  background: none;
  text-align: right;
  display: flex !important;
  justify-content: flex-end;
}

.bx_wrk_data {
  display: flex;
  flex-direction: column;
}

.bx_wrk_avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ABC0C4;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  min-width: 32px;
}
.bx_wrk_avatar i {
  padding-right: 0 !important;
  font-size: 16px;
}

#box_hist_vw .bx_pgs {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px;
  margin: 0;
}

#box_hist_vw .bx_pgs .btn-group {
  margin: 0;
}

#box_hist_vw .bx_pgs .icon {
  padding: 0;
  overflow: hidden;
  font-size: 90%;
  line-height: 90%;
}

.wrk_empty_state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
}

.wrk_empty_icon {
  font-size: 48px;
  color: #BAAA8A;
  max-height: 48px;
  margin-bottom: 16px;
}

.wrk_empty_content {
  color: #4C6569;
  text-align: center;
}

/*

    Hubspot

*/
#hubspot-messages-iframe-container {
  display: none !important;
  bottom: -90px !important;
  height: 617px !important;
  max-height: 617px !important;
  width: 416px !important;
  overflow-y: hidden !important;
}

#hubspot_cont {
  z-index: 1000000;
  display: none;
  position: fixed;
  bottom: 523px;
  right: 0px;
  margin-right: 16px;
  width: 376px;
}

#btn_hubspot_chat_close {
  z-index: 2000000;
  position: absolute;
  top: -35px;
  right: 16px;
  border: 0;
  border-radius: 30px;
  padding: 15px;
  background: #4CB58E;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

#btn_hubspot_chat_close:hover {
  background: rgb(81, 189, 149);
}

#btn_hubspot_chat_close:before {
  font-family: "Glyphicons Regular";
  content: "\e208";
  display: block;
  font-size: 150%;
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hs-cookie-notification-position-bottom {
  display: none !important;
}

/*

    ## Mode Excel

*/
.tooltip-form-label {
  vertical-align: bottom;
  margin-right: 10px;
}

.form-group .xl-tooltip {
  color: #bdc3c7;
}

.form-group .tooltip-inner {
  max-width: 300px;
  width: auto;
  text-align: left;
}

.tbl_excel_stat {
  position: absolute;
  display: none;
  top: -10px;
  left: 50px;
  overflow: hidden;
  font-size: 10px;
  line-height: 20px;
  font-weight: 400;
}
.tbl_excel_stat .lotoftime {
  display: none;
}

.tbl_excel_stat > div {
  position: relative;
  overflow: hidden;
  display: none;
}

.tbl_excel_stat .whait {
  color: #653600;
}

.tbl_excel_stat .updtd {
  color: #05570A;
}

.tbl_excel {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 350px;
}

.handsontable td,
.handsontableInputHolder,
.handsontableInput,
.handsontable .htSelectEditor {
  font-size: 13px;
  line-height: 16px;
}

.handsontable .htSelectEditor {
  border: 1px solid #D5DFE1;
}

.handsontable .colHeader, .handsontable td {
  max-width: 450px;
}

.handsontable .wtSpreader {
  top: 0;
}

.handsontable td.updtd {
  background: #feffce !important;
}

.handsontable td.deltd {
  background: #dbadad !important;
}

.handsontable td.slyr_invld {
  background: #ffd2c4 !important;
}

.handsontable td.updtd.area {
  background: #e6fffb !important;
}

.handsontable td.deltd.area {
  background: #dbc9ad !important;
}

.handsontable td.slyr_invld.area {
  background: #d5f76d !important;
}

.handsontable .columnSorting.ascending,
.handsontable .columnSorting.descending {
  padding-right: 15px;
}

.handsontable .columnSorting.ascending::after,
.handsontable .columnSorting.descending::after {
  font-size: 80%;
  right: 0 !important;
}

.handsontableInputHolder .select2 {
  min-height: 25px;
  height: 25px;
  max-height: 25px;
  visibility: hidden;
}

.handsontable td.htAutocomplete,
.handsontable td.htNoWrap.htAutocomplete {
  white-space: normal;
}

.handsontable .htAutocompleteArrow {
  text-align: right;
}

.ht_clone_left.handsontable .wtHolder table.htCore {
  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.15);
}

.select2-container--bootstrap.mod-handsontable > .select2-dropdown {
  padding: 0;
  margin-top: 0;
  border-radius: 0;
  border: 1px solid #D5DFE1;
  font-size: 13px;
  line-height: 16px;
}

.select2-container--bootstrap.mod-handsontable .select2-results {
  padding: 0;
  margin: 0;
  height: auto;
}

.select2-container--bootstrap.mod-handsontable .select2-results li {
  padding: 2px 5px;
  min-height: auto;
}

.handsontable th, .handsontable td {
  color: #4C6569;
  background: #FFFFFF;
}

.handsontable td.st_V {
  color: #387B77;
}

.handsontable td.st_I {
  color: #2E383A;
}

.handsontable td.st_D {
  color: #FF9900;
}

.handsontable td.st_V:before {
  padding: 0 5px;
  font-size: 80%;
  content: "\e012";
  font-family: "Flat-UI-Icons";
}

.handsontable td.st_I:before {
  padding: 0 5px;
  font-size: 80%;
  content: "\e208";
  font-family: "Glyphicons Regular";
}

.handsontable td.st_D:before {
  padding: 0 5px;
  font-size: 80%;
  content: "\e431";
  font-family: "Glyphicons Regular";
}

.handsontable table td {
  position: relative;
}

.handsontable table > tbody > tr:nth-child(2n) > td,
.handsontable table > tbody > tr.selected-row > td,
.handsontable table > tbody > tr:nth-child(2n) > th,
.handsontable table > tbody > tr.selected-row > th {
  background: #fcfcfc;
}

.handsontable td.htDimmed {
  background: #F8F8F8 !important;
  color: #7B9BA1 !important;
}

.handsontable table > tbody > tr:nth-child(2n) > td.htDimmed,
.handsontable table > tbody > tr.selected-row > td.htDimmed {
  background: #f8f8f8;
  color: #999;
}

.handsontable table > tbody > tr:nth-child(2n) > td.area,
.handsontable table > tbody > tr.selected-row > td.area,
.handsontable table > tbody > tr:nth-child(2n) > th.area,
.handsontable table > tbody > tr.selected-row > th.area {
  background: #d6e2f6;
}

.handsontable table > tbody > tr:nth-child(2n) > td.updtd,
.handsontable table > tbody > tr.selected-row > td.updtd {
  background: #f3f4bd !important;
}

.handsontable table > tbody > tr:nth-child(2n) > td.updtd.area,
.handsontable table > tbody > tr.selected-row > td.updtd.area {
  background: #d6f2ed !important;
}

.handsontable table > tbody > tr:nth-child(2n) > td.slyr_invld,
.handsontable table > tbody > tr.selected-row > td.slyr_invld {
  background: #fec9b9 !important;
}

.handsontable table > tbody > tr:nth-child(2n) > td.slyr_invld.area,
.handsontable table > tbody > tr.selected-row > td.slyr_invld.area {
  background: #caed61 !important;
}

.handsontable-cell-btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin: -11px 0 0 0;
  padding: 3px 3px;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 11px;
  line-height: 11px;
}

.handsontable-cell-btn:hover {
  color: #222;
}

/*
.handsontable-cell-btn.up       { background: #16a085; color: #fff }
.handsontable-cell-btn.up:hover { background: #387B77 }
*/
.handsontable-cell-btn:hover {
  -webkit-transition: background 0.6s ease;
  -o-transition: background 0.6s ease;
  transition: background 0.6s ease;
}

/*
.handsontable table > tbody > tr.selected-row  > td .handsontable-cell-btn { border-color: #f8f8f8 }
*/
.handsontable td.htDimmed button {
  pointer-events: none;
  opacity: 0.3;
}

.handsontableInput {
  box-shadow: 0 0 0 2px #BAAA8A inset !important;
  color: #4C6569 !important;
  padding: 9px 5px 0 17px !important;
}

.md_xl .htBorders .wtBorder.current, .md_xl .htBorders .wtBorder.area {
  background-color: #BAAA8A !important;
}

.box_list.md_xl {
  padding: 14px 44px;
}

#box_content .box_list_excel {
  overflow: visible;
}

.handsontable .table colgroup + thead tr:first-child td,
.handsontable .table thead:first-child tr:first-child th,
.handsontable .table thead:first-child tr:first-child td {
  border-top: 1px solid #D5DFE1;
}

.handsontable .table-bordered th:first-child,
.handsontable .table-bordered td:first-child {
  border-left: 1px solid #D5DFE1;
}

.handsontable td {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}

.handsontable th:last-child {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}

.handsontable tr:first-child th.htNoFrame,
.handsontable th:first-child.htNoFrame,
.handsontable th.htNoFrame {
  border-left-width: 0;
  background-color: white;
  border-color: #FFF;
}

.handsontable th:first-child,
.handsontable th:nth-child(2),
.handsontable td:first-of-type,
.handsontable .htNoFrame + th,
.handsontable .htNoFrame + td {
  border-left: 1px solid #D5DFE1;
}

.handsontable.htRowHeaders thead tr th:nth-child(2) {
  border-left: 1px solid #D5DFE1;
}

.handsontable tr:first-child th,
.handsontable tr:first-child td {
  border-top: 1px solid #D5DFE1;
}

.handsontable.listbox .ht_master table {
  border: 1px solid #D5DFE1;
}

.handsontable tbody tr th:nth-last-child(2) {
  border-right: 1px solid #D5DFE1;
}

.handsontable thead tr:nth-last-child(2) th.htGroupIndicatorContainer {
  border-bottom: 1px solid #D5DFE1;
}

.ht_clone_top_left_corner thead tr th:nth-last-child(2) {
  border-right: 1px solid #D5DFE1;
}

.pika-single {
  border: 1px solid #D5DFE1;
}

.htContextMenu table.htCore {
  border: 1px solid #D5DFE1;
}

.md_xl .handsontable th, .md_xl .handsontable td {
  color: #4C6569;
  text-align: initial;
  font-weight: 400;
  font-size: 14px;
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
  box-sizing: border-box;
}
.md_xl .handsontable th:before, .md_xl .handsontable td:before {
  line-height: 10px !important;
}
.md_xl .handsontable th.htNoWrap, .md_xl .handsontable td.htNoWrap {
  text-overflow: ellipsis;
}
.md_xl .handsontable th.htNoWrap > div, .md_xl .handsontable td.htNoWrap > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 22px;
}
.md_xl .handsontable td {
  padding: 8px 16px;
}
.md_xl .handsontableMaxHeight {
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.md_xl .handsontableMaxHeight * {
  font-size: 14px !important;
  font-style: normal !important;
  display: unset !important;
  text-align: initial !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #4C6569 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.md_xl .handsontable .handsontable.ht_clone_top .wtHider {
  padding: 0;
}
.md_xl .handsontable thead th .relative {
  text-align: initial;
  text-transform: uppercase;
  background-color: #F8F8F8;
  color: #2E383A;
  padding: 0px 16px;
  font-size: 12px;
  line-height: 40px;
  font-weight: 600;
  height: 40px !important;
}
.md_xl .handsontable tr:first-child th, .md_xl .handsontable tr:first-child td, .md_xl .handsontable th:first-child {
  border-top-color: #D5DFE1;
}
.md_xl .handsontable th:first-child, .md_xl .handsontable th:nth-child(2), .md_xl .handsontable td:first-of-type, .md_xl .handsontable .htNoFrame + th, .md_xl .handsontable .htNoFrame + td {
  border-left: 1px solid #D5DFE1;
}
.md_xl .handsontable th:last-child {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}
.md_xl .ht_clone_left.handsontable .wtHolder table.htCore {
  box-shadow: unset;
}
.md_xl .handsontable table > tbody > tr:nth-child(2n) > td, .md_xl .handsontable table > tbody > tr.selected-row > td, .md_xl .handsontable table > tbody > tr:nth-child(2n) > th, .md_xl .handsontable table > tbody > tr.selected-row > th {
  background: #FFFFFF;
}
.md_xl .handsontable .columnSorting:hover {
  text-decoration: none;
}
.md_xl .handsontable-cell-btn {
  margin: -12px 0 0 0;
  padding: 5px 8px;
  background: #fff;
  color: #fff;
  font-size: 16px;
  color: #4C6569;
}
.md_xl .htDimmed .handsontable-cell-btn {
  background: transparent;
}
.md_xl .handsontable td.st_V:before {
  font-family: "sally-icon";
  content: "\e931";
  padding: 0 8px 0 0;
  font-size: 16px;
}
.md_xl .handsontable td.st_I:before {
  font-family: "sally-icon";
  content: "\e930";
  padding: 0 8px 0 0;
  font-size: 16px;
}
.md_xl .handsontable td.st_D:before {
  font-family: "sally-icon";
  content: "\e92b";
  padding: 0 8px 0 0;
  font-size: 16px;
}
.md_xl .handsontable .columnSorting.ascending::after {
  font-family: "sally-icon";
  content: "\e90c";
  font-size: 16px;
  right: -6px !important;
}
.md_xl .handsontable .columnSorting.descending::after {
  font-family: "sally-icon";
  content: "\e908";
  font-size: 16px;
  right: -6px !important;
}
.md_xl .wtBorder.current, .md_xl .wtBorder.area {
  background-color: #3EAC93 !important;
}
.md_xl .fixedHeightCell {
  border-left-width: 0;
  height: 22px !important;
  height: 38.8px !important;
  line-height: 22px !important;
}

@keyframes animate-in-down-excel-status {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 20px;
  }
}
@keyframes animate-in-down-excel-status-2 {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 10px;
  }
}
.select2-container.select2-container--bootstrap.mod-handsontable.select2-container--open {
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  margin-top: 20px;
  animation: animate-in-down-excel-status 0.3s ease;
}

.select2-container--bootstrap.mod-handsontable > .select2-dropdown {
  border-radius: 8px;
  border: none;
}

.select2-container--bootstrap.mod-handsontable .select2-results li {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 24px;
  white-space: nowrap;
  color: #2E383A;
}

.select2-container--bootstrap.mod-handsontable .select2-results li.select2-results__option--highlighted {
  border-radius: 4px;
  background-color: #F8F8F8;
  color: #3D9487;
}

.handsontable .autocompleteEditor.handsontable.htMacScroll {
  min-width: 100%;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.handsontable.listbox.autocompleteEditor {
  overflow: visible !important;
  height: auto !important;
}
.handsontable.listbox.autocompleteEditor .ht_master.handsontable {
  width: calc(100% + 16px) !important;
  margin-left: -10px;
}
.handsontable.listbox.autocompleteEditor .wtHolder {
  height: auto !important;
  max-height: 200px;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  margin-top: 10px;
  animation: animate-in-down-excel-status-2 0.3s ease;
  margin: 10px;
  border-radius: 8px;
}

.handsontable.listbox .ht_master table.htCore {
  width: 100% !important;
  border: unset;
}
.handsontable.listbox .ht_master table.htCore td.listbox.htDimmed {
  border-left: none;
  cursor: pointer;
}
.handsontable.listbox .ht_master table.htCore td.listbox.htDimmed.current.highlight, .handsontable.listbox .ht_master table.htCore td.listbox.htDimmed:hover {
  background-color: #F8F8F8;
  color: #3D9487;
}

.ht_master .wtHolder {
  width: 100% !important;
  min-height: 350px;
}
.ht_master .wtHolder .wtHider {
  width: 100% !important;
}
.ht_master .wtHolder .wtHider .wtSpreader {
  width: 100%;
}

.handsontable.listbox tr td.current,
.handsontable.listbox tr:hover td {
  background-color: none;
}

/*

    Extras

*/
#box_back_img, #box_back_all {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #f8f8f8; /* #e1e4e5;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

#box_back_img img,
#box_back_all img {
  width: 100%;
}

#box_back_all img {
  width: 100%;
  height: auto;
  max-width: none !important;
  max-height: none !important;
  visibility: hidden;
}

/*

    Cuerpo

*/
/*#body, #body_content {position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden}*/
/*

    Formulari en revisió

*/
#box_content.frm_rev {
  padding-top: 110px;
}

/*

    Traduccions

*/
#box_content.bx_translt .has_preview {
  margin-bottom: 10px;
}

#box_content.bx_translt .auto_trn_fld {
  position: relative;
  display: inline;
  vertical-align: 14px;
}

/*

    Cerques de camps

*/
.in_srch.col-fld > label,
.in_srch .col-fld > label {
  font-weight: 600;
  background: rgba(255, 255, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 11px;
  line-height: 1.8;
  padding: 0 8px;
  margin-right: 10px;
}

.in_srch .field_ops {
  vertical-align: 20px;
}

.titlecrumb.in_srch {
  background: rgba(255, 255, 0, 0.5);
  border-radius: 5px;
  line-height: 1.8;
  padding: 0 4px;
}

#fld_bx_srch {
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  background-color: #ffffff;
  max-height: 40px;
  border: 1px solid #D5DFE1;
  padding: 8px 12px;
  border-radius: 4px;
}

#fld_bx_srch:focus-within {
  border: 1px solid #3EAC93;
  color: #2E383A;
}

#fld_bx_srch > i.icon {
  position: relative;
  display: inline-block;
  float: left;
  font-size: 16px;
  color: #ABC0C4;
}

#fld_bx_srch_rwn > i.icon, #fld_bx_srch_frw > i.icon {
  color: #2E383A;
}

#fld_bx_srch:focus-within > button i.icon {
  color: #2E383A;
}

#fld_bx_srch.focus > i.icon {
  color: #2E383A;
}

#fld_bx_srch > input {
  position: relative;
  float: left;
  background: transparent;
  border: 0;
  width: auto;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
  color: #2E383A;
}
#fld_bx_srch > input::placeholder {
  color: #7B9BA1;
}

#fld_bx_srch.err_find > input {
  border-bottom-color: rgba(217, 41, 30, 0.8);
  color: rgba(217, 41, 30, 0.8);
}

#fld_bx_srch > input:focus {
  border-color: #3EAC93;
  color: rgba(0, 0, 0, 0.8);
}

#fld_bx_srch button {
  padding: 2px 4px;
}

#fld_bx_srch button:hover {
  color: #387B77;
}

#fld_bx_srch > input[disabled] {
  color: rgba(0, 0, 0, 0.1) !important;
}

#fld_bx_srch button[disabled] {
  color: rgba(0, 0, 0, 0.1) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}

/*

    Caja centradora

*/
.box_center {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -100px;
  display: block;
  text-align: center;
}

.box_center.long,
.box_center.long_form {
  top: 0;
  bottom: 0;
  right: 0;
  margin: 60px auto 70px;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 10;
}

.box_center.long {
  overflow: auto;
  margin-bottom: 0;
}

.box_center .box_input_medium {
  text-align: left;
}

/*

    Caixa opcions

*/
.box_opts {
  z-index: 1;
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 20px 0;
  font-size: 12px;
  line-height: 12px;
}

.box_opts .bx_left, #hd_options .bx_left {
  float: left;
  margin-right: 10px;
}

.box_opts .bx_right, #hd_options .bx_right {
  float: right;
  margin-left: 10px;
}

.box_opts .last, #hd_options .last {
  margin: 0;
}

.box_smsl {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 40px;
  border: 1px solid #D5DFE1;
  border-radius: 4px;
  background: white;
  color: #2E383A;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.box_smsl .icon {
  top: 10px;
  padding-left: 12px;
  font-size: 20px;
}

.box_smsl .title {
  padding: 0 12px 0 4px;
}

/*

    Contenidors especials

*/
.contnr_bx_lst {
  position: relative;
  display: block;
  width: 100%;
}

/*

    Caixes centrades form

*/
.box_form_add_db, .box_form_message {
  position: relative;
  overflow: hidden;
  width: 996px;
  margin: 40px auto 0;
}

.box_form_add_db.no_sep {
  margin-top: 0;
}

.box_form_add_db .alert {
  text-align: left;
  padding: 16px 30px 16px 15px;
  margin-bottom: 10px;
}

.box_form_add_db .alert .pull-right {
  margin: 0;
}

.box_form_add_db .box_msg, .box_form_message .box_msg {
  position: relative;
  display: block;
  margin-bottom: 20px;
  color: #999;
  font-style: italic;
  text-align: left;
  font-size: 90%;
}

.box_form_add_db .box_msg .btf_icon, .box_form_message .box_msg .btf_icon {
  padding-right: 5px;
}

.mod_popup .box_form_add_db,
.mod_popup .box_form_message {
  width: auto;
  margin-top: 0;
}

.box_form_add_db .tagsinput .not_valid {
  border-color: #aaa;
  margin-left: 0 !important;
}

.box_form_categories_reg {
  margin-top: 350px;
}

/* llistat simple */
.tp_activity .list_tasks td.tick {
  width: 120px;
  padding-left: 24px;
}

.tp_activity .list_tasks td.tick span:hover {
  cursor: pointer;
}

.tp_activity .list_tasks td.tick .label {
  display: flex;
  justify-content: center;
}

.tp_activity .list_tasks tbody tr td.inf {
  color: #4C6569;
  padding-left: 8px;
  gap: 8px;
}
.tp_activity .list_tasks tbody tr td.inf .inf_name {
  font-weight: 600;
}

.tp_activity .list_tasks tbody tr td.inf .activity-btn-group {
  display: flex;
  align-items: center;
}

.tp_activity .list_tasks tbody tr td.inf .btn-sm {
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  padding: 8px 16px;
}

/*  Modificador generals  */
.box_form_add_db .form-group.all {
  width: 100%;
}

/*

    editor HTML

*/
.mce-floatpanel {
  z-index: 110000 !important;
}

/*

    ## Edició pop idiomes

*/
#pop_box_tlang_org,
#pop_box_tlang_var {
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

#pop_box_tlang_org {
  padding: 24px 20px 0;
  border-bottom: 1px solid #D5DFE1;
}

#pop_box_tlang_var {
  padding: 24px 20px 0;
}

#pop_box_tlang_org label, #pop_box_tlang_var label {
  color: #4C6569;
}
#pop_box_tlang_org label strong, #pop_box_tlang_var label strong {
  font-weight: 600;
  color: #2E383A;
}

#pop_box_tlang_var.file_tp {
  height: 100%;
  padding: 0 20px;
}

#form_tlang {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#tlang_default_field {
  margin-bottom: 24px;
}

.auto_trn_fld {
  display: none;
}

/*

    ## Caixa preview item pare en editor ##

*/
.sec-prd-frm_edt #box_form_content #bx_slct_prd_frmt .box_head_sec {
  position: absolute;
  top: 29px;
}

.sec-prd-frm_edt #box_form_content #bx_slct_prd_frmt .box_head_sec h1 {
  font-size: 24px;
  line-height: 28px;
  color: #2E383A;
  font-weight: 700;
  width: 60%;
}

#box_content.prv_prnt_vw_act .form form {
  width: 62%;
}

#box_content.prv_prnt_vw_cls .form form {
  width: auto;
  margin-right: 22px;
}

#bx_prev_inf_prnt {
  z-index: 901;
  position: absolute;
  right: 0;
  width: 38%;
  height: 100%;
  min-height: 450px;
  padding: 0 0 71px 20px;
  background-color: #FFFFFF;
  border-left: 1px solid #D5DFE1;
  top: 0px;
  min-height: calc(100vh - 140px);
  height: 100%;
}

#bx_prev_inf_prnt iframe {
  position: relative;
  background: #fff;
  border: 0;
  width: 100%;
  height: 100%;
}

#btn_act_dis_prev {
  position: absolute;
  top: 28px;
  left: -12px;
  color: #2E383A;
  background-color: #fff;
  -webkit-transition: linear 0.15s;
  transition: linear 0.15s;
  border: 1px solid #D5DFE1;
  border-radius: 100%;
  padding: 3px;
  width: 24px;
  height: 24px;
}

#btn_act_dis_prev:before {
  font-family: "sally-icon";
  content: "\e91e";
  display: block;
  font-size: 16px;
}

#box_content.prv_prnt_vw_cls #bx_prev_inf_prnt {
  width: 0;
}

#box_content.prv_prnt_vw_cls #btn_act_dis_prev {
  right: 2px;
}

#box_content.prv_prnt_vw_cls #btn_act_dis_prev:before {
  content: "\e91d";
}

/*

    Modificadors esencials

*/
.ehide, .focus_show {
  display: none;
}

.eshow {
  display: block;
}

.hidden {
  visibility: hidden;
}

.txhdn {
  color: transparent;
}

.ihide {
  display: none !important;
}

.not_selectable, .btn-box label .title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

#linkUp {
  display: none;
}

#head, #box_menu {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

/*

    Precàrregues imatges

*/
body:before {
  content: url(../../../img/sl_loading_bl.png);
  max-width: 100px;
  visibility: hidden;
  filter: alpha(opacity=0);
  position: absolute;
  z-index: 0;
  pointer-events: none;
}

body:after {
  content: url(../../../img/loader.gif);
  max-width: 100px;
  visibility: hidden;
  filter: alpha(opacity=0);
  position: absolute;
  z-index: 0;
  pointer-events: none;
}

body:after {
  content: url(../../../img/switch/mask.png);
  max-width: 100px;
  visibility: hidden;
  filter: alpha(opacity=0);
  position: absolute;
  z-index: 0;
  pointer-events: none;
}

/*

    Box errors conex

*/
#box_error {
  z-index: 100005;
  display: none;
  position: fixed;
  left: 50%;
  top: 20px;
  width: 400px;
  margin-left: -200px;
  _overflow: hidden;
  border-radius: 6px;
  background: #FFF1DB;
}

#box_error h6 {
  float: left;
  margin: 0;
  width: 435px;
  font-size: 100%;
  line-height: 120%;
  color: #fff;
}

#box_error .close {
  margin-top: 5px;
}

#legal_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}

#legal_box, #legal_box table, #legal_box p {
  font-size: 11px;
  line-height: 18px;
}

#legal_box h1, #legal_box h2 {
  font-size: 125%;
}

#legal_box h3 {
  font-size: 115%;
}

#legal_box h4 {
  font-size: 110%;
}

#legal_box h5,
#legal_box h6,
#legal_box span.legacy-h7 {
  font-size: 105%;
}

/*

    ## Quick Edit

*/
#box_content.bx_qck_edt .box_form {
  padding-bottom: 0;
}

#sections_container .nav .makefield_ops {
  position: relative;
  float: right;
  top: auto;
  right: auto;
}

#sections_container .bt_add_field {
  margin-bottom: 16px;
}

#qck_edt_ops {
  position: relative;
  display: block;
  padding-bottom: 40px;
}

#qck_edt_ops label.pull-left {
  margin-right: 15px;
}

/*#fields_container .row           { margin-bottom: 16px }*/
#fields_container .box_search {
  z-index: 0;
  padding: 0 0 30px 0;
}

#fields_container label {
  margin: 8px 0 0 0;
}

.field_container {
  display: inline-table;
  margin-bottom: 16px;
}

.field_container .field {
  border: 1px solid #EAEEF0;
  border-radius: 4px;
  background-color: #EAEEF0;
}

.field_container div.makefield_ops {
  display: inline-block;
  float: none;
  position: absolute;
  right: 10px;
  top: 6px;
}

.field_container.field_container-fld div.makefield_ops {
  right: 10px;
}

.field_container span.glyphicon {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 20px;
  color: #ABC0C4;
  cursor: grab;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.field_container .field:hover span.glyphicon-move,
.field_container span.glyphicon:hover {
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.field_container label {
  width: auto;
  margin-right: 80px;
}

.field_container .elm_check .label {
  border-radius: 5px;
  margin: 0;
  font-size: 10px;
  vertical-align: 0;
  color: #fff;
}

.field_container .elm_check .label-default {
  background: rgba(1, 1, 1, 0.25);
}

.field_container .elm_check .icon {
  margin: 0 0 0 5px;
  font-size: 16px;
  line-height: 1px;
  color: rgba(1, 1, 1, 0.25);
  vertical-align: -4px;
}

#qkd_move_shadow div,
#qkd_new_shadow div {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #D5DFE1;
}

#qkd_new_shadow div {
  background: #EDF8F3;
  border: none;
}

.field_container.actn_mov {
  position: fixed;
  z-index: 100000;
  padding-top: 0;
  cursor: pointer;
}

.field_container.line_mov:not(.actn_mov) {
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
}

.field_container.line_mov .field:hover {
  border-color: #EAEEF0;
}

.field_container.actn_mov .field {
  background: #EAEEF0;
  box-shadow: -5px 5px 1px rgba(126, 145, 154, 0.3);
}

/* drop dragged element ghost */
.field_container.in_change > div {
  background: #DCEFE9;
  border-color: #DCEFE9;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
}

.field_container.actn_mov .label,
.field_container.actn_mov .icon,
.field_container.actn_mov .dropdown {
  display: none;
}

#fields_container .nav-pages.ms_over li a {
  background: rgba(255, 240, 0, 0.3);
}

#fields_container hr.form_med {
  margin: 0 20px 20px;
}

#fields_container hr.form_med.and_ttl {
  margin-bottom: 0;
}

#fields_container hr.form_max {
  margin: 10px 20px 30px;
}

#fields_container hr.form_max.and_ttl {
  margin-bottom: 10px;
}

#fields_container .ttl_prg {
  margin: 10px 0;
  font-size: 18px;
}

#fields_container .field {
  padding: 0 0 0 44px;
  height: 40px;
}

.field_container .field .makefield_ops .btn {
  background-color: transparent;
}
.field_container .field .makefield_ops .btn:active {
  border-color: transparent;
}

.alert.alert-info > p, .alert.alert-warning > p, .alert.alert-error > p, .alert.alert-success > p {
  margin-left: 36px;
}

.ui-datepicker {
  width: 392px;
  border: 7px solid #fff;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}
.ui-datepicker th {
  text-transform: uppercase;
  color: #4C6569;
  font-weight: 600;
}
.ui-datepicker td span, .ui-datepicker td a {
  color: #2E383A;
  font-weight: 600;
  font-size: 14px;
}
.ui-datepicker .ui-datepicker-header {
  background-color: #fff;
  color: #000000;
  cursor: default;
  margin: -7px -7px 2px;
  position: relative;
  border-radius: 6px 6px 0 0;
  padding: 12px 0;
}
.ui-datepicker .ui-datepicker-title {
  line-height: 26px;
}
.ui-datepicker .ui-datepicker-year {
  background-color: #fff;
  color: #000000;
  padding: 8px 0;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: auto;
}
.ui-datepicker .ui-datepicker-month, .ui-datepicker .ui-datepicker-year {
  width: auto;
  font-weight: 700;
}
.ui-datepicker select.ui-datepicker-year {
  cursor: pointer;
}
.ui-datepicker .datepicker-title {
  font-weight: 700;
}
.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker .ui-datepicker-next:hover {
  background-color: #fff;
}
.ui-datepicker .ui-slider {
  height: 6px;
  margin-bottom: 10px !important;
}
.ui-datepicker .ui-slider-horizontal .ui-slider-handle {
  margin-left: -9px;
  top: -5px;
}
.ui-datepicker dt, .ui-datepicker dd {
  line-height: 3;
}
.ui-datepicker .ui-datepicker-buttonpane {
  margin: 24px 0 0;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  top: 12px;
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  content: initial;
}
.ui-datepicker .ui-datepicker-prev span:before, .ui-datepicker .ui-datepicker-next span:before {
  font-family: "sally-icon" !important;
  font-style: normal;
  color: #4C6569;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: initial;
  font-size: 24px;
}

.ui-datepicker .ui-icon-circle-triangle-e, .ui-datepicker .ui-icon-circle-triangle-w {
  border: none;
}

.ui-datepicker .ui-datepicker-prev span:before {
  content: "\e91d";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -6px;
}

.ui-datepicker .ui-datepicker-next span:before {
  content: "\e91e";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -6px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0;
  border-right: none;
  border-radius: 6px 0 0 0;
}

.ui-datepicker .ui-datepicker-next {
  right: 0;
  border-left: none;
  border-radius: 0 6px 0 0;
}

/*

	Llistes taula

*/
.table_container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.table_container .box_left {
  float: left;
}

.table_container .box_right {
  float: right;
}

.table_container .box_left,
.table_container .box_right {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 20px 0;
}

.table_container .pagination li {
  margin-right: 0;
}

.table.no_mrg {
  margin: 0;
}

.table th {
  white-space: nowrap;
}

.table.micro th {
  text-align: center;
}

.table td {
  font-size: 14px;
  line-height: 15px;
}

.table tr.fntom {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.table tr.success td {
  background-color: #387B77 !important;
  color: #fff;
}

.table.adjust {
  width: auto;
  min-width: 50%;
}

.table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 24px;
  color: #4C6569;
}

.table tr th {
  font-size: 12px;
  line-height: 20px;
}

.table tr th.is_number,
.table tr th.sl-text-align-right {
  text-align: right;
}

.table > thead > tr > th {
  vertical-align: middle;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 20px;
  color: #2E383A !important;
  letter-spacing: 1px;
}

.table > thead.total > tr > th,
.table > tfoot.total > tr > th {
  border: 0;
  border-top: 2px solid #dfdfdf;
}

.table.micro th,
.table.micro td {
  font-size: 10px;
  line-height: 12px;
  padding: 5px 15px;
}

.table.mini th,
.table.mini td {
  font-size: 80%;
  line-height: 150%;
  padding: 5px 15px;
}

.table.mini .form-control {
  font-size: 90%;
  height: auto;
  padding: 0 4px;
}

.table .order-asc:before,
.table .order-desc:before {
  font-family: "sally-icon";
  color: #2E383A;
  margin-right: 4px;
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table .order-asc:before {
  content: "\e91c";
}

.table .order-desc:before {
  content: "\e91f";
}

.table th.clean {
  padding: 0;
}

.table th.right,
.table td.right {
  text-align: right;
}

.table td.td_mov {
  padding: 0;
  vertical-align: middle;
  width: 20px;
  min-width: auto;
  padding: 9px 0;
}

.table td.td_mov button {
  border: 0;
  background: 0;
  display: block;
  color: rgba(0, 0, 0, 0.2);
}

.table td.td_stat {
  width: 45px;
  min-width: 40px;
  padding: 16px;
}

.table td.td_stat a i.icon,
.table td.td_stat button i.icon {
  font-size: 16px;
  line-height: 16px;
  color: #CFDADD;
}

.table td.td_file,
.table td.td_check,
.table th.td_check {
  width: 64px;
  padding: 24px;
}

.table td.td_file a i.icon {
  color: #ABC0C4;
}
.table td.td_file a i.icon:hover {
  color: #4C6569;
}

.table td.td_wkfl {
  width: 40px;
  padding: 9px 10px;
  text-align: center;
  vertical-align: middle;
}

.table th.th_cntr,
.table td.td_cntr {
  text-align: center;
  vertical-align: middle;
}

.table td.td_check .checkbox .icons,
.table th.td_check .checkbox .icons {
  top: -1px;
}

.table td.td_ref {
  min-width: 100px;
}

.table td.td_num,
.table td.td_numb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table td.td_wrap {
  text-wrap: wrap;
}

.table td.td_num strong {
  display: inline-block;
  min-width: 50%;
  padding-right: 5px;
}

.table td.td_numb a.lnk:link,
.table td.td_numb a.lnk {
  color: #34495E;
}

/*.table td.td_numb em        { font-weight: normal; font-style: normal; color: #586f86 }*/
.table td.td_numb a.lnk:hover {
  color: #387B77;
}

.table td.td_num em {
  display: none;
}

.table td.td_opts {
  width: 180px;
  text-align: right;
}

.table td.price h6 {
  font-size: 14px;
  line-height: 24px;
  color: #4C6569;
  font-weight: 600;
}

.table td ul {
  overflow: hidden;
  margin: 0;
}

.table td li {
  list-style-type: none;
  margin: 0 5px 0 0;
  padding: 0;
  float: left;
}

.table td ul.list_horitz.sep {
  margin-top: 5px;
}

.table td ul.list_horitz li {
  margin: 0 0 5px 0;
  float: none;
}

.table td ul.list_horitz li:last-child {
  margin: 0;
}

.table td .append {
  color: #7B9BA1;
}

.table td.td_thumb {
  width: 80px;
}

.table td.td_thumb .box_img {
  position: relative;
  overflow: hidden;
  background: white;
  top: auto;
  left: auto;
  width: 58px;
  height: 48px;
  min-height: unset;
  border: none;
}

.table td.td_thumb .box_img.img_on {
  background: #fff url(../../../img/loader.gif) center center no-repeat;
}

.tp_fls .table td.td_thumb .box_img {
  background: transparent;
  display: flex;
  align-items: center;
}

.tp_fls .table td.td_thumb .box_img img {
  height: 42px;
  width: auto;
}

.table td.td_thumb .box_img span,
.box_thumb .box_img span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table td.td_thumb .box_img span.img_placeh {
  background-size: 22px;
}

.table td.td_thumb .box_img span i {
  font-size: 22px;
  line-height: 22px;
  padding: 0;
}

.table td.td_thumb .box_img span em {
  display: block;
  padding: 0 5px;
  font-size: 12px;
  line-height: 12px;
  color: #aaa;
  font-style: italic;
}

.table td.td_thumb .box_img span em.alter {
  display: none;
}

.table td em {
  font-weight: 400;
  color: #4C6569;
}

.table td em.prm,
.table td em.alter {
  display: inline;
}

.table .tr_moving {
  position: fixed;
  z-index: 1000;
  display: block;
  top: 50%;
  left: 50%;
  box-shadow: -5px 5px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.table .tr_moving td.td_mov {
  background: #387B77;
}

.table .tr_moving td.td_mov button {
  color: #fff;
}

.table tbody tr.no_border td {
  border: 0;
}

.table tbody tr.in_change td {
  background: #afe2d8;
}

.table .move_in {
  border-bottom: 15px solid #387B77;
  transition: all 0.25s ease 0s;
}

.table tbody tr.tr_orig_sz td {
  background: #bdc3c7;
  height: 35px;
  transition: height 0.25s ease 0s;
}

tbody tr.tr_primal td {
  background: transparent;
  padding: 0;
  height: 0;
  border: 0;
  display: none;
}

.table.mod_on tbody tr.tr_movr.tr_primal td {
  background: #afe2d8;
  height: 40px;
  transition: height 0.25s ease 0s;
}

.table .td_key {
  padding: 8px;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
}

.table .td_btn_opt {
  width: 100px;
  padding: 8px;
  text-align: center;
}

.table-hover > tbody > tr:hover > td.td_bx_btns {
  background: transparent;
}

.table .td_tags .label {
  display: inline-block;
  cursor: pointer;
}

.td_tags.usr_adm ul li {
  margin-bottom: 3px;
}

.td_check .elm_check {
  margin-left: 0;
}

.sl-payment-paragrh .table tbody tr:hover,
.box_list .table tbody tr:hover {
  background-color: #F8F8F8;
}

.box_list .table .elm_check .icons span,
.box_list .table .elm_check .icons span:before {
  line-height: 22px;
}

.table.st_list_refs .st_ref {
  width: 15%;
}

.table.st_list_refs .st_tit {
  width: 50%;
}

.table.st_list_refs .st_xtr {
  width: 35%;
}

/*  table accounts */
.table.table-accounts {
  background: #fff;
  border-radius: 6px;
}

.fld_sep_top_gr .table.table-accounts {
  background: transparent;
  border-radius: 0;
}

.table.table-accounts > thead > tr > th,
.table.table-accounts > tbody > tr > td {
  border: 0;
  padding: 6px 35px;
  border-left: 1px solid #eee;
}

.table.table-accounts > thead > tr > th {
  text-align: center;
  vertical-align: top;
  font-size: 150%;
  border-bottom: 1px solid #eee;
}

.table.table-accounts > tbody > tr > td {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}

.table.table-accounts > tbody > tr > td.uncheck {
  font-weight: normal;
  color: #ccc;
}

.table.table-accounts > thead > tr > th {
  padding-top: 25px;
  padding-bottom: 25px;
}

.table.table-accounts > tbody > tr:first-child > td {
  padding-top: 25px;
}

.table.table-accounts > thead > tr > th sub {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}

.table.table-accounts > thead > tr > th.selected {
  background: #387B77;
  color: #fff !important;
}

.table.table-accounts > tbody > tr > td.check:before,
.table.table-accounts > tbody > tr > td.uncheck:before {
  font-family: "Glyphicons Regular";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  float: left;
  padding: 0 10px 5px 0;
}

.table.table-accounts > tbody > tr > td.check:before {
  content: "\e207";
  color: #387B77;
}

.table.table-accounts > tbody > tr > td.uncheck:before {
  content: "\e208";
  color: #bdc3c7;
}

/* table stauts */
.table th.tb_prcnt em,
.table td.tb_prcnt em {
  position: relative;
  display: inline;
  float: left;
  padding-right: 8px;
}

.table th.tb_prcnt .progress,
.table td.tb_prcnt .progress {
  margin: 2px 0 0;
  -webkit-print-color-adjust: exact;
}

@media (min-width: 1600px) {
  .table td em.alter {
    display: nome;
  }
}
@media (max-width: 1000px) {
  .table td.td_num strong,
  .table td.td_numb strong {
    width: 100%;
    text-align: right;
  }
  .table td.td_numb .btn {
    display: none;
  }
  .form label em {
    display: none;
  }
}
.sso-accounts-table {
  margin: 0 0 20px 0;
}

.ssoaccount-trash {
  color: #CFDADD;
  font-size: 20px;
}

.ssoaccount-trash:hover,
.ssoaccount-trash:active,
.ssoaccount-trash:focus {
  color: #CFDADD;
}

.sl-icon-reorder {
  font-size: 16px;
  font-weight: 400;
  vertical-align: bottom;
}

#box_users .table td.td_titl {
  width: 200px;
  font-weight: 600;
}

.tr_movr .td_titl .url_ldg {
  display: flex;
  align-items: center;
}

.td_edit {
  width: 0px;
  padding: 0 24px !important;
}
.td_edit i {
  font-size: 16px;
  color: #CFDADD;
}

.teams_link {
  color: #387B77;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.teams_link:hover {
  color: #3D9487;
}

.td_opts .td_opts_btn i {
  color: #ABC0C4;
}
.td_opts .td_opts_btn i:hover {
  color: #4C6569;
}

.box_list_spc .table.list_regs {
  margin-bottom: 40px;
}
.box_list_spc .table.list_regs .td_numb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_list_spc .table.list_regs .td_numb a.url_ldg {
  height: 16px;
}
.box_list_spc .table.list_regs .td_numb a.url_ldg i {
  color: #CFDADD;
  font-size: 16px;
  line-height: 16px;
}

th .sl-icon-organization {
  font-size: 16px;
  font-weight: normal;
}

.td_wkfl i {
  font-size: 16px;
  font-weight: normal;
  color: #4C6569;
}
.td_wkfl i.text-danger {
  color: #D2322C;
}
.td_wkfl i.text-muted {
  color: #ABC0C4;
}

.table .td_right {
  text-align: right;
}
.table .td_right a {
  display: inline-block;
}
.table .checkbox {
  display: block;
}
.table .checkbox .icons, .table .checkbox .icons:hover {
  cursor: default;
}

/*

	Llistes visuals

*/
.box_list {
  position: relative;
  width: 100%;
  overflow: hidden;
  clear: left;
  min-height: 150px;
  padding: 14px 44px 44px 44px;
}
.box_list.tp_activity {
  padding-top: 24px;
}

#box_pay_hist.tp_activity {
  padding-top: 19px;
}

.box_list.box_list_sales_material {
  padding: 40px 44px 44px 44px;
  overflow: inherit;
}
.box_list.box_list_sales_material #list_types {
  margin-bottom: 40px;
}
.box_list.box_list_sales_material #list_types .td_numb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_list.box_list_sales_material #list_types .td_numb em {
  height: 16px;
}
.box_list.box_list_sales_material #list_types .td_numb em a.url_ldg i {
  color: #CFDADD;
  font-size: 16px;
  line-height: 16px;
}

.box_list.box_img_list {
  padding: 14px 28px 44px 28px;
}

.box_list.md_ls {
  padding-right: 40px;
}

.box_list.invisible {
  visibility: visible;
}

.box_list.invisible .box_elm,
.modal .box_list.invisible .bx_image {
  visibility: hidden;
}

.box_list .box_elm:not(.sl-card) {
  position: relative;
  overflow: hidden;
  float: left;
  width: 250px;
  height: fit-content !important;
  margin: 0;
  padding: 16px;
  background-color: transparent;
  border-radius: 6px;
}
.box_list .box_elm:not(.sl-card) .sl-thumbnail {
  max-width: 100%;
  width: 100%;
}
.box_list .box_elm:not(.sl-card) .elm_check,
.box_list .box_elm:not(.sl-card) .box_inf_more {
  display: none;
}
.box_list .box_elm:not(.sl-card) .elm_check.checked {
  display: flex;
}
.box_list .box_elm:not(.sl-card):hover {
  background-color: #EAEEF0;
}
.box_list .box_elm:not(.sl-card):hover .box_inf_details > .box_inf_ref {
  display: none;
}
.box_list .box_elm:not(.sl-card):hover .box_inf_details > .box_inf_more,
.box_list .box_elm:not(.sl-card):hover .elm_check {
  display: flex;
}

.box_list .box_img {
  border-radius: 6px;
  border: 1px solid #D5DFE1;
  min-height: 175px;
  background-color: white;
}

.box_list .box_img.img_on {
  background: #fff url(../../../img/loader.gif) center center no-repeat;
}

.box_list .box_img span {
  position: absolute;
  top: 56px;
  left: 30px;
  color: #4C6569;
  font-size: 12px;
  line-height: 20px;
}
.box_list .box_img span.img_alert {
  top: 28px;
  left: unset;
  right: 30px;
}
.box_list .box_img span.img_alert i {
  font-size: 20px;
  color: #D2322C;
}

.box_thumb .box_img span .icon,
.box_list .box_img span .icon {
  vertical-align: middle;
  padding-right: 4px;
}

.box_list .box_img img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.box_list .box_img span.img_placeh {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../../../img/placeholder_02.png);
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: unset;
  border-radius: 6px;
}

.box_list.tp_fls .box_elm .box_img {
  background: white;
  text-align: center;
}

.box_list.tp_fls .box_elm .box_img a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.box_list.tp_fls .box_elm .box_img img {
  height: 87px;
  width: auto;
}

.td_thumb.st_Er .box_img img,
.vw_img.st_Er img,
.box_elm.st_Er .box_img img,
img.st_Er {
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.box_list .elm_check {
  margin-left: 0;
}

.box_list.md_vw .box_bar {
  position: absolute;
  top: 28px;
  left: 30px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.box_list.md_vw .elm_check {
  margin: 0px;
  background-color: #D5DFE1;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.box_list.md_vw .elm_check.checkbox:before {
  top: 4px;
  left: 4px;
}

.box_list.md_vw.tp_imgs .elm_check,
.box_list.md_vw.tp_fls .elm_check {
  margin: 0px;
}

.box_list .elm_check .icons {
  width: 25px;
  height: 22px;
}

.box_list.md_vw .elm_check .icons {
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.box_list .elm_check .icons span,
.elm_check .icons span {
  width: auto;
  height: 30px;
  overflow: hidden;
  font-size: 24px;
  line-height: 22px;
}

.box_list.md_vw .elm_check .icons span {
  font-size: unset;
  line-height: unset;
  width: unset;
  height: unset;
  top: 1px;
  left: 1px;
}

.box_list .elm_check .icons .fui-checkbox-unchecked:before {
  content: "\e034";
  color: rgba(0, 0, 0, 0.1);
}

.box_list .elm_check .icons .fui-checkbox-checked:before,
.box_list .elm_check .icons .fui-checkbox-unchecked:before {
  line-height: 30px;
} /* <-- 29px no! */
.box_list.md_vw .elm_check .icons .fui-checkbox-checked:before,
.box_list.md_vw .elm_check .icons .fui-checkbox-unchecked:before {
  line-height: unset;
}

.box_list .bx_stat,
.box_list .elm_imga {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
}

.box_list .elm_stat {
  width: 24px;
  height: 24px;
}

.elm_stat.open {
  right: -20px;
}

.box_list .elm_stat.disabled {
  display: inline-table;
}

.box_list .elm_stat.disabled button {
  cursor: auto;
}

#head .elm_qlty,
#head .elm_info,
.box_list.md_vw .elm_qlty {
  width: auto;
  border-radius: 4px;
  background-color: #BFF5C5;
  color: #05570A;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 8px;
}

#head .elm_qlty,
#head .elm_info {
  position: absolute;
  left: 26px;
  top: 26px;
}

#head .elm_qlty {
  display: flex;
  align-items: center;
}

#head .elm_qlty {
  min-width: 74px;
  transition: background 0.25s ease 0s;
}

#head .elm_qlty i:not(.icon),
#head .elm_info i:not(.icon),
.box_list.md_vw .elm_qlty i:not(.icon) {
  font-size: 100%;
}

.box_list.md_vw .elm_qlty,
.box_list.md_vw .elm_wkfl {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

.box_list.md_vw .elm_qlty i.icon,
.box_list.md_vw .elm_wkfl i.icon {
  padding-right: 0;
  line-height: 100%;
  vertical-align: middle;
}

.td_qlty .elm_qlty i.icon {
  padding-right: 0;
}

#head .elm_qlty,
#head .elm_info {
  position: relative;
  left: auto;
  top: auto;
  overflow: hidden;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 8px 12px;
  font-weight: 600;
}

#head .elm_qlty,
.box_list.md_vw .elm_qlty {
  background-color: #BFF5C5;
  color: #05570A;
}

#head .elm_info,
#head .elm_qlty.good,
.box_list.md_vw .elm_qlty.good {
  background-color: #FFD9A0;
  color: #653600;
}

#head .elm_qlty.bad,
.box_list.md_vw .elm_qlty.bad {
  background-color: #F7BCBA;
  color: #55201E;
}

#head .elm_qlty.prv {
  background: rgba(0, 0, 0, 0.15);
}

.box_list.md_ls .td_qlty {
  white-space: nowrap;
}

.box_list.md_ls .td_qlty.qlty_related_items {
  display: table-cell;
}

.box_list.md_ls .elm_qlty {
  background-color: #BFF5C5;
  color: #05570A;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  max-height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.box_list.md_ls .elm_qlty.good {
  background-color: #FFD9A0;
  color: #653600;
}

.box_list.md_ls .elm_qlty.bad {
  background-color: #F7BCBA;
  color: #55201E;
}

.box_list.md_ls .btn-xs {
  padding: 5px 7px;
}

.box_list.md_vw .elm_stat,
.box_list.md_vw .elm_imga,
.bx_conf_img .top_btns {
  position: absolute;
  left: auto;
  right: 28px;
  top: 28px;
}

.box_list.md_vw .elm_wkfl {
  position: absolute;
  bottom: 20px;
  right: 16px;
  border: 0;
}

.box_list.md_vw .elm_wkfl i.icon {
  font-size: 16px;
  line-height: 16px;
  color: #4C6569;
  font-weight: 400;
}
.box_list.md_vw .elm_wkfl i.icon.text-danger {
  color: #D2322C;
}
.box_list.md_vw .elm_wkfl i.icon.sl-icon-check {
  color: #0BA815;
}

.bx_conf_img .top_btns {
  top: 10px;
}

.box_list.md_vw .elm_wkfl,
.box_list.md_vw .box_inf {
  z-index: 1;
}

.box_list.md_vw .elm_stat,
.box_list.md_vw .elm_qlty,
.box_list.md_vw .elm_check {
  z-index: 2;
}

.box_list .elm_stat button,
.box_list .elm_imga button,
.bx_conf_img .top_btns button {
  position: relative;
  overflow: hidden;
  float: left;
  padding: 3px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  border-radius: 4px;
  display: none;
  background: #D5DFE1;
}

.box_list .bx_stat .elm_stat button,
.box_list .bx_stat .elm_imga button,
.bx_conf_img .top_btns button {
  margin-left: 2px;
}

.box_list.md_vw .elm_stat button,
.box_list.md_vw .elm_imga button,
.bx_conf_img .top_btns button {
  width: 24px;
  height: 24px;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 3px;
}

.box_list .elm_stat.disabled button,
.box_list .elm_stat.disabled button.st_D.active {
  opacity: 0.5;
}

.box_list.md_vw .elm_stat button:last-child {
  margin-right: 0;
}

.box_list .elm_stat button.slct,
.box_list .elm_stat button.active {
  display: block;
}

.btn-st_V, .btn-st_I, .btn-st_D {
  color: #4C6569;
}

.box_list .elm_stat i.st_V:before,
.box_list .elm_stat button.st_V:before {
  color: #4C6569;
  font-family: "sally-icon";
  font-weight: 400;
  content: "\e931";
}

.btn-st_V,
.box_list .elm_stat i.st_V,
.box_list .elm_stat button.st_V,
.box_list .elm_stat button.st_V.active {
  background: #BFF5C5;
}

.btn-st_V:hover,
.box_list .elm_stat button.st_V:hover {
  background: #E6FAE8 !important;
}

.box_list .elm_stat i.st_I:before,
.box_list .elm_stat button.st_I:before {
  color: #4C6569;
  font-family: "sally-icon";
  font-weight: 400;
  content: "\e930";
}

.btn-st_I,
.box_list .elm_stat i.st_I,
.box_list .elm_stat button.st_I.active {
  background: #D5DFE1;
} /*{background: #E74C3C}*/
.btn-st_I:hover,
.box_list .elm_stat button.st_I:hover {
  background: #EAEEF0;
} /*{background: #ff7162}*/
.box_list .elm_stat i.st_D:before,
.box_list .elm_stat button.st_D:before {
  color: #4C6569;
  font-family: "sally-icon";
  font-weight: 400;
  content: "\e92b";
}

.btn-st_D,
.box_list .elm_stat i.st_D,
.box_list .elm_stat button.st_D,
.box_list .elm_stat button.st_D.active {
  background: #FBF2D0;
  color: #4C6569;
}

.btn-st_D:hover,
.box_list .elm_stat button.st_D:hover {
  background: #FCF8ED !important;
  color: #4C6569;
}

.box_list .elm_imga button,
.bx_conf_img .top_btns button {
  display: block;
}

.box_list .elm_imga button:before,
.bx_conf_img .top_btns button:before {
  font-family: "Glyphicons Regular";
  content: "\e093";
}

.box_list .elm_imga button,
.bx_conf_img .top_btns button {
  background: #D5DFE1;
}

.box_list .elm_imga button:hover,
.bx_conf_img .top_btns button:hover {
  background: #EAEEF0;
}

.box_list .elm_imga button.st_P,
.bx_conf_img .top_btns button.st_P {
  background: #D5DFE1;
  cursor: not-allowed;
}

.box_list .elm_imga button.st_P:hover,
.bx_conf_img .top_btns button.st_P:hover {
  background: #EAEEF0;
}

.txt_st_V {
  color: #4C6569;
}

.txt_st_I {
  color: #4C6569;
}

.txt_st_D {
  color: #4C6569;
}

.box_list .box_inf {
  width: 100%;
  min-height: 60px;
  color: #fff;
  padding: 12px 0px 0px 0px;
}

.box_list span.legacy-h7 {
  display: block;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box_list span.legacy-h7 a {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2E383A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box_list .box_inf span.legacy-h7 a {
  display: block;
}

.box_list .ttl_min_ref {
  position: relative;
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #4C6569;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box_list ul.st_stat {
  margin: 0px;
}

.box_list i.st_tip, .box_list i.st_tip a,
.box_list ul.st_stat li, .box_list ul.st_stat a {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #4C6569;
}

.box_list ul.st_stat {
  display: block;
  width: 100%;
}

.box_list ul.st_stat li {
  float: left;
  list-style-type: none;
  padding: 0px 16px 0px 0px;
  margin: 0px;
  white-space: nowrap;
}

.box_list ul.st_stat li .icon {
  vertical-align: middle;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 400;
}

.box_list.md_vw .box_elm label {
  padding: 0;
}

.box_list .table {
  margin-bottom: 10px;
}

.box-img-center {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.img_zoom {
  position: relative;
  display: inline-block;
}

.img_zoom:hover:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}

.img_zoom:hover:after {
  content: "\e964";
  font-family: "sally-icon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  position: absolute;
}

.modal.mod_popup .img_zoom_cont_thumb {
  width: 226px;
  height: 188px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.modal.mod_popup .img_zoom_cont_thumb:hover:before {
  z-index: 99;
  border-radius: 6px;
}
.modal.mod_popup .img_zoom_cont_thumb:hover:after {
  z-index: 999;
}

.box_list.tp_dasbhoard_companies {
  padding-top: 0;
  padding-right: 20px;
}

/* Workflow messages */
.wkf_msgs {
  padding: 0 44px;
}

form .wkf_msgs_hdr {
  font-size: 13px;
  line-height: 16px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  padding: 0px 44px;
}

form .wkf_msgs_hdr .btn {
  font-style: normal;
}

form .wkf_msgs_hdr .btn-xs {
  padding: 3px 6px;
}

form .wkf_msgs .alert {
  font-size: 12px;
  line-height: 20px;
}

form .wkf_msgs .alert label {
  font-size: 12px;
  line-height: 14px;
  color: #2E383A;
}

form .wkf_msgs .alert label .icon {
  color: #BAAA8A;
  top: 10px;
}

form .wkf_msgs .alert .alert-msg-button {
  margin-left: 25px;
  margin-top: 12px !important;
}

form .wkf_msgs .alert {
  color: rgba(0, 0, 0, 0.7);
}

/*

    ## Caixa Workflows info

*/
#box_wrkflw {
  position: fixed;
  z-index: 5;
  top: 80px;
  left: 224px;
  right: 0;
  min-height: 60px;
  padding: 24px 44px 16px 44px;
  overflow: visible;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D5DFE1;
  display: flex;
}

#box_wrkflw.hist_vw_act {
  left: 524px;
}

#box_wrkflw .btn-wkf-group-revision {
  min-width: 76px;
}
#box_wrkflw .btn-wkf-group-revision #btn_wkf_act_pr {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
  padding: 8px;
}
#box_wrkflw .btn-wkf-group-revision #btn_wkf_act_rv {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  padding: 8px;
}
#box_wrkflw .btn-wkf-group-revision .btn-tertiary {
  border: 1px solid #D5DFE1;
}

.minus_left #box_wrkflw {
  left: 80px;
}

.minus_left #box_wrkflw.hist_vw_act {
  left: 380px;
}

#box_wrkflw .btn-group.rgt {
  float: right;
}

#box_wrkflw .btn-group.mrg_rgt {
  margin-right: 10px;
}

#box_wrkflw .btn {
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
}
#box_wrkflw .btn:disabled {
  border: 1px solid transparent;
  background-color: #D5DFE1;
  color: #2E383A;
}

#box_wrkflw .icon {
  font-size: 16px;
  line-height: 16px;
}

#box_wrkflw .btn .icon {
  padding-right: 0;
}

#box_wrkflw .btn em {
  display: none;
  font-style: normal;
  font-weight: 600;
}

#pop_wkfl_sign .check_list label, #sign_gpr_st .check_list label {
  margin: 4px 0 8px 0;
}

#box_wrkflw .btn-group button {
  margin: 0px 4px 8px 4px;
}

.btn-wkf-warning {
  background-color: #FBF2D0;
  color: #5A4B35;
}

.btn-wkf-warning:hover {
  background-color: #FCF8ED;
  color: #5A4B35;
  border: 1px solid transparent;
}

.btn-wkf-warning:focus {
  background-color: #FCF8ED;
  color: #5A4B35;
  border: 1px solid #3EAC93;
}

.btn-wkf-success {
  background-color: #BFF5C5;
  color: #05570A;
}

.btn-wkf-success:hover {
  background-color: #E6FAE8;
  color: #05570A;
  border: 1px solid transparent;
}

.btn-wkf-success:focus {
  background-color: #E6FAE8;
  color: #05570A;
  border: 1px solid #3EAC93;
}

.btn-wkf-danger {
  background-color: #F7BCBA;
  color: #55201E;
}

.btn-wkf-danger:hover {
  background-color: #FAD7D6;
  color: #55201E;
  border: 1px solid transparent;
}

.btn-wkf-danger:focus {
  background-color: #FAD7D6;
  color: #55201E;
  border: 1px solid #3EAC93;
}

.btn-wkf-blocked {
  background-color: #EAEEF0;
  color: #ABC0C4;
}

.btn-wkf-blocked:hover {
  background-color: #EAEEF0;
  color: #ABC0C4;
  border: 1px solid transparent;
}

.btn-wkf-blocked:focus {
  background-color: #EAEEF0;
  color: #ABC0C4;
  border: 1px solid #3EAC93;
}

.btn-wkf-default {
  background-color: #D5DFE1;
  color: #2E383A;
}

.btn-wkf-default:hover {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid transparent;
}

.btn-wkf-default:focus {
  background-color: #EAEEF0;
  color: #2E383A;
  border: 1px solid #3EAC93;
}

form .wkf_msgs_hdr {
  margin-top: 44px;
  cursor: pointer;
  color: #2E383A;
}
form .wkf_msgs_hdr .wkf_msgs_hdr_in {
  display: flex;
  align-items: center;
}
form .wkf_msgs_hdr a {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}
form .wkf_msgs_hdr a i.sl-icon-message-round {
  font-size: 20px;
  color: #2E383A;
}
form .wkf_msgs_hdr a .view_link {
  color: #387B77;
  font-weight: 600;
}
form .wkf_msgs .alert {
  padding: 20px;
}
form .wkf_msgs .alert p, form .wkf_msgs .alert label {
  color: #2E383A;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
form .wkf_msgs .alert p strong, form .wkf_msgs .alert label strong {
  font-weight: 600;
}
form .wkf_msgs .alert .btn-close-ok {
  padding-left: 0;
  display: block;
  font-weight: 600;
  color: #4C6569;
  text-decoration: underline;
}
form .wkf_msgs .alert .col-auto.sl-no-gutters {
  padding-left: 0;
  padding-right: 0;
}
form .wkf_msgs .alert .icon[class*=sl-icon] {
  margin-right: 0;
}
form .wkf_msgs .alert .icon[class*=sl-icon].sl-icon-message-round {
  color: #BAAA8A;
}
form .wkf_msgs .alert a {
  text-decoration: none;
}

/*

	## Variacions responsive ##

*/
@media (min-width: 1000px) {
  #hd_title {
    left: 270px;
    top: 20px;
    font-size: 20px;
    line-height: 20px;
  }
  #hd_options .btn_op,
  #head .elm_qlty,
  #head .elm_qlty {
    min-width: unset;
  }
  #hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle {
    height: 40px;
  }
  #hd_options .btn-group.select button.dropdown-toggle .icon, .btn-group.slc_currency button.dropdown-toggle .icon {
    top: 10px;
  }
  #head .box_msgs {
    margin: 20px 14px 0 0;
  }
  #head .box_msgs button {
    font-size: 20px;
    line-height: 16px;
  }
  #head .box_msgs button i {
    line-height: 35px;
  }
  #Intercom_slyr .glyphicons:before {
    margin-left: 0;
    margin-top: 0;
  }
  #Hubspot_slyr .glyphicons:before {
    margin-left: 0;
    margin-top: 0;
  }
  #box_menu ul ul li a {
    padding-left: 30px;
  }
  #box_content.pop_mode {
    left: 0;
    padding-top: 0;
  }
  #body .box_input_medium .form .frm_gnr_btns {
    left: 0;
  }
  .box_input_medium {
    width: 75%;
  }
  #box_hist_vw {
    width: 300px;
  }
  #box_wrkflw .btn .icon:not(.no_pdg) {
    padding-right: 4px;
  }
  #box_wrkflw .btn .icon.sl-icon-trash:not(.no_pdg) {
    padding-right: 8px;
  }
  #box_wrkflw .btn em {
    display: inline;
  }
  #box_content.frm_rev {
    padding-top: 120px;
  }
  #box_content.hist_vw_act .box_head_sec,
  #box_content.hist_vw_act .box_msg_action,
  #box_content.hist_vw_act .form {
    margin-left: 300px;
    width: auto;
  }
  #box_content.hist_vw_act .box_head_sec {
    width: calc(100% - 300px);
  }
  .minus_left #box_content.pop_mode,
  .minus_left #body #box_content.pop_mode .frm_gnr_btns {
    left: 0;
  }
  .table td {
    font-size: 14px;
    line-height: 17px;
  }
  .table.micro td {
    font-size: 11px;
    line-height: 14px;
  }
}
@media (min-width: 1500px) {
  .box_center.long {
    margin-top: 70px;
  }
  #hd_title {
    left: 300px;
    top: 25px;
    font-size: 22px;
    line-height: 22px;
  }
  #head .btn_rgt button {
    padding: 10px;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
  }
  #notifications.btn_rgt button {
    padding: 10px 5px;
  }
  #head .box_msgs {
    margin: 22px 14px 0 0;
  }
  #head .box_msgs button {
    height: 30px;
    font-size: 13px;
    line-height: 13px;
  }
  #head .box_msgs button {
    font-size: 20px;
    line-height: 16px;
  }
  #head .box_msgs button i {
    line-height: 35px;
  }
  #box_menu .accordion {
    font-size: 90%;
  }
  #box_menu ul ul li a {
    padding-left: 35px;
  }
  #body .box_input_medium .form .frm_gnr_btns {
    left: 0;
  }
  .box_input_medium {
    width: 60%;
  }
  #box_content.frm_rev {
    padding-top: 130px;
  }
  .table td {
    font-size: 16px;
    line-height: 20px;
  }
  .table.micro td {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (min-width: 1900px) {
  .dropdown-toggle .filter-option.title.med {
    max-width: 250px;
  }
}
span.filter-option.pull-left.user_name {
  font-size: 14px;
  line-height: 24px;
}

#head .btn_rgt #notif_button:hover #notif_mark::before, #head .btn_rgt #notif_button:hover .sl-icon-message-round::before, #head .box_msgs #Hubspot_slyr:hover #notif_mark::before, #head .box_msgs #Hubspot_slyr:hover .sl-icon-message-round::before {
  color: #3D9487;
}

.sl-checkbox-small.elm_check {
  margin: initial !important;
  background-color: initial !important;
  width: initial !important;
  height: initial !important;
  border-radius: initial !important;
}

#head #box_lst_usr button.dropdown-toggle, #head #box_lst_usr button.in_sec {
  background: transparent;
  color: #2E383A;
  border: none;
}
#head #box_lst_usr button.dropdown-toggle:hover, #head #box_lst_usr button.in_sec:hover {
  background: transparent;
  color: #2E383A;
  border: none;
}

/*add temporary classes to review*/
.sl-float-right {
  float: right;
}