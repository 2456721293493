/*

	Menú inferior pasos stups

*/

.box_steps {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	overflow: hidden;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 62px;
	border-top: 1px solid #D5DFE1;
	background-color: #F8F8F8;
	z-index: 100;
	/*min-width: 1150px;*/
}

.box_steps .btn_pas {
	position: absolute;
	right: 44px;
}

.box_steps .btn_pas.bx_left {
	left: 44px;
}

.box_steps .btn_pas.bx_left.disabled {
	display: unset;

	a {
		color: #93AFB4;
		pointer-events: none;
  	cursor: default;
	}
}

.box_steps .btn_pas a {
	height: 40px;
	padding: 10.5px 20px;
  background-color: #387B77;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	border-radius: 4px;

	&:hover {
		background-color: #205A5A;
	}
}

.box_steps .btn_pas.bx_left a {
	background-color: transparent;
	color: #2E383A;

	&:hover {
		background-color: #EAEEF0;
	}
}


.box_steps .btns_pas a {
	position: relative;
	overflow: hidden;
	float: left;
	width: fit-content;
	height: 32px;
	margin-left: -10px;
	font-size: 12px;
	line-height: 16px;
}

.box_steps .btns_pas a strong {
	position: relative;
	display: block;
	float: left;
	width: fit-content;
	height: 32px;
	padding: 8px 8px 8px 24px;
	background: #d7dcdf;
	text-align: center;
	color: #4C6569;
	font-weight: normal
}

.box_steps .btns_pas a.ini        { margin-left: 0 }

.box_steps .btns_pas a.ini strong {
	padding-left: 16px;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px
}

.box_steps .btns_pas a.ini .pre   { display: none }

.box_steps .btns_pas a.end strong {
	width: fit-content;
}


.box_steps .btns_pas a strong .pre,
.box_steps .btns_pas a .suf {
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 17px 6px 17px 17px;
	line-height: 0px;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}

.box_steps .btns_pas a strong .pre {
 	position: absolute; top: 0; left: 0;
	border-color: rgba(236,240,241,0) rgba(236,240,241,0) rgba(236,240,241,0) #F8F8F8;
	_border-color: #000000 #000000 #000000 #F8F8F8;
}
.box_steps .btns_pas a .suf {
	position: relative; display: block; float: left;
	border-color: rgba(215,220,223,0) rgba(215,220,223,0) rgba(215,220,223,0) #d7dcdf;
	_border-color: #000000 #000000 #000000 #d7dcdf;
}

.box_steps .btns_pas a.next strong {
	background: #FFFFFF;
	color: #4C6569;
}

.box_steps .btns_pas a.next .suf {
	border-color: rgba(136,147,153,0) rgba(136,147,153,0) rgba(136,147,153,0) #FFFFFF;
	_border-color: #000000 #000000 #000000 #FFFFFF;
}

.box_steps .btns_pas a.dissabled {cursor: default}

.box_steps .btns_pas a.dissabled strong, .box_steps .btns_pas a.dissabled:hover strong {
	background: #FFFFFF;
}
.box_steps .btns_pas a.dissabled .suf, .box_steps .btns_pas a.dissabled:hover .suf {
	border-color: rgba(215,220,223,0) rgba(215,220,223,0) rgba(215,220,223,0) #FFFFFF;
	_border-color: #000000 #000000 #000000 #FFFFFF;
}

// .box_steps .btns_pas a:hover strong {
// 	background: #1bbc9b; color: #fff; -webkit-transition: linear 0.15s; transition: linear 0.15s
// }
// .box_steps .btns_pas a:hover .suf {
// 	border-color: rgba(27,188,155,0) rgba(27,188,155,0) rgba(27,188,155,0) #1bbc9b;
// 	_border-color: #000000 #000000 #000000 #1bbc9b;
// 	-webkit-transition: linear 0.15s; transition: linear 0.15s
// }

.box_steps .btns_pas a.prev strong {
	background-color: #D5DFE1;
	color: #2E383A;
}

.box_steps .btns_pas a.prev .suf {
	border-color: rgba(27,188,155,0) rgba(27,188,155,0) rgba(27,188,155,0) #D5DFE1;
	_border-color: #000000 #000000 #000000 #D5DFE1;
}

.box_steps .btns_pas a.selected strong {
	background: #CFDADD;
	color: #4C6569;
}

.box_steps .btns_pas a.selected .suf {
	border-color: rgba(27,188,155,0) rgba(27,188,155,0) rgba(27,188,155,0) #CFDADD;
	_border-color: #000000 #000000 #000000 #CFDADD;
}

.box_steps .btns_pas .navbar-new {margin: -14px 0 0 0; background: #e74c3c}

.box_steps .btns_pas a.stp_1 {z-index: 6}
.box_steps .btns_pas a.stp_2 {z-index: 5}
.box_steps .btns_pas a.stp_3 {z-index: 4}
.box_steps .btns_pas a.stp_4 {z-index: 3}
.box_steps .btns_pas a.stp_5 {z-index: 2}
.box_steps .btns_pas a.stp_6 {z-index: 1}


/*

	Emp crea db caja idiomas

*/

.box_form_add_db.lng_loc label.checkbox { width: 285px }

.box_form_add_db label.checkbox .icons {top: 7px; left: 10px}

.box_form_add_db label.checkbox:before {
    top: 10px;
    left: 13px;
}

.box_form_add_db label.box_lang_chk {
	width: fit-content;
	background-color: white;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;
	color: #2E383A;
}

.box_form_add_db label.box_lang_chk:hover {
	background-color: #EAEEF0;
	transition: all 0.3s ease;
}

.box_form_add_db label.box_lang_chk .icon {
	font-size: 20px;
	color: #2E383A;
}

.box_top_langs, .box_extra_langs { position: relative; width: 800px;  overflow: hidden; display: none }
.box_top_langs                   { display: block; }

.box_top_langs, .box_extra_langs {
	label {
		background-color: #EAEEF0;
		padding: 8px 12px;
		border-radius: 4px;
		margin: 8px 0;
		width: 100%;
	}
}

.box_form_add_db label.defLg-tit    {
	margin: 0 0 8px 0;
	line-height: 24px;
	font-size: 14px;
}

.box_form_add_db select.defLg-sel {
	position: relative;
	float: left;
	width: auto;
}

.box_form_add_db.defLang a.mrk-help {
	margin-left: 8px;
	height: 20px;
}

.mod_popup .defLang         { margin-bottom: 20px }
.mod_popup .box_top_langs,
.mod_popup .box_extra_langs { width: auto }
.mod_popup .box_form_add_db { margin-bottom: 20px }

/*

	Emp crea db caja catálogo

*/

.box_form_add_db .scroll     { z-index: 2; position: relative; display: none; width: 100%; overflow-x: scroll; overflow-y: hidden; height: 20px }
.box_form_add_db .scroll div { z-index: 1; position: relative; overflow: hidden; height: 1px }
.box_form_add_db .wrap       { position: absolute; top: 0; left: 0; width: auto; overflow: hidden }

.box_form_add_db .box_cat,
.box_form_add_db .box_sub_cat { position: relative; overflow: hidden }

.box_form_add_db .form-group {
	width: 150px;
	margin: 0;
	float: left;
	overflow: hidden;
	margin-right: 25px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.box_form_add_db .fld_btn_sec,
.box_form_add_db .fld_add_sec { margin-bottom: 10px }

.box_form_add_db .fld_btn_sec  {
	font-weight: 600;
  background-color: #FBF2D0;
  color: #5A4B35;
	border: none;
	margin-bottom: 12px;
	text-align: center;
}

.box_form_add_db .fld_btn_sec:hover,
.box_form_add_db .fld_btn_sec:focus  { background: #fff; color: #34495e; border-color: #387B77 }

.box_form_add_db .box_cat .tagsinput-remove-link { display: none }

.box_form_add_db .fld_add_sec { width: 150px }

.box_form_add_db .box_sub_cat { position: relative; overflow: hidden }

.box_form_add_db .box_cat .dec_dtll           { position: absolute; left: 75px; top: 55px; bottom: 10px; border-left: 2px dotted #ABC0C4; width: 0 }
.box_form_add_db .box_cat .sub_dtll           { position: absolute; left: 155px; top: 0; bottom: 10px; width: 10px; border-right: 1px solid #ABC0C4 }
.box_form_add_db .box_cat .sub_dtll .icon_ref { position: absolute; top: 20px; left: 0; width: 100%; border-top: 1px solid #ABC0C4 }

/*

	Emp summary

*/

.box_form_add_db                .ico_status                    { vertical-align: -2px; margin-right: 10px }
.box_form_add_db                .ico_status.fui-check-inverted { color: #5CB85C }
.box_form_add_db .alert-warning .ico_status.fui-cross-inverted { color: #f1c40f }
.box_form_add_db .alert-danger  .ico_status.fui-cross-inverted { color: #E74C3C }

.box_form_add_db .btn_continue { display: inline-block; margin-top: 20px }


div.box_cat_reg {
	position: relative;
	overflow: hidden;
	width: 940px;
	margin: 24px auto 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	color: #4C6569;

	#msg_info_import_cats.box_form_message_info {
		display: flex;
    align-items: start;
		padding: 16px;
		border-radius: 4px;
		background-color: #E9F9FB;
		border: 1px solid #BAEBF5;
		color: #005583;
		font-weight: 400;

		span {
			font-size: 24px;
			line-height: 24px;
			margin-right: 12px;
		}

		p {
			font-size: 14px;
			line-height: 24px;
			text-align: left;
			padding-top: 0;
			color: #005583;
		}
	}
}

.box_cat_reg .box_btn_more_inf {
	position: relative;
	padding-top: 32px;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #387B77;

	a {
		display: flex;
    align-items: center;
    justify-content: center;

		span {
			font-size: 16px;
			line-height: 16px;
			margin-right: 8px;
		}
	}

}

.title_create_company {
	margin-bottom: 60px;
	color: #2E383A;
}
.box_center.long.box_create_company {
	padding: 0;
	margin: 164px 0 70px 0;
	margin-bottom: 70px;
	position: relative;

	h4 {
		font-size: 29px;
		font-weight: 700;
		line-height: 36px;
		color: #2E383A;
		width: 940px;
		margin-left: auto;
		margin-right: auto;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #4C6569;
		padding-top: 24px;
		width: 940px;
		margin-left: auto;
		margin-right: auto;
	}

	.box_form_add_db.defLang {
		margin-top: 60px;
	}

	.box_form_add_db {
		margin-top: 24px;

		.form-group-lang {
			display: flex;
    	align-items: center;

			.defLg-sel {
				width: 474px;
			}

			a span {
				font-size: 20px;
				line-height: 20px;
				color: #4C6569;
			}
		}

	}

	.box_form_add_db.box_form_add_tag {
		margin-top: 50px;

		.tagsinput {
			display: flex;
			flex-wrap: wrap;

			.tag {
				align-items: center;
				width: fit-content;
				margin: 10px 8px 8px 0;
				padding: 8px 12px;
				font-size: 14px;
				line-height: 24px;
				font-weight: 600;
				background-color: #FBF2D0;
				color: #5A4B35;
				height: 40px;

				.tagsinput-remove-link {
					opacity: 1;
					padding: 0;
					position: unset;
					font-size: 16px;
					line-height: 16px;
					font-weight: 400;
				}

				.tagsinput-remove-link:before {
					font-family: "sally-icon";
					content: "\e922";
					color: #5A4B35;
					font-size: 16px;
					line-height: 16px;
					font-weight: 400;
				}

			}
			.tagsinput-add-container {
				padding-top: 10px;

				input::placeholder {
					opacity: 1;
					color: #7B9BA1;
				}
			}

			.tagsinput-add-container input:hover {
				border-color: #CFDADD;
			}
		}

	}

	.box_form_add_db.selectLang {
		margin-top: 24px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: 24px;

		.form-group-lang {
			display: flex;
    	align-items: center;

			.defLg-sel {
				width: 474px;
			}

			a span {
				font-size: 20px;
				line-height: 20px;
			}
		}

		label.checkbox {
			position: relative;
			float: left;
			width: 228px;
			overflow: hidden;
			margin: 0;
			padding: 8px 10px 8px 40px;
			text-align: left;
			border-radius: 4px;
			background: #EAEEF0;
			cursor: pointer;
			color: #2E383A;
			height: 40px;
			font-size: 14px;
			line-height: 24px;

			&::before {
				top: 12px;
				left: 13px;
			}

			.icons {
				top: 9px;
				left: 10px;
			}
		}

		label.checkbox.box_lang_chk {
			background-color: transparent;
			border-radius: 4px;
			width: fit-content;
			padding: 8px 20px;
			font-weight: 600;

			span {
				font-size: 20px;
				line-height: 20px;
				margin-right: 8px;
				font-weight: 400;
			}

			&::before {
				display: none;
			}

			&:hover {
				background-color: #EAEEF0;
			}
		}
	}

	.box_form_add_db.box_form_add_db_summary {
		margin-top: 60px;
		width: 693px;
		margin-left: auto;
		margin-right: auto;
		.alert {
			width: 694px;
			&.alert-success,
			&.alert-warning {
				border: none;
				background-color: #FFFFFF;
				color: #000000;
				padding: 24px;
				margin-bottom: 8px;
				width: 100%;
				h6 {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
					a {
						color: #4C6569;
						font-weight: 600;
					}
				}
			}
		}
	}

	.box_extra_langs_open {
		position: relative;
    overflow: hidden;
    width: 960px;
    margin: 24px auto 90px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: 24px;

		label.checkbox {
			position: relative;
			float: left;
			width: 228px;
			overflow: hidden;
			margin: 0;
			padding: 8px 10px 8px 40px;
			text-align: left;
			border-radius: 4px;
			background: #EAEEF0;
			cursor: pointer;
			color: #2E383A;
			height: 40px;
			font-size: 14px;
			line-height: 24px;

			&::before {
				top: 12px;
				left: 13px;
			}

			.icons {
				top: 9px;
				left: 10px;
			}
		}
	}
}