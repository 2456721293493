strong {
  font-weight: unset;
}

.input-group {
  width: 100%;
}

.nav-tabs>li>a {
  margin-right: 0;
}