/*

	Llistes taula

*/

.table_container {position: relative; width: 100%; overflow: hidden}
.table_container .box_left {float: left}
.table_container .box_right {float: right}
.table_container .box_left,
.table_container .box_right {position: relative; display: inline-block; overflow: hidden; margin: 20px 0}
.table_container .pagination li {margin-right: 0}

.table.no_mrg   { margin: 0 }
.table       th { white-space: nowrap }
.table.micro th { text-align: center }
.table       td { font-size: 14px; line-height: 15px }
.table       tr.fntom { opacity: 0.4; filter: alpha(opacity=40) }

.table tr.success td { background-color: #387B77 !important; color: #fff }

.table.adjust       { width: auto; min-width: 50% }

.table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
    vertical-align: middle;
    padding: 8px 16px;
    font-size: 14px;
	line-height: 24px;
    color: #4C6569;
}

.table tr th {
    font-size: 12px;
    line-height: 20px;
  }
.table tr th.is_number,
.table tr th.sl-text-align-right {
    text-align: right;
  }

.table > thead > tr > th {
    vertical-align: middle;
    padding: 8px 16px;
    font-size: 12px;
	line-height: 20px;
    color: #2E383A !important;
    letter-spacing: 1px;
}

.table > thead.total > tr > th,
.table > tfoot.total > tr > th {
    border: 0;
    border-top: 2px solid #dfdfdf;
}

.table.micro th,
.table.micro td           { font-size: 10px; line-height: 12px; padding: 5px 15px; }
.table.mini  th,
.table.mini  td           { font-size: 80%; line-height: 150%; padding: 5px 15px; }
.table.mini .form-control { font-size: 90%; height: auto; padding: 0 4px; }

.table .order-asc:before,
.table .order-desc:before {
    font-family: "sally-icon";
    color: #2E383A;
    margin-right: 4px;
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.table .order-asc:before  { content: "\e91c" }
.table .order-desc:before { content: "\e91f" }

.table th.clean               { padding: 0 }
.table th.right,
.table td.right               { text-align: right }

.table td.td_mov              { padding: 0; vertical-align: middle; width: 20px; min-width: auto; padding: 9px 0 }
.table td.td_mov button       { border: 0; background: 0; display: block; color: rgba(0,0,0,0.20) }


.table td.td_stat {
    width: 45px; min-width: 40px;
    padding: 16px;
}

.table td.td_stat a i.icon,
.table td.td_stat button i.icon {
    font-size: 16px;
    line-height: 16px;
    color: #CFDADD;
}

.table td.td_file,
.table td.td_check,
.table th.td_check {
    width: 64px;
    padding: 24px;
}

.table td.td_file a i.icon {
    color: #ABC0C4;
    &:hover {
        color: #4C6569;
    }
}

.table td.td_wkfl             { width: 40px; padding: 9px 10px; text-align: center; vertical-align: middle }
.table th.th_cntr,
.table td.td_cntr             { text-align: center; vertical-align: middle }
.table td.td_check .checkbox .icons,
.table th.td_check .checkbox .icons { top: -1px }
.table td.td_ref              { min-width: 100px }

.table td.td_num,
.table td.td_numb {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table td.td_wrap {
    text-wrap: wrap;
}

.table td.td_num  strong      { display: inline-block; min-width: 50%; padding-right:  5px;}
.table td.td_numb a.lnk:link,
.table td.td_numb a.lnk       { color: #34495E }
/*.table td.td_numb em        { font-weight: normal; font-style: normal; color: #586f86 }*/
.table td.td_numb a.lnk:hover { color: #387B77 }

.table td.td_num em {
    display: none;
}

.table td.td_opts             { width: 180px; text-align: right }

.table td.price {
    h6 {
        font-size: 14px;
        line-height: 24px;
        color: #4C6569;
        font-weight: 600;
    }
}

.table td ul                           { overflow: hidden; margin: 0;}
.table td li                           { list-style-type: none; margin: 0 5px 0 0; padding: 0; float: left }
.table td ul.list_horitz.sep           { margin-top: 5px }
.table td ul.list_horitz li            { margin: 0 0 5px 0; float: none }
.table td ul.list_horitz li:last-child { margin: 0}

.table td .append {color: #7B9BA1}

.table td.td_thumb {width: 80px}
.table td.td_thumb .box_img {
    position: relative;
    overflow: hidden;
    background: white;
    top: auto;
    left: auto;
    width: 58px;
    height: 48px;
    min-height: unset;
    border: none;
}
.table td.td_thumb .box_img.img_on  {background: #fff url(../../../img/loader.gif) center center no-repeat}
.tp_fls .table td.td_thumb .box_img {
    background: transparent;
    display: flex;
    align-items: center;
}

.tp_fls .table td.td_thumb .box_img img {
    height:42px;
    width: auto;
}

.table td.td_thumb .box_img span,
.box_thumb .box_img span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table td.td_thumb .box_img span.img_placeh {
    background-size: 22px;
}

.table td.td_thumb .box_img span i  {
    font-size: 22px;
    line-height: 22px;
    padding: 0;
}

.table td.td_thumb .box_img span em       { display: block; padding: 0 5px; font-size: 12px; line-height: 12px; color: #aaa; font-style: italic }
.table td.td_thumb .box_img span em.alter { display: none }

.table td em {
    font-weight: 400;
    color: #4C6569;
}

.table td em.prm,
.table td em.alter                { display: inline }

.table .tr_moving                  { position: fixed; z-index: 1000; display: block; top: 50%; left: 50%;
											 box-shadow: -5px 5px rgba(0, 0, 0, 0.20); opacity: 0.9; filter: alpha(opacity=90) }
.table .tr_moving td.td_mov        { background: #387B77 }
.table .tr_moving td.td_mov button { color: #fff }

.table tbody tr.no_border td {border: 0}
.table tbody tr.in_change td {background: #afe2d8}

.table .move_in {border-bottom: 15px solid #387B77; transition: all 0.25s ease 0s}

.table tbody tr.tr_orig_sz td {background: #bdc3c7; height: 35px; transition: height 0.25s ease 0s}

tbody tr.tr_primal td {
    background: transparent;
    padding: 0;
    height: 0;
    border: 0;
    display: none;
}

.table.mod_on tbody tr.tr_movr.tr_primal td {background: #afe2d8; height: 40px; transition: height 0.25s ease 0s}

.table .td_key     {padding: 8px; font-size: 18px; line-height: 18px; font-weight: bold}
.table .td_btn_opt {width: 100px; padding: 8px; text-align: center}

.table-hover > tbody > tr:hover > td.td_bx_btns {background: transparent}

.table .td_tags .label {display: inline-block; cursor: pointer }

.td_tags.usr_adm ul li {margin-bottom: 3px}

.td_check        .elm_check { margin-left: 0 }

.sl-payment-paragrh .table tbody tr:hover,
.box_list .table tbody tr:hover {
    background-color: #F8F8F8;
}

.box_list .table .elm_check .icons span,
.box_list .table .elm_check .icons span:before  { line-height: 22px; }

.table.st_list_refs .st_ref {width: 15%}
.table.st_list_refs .st_tit {width: 50%}
.table.st_list_refs .st_xtr {width: 35%}

/*  table accounts */

.table.table-accounts                               { background: #fff; border-radius: 6px }
.fld_sep_top_gr .table.table-accounts               { background: transparent; border-radius: 0 }
.table.table-accounts > thead > tr > th,
.table.table-accounts > tbody > tr > td             { border: 0; padding: 6px 35px; border-left: 1px solid #eee; }
.table.table-accounts > thead > tr > th             { text-align: center; vertical-align: top; font-size: 150%; border-bottom: 1px solid #eee }
.table.table-accounts > tbody > tr > td             { text-align: left; font-size: 14px; line-height: 18px; font-weight: normal;  }
.table.table-accounts > tbody > tr > td.uncheck     { font-weight: normal; color: #ccc }

.table.table-accounts > thead > tr > th             { padding-top: 25px; padding-bottom: 25px; }
.table.table-accounts > tbody > tr:first-child > td { padding-top: 25px }

.table.table-accounts > thead > tr > th sub         { font-size: 14px; line-height: 20px; font-weight: normal }
.table.table-accounts > thead > tr > th.selected    { background: #387B77; color: #fff!important; }

.table.table-accounts > tbody > tr > td.check:before,
.table.table-accounts > tbody > tr > td.uncheck:before {
    font-family: 'Glyphicons Regular';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    float: left;
    padding: 0 10px 5px 0;
}
.table.table-accounts > tbody > tr > td.check:before   { content:"\E207"; color: #387B77 }
.table.table-accounts > tbody > tr > td.uncheck:before { content:"\E208"; color: #bdc3c7 }

.table.table-accounts > tbody > tr > td span {  }

/* table stauts */

.table th.tb_prcnt em,
.table td.tb_prcnt em         { position: relative; display: inline; float: left; padding-right: 8px;}
.table th.tb_prcnt .progress,
.table td.tb_prcnt .progress  { margin: 2px 0 0; -webkit-print-color-adjust: exact }

@media (min-width: 1600px) {
    .table td em.alter       { display: nome }
}
@media (max-width: 1000px) {

    .table td.td_num  strong,
    .table td.td_numb strong { width: 100%; text-align: right }
    .table td.td_numb .btn   { display: none }
    .form label em                   { display: none }
}

.sso-accounts-table{
    margin:  0 0 20px 0;
}

.ssoaccount-trash{
    color: #CFDADD;
	font-size: 20px;
}

.ssoaccount-trash:hover,
.ssoaccount-trash:active,
.ssoaccount-trash:focus {
    color: #CFDADD;
}

.sl-icon-reorder {
    font-size: 16px;
    font-weight: 400;
    vertical-align: bottom;
}

#box_users .table td.td_titl {
    width: 200px;
    font-weight: 600;
}

.tr_movr .td_titl .url_ldg {
    display: flex;
    align-items: center;
}

.td_edit {
    width: 0px;
    padding: 0 24px!important;
    i {
        font-size: 16px;
        color: #CFDADD;
    }
}

.teams_link {
    color: #387B77;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    &:hover {
        color: #3D9487;
    }
}

.td_opts .td_opts_btn i {
    color: #ABC0C4;
    &:hover {
        color: #4C6569;
    }
}

.box_list_spc .table.list_regs {
    margin-bottom: 40px;

    .td_numb {
        display: flex;
        align-items: center;
        justify-content: space-between;

            a.url_ldg {
                height: 16px;

                i {
                    color: #CFDADD;
                    font-size: 16px;
                    line-height: 16px;
                }
        }
    }
}

th .sl-icon-organization {
    font-size: 16px;
    font-weight: normal;
}

.td_wkfl {
    i {
        font-size: 16px;
        font-weight: normal;
        color: #4C6569;
        &.text-danger {
            color: #D2322C;
        }
        &.text-muted {
            color: #ABC0C4;
        }
    }
}



.table {
    .td_right {
        text-align: right;
        a {
            display: inline-block;
        }
    }
    .checkbox {
        display: block;
        .icons, .icons:hover {
            cursor: default;
          }
      }
}
