@use '../variables/fonts' as *;
@use '../variables/backgrounds' as *;

html, body {
    box-sizing: border-box;
    background: $background01!important;
    font-size: unset;
    line-height: unset;
}

*, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

body {
    font-family: $fontOpenSans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

/* scrollbar global */
@-moz-document url-prefix() {
    * {
        scrollbar-width: thin;
    }
  }
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
*::-webkit-scrollbar-track {
    background: #f0f0f0;
}
*::-webkit-scrollbar-thumb {
    background: #cdcdcd;
}
*::-webkit-scrollbar-thumb:hover {
    background: #a6a6a6;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none;
}

i {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    margin: unset;
}

p {
    margin: unset;
}

button {
    background-color: unset;
    margin: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
}