@use '../../variables/globals' as *;
@use '../../variables/icons' as *;
@use '../../variables/colors' as *;

.#{$namespace}ct-reply-box-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 4px;
    padding: 12px;
    position: relative;
    background-color: white;
}

.#{$namespace}ct-frame {
  .#{$namespace}ct-reply-box-container {
  border: 1px solid #D5DFE1;
  }
}

.#{$namespace}ct-reply-box-input{
    width: 286px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
    max-height: 130px;
    max-width: 230px;
    font-weight: normal;
    cursor: text;
    color: $neutral600;
    cursor: text;
    &:focus {
      color: $neutral900;
    }
  }

  .#{$namespace}ct-reply-box-context-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 12px;
    gap: 12px;
    border-top: 1px solid #CFDADD;
  }
