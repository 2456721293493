@use '../variables/globals' as *;
@use '../variables/borders' as *;
// GENERIC

$border-map: (
	"01": $border01,
	"02": $border02,
	"03": $border03,
	"04": $border04,
	"05": $border05,
	"06": $border06,
);

@each $key, $val in ($border-map) {
	.#{$namespace}border-#{$key} {
		border: 1px solid $val !important;
	}
}

@each $prop, $abbrev in (top: top, bottom: bottom, left: left, right: right) {
	@each $key, $val in ($border-map) {
		.#{$namespace}border-#{$abbrev}-#{$key} {
			border-top: 0;
			border-bottom: 0;
			border-left: 0;
			border-right: 0;
			border-#{$prop}: 1px solid $val;
		}
	}
}
