@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/spacing' as *;
@use '../variables/typography' as *;
@use '../variables/actions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/borders' as *;
@use '../variables/zindex' as *;
@use '../variables/icons' as *;
@use '../variables/colors' as *;
@use "../mixins/typography" as *;
@use "../mixins/animation" as *;

.#{$namespace}dropdown {
	display: inline-block;
	position: relative;
	&.#{$namespace}dropdown-disabled {
		pointer-events: none;
		button {
			background-color: $neutral100;
			span, i {
				color: $neutral400;
			}
		}
	}
	&.open {
		.#{$namespace}dropdown-menu {
			display: flex !important;
		}
	}
	&-menu {
		position: absolute;
		display: none;
		flex-direction: column;
		min-width: 208px;
		background-color: white;
		border-radius: $borderRadius700;
		box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
		padding: $spacing300 0 $spacing300 0;
		z-index: $zindex500;
		margin-top: $spacing300;
		&[data-direction=down] {
			@extend .#{$namespace}dropdown-menu;
			margin-top: $spacing200;
			@include dropdown-animate("down");
		}
		&[data-direction=down-left] {
			@extend .#{$namespace}dropdown-menu;
			right: 0;
			margin-right: 0;
			@include dropdown-animate("down");
		}
		&[data-direction=up] {
			@extend .#{$namespace}dropdown-menu;
			bottom: 100%;
			margin-bottom: $spacing200;
			@include dropdown-animate("up");
		}
		&[data-direction=up-left] {
			@extend .#{$namespace}dropdown-menu;
			bottom: 100%;
			right: 0;
			margin-right: 0;
			@include dropdown-animate("up");
		}
		.#{$namespace}link {
			&-icon-left {
				margin-right: 8px;
			}
		}
	}
	li {
		&.#{$namespace}dropdown-divider {
			margin: $spacing200 $spacing600 !important;
			background-color: $neutral250;
			height: 1px;
			display: block;
			position: relative;
		}
	}

	&-item {
		@include generate-type("medium");
		margin: 0 $spacing300;
		white-space: nowrap;
		cursor: pointer;
		&:hover {
			background-color: $background01;
			color: $actionPrimaryHover;
		}
		&-selected {
			background-color: $background01;
			color: $actionPrimaryHover;
		}
		a {
			padding: $spacing250 $spacing600;
			width: 100%;
		}
	}
	&-group-title {
		text-transform: uppercase;
		padding: $spacing300 $spacing600;
	}
	&-search {
		padding: $spacing300 $spacing500;
	}
	&.#{$namespace}dropdown-text {
		.#{$namespace}dropdown-toggle {
			&:hover {
					i {
						color: $iconHighlight;
					}
				}
			}
	}
	.#{$namespace}button-tertiary-medium {
		&:hover {
			background-color: $background01;
		}
		&:focus {
			border-color: transparent;
		}
	}
	hr {
		margin: $spacing200 auto;
		color: $border04;
		border: 1px solid;
		width: 80%;
		border-top: none;
		opacity: 1;
	}
	/*fix dropdown library styles*/
	.dropdown-toggle {
		cursor: pointer;
		align-items: center;
		display: flex;
		i {
			display: inline-block;
			line-height: 1em;
		}
		.sl-dropdown-chevron {
			position: absolute;
			right: 20px;
		}
	}
	.dropdown-menu {
		min-width: auto !important;
		width: auto !important;
		right: initial !important;
		li {
			float: none;
			> a {
    			padding: 10px 16px;
			}
		}
	}
	[class^="#{$namespace}tag-upgrade-"] {
		display: inline-flex;
	}
}


.dropdown-menu {
	li {
		.#{$namespace}link {
			&-icon-left {
				margin-right: 8px !important;
			}
		}
	}
}

#actions {
	&.#{$namespace}dropdown-disabled, 
	&.md_highlight &.#{$namespace}dropdown-disabled {
		* {
			transition: none !important;
		}
		pointer-events: none;
		button {
			background-color: $neutral100 !important;
			border-color: $neutral100 !important;
			span, i {
				color: $neutral400;
			}
		}
	}
}