.form table .btn-group.select {
    margin: 0
  }

.form table .btn-group.select button.dropdown-toggle {
    background: transparent;
}
.form table .btn-group.select button.dropdown-toggle:hover {
    background: rgba(0,0,0,0.05);

}
.form table .dropdown-menu a:hover {
    cursor: pointer;
}
.form table td.td_numb {
    text-align: right;
}

/* Modal order detail */

#form_connect td .btn-group.select button.dropdown-toggle,
#form_connect td button.btn-clean {
  padding: 0 6px;
}
#form_connect       td button.btn-clean:hover,
#form_connect       td button.btn-clean:focus,
#connect_order_list td button.btn-clean:hover,
#connect_order_list td button.btn-clean:focus,
#modalStatus           button.btn-clean:hover,
#modalStatus           button.btn-clean:focus {
  color: rgba(0, 0, 0, 0.8);
  cursor: default;
}

#form_connect label.checkbox .first-icon {
    z-index: 1;
}

#head_connect_order td {
    color: #2E383A;
    border: 0 !important;
    .dropdown-toggle {
      padding: 0;
    }
}

#head_connect_order tr:first-child td {
    color: #4C6569;
    font-weight: normal;
    height: 0;
    padding: 16px 16px 0 16px;
}

#head_connect_order tr:last-child td {
    height: 0;
    padding: 0 16px 8px 16px;
}

#head_connect_order td strong {
    font-weight: 600;
}

#head_connect_order_detail table {
    margin-bottom: 0;
}

#head_connect_order_detail td {
    text-align: left;
}

#connect_order_list .table td.td_opts,
#form_connect .table td.td_opts {
    width: auto;
    vertical-align: middle;
    text-align: right;
    padding: 8px 16px;
}

#connect_order_list .table td.td_opts button.btn,
#form_connect .table td.td_opts button.btn {
    padding: 0;
    height: auto;
}

#form_connect .table td.td_ref,
#form_connect .table td.td_title,
#form_connect .table td.td_date,
#form_connect .table td.td_status {
  text-align: left;
}
#head_connect_order_detail td.td_numb,
#form_connect .table td.td_numb,
#form_connect .table td.td_right {
  text-align: right;
}

#form_connect .table td.td_numb .sl-icon-arrowUp::before,
#form_connect .table td.td_numb .sl-icon-arrowDown::before {
  font-size: 16px;
  vertical-align: bottom;
}

#form_connect .table td.td_title {
  display: flex;
  align-items: center;
}
#form_connect .table td.td_title .thumb {
  margin-right: 20px;
  width: 40px;
  height: auto;
}

#head_connect_order_detail td.sl-card-channel-header-logo {
  padding-right: 0;
}
.sl-card-channel-header-logo img {
  height: 32px;
  width: auto;
}