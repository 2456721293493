@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/icons' as *;
@use '../variables/backgrounds' as *;
@use '../variables/typography' as *;
@use '../variables/content' as *;
@use "../variables/fonts" as *;
@use '../variables/zindex' as *;
@use "../mixins/typography" as *;

.select2-container--default .select2-selection--multiple { 
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  border: 1px solid $border04;
  padding: $spacing200 $spacing300;
  @include generate-type('medium');
  color: $neutral900;
  border-radius: $borderRadius300;
  background-color: $white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  .select2-selection__rendered {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 20px);
    overflow: hidden;
  }
  .select2-selection__choice {
    font-family: Open Sans;
    font-size: $fontSize200;
    line-height: $lineHeight100;
    padding: $spacing50 $spacing200;
    max-height: 40px;
    border: none;
    background-color: $background05;
    color: $contentTertiary;
    border-radius: $borderRadius100;
    display: block;
    align-items: center;
    font-weight: $fontSemiBold;
    transition: all 0.3s ease;
    white-space: nowrap;
    align-items: center;
    margin-top: $spacing50;
    margin-bottom: $spacing50;
    margin-left: 0px;
    margin-right: $spacing200;
  }
  .select2-selection__choice__remove {
    order: 2;
    background-color: transparent;
    border: none;
    border-right: none;
    color: $iconTertiary;
    cursor: pointer;
    padding: 0;
    position: relative;
    color: $contentDefault;
    color: $contentTertiary;
    font-size: 0px;
    display: flex;
    align-items: center;
    margin-left: $spacing100;
    float: right;
  }
}


.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: $white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    .select2-selection__choice {
        background-color: $neutral100;
        color: $iconDisable;
    }
    .select2-selection__choice__remove {
        color: $iconDisable;
    }
    
    &.select2-container--focus {
        .select2-selection--multiple {
            border: 1px solid $border04;
            border: 1px solid $border01;
        }
    }
}



.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:focus {
  background-color: transparent;
  color: $contentTertiary;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid $border01;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:before {
    font-family: $fontSallyIcon !important;
    content: "\e01b";
    font-size: $fontSize400;
}

.select2-results__option.select2-results__option[aria-selected='true'] {
  background-color: $border04;
  font-weight: $fontSemiBold;
}

.select2-results__option.select2-results__option--highlighted[aria-selected] {
  background-color: $background05;
}

.select2-results__option.select2-results__option--highlighted.select2-results__option[aria-selected='true'] {
  background-color: $border04;
}

.select2-results__option {
  font-family: Open Sans;
  @include generate-type('medium');
  font-weight: $fontRegular;
  letter-spacing: 0px;
  text-align: left;
  color: $contentDefault;
}

.select2-dropdown {
  background-color: $white;
  border-radius: $borderRadius700;
  border: none;
  box-shadow: 0px 4px 15px rgb(128 128 128 / 25%);
  padding: $spacing300 0;
  z-index: $zindex100;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-bottom: $spacing50;
}
