@use '../variables/globals' as *;
@use '../variables/typography' as *;
@use '../variables/fonts' as *;
@use '../variables/backgrounds' as *;
@use '../variables/content' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/zindex' as *;
@use "../mixins/typography" as *;

.#{$namespace}tooltip {
  position: relative;
  &-tiny {
    font-size: $fontSize400;
  }
  &-content {
    position: absolute;
    background-color: $background09;
    color: $contentInverse;
    @include generate-type("small"); 
    font-family: $fontOpenSans;
    padding: $spacing200 $spacing300;
    border-radius: $borderRadius300;
    width: max-content;
    max-width: 240px;
    z-index: $zindex900;
    word-break: break-word;
    &-light {
      background-color: $background04;
      color: #1E413E;
      border: 1px solid #1E413E;
    }
    &-left {
      margin-left: -8px;
    }
    &-right {
      margin-left: 8px;
    }
    &-top-right {
      transform: translateX(-100%);
      margin-top: 0px;
    }
    &-top-left {
      margin-top: 0px;
    }
    &-top-center {
      transform: translateX(-50%);
      margin-top: 0px;
    }
    &-bottom-right {
      transform: translateX(-100%);
      margin-top: 0px;
    }
    &-bottom-left {
      margin-top: 0px;
    }
    &-bottom-center {
      transform: translateX(-50%);
      margin-top: 0px;
    }
    &-tiny {
      font-size: $fontSize400;
    }
  }
  &:hover .#{$namespace}tooltip-content {
    visibility: visible;
  }
  &-list-item {
    display: flex;
    margin-bottom: $spacing200;
  }
  &-list-item:last-child {
    margin-bottom: 0px;
  }
  &-list-icon {
    font-size: $lineHeight100;
    padding-right: $spacing300;
  }
}
