@use '../variables/fonts' as *;

[class^="sl-icon-"]:before,
[class*=" sl-icon-"]:before {
  font-family: $fontSallyIcon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sl-icon-connect:before {
  content: "\e97b";
}
.sl-icon-connect-filled:before {
  content: "\e97c";
}
.sl-icon-box:before {
  content: "\e97d";
}
.sl-icon-folderupload:before {
  content: "\e97e";
}
.sl-icon-play:before {
  content: "\e97f";
}
.sl-icon-shoppingbasket:before {
  content: "\e980";
}
.sl-icon-forward:before {
  content: "\e977";
}
.sl-icon-rewind:before {
  content: "\e978";
}
.sl-icon-star:before {
  content: "\e979";
}
.sl-icon-star-filled:before {
  content: "\e97a";
}
.sl-icon-database:before {
  content: "\e900";
}
.sl-icon-drag:before {
  content: "\e901";
}
.sl-icon-hr:before {
  content: "\e902";
}
.sl-icon-line-curved:before {
  content: "\e903";
}
.sl-icon-resize:before {
  content: "\e904";
}
.sl-icon-row:before {
  content: "\e905";
}
.sl-icon-users:before {
  content: "\e906";
}
.sl-icon-add:before {
  content: "\e907";
}
.sl-icon-arrow-down:before {
  content: "\e908";
}
.sl-icon-arrow-left-right:before {
  content: "\e909";
}
.sl-icon-arrow-up-circle:before {
  content: "\e90a";
}
.sl-icon-arrow-up-down:before {
  content: "\e90b";
}
.sl-icon-arrow-up:before {
  content: "\e90c";
}
.sl-icon-bar-chart-filled:before {
  content: "\e90d";
}
.sl-icon-bar-chart:before {
  content: "\e90e";
}
.sl-icon-bill:before {
  content: "\e90f";
}
.sl-icon-book-open:before {
  content: "\e910";
}
.sl-icon-bookmark:before {
  content: "\e911";
}
.sl-icon-boolean:before {
  content: "\e912";
}
.sl-icon-branch-filled:before {
  content: "\e913";
}
.sl-icon-branch:before {
  content: "\e914";
}
.sl-icon-building:before {
  content: "\e915";
}
.sl-icon-calendar:before {
  content: "\e916";
}
.sl-icon-chat-check:before {
  content: "\e917";
}
.sl-icon-check-double:before {
  content: "\e918";
}
.sl-icon-check:before {
  content: "\e919";
}
.sl-icon-checkbox-blank:before {
  content: "\e91a";
}
.sl-icon-checkbox:before {
  content: "\e91b";
}
.sl-icon-chevron-down:before {
  content: "\e91c";
}
.sl-icon-chevron-left:before {
  content: "\e91d";
}
.sl-icon-chevron-right:before {
  content: "\e91e";
}
.sl-icon-chevron-up:before {
  content: "\e91f";
}
.sl-icon-clock-filled:before {
  content: "\e920";
}
.sl-icon-clock:before {
  content: "\e921";
}
.sl-icon-close:before {
  content: "\e922";
}
.sl-icon-code:before {
  content: "\e923";
}
.sl-icon-copy-filled:before {
  content: "\e924";
}
.sl-icon-copy:before {
  content: "\e925";
}
.sl-icon-divide:before {
  content: "\e926";
}
.sl-icon-download:before {
  content: "\e927";
}
.sl-icon-earth:before {
  content: "\e928";
}
.sl-icon-edit-2:before {
  content: "\e929";
}
.sl-icon-edit-box:before {
  content: "\e92a";
}
.sl-icon-edit:before {
  content: "\e92b";
}
.sl-icon-enter:before {
  content: "\e92c";
}
.sl-icon-error:before {
  content: "\e92d";
}
.sl-icon-exportar:before {
  content: "\e92e";
}
.sl-icon-external-link:before {
  content: "\e92f";
}
.sl-icon-eye-close:before {
  content: "\e930";
}
.sl-icon-eye:before {
  content: "\e931";
}
.sl-icon-file:before {
  content: "\e932";
}
.sl-icon-filter:before {
  content: "\e933";
}
.sl-icon-fingerprint:before {
  content: "\e934";
}
.sl-icon-folder-filled:before {
  content: "\e935";
}
.sl-icon-folder-open:before {
  content: "\e936";
}
.sl-icon-folder:before {
  content: "\e937";
}
.sl-icon-font:before {
  content: "\e938";
}
.sl-icon-formula:before {
  content: "\e939";
}
.sl-icon-fullscreen:before {
  content: "\e93a";
}
.sl-icon-gift:before {
  content: "\e93b";
}
.sl-icon-grid:before {
  content: "\e93c";
}
.sl-icon-home-filled:before {
  content: "\e93d";
}
.sl-icon-home:before {
  content: "\e93e";
}
.sl-icon-image-filled:before {
  content: "\e93f";
}
.sl-icon-image:before {
  content: "\e940";
}
.sl-icon-import:before {
  content: "\e941";
}
.sl-icon-info:before {
  content: "\e942";
}
.sl-icon-layer:before {
  content: "\e943";
}
.sl-icon-layer-filled:before {
  content: "\e944";
}
.sl-icon-layout-2:before {
  content: "\e945";
}
.sl-icon-layout-filled:before {
  content: "\e946";
}
.sl-icon-layout:before {
  content: "\e947";
}
.sl-icon-leave:before {
  content: "\e948";
}
.sl-icon-link:before {
  content: "\e949";
}
.sl-icon-links:before {
  content: "\e94a";
}
.sl-icon-list-2:before {
  content: "\e94b";
}
.sl-icon-list:before {
  content: "\e94c";
}
.sl-icon-lock:before {
  content: "\e94d";
}
.sl-icon-unlock:before {
  content: "\e981";
}
.sl-icon-log-out:before {
  content: "\e94e";
}
.sl-icon-menu:before {
  content: "\e94f";
}
.sl-icon-message-filled:before {
  content: "\e950";
}
.sl-icon-message-round:before {
  content: "\e951";
}
.sl-icon-message:before {
  content: "\e952";
}
.sl-icon-more-option-horizontal:before {
  content: "\e953";
}
.sl-icon-more-option-vetical:before {
  content: "\e954";
}
.sl-icon-move:before {
  content: "\e955";
}
.sl-icon-node:before {
  content: "\e956";
}
.sl-icon-notification:before {
  content: "\e957";
}
.sl-icon-number-1:before {
  content: "\e958";
}
.sl-icon-numbers:before {
  content: "\e959";
}
.sl-icon-pin-filled:before {
  content: "\e95a";
}
.sl-icon-pin:before {
  content: "\e95b";
}
.sl-icon-placeholder:before {
  content: "\e95c";
}
.sl-icon-printer:before {
  content: "\e95d";
}
.sl-icon-question:before {
  content: "\e95e";
}
.sl-icon-redo:before {
  content: "\e95f";
}
.sl-icon-refresh:before {
  content: "\e960";
}
.sl-icon-related:before {
  content: "\e961";
}
.sl-icon-restore:before {
  content: "\e962";
}
.sl-icon-ruler:before {
  content: "\e963";
}
.sl-icon-search:before {
  content: "\e964";
}
.sl-icon-setting:before {
  content: "\e966";
}
.sl-icon-settings-2:before {
  content: "\e966";
}
.sl-icon-stop:before {
  content: "\e967";
}
.sl-icon-table:before {
  content: "\e968";
}
.sl-icon-tag-filled:before {
  content: "\e969";
}
.sl-icon-tag:before {
  content: "\e96a";
}
.sl-icon-testing:before {
  content: "\e96b";
}
.sl-icon-text:before {
  content: "\e96c";
}
.sl-icon-tools:before {
  content: "\e96d";
}
.sl-icon-trash:before {
  content: "\e96e";
}
.sl-icon-undo:before {
  content: "\e96f";
}
.sl-icon-unlink:before {
  content: "\e970";
}
.sl-icon-upload:before {
  content: "\e971";
}
.sl-icon-user:before {
  content: "\e972";
}
.sl-icon-variants-filled:before {
  content: "\e973";
}
.sl-icon-variants:before {
  content: "\e974";
}
.sl-icon-warning-filled:before {
  content: "\e975";
}
.sl-icon-warning:before {
  content: "\e976";
}
.sl-icon-radioblank:before {
  content: "\e983";
}
.sl-icon-radioselected:before {
  content: "\e984";
}
.sl-icon-account:before {
  content: "\e985";
}
.sl-icon-add-category:before {
  content: "\e986";
}
.sl-icon-add-company:before {
  content: "\e987";
}
.sl-icon-add-page:before {
  content: "\e988";
}
.sl-icon-add-point-of-sale:before {
  content: "\e989";
}
.sl-icon-add-product:before {
  content: "\e98a";
}
.sl-icon-add-user:before {
  content: "\e98b";
}
.sl-icon-add-variant:before {
  content: "\e98c";
}
.sl-icon-checkbox-multiple:before {
  content: "\e98d";
}
.sl-icon-color-picker:before {
  content: "\e98e";
}
.sl-icon-download-database:before {
  content: "\e98f";
}
.sl-icon-download-file:before {
  content: "\e990";
}
.sl-icon-download-image:before {
  content: "\e991";
}
.sl-icon-download-information:before {
  content: "\e992";
}
.sl-icon-export-configuration:before {
  content: "\e993";
}
.sl-icon-export-csv:before {
  content: "\e994";
}
.sl-icon-export-excel:before {
  content: "\e995";
}
.sl-icon-filter-off:before {
  content: "\e996";
}
.sl-icon-key:before {
  content: "\e997";
}
.sl-icon-merge:before {
  content: "\e998";
}
.sl-icon-organization:before {
  content: "\e999";
}
.sl-icon-page-import:before {
  content: "\e99a";
}
.sl-icon-ai:before {
  content: "\e99b";
}
.sl-icon-arrow-right:before {
  content: "\e99c";
}
.sl-icon-connect1:before {
  content: "\e99d";
}
.sl-icon-connect1-filled:before {
  content: "\e99e";
}
.sl-icon-email:before {
  content: "\e99f";
}
.sl-icon-money:before {
  content: "\e9a2";
}
.sl-icon-arrowDown:before {
  content: "\e9a0";
}
.sl-icon-arrowUp:before {
  content: "\e9a1";
}
.sl-icon-chat:before {
  content: "\e9a3";
}
.sl-icon-dashboard:before {
  content: "\e9a4";
}
.sl-icon-integration:before {
  content: "\e9a5";
}
.sl-icon-cart:before {
  content: "\e9a6";
}
.sl-icon-store:before {
  content: "\e9a7";
}
.sl-icon-share:before {
  content: "\e9a8";
}
.sl-icon-file-import:before {
  content: "\e9a9";
}
.sl-icon-zip:before {
  content: "\e9aa";
}
.sl-icon-family:before {
  content: "\e9ab";
}
.sl-icon-exportexcel:before {
  content: "\e9ac";
}
.sl-icon-downloadfile:before {
  content: "\e9ad";
}
.sl-icon-downloadimage:before {
  content: "\e9ae";
}
.sl-icon-exportcsv:before {
  content: "\e9af";
}
.sl-icon-exportpdf:before {
  content: "\e9b0";
}
.sl-icon-filter-line:before {
  content: "\e9b1";
}