/*  ## Bootstrap Select */

.btn-group.bootstrap-select                         { margin-top: 0 }
.btn-group.bootstrap-select button                  { color: #34495e; background: #fff; border: 2px solid #bdc3c7; border-radius: 6px; padding: 8px 24px 8px 12px }
.btn-group.bootstrap-select.open button,
.btn-group.bootstrap-select button:focus            { border-color: #387B77; color: #34495e }
.btn-group.bootstrap-select .dropdown-toggle        { z-index: 101 }
.btn-group.bootstrap-select .bs-searchbox           { padding: 12px }
.btn-group.bootstrap-select > .dropdown-menu.open   { z-index: 101; margin-top: 5px !important; overflow: hidden }
.btn-group.bootstrap-select .open >  .dropdown-menu { margin-top: 0 !important; overflow: hidden }
.btn-group.bootstrap-select .h-limit .dropdown-menu { max-height: 250px }

body .btn-group.bootstrap-select { z-index: 100000 }

.bootstrap-select      .open { display: none  } /* evitem bug */
.bootstrap-select.open .open { display: block }

#box_content .box_form .btn-group.bootstrap-select .dropdown-menu { margin-bottom: 10px }

.sl-select {
    select, 
    select.form-control {
        -ms-overflow-style: unset;
        background-image: unset;
        background-repeat: unset;
        background-position: unset;
        background-size: unset;
        /* min-width: fit-content; */
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0 1em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
    }
}

