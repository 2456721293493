/*

	Llistes visuals

*/

.box_list {
	position: relative;
	width: 100%;
	overflow: hidden;
	clear: left;
	min-height: 150px;
	padding: 14px 44px 44px 44px;
	&.tp_activity {
		padding-top: 24px;
	}
}

#box_pay_hist {
	&.tp_activity {
		padding-top: 19px;
	}
}

.box_list.box_list_sales_material {
	padding: 40px 44px 44px 44px;
	overflow: inherit;

	#list_types {
		margin-bottom: 40px;

		.td_numb {
			display: flex;
			align-items: center;
			justify-content: space-between;

			em {
				height: 16px;
				
				a.url_ldg i {
					color: #CFDADD;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
	}
}

.box_list.box_img_list {
	padding: 14px 28px 44px 28px;
}

.box_list.md_ls { padding-right: 40px }

       .box_list.invisible            { visibility: visible }
       .box_list.invisible .box_elm,
.modal .box_list.invisible .bx_image  { visibility: hidden }

.box_list .box_elm:not(.sl-card) {
	position: relative;
	overflow: hidden;
	float: left;
	width: 250px;
	height: fit-content !important;
	margin: 0;
	padding: 16px;
	background-color: transparent;
	border-radius: 6px;
	.sl-thumbnail {
		max-width: 100%;
		width: 100%;
	}
	.elm_check,
	.box_inf_more {
		display: none;
	}

	.elm_check.checked {
		display: flex;
	}

	&:hover {
		background-color: #EAEEF0;
		.box_inf_details > .box_inf_ref {
			display: none;
		}
		.box_inf_details > .box_inf_more,
		.elm_check {
			display: flex;
		}
	}
}

.box_list .box_img {
	border-radius: 6px;
	border: 1px solid #D5DFE1;
	min-height: 175px;
	background-color: white;

}
.box_list  .box_img.img_on         { background: #fff url(../../../img/loader.gif) center center no-repeat }

.box_list .box_img span {
	position: absolute;
	top: 56px;
	left: 30px;
	color: #4C6569;
	font-size: 12px;
	line-height: 20px;
	&.img_alert {
		top: 28px;
		left: unset;
		right: 30px;
		i {
			font-size: 20px;
			color: #D2322C;
		}
	}
}

.box_thumb .box_img span .icon,
.box_list .box_img span .icon {
	vertical-align: middle;
	padding-right: 4px;
}

.box_list  .box_img img {
	width: 100%;
	height: 100%;
	border-radius: 6px;
}

.box_list .box_img span.img_placeh {
	display: block;
	width: 100%;
	height: 100%;
	background-image: url(../../../img/placeholder_02.png);
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
	position: unset;
	border-radius: 6px;
}

.box_list.tp_fls .box_elm .box_img { 
	background: white;
	text-align: center; 
}

.box_list.tp_fls .box_elm .box_img a { 
	display: flex; 
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.box_list.tp_fls .box_elm .box_img img { 
	height: 87px; 
	width: auto;
}

.td_thumb.st_Er .box_img img,
.vw_img.st_Er            img,
.box_elm.st_Er  .box_img img,
                         img.st_Er { opacity: 0.3; filter: alpha(opacity=30) }

.box_list       .elm_check { margin-left: 0 }

.box_list.md_vw .box_bar {
	position: absolute;
	top: 28px;
	left: 30px;
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.box_list.md_vw .elm_check {
	margin: 0px;
	background-color: #D5DFE1;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	&.checkbox:before {
		top: 4px;
		left: 4px;
	}
}

.box_list.md_vw.tp_imgs .elm_check,
.box_list.md_vw.tp_fls .elm_check {
	margin: 0px;
}

.box_list       .elm_check .icons { width: 25px; height: 22px }
.box_list.md_vw .elm_check .icons {
	position: relative;
	display: block;
	background: transparent;
	border: none;
	border-radius: 4px;
	overflow: hidden;
}
.box_list .elm_check .icons span,
.elm_check .icons span { 
	width: auto; 
	height: 30px; 
	overflow: hidden; 
	font-size: 24px; 
	line-height: 22px 
}
.box_list.md_vw .elm_check .icons span { 
	font-size: unset; 
	line-height: unset; 
	width: unset;
    height: unset; 
	top: 1px;
	left: 1px;
}

.box_list       .elm_check .icons .fui-checkbox-unchecked:before { content: "\E034"; color: rgba(0, 0, 0, 0.10) }
.box_list       .elm_check .icons .fui-checkbox-checked:before,
.box_list       .elm_check .icons .fui-checkbox-unchecked:before { line-height: 30px } /* <-- 29px no! */
.box_list.md_vw       .elm_check .icons .fui-checkbox-checked:before,
.box_list.md_vw       .elm_check .icons .fui-checkbox-unchecked:before { line-height: unset }

.box_list .bx_stat,
.box_list .elm_imga {
	position: relative;
	display: block;
	width: 24px;
	height: 24px;
}

.box_list .elm_stat {
	width: 24px;
	height: 24px;
}

.elm_stat.open {
	right: -20px;
}

.box_list          .elm_stat.disabled        { display: inline-table }
.box_list          .elm_stat.disabled button { cursor: auto }

#head .elm_qlty,
#head .elm_info,
.box_list.md_vw .elm_qlty {
	width: auto;
	border-radius: 4px;
	background-color: #BFF5C5;
	color: #05570A;
	font-size: 12px;
	line-height: 14px;
	padding: 2px 8px;
}

#head .elm_qlty,
#head .elm_info {
	position: absolute;
	left: 26px;
	top: 26px;
}

#head .elm_qlty {
	display: flex;
	align-items: center;
}

#head              .elm_qlty               { min-width: 74px; transition: background 0.25s ease 0s }
#head              .elm_qlty i:not(.icon),
#head              .elm_info i:not(.icon),
.box_list.md_vw    .elm_qlty i:not(.icon)  { font-size: 100% }

.box_list.md_vw .elm_qlty,
.box_list.md_vw .elm_wkfl {
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
}
.box_list.md_vw    .elm_qlty i.icon,
.box_list.md_vw    .elm_wkfl i.icon        { padding-right: 0; line-height: 100%; vertical-align: middle }
.td_qlty           .elm_qlty i.icon        { padding-right: 0 }

#head .elm_qlty,
#head .elm_info {
	position: relative;
	left: auto;
	top: auto;
	overflow: hidden;
	border-radius: 4px;
	font-size: 14px;
	line-height: 24px;
	height: 40px;
	padding: 8px 12px;
	font-weight: 600;
}


#head .elm_qlty,
.box_list.md_vw .elm_qlty {
	background-color: #BFF5C5;
	color: #05570A;
}

#head .elm_info,
#head .elm_qlty.good,
.box_list.md_vw .elm_qlty.good {
    background-color: #FFD9A0;
    color: #653600;
}

#head .elm_qlty.bad,
.box_list.md_vw .elm_qlty.bad {
	background-color: #F7BCBA;
	color: #55201E;
}

#head              .elm_qlty.prv  { background: rgba(0,0,0,0.15) }

.box_list.md_ls    .td_qlty       { white-space: nowrap;}

.box_list.md_ls .td_qlty.qlty_related_items { 
	display: table-cell; 
}

.box_list.md_ls .elm_qlty      { 
	background-color: #BFF5C5;
    color: #05570A;;	
	font-size: 12px;
    line-height: 20px;
    padding: 4px 8px;
    max-height: 24px;
	border-radius: 4px;
    display: flex;
    align-items: center;
	width: fit-content;
    font-weight: 600;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.box_list.md_ls .elm_qlty.good { 
	background-color: #FFD9A0;
	color: #653600;
}

.box_list.md_ls .elm_qlty.bad { 
	background-color: #F7BCBA; 
	color: #55201E; 
}

.box_list.md_ls    .btn-xs        { padding: 5px 7px }

.box_list.md_vw .elm_stat,
.box_list.md_vw .elm_imga,
.bx_conf_img .top_btns {
	position: absolute;
	left: auto;
	right: 28px;
	top: 28px;
}
.box_list.md_vw .elm_wkfl { 
	position: absolute;
	bottom: 20px;
	right: 16px;
	border: 0;
}

.box_list.md_vw .elm_wkfl i.icon {
	font-size: 16px;
	line-height: 16px;
	color: #4C6569;
	font-weight: 400;
	&.text-danger {
		color: #D2322C;
	}
	&.sl-icon-check {
		color: #0BA815;
	}
}

.bx_conf_img       .top_btns { top: 10px }

.box_list.md_vw    .elm_wkfl,
.box_list.md_vw    .box_inf   { z-index: 1 }
.box_list.md_vw    .elm_stat,
.box_list.md_vw    .elm_qlty,
.box_list.md_vw    .elm_check { z-index: 2 }

.box_list .elm_stat button,
.box_list .elm_imga button,
.bx_conf_img .top_btns button {
	position: relative;
	overflow: hidden;
	float: left;
	padding: 3px;
	width: 24px;
	height: 24px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	border-radius: 4px;
	display: none;
	background: #D5DFE1;
}

.box_list .bx_stat .elm_stat button,
.box_list .bx_stat .elm_imga button,
.bx_conf_img       .top_btns button  {margin-left: 2px}

.box_list.md_vw    .elm_stat button,
.box_list.md_vw    .elm_imga button,
.bx_conf_img       .top_btns button  {
	width: 24px; 
	height: 24px; 
	font-size: 16px;
	border-radius: 4px;
	margin-right: 3px;
}


.box_list .elm_stat.disabled button,
.box_list .elm_stat.disabled button.st_D.active  {
	opacity: 0.5;
}


.box_list.md_vw .elm_stat button:last-child {
	margin-right: 0;
}

.box_list .elm_stat button.slct,
.box_list .elm_stat button.active {display: block}

.btn-st_V, .btn-st_I, .btn-st_D { color: #4C6569 }

.box_list .elm_stat i.st_V:before,
.box_list .elm_stat button.st_V:before {
	color: #4C6569;
	font-family: 'sally-icon';
	font-weight: 400;
	content: "\e931";
}

.btn-st_V,
.box_list .elm_stat i.st_V,
.box_list .elm_stat button.st_V, 
.box_list .elm_stat button.st_V.active {background: #BFF5C5}
.btn-st_V:hover,
.box_list .elm_stat button.st_V:hover  {background: #E6FAE8!important}

.box_list .elm_stat i.st_I:before,
.box_list .elm_stat button.st_I:before {
	color: #4C6569;
	font-family: 'sally-icon';
	font-weight: 400;
	content: "\e930";
}
.btn-st_I,
.box_list .elm_stat i.st_I,
.box_list .elm_stat button.st_I.active {background: #D5DFE1} /*{background: #E74C3C}*/
.btn-st_I:hover,
.box_list .elm_stat button.st_I:hover  {background: #EAEEF0} /*{background: #ff7162}*/

.box_list .elm_stat i.st_D:before,
.box_list .elm_stat button.st_D:before {
	color: #4C6569;
	font-family: 'sally-icon';
	font-weight: 400;
	content: "\e92b";
}
.btn-st_D,
.box_list .elm_stat i.st_D,
.box_list .elm_stat button.st_D,
.box_list .elm_stat button.st_D.active {background: #FBF2D0; color: #4C6569}
.btn-st_D:hover,
.box_list .elm_stat button.st_D:hover  {background: #FCF8ED!important; color: #4C6569}

.box_list    .elm_imga button,
.bx_conf_img .top_btns button          {display: block}
.box_list    .elm_imga button:before,
.bx_conf_img .top_btns button:before   {font-family: 'Glyphicons Regular'; content: "\E093"}

.box_list    .elm_imga button,
.bx_conf_img .top_btns button          {background: #D5DFE1}
.box_list    .elm_imga button:hover,
.bx_conf_img .top_btns button:hover    {background: #EAEEF0}

.box_list    .elm_imga button.st_P,
.bx_conf_img .top_btns button.st_P        {background: #D5DFE1; cursor: not-allowed}
.box_list    .elm_imga button.st_P:hover,
.bx_conf_img .top_btns button.st_P:hover  {background: #EAEEF0}

.txt_st_V { color: #4C6569 }
.txt_st_I { color: #4C6569 }
.txt_st_D { color: #4C6569 }

.box_list .box_inf {
	width: 100%;
	min-height: 60px;
	color: #fff;
	padding: 12px 0px 0px 0px;
}
.box_list span.legacy-h7 { display: block; font-size: 14px; line-height: 18px; overflow: hidden; text-overflow: ellipsis }

.box_list span.legacy-h7 a {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #2E383A;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.box_list .box_inf span.legacy-h7 a             { display: block }

.box_list .ttl_min_ref {
	position: relative;
	display: block;
	width: 100%;
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	color: #4C6569;
	max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box_list ul.st_stat {
	margin: 0px;
}

.box_list i.st_tip, .box_list i.st_tip a,
.box_list ul.st_stat li, .box_list ul.st_stat a {
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	color: #4C6569;
}

.box_list ul.st_stat {display: block; width: 100%;}
.box_list ul.st_stat li {
	float: left;
	list-style-type: none;
	padding: 0px 16px 0px 0px;
	margin: 0px;
	white-space: nowrap;
}

.box_list ul.st_stat li {
	.icon {
		vertical-align: middle;
		margin-right: 4px;
		font-size: 16px;
		font-weight: 400;
	}
}

.box_list.md_vw .box_elm label {padding: 0}

.box_list .table {margin-bottom: 10px}

.box-img-center { display: flex; width: 100%; height: 100%; justify-content: center; align-items: center }

.img_zoom { position:relative; 	display: inline-block; }
.img_zoom:hover:before { 	
	content: "";
	position: absolute; 
	background: rgba(0,0,0,0.40); 
	width:100%;
	height:100%;
}
.img_zoom:hover:after { 
	content: "\e964";
	font-family: 'sally-icon' !important;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fff;
	left:calc(50% - 8px);
	top:calc(50% - 8px);
	position:absolute;
}

.modal.mod_popup {
	.img_zoom_cont_thumb {
		width: 226px;
		height: 188px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		&:hover:before { 	
			z-index: 99;
			border-radius: 6px;
		}
		&:hover:after { 	
			z-index: 999;
		}
	}
}

.box_list.tp_dasbhoard_companies {
	padding-top: 0;
	padding-right:20px;
}