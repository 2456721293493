
$grid-gutter-width: 30px;
$grid-columns: 12;
$enable-grid-classes: true;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1500px
);
$grid-breakpoints: (
    xs: 576px,
    sm: 940px,
    md: 1150px,
    lg: 1440px,
    xl: 1700px,
    xxl: 2240px
);