@use '../variables/globals' as *;

.#{$namespace}checkbox-small {
    &.elm_check {
        margin: initial !important;
        background-color: initial !important;
        width: initial !important;
        height: initial !important;
        border-radius: initial !important;
    }
}

#head {
    #box_lst_usr {
        button {
            &.dropdown-toggle, &.in_sec {
                background: transparent;
                color: #2E383A;
                border: none;
                &:hover {
                    background: transparent;
                    color: #2E383A;
                    border: none;
                }
            }
        }
    }
}