@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/actions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/typography' as *;
@use "../mixins/typography" as *;

.#{$namespace}text-field-group {
    display: flex;
    flex-direction: row;
        flex-wrap: wrap;
        &-right {
            .text-field-input-wrapper, .select {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            }
        }
        &-left {
            .text-field-input-wrapper, .select {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            }
        }
        label, .#{$namespace}text-field-help, .#{$namespace}text-field-error {
            width: 100%;
        }
        input {
            height: 38px;
        }
    button {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center; 
    }
    .#{$namespace}prefix {
        border: 1px solid $border04;
        border-top-left-radius: $borderRadius300;
        border-bottom-left-radius: $borderRadius300;
        border-right: none;
        height: 40px;
        display: flex;
        align-items: center;
        padding-right: $spacing400;
        padding-left: $spacing400;
    }
    .#{$namespace}suffix {
        border: 1px solid $border04;
        border-top-right-radius: $borderRadius300;
        border-bottom-right-radius: $borderRadius300;
        border-left: none;
        height: 40px;
        display: flex;
        align-items: center;
        padding-right: $spacing400;
        padding-left: $spacing400;
    }
    .#{$namespace}suffix, .#{$namespace}prefix {
	    @include generate-type("medium");
        background-color: $white;
        font-weight: $fontRegular;
        letter-spacing: 0px;
        text-align: left;
    }
        .#{$namespace}select {
        margin-bottom: $spacing200;  
        }
    &.disabled {
        .#{$namespace}text-field-input-wrapper, .#{$namespace}prefix, .#{$namespace}suffix, .select {
            background: $actionSecondaryHover;
        }
        label, .#{$namespace}prefix, .#{$namespace}suffix, select {
            color: $background03;
        }
        button {
            color: $background03;
            background: $border04;
        }
    }
}

