/*

    Extras

*/

#box_back_img, #box_back_all {
    position: fixed; left: 0; bottom: 0; width: 100%; height: 100%; background: #f8f8f8;/* #e1e4e5;*/
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none;
    -ms-user-select: none; user-select: none; -o-user-select: none; pointer-events: none;
}
#box_back_img img,
#box_back_all img {width: 100%}
#box_back_all img {width: 100%; height: auto; max-width: none !important; max-height: none !important; visibility: hidden}


/*

    Cuerpo

*/
/*#body, #body_content {position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden}*/


/*

    Formulari en revisió

*/

#box_content.frm_rev { padding-top: 110px }

/*

    Traduccions

*/
#box_content.bx_translt .has_preview  { margin-bottom: 10px }
#box_content.bx_translt .auto_trn_fld { position: relative; display: inline; vertical-align: 14px }

/*

    Cerques de camps

*/

.in_srch.col-fld  > label,
.in_srch .col-fld > label  { font-weight: 600; background: rgba(255,255,0,0.5); border-radius: 5px; margin-bottom: 11px; line-height: 1.8; padding: 0 8px; margin-right: 10px }
.in_srch .field_ops        { vertical-align: 20px }
.titlecrumb.in_srch { background: rgba(255, 255, 0, 0.5); border-radius: 5px; line-height: 1.8; padding: 0 4px;}

#fld_bx_srch {
    position: relative;
    display: flex;
    align-items: center;
    float: left;
    background-color: #ffffff;
    max-height: 40px;
    border: 1px solid #D5DFE1;
    padding: 8px 12px;
    border-radius: 4px;

}

#fld_bx_srch:focus-within {
	border: 1px solid #3EAC93;
    color: #2E383A;
}

#fld_bx_srch > i.icon {
    position: relative;
    display: inline-block;
    float: left;
    font-size: 16px;
    color: #ABC0C4;
}

#fld_bx_srch_rwn > i.icon, #fld_bx_srch_frw > i.icon {
    color: #2E383A;
}



// #fld_bx_srch_rwn:hover, #fld_bx_srch_frw:hover {
//     background-color: #ABC0C4;
// }

#fld_bx_srch:focus-within > button i.icon {
    color: #2E383A;
}

#fld_bx_srch.focus > i.icon {
    color: #2E383A
}

#fld_bx_srch > input {
    position: relative;
    float: left;
    background: transparent;
    border: 0;
    width: auto;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    color: #2E383A;
    &::placeholder {
        color: #7B9BA1;
    }
}

#fld_bx_srch.err_find > input { border-bottom-color: rgba(217,41,30,0.8); color: rgba(217,41,30,0.8) }



#fld_bx_srch > input:focus {
    border-color: #3EAC93;
    color: rgba(0,0,0,0.8)
}

#fld_bx_srch button {
    padding: 2px 4px;
    // background-color: transparent;
    // border: none;
    // color: rgba(0,0,0,0.2);
    // font-size: 22px;
    // line-height: 24px;
    // -webkit-font-smoothing: subpixel-antialiased;
    // -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    // transition: border .25s linear, color .25s linear, background-color .25s linear;
}
#fld_bx_srch button:hover { color: #387B77 }


#fld_bx_srch > input[disabled] { color: rgba(0,0,0,0.1) !important }
#fld_bx_srch button[disabled]  { color: rgba(0,0,0,0.1) !important; border-color: rgba(0,0,0,0.1) !important }


/*

    Caja centradora

*/

.box_center            { position: absolute; left: 0; width: 100%; top: 50%; margin-top: -100px; display: block; text-align: center }
.box_center.long,
.box_center.long_form  { top: 0; bottom: 0; right: 0; margin: 60px auto 70px; padding-top: 40px; padding-bottom: 40px; z-index: 10 }
.box_center.long       { overflow: auto; margin-bottom: 0 }

.box_center .box_input_medium { text-align: left }



/*

    Caixa opcions

*/

.box_opts {
    z-index: 1; position: relative; display: block; width: 100%; height: 40px; padding: 10px 20px 0; font-size: 12px; line-height: 12px
}

.box_opts .bx_left,  #hd_options .bx_left  {float: left; margin-right: 10px}
.box_opts .bx_right, #hd_options .bx_right {float: right; margin-left: 10px}
.box_opts .last,     #hd_options .last     {margin: 0}

.box_smsl {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 40px;
    border: 1px solid #D5DFE1;
    border-radius: 4px;
    background: white;
    color: #2E383A;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    display: flex;
    align-items: center;
}
.box_smsl .icon {top: 10px;padding-left: 12px; font-size: 20px;}
.box_smsl .title {padding: 0 12px 0 4px}




/*

    Contenidors especials

*/

.contnr_bx_lst { position: relative; display: block; width: 100% }





/*

    Caixes centrades form

*/

.box_form_add_db, .box_form_message { position: relative; overflow: hidden; width: 996px; margin: 40px auto 0 }
.box_form_add_db.no_sep             { margin-top: 0 }
.box_form_add_db .alert             { text-align: left; padding: 16px 30px 16px 15px; margin-bottom: 10px }
.box_form_add_db .alert .pull-right { margin: 0 }

.box_form_add_db .box_msg, .box_form_message .box_msg {
    position: relative; display: block; margin-bottom: 20px; color: #999; font-style: italic; text-align: left; font-size: 90%
}
.box_form_add_db .box_msg .btf_icon, .box_form_message .box_msg .btf_icon {padding-right: 5px}

.mod_popup .box_form_add_db,
.mod_popup .box_form_message {width: auto; margin-top: 0}

.box_form_add_db .tagsinput .not_valid {border-color: #aaa; margin-left: 0 !important}

.box_form_categories_reg{ margin-top: 350px; }


/* llistat simple */

.tp_activity .list_tasks td.tick {width: 120px; padding-left: 24px;}
.tp_activity .list_tasks td.tick span:hover {cursor: pointer}
.tp_activity .list_tasks td.tick .label {display: flex; justify-content: center;}

.tp_activity .list_tasks tbody tr td.inf {
    color: #4C6569;
    padding-left: 8px;
    gap: 8px;

    .inf_name {
        font-weight: 600;
    }
}

.tp_activity .list_tasks tbody tr td.inf .activity-btn-group {
    display: flex;
    align-items: center;
}

.tp_activity .list_tasks tbody tr td.inf .btn-sm {
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    padding: 8px 16px;
}


/*  Modificador generals  */

.box_form_add_db .form-group.all { width: 100% }

/*

    editor HTML

*/
.mce-floatpanel { z-index: 110000 !important; }

/*

    ## Edició pop idiomes

*/

#pop_box_tlang_org,
#pop_box_tlang_var  {position: relative; width: 100%; height: 50%; overflow: hidden; overflow-x: hidden; overflow-y: auto}

#pop_box_tlang_org {
    padding: 24px 20px 0;
    border-bottom: 1px solid #D5DFE1;
}
#pop_box_tlang_var  {
    padding: 24px 20px 0
}

#pop_box_tlang_org, #pop_box_tlang_var {
    label {
        color: #4C6569;
        strong {
            font-weight: 600;
            color: #2E383A;
        }
    }
}

#pop_box_tlang_var.file_tp {height: 100%; padding: 0 20px}

#form_tlang {position: absolute; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%}

#tlang_default_field {
    margin-bottom: 24px;
}

.auto_trn_fld{display:none;}


/*

    ## Caixa preview item pare en editor ##

*/

.sec-prd-frm_edt #box_form_content #bx_slct_prd_frmt .box_head_sec {
    position: absolute;
    top: 29px;
}

.sec-prd-frm_edt #box_form_content #bx_slct_prd_frmt .box_head_sec h1 {
    font-size: 24px;
    line-height: 28px;
    color: #2E383A;
    font-weight: 700;
    width: 60%;
}

#box_content.prv_prnt_vw_act .form form { width: 62% }
#box_content.prv_prnt_vw_cls .form form { width: auto; margin-right: 22px }

#bx_prev_inf_prnt {
    z-index: 901;
    position: absolute;
    right: 0;
    // top: 19px;
    width: 38%;
    height: 100%;
    min-height: 450px;
    padding: 0 0 71px 20px;
    background-color: #FFFFFF;
    border-left: 1px solid #D5DFE1;
    top: 0px;
    min-height: calc(100vh - 140px);
    height: 100%;
}

#bx_prev_inf_prnt iframe { position: relative; background: #fff; border: 0; width: 100%; height: 100% }

#btn_act_dis_prev {
    position: absolute;
    top: 28px;
    left: -12px;
    color: #2E383A;
    background-color: #fff;
    -webkit-transition: linear 0.15s;
    transition: linear 0.15s;
    border: 1px solid #D5DFE1;
    border-radius: 100%;
    padding: 3px;
    width: 24px;
    height: 24px;
}
#btn_act_dis_prev:before {
    font-family: "sally-icon";
    content: "\e91e";
    display: block;
    font-size: 16px;
}

#box_content.prv_prnt_vw_cls #bx_prev_inf_prnt        { width: 0 }

#box_content.prv_prnt_vw_cls #btn_act_dis_prev {
    right: 2px;
}

#box_content.prv_prnt_vw_cls #btn_act_dis_prev:before { content: "\e91d" }

/*

    Modificadors esencials

*/

.ehide, .focus_show {display: none}
.eshow              {display: block}
.hidden             {visibility: hidden}
.txhdn              {color:transparent}
.ihide              {display: none !important}

.not_selectable, .btn-box label .title {
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none;
    -ms-user-select: none; user-select: none; -o-user-select: none; pointer-events: none;
}

#linkUp {display: none}

#head, #box_menu {

    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none;
    -ms-user-select: none; user-select: none; -o-user-select: none;
}

/*

    Precàrregues imatges

*/

body:before{content: url(../../../img/sl_loading_bl.png); max-width: 100px; visibility: hidden; filter: alpha(opacity=0); position: absolute; z-index: 0; pointer-events: none}
body:after {content: url(../../../img/loader.gif);        max-width: 100px; visibility: hidden; filter: alpha(opacity=0); position: absolute; z-index: 0; pointer-events: none}
body:after {content: url(../../../img/switch/mask.png);   max-width: 100px; visibility: hidden; filter: alpha(opacity=0); position: absolute; z-index: 0; pointer-events: none}

/*

    Box errors conex

*/

#box_error {
    z-index: 100005; display: none; position: fixed; left: 50%; top: 20px; width: 400px; margin-left: -200px; _overflow: hidden;
    border-radius: 6px; background: #FFF1DB; 
}
#box_error h6 {float: left; margin: 0; width: 435px; font-size: 100%; line-height: 120%; color: #fff}
#box_error .close {margin-top: 5px}

#legal_box {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow-x: hidden; overflow-y: auto; background: #fff;

}
#legal_box, #legal_box table, #legal_box p { font-size: 11px; line-height: 18px; }
#legal_box h1, #legal_box h2 { font-size: 125%; }
#legal_box h3 { font-size: 115%; }
#legal_box h4 { font-size: 110%; }
#legal_box h5,
#legal_box h6,
#legal_box span.legacy-h7 { font-size: 105%; }

/*

    ## Quick Edit

*/

#box_content.bx_qck_edt .box_form { padding-bottom: 0 }

#families_selector       {}
#sections_container .nav {}
#sections_container .nav .makefield_ops { position: relative; float: right; top: auto; right: auto; }

#sections_container .bt_add_field {
    margin-bottom: 16px;
}

#qck_edt_ops {
    position: relative;
    display: block;
    padding-bottom:40px;
}

#qck_edt_ops label.pull-left { margin-right: 15px }


/*#fields_container .row           { margin-bottom: 16px }*/
#fields_container .box_search    { z-index: 0; padding: 0 0 30px 0 }

#fields_container label {
    margin: 8px 0 0 0;
}

.field_container                     { display: inline-table;  margin-bottom: 16px }
.field_container .field {
    border: 1px solid #EAEEF0;
    border-radius: 4px;
    background-color: #EAEEF0;
}

.field_container div.makefield_ops {
    display: inline-block;
    float: none;
    position: absolute;
    right: 10px;
    top: 6px;
}

.field_container.field_container-fld div.makefield_ops { right: 10px }

.field_container span.glyphicon {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 20px;
    color: #ABC0C4;
    cursor: grab;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}
.field_container .field:hover span.glyphicon-move,
.field_container span.glyphicon:hover         { color: rgba(0,0,0,0.5); -webkit-transition: color 0.2s;  transition: color 0.2s }
.field_container label                             { width: auto;  margin-right: 80px }

.field_container .elm_check .label         { border-radius: 5px; margin: 0; font-size: 10px; vertical-align: 0; color: #fff }
.field_container .elm_check .label-default { background: rgba(1,1,1,0.25) }
.field_container .elm_check .icon          { margin: 0 0 0 5px; font-size: 16px; line-height: 1px; color: rgba(1,1,1,0.25); vertical-align: -4px }

#qkd_move_shadow div,
#qkd_new_shadow  div {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #D5DFE1;
}
#qkd_new_shadow  div {
    background: #EDF8F3;
    border: none;
}

.field_container.actn_mov                 {
    position: fixed;
    z-index: 100000;
    padding-top: 0;
    cursor: pointer;
}
.field_container.line_mov:not(.actn_mov)  { transition: all 0.25s ease 0s; -webkit-transition: all 0.25s ease 0s }
.field_container.line_mov .field:hover    { border-color: #EAEEF0 }
.field_container.actn_mov .field          {
    // box-shadow: -10px 10px rgba(0,0,0,0.20);
    background: #EAEEF0;
    box-shadow: -5px 5px 1px rgba(126, 145, 154, 0.3);

}
/* drop dragged element ghost */
.field_container.in_change > div {
    background: #DCEFE9;
    border-color: #DCEFE9;
    transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s
}
.field_container.actn_mov .label,
.field_container.actn_mov .icon,
.field_container.actn_mov .dropdown       { display: none }

#fields_container .nav-pages.ms_over li a { background: rgba(255,240,0,0.3) }

#fields_container hr.form_med         { margin: 0 20px 20px }
#fields_container hr.form_med.and_ttl { margin-bottom: 0 }
#fields_container hr.form_max         { margin: 10px 20px 30px }
#fields_container hr.form_max.and_ttl { margin-bottom: 10px }
#fields_container .ttl_prg            { margin: 10px 0; font-size: 18px }

#fields_container .field {
    padding: 0 0 0 44px;
    height: 40px;
}

.field_container .field .makefield_ops .btn {
    background-color: transparent;

    &:active {
        border-color: transparent;
    }
}




.alert.alert-info,.alert.alert-warning,.alert.alert-error,.alert.alert-success {
    &> p {
        margin-left: 36px;
    }
}



.ui-datepicker {
    width: 392px;
    border: 7px solid #fff;
    box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    th {
        text-transform: uppercase;
        color: #4C6569;
        font-weight: 600;
    }
    td span, td a {
        color: #2E383A;
        font-weight: 600;
        font-size: 14px;
    }
	.ui-datepicker-header {
		background-color: #fff;
		color: #000000;
		cursor: default;
		margin: -7px -7px 2px;
		position: relative;
		border-radius: 6px 6px 0 0;
        padding: 12px 0;
	}
	.ui-datepicker-title {
		line-height: 26px;
	}
    .ui-datepicker-year {
		background-color: #fff;
		color: #000000;
        padding: 8px 0;
    }
    select.ui-datepicker-month, select.ui-datepicker-year {
        width: auto;
    }
    .ui-datepicker-month, .ui-datepicker-year {
        width: auto;
        font-weight: 700;
    }
    select.ui-datepicker-year {
        cursor: pointer;
    }
    .datepicker-title {
        font-weight: 700;
    }
    .ui-datepicker-prev:hover, .ui-datepicker-next:hover {
        background-color: #fff;
    }

    .ui-slider {
        height: 6px;
        margin-bottom: 10px !important;
    }
    .ui-slider-horizontal .ui-slider-handle {
        margin-left: -9px;
        top: -5px;
    }
    dt, dd {
        line-height: 3;
    }
    .ui-datepicker-buttonpane {
        margin: 24px 0 0;
    }
    .ui-datepicker-prev, .ui-datepicker-next {
        top: 12px;
        span {
            content: initial;
            &:before {
                font-family: "sally-icon" !important;
                font-style: normal;
                color: #4C6569;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-indent: initial;
                font-size: 24px;
            }
        }
    }
}


.ui-datepicker {
    .ui-icon-circle-triangle-e, .ui-icon-circle-triangle-w {
        border: none;
    }
}

.ui-datepicker .ui-datepicker-prev span:before {
    content: "\e91d";
    position: absolute;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -6px;
}


.ui-datepicker .ui-datepicker-next span:before {
    content: "\e91e";
    position: absolute;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -6px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 0;
    border-right: none;
    border-radius: 6px 0 0 0;
}

.ui-datepicker .ui-datepicker-next {
    right: 0;
    border-left: none;
    border-radius: 0 6px 0 0;
}


