
/*

    Sales Layer color palette

*/

// Green ------------------------------------------------------------------
$green-100: #EDF9F5;
$green-200: #DBF4EB;
$green-300: #A5E5CD;
$green-400: #70D5B0;
$green-500: #3BC693;
$green-600: #36B486;
$green-700: #31A381;
$green-800: #267F6D;
$green-900: #1B5A55;


// Charcoal ------------------------------------------------------------------
$charcoal-100: #AAB8C4;
$charcoal-200: #899BAD;
$charcoal-300: #677F96;
$charcoal-400: #46637F;
$charcoal-500: #405A74;
$charcoal-600: #3A5268;
$charcoal-700: #2D4051;
$charcoal-800: #202D3A;


// Silver ------------------------------------------------------------------
$silver-100: #F9FAFC;
$silver-200: #F4F6F9;
$silver-300: #EAEEF2;
$silver-400: #DFE5EC;
$silver-500: #D5DDE5;
$silver-600: #CBD4DE;
$silver-700: #C1CBD7;
$silver-800: #B6C3D1;


// Cyan ------------------------------------------------------------------
$cyan-100: #EFFCFC;
$cyan-200: #D1F7F5;
$cyan-300: #A3EFEC;
$cyan-400: #85E9E4;
$cyan-500: #58E2D8;
$cyan-600: #50CEC8;
$cyan-700: #49B9B6;
$cyan-800: #399098;
$cyan-900: #2A666F;

// Blue ------------------------------------------------------------------
$blue-100: #EEF7FF;
$blue-200: #CAE6FF;
$blue-300: #85C4FF;
$blue-400: #3378FF;
$blue-500: #193BFF;
$blue-600: #1736E8;
$blue-700: #1531D1;
$blue-800: #132BBA;
$blue-900: #0E218C;

// Red ------------------------------------------------------------------
$red-100: #FFEEED;
$red-200: #FFCCCB;
$red-300: #FF9A97;
$red-400: #FF6561;
$red-500: #FF4742;
$red-600: #E8413C;
$red-700: #D33B37;
$red-800: #A92D29;
$red-900: #791D1B;

// Yellow ------------------------------------------------------------------
$yellow-100: #FFFAF0;
$yellow-200: #FFF2D3;
$yellow-300: #FFE6A8;
$yellow-400: #FFDA7D;
$yellow-500: #FFD261;
$yellow-600: #F0B459;
$yellow-700: #C88240;
$yellow-800: #9B5831;
$yellow-900: #843F26;


// Orange ------------------------------------------------------------------
$orange-100: #FFF5EF;
$orange-200: #FFECDF;
$orange-300: #FFBF92;
$orange-400: #FFAD73;
$orange-500: #FF9B54;
$orange-600: #FA6F0C;
$orange-700: #CA590A;
$orange-800: #984408;
$orange-900: #633204;




// Color classes for text ------------------------------------------------------

.color-green-100 { color: $green-100; }
.color-green-200 { color: $green-200; }
.color-green-300 { color: $green-300; }
.color-green-400 { color: $green-400; }
.color-green-500 { color: $green-500; }
.color-green-600 { color: $green-600; }
.color-green-700 { color: $green-700; }
.color-green-800 { color: $green-800; }
.color-green-900 { color: $green-900; }

.color-charcoal-100 { color: $charcoal-100; }
.color-charcoal-200 { color: $charcoal-200; }
.color-charcoal-300 { color: $charcoal-300; }
.color-charcoal-400 { color: $charcoal-400; }
.color-charcoal-500 { color: $charcoal-500; }
.color-charcoal-600 { color: $charcoal-600; }
.color-charcoal-700 { color: $charcoal-700; }
.color-charcoal-800 { color: $charcoal-800; }

.color-silver-100 { color: $silver-100; }
.color-silver-200 { color: $silver-200; }
.color-silver-300 { color: $silver-300; }
.color-silver-400 { color: $silver-400; }
.color-silver-500 { color: $silver-500; }
.color-silver-600 { color: $silver-600; }
.color-silver-700 { color: $silver-700; }
.color-silver-800 { color: $silver-800; }

.color-cyan-100 { color: $cyan-100; }
.color-cyan-200 { color: $cyan-200; }
.color-cyan-300 { color: $cyan-300; }
.color-cyan-400 { color: $cyan-400; }
.color-cyan-500 { color: $cyan-500; }
.color-cyan-600 { color: $cyan-600; }
.color-cyan-700 { color: $cyan-700; }
.color-cyan-800 { color: $cyan-800; }
.color-cyan-900 { color: $cyan-900; }

.color-blue-100 { color: $blue-100; }
.color-blue-200 { color: $blue-200; }
.color-blue-300 { color: $blue-300; }
.color-blue-400 { color: $blue-400; }
.color-blue-500 { color: $blue-500; }
.color-blue-600 { color: $blue-600; }
.color-blue-700 { color: $blue-700; }
.color-blue-800 { color: $blue-800; }
.color-blue-900 { color: $blue-900; }

.color-red-100 { color: $red-100; }
.color-red-200 { color: $red-200; }
.color-red-300 { color: $red-300; }
.color-red-400 { color: $red-400; }
.color-red-500 { color: $red-500; }
.color-red-600 { color: $red-600; }
.color-red-700 { color: $red-700; }
.color-red-800 { color: $red-800; }
.color-red-900 { color: $red-900; }

.color-yellow-100 { color: $yellow-100; }
.color-yellow-200 { color: $yellow-200; }
.color-yellow-300 { color: $yellow-300; }
.color-yellow-400 { color: $yellow-400; }
.color-yellow-500 { color: $yellow-500; }
.color-yellow-600 { color: $yellow-600; }
.color-yellow-700 { color: $yellow-700; }
.color-yellow-800 { color: $yellow-800; }
.color-yellow-900 { color: $yellow-900; }

.color-orange-100 { color: $orange-100; }
.color-orange-200 { color: $orange-200; }
.color-orange-300 { color: $orange-300; }
.color-orange-400 { color: $orange-400; }
.color-orange-500 { color: $orange-500; }
.color-orange-600 { color: $orange-600; }
.color-orange-700 { color: $orange-700; }
.color-orange-800 { color: $orange-800; }
.color-orange-900 { color: $orange-900; }

.color-info-500 { color: #2977D1; }
