@use '../variables/globals' as *;
@use '../variables/colors' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/transitions' as *;
@use '../variables/borders' as *;
@use '../variables/actions' as *;
@use '../variables/icons' as *;
@use '../variables/content' as *;
@use '../mixins/typography' as *;
@use '../variables/typography' as *;
@use '../variables/semantic' as *;

.#{$namespace}card {
	padding: $spacing400;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: $borderRadius500;
	// width: 260px;
	// transition: width $transitionSpeed01, height $transitionSpeed01;
	&-top-left {
		position: absolute;
		top: 12px;
		left: 12px;
		z-index: 1;
	}
	&-top-right {
		position: absolute;
		left: auto;
		right: 12px;
		top: 12px;
		z-index: 1;
	}
	&.#{$namespace}card-fluid {
		width: 100%;
		overflow: hidden;
		.#{$namespace}card-content {
			width: 100%;
		}

	}
	&.#{$namespace}card-catalog {
		max-width: 350px;
		min-width: 188px;
		.#{$namespace}thumbnail {
			width: 100%;
			max-width: 100%;
			height: 100%;
		}
		&:hover {
			background-color: $background02;
			transition: all $transitionSpeed01;
			.#{$namespace}card-selector {
				display: flex;
			}
			.#{$namespace}card-subtitle {
				&.#{$namespace}card-subtitle-hide {
					display: none;
					height: 22px;
				}
			}
			.#{$namespace}card-details{
				display: flex;
			}
		}
	}
	.#{$namespace}card-content {
		display: flex;
		flex-direction: column;
		width: 228px;
		margin: auto;
	}
	.#{$namespace}card-error-icon {
		position: absolute;
		left: auto;
		right: 0;
		top: 33px;
		background: $error50;
		border-radius: 4px;
		width: 24px;
		height: 24px;
		color: $error600;
		text-align: center;
		line-height: 1.4;
		border: 1px solid $error50;
	}
	.#{$namespace}card-image {
		position: relative;
		display: flex;
		background-color: $background04;
		border-radius: $borderRadius500;
		align-items: center;
		justify-content: center;
		border: 1px solid $border04;
		overflow: hidden;
		a {
			width: 100%;
			height: 100%;
		}
	}
	.#{$namespace}thumbnail {
		border-radius: 0;
	}
	.#{$namespace}card-icon-bar {
		position: absolute;
		top: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		padding: 12px 12px;
	}
	.#{$namespace}card-info {
		padding: $spacing300 0 0 0;
		display: flex;
	}
	.#{$namespace}card-selector {
		display: none;
		height: 24px;
		.#{$namespace}checkbox-small {
			margin: 0px;
			.#{$namespace}checkbox-icon-check-small {
				top: 0px;
				left: 0px;
				margin: 0px;
			}
		}
	}
	.#{$namespace}card-details {
		display: none;
	}
	.#{$namespace}card-details {
		height: 22px;
	}
	
	.#{$namespace}card-subtitle {
		&.#{$namespace}card-subtitle-hide {
			height: 22px;
		}
	}
	&.sl-card-selected {
		> .#{$namespace}card-content > .#{$namespace}card-image {
			border: 1px solid $actionPrimaryHover;
		}
		.#{$namespace}card-selector {
			display: flex;
		}
	}
}

.#{$namespace}card-frame {
	background: white;
	border: 1px solid $border04;
	border-radius: $borderRadius700;
	padding: $spacing600;
	display: flex;
	flex-direction: column;
}

.#{$namespace}card-activity {
	justify-content: space-between;
	&-top {
		justify-content: flex-start;
	}
}

.#{$namespace}card-channel {
	max-width: 307px;
	min-height: 238px;
	justify-content: space-between;
	&-connect {
		width: 307px;
	}
	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $spacing300;
		&-left .#{$namespace}avatar {
			background: $background01;
			color: $iconLight;
		}
		&-right i {
			color: $iconSecondary;
		}
		&-logo {
			img {
				height: 32px;
				width: auto;
			}
		}
		&-notification {
			position: relative;
			&-badge {
				background-color: $error600;
				border-radius: 100%;
				height: 6px;
				width: 6px;
				position: absolute;
				top: 4px;
				right: 0px;
				border: 1px solid #fff;
			}
		}
	}
	&-title {
		color: $contentDefault;
		font-size: $fontSize400;
		line-height: $lineHeight300;
		font-weight: $fontBold;
		max-width: 100%;
    	word-break: break-word;
	}
	&-content {
		margin-bottom: $spacing350;
		&-subtitle {
			margin-bottom: $spacing100;
			color: $contentSecondary;
			@include generate-type("small");
		}
		&-centered {
			height: 100%;
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&-logo {
			max-height: 22px;
			width: auto;
		}
		&-item {
			display: flex;
			align-items: center;
			margin-bottom: $spacing100;
			@include generate-type("small");
			color: $contentSecondary;
			&.#{$namespace}title {
				@include generate-type("large");
				color: $contentDefault;
				margin-bottom: $spacing200;
				font-weight: $fontBold;
			}
			&.#{$namespace}success,
			.sl-content-success i {
				color: $info500;
			}
			i {
				vertical-align: middle;
				font-size: $fontSize400;
			}
		}
		&-item.no-flex {
			display: block;
    		align-items: none;
		}
		i {
			/*color: $linkSecondaryDefault; <-- quitado porque no deja marcar el icono en rojo de alerta cuando es necesario en Control de Precios, por ejemplo */
		}
		.#{$namespace}success,
		.sl-content-success,
		.sl-content-success i {
			color: $info500;
		}
	}
	&-footer {
		margin-top: auto;
		display: flex;
		align-items: center;
	}
	&.not-active .#{$namespace}avatar {
		width: 40px;
		height: 40px;
	}
	&.not-active .#{$namespace}card-channel-content {
		min-height: 96px;
	}
	&.not-active .#{$namespace}card-channel-content-item.#{$namespace}title,
	&.blocked .#{$namespace}card-channel-content-item.#{$namespace}title,
	&.disabled .#{$namespace}card-channel-content-item.#{$namespace}title {
		margin-bottom: $spacing100;
	}
	&.blocked,
	&.disabled {
		padding: $spacing800 $spacing600 $spacing600 $spacing600;
		align-items: center;
	}
	&.blocked .#{$namespace}card-channel-content,
	&.disabled .#{$namespace}card-channel-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&.blocked .#{$namespace}card-channel-content i,
	&.disabled .#{$namespace}card-channel-content i {
		font-size: 60px;
		color: $iconSecondary;
		margin-bottom: $spacing100;
	}
	&:hover {
		border: 1px solid $actionPrimaryHover;
	}
}
