@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/borders' as *;
@use '../variables/transitions' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;
@use '../variables/semantic' as *;

.#{$namespace}textarea {
	&-wrapper {
		display: flex;
		flex-direction: column;
	}
	&-label {
		display: flex;
		flex-direction: column;
		min-width: 292px;
		color: $contentSecondary;
		margin-bottom: $spacing200;
	}
	&-input {
		border-radius: $borderRadius300;
		color: $contentDefault;
		min-width: 292px;
		min-height: 136px;
		padding: $spacing300 $spacing400;
		border: 1px solid $border04;
		transition: all $transitionSpeed01 ease;
		font-family: Open Sans;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: left;
		&:focus {
			border: 1px solid $border01;
			transition: all $transitionSpeed01 ease;
			outline: none;
		}
		&::placeholder {
			color: $contentPlaceholder;
			font-family: Open Sans;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0px;
			text-align: left;
			
		}
		&.#{$namespace}has-error {
			border: 1px solid $error01;
		}
		&.#{$namespace}is-valid {
			border: 1px solid $success01;
		}
	}
	&-valid {
		color: $success01;
	}
	&-error {
		color: $error01;
	}
	&-help {
		color: $contentSecondary;
	}
}
