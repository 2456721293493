$white: #ffffff;
$black: #000000;

$primary900: #205A5A;
$primary800: #387B77;
$primary700: #3D9487;
$primary600: #3EAC93;
$primary500: #3BC693;
$primary400: #61DAA5;
$primary300: #76EAAA;
$primary200: #A3F5BE;
$primary100: #CBFBD6;
$primary50: #ECFEEE;

$secondary900: #5A4B35;
$secondary700: #5A4B35;
$secondary500: #E2D8B1;
$secondary400: #EEE5C0;
$secondary350: #F7EABB;
$secondary300: #FBF2D0;
$secondary100: #FCF8ED;

$neutral900: #2E383A;
$neutral800: #4C6569;
$neutral700: #638688;
$neutral600: #7B9BA1;
$neutral500: #93AFB4;
$neutral400: #ABC0C4;
$neutral300: #CFDADD;
$neutral250: #D5DFE1;
$neutral200: #E3EAED;
$neutral100: #EAEEF0;
$neutral50: #F8F8F8;

$error900: #55201E;
$error800: #8C2824;
$error700: #B22D28;
$error600: #D2322C;
$error500: #ED352E;
$error200: #E89E9B;
$error100: #F7BCBA;
$error75: #FAD7D6;
$error50: #FDEDED;

$success900: #05570A;
$success800: #06890E;
$success700: #0BA815;
$success600: #18C925;
$success500: #37D046;
$success200: #9BE8A3;
$success100: #BFF5C5;
$success75: #D7F9DB;
$success50: #E6FAE8;

$warning900: #653600;
$warning800: #814600;
$warning700: #B96400;
$warning600: #D57300;
$warning500: #FFAA46;
$warning200: #F7C983;
$warning100: #FFD9A0;
$warning50: #FFF1DB;

$info900: #1C303B;
$info800: #0A496A;
$info700: #005583;
$info600: #0067AA;
$info500: #2977D1;
$info100: #BAEBF5;
$info74: #D7F3F9;
$info50: #E9F9FB;

$lavander500: #EBB6FD;
$lavander400: #EFC5FD;
$lavander300: #F3D3FE;
$lavander200: #F7E2FE;
$lavander100: #FBF0FF;

$tangerine500: #FF9365;
$tangerine400: #FFA984;
$tangerine300: #FFBEA3;
$tangerine200: #FFD4C1;
$tangerine100: #FFE9E0;

$skyblue500: #9DB2FF;
$skyblue400: #849FFF;
$skyblue300: #B5C5FF;
$skyblue200: #CED9FF;
$skyblue100: #E6ECFF;

$sunyellow500: #FFD15D;
$sunyellow400: #FFDA7D;
$sunyellow300: #FFE39E;
$sunyellow200: #FFEDBE;
$sunyellow100: #FFF6DF;