.box_list.box_list_global_quality {
  padding: 44px 20px 0px 44px;

  .box_dashb {
    padding: 24px 32px 32px 32px;
    max-height: unset;
    min-height: unset;
    height: 231px;

    h3 {
      padding: 0;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
    }

    .box_info {
      h6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2E383A;

        a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        small {
          i {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #2E383A;
          }
        }
      }

      .progress {
        margin: 4px 0 32px 0;
      }

      h4.msg {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2E383A;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        text-transform: capitalize;

        i {
          font-size: 20px;
          line-height: 20px;
          margin-right: 8px;
        }
      }

      .sub_bx_group {
        display: flex;
        justify-content: space-between;

        .sub_bx {
          strong {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .sub_bx.st_V,
        .sub_bx.st_I,
        .sub_bx.st_D {
          color: #2E383A;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .box_dashb.box_dashb_activity {
    height: 373px;

    h3 {
      margin-bottom: 22px;
      align-items: center;

      small {
        color: #2E383A;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .box_info.chart {
      padding: 0;

      .chart_sklt.left {
        margin: 0 32px 0 0;
        width: unset;

        span.legacy-h7 {
          text-transform: uppercase;
          color: #2E383A;
          font-weight: 400;
        }

        #chart_usr_act {
          border: none;
          margin: 22px 0 0 0;
          display: flex;
          justify-content: center;
          width: unset;
          height: unset;

          .chartjs-render-monitor {
            width: 159px !important;
            height: 159px !important;
            margin: 0;
          }
        }
      }

      .chart_sklt.all_right {

        span.legacy-h7 {
          text-transform: uppercase;
          color: #2E383A;
          font-weight: 400;
        }

        #chart_usr_iter {
          margin-top: 20px;
          height: 235px;
        }
      }
    }
  }

  .sklt.mxm {
    padding: 0 24px 24px 0;
  }
}

.qlty_evolution_chart {
  height: auto!important;
}

.box_head_sec h1.sl-h5-bold {
  color: #2E383A;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.box_head_sec.box_head_sec_qrt {
  margin-bottom: 40px;
}

.box_prgph.box_prgph_summary {
  .box_head_sec {
    display: flex;
    align-items: center;
    padding: 0px 44px 0px 44px;

    h2 {
      font-size: 18px;
      margin-right: 18px;
    }

    h3 {
      display: flex;
      align-items: center;

      .progress {
        width: 235px;
        margin-bottom: 0;
        background-color: #EAEEF0;
        margin: 0 18px 0 0;
// CALIDAD DE TABLAS
        .progress-bar-warning {
          background-color: #F28790;
        }

        .progress-bar-danger {
          background-color: #FFD9A0;
        }

        .progress-bar-info {
          background-color: #3BC693;
        }

        .progress-bar-success {
          background-color: #A9DAD1;
        }
      }

      .progress_percentage {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: baseline;
          color: #2E383A;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          margin-right: 16px;

          strong {
            display: flex;
            align-items: baseline;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .box_list {
    padding: 24px 24px 44px 44px;

    .box_dashb {
      padding: 24px 32px 32px 32px;
      max-height: unset;
      min-height: unset;
      height: 231px;

      h3 {
        padding: 0;
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
      }

      .box_info {
        h6 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #2E383A;

          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          small {
            i {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: #2E383A;
            }
          }
        }

        .progress {
          margin: 4px 0 32px 0;
        }

        h4.msg {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2E383A;
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          text-transform: capitalize;

          i {
            font-size: 20px;
            line-height: 20px;
            margin-right: 8px;
          }
        }

        .sub_bx_group {
          display: flex;
          justify-content: space-between;

          .sub_bx {
            strong {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .sub_bx.st_V,
          .sub_bx.st_I,
          .sub_bx.st_D {
            color: #2E383A;

            .url_ldg {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .box_chart_dfrrd {
    width: 100%;
    display: flex;
    padding: 0 24px 0 0;

    .box_dashb {
      border: 1px solid #D5DFE1;
      height: 400px;

      h3 {
        margin-bottom: 0;
      }

      .box_info.chart {
        padding: 0;

        .chart_sklt {
          height: 311px;
          margin-top: 15px;

          .chart_box {
            height: 311px;
          }
        }
      }
    }
    .sklt.mxm {
      padding: 0 0 24px 0;
    }
  }
}

.box_dashb_activity {

  .chart_sklt {
    width: auto;
  }
}

.box_prgph.box_prgph_qr_channels,
.box_prgph.box_prgph_qr_tables {

  .box_head_subsec {
    display: flex;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #2E383A;
      margin-right: 18px;
    }

    h4 {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #2E383A;

      .progress {
        width: 235px;
        margin: 0 18px 0 0;
        background-color: #EAEEF0;

        .progress-bar-warning {
          background-color: #F28790;
        }

        .progress-bar-danger {
          background-color: #FFD9A0;
        }

        .progress-bar-info {
          background-color: #3BC693;
        }

        .progress-bar-success {
          background-color: #A9DAD1;
        }
      }

      .progress_percentage {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: baseline;
          color: #2E383A;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          margin-right: 16px;

          strong {
            display: flex;
            align-items: baseline;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .box_list {
    padding: 24px 44px 44px 44px;
  }

  table {
    .tb_prcnt {
      display: flex;
      align-items: center;

      .progress {
        width: 64px;
        margin: 0 8px 0 0;
// TABS TABLAS Y CANALES - CALIDAD
        .progress-bar-warning {
          background-color: #FFD9A0;
        }

        .progress-bar-danger {
          background-color: #F28790;
        }

        .progress-bar-info {
          background-color: #3BC693;
        }

        .progress-bar-success {
          background-color: #9BE8A3;
        }
      }

      em {
        color: #46637F;
      }
    }

    .tb_field {
      font-weight: 600;
    }

    .td_user_plus {
      font-weight: 600;
      margin-left: 4px;
    }

    .bt_fld_delete {
      margin: 0px 82%;

      i {
        font-size: 16px;
        line-height: 16px;
        color: #CFDADD;
      }
    }
  }
}

.box_prgph_qr_tables {
  .paragrh {
    padding-left: 44px;
  }
}
