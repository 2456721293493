@use '../variables/globals' as *;
@use '../variables/zindex' as *;

$zindex-map: (
	"100": $zindex100,
	"200": $zindex200,
	"300": $zindex300,
	"400": $zindex400,
	"500": $zindex500,
	"600": $zindex600,
	"700": $zindex700,
	"800": $zindex800,
	"900": $zindex900
);

@each $key, $val in ($zindex-map) {
	.#{$namespace}zindex-#{$key} {
		z-index: $val !important;
	}
}