@use '../../variables/globals' as *;
@use '../../variables/backgrounds' as *;
@use '../../variables/colors' as *;
@use '../../variables/semantic' as *;
@use '../../variables/border-radius' as *;
@use '../../variables/spacing' as *;
@use '../../variables/content' as *;
@use '../../variables/icons' as *;
@use '../../variables/typography' as *;
@use '../../variables/zindex' as *;

@font-face {
    font-family: 'SFMono';
    src: url('legacy/fonts/SFMonoLight.woff') format('woff');
}

html {
    overflow-y: auto;
}

/* previous chrome */
html::-webkit-scrollbar {
  width: 8px;
}
html::-webkit-scrollbar-track {
  background: #f0f0f0;
}
html::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}
html::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

html, body { background: #ecf0f1 }
body       { overflow: hidden !important } /* overflow: auto !important */

.nm-ie8-bug { position: absolute; top: 0; left: 0; font-size: 1px; line-height: 1px; height: 1px }

a, a:link, input:focus, select:focus, option:focus, textarea:focus, .btn:focus, .btn.active:focus { text-decoration: none; outline: 0 !important }
::-moz-focus-inner { text-decoration: none; outline: 0 !important; border: 0 !important }
select { -webkit-appearance: none; -moz-appearance: none; appearance: none; outline: 0 !important; text-shadow: 0 0 0 transparent !important; }
select:not(:focus) { transition: none; -moz-transition: none; -webkit-transition: none }
option { -webkit-appearance:none; -moz-appearance:none; appearance:none; }

.btn {
    text-overflow: ellipsis;
    overflow: hidden;
    &.not-click {
        cursor: default;
    }
}

h1,h2,h3,h4,h5,h6, span.legacy-h7 { display: block }

span.legacy-h7 { font-size: 16px }

hr          { border-color: #D5DFE1; }
hr.min      { margin: 5px 0 }
hr.form_hid { margin: 0; border-color: rgba(0,0,0,0) }
hr.form_min { margin: 0 0 10px }
hr.form_med { margin: 20px 0 } hr.form_med.and_ttl { margin-bottom: 0 }
hr.form_max { margin: 40px 0 } hr.form_max.and_ttl { margin-bottom: 20px }

em.prsbc {
    font-size: 16px;
    line-height: 16px;
    padding-right: 10px;
}
i.tab    { position: relative; overflow: hidden; display: inline-block; min-width: 15px; }

.subx { font-weight: normal; font-size: 70% }

.icon.dml         { font-size: 18px; line-height: 22px }
.icon.ini_med     { vertical-align: middle; font-size: 115%; margin-right: 5px }
.icon.med         { vertical-align: middle }
.icon.ini.mrg_lft,
.icon.mrg_lft     { margin-right: 0; margin-left: 10px }
.icon.ini.no_mrg  { margin: 0 }
.icon.no_pdg,
.btn .icon.no_pdg { padding: 0 !important }
.icon.thin        { color: #60758a }
.icon.good        { color: #36B486 }
.icon.wrng        { color: #f1c40f }
.icon.alrt        { color: #e74c3c }
.icon.low         { color: rgba(0,0,0,0.3) }
.icon.clr         { color: rgba(0,0,0,0.1) }
.icon.mrg_rgt     { margin-right: 5px }
.help-block .icon { vertical-align: middle; padding-right: 5px }
.image_icon       { position: relative; overflow: hidden; float: left; margin: 0 20px 20px 0 }
.txt_icon         { display: inline-block; padding-left: 10px }
.txt_icon strong  { font-weight: 600 }
.to_ico           { margin-left: 10px; font-style: normal }

table th .glyphicons::before { vertical-align: -2px }

.disabled, .data-info  { display: none }
.input-group .disabled { display: inline; background-color: #f4f6f6; border-color: #d5dbdb; }
.text-low              { color: rgba(0,0,0,0.3) }
.text-med              { color: rgba(0,0,0,0.5) }
.text-ornt-rgt         { direction: rtl !important }

.form-group.focus  .input-group-btn .btn.disabled,
.input-group.focus .input-group-btn .btn.disabled { color: #bdc3c7; background-color: #f4f6f6; border-color: #d5dbdb; }

.alert-danger .icon.ini,
.alert-error .icon.ini,
.alert .icon.ini.alrt,
.icon.alrt {
    color: #55201E
}
.icon.low       {
    color: #bdc3c7
}

.alert {
    .icon.ini.sl-icon-info {
        color: #005583
    }
    .icon.ini.sl-icon-warning {
        color: #653600
    }
    .icon.ini.sl-icon-error {
        color: #55201E
    }
    .icon.ini.sl-icon-success {
        color: #05570A
    }
}

.bx_alrt_ttl         {
    display: inline-block;
    width: 100%;
 }

 .alert {
    h6 {
    &.bx_alrt_ttl {
        margin-bottom: 0;
    }
}

 }

.bx_alrt_ttl .icon   { display: block; float: left; margin-right: 15px }

.bx_alrt_ttl .tx_ttl {
    display: block;
    float: left;
    width: 90%;
}

.label-half    { font-size: 50% }
.label-default {
    background: #FBF2D0;
    color: #5A4B35;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 2px 12px;
}
.label-neutral {
    background-color: #D5DFE1;
    color: #2E383A;
}
#box_menu .label-default { background-color: #ebedef; color: #7b8996 }

.btn        .icon.left      { padding: 0 0 0 5px }
.btn        .icon.right     { padding: 0 5px 0 0 }
.btn        .icon.alone     { padding: 0 }
.btn.btn-xs.txt             { padding: 2px 8px; vertical-align: middle; margin: 0 5px }

.btn.auto_trn_fld .icon  { padding: 0; font-size: 130%; line-height: 100% }
.btn.btn-slc             { height: 36px; padding: 6px 15px }

.form-group .btn-group select { display: inline; max-width: 250px; border-top-right-radius: 0; border-bottom-right-radius: 0;}

.btn .label { border-radius: 50%; margin-left: 5px }

.dropdown-toggle .filter-option.title.med { overflow:hidden; white-space: nowrap; text-overflow: ellipsis;max-width: 250px}

.icon.equ, .btn .icon.equ { font-size: 100%; line-height: 100% }
.btn .icon.equ {
    margin-right: 8px;
}
.icon.med, .btn .icon.med { font-size: 80%; vertical-align: middle }
.icon.lft                 { font-size: 100%; line-height: 100%; vertical-align: middle; padding-right: 5px }
.icon.deco                { color: rgba(0,0,0,0.3) }
.icon.bullet              { background: #999; color: #fff; padding: 10px 10px 6px; border-radius: 50%; font-size: 115%; line-height: 115% }
.icon.bullet.low          { background: #bbb; font-size: 85%; line-height: 85% }
.icon.bullet.low.alrt     { background: #f1c40f }
.icon.invert {
	overflow: hidden; padding: 3px; position: relative; border-radius: 5px; float: left; width: 25px; height: 23px; background: #999;
	color: #fff; font-size: 15px; line-height: 17px; text-align: center; font-style: normal; font-weight: normal
}

.link_sim:hover { cursor: pointer; color: #387B77 }

.glyphicons.rotate90::before { transform: rotate(90deg) }

.pagination              { height: 42px }
.pagination ul           { overflow: hidden }
.pagination ul li > a,
.pagination ul li > span { height: 40px }
.pagination.no_mrgn      { margin: 0 0 0 8px }

.untouch {
	-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none;
	-ms-user-select: none; user-select: none; -o-user-select: none; pointer-events: none;
}

.sep_top,
.alert.sep_top { margin-top: 20px }
.sep_bottom    { margin-bottom: 24px }
.pad_bottom    { padding-bottom: 20px }
.no_pdg {padding: 0 !important}
.bg-white { background-color: white}

@media (max-width: 767px) { .pagination ul { padding: 0 } }

.no_mrtp { margin-top:    0 !important }
.no_mrbt { margin-bottom: 0 !important }
.form     { position: relative; display: block }
.form.clr { overflow: hidden }

.sep_btm,
.form.sep_btm {
    margin-bottom: 10px
}

.form-group.sep_btm     {
    margin-bottom: 12px
}

.sep_btm.dbl,
.form.sep_btm.dbl,
.form-group.sep_btm.dbl { padding-bottom: 20px; margin-bottom: 20px }
.form-group.sep_top     { border-top: 1px solid rgba(0,0,0,0.05); padding-top: 20px }
.sep_ln_top,
.form-group.sep_ln_top  { border-top: 1px solid rgba(0,0,0,0.05); margin-top: 10px }
.form-group.sep_cl_top  { margin-top: 20px }
/* Quitar el overflow: hidden genera problemas. Si es preciso reponerlo habría que ver de aplicarlo en el sitio exacto donde se requiere la modicación */
.form-group.overflow    { overflow: hidden }
.form-group.block .row  { padding-bottom: 10px }
.form-group.no_sep      { margin-bottom: 0 }

.sep_ul_btm > ul { border-bottom: 1px solid rgba(0,0,0,0.05); padding-bottom: 20px; margin-bottom: 10px }

.btn-middle         { vertical-align: middle }
.btn-clean          { display: inline; margin-right: 10px; padding: 0; border: 0; background: transparent; color: rgba(0,0,0,0.8); }
.btn-clean.check    { color: rgba(0,0,0,0.3); line-height: 1; font-size: 17px; margin-right: 8px;/*vertical-align:-9px;*/

    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
}
.btn-clean.check:hover { color: rgba(0,0,0,0.6); }
.btn-clean.check  i    { float: left }
.btn-clean[disabled]   { background: transparent; opacity: 1 }
label .btn-clean.check   { margin: 0; min-height: 32px }
label .btn-clean.check i { padding: 0; line-height: 60% }

.btn-group.bx_left ul  { right: auto; left: 0; }

.btn-group .btn_rgt             { float: right }
.form .btn-group .dropdown-menu { min-width: 100%; overflow: hidden; z-index: 1 }

.input-group-btn .btn {
    border: 1px solid #D5DFE1;
    border-right: none;
    color: #2E383A;
    height: 40px;
    overflow: hidden;
}

.input-group-btn .btn .icon     { padding: 0; font-size: 14px; line-height: 14px }
form[name="product_formats"] {
    .input-group-btn {
        .btn .icon {
            font-size: 20px;
        }
        .btn-default {
            padding: 8px 9px;
        }
    }
}


.input-group-btn .btn.btn-icon  {
    padding: 10px;
    .icon {
        font-size: 20px;
    }
}

.form .msg_void {
    color: #7B9BA1;
    display: block;
    font-size: 14px;
    margin-bottom: 5px
}

.form .box_desc { position: relative;
    // overflow: hidden;
    // width: 100%;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #4C6569;
    margin-top: 8px;
    margin-bottom: 16px;
}

.form .button_rfrsh_conn + .box_desc {
    position: absolute;
    top: -36px;
    right: 0;
}

label.hdr { display: block }

input, textarea, select, option { appearance: none; -moz-appearance: none; -webkit-appearance: none }

select, select.form-control {
    -ms-overflow-style: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /*min-width: fit-content;*/
    width: 100%;
    padding: 8px 26px 8px 12px;
}

select.form-control[disabled] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ABC0C4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

}

select::-ms-expand          { display: none }

button:focus, :focus { outline: 0 !important }
option               { padding: 5px 10px }

select.input-sm      { line-height: 100%; min-height: 40px }

ul.check_list                       { 
    position: relative; 
    padding-left: 0; 
    list-style: none; 
    display: block; 
    overflow: hidden; 
    margin-bottom: 0 
}

.check_list_vertical ul.check_list { 
    li {
        width: 100%;
    } 
}

ul.check_list li                    { display: inline-block; vertical-align: top; padding-right: 30px; min-height: 32px }
ul.check_list li label              { margin: 8px 0 }
.col_list ul.check_list li          { width: 31% }

.form ul.check_list {
    margin-bottom: 0;
    width: 100%;
}

.form .page_fields ul.check_list    { margin-bottom: 15px }
.page_fields ul.check_list li {
    width: 25%;
    max-width: 272px;
}

.checkbox .icons .first-icon,
.radio    .icons .first-icon,
.checkbox .icons .second-icon,
.radio    .icons .second-icon { padding-left: 1px }

ul.check_list li.checkbox_head {

    display: inline-block; overflow: hidden; float: none; width: 100%;
    font-style: italic; color: rgba(0,0,0,0.5); font-size: 90%; line-height: 90%; padding: 15px 0 0 0; margin-bottom: 5px; border-bottom: 1px solid rgba(0,0,0,0.05)
}
ul.check_list li.checkbox_head label.checkbox { display: inline-block; margin-top: 0 }

ul.check_list label.list_not_translated { font-style: italic; color: #AAA; }

.nav-tabs > li > a               { color: #387B77; -webkit-font-smoothing: subpixel-antialiased; -webkit-transition: .10s linear; transition: color .10s linear, background .10s linear }
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus         { background: #387B77; color: #fff }
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus  { border-color: #d5d9dc #E1E4E7 #ecf0f1; border-bottom-width: 3px; border-top: 3px solid #d5d9dc; background: #ecf0f1 }
.nav-tabs > li.disabled > a      { color: #c0c5ca }
.nav-tabs > li em                { font-weight: normal }
.nav-tabs label                  { margin: 0 }

.box_msg_action .alert:last-child   { margin-bottom: 0 }
.modal-body .paragrh.box_msg_action { padding: 0 }

.modal_prld                { position: relative; overflow: hidden; width: 100%; height: 35px; background: url(../../../img/prld_mina.gif) center center no-repeat; }

.modal                     { overflow-x: hidden; overflow-y: auto; }
.modal-content             { background-color: #fff; border: 0 }

.modal-footer button,
.modal-footer a {
    height: 40px;
}

.modal-title               { white-space: nowrap; text-overflow:ellipsis; overflow: hidden; }
.modal-title .icon         { display: none; //hides icon on modal title for fast integration
}
.modal-title i             { font-weight: 400 }
.modal-footer .logo        { position: relative; float: left; overflow: hidden; height: 80%; margin-right: 10px }
.dropdown-menu             { overflow-y: auto; overflow-x: hidden }
.dropdown-backdrop         { display: none }

.dropdown-header {
    font-size: 14px;
    line-height: 24px;
    padding: 10px 24px;
    margin: 0 12px;
    color: #2E383A;
    font-weight: 600;
}

.form .btn-group .dropdown-large, .dropdown-large { max-height: 350px; overflow-y: auto }
.form .btn-group .dropdown-short, .dropdown-short { max-height: 150px; overflow-y: auto }

.alertLag,
img.prld   { display: none }
img.xld    { visibility: hidden }

.nav-tabs .icon     { font-size: 115%; vertical-align: middle }
.nav-tabs .icon.med { font-size: 100%; line-height: 100% }

.nav-tabs a > button.btn-clean { vertical-align: bottom }

.tooltip, .sl-tooltip-content          { z-index: 1000000 }

.tooltip.image,
.tooltip.image .tooltip-inner { width: auto; max-width: none}

.msg_tooltip { cursor: pointer }

ul.tooltip-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0px;
}

.tooltip-list-item {
    margin-top: 8px;
    text-align: left;
}

.tooltip-list-item:nth-child(1) {
    margin-top: 0px;
}


.tooltip-list-icon{ margin-right: 10px; color: #A7AEB2; }

.tooltip strong {
    font-weight: 600;
    white-space: nowrap;
  }

.ui-slider         { background-color: rgba(0,0,0,0.1) }
.ui-slider-segment { background-color: rgba(0,0,0,0.3) }

/* select accions */

.btn-group.select { margin-bottom: 0 }

.btn-group.select button.dropdown-toggle, .btn-box {
	position: relative; overflow: hidden; width: fit-content; border-radius: 4px; color: #2E383A;
}

.btn-box                                             { padding: 4px 10px 0 5px; height: 30px }
.btn-box label                                       { padding-left: 27px; cursor: pointer }
.btn-box label .icons .fui-checkbox-unchecked:before { content: "\E034"; color: rgba(0, 0, 0, 0.10) }
.btn-box label .title           { color: #2E383A }
.btn-box label .title em,
#head .op_btn         em        { font-style: normal }

.btn-group select.lft            { float: left }
.btn-group.select button span    { margin: 0 }

.btn-group.select .filter-option { padding: 0 8px 0 0 }
.btn-group.select .caret         { margin-top: 3px; right: 10px; border-bottom-color: #ccc; border-top-color: #ccc }

.btn-group.select button .caret       { margin-top: -3px }
.btn-group.select button:hover .caret { margin-top: -1px; border-bottom-color: #555; border-top-color: #555 }

.btn-group.select        .dropdown-arrow { z-index: 6; margin-top: 0 }
.btn-group.select.open > .dropdown-arrow { margin-top: 4px }

.dropdown-menu li                         { position: relative; display: block }
.dropdown-menu li.ehide                   { display: none }
.dropdown-menu li > a                     { white-space: nowrap }

.dropdown-menu li > a.right button {
    position: absolute;
    right: 16px;
}

.dropdown-menu li > a.right button i.icon {
    color: #4C6569;
}

.dropdown-menu .box_ul_sub         { position: relative; overflow: hidden; width: 100%; font-weight: 400; line-height: 1.42857 }
.dropdown-menu .box_ul_sub li      { display: block; }
.dropdown-menu .box_ul_sub li a    { display: block; border-radius: 0; padding-right: 30px!important }
.dropdown-menu .box_ul_sub li.prld,
.dropdown-menu li.prld,
#box_hist_vw .prld                 { position: relative; width: 100%; height: 22px; background: url(../../../img/prld_mina.gif) center center no-repeat; }

.dropdown > .btn-xs { text-overflow: clip }

.btn-group      { z-index: 1 }
.btn-group.open { z-index: 3 }

.modal.fade.in {
  background-color: rgba(46, 56, 58, 0.8);
}

.close {
  float: unset;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  color: unset;
  text-shadow: unset;
  filter: unset;
  opacity: unset;
}

.modal {
  &-small {
		max-width: 500px;
	}
	&-medium {
		max-width: 680px;
	}
	&-large {
		max-width: 832px;
	}
	&-xlarge {
		max-width: 1024px;
	}
}

.modal-dialog {
	position: relative;
	z-index: $zindex700;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $white;
	border-radius: $borderRadius700;
    margin: 40px auto 30px auto;
}

.modal-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: $spacing600 $spacing700 4px;
    color: $contentDefault;
    background-color: $white;
    border-bottom: unset;
    border-radius: $borderRadius700 $borderRadius700 0 0;
    .close {
        margin: unset;
        color: #2E383A;
        font-size: 20px;
        line-height: 20px;
        &:disabled {
            cursor: default;
            &:hover {
                color: $neutral400 !important;
                opacity: 1;
            }
        }
    }
}

.modal-header-mini {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    .close {
        margin: auto;
        color: #2E383A;
        font-size: 20px;
        line-height: 20px;
    }
}

.modal-title {
    font-size: $fontSize500;
    line-height: $lineHeight500;
    font-weight: 600;
}

.title_text {
    font-size: 14px;
    line-height: 24px;
    color: #2E383A;
    margin-bottom: 8px;
}

.avis {
    font-size: 14px;
    line-height: 24px;
    color: #2E383A;

    strong {
        font-weight: 700;
        text-transform: capitalize;
    }
}

.modal-content {
    border: unset;
    min-height: 50px;

    .modal-body {
        padding: $spacing650 $spacing700;
        flex: 2;
        background-color: $white;
        color: #2E383A;
        p {
            font-size: 14px;
            list-style: 24px;
        }

        .row-img-file-trans {
            .input-group-btn {
                .btn.btn-icon {
                    padding: 10px;
                    i {
                        margin-right: 0px;
                    }
                }
            }
        }

        button:not(.sl-icon-close):not(.btn-xs):not(.close) {
            height: 40px;
            font-weight: 600;

            i:not(.sl-icon-search):not(.sl-icon-chevron-down)  {
                margin-right: 8px;
            }
            i:not(.sl-icon-chevron-down) {
                font-size: 20px;
            }
        }
        .btn:not(.sl-icon-close):not(.btn-xs) {
            height: 40px;
            font-weight: 600;

            i:not(.sl-icon-search):not(.sl-icon-chevron-down) {
                margin-right: 8px;
            }
            i {
                font-size: 20px;
            }
        }

        .avis .form-group {
            margin-bottom: 0;
        }

        .nav-tabs {
            border-bottom: 1px solid #D5DFE1;
            margin-bottom: 24px;
        }
    }
}

.modal-footer {
    display: flex !important;
    background-color: $background01;
    justify-content: flex-end;
    border-radius: 0 0 $borderRadius700 $borderRadius700;
    padding: $spacing500 $spacing700;
    button.btn-primary {
        order: 6;
        margin-left: 12px;
    }
}

.modal-dialog .modal-content iframe {
    height: 70vh !important;
}

.msng_refs_btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 12px;
    a {
        background-color: #FBF2D0!important;
        color: #5A4B35!important;
    }
}

.modal-body {
    h6, span.legacy-h7 {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
    }
    .sl-alert-info {
        h6, span.legacy-h7 {
            color: #1C303B;
        }
    }
    .sl-alert-success {
        h6, span.legacy-h7 {
            color: #05570A;
        }
    }
    .sl-alert-error {
        h6, span.legacy-h7 {
            color: #55201E;
        }
    }
    .sl-alert-warning {
        h6, span.legacy-h7 {
            color: #653600;
        }
    }
    div[class^="sl-alert-"] {
        a.sl-link {
            text-decoration: underline;
        }
    }
}
.modal-body .alert span.legacy-h7 {
    color: $warning03;
    font-weight:400!important;
}

#comps_perms > label {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #2E383A;
    margin: 36px 0 0 0;
}

#comps_perms {

    .checkbox_head {
        background-color: #FFFFFF;
        padding: 20px 18px;
        border-radius: 8px 8px 0 0;
        border: 1px solid #D5DFE1;
        margin: 24px 0 0 0;

        label {
            color: #2E383A;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            font-style: normal;
        }

        label > span {
            margin-top: 2px;
        }
    }

    .checkbox_list {
        padding: 32px 18px;
        background-color: #FFFFFF;
        border-radius: 0 0 8px 8px;
        border: 1px solid #D5DFE1;
        border-top: none;

        li {
            padding-bottom: 16px;
        }

        label {
            color: #2E383A;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
            font-style: normal;
        }
    }
}

#comps_check div > label {
    width: 100%;
    background-color: #FFFFFF;
    padding: 20px 32px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #D5DFE1;
    margin: 24px 0 0 0;
    color: #2E383A;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    font-style: normal;
}

#comps_check .check_list {
    padding: 32px;
    background-color: #FFFFFF;
    border-radius: 0 0 8px 8px;
    border: 1px solid #D5DFE1;
    border-top: none;

    label {
        color: #2E383A;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        font-style: normal;
    }
}

.alert.alert-danger .sl-payment-alert-action {
    font-size: 14px;
    line-height: 24px;
    color: #55201E;
}

.box_msg_action.pbtm {
    padding: 20px 44px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {

    padding-right: 16px;
    padding-left: 16px;
}

.row {
    margin-right: -16px;
    margin-left: -16px;
}

.sl-position-relative {
    position: relative;
}

.sl-position-absolute {
    position: absolute;
}

.sl-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.label_hidden {
    height: 19px;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.sl-icon-size-xs {
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
}