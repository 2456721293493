/* tags input modificat */

.tagsinput                        { background: transparent; border: 0; padding: 0 }
.tagsinput.disabled               { display: inline-block }
.tagsinput .tag                   { border-radius: 6px; padding: 12px 20px; font-size: 16px; line-height: 20px; margin: 0 10px 10px 0 }
.tagsinput .tag.tag-default       { background: #7b8996 }
.tagsinput .tag i.icon            { vertical-align: middle; padding-right: 5px }
.tagsinput-add-container          { vertical-align: top }
.tagsinput-add-container div      { display: none }
.tagsinput-add-container input {
	min-width: 175px; margin: 0; border: 2px solid #ddd; padding: 10px; border-radius: 6px; background: #fff;
	font-size: 16px; line-height: 18px
}



.tagsinput-add-container input,
.form .box_tags .tagsinput-add-container input {
    color: #93AFB4 !important;
}



.tagsinput-add-container input:focus,
.form .box_tags .tagsinput-add-container input:focus {
    color: #2E383A !important;
}


.tagsinput .tag.disabled:hover                        { padding: 12px 20px !important }
.tagsinput .tag.disabled:hover .tagsinput-remove-link,
.tagsinput.form-disabled       .tagsinput-remove-link { display: none }
.tagsinput-default .tag,
.tagsinput-default .tag.disabled:hover                { background: #bdc3c7; color: white; cursor: default }

.form-disabled .tag,
.form-disabled .tag:hover { background-color: #aaa; color: #fff; padding: 12px 16px 12px 20px; -moz-transition: none; -webkit-transition: none; transition: none; cursor: default }


.fileinput .input-group {
    display: flex;

    .uneditable-input {
        flex: 5;
    }

    .btn-file {
        flex: 1;
        display: flex;
        width: 100%;
    }
}

.input-group .btn-file input { z-index: 0 !important }

.form-group .tagsinput-add-container input                                 { border-color: #BDC3C7 }
.tagsinput-add-container input:hover, .tagsinput-add-container input:focus { border-color: #ABC0C4 }

.form .form-group .tagsinput-primary           { float: left }
.form .form-group .box_flt_rgt { margin: 0 0 0 0px }
.form .form-group .box_flt_rgt.slct_empty      { margin: 0 }

.form .box_tags.fld_combi                      { display: inline-block; float: none }

.form .box_tags select {
    width: 150px;
}

.form .box_tags select.disabled                { display: none }
.form .box_tags select option                  { font-size: 12px }
.form .box_tags select option.disabled         { display: block; color: #ccc }

.tagsinput-add-container input,
.form .box_tags .tagsinput-add-container input {
    border: 1px solid #D5DFE1;
    color: #2E383A;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 12px;
    height: 40px;
    outline: none;
    margin-bottom: 8px;

    &:focus {
        border: 1px solid #3EAC93;
    }
}
.form .tagsinput                               { margin-bottom: 0 }
.form select option.list_not_translated        { font-style: italic; color: #AAA; }

.form .has-switch label { z-index: 2 }

/* Editable version of tags */

.op_create .tag-options-menu { display:none; border:1px solid #000; background-color: #CCC }

.op_create .tagsinput .tag                             { display: inline-block; padding: 0; overflow: hidden }
.op_create .tagsinput .tag span,
.op_create .tagsinput .tag .tagsinput-edit-link        { display: inline-block; }
.op_create .tagsinput .tag .tagsinput-new-remove-link  { display: inline-block; padding: 12px 0; line-height: 100%; color: #5A4B35; min-width: 0px; margin: 0; }

.op_create .tagsinput .tag .tagsinput-edit-link:hover             { cursor: text; }
.op_create .tagsinput .tag .tagsinput-new-remove-link       { margin-left: 4px; width: 16px; }
.op_create .tagsinput .tag .tagsinput-new-remove-link:after { content: "\e922"; font-family: "sally-icon"; font-size: 16px; }

.op_create .tagsinput .tagsinput-edit-control { border: 1px solid #D5DFE1; display: inline-block; margin: 0px 10px 0px 0px; padding: 10px 15px }

.box_tags {
    & + .box_usr_inf {
        margin-top: 4px;
    }
}


.box_tags .tagsinput {
    display: flex;
    flex-wrap: wrap;
    .tag {
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: fit-content;
        background-color: #FBF2D0;
        padding: 8px 12px 8px 12px;
        border-radius: 4px;
        border: none;
        height: 40px;
        color: #5A4B35;
        margin: 0 8px 0 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;

        .tagsinput-remove-link {
            opacity: 1;
            padding: 0;
            position: unset;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;

            &::before {
                font-family: "sally-icon";
                content: "\e922";
                color: #5A4B35;
            }
        }

    }

    .tagsinput-add-container {
        margin: 0 8px 0 0;
    }
}

.box_tags.box_rgt .tagsinput {
    overflow: hidden;
}

.list-tags {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.tag-drag-handler {
    color: #BAAA8A;
    font-size: 20px;
    margin-right: 8px;
    cursor: grab;
    &:hover, &:active, &:focus {
        color: #5A4B35;
    }
}

.tag-hidden-placeholder {
    border: 1px solid #D5DFE1!important;
    background-color: white!important;
}