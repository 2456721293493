@use '../variables/globals' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/content' as *;
@use '../variables/backgrounds' as *;
@use "../mixins/typography" as *;

.#{$namespace}select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}

.#{$namespace}select-label {
  margin-bottom: $spacing200;
  color: $contentSecondary;
}

.#{$namespace}select {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $border04;
  padding: $spacing200 $spacing300;
  width: 292px;
  height: 40px;
  @include generate-type("medium");
  color: $contentDefault;
  border-radius: $borderRadius300;
  background-color: $background04;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.#{$namespace}select-sm {
	@include generate-type("small");
}

.#{$namespace}select.#{$namespace}auto {
  width: unset;
}

.#{$namespace}select:focus-within {
	border-color: $border01;
}