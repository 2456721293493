@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
  font-family: 'remixicon';
  src: url("../assets/remixicon/remixicon.woff2?t=1590207869815") format("woff2"),
  url("../assets/remixicon/remixicon.woff?t=1590207869815") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "sally-icon";
  src:url("../assets/sally-icon/sally-icon.woff") format("woff"),
    url("../assets/sally-icon/sally-icon.woff2") format("woff2");
  font-display: swap;
}

$fontOpenSans: 'Open Sans';
$fontSallyIcon: 'sally-icon';
$fontRemixicon: 'remixicon';