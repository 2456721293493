/*

	Connectors

*/

.tp_market { margin-bottom: 20px }

.box_conn.in_wkf {

    background-image: -webkit-linear-gradient(45deg, #fff 25%, #f3f8fc 25%, #f3f8fc 50%, #fff 50%, #fff 75%, #f3f8fc 75%, #f3f8fc);
	background-image:         linear-gradient(45deg, #fff 25%, #f3f8fc 25%, #f3f8fc 50%, #fff 50%, #fff 75%, #f3f8fc 75%, #f3f8fc);
	background-size: 41px 41px;
}

.box_conn h3   {line-height: 19px}

.box_conn .box_info.btm   {bottom: 70px}
.box_conn .box_info .icon {margin-right: 0}
.box_conn .box_info .md_pk, .box_conn .box_info .md_pk .icon {color: #2ECC71}
.box_conn .box_info .md_ok, .box_conn .box_info .md_ok .icon {color: #E74C3C}

.box_conn .box_info p {
    font-size: 12px;
    line-height: 20px
}

.box_conn .box_btm      {height: 60px; padding-top: 10px}

.conn_ops_check,                  label.conn_ops_check { float: left; display: inline-block; padding-left: 10px }
.in_form.conn_ops_check, .in_form label.conn_ops_check {
    float: none;
    display: block;
    padding-left: 0;
    margin-bottom: 0;
}
.in_form.conn_ops_check .box_tags                      { float: left; margin-left: 0 }

.in_form.conn_ops_check.no_label {
    label {
        display: none;
    }
    .box_tags {
        margin-top: 26px;
    }
}

.conn_ops_check .box_tags  { margin: 10px 0 0 10px }
.conn_ops_check .tagsinput { margin: 0 }

.table-striped td .box_tags .dropdown-short li,
.table-striped td .box_tags .dropdown-large li,
.box_tags .btn-group.select .dropdown-menu  li            { margin: 0 }
.table-striped td .box_tags .dropdown-short li.disabled,
.table-striped td .box_tags .dropdown-large li.disabled,
.box_tags .btn-group.select .dropdown-menu  li.disabled   { display: block }

.dropdown-short > li:first-child > a,
.dropdown-large > li:first-child > a  { border-radius: 6px 0 0 0 }
.dropdown-short > li:last-child  > a,
.dropdown-large > li:last-child  > a  { border-radius: 0 0 0 6px }

.conn_ops_check .box_tags.md_filter .tagsinput .tag {
    height: 40px;
    padding: 8px 12px;

    span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        margin-right: 4px;
    }

    .tagsinput-remove-link {
        font-weight: 600;
    }
}

.conn_ops_check .box_tags           .select                                  { margin: 0; height: 40px }
.conn_ops_check .box_tags.md_filter .btn-group.select button .icon           { top: 8px }

.conn_ops_check.clean           { position: relative; width: 100%; float: none; display: inline-block; padding: 0 }
.conn_ops_check.clean .box_tags { width: 100% }

.box_conn.mrkt .box_info             { top: 24px; left: 24px }

.box_conn.mrkt .box_info p.blogo     {
    position: relative;
    width: 100%;
}

.box_conn.mrkt .box_info p.blogo a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    font-weight: 600;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    color: #2E383A;
    font-size: 16px;
    line-height: 24px;
}

.box_conn.mrkt .box_info p.descr {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Número de líneas que quieres mostrar */
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    color: #4C6569;
}

.box_conn .box_info p { margin: 0 0 4px 0 }

.box_conn.stat_alert h3 a,
.box_conn.stat_alert .box_info,
.box_conn.stat_alert .box_info strong {color: rgba(0,0,0,0.5)}
.box_conn.stat_alert h3 i.icon,
.box_conn.stat_alert .box_info i.icon {color: rgba(0,0,0,0.3)}
.box_conn.stat_alert .btn             {background: rgba(0,0,0,0.4)}
.box_conn.stat_alert .btn:hover       {background: rgba(0,0,0,0.3)}
.box_conn.stat_alert h3               {border-bottom-color: rgba(0,0,0,0.1)}
.box_conn.stat_alert .box_btm         {border-top-color: rgba(0,0,0,0.1)}

.bx_hd_tbl_chn label                                                      { display: block; margin-top: 10px }
.bx_hd_tbl_chn .btn                                                       { padding: 8px 13px }
.bx_hd_tbl_chn .select2.select2-container--bootstrap                      { min-height: 35px }
.bx_hd_tbl_chn .select2-container--bootstrap .select2-selection--multiple { min-height: 20px }
.bx_hd_tbl_chn .select2-container--bootstrap                              { font-size: 12px }

.bx_hd_tbl_chn .btn i {
    font-size: 20px;
}

/*

    Macros en connectors

 */

.box_functions_info                      { font-size: 14px; }
.box_functions_info ul                   { list-style-type: none; padding-left: 0 }
.box_functions_info ul ul                { padding-left: 20px; color: #4C6569 }
.box_functions_info > ul > li            { padding: 0 0 22px 0; margin: 0 0 10px 0 }
.box_functions_info > ul > li:last-child { padding-bottom: 0; border: 0; margin: 0 }
.box_functions_info h4                   { font-size: 14px; line-height: 24px; margin: 4px 0;}
.box_functions_info h5                   { font-size: 12px; line-height: 20px; color: #4C6569; font-weight: normal; margin: 4px 0;}
.box_functions_info h6                   { font-size: 14px; line-height: 24px; font-weight: normal; color: #2E383A; margin: 4px 0;}
.box_functions_info h4 i                 { color: #2E383A; font-weight: normal; padding: 0 5px; font-style: italic; }

#cnn_macr_text,
#test_formula textarea                   { overflow-x: hidden; overflow-y: auto; font-family: 'SFMono', monospace; }

.box_colorpiker {
    position: absolute;
    z-index: 9;
    top: -210px;
    right: 35px;
    background: white;
    padding: 1rem;
    box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
    border-radius: 8px; }

.pickerNon      { display: none; }

.box_sz_bt_ops.top_bt {
    width: 8%;
    min-width: 120px;
    padding-right: 0;
}

.col-auto.box_sz_bt_ops {
    padding-right: 0;
}


.box_sz_bt_ops {
    // width: 10%;
    display: flex;
    align-items: center;
    &.lft {
        width: 8%;
        min-width: 120px;
        padding-right: 0;
        label.checkbox:before {
            width: 19px;
            height: 19px;
            top: 1px !important;
            left: 3px !important;
        }
        .icons {
            span {
                font-size: 24px !important;
            }
        }
        i.icon {
            &:before {
                font-size: 24px;
                color: #ABC0C4;
            }
            &:hover {
                &:before {
                   color: #4C6569;
                }
            }
        }
    }

    button.act_mov {
        padding: 0 14px 0 0;
        color: #ABC0C4;
        max-width: 35px;

        i.icon.no_pdg {
            font-size: 20px;
        }
    }

    label.elm_check.checkbox {
        margin: 0;
        z-index: 0;
    }

    button.btn-xs.act_del, button.btn-xs.act_act {
        height: 40px;
        background-color: transparent;
        &.btn-danger:active {
            border:none;
        }
        .icon {
            font-size: 20px;
            color: #ABC0C4;
        }

        &:hover {
            border-color: transparent;
        }
    }
}

.sep_btm.line_mov .col-frm .form-control.input-sm {
    height: 40px;
    width: 100%;
}

.offset_sz_bt_ops button.btn i.icon {
    font-size: 20px;
    line-height: 20px;
    margin-right: 8px;
}

.box_sz_bt_ops button.btn_formula_conn {
    height: 40px;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 20px;
    background-color: transparent;
    color: #2E383A;

    i.icon {
        font-size: 20px;
        line-height: 20px;
        margin-right: 8px;
    }
}


.box_sz_bt_ops button.btn_formula_conn {
    height: 40px;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 20px;
    background-color: transparent;
    color: #2E383A;
    border: 1px solid transparent;
    &:focus {
        background-color: transparent;
        border-color: #3EAC93;
        border: 1px solid #3EAC93;
    }
    i {
        font-size: 20px;
        line-height: 20px;
        margin-right: 8px;
    }
    i.icon {
        font-size: 20px;
        line-height: 20px;
        margin-right: 8px;
        font-weight: initial;
        line-height: 12px;
    }
    .sl-icon-formula:before {
        content: "";
        background-image: url("../../../img/icons/formula_default.png");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }
    &.btn-danger, &.btn-warning {
        background-color: #FDEDED;
        color: #D2322C;
        .sl-icon-formula:before {
            background-image: url("../../../img/icons/formula_error@2x.png");
        }
        &:hover {
            background-color: #FAD7D6;
        }
        &:active {
            background-color: #F7BCBA;
        }
    }
    &.btn-default {
        border: 1px solid #D5DFE1;
        color: #2E383A;
        .sl-icon-formula:before {
            font-family: "sally-icon", fantasy !important;
            font-style: normal;
            content: "\e907";
            font-weight: normal;
            font-size: 20px;
            background-image: none;
            line-height: 20px;
        }
        &:hover {
            background-color: #EAEEF0;
            border: 1px solid #D5DFE1 !important;
        }
        &:active {
            background-color: #D5DFE1;
        }
    }
    &.btn-primary {
        background-color: #D7F9DB;
        color: #05570A;
        .sl-icon-formula:before {
            background-image: url("../../../img/icons/formula_fill@2x.png");
        }
        &:hover {
            background-color: #E6FAE8;
        }
        &:active {
            background-color: #BFF5C5;
        }
    }
    &.btn-disabled, &readonly, &disabled {
        .sl-icon-formula:before {
            background-image: url("../../../img/icons/formula_no@2x.png");
        }
        em {
            color: #93AFB4;
        }
    }
}


.fld_dsbl .box_sz_bt_ops button.btn_formula_conn {
    cursor: initial;
    background: #EAEEF0;
    border: 1px solid transparent !important;
    em, i {
        color: #ABC0C4;
    }
    .sl-icon-formula:before {
        background-image: url("../../../img/icons/formula_no@2x.png");
    }
    &:hover {
        border: 1px solid transparent !important;
    }
    &:active {
        background: #EAEEF0;
        border: 1px solid transparent !important;
    }
}

div.form-group.sep_ln_top {
    border-color: #D5DFE1;

    span.legacy-h7 {
        font-size: 14px;
        line-height: 24px;
        color: #2E383A;
        margin-top: 20px;
    }

    a {
        font-size: 14px;
        line-height: 24px;
        color: #387B77;

        &:hover {
            text-decoration: underline;
            color: #3D9487;
        }
    }
}

.fld_mrg_top .offset_sz_bt_ops {
    display: flex;

    button {
        margin-right: 12px;
    }
}

.alert_conn_box {
    background-color: #FFFFFF;

    .alert {
        display: flex;
    }
}

.paragrh.box_msg_action.pbtm:not(.on-header) {
    background-color: transparent;
}

.crumbs_formula_conn {
    padding: 0;
}

@media (max-width: 1200px) {

    .box_list .sklt                     {width: 50%}

    .box_list .sklt.dbl,
    .box_list .sklt.trp,
    .box_list .sklt.mxm                 {width: 100%}

    .minus_left .box_list .sklt         {width: 25%}
	.minus_left .box_list .sklt.dbl     {width: 50%}
    .minus_left .box_list .sklt.trp     {width: 75%}
	.minus_left .box_list .sklt.mxm     {width: 100%}

    .box_dashb {font-size: 14px; line-height: 18px}

    .box_dashb, .box_conn         {min-height: 250px; max-height: 250px}
    .box_dashb.msg, .box_conn.msg {min-height: none; max-height: none}

    .box_dashb.bx_bench.hvar .bx_elm   {margin-top: 5px}

    .box_form       .btn .icon         { margin: 0; padding: 0 }
    .box_pagination .btn em.alter      { display: inline }
    .box_pagination .btn i.icon        { padding-right: 0 }
}

@media (max-width: 1000px) {

    .box_conn .box_info em              { display: none }
    .box_conn .box_info em.alter        { display: inline }

    #fld_bx_srch > i.icon {
        font-size: 22px;
        line-height: 24px;
        padding-top: 5px
    }
    #fld_bx_srch > input  { width: 200px; font-size: 16px; line-height: 18px; padding-top: 5px }
    #fld_bx_srch button   { font-size: 20px; line-height: 22px; padding-top: 5px }
}

@media (max-width: 900px) {

    .minus_left .box_list .sklt         {width: 50%}
    .minus_left .box_list .sklt.trp,
	.minus_left .box_list .sklt.dbl     {width: 100%}

    #fld_bx_srch > input  { width: 125px }
}

@media (max-width: 800px) {

    .box_conn .box_info .icon           {font-size: 100%}
    .box_conn .box_info p               {margin-bottom: 5px}

    .box_list .sklt {width: 50%}

    .minus_left .box_list .sklt         {width: 50%}
    .minus_left .box_list .sklt.trp,
	.minus_left .box_list .sklt.dbl     {width: 100%}

	.offset_sz_bt_del                   {margin-left: 0}

    #fld_bx_srch { display: none }
}

@media (max-width: 650px) {

    .box_list .sklt,
	.minus_left .box_list .sklt              { width: 100% }
	.minus_left .box_list.tp_conn .sklt.dbl,
    .minus_left .box_list.tp_conn .sklt.mxm  { width: 100% }
}

@media (max-width: 450px) {

    .box_list .sklt,
	.minus_left .box_list .sklt {width: 100%}
}

@media (min-width: 1400px) {

    .box_dashb.bx_bench .bx_elm.st_lst { margin-top: 11px }

    #fld_bx_srch > i.icon {
        font-size: 24px;
        line-height: 26px;
    }
    #fld_bx_srch > input  { width: 350px; font-size: 18px; line-height: 20px }
    #fld_bx_srch button   { font-size: 22px; line-height: 24px }

    .box_list.tp_conn .sklt.dbl { width: 50%  }
    .box_list.tp_conn .sklt.trp { width: 75%  }
    .box_list.tp_conn .sklt.mxm { width: 100% }

}

@media (min-width: 1600px) {

    .box_dashb.bx_bench .bx_elm.st_lst {margin-top: 2px}
}

.box_dashb.msg, .box_conn.msg {min-height: 100%; max-height: none}

.box_form .sl-conn-title {
    padding-left: 0;
}



.box_form .form-group {
    > h6.title_text {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #2E383A;
        margin-bottom: 0;
        margin-top: 36px;
    }
}

.box_form .form-group.row {
    > label.title_text {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #2E383A;
        margin-bottom: 0;
        margin-left: 16px;
        margin-top: 36px;
    }
    > h5.title_text {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #2E383A;
        margin-bottom: 0;
        margin-left: 16px;
        margin-top: 36px;
    }
    .id_code_connector,

    .private_key_conn {
        > label {
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
            color: #2E383A;
            margin-bottom: 16px;
        }

        > h5.no_mrtp {
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            color: #2E383A;
        }
    }
    &.sl-conn-language > .col-sm-12 {
        label[for="output_languages"] {
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
            color: #2E383A;
            margin-bottom: 16px;
            margin-top: 36px;
        }
    }
}

.form-group.sl-conn-language {
    ul.check_list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    div#CTLANG.switch_box {
        margin-top: 24px;
    }

    label.checkbox .first-icon {
        z-index: 1;
    }
}


.form-group.form_private_key_conn {
    margin-bottom: 16px;
}

.private_key_conn {

    h3 {
        color: #2E383A;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }

    ul {
        margin: 0;

        li.td_key {
            align-items: center;
            color: #2E383A;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            justify-content: space-between;
            margin-top: 16px;

            .td_btn_opt {
                height: 20px;

                button {
                    i {
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 400;
                        color: #CFDADD;
                    }
                }
            }
        }

        li.td_key:first-child {
            margin-top: 0;
        }
    }
}

#box_form_content .form-group.overflow {
    overflow: visible;
    width: 100%;
    display: inline-block;
}
#box_form_content .form-group.overflow .btn {
    float: left;
}

.form-group.overflow.conn_sdk {
    margin-top: 60px;
}

.form-group .xl-tooltip.conn_icon_info {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    color: #CFDADD;
    position: absolute;
    top: 2px;
}
/*
select.form-control#select_secret {
    color: #93AFB4;
}
*/
#box_content .form .box_form.box_form_conn {
    padding: 0px 44px 40px 44px;
}

.form_group_plugin {
    margin-top: 44px;
}

.conn_tags_box {
    width: 100%;
}

#conn_move_shadow {
    background-color: transparent !important;
    border: 1px solid #D5DFE1;
}

.paragrh .form .mgbform h4.title.crbTp.sl_conn_title {
    background-color: white;
    padding: 40px 44px 20px 44px;
}

// .form-group.row.conn_description_group {
//     position: absolute;
//     top: 100px;
//     width: 100%;
// }

label[for="conn_description"] {
    text-transform: capitalize;
}

#crumbs_box_companies_connectors {
    margin-bottom: 44px;
}

div.box_list.tp_conn {
    padding: 24px 28px 44px 44px;

    div.sklt {
        padding: 0 16px 16px 0;

        .box_conn {
            border: 1px solid #D5DFE1;

            &:hover {
                border: 1px solid #3D9487;
            }

            .box_info {
                a {
                    img {
                        height: 34px;
                        width: fit-content;
                    }
                }
            }

            h3 {
                padding: 24px 24px 0 24px;

                    span  {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 700;
                    color: #2E383A;
                    line-height: 24px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    img {
                        height: 34px;
                        transform-origin: 0 0;
                        width: fit-content;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 700;
                        color: #2E383A;
                        line-height: 24px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }

            .box_info.btm.chann_box_info {
                margin-top: 8px;
                position: unset;
                padding: 0 24px;

                p {
                    color: #4C6569;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 16px;
                        line-height: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    em {
                        font-style: normal;
                    }
                }

                p.md_pk {
                    color: #0BA815;

                    i {
                        color: #0BA815;
                    }
                }

                .connDescription {
                    top: 32px;
                    right: 24px;
                    bottom: unset;
                    margin: 0;

                    i {
                        margin: 0;
                    }
                }
            }

            .box_btm {
                height: unset;
                padding: 0;
                bottom: 24px;
                left: 24px;
                right: auto;
                .btn {
                    height: 32px;
                    padding: 8px 16px;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .box_conn.stat_alert {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            .box_info {
                display: none;
            }

            h3 {
                display: none;
            }

            .box_info.btm.chann_box_info {
                display: none;
            }

            .box_btm {
                position: unset;
                padding: 0;
                margin-bottom: 24px;
                height: unset;

                .btn {
                    background-color: #D5DFE1;
                    height: 32px;
                }
            }

            .channel_disabled {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 46px;

                i {
                    font-size: 44px;
                    line-height: 44px;
                    height: 44px;
                    color: #4C6569;
                    margin-bottom: 4px;
                }

                h4 {
                    color: #2E383A;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    margin-bottom: 4px;
                    text-align: center;
                    max-height: 48px;
                    padding: 0 20px;
                    max-width: 300px;
                    overflow: hidden;
                }

                p {
                    color: #4C6569;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

    }
}

@media screen and (min-width: 1400px) {
    div.box_list.tp_conn {
        div.sklt {
            width: 20%;
        }
    }
}


form[name="companies_connectors"] {
    .crumbs_conn {
        display: none;
    }
    #crumbs_box_table_active {
        background-color: transparent;
        margin-bottom: 44px;
        .wrapper {
            height: auto;
            ul.nav-tabs {
                position: relative;
                padding: 0;
                > li {
                    margin-left: 1px;
                    position: relative;
                    margin-right: 20px;
                    border-radius: 4px;
                    margin-bottom: 0;
                    border-bottom: 0;
                    &:hover {
                        background-color: #EAEEF0;
                    }
                    a {
                        padding: 4px 16px;
                        margin-left: 16px;
                        color: #4C6569;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        border: none;
                    }
                    &:before {
                        position: absolute;
                        left: 16px;
                        top: calc(50% - 5px);
                        content: "";
                        width: 9px;
                        height: 9px;
                        border-radius: 100%;
                        background-color: #CFDADD;
                    }
                }
                > li.active {
                    background-color: #EAEEF0;
                    border-bottom: none;
                    a {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: #2E383A;
                    }
                    &:before {
                        background-color: #3D9487;
                    }
                }
                > li.last-child-tab {
                    a {
                        margin-left: 0px;
                        em {
                            font-style: normal;
                        }
                    }
                    &::before {
                        content: initial;
                    }
                }
            }
        }
    }
}
.form-group.button-download {
    margin-bottom: 44px;
}

#cXMLFieldsMapContainer {

    &.tab-content {
        border: none;
        padding: 0px 0px 10px;
    }

    .inline .form-group {
        float: left;
    }

    .asr_icon {

        .btn {
            padding: 10px 0px;
        }

        a.act_trash {
            color: #BDC3C7;

            &:hover {
                color: #FF4742;
            }
        }

    }

    .form-group + .form-group.asr_icon {
        padding-left: 20px;
    }

    option.unselected {
        font-style: italic;
    }

}

#CSVFieldsMapContainer {

    .inline .form-group {
        float: left;
    }

    .col-md-1.icon-tiny {
        max-width: 48px;
    }

    .select-wrapper::after {
        content: '';
        position: absolute;
        right: 13%;
        top: 33%;
        width: 24px;
        transform: translateY(-50%);
        border-radius: 100px;
        background: #EAEEF0;
        text-align: center;
        font-size: 10px;
    }

}

.visibilityDropdownBtn button.dropdown-toggle i.sl-icon-chevron-down{
    margin-right: 0px!important;
}

.csv-map-arrow {
    font-size: 24px;
    color: #ABC0C4;
}

.form-icon-trash {
    font-size: 24px!important;
    font-weight: normal!important;
    color: #ABC0C4;
    &:hover {
        color: #FF4742;
    }
}

.sl-buttons-refresh {
    > .btn {
        margin-right: 12px !important;
    }
    .box_desc {
        width: 100%;
    }
}

.box_form_conn .tooltip-inner {
    position: relative;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    white-space: normal;
}

.box_form_conn .form-group .tooltip-inner {
    width: max-content;
}