@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;
@use '../variables/actions' as *;
@use '../variables/semantic' as *;

.#{$namespace}admin-box {
    background: $white;
    border: 2px solid;
    border-color: $secondary300;
    border-radius: $borderRadius300;
    width: 100%;
    padding: $spacing600;
    display: flex;
    flex-wrap: wrap;
}