@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/borders' as *;
@use '../variables/transitions' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;
@use '../variables/semantic' as *;
@use '../variables/backgrounds' as *;
@use '../variables/typography' as *;
@use '../variables/actions' as *;
@use '../variables/icons' as *;
@use "../mixins/typography" as *;

.#{$namespace}pagination {
	display: flex;
	flex-direction: row;
	a {
		&:first-child {
			.#{$namespace}pagination-item {
				border-radius: $borderRadius300 0 0 $borderRadius300;
			}
		}
		&:last-child {
			.#{$namespace}pagination-item {
				border-radius: 0 $borderRadius300 $borderRadius300 0;
				border-right: 1px solid $border04;
				&:disabled {
					border-right: 1px solid $border04;
				}
				&:hover {
					&:disabled {
						border: 1px solid $border04;
					}
				}
			}
		}
	}
	&-item {
		border: 1px solid $border04;
		border-right: 0;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		outline: 0;
		transition: all $transitionSpeed01 ease;
		height: 40px;
		width: 40px;
		background-color: $white;
		color: $contentDefault;
		border-radius: 0;
		&:hover {
			background-color: $actionSecondaryDefault;
			&:disabled {
				border: 1px solid $border04;
				border-right: 0;
			}
		}
		&:active {
			background-color: $actionSecondaryDefault;
		}
		&:disabled {
			background-color: transparent;
			color: $iconDisable;
			cursor: default;
			border-right: 0;
		}
		&.active {
			background-color: $actionSecondaryDefault;
		}
		&.arrow {
			font-size: $fontSize400;
		}
		&.more {
			font-size: $fontSize400;
			font-weight: $fontBold;
			cursor: unset;
			&:hover {
				background-color: transparent;
			}
		}
	}	
	&-active {
		background-color: $neutral250;
	}
}