@use '../variables/globals' as *;
@use '../variables/typography' as *;
@use '../variables/fonts' as *;
@use '../variables/icons' as *;
@use '../variables/content' as *;

.#{$namespace}empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 467px;
  margin: 0 auto;
  position: absolute;
  top: 20vh;
  height: 0px;
  left: 50%;
  transform: translate(-50%, -150%);
  &-img {
    width: 150px;
    text-align: center;
  }

  &-icon {
    font-size: 64px;
    color: $iconLight;
  }

  &-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-title {
    font-weight: $fontBold;
    font-size: $fontSize500;
    line-height: $lineHeight500;
    text-align: center;
    color: $contentDefault;
  }

  &-description {
    font-family: $fontOpenSans;
    font-weight: $fontRegular;
    font-size: $fontSize300;
    line-height: $lineHeight300;
    text-align: center;
    color: $contentDefault;
  }

  &-actions {
    display: flex;
  }
}
