@use '../../variables/globals' as *;
@use '../../variables/colors' as *;

.#{$namespace}panel-comment {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: 8px;
    cursor: pointer;
    &-header {
        .#{$namespace}flag {
            top: 0px;
            right: 0px;
            }
        &-right {
            position: relative;
            display: grid;
            &-bottom {
                line-height: 1em;
            }
        }
    }
    &-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        white-space: normal; 
    }
    &:hover {
        background-color: #F8F8F8;
    }
    
}
