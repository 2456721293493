
@use '../../variables/globals' as *;

.#{$namespace}product-fruits-container {
    height: 80px;
    width: 24px;
    .#{$namespace}product-fruits-button {
        width: 24px;
        height: 24px;
    }
}

.#{$namespace}custom-life-ring-launcher  {
    z-index: 900;
    transition: none;
    &-disabled {
        opacity: 0;
        pointer-events: none;
    }
}