/*

    Charts

*/

.sklt_paragh,
.chart_sklt            { position: relative; width: 100%; overflow: hidden }
.chart_sklt.left       { float: left; margin-right: 20px }
.chart_sklt.right      { float: right; margin-left: 20px }
.chart_sklt.pie        { width: 200px }

.chart_sklt.pre_ldng   { height: 200px }

.chart_sklt .prld      {

    position: absolute;
    top: 50%; height: 20px; margin-top: -10px;
    left: 50%; width: 60px; margin-left: -30px;
    background: url(../../../img/prld_mina.gif) center center no-repeat;
}

.box_info.chart h5,
.box_info.chart h6,
.box_info.chart span.legacy-h7     { position: relative; overflow: hidden; display: block; margin: 0 auto; text-align: center; color: #4C6569; }
.box_info.chart h5,
.box_info.chart h6     { color: rgba(0,0,0,0.7) }

.chart_box             { position: relative; display: block; width: 100%; height: 200px; text-align: center; margin: 0 20px 0 0 }
.chart_box.back        { background: #fff; padding: 10px }
.chart_box.back canvas { width: 100%; height: 100%; overflow: auto }
.chart_box.hmed        { height: 150px }
.chart_box.hprc        { height: 250px }
.chart_box.hmax        { height: 300px }
.chart_box.no_mrg_btm  { margin-bottom: 0 }

.pie .chart_box        { border-top: 15px solid transparent }

.chart-legend          { position: absolute; top: 20px; right: 20px; padding: 5px 10px 0; background: rgba(255,255,255,0.5); font-size: 12px; line-height: 16px}
.chart-legend ul       { list-style: none; padding: 0; margin: 0 }
.chart-legend li       { padding: 5px 0; margin: 0; text-align: left }
.chart-legend li span  { display: inline-block; width: 12px; height: 12px; margin-right: 5px; vertical-align: middle }

.chart_sklt.legend_bottom .chart-legend    { position: relative; width: 100%; top: auto; left: auto; overflow: hidden; background: transparent }
.chart_sklt.legend_bottom .chart-legend ul { display: inline-block; margin: 0 auto; overflow: hidden; width: auto }
.chart_sklt.legend_bottom .chart-legend li { float: left; margin-left: 20px }

.chart_sklt.pie .chart_box.legend_bottom .chart-legend { float: none; }