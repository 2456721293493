@use '../variables/globals' as *;
@use '../variables/colors' as *;

.#{$namespace}panel {
    background-color: white;
    width: 404px;
    height: calc(100% - 79px);
    top: 79px;
    position: fixed;
    right: -404px;
    border-radius: 0 !important;
    display: flex;
    flex-direction: column;
    &-top {
        margin-bottom: 18px;
        margin-top: 14px;
        margin-left: 32px;
        margin-right: 32px;
    }
    &-content {
        max-height: calc(100% - 100px);
        margin-left: 16px;
        margin-right: 8px;
        padding-bottom: 28px;
        flex: 1;
    }
    &-header {
        margin-left: 32px;
        margin-right: 32px;
    }
}