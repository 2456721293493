@use '../variables/globals' as *;
@use '../variables/typography' as *;
@use '../variables/fonts' as *;
@use '../variables/spacing' as *;
@use '../variables/borders' as *;
@use '../variables/content' as *;
@use '../variables/actions' as *;
@use "../mixins/typography" as *;


.#{$namespace}tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.#{$namespace}tab {
  &-container {
    border-bottom: 1px solid $border04;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  &-content {
    overflow-y: auto;
    max-height: 100%;
    flex-grow: 1;
  }
  &-label {
    @include generate-type("medium");
    font-weight: $fontRegular;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing200 $spacing400;
    cursor: pointer;
    color: $contentSecondary;
    margin: 0;

    &:hover {
      color: $contentDefault;
    }
    &.active {
      color: $actionPrimaryHover;
      border-bottom: 2px solid $actionPrimaryHover;
      margin-bottom: -2px;
    }
  }
}

.form {
  table {
    .btn-group.select {
      margin: 0;
      button.dropdown-toggle {
        background: transparent;
      }
      button.dropdown-toggle:hover {
        background: rgba(0,0,0,0.05);
      }
      .dropdown-menu {
        a:hover {
          cursor: pointer;
        }
      }
      td.td_numb {
        text-align: right;
      }
    }
  }
}

