/*

	Capsal seccions
*/

.box_head_sec,
.box_head_subsec,
.box_search,
.num_channels {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 44px 0px 44px;
}

.num_channels {
    padding-top: 20px;
}

.head_channels h1  {
    padding-bottom: 0;
}

.head_channels .box_msg_action {
    padding-bottom: 0
}

.num_elements.num_channels {
    h3.sl-content-light {
        color: #7B9BA1;
    }
    h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: #262C2A;
        margin-top: 0px;
        &.sl-content-default{
            color: #2E383A;
        }
    }
}

.box_head_sec {
    z-index: 3;
    padding-top: 40px;
}

.box_report .box_head_sec {
    padding-top: 44px;
}

.box_head_white {
    background-color: white;
}

.box_head_subsec,
.box_search      { z-index: 2 }

.box_head_sec.pad_btm,
.box_head_subsec.pad_btm {
    padding-bottom: 20px;
    padding-top: 0;
}

.box_head_subsec.cmpct {padding-top: 0}

.box_head_sec h1 {
    font-size: 29px;
    line-height: 36px;
    font-weight: 700;
    color: #2E383A;
}

.box_head_sec h1.head_sec_subtitle {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #2E383A;
    margin-top: 12px;
}

.box_head_sec h2,
.box_head_subsec h2,
h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #7B9BA1;
    margin-top: 12px;
}

.box_head_sec    h3,
.box_head_subsec h3          { margin: 0; font-size: 26px; line-height: 40px; color: #8799ab }

.box_head_sec h1.up_tbs {
    padding-bottom: 26px;
}
.box_head_sec h6 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #7B9BA1;
  }

.box_head_sec h1 strong, .box_head_subsec h1 strong,
.box_head_sec h2 strong, .box_head_subsec h2 strong,
.box_head_sec h3 strong, .box_head_subsec h3 strong  { display: inline-block }

.box_head_sec h1 .rgt_btn, .box_head_subsec h1 .rgt_btn {position: relative; overflow: hidden; float: right}

.box_dashb .box_info h1          { margin: 0; font-size: 35px; line-height: 45px }
.box_dashb .box_info h4,
.box_dashb .box_info h5,
.box_dashb .box_info h6          { color: #8799ab; white-space: nowrap }

.box_head_sec    h1 a:hover strong, .box_head_sec    h1 a:focus strong,
.box_head_sec    h2 a:hover strong, .box_head_sec    h2 a:focus strong,
.box_head_sec    h3 a:hover strong, .box_head_sec    h3 a:focus strong,
.box_head_subsec h1 a:hover strong, .box_head_subsec h1 a:focus strong,
.box_head_subsec h2 a:hover strong, .box_head_subsec h2 a:focus strong,
.box_head_subsec h3 a:hover strong, .box_head_subsec h3 a:focus strong,
.box_dashb .box_info h4 a:hover strong, .box_dashb .box_info h4 a:focus strong,
.box_dashb .box_info h5 a:hover strong, .box_dashb .box_info h5 a:focus strong,
.box_dashb .box_info h6 a:hover strong, .box_dashb .box_info h6 a:focus strong  { color: #387B77 }

.box_head_sec    h1 .text-primary, .box_head_sec    h1 .text-primary strong,
.box_head_sec    h2 .text-primary, .box_head_sec    h2 .text-primary strong,
.box_head_sec    h3 .text-primary, .box_head_sec    h3 .text-primary strong,
.box_head_subsec h1 .text-primary, .box_head_subsec h1 .text-primary strong,
.box_head_subsec h2 .text-primary, .box_head_subsec h2 .text-primary strong,
.box_head_subsec h3 .text-primary, .box_head_subsec h3 .text-primary strong,
.box_dashb .box_info h4 .text-primary, .box_dashb .box_info h4 .text-primary strong,
.box_dashb .box_info h5 .text-primary, .box_dashb .box_info h5 .text-primary strong,
.box_dashb .box_info h6 .text-primary, .box_dashb .box_info h6 .text-primary strong  { color: #18C925 }

.box_head_sec    h1 .text-warning, .box_head_sec    h1 .text-warning strong,
.box_head_sec    h2 .text-warning, .box_head_sec    h2 .text-warning strong,
.box_head_sec    h3 .text-warning, .box_head_sec    h3 .text-warning strong,
.box_head_subsec h1 .text-warning, .box_head_subsec h1 .text-warning strong,
.box_head_subsec h2 .text-warning, .box_head_subsec h2 .text-warning strong,
.box_head_subsec h3 .text-warning, .box_head_subsec h3 .text-warning strong,
.box_dashb .box_info h4 .text-warning, .box_dashb .box_info h4 .text-warning strong,
.box_dashb .box_info h5 .text-warning, .box_dashb .box_info h5 .text-warning strong,
.box_dashb .box_info h6 .text-warning, .box_dashb .box_info h6 .text-warning strong  { color: #D57300 }

.box_head_sec    h1 .text-danger, .box_head_sec    h1 .text-danger strong,
.box_head_sec    h2 .text-danger, .box_head_sec    h2 .text-danger strong,
.box_head_sec    h3 .text-danger, .box_head_sec    h3 .text-danger strong,
.box_head_subsec h1 .text-danger, .box_head_subsec h1 .text-danger strong,
.box_head_subsec h2 .text-danger, .box_head_subsec h2 .text-danger strong,
.box_head_subsec h3 .text-danger, .box_head_subsec h3 .text-danger strong,
.box_dashb .box_info h4 .text-danger, .box_dashb .box_info h4 .text-danger strong,
.box_dashb .box_info h5 .text-danger, .box_dashb .box_info h5 .text-danger strong,
.box_dashb .box_info h6 .text-danger, .box_dashb .box_info h6 .text-danger strong    { color: #D2322C }

.box_head_sec.cmpct h2.lft {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #2E383A;
    margin-top: 0;
}

.box_head_sec.cmpct h3.lft,
.box_head_subsec.cmpct h3.lft {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #4C6569;
}

.box_head_sec.cmpct select.form-control {
    width: -webkit-fill-available;
}

.box_head_sec h1 .btn-group,
.box_head_sec h2 .btn-group { vertical-align: baseline }

.box_head_sec h1.lft, .box_head_subsec h1.lft,
.box_head_sec h2.lft, .box_head_subsec h2.lft,
.box_head_sec h3.lft, .box_head_subsec h3.lft { float: left; margin-right: 10px }

.box_head_sec h1 .btn-group.select button.btn_hide.dropdown-toggle {
    display: flex;
    border-radius: 4px;
    padding: 10px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;

    .title {
        padding: 0;
        color: #2E383A;
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        height: 20px;
    }

    &:hover {
        background-color: #EAEEF0;
    }
}

.box_head_sec h1 .btn-group.select .dropdown-arrow      { top: 35px; right: 0 }
.box_head_sec h1 .btn-group.select .dropdown-menu       { top: 36px }
.box_head_sec h1 .btn-group.select .dropdown-menu.right { left: auto; right: -20px }

.box_head_sec h1 .sublev {
    vertical-align: bottom;
}

.box_head_sec h1 .sublev {
    margin-top: 10px;
    .htitl {
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #2E383A;
    }
}

.box_head_sec h1 .sublev .htitl .icon {
    vertical-align: middle;
    font-size: 20px;
    line-height: 20px;
    color: #2E383A;
    padding-right: 8px;
}


#box_form_content #bx_slct_prd_frmt {
    background-color: #FFFFFF;
}

#bx_slct_prd_frmt .box_head_sec h1 {
    display: flex;
    flex-wrap: wrap;

    .btn-group.select.drop_right {
        margin-left: 8px;
    }

    span.sublev {
        width: 100%;
    }
}

.box_head_sec .dropdown-menu.dropdown-large li a i,
.box_list_ops .dropdown-menu.dropdown-large li a i,
.box_list_ops .btn-group.select .dropdown-menu li a i {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #2E383A;
}

.box_search.box_list_ops .dropdown-menu.adv-srch-dropdown.dropdown-large li a {
    &:hover {
        i {
            color: #3D9487;
        }
    }
    i {
        font-size: 20px;
    }
}

#box_content.box_report .box_head_sec.box_report_head {
    background-color: #FFFFFF;

    .rgt_btn button.btn {
        padding: 10px;
        background-color: #FFFFFF;
        color: #2E383A;

        &:hover {
            background-color: #EAEEF0;
        }

        i {
            font-size: 20px;
            line-height: 20px;
        }
    }

    .crumbs {
        padding: 0;

        ul.nav {
            border: none;
            margin: 30px 0 0px 0;
        }
    }
}


.box_head_sec.sl_title_info {
    background-color: #FFFFFF;
    padding-bottom: 32px;

    h2 {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: #7B9BA1;
        margin-top: 12px;
    }
}

.crumbs.sl_title_crumbs {
    padding-top: 0px;

    ul {
        padding-top: 0px;
    }
}

.paragrh .form .mgbform h4.title.sl-payment-information-title {
    position: absolute;
    z-index: 999;
    top: -133px;
    padding: 0px;
}

.sl-box-head-payment .sl-payment-crumbs {
    padding-top: 110px;
}

.box_head_sec h2.num_elements {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #7B9BA1;
    margin-top: 12px;

    i a {
        color: #387B77;

        &:hover {
            color: #3D9487;
        }

        &.sl-with-errors {
            color: #D2322C;
            font-weight: 600;
            font-size: 14px;
            text-transform: lowercase;
        }
    }
}

.sl-with-errors-digit{
    width: 16px;
    height: 16px;
    background-color: #ED352E;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 600;
    margin: 0px 8px 0px 12px;
}

.box_search.box_list_ops {
    padding-top: 40px;
}