@use '../../variables/globals' as *;
@use '../../variables/icons' as *;
@use '../../variables/colors' as *;

.#{$namespace}ct-mention-users-popover {
    position: absolute;
    width: 254px;
    height: 156px;
    overflow-y: auto;
    background: white;
    box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
    left: 0;
    padding: 8px;
    border-radius: 4px;
    z-index: 100;
    .#{$namespace}avatar {
        width: 32px;
        height: 32px;
    }
}

.#{$namespace}ct-mention-users-author-section {
    cursor: pointer;
    &:hover{
        background-color:#F8F8F8;
        border-radius:4px;
        color: #3D9487;
    }
}


.#{$namespace}ct-user-highlight {
    color: #3D9487 !important;
}