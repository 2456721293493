// REVIEW: missing spacing, border-radius and color variables
@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/transitions' as *;
@use '../variables/backgrounds' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/fonts' as *;
@use "../variables/content" as *;
@use '../variables/zindex' as *;
@use "../mixins/typography" as *;


.#{$namespace}onboarding {
	position: absolute;
    z-index: $zindex850;
	overflow: hidden;
	border-radius: $borderRadius300;
	box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
	max-width: 375px;
	min-width: 200px;
    opacity: 0;           
    visibility: hidden; 
	pointer-events: none;      
	transform: translateY(-20px);  
    animation: onboardingFadeOut 0.2s forwards; 
	&.#{$namespace}onboarding-show {
		opacity: 1;
		visibility: visible;
		animation: onboardingFadeIn 0.2s forwards;   
		transform: translateY(0);  
	}
	&.#{$namespace}onboarding-active {
		pointer-events: auto;  
	}
	&-content {
		padding: $spacing600;
		background: $white;
	}
	&-icon {
		border-radius: 5px;
		padding: $spacing150;
		background-color: $skyblue400;
		display: inline-flex;
		img {
			width: 16px;
			height: 16px;
		}
	}
	&-title {
		font-size: $fontSize400;
		font-weight: $fontBold;
	}
	&-text {
		font-size: $fontSize200;
		color: $neutral900;
		p {
			font-size: unset;
			color: unset;
			margin-bottom: $spacing500;
		}
	}
}

@keyframes onboardingFadeIn {
    from {
        opacity: 0;
		transform: translateY(-20px);
    }
    to {
        opacity: 1;
		transform: translateY(0);
    }
}

@keyframes onboardingFadeOut {
    from {
        opacity: 1;
		transform: translateY(0);
    }
    to {
        opacity: 0;
		transform: translateY(-20px);
    }
}

*[data-onboarding] {
	cursor: pointer;
}