// Edit items
.itemValidation_validateOk{
    .itemValidationButton, .itemValidationButton:active, .itemValidationButton:focus {
        background: #E6FAE8;
        i {
            color: #0BA815; 
        }
        &:hover {
            background: #BFF5C5;
        }
    }
    input, textarea {
        border-color: #0BA815;
    }
}
.itemValidation_validateError{
    .itemValidationButton {
        background: #D2322C;
    }
    input, textarea {
        border-color: #D2322C;
    }
    .itemValidationButton, .itemValidationButton:active, .itemValidationButton:focus {
        background: #FDEDED;
        width: 24px;
        height: 24px;
        font-size: 16px;
        padding: 0;
        &:after {
            content: "\e92d";
            font-family: 'sally-icon';
            color: #D2322C;
            font-weight: normal;
        }
        &:hover {
            background: #FAD7D6;
        }
        i {
            display: none;
        }
    }
}
.itemValidationTooltip {
    &.top {
        .tooltip-arrow {
            border-top-color: #fff;
        }
    }
    .tooltip-inner {
        max-width: 180px;
        width: 180px;
        background-color: #fff;
        text-align: left;
        padding: 24px;
        box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
        span {
            color: #4C6569;
            font-size: 12px;
            &.validateOk {
                color: #0BA815;
            }
            &.validateError {
                color: #D2322C;
            }
            strong {
                color: #2E383A;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                text-transform: capitalize;
            }
        }
    }
}
// List items
.box_list {
    // Grid view
    &.md_vw .itemValidationAlertList {
        position: absolute;
        left: auto;
        right: 28px;
        top: 59px;
        background: #FDEDED;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        color: #D2322C;
        text-align: center;
        line-height: 1.5;
        i {
            font-size: 16px;
        }
    }
    // Table view
    &.md_ls .itemValidationAlertList {
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background: #FDEDED;
        color: #D2322C;
        text-align: center;
        padding: 3px;
        line-height: 1.231;
        i {
            font-size: 16px;
        }
    }
}

/* Remove arrows on number inputs */
.form-validation {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
}
