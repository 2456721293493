@use '../variables/globals' as *;
@use '../variables/typography' as *;
@use '../variables/backgrounds' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/actions' as *;
@use '../variables/borders' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;

.#{$namespace}toggle {
  @include generate-type("medium"); 
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &-input { 
    font: inherit;
  	cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    background-color: $background03;
    margin: 0;
    width: 42px;
    height: 24px;
    border: none;
    border-radius: 16px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &:before {
      content: "";
      border-radius: $borderRadius900;
      height: 16px;
      width: 16px;
      background-color: $background04;
      transition: all .3s ease;
      margin: 0px $spacing100;
    }
    
    &:checked {
      background-color: $background10;
      justify-content: flex-end;
      
      &:active {
        background-color: $background09;
      }
      
      &:hover {
        background-color: $actionPrimaryHover;
      }
      
      &:disabled {
        background-color: $actionSecondaryHover;
        
        &::before {
          background-color: $background03;
        }
      }
    }
    
    &:hover {
      background-color: $background06;
    }
    
    &:focus {
      border: 1px solid $border01;
    }
    
    &:disabled {
      background-color: $background02;
      
      &::before {
        background-color: $background03;
      }
    }
    
    &:disabled ~ .toggle-text{
      color: $contentDisable;
    }
  }
  
  &-text {
    color: $contentDefault;
    margin-left: $spacing200;
  }
}