/* label component */
@use '../variables/globals' as *;
@use '../variables/colors' as *;
@use '../variables/spacing' as *;
@use '../mixins/typography' as *;
@use '../variables/typography' as *;

.#{$namespace}label {
  	display: flex;
  	align-items: center;
    color: $neutral800;
	@include generate-type("medium");
    font-weight: $fontRegular;
    letter-spacing: 0px;
    text-align: left;
  	margin-bottom: $spacing200;
    &-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    &-edit {
        margin-bottom: $spacing200;
        display: flex;
        align-items: center;
    }
}