@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/dataviz' as *;
@use '../variables/colors' as *;

.#{$namespace}progress-bar {
  position: relative;
  background-color: $background02;
  width: 100%;
  height: 8px;
  border-radius: $borderRadius300;
  overflow: hidden;
  &-status {
    position: absolute;
    background-color: $background13;
    width: 25%;
    height: 8px;
    border-radius: $borderRadius300;
  }
  &-indeterminate {
    .#{$namespace}progress-bar-status {
      width: 100%;
      height: 8px;
      border-radius: $borderRadius300;
      animation: indeterminateAnimation 1s infinite linear;
      -webkit-animation: indeterminateAnimation 1s infinite linear;
      transform-origin: 0% 50%;
    }
  }
  .progress-bar {
    background-image: linear-gradient(90deg, rgb(123, 155, 161) 100%, transparent);
  }
}

.#{$namespace}loader-upload {
  top: 0;
  min-height: 200px;
  position: absolute;
  z-index: 99;
  background: white;
  width: 100%;
  height: 100%;
  svg {
    width: 48px;
  }
}

@keyframes indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0) scaleX(0);
    transform:  translateX(0) scaleX(0);
  }
  40% {
    -webkit-transform: translateX(0) scaleX(0.4);
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(0.2);
    transform:  translateX(100%) scaleX(0.2);
  }
}
@-webkit-keyframes indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -webkit-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}
@-moz-keyframes indeterminateAnimation {
  0% {
    -moz-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -moz-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -moz-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}

@-o-keyframes indeterminateAnimation {
  0% {
    -o-transform: translateX(0) scaleX(0);
    transform: translateX(0) scaleX(0);
  }
  40% {
    -o-transform: translateX(0) scaleX(0.4);
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    -o-transform: translateX(100%) scaleX(0.2);
    transform: translateX(100%) scaleX(0.2);
  }
}

.#{$namespace}loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  .#{$namespace}loading-dot {
    width: 4px;
    height: 4px;
    margin: 0 4px;
    background-color: $primary800;
    border-radius: 5px;
    animation: #{$namespace}bounce 1.5s infinite ease-in-out;
    &:nth-child(1) { animation-delay: 0s; }
    &:nth-child(2) { animation-delay: 0.2s; }
    &:nth-child(3) { animation-delay: 0.4s; }
  }

  @keyframes #{$namespace}bounce {
      0%, 100% { transform: scale(1); opacity: 0.5; }
      50% { transform: scale(1.5); opacity: 1; }
  }
}