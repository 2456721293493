@use '../../variables/colors' as *;

#head, #logo { height: 80px }

#head {
	z-index: 600; position: fixed; top: 0; left: 0; display: block; width: 100%; border-bottom: 1px solid #D5DFE1;
	background: white;
}

#logo {
	position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 224px;
    background: #0D423E;
    a {
        img {
            width: auto;
            height: 26px;
        }
    }
}

#logo a {
    overflow: hidden;
    display: block;
    padding: 24px 0 0 0;
    margin-left: 24px;
}

#logo img {
    width: 130px;
}

#logo .lg_icon,
.minus_left #logo .lg_all  { display: none }
#logo .lg_all,
.minus_left #logo .lg_icon { display: block }
.minus_left #logo .lg_icon { margin: 0 auto }

#hd_title        { position: fixed; left: 220px; top: 18px; font-size: 16px; line-height: 16px; color: #34495e; text-transform: uppercase }
#hd_title i.icon { vertical-align: middle; font-style: normal; color: #7c95ae }
#hd_title i.min  { font-size: 60%; line-height: 60% }
#hd_title a,
#hd_title a i.icon       { color: #4eb38a; transition: all 0.25s ease 0s }
#hd_title a:hover,
#hd_title a:hover i.icon { color: #0b1826; transition: all 0.25s ease 0s }

#hd_title .title_sel                       { display: inline-block; width: 20px; margin: 0 5px 0 0; vertical-align: -5px }
#hd_title .title_sel button                { width: auto; border-radius: 3px; padding: 2px 6px; background: #fff; transition: all 0.20s ease 0s }
#hd_title .title_sel button i              { display: inline-block; padding: 0; font-size: 112%; line-height: 110% }
#hd_title .title_sel button i:before       { color: #4eb38a; transition: all 0.20s ease 0s }
#hd_title .title_sel button:hover          { background: #54c195; transition: all 0.20s ease 0s }
#hd_title .title_sel button:hover i:before { color: #fff; transition: all 0.20s ease 0s }

#hd_title .dropdown-arrow  { right: 0 }
#hd_title .dropdown-menu   { margin-left: -10px }
#hd_title .dropdown-menu a { color: #34495e }

#hd_options {
    height: 80px;
    display: flex;
    align-items: center;
    position: fixed;
    left: 264px;
    font-size: 14px;
    line-height: 14px
}

#hd_options .btn_op {
    padding: 12px!important;
    border: 1px solid #D5DFE1;
    width: 40px;

    i {
        margin: 0!important;
    }
}

#hd_options .btn_op.btn-back {
    border: 1px solid transparent;
}

#hd_options .btn_op:nth-child(2), #hd_options .btn_op:nth-child(2):hover,
#hd_options .btn_op:nth-child(3), #hd_options .btn_op:nth-child(3):hover {
    border-left: none!important;
}

#hd_options .btn_op.btn_default {
    background-color: #EAEEF0!important;
    &:hover    {
        background-color: #EAEEF0;
        color: #2E383A;
    }
}

#hd_options .btn_op:disabled { color: #ABC0C4 }

#hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle {
	font-size: 14px;
    line-height: 24px;
    padding: 0 5px;
    height: 40px;
    background-color: white;
    color: #2E383A;
    white-space: nowrap;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
    outline: 0;
    transition: all 0.3s ease;
    i {
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        padding: 0;
        & + span {
            margin-left: 8px;
        }
    }
    span {
        i {
            margin-right: 8px;
        }
    }
}

#hd_options .btn-group.select button.dropdown-toggle:not(.sl-button-outline-medium), 
.btn-group.slc_currency button.dropdown-toggle:not(.sl-button-outline-medium) {
    border: 1px solid #D5DFE1;
}

#hd_options .btn-group.select button.dropdown-toggle {
    padding: 8px 20px;
}

#hd_options .btn-group.select button.dropdown-toggle.btn-lang,
#hd_options .btn-group.select button.dropdown-toggle.btn-filter {
    border: 1px solid transparent;
    span.filter-option {
        padding-right: 0!important;
    }
}

#hd_options .btn-group.select button.dropdown-toggle:hover,
.btn-group.slc_currency button.dropdown-toggle:hover {
	background-color: #EAEEF0;
}

#hd_options .btn-group.select button .icon, .btn-group.slc_currency button.dropdown-toggle .icon                     { top: 6px }

#hd_options label    { margin: 0 }
#hd_options .btn-box { background: white;}
#hd_options select   { height: 36px; padding: 6px 8px }

	#slct_stat .st_V:before  {content: "\e931"; font-family: 'sally-icon';}
	#slct_stat .st_I:before  {content: "\e930"; font-family: 'sally-icon';}
	#slct_stat .st_D:before  {content: "\e92b"; font-family: 'sally-icon';}

	#slct_link .st_y:before  {content: "\e949"; font-family: "sally-icon";}
	#slct_link .st_n:before  {content: "\e970"; font-family: "sally-icon";}
    #slct_link .st_E:before  {content: "\e92d"; font-family: 'sally-icon'}
    #slct_link .st_Ey:before {content: "\e92d"; font-family: 'sally-icon'}

.btn-group.slc_currency                { float: right; margin: 0 0 0 20px }
.btn-group.slc_currency .dropdown-menu { left: auto; right: 0 }

#head .btn_rgt {
    height: 40px;
    display: block;
    align-items: center;
    position: relative;
    float: right;
    margin: 16px 20px 0px 0px;
}

#head .btn_rgt.ehide {
    display: none;
}

#head .btn_rgt.nxt {
    margin-right: 5px;
}

#head .btn_rgt button {
    top: 4px;
    width: 200px;
    height: 40px;
    color: #2E383A;
    font-size: 20px;
    line-height: 20px;
}
#head .btn_rgt span                { top: 6px }
#head .btn_rgt span.fui-user       { top: -5px; left: -12px; color: #bbb; float: left; font-size: 14px }
#head .btn_rgt              .caret { margin-top: 6px; right: 10px; border-bottom-color: #ccc; border-top-color: #ccc }
#head .btn_rgt button:hover .caret { margin-top: 4px }
#head .btn_rgt button              { border-radius: 4px; float: right; width: auto; padding: 6px 10px; background: white }
#notifications.btn_rgt button      { padding: 6px 5px }
#head .btn_rgt button .fui-user    { top: -2px; left: -6px; font-size: 16px }
#head .btn_rgt button .bell,
#head .btn_rgt button .gly-icon    { position: relative; display: inline-block; line-height: 1; font-size: 16px; top: -2px; left: -1px; right: auto; bottom: auto; float: left; }

#head .btn_rgt button .npdg { padding-right: 0 }
#head .btn_rgt button .bell { padding-right: 0; left: 0; top: 0 }

#head #userdat.btn_rgt button i { font-size: 20px}

#head .btn_rgt button.smpl  {
    padding: 6px 5px;
    font-size: 23px;
    margin-top: 6px;
}

#head .btn_rgt button.smpl .filter-option {

    position: relative; top: auto; left: auto; right: auto; display: inline-block; margin: 2px 0 0 0; padding: 0;
	max-width: 50px; font-size: 100%; line-height: 100%
}

#head .btn_rgt button .filter-option i { display: none }
#head .btn_rgt button:hover            { background: #F8F8F8; -webkit-transition: linear 0.15s; transition: linear 0.15s }
#head .btn_rgt #notif_button:hover {
    background: transparent;
}
#head .btn_rgt button:hover .caret     { position: absolute; border-bottom-color: #555; border-top-color: #555 }

#head .btn_rgt .dropdown-arrow { right: 7px }
#head          .dropdown-arrow { z-index: 5; margin-top: 2px }
#head .open >  .dropdown-arrow { margin-top: 7px }
#head          .dropdown-menu  { z-index: 1; overflow: hidden; min-width: 100% }
#head          .dropdown-menu.dropdown-large { overflow: auto }
#head .btn_rgt .dropdown-menu  { z-index: 1; left: auto; right: 0; min-width: 250px }
#head .open >  .dropdown-menu  { margin-top: 8px !important }

#slct_stat .dropdown-menu li a i {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #2E383A;
}

#slct_stat .dropdown-menu li a:hover {
    i {
        color: #3D9487;
    }
}

@media (max-width: 1200px) {

    #head .btn-group .btn.dropdown-toggle .icon { padding: 0 }
}

#head .box_msgs        { position: relative; float: right; margin: 25px 5px 0 0 }
#head .box_min_usr button, #head .box_msgs button {
    width: 30px; height: 30px; max-height: 30px; vertical-align: 2px; background: #ABC0C4; border: 0; border-radius: 50%; padding: 0;
	color: white; text-align: center; font-size: 11px; line-height: 11px
}



#head .box_min_usr_cont .box_min_usr {
    margin-right: -2px;
    box-shadow: 0 0 0px 2px #fff;
    border-radius: 100%;
    button {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        width: 32px;
        height: 32px;
        max-height: 32px;
    }
    &:first-child {
       margin-right: 18px;
    }
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
		  z-index: #{50 - $i};
		}
	 }

}

#head .box_msgs button { background: transparent; color: #2E383A; font-size: 20px; line-height: 15px }

#head .box_msgs button#Hubspot_slyr {
    font-size: 24px;
    line-height: 16px;
    margin-top: 2px;
}

#head #box_lst_usr button            { background: #ddd; border-color: #ddd; color: #777 }
#head #box_lst_usr button .fui,
#head #box_lst_usr button .icon      { color: #aaa }
#head #box_lst_usr button:hover      { background: #e4e4e4 }

#box_lst_usr .dropdown-menu li a:hover    { background-color: #f9f9f9 }
#box_lst_usr .dropdown-menu         .icon { color: #bbb !important }
#box_lst_usr .dropdown-menu .in_sec .icon { color: #f1c40f !important }
#box_lst_usr .dropdown-menu li em         { display: inline-block; margin-left: 25px; font-style: italic; color: #999 }

#head #box_lst_usr        button.in_sec,
#head .box_min_usr.in_sec button {
    // background: #f1c40f;
    // border-color: #f1c40f;
    background: #ABC0C4;
    border-color: #ABC0C4;
    color: #fff
}

button.button_min_usr {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    width: 24px;
    height: 24px;
    max-height: 24px;
    background: #ABC0C4;
    border-color: #ABC0C4;
    color: #fff;
    border: 0;
    border-radius: 50%;
    margin-right: 5px;
    pointer-events: none;
}

#head #box_lst_usr        button.in_sec .fui,
#head #box_lst_usr        button.in_sec .icon,
#head .btn_rgt.shdw   button.in_sec .icon      { color: #fff }
#head #box_lst_usr        button.in_sec:hover,
#head .box_min_usr.in_sec button:hover         {
    background: #f4d313;
    border-color: #f4d313;
    background: #ABC0C4;
    border-color: #ABC0C4;
    color: #fff }

.tooltip-inner .icon.upr { font-size: 115%; color: rgba(255,255,255,0.7); vertical-align: -10% }

#Intercom_slyr .glyphicons:before { margin-left: -3px; margin-top: 3px }
#Hubspot_slyr .glyphicons:before { margin-left: -3px; margin-top: 3px }

#head .btn_rgt.shdw button #notif_mark.active.icon { color: #2E383A }

#head .btn_rgt.shdw button #notif_mark.icon:after {
    content: "\A";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: flex;
    position: relative;
    background: transparent;
    border: 2px solid transparent;
    top: -24px;
    right: -14px;
}

#head .btn_rgt.shdw button #notif_mark.active.icon:after {
    background: #ED352E;
    border: 2px solid white;
}

#notifications .dropdown-menu {
    padding: 0;
}

#notifications .dropdown-menu .void {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #2E383A;
        margin: 22px 0 0 0;
        text-align: center;
    }
}

#notifications .dropdown-menu,
#notifications.btn_rgt .dropdown-menu {
    min-width: 400px;
    max-width: 600px;
    max-height: 496px;
    overflow-y: auto;
    position: fixed;
    right: 35px;
    top: 60px;
}
#notifications.btn-group.select .dropdown-menu p {
    width: auto;
    color: #2E383A;
}

#notifications li .title {
    display: block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #2E383A;
    margin-bottom: 4px;
}

#notifications li .date {
    display: block;
    font-size: 10px;
    line-height: 16px;
    font-style: normal;
    color: #4C6569;
}

#notifications li p {
    display: block;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    white-space: normal;
    font-weight: 400;
}

#notifications .notif_title {
    padding: 20px 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
}

#notifications li p a         { display: inline-block; padding-top: 5px }
#notifications li > a {
    margin: 0;
    padding: 0;
    border: 0;
    flex-wrap: wrap;
    height: auto;
}
#notifications li > a.nolk    { cursor: default }

#notifications .dropdown-menu {
    margin: 0;
    border-radius: 8px;
    position: relative;
    .list-footer {
        margin-top: 16px;
        position: sticky;
        width: 100%;
        bottom: 0;
    }
}

#notifications .dropdown-menu .notification_item {
    padding: 0px 16px;
    margin-bottom: 8px;
    a {
        white-space: normal;
    }
    a strong {
        font-weight: 600;
    }
    a .title strong {
        font-weight: 800;
    }
    &:first-child {
        padding-top: 16px;
    }
    &.new {
        a:not(.btn) {
            background-color: #FCF8ED;
            border-radius: 8px;
        }
    }
    &:hover {
        a:not(.btn) {
            background-color: #F8F8F8;
            border-radius: 8px;
        }
    }
}

#notifications .dropdown-menu li > a > span.pull-left {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
}

#notifications li p .btn { width: auto; margin-top: 5px }


#notifications li p a.btn,
#box_notifs    .txt a.btn  {
    margin-top: 12px;
    height: 32px;
    padding: 8px 16px;
    border: none;
    font-size: 12px;
    color: #2E383A;
    border-radius: 4px;
  }
  #notifications li p a.btn-primary,
  #box_notifs    .txt a.btn-primary {
    color: #fff;
  }
  #notifications li p a.btn:hover,
  #box_notifs    .txt a.btn:hover {
    color: #2E383A;
  }
  #notifications li p a.btn-primary:hover,
  #box_notifs    .txt a.btn-primary:hover {
    color: #fff;
  }



#notifications .tp_Alert        { border-left: 5px solid rgba(241,196,15,0.5) }
#notifications .tp_Alert:hover  { border-left-color: rgba(241,196,15,0.7) }
#notifications .tp_Error        { border-left: 5px solid rgba(231,76,60,0.5) }
#notifications .tp_Error:hover  { border-left-color: rgba(231,76,69,0.7) }

#notifications .tp_Alert .title,
#notifications .tp_Alert .date,
#notifications .tp_Alert p,
#notifications .tp_Error .title,
#notifications .tp_Error .date,
#notifications .tp_Error p           { padding-left: 10px }

#notifications .list-footer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
}

#notifications .list-footer a span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #000000;
}

#hd_options .btn_op {
    display: flex;
    align-items: center;
    font-weight: 600;

    .icon {
        font-size: 20px;
        margin-right: 4px;
    }
}

#hd_options .btn_op,
#head .elm_qlty,
#head .elm_info {
    height: 40px;
}

#hd_options a.btn.btn_op.btn-warning.url_ldg,
#hd_options .btn-trial {
    background-color: #FBF2D0;
    color: #5A4B35 !important;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;
    padding: 8px 12px;
    width: fit-content;
    border: none;
    opacity: 1;
    strong {margin: 0 4px 0 4px;}
}

#hd_options .bx_left .btn_op .icon.alone {
    font-size: 16px;
    line-height: 16px;
}

div#actions.md_highlight button.btn.dropdown-toggle {
    background-color: #387B77;
    color: #ffffff;

    &:hover {
        background-color: #3D9487;
    }
}

#hd_options .bx_left.chk_elm_all {
    margin-right: 20px;
    margin-left: 12px;
    label {
        line-height: 22px;
    }
}

#hd_options #actions button.btn.dropdown-toggle span.filter-option.pull-left.title {
    padding: 0px 10px 0px 0px;
    font-weight: 600;
}

span.dropdown_language {
    font-weight: 400;
}

@media (min-width:1000px) {
	#hd_options .btn-group.select button.dropdown-toggle, .btn-group.slc_currency button.dropdown-toggle {
		padding: 8px 12px;
		height: 42px;
	}

    #hd_options .btn-group.select button.dropdown-toggle{
        padding: 8px 20px;
    }

    #hd_options .btn_op,
    #head .elm_qlty,
    #head .elm_info {
        min-height: unset;
        max-height: unset;
        height: 40px;
    }
}

.sl-payment-title {
    background-color: #FFFFFF;
    padding: 40px 44px 0 44px;
}

.sl-payment-crumbs {
    padding-top: 34px;
}

.bx_translt .crumbs {
    padding: 34px 0 0 0;
}