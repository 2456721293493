@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/colors' as *;

.#{$namespace}custom-icon-status {
    border-radius: $borderRadius900;
    height: 15px;
    width: 15px;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    &.#{$namespace}custom-icon-status-warning {
        background-color: $warning50;
        &:before {
            background-color: $warning500;
        }
    }
    &.#{$namespace}custom-icon-status-success {
        background-color: $success50;
        &:before {
            background-color: $success500;
        }
    }
    &.#{$namespace}custom-icon-status-critical {
        background-color: $error50;
        &:before {
            background-color: $error500;
        }
    }
    &.#{$namespace}custom-icon-status-info {
        background-color: $info50;
        &:before {
            background-color: $info500;
        }
    }
    &:before {
        position: absolute;
        border-radius: $borderRadius900;
        background-color: black;
        top: 3px;
        left: 3px;
        height: 9px;
        width: 9px;
        content: "";
    }
}