@use '../variables/globals' as *;
@use '../variables/icons' as *;

$icon-size-map: (
    "xxs": $icon50, 
    "xs": $icon100, 
    "sm": $icon200, 
    "md": $icon300, 
    "lg": $icon400
);

// Used in <i> icons
@each $key, $val in ($icon-size-map) {
    .#{$namespace}icon-#{$key} {
        font-size: #{$val} !important;
    }
}

// Used in <img> icons
@each $key, $val in ($icon-size-map) {
    .#{$namespace}icon-size-#{$key} {
        width: #{$val};
        height: #{$val};
    }
}

.#{$namespace}flag {
    content: "\A";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: flex;
    position: absolute;
    background: #ED352E;
    border: 2px solid white;
}

.#{$namespace}flag-icon {
    .#{$namespace}flag {
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        &-hide {
            animation: flagDisappear 0.5s forwards;
        }
    }
}

@keyframes flagDisappear {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    40% {
        transform: scale(1.3);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

/*custom positions icons flag*/

.#{$namespace}icon-chat {
    .sl-flag {
        top: 0px;
        right: 0px;
        }
}

.#{$namespace}icon-user {
    .sl-flag {
        top: 0px;
        right: 0px;
    }
}