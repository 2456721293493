@use '../variables/globals' as *;
@use '../variables/spacing' as *;

/*
	## Dropdown Animation ##
	Generates correct dropdown animation depending on direction.
	Implementation example:
	&[direction=down]{
		...
		@include dropdown-animate("down");
	}
*/

$dropdown-direction-map: (
	"down": "top",
	"up": "bottom",
);

@mixin dropdown-animate($direction) {
	&.#{$namespace}animate-menu-in {
		animation: animate-menu-in-#{$direction} 0.3s ease forwards;
	}
	&.#{$namespace}animate-menu-out {
		animation: animate-menu-out-#{$direction} 0.3s ease forwards;
	}
	@keyframes animate-menu-in-#{$direction} {
		from {
			opacity: 0;
			margin-#{map-get($dropdown-direction-map, $direction)}: 0;
		}
		to {
			opacity: 1;
			margin-#{map-get($dropdown-direction-map, $direction)}: $spacing200;
		}
	}
	
	@keyframes animate-menu-out-#{$direction} {
		from {
			opacity: 1;
			margin-#{map-get($dropdown-direction-map, $direction)}: $spacing200;
		}
		to {
			opacity: 0;
			margin-#{map-get($dropdown-direction-map, $direction)}: 0;
		}
	}
}