/*

    Hubspot

*/

#hubspot-messages-iframe-container {

    display: none !important; bottom: -90px !important; height: 617px !important; max-height: 617px !important; width:416px !important; overflow-y: hidden !important;
}
#hubspot_cont {

    z-index: 1000000; display:none; position: fixed; bottom: 523px; right: 0px; margin-right: 16px; width: 376px
}
#btn_hubspot_chat_close {

    z-index: 2000000; position: absolute; top: -35px; right: 16px; border: 0; border-radius: 30px; padding: 15px; background: #4CB58E; color: #fff; font-size: 12px; line-height: 12px;
    box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.15); cursor: pointer;
}
#btn_hubspot_chat_close:hover {

    background: rgb(81, 189, 149);
}

#btn_hubspot_chat_close:before {

    font-family: 'Glyphicons Regular'; content: "\E208"; display: block; font-size: 150%;
    speak: none; font-weight: normal; font-variant: normal; text-transform: none; font-style: normal;
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}

.hs-cookie-notification-position-bottom { display: none !important }