.is-collapsable {
    display: none;
    opacity: 0;
}
  
.tree-panel__folders__cont {
    bottom: 0px;
    position: fixed;
    width: 299px;
}
  
.tree-panel__folders {
    position: absolute;
    left: 0;
    height: 24px;
    z-index: 501;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    background: #fff;
    width: 100%;
}

.tree-panel__toggler {
    position: fixed;
    margin-left: 287px;
    height: 24px;
    z-index: 501;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    transition: margin-left 0.1s linear;
    &.tree-panel--collapsed {
        margin-left: 19px;
        transition: margin-left 0.1s linear;
    }
}

.tree-panel__toggler--icon {
    cursor: pointer;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    color: #2E383A;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    border: 1px solid #D5DFE1;

    &::before {
        margin-left: 3px;
    }
    &:hover {
        color: #3D9487;
    }
}

.tree-panel {
    background-color: white;
    padding: 0;
    border-right: 1px solid #D5DFE1;
    position: fixed;
    width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1001;
    min-width: 300px;
    transition: all 0.1s linear;
    &.tree-panel--collapsed {
        max-width: 32px;
        min-width: 32px;
        width: 32px;
        transition: all 0.1s linear;
    }
}

.tree-panel::-webkit-scrollbar {
    display: none;
}

.tree-panel {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tree-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 24px 24px 24px;
}

.tree-panel-title {
    font-size: 16px;
    font-weight: bold;
    color: #2E383A;
}

.tree-panel-header button {
    width: 40px;
    i {
        font-size: 20px;
    }
}

.tree-panel--modal {
    padding: 0;
}

.tree-panel ul,
.tree-panel--modal ul {
    padding-left: 0px;
}

.tree-panel ul li,
.tree-panel--modal ul li {
    list-style-type: none;
}

.tree-panel-folders {
    z-index: 999;
}

.tree-panel-folder,
.tree-panel-folder--modal {
    position: relative;
}

.tree-panel-folder a,
.tree-panel-folder--modal a {
    color: #2e383a;
}

.tree-panel-folder__title--active,
.tree-panel-folder--modal__title--active {
    background-color: #EAEEF0;
    
    .tree-panel-folder__root,
    .tree-panel-folder_title__name--text,
    .tree-panel-folder__caret,
    .tree-panel-folder__caret--root,
    .tree-panel-folder_title__icon {
        color: #3d9487;
    }    
}

.tree-panel-folder__title--active,
.tree-panel-folder__title:hover,
.tree-panel-folder--modal__title:hover {
    background-color: #EAEEF0;

    .tree-panel-folder__root,
    .tree-panel-folder_title__name--text,
    .tree-panel-folder__caret,
    .tree-panel-folder__caret--root,
    .tree-panel-folder_title__icon {
        color: #3d9487;
    }
}

.tree-panel-folder__title,
.tree-panel-folder--modal__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 0px;
}

.tree-panel-folder_title__name,
.tree-panel-folder--modal_title__name {
    display: flex;
    width: 100%;
}

.tree-panel-folder_title__name--link {
    display: flex;
}

.tree-panel-folder_title__name--text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}



.tree-panel-folder_breadcrumbs--container {
    height: 24px;
    .change-folder-path {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        color: #205A5A;

        .tree-panel-folder_breadcrumbs--modal {
            font-weight: normal;
            color: #4C6569;
        }
    }
    .sl-icon-chevron-right,
    .sl-icon-folder {
        line-height: 24px;
        max-height: 24px;
        margin-top: 0;
    }
}

.tree-panel-folder__breadcrumbs,
.tree-panel-folder_breadcrumbs--modal {
    display: flex;
    align-items: center;
    font-size: 14px;
    flex-wrap: nowrap;
    overflow-x: clip;
    .truncate {
        strong {
            color: #205A5A;
            font-weight: 600;
        }
    }
}


.tree-panel-folder__breadcrumbs_name--link {
    color: #4C6569;
}

.tree-panel-folder__breadcrumbs_name--link:hover {
    color: #3D9487;
}

.tree-panel-folder_breadcrumbs--more {
    position: relative;
    display: none;
    align-items: center;
}

.tree-panel-folder_breadcrumbs__more--container {
    display: flex;
    align-items: center;
}

.tree-panel-folder_breadcrumbs--link {
    color: #8799aa !important;
}

.tree-panel-folder__caret_container {
    display: flex;
    align-items: center;
    min-width: 13px;
    cursor: pointer;
}

.tree-panel-folder__caret,
.tree-panel-folder__caret--root {
    color: #2e383a;
    font-size: 20px;
    transform: rotate(-90deg);
}

.tree-panel-folder__caret--breadcrumbs {
    color: #4C6569;
    font-size: 16px;
}

.tree-panel-folder__caret--root {
    transform: rotate(0deg) !important;
}

.tree-panel-folder__caret--active {
    transform: rotate(0deg);
}

.tree-panel-folder__caret,
.tree-panel-folder__caret--root,
.tree-panel-folder--modal__caret,
.tree-panel-folder--modal__caret--root,
.tree-panel-folder_title__icon,
.tree-panel-folder--modal_title__icon {
    margin-right: 4px;
}

.tree-panel-folder_title__icon,
.tree-panel-folder--modal_title__icon {
    margin-right: 8px;
}

.tree-panel-folder_title__icon {
    font-size: 20px;
    color: #2e383a;
}

.tree-panel-folder_title__menu {
    display: none;
    padding-right: 24px;
    cursor: pointer;
    align-items: center;
    font-size: 20px;
}

.tree-panel-folder__title:hover .tree-panel-folder_title__menu {
    display: flex;
}

.tree-panel-folder_title__menu--active {
    display: block;
}

.tree-panel-folder__root {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 24px;
}

.toggle-menu-tree,
.tree-breadcrumb-menu {
    position: absolute;
    max-width: 195px;
    background-color: white;
    z-index: 1000;
    font-size: 13px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgb(128 128 128 / 25%);
    padding: 12px;
    right: -90px;
}

.toggle-menu-tree {
    width: 200px;
    opacity: 0;
    transform: translateY(-5px) translateX(-112px);
    transition: all 0.3s ease;
    visibility: hidden;
    display: block !important;
}

.toggle-menu-tree--arrow {
    border-style: solid;
    border-width: 0 10px 9px 10px;
    border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #d5dde5
        rgba(204, 204, 204, 0);
    height: 0;
    margin-top: 0;
    opacity: 1;
    position: absolute;
    right: 6px;
    top: -9%;
    width: 0;
    z-index: 10;
    -webkit-transition: 0.25s;
    transition: 0.25s;
}

.toggle-menu-tree--arrow:after {
    content: " ";
    border-style: solid;
    border-width: 0 10px 8px 8px;
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff
        rgba(255, 255, 255, 0);
    position: absolute;
    right: -9px;
    top: 0;
    width: 0;
    height: 0;
    margin-top: 1px;
}

.tree-panel-animate-menu-in {
    opacity: 1;
    transform: translateY(0px) translateX(-112px);
    transition: all 0.3s ease;
    visibility: visible;
}

.tree-panel-animate-menu-out {
    opacity: 0;
    transform: translateY(-5px) translateX(-112px);
    transition: all 0.3s ease;
}

.tree-breadcrumb-menu {
    display: none;
    flex-wrap: wrap;
    flex-direction: row;
    right: -58px;
    top: 30px;
    max-width: 121px;
}

.item-tree-option {
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    i {
        font-size: 20px;
    }
}

.item-tree-option,
.item-breadcrumb-option {
    width: 100%;
    padding: 10px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-tree-option:hover {
    background-color: #F8F8F8;
    color: #3d9487;
}

.item-tree-option span {
    margin-right: 5px;
    color: rgba(52, 73, 94, 0.75);
}

.tree-breadcrumb-menu--arrow {
    border-style: solid;
    border-width: 0 10px 9px 10px;
    border-color: rgba(204, 204, 204, 0) rgba(204, 204, 204, 0) #d5dde5
        rgba(204, 204, 204, 0);
    height: 0;
    margin-top: 0;
    opacity: 1;
    position: absolute;
    left: 21px;
    top: -9px;
    width: 0;
    z-index: 10;
    -webkit-transition: 0.25s;
    transition: 0.25s;
}

.tree-breadcrumb-menu--arrow:after {
    content: " ";
    border-style: solid;
    border-width: 0 10px 8px 8px;
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff
        rgba(255, 255, 255, 0);
    position: absolute;
    left: -9px;
    top: 0.5px;
    width: 0;
    height: 0;
    margin-top: 1px;
}

.move-folder-footer {
    display: flex;
    justify-content: space-between;
}

.btn-create-folder {
    background: transparent;
    &:hover {
        color: #3D9487;
    }
}

.tree-panel-modal--btn {
    min-width: 114px;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.tree-panel-folder_breadcrumbs--icon {
    color: #4C6569;
    margin-right: 16px;
    width: 24px;
    &:before {
        font-size: 24px;
    }
}

.tree-panel-folder_breadcrumbs--container {
    display: flex;
    align-items: center;
}

#error_creating_folder,
#error_moving_items {
    font-size: 13px;
}
