@use '../../variables/globals' as *;
@use '../../variables/icons' as *;
@use '../../variables/colors' as *;

.#{$namespace}ct-edit-comment-box-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid #D5DFE1;
    border-radius: 4px;
    padding: 12px;
}

.#{$namespace}ct-edit-comment-box-input{
    width: 286px;
    max-width: 100%;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
    max-height: 130px;
    // max-width: 230px;
    color: $neutral600;
    cursor: text;
    &:focus {
      color: $neutral900;
    }
  }

.#{$namespace}ct-edit-comment-box-context-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 12px;
    margin-top: 12px;
    gap: 12px;
    border-top: 1px solid #CFDADD;
}

.#{$namespace}ct-comment-box-new-comment-opacity-transition{
    transition: opacity 3s;
    opacity: 0;
}

