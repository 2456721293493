@use '../../variables/globals' as *;
@use '../../variables/spacing' as *;
@use '../../variables/colors' as *;

.#{$namespace}ct-popover-container {
    color: $neutral900;
    white-space: wrap;
    position: absolute;
    z-index: 99;
    width: 286px;
    z-index: 2001;
    &-background {
        background-color: white;
        border: 1px solid #D5DFE1;
        border-radius: $spacing100;
        box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
        display: flex;
        flex-direction: column;
        gap: $spacing300;
        max-height: 217px;
        font-weight: normal;
        transition: max-height 0.2s ease-out;
        &.#{$namespace}ct-content-loaded {
            max-height: 700px;
        }
    }
    .#{$namespace}ct-inline-message {
        align-items: center;
        display: none;
        padding: 0 12px 12px 12px;
        margin-top: -11px;
        padding-top: 12px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
    }
    &.sl-ct-frame {
        .#{$namespace}ct-popover-container-background {
            padding: $spacing200 $spacing400 $spacing400 $spacing400;
        }
        .#{$namespace}ct-inline-message {
            margin-top: 0;
            padding-top: 0;
            padding: 0;
            border-radius: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }


    .#{$namespace}ct-popover-loader {
        position: absolute;
        display: block;
        padding-left: 16px;
        margin-top: -8px;
        font-weight: 400;
    }
}

.#{$namespace}ct-popover-action-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $spacing100;
}

.#{$namespace}ct-popover-comments-box-container {
    overflow-y: auto;
    overflow-x: hidden;
    /*fix for scrollbar appears*/
    margin-right: -16px;
    padding-right: 16px;
}



