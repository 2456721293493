@use '../variables/globals' as *;
@use '../variables/colors' as *;

.calendar {
    max-width: 392px;
    position: absolute;
    z-index: 999;
      .pika-single.is-hidden {
         display: none;
      }
     
      .is-inrange .pika-button {
         background-color: $neutral100;
      }

      .pika-button:hover {
         background-color: $primary600;
         color: $white;
      }

      .is-disabled {
         .pika-button:hover {
            background-color: $neutral100;
            color: $neutral900;
            cursor:not-allowed;
         }
      }


     .is-startrange .pika-button,
     .is-endrange .pika-button {
        background-color: $neutral300;
        color: $neutral900;
     }
     
     .is-selected .pika-button {
        background-color: $primary800;
        color: $white;
     }

     .is-startrange .pika-button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
     }

     .is-endrange .pika-button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
     }
     
     .pika-table {
        width: 100%;
     }
     
     .pika-table th {
        padding-bottom: 8px;
        text-align: center;
     }
     
     .pika-table td {
        padding-top: 40px;
        position: relative;
        width: 14.25%;
     }
     
     .pika-single button {
        display: inline-block;
        padding: 0;
        height: 32px;
        margin: 9px 0;
     }
     
     .pika-button {
        cursor: pointer;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        color: $neutral900;
        font-weight: 600;
        font-size: 14px;
     }
     
     .pika-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 43px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
     }
     
     .pika-title .pika-label {
        padding: 0 8px;
        position: relative;
        height: 31px;
        align-items: center;
        display: flex;
        justify-content: center;
        &:first-child {
            padding-right: 0px;
        }
     }
     
     .pika-title button {
        padding: 8px;
        position: absolute;
        top: 0;
        margin: 0;
        height: auto;
     }
     
     .pika-title .pika-prev {
        left: 4px;
        &:before {
            font-family: "sally-icon" !important;
            content: "\e91d";
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            font-size: 24px;
        }
     }
     
     .pika-title .pika-next {
        right: 4px;
        &:before {
            font-family: "sally-icon" !important;
            content: "\e91e";
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            font-size: 24px;
        }
     }
     
     .pika-select {
        cursor: pointer;
        position: absolute;
        z-index: 9998;
        margin: 0;
        left: 0;
        top: 10px;
        opacity: 0;
        overflow: visible !important;
        display: inline-block;
        padding: 0 10px;
        width: 100%;
     }
     
     
    .calendar__container {
         background-color: $white;
         box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
         padding: 24px;
         border-radius: 6px;
         margin: 8px 0 0 0;
   }


    .pika-single {
        background-color: $white;
        box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
        padding: 24px;
        border-radius: 6px;
        margin: 8px 0 0 0;
    }
    
    abbr[title], abbr[data-original-title] {
        cursor: default;
        border-bottom: none;
        text-decoration: none;
        text-transform: uppercase;
        color: $neutral800;
        font-weight: 600;
        font-size: 12px;
    }


}
 