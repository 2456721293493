@use '../variables/globals' as *;
@use '../variables/backgrounds' as *;
@use '../variables/borders' as *;
@use '../variables/spacing' as *;
@use '../variables/border-radius' as *;
@use '../variables/typography' as *;
@use '../variables/icons' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;

.#{$namespace}search {
  
  &-box {
    display: flex;
    align-items: center;
    background-color: $background04;
    max-height: 40px;
    width: 292px;
    border: 1px solid $border04;
    padding: $spacing200 $spacing300;
    border-radius: $borderRadius300;
    
    &-focus {
      border: 1px solid $border01;
    }
  }
  
  &-icon {
    font-size: $fontSize400;
    color: $iconDisable;
    
    &-focus {
      color: $iconDefault;
    }
  }
  
  &-input{
  	border: none;
    color: $contentDefault;
    @include generate-type("medium");
    outline: none;
    margin-left: $spacing200;
    width: 100%;

    &::placeholder {
      @include generate-type("medium");
      font-style: $fontRegular;
      color: $contentPlaceholder;
    }
    
    &-focus {
      color: $contentDefault;
    }
  }
  
  &-button {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: $spacing200;
    cursor: pointer;
    font-size: $fontSize400;
    background-color: $background04;
    color: $contentDisable;
    
    &-close {
      @extend .#{$namespace}search-button;
      display: none;
    }
    
    &-left, &-right {
      @extend .#{$namespace}search-button;
      cursor: default;
      
      &-focus {
        color: $contentDefault;
        cursor: pointer;
      }
    }
    
    &-advanced {
      @extend .#{$namespace}search-button;
      color: $contentDefault;
    }
    
    &-visible {
      display: flex;
    }
    
  }
}