/*

    ## Mode Excel

*/

.tooltip-form-label { vertical-align: bottom; margin-right: 10px; }
.form-group .xl-tooltip { color: #bdc3c7; }
.form-group .tooltip-inner { max-width: 300px; width: auto; text-align:left; }

.tbl_excel_stat {
    position: absolute;
    display: none;
    top: -10px;
    left: 50px;
    overflow: hidden;
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    .lotoftime {
      display: none;
    }
}

.tbl_excel_stat > div {
    position: relative;
    overflow: hidden;
    display: none;
}

.tbl_excel_stat .whait {
    color: #653600;
}

.tbl_excel_stat .updtd {
    color: #05570A;
}

.tbl_excel { position: relative; overflow: hidden; width: 100%; min-height: 350px }

.handsontable td,
.handsontableInputHolder,
.handsontableInput,
.handsontable .htSelectEditor { font-size: 13px; line-height: 16px }
.handsontable .htSelectEditor { border: 1px solid #D5DFE1 }

.handsontable .colHeader, .handsontable td { max-width: 450px }

.handsontable .wtSpreader { top: 0 }

.handsontable td.updtd           { background: #feffce !important; }
.handsontable td.deltd           { background: #dbadad !important; }
.handsontable td.slyr_invld      { background: #ffd2c4 !important; }

.handsontable td.updtd.area      { background: #e6fffb !important; }
.handsontable td.deltd.area      { background: #dbc9ad !important; }
.handsontable td.slyr_invld.area { background: #d5f76d !important; }

.handsontable .columnSorting.ascending,
.handsontable .columnSorting.descending { padding-right: 15px }

.handsontable .columnSorting.ascending::after,
.handsontable .columnSorting.descending::after { font-size: 80%; right: 0 !important }

.handsontableInputHolder .select2 { min-height: 25px; height: 25px; max-height: 25px; visibility: hidden }

.handsontable td.htAutocomplete,
.handsontable td.htNoWrap.htAutocomplete { white-space: normal }
.handsontable   .htAutocompleteArrow     { text-align: right }

.ht_clone_left.handsontable .wtHolder table.htCore { box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.15) }

.select2-container--bootstrap.mod-handsontable > .select2-dropdown   { padding: 0; margin-top: 0; border-radius: 0; border: 1px solid #D5DFE1; font-size: 13px; line-height: 16px }
.select2-container--bootstrap.mod-handsontable   .select2-results    { padding: 0; margin: 0; height: auto }
.select2-container--bootstrap.mod-handsontable   .select2-results li { padding: 2px 5px; min-height: auto }

.handsontable th, .handsontable td {
  color: #4C6569;
  background: #FFFFFF;
}

.handsontable td.st_V { color: #387B77; }
.handsontable td.st_I { color: #2E383A; }
.handsontable td.st_D { color: #FF9900; }
.handsontable td.st_V:before {padding: 0 5px; font-size: 80%; content: "\E012"; font-family: 'Flat-UI-Icons'}
.handsontable td.st_I:before {padding: 0 5px; font-size: 80%; content: "\E208"; font-family: 'Glyphicons Regular'}
.handsontable td.st_D:before {padding: 0 5px; font-size: 80%; content: "\E431"; font-family: 'Glyphicons Regular'}

.handsontable .htCheckboxRendererInput {}

.handsontable table td { position: relative }
.handsontable table > tbody > tr:nth-child(2n) > td,
.handsontable table > tbody > tr.selected-row  > td,
.handsontable table > tbody > tr:nth-child(2n) > th,
.handsontable table > tbody > tr.selected-row  > th           { background: #fcfcfc }

.handsontable td.htDimmed { 
  background: #F8F8F8 !important;
  color: #7B9BA1 !important; 
}
.handsontable table > tbody > tr:nth-child(2n) > td.htDimmed,
.handsontable table > tbody > tr.selected-row  > td.htDimmed  { background: #f8f8f8; color: #999; }

.handsontable table > tbody > tr:nth-child(2n) > td.area,
.handsontable table > tbody > tr.selected-row  > td.area,
.handsontable table > tbody > tr:nth-child(2n) > th.area,
.handsontable table > tbody > tr.selected-row  > th.area  { background: #d6e2f6 }
.handsontable table > tbody > tr:nth-child(2n) > td.updtd,
.handsontable table > tbody > tr.selected-row  > td.updtd            { background: #f3f4bd !important }
.handsontable table > tbody > tr:nth-child(2n) > td.updtd.area,
.handsontable table > tbody > tr.selected-row  > td.updtd.area       { background: #d6f2ed !important }
.handsontable table > tbody > tr:nth-child(2n) > td.slyr_invld,
.handsontable table > tbody > tr.selected-row  > td.slyr_invld       { background: #fec9b9 !important }
.handsontable table > tbody > tr:nth-child(2n) > td.slyr_invld.area,
.handsontable table > tbody > tr.selected-row  > td.slyr_invld.area  { background: #caed61 !important }
.handsontable-cell-btn {
    position: absolute; top: 50%; right: 0; margin: -11px 0 0 0; padding: 3px 3px; border: none;
    background: transparent; color: #fff; font-size: 11px; line-height: 11px
}
.handsontable-cell-btn:hover    { color: #222; }
/*
.handsontable-cell-btn.up       { background: #16a085; color: #fff }
.handsontable-cell-btn.up:hover { background: #387B77 }
*/
.handsontable-cell-btn:hover    { -webkit-transition: background .6s ease; -o-transition: background .6s ease; transition: background .6s ease }
/*
.handsontable table > tbody > tr.selected-row  > td .handsontable-cell-btn { border-color: #f8f8f8 }
*/

.handsontable td.htDimmed button { pointer-events: none; opacity: 0.3 }

.handsontableInput {
  box-shadow: 0 0 0 2px #BAAA8A inset !important;
  color: #4C6569 !important;
  padding: 9px 5px 0 17px !important;
}

.md_xl .htBorders .wtBorder.current, .md_xl .htBorders .wtBorder.area {
  background-color: #BAAA8A !important;
}
// EXCEL VIEW

.box_list.md_xl {
    padding: 14px 44px;
}

#box_content {
  .box_list_excel {
    overflow: visible;
  }
}


.handsontable .table colgroup + thead tr:first-child td,
.handsontable .table thead:first-child tr:first-child th,
.handsontable .table thead:first-child tr:first-child td {
  border-top: 1px solid #D5DFE1;
}

.handsontable .table-bordered th:first-child,
.handsontable .table-bordered td:first-child {
  border-left: 1px solid #D5DFE1;
}

.handsontable td {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}

.handsontable th:last-child {
  border-right: 1px solid #D5DFE1;
  border-bottom: 1px solid #D5DFE1;
}

.handsontable tr:first-child th.htNoFrame,
.handsontable th:first-child.htNoFrame,
.handsontable th.htNoFrame {
  border-left-width: 0;
  background-color: white;
  border-color: #FFF;
}

.handsontable th:first-child,
.handsontable th:nth-child(2),
.handsontable td:first-of-type,
.handsontable .htNoFrame + th,
.handsontable .htNoFrame + td {
  border-left: 1px solid #D5DFE1;
}

.handsontable.htRowHeaders thead tr th:nth-child(2) {
  border-left: 1px solid #D5DFE1;
}

.handsontable tr:first-child th,
.handsontable tr:first-child td {
  border-top: 1px solid #D5DFE1;
}

.handsontable.listbox .ht_master table {
  border: 1px solid #D5DFE1;
}

.handsontable tbody tr th:nth-last-child(2) {
  border-right: 1px solid #D5DFE1;
}

.handsontable thead tr:nth-last-child(2) th.htGroupIndicatorContainer {
  border-bottom: 1px solid #D5DFE1;
}


.ht_clone_top_left_corner thead tr th:nth-last-child(2) {
  border-right: 1px solid #D5DFE1;
}

.pika-single {
  border: 1px solid #D5DFE1;
}

.htContextMenu table.htCore {
  border: 1px solid #D5DFE1;
}


  
.md_xl {

  .handsontable th, .handsontable td {
    color: #4C6569;
    text-align: initial;
    font-weight: 400;
    font-size: 14px;
    border-right: 1px solid #D5DFE1;
    border-bottom: 1px solid #D5DFE1;
    box-sizing: border-box;
    &:before {
      line-height: 10px !important;
    }
    &.htNoWrap {
      text-overflow: ellipsis;
      > div {
       text-overflow: ellipsis;
       white-space: nowrap;
       overflow: hidden;
       height: 22px;
     }
    }
  }

  .handsontable td {
    padding: 8px 16px;
  }


  .handsontableMaxHeight {
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    * {
      font-size: 14px !important;
      font-style: normal !important;
      display: unset !important;
      text-align: initial !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      text-align: left !important;
      color: #4C6569 !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .handsontable .handsontable.ht_clone_top .wtHider {
    padding: 0;
  }

  .handsontable thead th .relative {
    text-align: initial;
    text-transform: uppercase;
    background-color: #F8F8F8;
    color: #2E383A;
    padding: 0px 16px;
    font-size: 12px;
    line-height: 40px;
    font-weight: 600;
    height: 40px !important;
  }

  .handsontable tr:first-child th, .handsontable tr:first-child td, .handsontable th:first-child {
    border-top-color: #D5DFE1;
  }
  
  .handsontable th:first-child, .handsontable th:nth-child(2), .handsontable td:first-of-type, .handsontable .htNoFrame + th, .handsontable .htNoFrame + td {
    border-left: 1px solid #D5DFE1;
  }

  .handsontable th:last-child {
    border-right: 1px solid #D5DFE1;
    border-bottom: 1px solid #D5DFE1;
  }



  .ht_clone_left.handsontable .wtHolder table.htCore {
    box-shadow: unset;
  }

  .handsontable table > tbody > tr:nth-child(2n) > td, .handsontable table > tbody > tr.selected-row > td, .handsontable table > tbody > tr:nth-child(2n) > th, .handsontable table > tbody > tr.selected-row > th {
    background: #FFFFFF;
  } 

  .handsontable .columnSorting:hover {
    text-decoration: none;
  }

  .handsontable-cell-btn {
    margin: -12px 0 0 0;
    padding: 5px 8px;
    background: #fff;
    color: #fff;
    font-size: 16px;
    color: #4C6569;
  }

  .htDimmed {
    .handsontable-cell-btn {
      background: transparent;
    }
  }

  .handsontable td.st_V:before {
    font-family: 'sally-icon';
    content: "\e931";
    padding: 0 8px 0 0;
    font-size: 16px;
  }

  .handsontable td.st_I:before {
    font-family: 'sally-icon';
    content: "\e930";
    padding: 0 8px 0 0;
    font-size: 16px;
  }

  .handsontable td.st_D:before {
    font-family: 'sally-icon';
    content: "\e92b";
    padding: 0 8px 0 0;
    font-size: 16px;
  }

  .handsontable .columnSorting.ascending::after {
    font-family: 'sally-icon';
    content: "\e90c";
    font-size: 16px;
    right: -6px !important;
  }

  .handsontable .columnSorting.descending::after {
    font-family: 'sally-icon';
    content: "\e908";
    font-size: 16px;
    right: -6px !important;
  }

  .wtBorder.current, .wtBorder.area {
    background-color: #3EAC93 !important;
  }

  .fixedHeightCell {
    border-left-width: 0;
    height: 22px !important;
    height: 38.8px !important;
    line-height: 22px !important;
  }
  
}

@keyframes animate-in-down-excel-status {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 20px;
  }
}

@keyframes animate-in-down-excel-status-2 {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 10px;
  }
}

.select2-container.select2-container--bootstrap.mod-handsontable.select2-container--open {
  box-shadow: 0px 4px 15px rgb(128 128 128 / 25%);
  margin-top: 20px;
  animation: animate-in-down-excel-status 0.3s ease;
}

.select2-container--bootstrap.mod-handsontable > .select2-dropdown {
  border-radius: 8px;
  border: none;
}

.select2-container--bootstrap.mod-handsontable .select2-results li {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 24px;
  white-space: nowrap;
  color: #2E383A;
}

.select2-container--bootstrap.mod-handsontable .select2-results li.select2-results__option--highlighted {
  border-radius: 4px;
  background-color: #F8F8F8;
  color: #3D9487;
}
  
.handsontable .autocompleteEditor.handsontable.htMacScroll {
    // width: 200px !important;
    min-width: 100%;
    box-shadow: 0px 4px 15px rgb(128 128 128 / 25%);
    padding: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.handsontable.listbox.autocompleteEditor {
  overflow: visible !important;
  height: auto !important;
  .ht_master.handsontable {
    width: calc(100% + 16px) !important;
    margin-left: -10px;
  }
  .wtHolder {
    height: auto !important;
    max-height: 200px;    
    box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
    margin-top: 10px;
    animation: animate-in-down-excel-status-2 0.3s ease;
    margin: 10px;
    border-radius: 8px;
  }
}

.handsontable.listbox .ht_master table.htCore {
    width: 100% !important;
    border: unset;

    td.listbox.htDimmed {
        border-left: none;
        cursor: pointer;

        &.current.highlight,
        &:hover {
            background-color: #F8F8F8;
            color: #3D9487;
        }
    }
}

.ht_master .wtHolder {
    width: 100% !important;
    min-height: 350px;
    .wtHider {
        width: 100% !important;

        .wtSpreader {
            width: 100%;
        }
    }
}

.handsontable.listbox tr td.current,
.handsontable.listbox tr:hover td {
    background-color: none;
}


// .handsontable input, .handsontable textarea:first-line {
//   line-height: 38px;
//   padding-left: 17px;
//   margin-left: 17px;
// }

// .handsontableInputHolder {
//   input, textarea  {
//     padding-left: 17px;
//   }
// }