@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/backgrounds' as *;
@use '../variables/spacing' as *;
@use '../variables/colors' as *;
@use '../variables/content' as *;
@use '../variables/actions' as *;
@use '../variables/semantic' as *;

.#{$namespace}badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background02;
  color: $contentSecondary;
  padding: 0px $spacing100;
  border-radius: $borderRadius700;
  min-width: 20px;
  text-align: center;
  height: 16px;
  font-weight: 600;
  font-size: 10px;
  span {
    line-height: 20px;
  }
  &-info {
    background-color: $info01;
  }
  &-critical {
    background-color: $error01;
  }
  &-success {
    background-color: $success01;
  }
  &-warning {
    background-color: $warning01;
  }
  &-info span,
  &-critical span,
  &-success span,
  &-warning span {
    color: $white;
  }
}