@use '../variables/globals' as *;
@use '../variables/colors' as *;
@use '../variables/icons' as *;
@use '../variables/content' as *;
@use '../variables/semantic' as *;

.#{$namespace}white {
	color: $white !important;
}

// primary
$primary-map: (
	"50": $primary50,
	"100": $primary100,
	"200": $primary200,
	"300": $primary300,
	"400": $primary400,
	"500": $primary500,
	"600": $primary600,
	"700": $primary700,
	"800": $primary800,
	"900": $primary900,
);

$icon-semantic-map: (
	"default": $neutral900, 
	"secondary": $neutral800, 
	"tertiary": $secondary900, 
	"neutral": $neutral400, 
	"neutrallight": $neutral300, 
	"highlight": $primary300, 
	"alternative": $secondary700, 
	"light": $neutral600, 
	"disable": $neutral400, 
	"info-01": $info700, 
	"info-02": $info600,
	"success-01": $success900, 
	"success-02": $success700, 
	"warning-01": $warning600, 
	"warning-02": $warning900, 
	"error-01": $error900,
	"error-02": $error600
);

$content-semantic-map: (  
	"default": $neutral900, 
	"secondary": $neutral800, 
	"tertiary": $secondary900, 
	"light": $neutral600, 
	"disable": $neutral400, 
	"info-01": $info700, 
	"info-02": $info500,
	"success-01": $success700, 
	"success-02": $success900,
	"warning-01": $warning900, 
	"warning-02": $warning600,
	"error-01": $error600,
	"error-02": $error900
);

$border-semantic-map: (  
	"info-01": $info100,
	"neutral-01": $neutral250,
	"success-01": $success200, 
	"success-02": $success700,
	"warning-01": $warning100, 
	"warning-02": $warning200,
	"error-01": $error100,
	"error-02": $error600
);

$background-semantic-map: (  
	"info-01": $info50, 
	"info-02": $info500,
	"info-03": $info74,
	"success-01": $success50, 
	"success-02": $success100,
	"success-03": $success200,
	"success-04": $success600,
	"success-05": $success75,
	"warning-01": $warning50, 
	"warning-02": $warning100,
	"warning-03": $warning200,
	"warning-04": $warning500,
	"warning-05": $warning600,
	"error-01": $error50,
	"error-02": $error100,
	"error-03": $error200,
	"error-04": $error500,
	"error-05": $error75
);

@each $key, $val in ($primary-map) {
	.#{$namespace}primary#{$key} {
		color: $val !important;
	}
}

@each $key, $val in ($icon-semantic-map) {
	.#{$namespace}icon-#{$key} {
		color: $val !important;
	}
}

@each $key, $val in ($content-semantic-map) {
	.#{$namespace}content-#{$key} {
		color: $val !important;
	}
}

@each $key, $val in ($background-semantic-map) {
	.#{$namespace}background-#{$key} {
		background-color: $val !important;
	}
}

@each $key, $val in ($border-semantic-map) {
	.#{$namespace}border-#{$key} {
		border: 1px solid $val !important;
	}
}

.#{$namespace}icon-light {
	color: $iconLight !important;
}

.#{$namespace}content-default {
	color: $contentDefault !important;
}

.#{$namespace}content-secondary {
	color: $contentSecondary !important;
}

.#{$namespace}content-light {
	color: $contentLight !important;
}

.#{$namespace}content-disable  {
	color: $contentDisable !important;
}


.#{$namespace}semantic-error-01 {
	color: $error01 !important;
}

.#{$namespace}semantic-warning-01 {
	color: $warning01 !important;
}

.#{$namespace}semantic-info-01 {
	color: $info01 !important;
}

.#{$namespace}semantic-success-01 {
	color: $success06 !important;
}

.#{$namespace}content-success {
	color: $success06;
}