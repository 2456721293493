@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/spacing' as *;
@use '../variables/borders' as *;
@use '../variables/content' as *;
@use '../variables/typography' as *;
@use '../variables/colors' as *;
@use "../mixins/typography" as *;

.#{$namespace}table {
	min-width: 500px;
	overflow: auto;
	width: 100%;
	border-collapse: separate;
	border: 1px solid $border04;
	border-radius: $borderRadius700;
	background: white;
	> * > * > * {
		padding: 0 $spacing400;
		height: 64px;
	}
	> thead > * > * {
		border-bottom: 1px solid $border04;
	}
	> tbody > :not(:last-child) > * {
		border-bottom: 1px solid $border04;
	}
	thead tr th {
		@include generate-type("small");
		color: $contentSecondary;
		font-style: normal;
		font-weight: $fontSemiBold;
		text-align: left;
		vertical-align: middle;
		text-transform: uppercase;
		padding: $spacing550 $spacing400;
	}
	thead > * > :first-child {
		min-width: 64px;
	}
	tbody > * > :first-child {
		padding: 0px $spacing500;
	}
	tbody tr td {
		@include generate-type("medium");
		font-style: normal;
		font-weight: 400;
		text-align: left;
		color: $contentSecondary;
		vertical-align: middle;
		padding: $spacing500 $spacing400;
	}
	tbody:last-child > * {
		border-bottom: none;
	}

	.checkbox.disabled {
		display: block;
	}
	.checkbox .icons,
	.checkbox .icons:hover {
		cursor: default;
	}
	&.#{$namespace}table-hover {
		tr {
			&:hover {
				td {
					background-color: $neutral50;
				}
			}
		}
		tr:first-child {
			td:first-child {
				border-top-left-radius: $borderRadius700;
			}
			td:last-child {
				border-top-right-radius: $borderRadius700;
			}
		}
		tr:last-child {
			td:first-child {
				border-bottom-left-radius: $borderRadius700;
			}
			td:last-child {
				border-bottom-right-radius: $borderRadius700;
			}
		}
	}
}
