// REVIEW: all variables missing
@use '../variables/globals' as *;
@use '../variables/colors' as *;
@use '../variables/actions' as *;
@use '../variables/typography' as *;
@use '../variables/fonts' as *;
@use '../variables/spacing' as *;
@use '../variables/content' as *;
@use '../variables/icons' as *;
@use "../mixins/typography" as *;

.#{$namespace}link {
  font-weight: $fontRegular;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;
  p {
    font-size: unset;
    line-height: unset;
  }
  &-primary {
    color: $actionPrimaryDefault;
    &:hover {
      color: $actionPrimaryHover;
    }
    &:active {
      color: $contentDefault;
    }
    .#{$namespace}link-icon-right:hover {
      color: $actionPrimaryHover;
    }

    &-medium {
      @extend .#{$namespace}link-primary;
      @include generate-type("medium");
    }
    
    &-small {
        @extend .#{$namespace}link-primary;
        @include generate-type("small");
    }
    
    &-simple:hover {
        color: $actionPrimaryHover;
      }
      
    &-underline {
      text-decoration: underline;
    }

    &-underline:hover {
      color: $actionPrimaryHover;
    }

    &-title {
      font-weight: $fontSemiBold;
    }

    &-title:hover {
      color: $actionPrimaryHover;
    }

    &-lefticon {
      display: flex;
      align-items: center;
    }

    &-icon {
      display: flex;
      align-items: center;

      &-external {
        font-size: $fontSize400;
      }

      &-left {
        font-size: $fontSize400;
      }
    }

    &-icon:hover {
      color: $actionPrimaryHover;
    }

  }
  &-secondary {
    color: $linkSecondaryDefault;
    &:hover {
      color: $linkSecondaryHover;
    }
    .#{$namespace}link-icon-right:hover {
      color: $actionPrimaryHover;
    }
    &-medium {
      @extend .#{$namespace}link-secondary;
      @include generate-type("medium");
    }
    
    &-small {
        @extend .#{$namespace}link-secondary;
        @include generate-type("small");
    }
    
    &-simple {
      &:hover {
        color: $actionPrimaryHover;
        text-decoration: none;
      }
      
      &:active {
        color: $actionPrimaryActive;
      }
    }
      
    &-underline {
      text-decoration: underline;
      
      &:hover {
        color: $contentDefault;
      }
      &:active {
        color: $contentDefault;
      }
      
    }

    &-title {
      font-weight: $fontSemiBold;
      
      &:hover {
        color: $contentDefault;
      }
      
      &:active {
        color: $contentDefault;
      }
    }

    &-lefticon {
      display: flex;
      align-items: center;
    }

    &-icon {
      display: flex;
      align-items: center;

      &-external {
        font-size: $fontSize400;
        margin-left: $spacing200;
      }

      &-left {
        font-size: $fontSize400;
        margin-right: $spacing200;
      }
      
      &:hover {
        color: $contentDefault;
      }
      
      &:active {
        color: $contentDefault;
      }
    }

  }
  &-header-nav {
    color: $iconDefault;
    &:hover {
      color: $linkSecondaryHover;
    }
    .#{$namespace}link-icon-right:hover {
      color: $actionPrimaryHover;
    }
  }
  &-info {
    color: $info700;
  }
  &-medium {
      @include generate-type("medium");
  }

  &-small {
      @include generate-type("small");
      .#{$namespace}link-icon-right {
        @include generate-type("small");
      }
  }

  &-large {
      @include generate-type("large");
      .#{$namespace}link-icon-right {
        @include generate-type("large");
      }
  }

  &-h5 {
      @include generate-type("h5");
      .#{$namespace}link-icon-right {
        @include generate-type("h5");
      }
  }
    
  &-underline {
    text-decoration: underline !important;
  }
  &-title {
    font-weight: $fontSemiBold;
  }

  &-in {
    display: flex;
    align-items: center;
    span {
      display: inline-flex;
    }
  }
  
  &-disabled {
    color: $contentDisable !important;
    pointer-events: none;
    text-decoration: none;
    i:before {
      color: $contentDisable;
    }
  }

  &-nolink {
    cursor: unset;
  }
  
  &.#{$namespace}ellipsis {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}