@use '../variables/globals' as *;

.#{$namespace}status {
	&-accordion {
		width: 26px;
		overflow: hidden;
		transition: .1s all ease-out;
		&.#{$namespace}status-opened {
			width: 80px;
		}
	}
	&-disabled {
		button {
			opacity: 0.5;
			cursor: default;
			&:focus {
				border: 1px solid transparent;
			}
		}
	}
}
