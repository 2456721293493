@use '../variables/globals' as *;
@use "../variables/border-radius" as *;
@use "../variables/transitions" as *;
@use "../variables/backgrounds" as *;
@use "../variables/actions" as *;
@use "../variables/typography" as *;
@use "../variables/borders" as *;
@use "../variables/spacing" as *;
@use "../variables/colors" as *;
@use "../variables/fonts" as *;
@use "../variables/content" as *;
@use "../variables/icons" as *;
@use "../variables/semantic" as *;
@use "../mixins/typography" as *;

.#{$namespace}tag {
	border-radius: $borderRadius300;
	display: flex;
	align-items: center;
	font-weight: $fontSemiBold;
	transition: all $transitionSpeed01 ease;
	white-space: nowrap;
	text-transform: uppercase;
	&-lowercase {
		text-transform: none;
	}
	&-normal {
		@extend .#{$namespace}tag;
		background-color: $background05;
		color: $contentTertiary;
		&-medium {
			@extend .#{$namespace}tag-normal;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-normal;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $iconDefault;
			cursor: pointer;
		}
		&-removable:hover {
			color: $contentTertiary;
		}

		&:hover {
			color: $contentTertiary;
		}
	}
	
	&-info {
		@extend .#{$namespace}tag;
		background-color: $info74;
		color: $info700;
		&-medium {
			@extend .#{$namespace}tag-info;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-info;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $info500;
			cursor: pointer;
		}
		&-removable:hover {
			color: $info500;
		}

		&:hover {
			color: $info500;
		}
	}
	&-critical {
		@extend .#{$namespace}tag;
		background-color: $error04;
		color: $error05;
		&-medium {
			@extend .#{$namespace}tag-critical;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-critical;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $iconDefault;
			cursor: pointer;
		}
		&-removable:hover {
			color: $error05;
		}

		&:hover {
			color: $error05;
		}
	}
	&-warning {
		@extend .#{$namespace}tag;
		background-color: $warning02;
		color: $warning03;
		&-medium {
			@extend .#{$namespace}tag-warning;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-warning;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $iconDefault;
			cursor: pointer;
		}
		&-removable:hover {
			color: $warning03;
		}

		&:hover {
			color: $warning03;
		}
	}
	&-success {
		@extend .#{$namespace}tag;
		background-color: $success02;
		color: $success03;
		&-medium {
			@extend .#{$namespace}tag-success;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-success;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $iconDefault;
			cursor: pointer;
		}
		&-removable:hover {
			color: $success02;
		}

		&:hover {
			color: $success03;
		}
	}
	&-upgrade {
		@extend .#{$namespace}tag;
		background-color: $skyblue100;
		color: #506099;
		&-medium {
			@extend .#{$namespace}tag-upgrade;
			@include generate-type("small");
			padding: $spacing50 $spacing200;
			max-height: 24px;
		}
		&-small {
			@extend .#{$namespace}tag-upgrade;
			@include generate-type("tiny");
			padding: $spacing50 $spacing200;
			max-height: 20px;
		}

		&:hover {
			background-color: $skyblue200;
		}
	}
	&-neutral-secondary {
		@extend .#{$namespace}tag;
		background-color: $background02;
		color: $contentDefault;
		&-medium {
			@extend .#{$namespace}tag-neutral-secondary;
			@include generate-type("medium");
			padding: $spacing200 $spacing300;
			max-height: 40px;
		}
		&-small {
			@extend .#{$namespace}tag-neutral-secondary;
			@include generate-type("small");
			padding: $spacing100 $spacing200;
			max-height: 24px;
		}
		&-removable {
			font-size: $fontSize400;
			color: $iconDefault;
			cursor: pointer;
		}
		&-removable:hover {
			color: $contentDefault;
		}

		&:hover {
			color: $contentDefault;
		}
	}
	&-disabled {
		@extend .#{$namespace}tag;
		background-color: $background02;
		color: $contentDisable;
	}
}
