@use '../../variables/globals' as *;

.#{$namespace}ct-comment-box--ellipse-grey {
    opacity: 0.4;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: #4C6569;
}

.#{$namespace}ct-comment-box--ellipse {
    
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #EAEEF0;
}

.#{$namespace}ct-comment-box-container {
    border-bottom:1px solid #CFDADD;
    word-break: break-word;
    position: relative;
    .#{$namespace}avatar {
        width: 32px;
        height: 32px;
    }

    .#{$namespace}ct-edit-delete-comment {
        position: relative;
    }
}

.#{$namespace}ct-popover-comments-box-container .#{$namespace}ct-comment-box-container:last-child{ border-bottom:none }