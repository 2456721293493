@use '../variables/globals' as *;
@use '../variables/spacing' as *;

.#{$namespace}breadcrumbs {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.#{$namespace}breadcrumbs > * {
	margin-right: $spacing300;
}