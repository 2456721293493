@use '../variables/globals' as *;
@use '../variables/border-radius' as *;
@use '../variables/fonts' as *;
@use '../variables/typography' as *;
@use '../variables/semantic' as *;
@use '../variables/spacing' as *;
@use '../variables/content' as *;
@use "../mixins/typography" as *;

.#{$namespace}alert {
  &-banner {
    //max-width: 800px;
    border-radius: $borderRadius300;
    position: relative;
    @include generate-type("medium");
  }
  
  &-toast {
    width: 400px;
    border-radius: $borderRadius300;
    position: relative;
    @include generate-type("medium");
    box-shadow: 0px 4px 15px 0px #80808040;
  }

  &-page {
    position: relative;
    border: none !important;
    width: 100%;
  }
  
  &-success {
    background-color: $success04;
    border: 1px solid $success05;
    
    .#{$namespace}alert-content, .#{$namespace}alert-icon-state, .#{$namespace}alert-action {
      color: $success03;
    }
  }
  
  &-info {
    background-color: $info04;
    border: 1px solid $info02;
    
    .#{$namespace}alert-content, .#{$namespace}alert-icon-state, .#{$namespace}alert-action {
      color: $info01 !important;
    }
    .#{$namespace}alert-action {
      text-decoration: underline;
    }
  }
  
  &-warning {
    background-color: $warning04;
    border: 1px solid $warning02;
    
    .#{$namespace}alert-content, .#{$namespace}alert-icon-state, .#{$namespace}alert-action {
      color: $warning03;
    }
  }
  
  &-error {
    background-color: $error06;
    border: 1px solid $error04;
    
    .#{$namespace}alert-content, .#{$namespace}alert-icon-state, .#{$namespace}alert-action {
      color: $error05;
    }
  }
  
  &-content {
    padding: $spacing400 44px $spacing400 52px; // REVIEW: missing spacing variables for 44px and 52px
  }
  
  &-title {
    @include generate-type("medium");
    font-weight: $fontBold;
  }

  &-text {
    @include generate-type("medium");
    margin-top: $spacing100;
  }
  
  &-action {
    text-decoration: underline !important;
    font-size: 14px;
  }
  
  &-icon {
    &-state {
      position: absolute;
      top: $spacing400;
      left: $spacing400;
      font-size: $fontSize600;
    }
    
    &-close {
      position: absolute;
      top: $spacing400;
      right: $spacing400;
      font-size: $fontSize400;
      cursor: pointer;
      
      &-success {
        @extend .#{$namespace}alert-icon-close;
        color: $success03;
      }

      &-info {
        @extend .#{$namespace}alert-icon-close;
        color: $info03;
      }

      &-warning {
        @extend .#{$namespace}alert-icon-close;
        color: $warning03;
      }
      
      &-error {
        @extend .#{$namespace}alert-icon-close;
        color: $error05;
      }
    }
  }
}