@use '../../variables/globals' as *;
@use '../../variables/colors' as *;

.#{$namespace}draganddrop-cont li { 
    float: left;
}

.#{$namespace}image-placeholder { 
    background-color:white !important; 
    border-radius: 4px;
    width: 160px;
    height: 130px;
    margin: 0 24px 24px 0;
    background-image: repeating-linear-gradient(90deg, $primary600, $primary600 8px, transparent 8px, transparent 12px), repeating-linear-gradient(180deg, $primary600, $primary600 8px, transparent 8px, transparent 12px), repeating-linear-gradient(90deg, $primary600, $primary600 8px, transparent 8px, transparent 12px), repeating-linear-gradient(180deg, $primary600, $primary600 8px, transparent 8px, transparent 12px);
    background-position: left top, right top, left bottom, left top;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
    background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
}