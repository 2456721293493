@use '../variables/globals' as *;
@use '../variables/backgrounds' as *;
@use '../variables/semantic' as *;
@use '../variables/actions' as *;
@use '../variables/dataviz' as *;

$background-map: (
	"01": $background01,
	"02": $background02,
	"03": $background03,
	"04": $background04,
	"05": $background05,
	"06": $background06,
	"07": $background07,
	"08": $background08,
	"09": $background09,
	"10": $background10,
	"11": $background11,
	"12": $background12,
	"13": $background13,
	"14": $background14,
	"15": $background15,
	"16": $background16,
	"dataviz-1": $dataviz-1,
	"dataviz-2": $dataviz-2,
	"dataviz-3": $dataviz-3,
	"dataviz-4": $dataviz-4,
	"dataviz-5": $dataviz-5,
	"dataviz-6": $dataviz-6,
	"dataviz-7": $dataviz-7,
	"dataviz-8": $dataviz-8,
	"dataviz-9": $dataviz-9,
	"dataviz-10": $dataviz-10,
);

@each $key, $val in ($background-map) {
	.#{$namespace}background-#{$key} {
		background: $val !important;
	}
}

.#{$namespace}background-success-02 {
	background: $success02 !important;
}

.#{$namespace}action-secondary-default {
	background: $actionSecondaryDefault !important;
}
