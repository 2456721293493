@use '../variables/globals' as *;
@use '../variables/backgrounds' as *;
@use '../variables/colors' as *;
@use '../variables/border-radius' as *;
@use '../variables/spacing' as *;
@use '../variables/content' as *;
@use '../variables/icons' as *;
@use '../variables/typography' as *;
@use '../variables/zindex' as *;

.#{$namespace}modal-backdrop {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: $zindex700;
	background-color: $background09;
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
	top: 0;
	&.#{$namespace}modal-show {
		opacity: .8;
		pointer-events: auto;
	}
	&.#{$namespace}modal-hide {
		opacity: 0;
		pointer-events: none;
	}
}

.#{$namespace}modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: fit-content;
	z-index: $zindex800;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $white;
	border-radius: $borderRadius700;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    pointer-events: none;
    max-height: calc(100% - 64px);
	&.#{$namespace}modal-show {
		transform: translateY(0);
		opacity: 1;
		pointer-events: auto;
		&.#{$namespace}modal-top {
			margin: 0 auto;
			transform: translateY(52px);
		}
	}
	&.#{$namespace}modal-hide {
		transform: translateY(-100%);
		opacity: 0;
		pointer-events: none;
		&.#{$namespace}modal-top {
			margin: 0 auto;
			transform: translateY(-100%);
		}
	}
	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: $spacing600 $spacing700 0 $spacing700;
		color: $contentDefault;
		.#{$namespace}modal-close {
			font-size: $icon200;
			color: $iconDefault;
		}
	}
	&-body {
		padding: $spacing650 $spacing700 0 $spacing700;
		flex: 2;
		overflow-y: auto;
	}
	&-footer {
		display: flex;
		justify-content: flex-end;
		border-radius: 0 0 $borderRadius700 $borderRadius700;
		padding: $spacing500 $spacing700;
		&-buttons {
			margin-top: $spacing650;
			background-color: $background01;
		}
	}
	&-small {
		max-width: 500px;
	}
	&-medium {
		max-width: 680px;
	}
	&-large {
		max-width: 832px;
	}
	&-xlarge {
		max-width: 1024px;
	}
	&-subtitle {
		font-size: $fontSize400;
		font-weight: $fontSemiBold;
		line-height: $lineHeight300;
		text-align: center;
	  }
	&-subtitle-small {
		font-size: $fontSize300;
		font-weight: $fontRegular;
		line-height: $lineHeight300;
		text-align: left;
	}	  
	&-acknowledgment {
		@extend .#{$namespace}modal;
		.#{$namespace}modal-body {
			text-align: center; 
			padding-bottom: 0;
			i {
			font-size: 45px; 
			display: inline-block;
			}
		}
		.#{$namespace}modal-footer {
			background-color: $white;
			justify-content: center;
		}
		&-success {
			@extend .#{$namespace}modal-acknowledgment;
			i {
			color: $success700;   
			}
		}
		&-info {
			@extend .#{$namespace}modal-acknowledgment;
			i {
			color: $info500;   
			}
		}
		&-warning {
			@extend .#{$namespace}modal-acknowledgment;
			i {
			color: $warning600;   
			}
		}
	}
	&-doublecheck {
		.alert {
			display: flex;
		}
	}
	&-warning {
		font-size: $fontSize200;
		font-weight: $fontBold;
		line-height: $lineHeight100;
		text-align: left;
		margin: 0px 0px 0px 4px;
	}
	&-warning-container {	
		display: flex;
		align-items: flex-start;
	}

	&-promo {
		&-header {
			padding: $spacing400 $spacing400;
			position: absolute;
			top: 0;
			right: 0;
			button:hover {
				background-color: transparent;
				color: $neutral700;
			}
		}
		&-body {
			img {
				max-width: 100%;
			}
		}
		&-footer {
			.#{$namespace}content-secondary {
				max-width: 362px;
			}
		}
	}
}

.#{$namespace}modal-draganddrop {
	.modal-body-new {
		padding: 0px !important;
		margin-top: 0px !important;
		.#{$namespace}draganddrop-cont {
			display: inline-block;
			padding: 28px 32px;
		}
	}
	.form_slim {
		form {
			margin: 28px 32px;
		}
		&.form_slim_hide {
			form {
				margin: 0 32px;
				.btn-group {
					margin-top: 0 !important;
				}
				.alert {
					margin-top: 28px !important;
				}
			}
		}
	}

}