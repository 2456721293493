/*

	## popups ##

*/

.modal.mod_popup                           { z-index: 88888 }
.modal.mod_popup         .modal-content    { height: 100% }
.modal.mod_popup         .data-loaded      { position: relative; display: inline-block; width: 100% }
.modal.mod_popup         .modal-body       { z-index: 2; min-height: 250px; display: none }
.modal.mod_popup         .modal-footer     { z-index: 1 }
.modal.mod_popup.pop_min .modal-body       { min-height: 82px }
.modal.mod_popup         .modal-body.eshow { display: block }
.modal.mod_popup.direct  .modal-body       { min-height: 0; display: block }
.modal.mod_popup .modal-body.nobrd {
	/* He comentado esta linea porque no se para que sirve y jode los modals que requieren espacio suficiente para cajas de descrpción */
	/*height: 50vh !important;*/
	padding: 0;
}

.modal .modal-body                  { z-index: 5; position: relative; overflow-y: auto; overflow-x: inherit; width: 100%; margin-top: 5px }
.modal .modal-footer                { z-index: 4 }
.modal .modal-footer .btn.disabled,
.modal .modal-footer .btn[disabled],
.modal .modal-footer .sl-button-primary-medium[disabled]:not(.js-move-items-btn),
.modal .modal-footer .sl-button-secondary-medium[disabled] { display: none }

.form_slim                     { position: relative }
.form_slim.sp_top              { margin-top: 20px; border-top: 1px solid #eee; padding-top: 20px }
.form_slim.sp_btm              { padding-bottom: 20px; border-bottom: 1px solid #eee; margin-bottom: 20px }
.form_slim .box_info_required,
.form_slim .required_flag      { display: none }
.form_slim .btn-group          { margin: 0 }

.box_inf_fld div .img_box {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
}

.box_inf_fld div .img_box .xld {
	height: 130px;
}

.box_inf_img .table, .box_inf_fld .table { border: unset !important;}
.box_inf_img .table > * > tr > td, .box_inf_fld .table > * > tr > td {    border-top:unset !important;}

.box_inf_img .table-hover.no-border,    .box_inf_fld .table-hover.no-border    {width: auto}
.box_inf_img .table-hover.no-border td, .box_inf_fld .table-hover.no-border td {
	border: 0;
	background: unset;
}

.box_inf_img .table-hover.st_list_refs thead tr th,
.box_inf_fld .table-hover.st_list_refs thead tr th {
	border-bottom: 1px solid #e1e4e7 !important;
}

.box_inf_img .st_list_refs thead tr th {
	padding: 12px 8px 12px 0px;
    height: auto;
}
.box_inf_img .st_list_refs tbody tr td {
	padding: 12px 8px 0px 0px;
    height: auto;
	background-color:transparent;
	&:hover {
		background-color:transparent;
	}
}

.box_inf_img .table-hover.st_list_refs tbody tr td a,
.box_inf_fld .table-hover.st_list_refs tbody tr td a {
	color: #387B77;
}

.box_inf_img .table-hover.st_list_refs tbody tr td a:hover,
.box_inf_fld .table-hover.st_list_refs tbody tr td a:hover {
	color: #3D9487;
}

.img_info_title {
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	color: #2E383A;
	min-width: 100px;
}

.img_info_detail {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #4C6569;
}

.box_inf_links_title {
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	color: #2E383A;
}

.modal.mod_popup .crumbs .nav-tabs > li.active > a,
.modal.mod_popup .crumbs .nav-tabs > li.active > a:hover,
.modal.mod_popup .crumbs .nav-tabs > li.active > a:focus  {background: #fff; border-bottom-color: #387B77}

.modal.mod_popup .table-striped > tbody > tr:nth-child(2n+1) > td,
.modal.mod_popup .table-striped > tbody > tr:nth-child(2n+1) > th {background: #fff}

.modal.mod_popup .table-striped > tbody > tr:nth-child(2n) > td,
.modal.mod_popup .table-striped > tbody > tr.selected-row  > td,
.modal.mod_popup .table-striped > tbody > tr:nth-child(2n) > th,
.modal.mod_popup .table-striped > tbody > tr.selected-row  > th   {background: #F9FAFB}

.modal.mod_popup .box_msg_action {margin-bottom: 20px}

.modal.mod_popup .img_alert {
	display: block;
	padding: 20px;
	border-radius: 6px;
	background: #f0f0f0;
	text-align: center;
	color: #999}

.modal.mod_popup table.mod_selector > tbody > tr:hover td,
.table-striped > tbody > tr.selectable:hover td                   {background: #F8F8F8; color: #4C6569; cursor: pointer}
.modal.mod_popup table.mod_selector > tbody > tr:hover td strong,
.table-striped > tbody > tr.selectable:hover td strong            {color: #4C6569}
.modal.mod_popup table.mod_selector > tbody > tr:hover .st_V,
.modal.mod_popup table.mod_selector > tbody > tr:hover .label-primary,
.table-striped > tbody > tr.selectable:hover .st_V,
.table-striped > tbody > tr.selectable:hover .label-primary       {background: #fff; color: #387B77}

.modal.mod_popup .modal-body .nav.nav-tabs.nav_tabs_img_size li.active a {
	border-bottom-color: #387B77;
  border-width: 2px;
}
.modal-content .modal-body {
	.nav-tabs.border-green {
		border: 1px solid #3EAC93;
	}
	.nav-accordion,
	.page_fields {
		label {
			&.checkbox,
			&.checkbox.checked {
				color: #2E383A;
			}
		}
	}
}

.bx_trl_fld {
	width: 100%;
}

.field_tp .field_ops button:not(.itemValidationButton) {
	background-color: #D5DFE1;
	color: #2E383A;
	height: 24px!important;

	&:hover {
		background-color: #EAEEF0;
		color: #4C6569;
		border: 1px solid transparent;
	}
	&:active {
		background-color: #CFDADD;
		color: #4C6569;
	}
	&:disabled {
		background-color: #EAEEF0;
		color: #93AFB4;
	}
	&:focus {
		background-color: #D5DFE1;
		color: #2E383A;
		border: 1px solid #3EAC93;
	}

	i {
		margin: 0;
		font-size: 16px;
		line-height: 16px;
	}
}
.modal-content .modal-body .field_tp .field_ops button i {
	font-size: 16px;
	line-height: 16px;
	margin-right: 0px;
}

#encaix #body #box_content .paragrh {
	background-color: #FFFFFF;
}

#encaix #body .form .frm_gnr_btns,
#encaix #body .form .frm_max_btns,
#encaix #body .frm_gnr_btns,
#encaix #body .frm_max_btns {
	left: 0;
	padding: 20px 32px;

	button.btn.btn-lg.btn-primary {
		border: 1px solid #D5DFE1;
		background-color: #387B77;
		color: #FFFFFF;

		&:hover {
			background-color: #3D9487;
		}
	}
}
.modal-body form :last-child:is(.form-group):not(.no_mrbt) {
	margin-bottom: 0px;
}

.related_items_table {
	padding-right: 0px!important;
}

.related_items_table label.checkbox .first-icon {
	z-index:2;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover td strong,
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover td {color: #34495e}
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td.related_items_sorting {width: 0px; padding: 0 24px}
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move {color: #4C6569; opacity: 1;}
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move i {font-size: 16px;}
.modal.mod_popup table.mod_selector > tbody > tr.related_items_row td .related_items_act_move.act_move_disabled {opacity: .3; cursor:default}

.modal.mod_popup table.mod_selector > tbody > tr:hover .st_V,
.modal.mod_popup table.mod_selector > tbody > tr:hover .label-primary,
.table-striped > tbody > tr.selectable:hover .st_V,
.table-striped > tbody > tr.selectable:hover .label-primary       {background: #fff; color: #1abc9c}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover .st_V {
	background: #BFF5C5;
	color: #05570A;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row:hover .label-primary {
	background: #D5DFE1;
	color: #4C6569;
}

.modal.mod_popup table.mod_selector > tbody > tr.related_items_row.ui-sortable-helper {
	box-shadow: -10px 10px rgba(0,0,0,0.20);
}

.related_items_placeholder,
.related_items_placeholder * td {
    height: 43px;
    width: 100%;
}

.modal .btn-group button.dropdown-toggle { min-height: 42px }

.modal .text-center .icon-top   { font-size: 40px }
.modal .text-center .title_text { font-size: 1em }
.modal .text-center .form-group { font-size: 0.8em; padding:0 70px }

.link_pattern_test_alert span.legacy-h7 {
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
}

.link_pattern_test_alert.alert-success span.legacy-h7 {
	color: #05570A;
    font-weight:700!important;
}

.link_pattern_test_alert ul{
	margin-left: 36px;
}

.modal-body {
	.row {
		.sl-alert-banner {
			width: 100%;
		}
	}
}