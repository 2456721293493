/*

    ## Form buttons

*/

#box_content .form .box_form {
     z-index: 1;
     padding: 24px 44px 40px 44px;
}

#body .form .frm_gnr_btns,
#body .form .frm_max_btns,
#body .frm_gnr_btns,
#body .frm_max_btns {
     display: flex;
     justify-content: end;
	z-index: 2000;
     position: fixed;
     bottom: 0;
     right: 0;
     top: auto;
     left: 224px;
     margin: 0;
     padding: 10px 20px;
	background: #F8F8F8;
     border-top: 1px solid #D5DFE1;
}

.minus_left #body .form .frm_gnr_btns {
     z-index: 902;
}

#body                         .frm_gnr_btns,
#body                         .frm_max_btns         { z-index: 50 }
#body                   .form .frm_max_btns,
#body .box_input_medium .form .frm_gnr_btns         { left: 0 }
#body_content           .form .frm_gnr_btns,
#body_content           .form .frm_max_btns         { text-align: right; word-spacing: -1em }
#body_content           .form .frm_gnr_btns   .btn,
#body_content           .form .frm_max_btns   .btn  { float: none; word-spacing: 0 }
#body                         .frm_max_btns,
#body .box_input_medium       .frm_gnr_btns         { left: 0 }
#body_content                 .frm_gnr_btns,
#body_content                 .frm_max_btns         { text-align: right; word-spacing: -1em }
#body_content                 .frm_gnr_btns   .btn,
#body_content                 .frm_max_btns   .btn  { float: none; word-spacing: 0 }

.minus_left #body .form .frm_gnr_btns {
     left: 80px
}
.minus_left #body #body_content.pop_mode .form .frm_gnr_btns { left: 0; background: #fff; padding-bottom: 0}

.box_input_medium .form { padding-bottom: 5px }
.box_input_medium form  { margin-bottom: 0 }

     button.disabled,
form button.disabled
     button.readonly,
form button.readonly { cursor: auto !important; opacity: 0.7; filter: alpha(opacity=70) }

form .box_usr_inf,
form .box_grp_inf {
     display: flex;
     align-items: center;
     flex-wrap: wrap;
     font-size: 12px;
     font-weight: 400;
     color: #4C6569;
     margin: 12px 12px 0 0;

     em {
          font-style: normal;
     }
}

form .box_usr_inf.ehide,
form .box_grp_inf.ehide {
     display: none;
}

form .is_dsbld .box_usr_inf.tp_ref,
form .is_dsbld .box_grp_inf.tp_ref        { padding-top: 10px }
form[name=form_tlang] .box_usr_inf.tp_ref,
form[name=form_tlang] .box_grp_inf.tp_ref { padding: 10px 10px 0 0 }
form .box_usr_inf em.tm {
     color: #4C6569;
     cursor: help
}
form .box_usr_inf .hst_ops {
     display: inline;
}

form .box_usr_inf .hst_ops .btn {
     color: #4C6569;
     padding: 0 0 0 8px;
     margin: 0 0 0 0;
}

form {
     .is_dsbld {
          .box_usr_inf .hst_ops .btn {
               color: #2E383A;
               background: transparent;
               border-radius: 4px;
               border: 1px solid transparent;
               &.btn-xs {
                    padding: 4px;
               }
               .icon.alone {
                    padding-right: 0;
               }
               &.sl-ml-100 {
                    margin-left: 4px;
               }

          }

     }
     .box_usr_inf .hst_ops .btn {
          &.btn-xs {
               padding: 4px;
          }
          .icon.alone {
               padding-right: 0;
          }
          &.sl-ml-100 {
               margin-left: 4px;
          }
     }
}

form .box_usr_inf  .hst_ops .icon         { padding-right: 2px }

form .input-group-btn .icon.glasses,
form .input-group-btn .icon.conversation  { font-size: 18px }
form .box_usr_inf     .icon,
form .box_grp_inf .icon {
     font-size: 14px;
     padding-right: 8px;
     vertical-align: -1px;
}


form .nav-tabs li a .icon { padding-right: 5px }

form .form-group.unused label              { color: #bbb; }
form .form-group.unused input.form-control { color: #bbb; border-color: #ddd }

.btn-group.frm_gnr_btns button.btn.btn-lg.btn-primary,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-inverse,
.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-primary,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-inverse,
.btn-group.frm_gnr_btns button.btn.btn-hg.btn-default {
     height: 42px;
     background-color: #FFFFFF;
     color: #4C6569;
     border: 1px solid #D5DFE1;
     border-right: 1px solid transparent;
     font-weight: 600;

     &:hover {
     background-color: #EAEEF0;
     color: #4C6569;
     border: 1px solid #D5DFE1;
     }

     &:focus {
     border: 1px solid #3EAC93;
     }
}

.btn-secondary,
#body_content .form .frm_gnr_btns .btn.btn-secondary {
     height: 42px;
     background-color: #D5DFE1;
     color: #2E383A;
     border: 1px solid #D5DFE1;
     border-right: 1px solid transparent;
     font-weight: 600;

     &:hover, &:active, &:focus {
     background-color: #EAEEF0;
     color: #4C6569;
     border: 1px solid #EAEEF0;
     }

     &:focus {
     border: 1px solid #3EAC93;
     }
}

.btn-group.frm_gnr_btns>button.btn.btn-hg.btn-default:last-child:not(:first-child) {
     border-right: 1px solid #D5DFE1;
}

.btn-group.frm_gnr_btns button.btn.btn-lg.btn-default {
     border-right: 1px solid #D5DFE1;
}

#bt_add_field button {
     height: 40px;

     i {
         font-size: 20px;
         line-height: 20px;
         margin-right: 8px;
     }
 }

#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_ok],
#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_previous] {
     display: inherit;
     color: #2E383A;
     background-color: #D5DFE1;
     border: 1px solid #D5DFE1;

     &:hover {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          color: #4C6569;
     }
}

#body_content .form .frm_gnr_btns.frm_gnr_btns_conn .btn[name=submit_cancel] {
     color: #2E383A;
     background-color: transparent;
     border: 1px solid transparent;

     &:hover {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          color: #2E383A;
     }
}

#body_content .form .frm_gnr_btns .btn.sl-btn-import[name=submit_ok]  {
     display: flex;
}

#body_content .form .frm_gnr_btns .btn {
     font-weight: 600;
     font-size: 14px;
     line-height: 24px;
     height: 40px;
     padding: 8px 20px;
     color: #FFFFFF;
     background-color: #387B77;
     border-radius: 4px;
     order: 3;
     margin-left: 12px;
     border: 1px solid #387B77;

     &:hover {
          background-color: #3D9487;
          border: 1px solid #3D9487;
     }

     &:active {
          background-color: #205A5A;
          border: 1px solid #205A5A;
     }

     &:focus {
          border: 1px solid #3EAC93;
     }
}

.modal .form .frm_gnr_btns.sl-block button.sl-button-danger-medium {
     border: 1px solid #D5DFE1;
     border-radius: 0;
     height: 42px;
     border-right: 0;
}

.modal .form .frm_gnr_btns.sl-block button.btn-default[name=submit_cancel]{
     border: 1px solid #D5DFE1!important;
}

.sl-block.btn-group,
.sl-block.select,
.sl-block.btn-group.select {
     margin: 0
}

#body_content .form .frm_gnr_btns .btn[name=submit_cancel] {
     font-weight: 600;
     font-size: 14px;
     line-height: 24px;
     height: 40px;
     padding: 8px 20px;
     color: #2E383A;
     background-color: #D5DFE1;
     border-radius: 4px;
     border: 1px solid #D5DFE1;
     order: 2;

     &:hover {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          color: #4C6569;
     }

     &:active {
          background-color: #CFDADD;
          border: 1px solid #CFDADD;
          color: #4C6569;
     }

     &:focus {
          border: 1px solid #3EAC93;
          color: #4C6569;
     }
}

#body_content .form .frm_gnr_btns .btn.btn_save_and_variants_prd {
     font-weight: 600;
     font-size: 14px;
     line-height: 24px;
     height: 40px;
     padding: 8px 20px;
     color: #2E383A;
     background-color: transparent;
     border-radius: 4px;
     border: 1px solid transparent;
     order: 1;

     &:hover {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          color: #2E383A;
     }

     &:active {
          background-color: #D5DFE1;
          border: 1px solid #D5DFE1;
          color: #2E383A;
     }

     &:focus {
          border: 1px solid #3EAC93;
          color: #2E383A;
     }
}

.btn.btn-addssoaccount .icon {
     font-size: 20px;
     margin-right: 8px;
}

button.btn-danger {
     background-color: #FDEDED;
     color: #D2322C;

     &:hover {
          background-color: #FAD7D6;
          color: #D2322C;
          border: 1px solid transparent;
     }
}

button.btn-default {
     &:hover {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          border-color: #EAEEF0;
          color: #2E383A;
     }
     &:active {
          background-color: #CFDADD;
          border: 1px solid #CFDADD;
          border-color: #CFDADD;
          color: #2E383A;
     }
     &:disabled {
          background-color: #EAEEF0;
          border: 1px solid #EAEEF0;
          border-color: #EAEEF0;
          color: #93AFB4;
     }
     &:focus {
          background-color: #D5DFE1;
          border: 1px solid #3EAC93;
          border-color: #3EAC93;
          color: #2E383A;
     }
}


.btn-ordrst-warning {
     background-color: #FBF2D0;
     color: #5A4B35;
}
.btn-ordrst-warning:hover {
     background-color: #FCF8ED;
     color: #5A4B35;
     border: 1px solid transparent;
}
.btn-ordrst-warning:focus {
     background-color: #FCF8ED;
     color: #5A4B35;
     border: 1px solid #3EAC93;
}
.btn-ordrst-success {
     background-color: #BFF5C5;
     color: #05570A;
}
.btn-ordrst-success:hover {
     background-color: #E6FAE8;
     color: #05570A;
     border: 1px solid transparent;
}
.btn-ordrst-success:focus {
     background-color: #E6FAE8;
     color: #05570A;
     border: 1px solid #3EAC93;
}
.btn-ordrst-danger {
     background-color: #F7BCBA;
     color: #55201E;
}
.btn-ordrst-danger:hover {
     background-color: #FAD7D6;
     color: #55201E;
     border: 1px solid transparent;
}
.btn-ordrst-danger:focus {
     background-color: #FAD7D6;
     color: #55201E;
     border: 1px solid #3EAC93;
}
.btn-ordrst-default {
     background-color: #D5DFE1;
     color: #2E383A;
}
.btn-ordrst-default:hover {
     background-color: #EAEEF0;
     color: #2E383A;
     border: 1px solid transparent;
}
.btn-ordrst-default:focus {
     background-color: #EAEEF0;
     color: #2E383A;
     border: 1px solid #3EAC93;
}

.btn-upgrade, .btn-upgrade:focus, .btn-upgrade:active {
     color: #506099;
     background-color: #E6ECFF;
     border-color: transparent;
     text-transform: uppercase;
}
.btn-upgrade:hover, .btn-upgrade:hover, .btn-upgrade:focus:hover, .btn-upgrade:active:hover {
     color: #506099;
     background-color: #CED9FF;
     border: 1px solid transparent;
}