// Font size

$fontSize100: 10px;
$fontSize200: 12px;
$fontSize300: 14px;
$fontSize400: 16px;
$fontSize450: 18px;
$fontSize500: 20px;
$fontSize600: 24px;
$fontSize700: 29px;
$fontSize800: 35px;
$fontSize900: 42px;
$fontSize1000: 44px;
$fontSize1100: 46px;
$fontSize1200: 48px;

// Line Height

$lineHeight00: 0px;
$lineHeight50: 16px;
$lineHeight100: 20px;
$lineHeight300: 24px;
$lineHeight500: 28px;
$lineHeight700: 36px;
$lineHeight900: 40px;
$lineHeight950: 48px;

// Font Weight

$fontRegular: 400;
$fontSemiBold: 600;
$fontBold: 700;

// Text Align

$left: left;
$right: right;
$center: center;